import {useCallback} from 'react';

import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {APP_PERMISSIONS} from 'constants/app-features';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useNativeListener} from 'hooks/use-native-listener';
import {sendMessageToNativeApp} from 'services/native-api';
import {CameraImageEvent} from 'types/NativeEvents';

interface UseImageRequestProps {
  onComplete: ({image}: {image: string}) => any;
}

const useImageRequest = (props: UseImageRequestProps) => {
  const {onComplete} = props;
  const [isPermDialogVbl, showPermDialog, hidePermDialog] = useBoolean();

  const handleCameraImageResponse = useCallback(
    (event: CameraImageEvent) => {
      if (event.image) {
        onComplete({
          image: event.image,
        });
      } else if (event.missing_camera_permission) {
        showPermDialog();
      }
    },
    [showPermDialog, onComplete],
  );

  const handlePermissionCheck = useCallback(
    (goToSettings: boolean) => {
      if (goToSettings) {
        const message = {
          type: 'request_permissions',
          permission: [APP_PERMISSIONS.CAMERA],
        };
        sendMessageToNativeApp({message});
      }

      return hidePermDialog();
    },
    [hidePermDialog],
  );

  const {startListening} = useNativeListener({
    callback: handleCameraImageResponse,
    event: NATIVE_EVENTS.CAMERA_IMAGE,
    preventListen: true,
    isOneTime: true,
  });

  const handleTakePhoto = useCallback(() => {
    startListening();
    const message = {type: NATIVE_MESSAGES.CAMERA_IMAGE};
    sendMessageToNativeApp({message});
  }, [startListening]);

  return {
    isPermDialogVbl,
    handlePermissionCheck,
    handleTakePhoto,
  };
};

export default useImageRequest;

import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import {PullToRefresh as DefaultPullToRefresh} from 'react-js-pull-to-refresh';
import styled from 'styled-components';

import Loader from 'components/Loader/Loader';
import {SettingsContext} from 'contexts/SettingsContext';

const LoaderContainer = styled.div`
  padding: 30px;
`;

type PullToRefreshProps = {
  onRefresh: () => any;
  loaderColor?: string;
};

const PullToRefresh = (props: PropsWithChildren<PullToRefreshProps>) => {
  const {onRefresh, loaderColor, children} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = useCallback(async () => {
    try {
      setIsRefreshing(true);
      await onRefresh();
    } finally {
      setIsRefreshing(false);
    }
  }, [onRefresh]);

  return (
    <>
      {isRefreshing && (
        <LoaderContainer>
          <Loader
            sm={true}
            color={
              loaderColor ||
              organizationConfig.theme.components.pullToRefresh?.loaderColor
            }
          />
        </LoaderContainer>
      )}
      <DefaultPullToRefresh
        pullDownContent={<div />}
        releaseContent={<div />}
        refreshContent={<div />}
        pullDownThreshold={100}
        onRefresh={handleRefresh}
        triggerHeight={150}
        backgroundColor={'transparent'}
        startInvisible={true}>
        {children}
      </DefaultPullToRefresh>
    </>
  );
};

export default PullToRefresh;

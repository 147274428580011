import React from 'react';

import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';

import InfoPage from 'components/InfoPage/InfoPage';
import Notifications from 'components/Notifications';

const NotificationsPage = () => {
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation();

  return (
    <InfoPage
      title={translate('sdk.web.notifs.header')}
      onClose={handleGoBack}
      noPadding>
      <Notifications />
    </InfoPage>
  );
};

export default NotificationsPage;

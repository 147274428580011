import styled from 'styled-components';

const TextMedium = styled.span`
  font-size: ${(props) => props.theme.font.styles.medium.fontSize}px;
  line-height: ${(props) => props.theme.font.styles.medium.fontSize * 1.2}px;
  font-weight: ${(props) => props.theme.font.styles.medium.fontWeight};

  @media only screen and (min-width: 375px) {
    font-size: ${(props) => props.theme.font.styles.medium.s375.fontSize}px;
    line-height: ${(props) =>
      props.theme.font.styles.medium.s375.fontSize * 1.2}px;
  }
`;

export default TextMedium;

import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useToast} from 'hooks/use-toast';
import {useTranslations} from 'hooks/use-translations';
import {deletePaymentMethod} from 'services/billing';
import {PaymentMethod} from 'types/Billing';
import Loader from 'components/Loader/Loader';
import DialogBox from 'components/DialogBox/DialogBox';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import {ReactComponent as DeleteIcon} from 'icons/delete.svg';
import masterImg from 'assets/images/mastercard.jpg';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Details = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const CardNumber = styled(CopyTextSm)`
  display: block;
  margin-left: 7px;
`;

const Img = styled.img`
  width: 48px;
`;

const Icon = styled.div`
  width: 22px;
`;

const LoaderWrapper = styled.div`
  width: 20px;
`;

type PaymentCardProps = {
  paymentMethod: PaymentMethod;
  onDelete: () => void;
};

const PaymentCard = ({paymentMethod, onDelete}: PaymentCardProps) => {
  const {translate} = useTranslations();
  const toast = useToast();
  const [isDeleting, startDeleting, stopDeleting] = useBoolean(false);
  const [isDialogOpen, openDialog, closeDialog] = useBoolean();

  const handleDelete = useCallback(
    async (shouldDelete: boolean) => {
      if (!shouldDelete) {
        closeDialog();
        return;
      }

      try {
        startDeleting();

        await deletePaymentMethod({id: paymentMethod.id});
        await onDelete();
        stopDeleting();
        closeDialog();
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.compensation.payment.card.delete.fallback.error',
        );
        toast.error(message);
        stopDeleting();
      }
    },
    [
      paymentMethod,
      onDelete,
      toast,
      closeDialog,
      startDeleting,
      stopDeleting,
      translate,
    ],
  );

  return (
    <Wrapper>
      <Details>
        <Img src={masterImg} />
        <CardNumber>**** **** **** {paymentMethod.card.last4}</CardNumber>
      </Details>
      {isDeleting ? (
        <LoaderWrapper>
          <Loader sm={true} color={'#222'} width={20} height={20} />
        </LoaderWrapper>
      ) : (
        <Icon onClick={openDialog}>
          <DeleteIcon />
        </Icon>
      )}
      {isDialogOpen && (
        <DialogBox
          title={translate('sdk.web.compensation.payment.card.delete')}
          promptMessage={translate(
            'sdk.web.compensation.payment.card.delete.message',
          )}
          noText={translate('sdk.web.dialog.box.cancel')}
          yesText={translate('sdk.web.dialog.box.confirm')}
          onConfirmation={handleDelete}
          isLoading={isDeleting}
        />
      )}
    </Wrapper>
  );
};

export default PaymentCard;

import React, {useRef, useState} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';
import routes from 'config/routes';
import {updateProfile} from 'services/account';
import {useUserProfile} from 'hooks/use-user-profile';
import {useTranslations} from 'hooks/use-translations';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import {useForm} from 'hooks/forms/use-form';
import Input from 'components/Form/Input';
import FormButton from 'components/Form/FormButton';
import FormRow from 'components/Form/FormRow';
import InfoPage from 'components/InfoPage/InfoPage';
import AccountTitle from 'components/Account/AccountTitle';
import AccountForm from 'components/Account/AccountForm';
import AccountFooter from 'components/Account/AccountFooter';
import AccountButtonWrapper from 'components/Account/AccountButtonWrapper';

type UpdateData = {
  firstname: string;
  lastname: string;
};

const InputWrapper = styled.div`
  padding-top: 30px;
`;

const ProfilePage = (props: any) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {translate} = useTranslations();
  const {userProfile, reloadUserProfile, updateUserData} = useUserProfile();
  const {apiErrors, setApiErrors, validate} = useFormValidation();
  const {getKeyDownListener} = useForm();
  const toast = useToast();

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .required(translate('sdk.web.validations.firstName.required'))
      .max(255, translate('sdk.web.validations.text.max.characters')),
    lastname: yup
      .string()
      .required(translate('sdk.web.validations.lastName.required'))
      .max(255, translate('sdk.web.validations.text.max.characters')),
  });

  const onClose = () => {
    props.history.push(routes.SETTINGS.ACCOUNT.href);
  };

  const onSubmit = async (updateData: UpdateData) => {
    setIsSubmitting(true);

    try {
      updateUserData(updateData);
      updateProfile({id: userProfile!.user.id, data: updateData}).then(() =>
        reloadUserProfile(),
      );
      setIsSubmitting(false);
      toast.success(translate('sdk.web.account.profile.update.success'));
      onClose();
    } catch (e:any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const fieldErrorKeys = e.response
        ? e.response?.data?.fieldErrorKeys
        : undefined;
      const message = translate(
        errorKey || 'sdk.web.account.profile.update.error.fallback',
      );
      toast.error(message);
      setIsSubmitting(false);
      setApiErrors(fieldErrorKeys);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      ...userProfile!.user,
    },
    onSubmit,
    validate,
  });

  return (
    <InfoPage
      title={translate('sdk.web.account.profile.title')}
      onClose={onClose}>
      <AccountForm onSubmit={formik.handleSubmit} ref={formRef}>
        <div>
          <AccountTitle>
            {translate('sdk.web.account.profile.update')}
          </AccountTitle>
          <InputWrapper>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.firstName.label')}
                name={'firstname'}
                type={'text'}
                formik={formik}
                apiErrors={apiErrors}
                value={formik.values.firstname}
                onChange={formik.handleChange}
                enterkeyhint={'next'}
                onKeyDown={getKeyDownListener({form: formRef.current})}
              />
            </FormRow>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.lastName.label')}
                name={'lastname'}
                type={'text'}
                formik={formik}
                apiErrors={apiErrors}
                value={formik.values.lastname}
                onChange={formik.handleChange}
                enterkeyhint={'next'}
                onKeyDown={getKeyDownListener({form: formRef.current})}
              />
            </FormRow>
          </InputWrapper>
        </div>
        <AccountFooter>
          <AccountButtonWrapper>
            <FormButton isLoading={isSubmitting}>
              {translate('sdk.web.account.profile.update.button')}
            </FormButton>
          </AccountButtonWrapper>
        </AccountFooter>
      </AccountForm>
    </InfoPage>
  );
};

export default withRouter(ProfilePage);

import React from 'react';
import {errorBoundaryComponent} from '../store/utils';

/**
 * ErrorBundary
 * @status rc
 */
const ErrorBoundary = errorBoundaryComponent((props) => {
  if (props.error) {
    return (
      <div className="error-screen">
        <h2>An error has occured</h2>
        <h4>{props.error.message}</h4>
      </div>
    );
  }

  // @ts-ignore
  return <React.Fragment>{props.children}</React.Fragment>;
});

export default ErrorBoundary;

import styled from 'styled-components';

const CopyTextSm = styled.span`
  font-size: ${(props) => props.theme.font.styles.small.fontSize}px;
  line-height: ${(props) => props.theme.font.styles.small.fontSize * 1.2}px;
  font-weight: ${(props) => props.theme.font.styles.small.fontWeight};

  @media only screen and (min-width: 375px) {
    font-size: ${(props) => props.theme.font.styles.small.s375.fontSize}px;
    line-height: ${(props) =>
      props.theme.font.styles.small.s375.fontSize * 1.2}px;
  }
`;

export default CopyTextSm;

import React from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {addGlobalSquareCorners} from 'utils/theme';

import CopyText from 'components/Text/CopyText/CopyText';
import {ReactComponent as CaretIcon} from 'icons/caret-right.svg';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${(props) =>
    props.theme.components.metering?.updateReadingBgColor ||
    props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.black};
  padding: 15px;
  padding-right: 10px;
  margin-bottom: 20px;

  span {
    width: 200px;
    text-align: left;
  }

  ${addGlobalSquareCorners()}
`;

const Icon = styled.div`
  width: 25px;
  fill: ${(props) =>
    props.theme.components.metering?.updateReadingCaretColor ||
    props.theme.colors.primary};

  svg {
    width: 100%;
  }
`;

interface UpdateReadingCtaProps {
  onClick: () => any;
}

const UpdateReadingCta = (props: UpdateReadingCtaProps) => {
  const {onClick} = props;
  const {translate} = useTranslations();

  return (
    <Wrapper onClick={onClick}>
      <CopyText>{translate('sdk.web.meter.consumption.edit.cta')}</CopyText>
      <Icon>
        <CaretIcon />
      </Icon>
    </Wrapper>
  );
};

export default UpdateReadingCta;

import {useContext, useEffect} from 'react';
import {CACHE_KEYS} from 'constants/cache-keys';
import {ActivityContext} from 'contexts/ActivityContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import {useUserProfile} from 'hooks/use-user-profile';
import {ActivitySummary, getMyActivitySummary} from 'services/user';

const useActivitySummary = () => {
  const {updateIsInviteFriendsActive, updateIsTreePlantingActive} =
    useContext(ActivityContext);
  const {hasCustomActivities} = useOrganization();
  const {userProfile} = useUserProfile();

  const {
    data: activitySummary,
    fetchData: fetchActivitySummary,
  }: {data: ActivitySummary; fetchData: () => void} = useFetcher({
    fetcher: getMyActivitySummary,
    params: {
      userProfile,
      hasCustomActivities,
    },
    key: CACHE_KEYS.ACTIVITY_SUMMARY,
  });

  useEffect(
    () => {
      if (!activitySummary) {
        return;
      }

      if (activitySummary.treePlanting) {
        updateIsTreePlantingActive!(activitySummary?.treePlanting?.isActive);
      }

      if (activitySummary.referralsData) {
        updateIsInviteFriendsActive!(activitySummary?.referralsData.isActive);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activitySummary],
  );

  return {
    activitySummary,
    fetchActivitySummary,
  };
};

export default useActivitySummary;

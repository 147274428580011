import React, {useRef} from 'react';
import styled from 'styled-components';
import SwiperCore, {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import {useOrganization} from 'hooks/use-organization';
import {ReactComponent as LeftCaretIcon} from 'icons/caret-left-lg.svg';
import {ReactComponent as RightCaretIcon} from 'icons/caret-right-lg.svg';
import img0 from './images/gallery/0.jpg';
import img1 from './images/gallery/1.jpg';
import img2 from './images/gallery/2.jpg';
import img3 from './images/gallery/3.jpg';
import img4 from './images/gallery/4.jpg';
import img5 from './images/gallery/5.jpg';
import img6 from './images/gallery/6.jpg';
import img7 from './images/gallery/7.jpg';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation]);

const defaultImages = [img0, img1, img2, img3, img4, img5, img6, img7];

const Wrapper = styled.div`
  position: relative;
`;

const Slide = styled.div`
  overflow: hidden;
  border-radius: 12px;

  ${(props) => {
    if (props.theme.components.co2Compensation?.hasSquareCorners) {
      return `
        border-radius: 0;
      `;
    }
    return '';
  }}
`;

const Img = styled.img`
  width: 100%;
  height: 185px;
  object-fit: cover;
`;

const IconStyles = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
`;

const LeftIcon = styled(IconStyles)`
  left: 7px;
`;

const RightIcon = styled(IconStyles)`
  right: 7px;
`;

const Icon = styled.div`
  fill: ${(props) => props.theme.colors.white};
  stroke: ${(props) => props.theme.colors.white};
  width: 15px;
`;

const Gallery = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const {organizationConfig} = useOrganization();
  const images = (organizationConfig.theme.components.co2Compensation
    ?.galleryImages || defaultImages) as Array<string>;

  return (
    <Wrapper>
      <LeftIcon>
        <Icon ref={prevRef}>
          <LeftCaretIcon />
        </Icon>
      </LeftIcon>
      <RightIcon>
        <Icon ref={nextRef}>
          <RightCaretIcon />
        </Icon>
      </RightIcon>
      <Swiper
        onInit={(swiper: SwiperClass) => {
          // @ts-ignore
          swiper.params.navigation.prevEl = prevRef.current;
          // @ts-ignore
          swiper.params.navigation.nextEl = nextRef.current;
          // @ts-ignore
          swiper.navigation.update();
        }}
        watchSlidesVisibility
        spaceBetween={10}
        slidesPerView={'auto'}
        loop={true}
        threshold={10}>
        {images.map((image, index) => (
          <SwiperSlide style={{width: '85%'}} key={index}>
            <Slide>
              <Img src={image} />
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

export default Gallery;

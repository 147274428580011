import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10.5px;
`;

interface ProgressBarProps extends PropsWithChildren {
  pathColor: string;
  percent: number;
  strokeWidth: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  const {pathColor, percent, strokeWidth, children} = props;

  return (
    <CircularProgressbarWithChildren
      value={percent}
      strokeWidth={strokeWidth}
      styles={buildStyles({
        pathColor,
        trailColor: 'transparent',
        strokeLinecap: 'round',
      })}>
      <Wrapper>{children}</Wrapper>
    </CircularProgressbarWithChildren>
  );
};

export default ProgressBar;

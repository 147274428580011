import React from 'react';

import {useTranslations} from 'hooks/use-translations';

import PromptModal from 'components/PromptModal/PromptModal';
import successImg from './icons/success.svg';

interface NeutralModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const NeutralModal = (props: NeutralModalProps) => {
  const {isVisible, onClose} = props;
  const {translate} = useTranslations();

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.emissions.offset.neutral.title')}
      textContent={translate('sdk.web.emissions.offset.neutral.info')}
      image={successImg}
      onClose={onClose}
      btnText={translate('sdk.web.emissions.offset.neutral.ok')}
      onBtnClick={onClose}
    />
  );
};

export default NeutralModal;

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';
import pickBy from 'lodash/pickBy';
import isUndefined from 'lodash/isUndefined';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {CACHE_KEYS} from 'constants/cache-keys';
import {BREAKPOINTS} from 'constants/breakpoints';
import {useTranslations} from 'hooks/use-translations';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useForm} from 'hooks/forms/use-form';
import {useToast} from 'hooks/use-toast';
import {useFetcher} from 'hooks/use-fetcher';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useBodyHiddenScroll} from 'hooks/ui/use-body-hidden-scroll';
import {getCompensation, purchaseCompensation} from 'services/co2Compensation';
import {getBillingDetails, getCountries, saveBilling} from 'services/billing';
import {formatAmount, formatNumber} from 'utils/numbers';
import {addBoldText, addFontFamily, addGlobalSquareCorners} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Input from 'components/Form/Input';
import Button from 'components/Button/Button';
import PaymentMethods from './PaymentMethods';
import DialogBox from 'components/DialogBox/DialogBox';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import CopyText from 'components/Text/CopyText/CopyText';
import Loader from 'components/Loader/Loader';
import SuccessPrompt from './SuccessPrompt';
import CountryPicker from './CountryPicker';
import {Billing} from 'types/Billing';

import {Compensation, CompensationType} from 'types/Compensation';
import {ReactComponent as CloseIcon} from 'icons/close.svg';

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
`;

const HeaderTitle = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) =>
    props.theme.components.co2Compensation?.paymentForm?.headerTitleFontSize}px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const VAT = styled(CopyTextSm)`
  ${(props) =>
    addFontFamily({
      props,
      value: props.theme.components.co2Compensation?.paymentForm?.fontFamily,
    })}
`;
const Content = styled.div`
  max-height: 97%;
  min-height: 97%;
  background-color: ${(props) => props.theme.colors.white};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 20px 15px 30px;
  overflow-y: auto;
  position: relative;
`;

const BackButton = styled(CopyText)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 20px;

  &:active,
  &:hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Icon = styled.div`
  width: 20px;
  margin-right: 10px;
  display: block;
  color: ${(props) => props.theme.colors.black};
`;

const PriceWrapper = styled.div`
  background-color: ${(props) =>
    props.theme.components.co2Compensation.pageBgColor ||
    props.theme.colors.lightSecondary};
  padding: 20px 10px 30px;
  border-radius: 8px;
  margin-bottom: 10px;

  ${addGlobalSquareCorners()}
`;

const Price = styled(HeaderText)`
  display: block;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Title = styled(CopyTextSm)`
  display: block;
  margin-bottom: 30px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Form = styled.form``;

const FormRow = styled.div`
  margin-bottom: 20px;

  @media only screen and (min-height: ${BREAKPOINTS.HEIGHT.lg}) {
    margin-bottom: 25px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const PaymentMethodsWrapper = styled.div`
  margin-bottom: 30px;
`;

type CompensationUpdates = {
  isLoading: boolean;
  data: Compensation;
  fetchData: () => void;
};

type PaymentProps = {
  compensation: Compensation;
  month?: number;
  onClose: () => void;
};

const PaymentForm = ({compensation, month, onClose}: PaymentProps) => {
  const {hideBodyScroll} = useBodyHiddenScroll();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {translate} = useTranslations();
  const {apiErrors, setApiErrors, validate} = useFormValidation();
  const {getKeyDownListener} = useForm();
  const toast = useToast();
  const [isConfirmationOpen, showConfirmation, hideConfirmation] =
    useBoolean(false);
  const [isSuccessPromptOpen, showSuccessPrompt, hideSuccessPrompt] =
    useBoolean(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

  const {data: billingDetails} = useFetcher({
    fetcher: getBillingDetails,
    initialValue: [],
    key: CACHE_KEYS.BILLING_DETAILS,
  });

  const {data: countries} = useFetcher({
    fetcher: getCountries,
    initialValue: [],
    key: CACHE_KEYS.COUNTRIES,
  });

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    street_address: '',
    city: '',
    postal_code: '',
    country: '',
    vat_number: '',
    ...pickBy(billingDetails, (val) => val),
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstname: yup
          .string()
          .required(translate('sdk.web.validations.firstName.required'))
          .max(255, translate('sdk.web.validations.text.max.characters')),
        lastname: yup
          .string()
          .required(translate('sdk.web.validations.lastName.required'))
          .max(255, translate('sdk.web.validations.text.max.characters')),
        email: yup
          .string()
          .email(translate('sdk.web.contact.form.email.error.invalid')),
        phone: yup
          .string()
          .max(255, translate('sdk.web.validations.text.max.characters')),
        street_address: yup
          .string()
          .max(255, translate('sdk.web.validations.text.max.characters')),
        country: yup
          .string()
          .max(255, translate('sdk.web.validations.text.max.characters')),
      }),
    [translate],
  );

  const onSubmit = async (data: Billing) => {
    setIsSubmitting(true);

    try {
      await saveBilling({data});
      await purchaseCompensation({
        compensation_id: compensation.id,
        month: isUndefined(month) ? month : month + 1,
      });
      onPurchase();
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const fieldErrorKeys = e.response
        ? e.response?.data?.fieldErrorKeys
        : undefined;
      const message = translate(
        errorKey || 'sdk.web.compensation.purchase.error.fallback',
      );
      toast.error(message);
      setIsSubmitting(false);
      setApiErrors(fieldErrorKeys);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  const {
    data: updatedCompensation,
    isLoading: isLoadingCompensation,
    fetchData: reloadCompensation,
  }: CompensationUpdates = useFetcher({
    fetcher: getCompensation,
    preventFetch: true,
    initialValue: {
      ...compensation,
    },
    params: {
      id: compensation.id,
      countryCode: formik.values.country || 'de',
    },
  });

  useEffect(
    () => {
      if (formik.values.country) {
        reloadCompensation();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.country],
  );

  const grossPrice = useMemo(
    () => formatAmount({number: updatedCompensation.gross_price, dp: 2}),
    [updatedCompensation.gross_price],
  );

  const vat = useMemo(() => {
    if (!updatedCompensation.tax_rate) {
      return;
    }

    const taxRate = formatNumber({number: updatedCompensation.tax_rate, dp: 2});
    const amount = formatAmount({
      number: updatedCompensation.gross_price - updatedCompensation.price,
      dp: 2,
    });

    return translate('sdk.web.compensation.billing.fees', [
      {
        key: '{percent}',
        value: taxRate,
      },
      {
        key: '{amount}',
        value: amount,
      },
    ]);
  }, [updatedCompensation, translate]);

  const handleConfirmation = useCallback(
    (shouldSubmit: boolean) => {
      hideConfirmation();

      if (shouldSubmit) {
        formik.handleSubmit();
      }
    },
    [formik, hideConfirmation],
  );

  const handlePromptClose = useCallback(() => {
    hideSuccessPrompt();
    history.push(routes.DASHBOARD.href);
  }, [hideSuccessPrompt, history]);

  const onPurchase = () => {
    setIsSubmitting(false);
    showSuccessPrompt();
  };

  return (
    <Wrapper>
      <Overlay />
      <Content>
        <BackButton as={'a'} onClick={onClose}>
          <Icon>
            <CloseIcon />
          </Icon>
          <HeaderTitle>
            {translate('sdk.web.compensation.back.title')}
          </HeaderTitle>
        </BackButton>
        <PriceWrapper>
          {isLoadingCompensation ? (
            <Loader color="#222" sm={true} />
          ) : (
            <>
              <Price>
                {grossPrice} €{' '}
                {compensation.type === CompensationType.SUBSCRIPTION &&
                  translate('sdk.web.compensation.per.month')}
              </Price>
              <VAT>{vat}</VAT>
            </>
          )}
        </PriceWrapper>
        <Title>{translate('sdk.web.compensation.billing.title')}</Title>
        <Form onSubmit={formik.handleSubmit} ref={formRef}>
          <div>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.firstName.label')}
                name={'firstname'}
                type={'text'}
                formik={formik}
                apiErrors={apiErrors}
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={hideBodyScroll}
                enterkeyhint={'next'}
                onKeyDown={getKeyDownListener({form: formRef.current})}
              />
            </FormRow>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.lastName.label')}
                name={'lastname'}
                type={'text'}
                formik={formik}
                apiErrors={apiErrors}
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={hideBodyScroll}
                enterkeyhint={'next'}
                onKeyDown={getKeyDownListener({form: formRef.current})}
              />
            </FormRow>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.email.label')}
                name={'email'}
                type={'email'}
                formik={formik}
                apiErrors={apiErrors}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={hideBodyScroll}
                enterkeyhint={'next'}
                onKeyDown={getKeyDownListener({form: formRef.current})}
              />
            </FormRow>
            <FormRow>
              <CountryPicker
                name={'country'}
                countries={countries}
                value={formik.values.country}
                onChange={formik.handleChange}
                formik={formik}
                apiErrors={apiErrors}
              />
            </FormRow>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.city.label')}
                name={'city'}
                type={'text'}
                formik={formik}
                apiErrors={apiErrors}
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={hideBodyScroll}
                onKeyDown={getKeyDownListener({form: formRef.current})}
              />
            </FormRow>
            <FormRow>
              <Input
                placeholder={translate('sdk.web.inputs.postal_code.label')}
                name={'postal_code'}
                type={'text'}
                formik={formik}
                apiErrors={apiErrors}
                value={formik.values.postal_code}
                onChange={formik.handleChange}
                onBlur={hideBodyScroll}
                onKeyDown={getKeyDownListener({form: formRef.current})}
              />
            </FormRow>
            <PaymentMethodsWrapper>
              <PaymentMethods onUpdate={setHasPaymentMethod} />
            </PaymentMethodsWrapper>
          </div>
          <ButtonWrapper>
            <Button
              type="button"
              isLoading={isSubmitting}
              onClick={showConfirmation}
              disabled={!hasPaymentMethod || isLoadingCompensation}>
              {translate('sdk.web.compensation.purchase.cta')}
            </Button>
          </ButtonWrapper>
        </Form>
        {isConfirmationOpen && (
          <DialogBox
            title={translate('sdk.web.compensation.purchase.prompt.title')}
            promptMessage={translate(
              'sdk.web.compensation.purchase.prompt.message',
            )}
            noText={translate('sdk.web.dialog.box.cancel')}
            yesText={translate('sdk.web.dialog.box.confirm')}
            onConfirmation={handleConfirmation}
          />
        )}
      </Content>
      <SuccessPrompt
        compensation={compensation}
        isVisible={isSuccessPromptOpen}
        onClose={handlePromptClose}
      />
    </Wrapper>
  );
};

export default PaymentForm;

import React from 'react';
import styled from 'styled-components';
import {UpdateVoucherWishlist, useWishlist} from './use-wishlist';
import {ReactComponent as ActiveHeart} from 'icons/heart-active.svg';
import {ReactComponent as InactiveHeart} from 'icons/heart-inactive.svg';

const Icon = styled.div<{lg?: boolean}>`
  padding: 2.5px;
  position: absolute;
  z-index: 100;
  top: 2.5%;
  right: 2.5%;
  width: 35px;
  fill: ${(props) =>
    props.theme.components.vouchers.wishlistIcon?.fill ||
    props.theme.colors.primary};
`;

const InactiveHeartComponent = styled(InactiveHeart)`
  opacity: ${(props) =>
    props.theme.components.vouchers.wishlistIcon?.inActiveOpacity || 1};
`;

type WishListProps = {
  id: number;
  wishlisted: boolean;
  updateVoucherWishlist: UpdateVoucherWishlist;
};

const WishList = (props: WishListProps) => {
  const {isWishlist, handleWishlist} = useWishlist(props);

  return (
    <Icon onClick={handleWishlist}>
      {isWishlist ? <ActiveHeart /> : <InactiveHeartComponent />}
    </Icon>
  );
};

export default WishList;

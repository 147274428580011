import React, {useMemo} from 'react';
import {wrapLinksInAnchorTags} from 'utils/strings';

interface TextWithLinksProps {
  text: string;
}

const TextWithLinks = (props: TextWithLinksProps) => {
  const {text} = props;
  const processedText = useMemo(() => wrapLinksInAnchorTags(text), [text]);

  return <div dangerouslySetInnerHTML={{__html: processedText}} />;
};

export default TextWithLinks;

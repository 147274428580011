import moment from 'moment';
import classnames from 'classnames';
import styled from 'styled-components';
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {CACHE_KEYS} from 'constants/cache-keys';
import {SettingsContext} from 'contexts/SettingsContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import useNotificationsStats from 'hooks/services/notifications/use-notifications-stats';
import {
  getNotifications,
  markNotificationsAsSeen,
} from 'services/notifications';
import {Notification} from 'types/Notification';
import {addGlobalSquareCorners} from 'utils/theme';
import {loadMomentLocales} from 'utils/language';

import Loader from 'components/Loader/Loader';
import PullToRefresh from 'components/PullToRefresh';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

import {ReactComponent as CaretIcon} from 'icons/caret-right-lg.svg';
import activityIcon from 'icons/push-notifications/activities.svg';
import recoinIcon from 'icons/push-notifications/recoins.svg';
import challengeIcon from 'icons/push-notifications/challenges.svg';
import marketplaceIcon from 'icons/push-notifications/marketplace.svg';
import meteringIcon from 'icons/push-notifications/metering.svg';
import referralIcon from 'icons/push-notifications/referrals.svg';
import treeIcon from 'icons/push-notifications/tree-planting.svg';

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;

  .notifications {
    &__item {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin: 10px;
    }

    &__content {
      background-color: ${(props) =>
        props.theme.components.notifications?.tileBgColor ||
        props.theme.colors.secondary};
      border-radius: 6px;
      padding: 10px;
      display: flex;
      min-height: 90px;
      justify-content: space-between;
      flex-grow: 1;
      align-items: flex-start;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        margin-right: 10px;
        border-radius: 5px;
      }

      ${addGlobalSquareCorners()}
    }

    &__top-content {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }

    &__title {
      font-weight: 600;
      margin-bottom: 10px;
    }

    &__text {
      width: calc(100% - 50px);
      color: #000;
      padding-right: 7px;
    }

    &__text-without-image {
      width: 100%;
      color: #000;
    }

    &__time {
      text-align: right;
      color: #000;
      flex-shrink: 0;
    }

    &__bottom-content {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__cta-icon-wrapper {
      width: 7px;
      flex-shrink: 0;
      margin-left: 12px;
      fill: ${(props) =>
        props.theme.components.notifications?.caretColor ||
        props.theme.colors.primary};
      display: flex;
      align-items: center;
      padding-top: 5px;
    }

    &__scroll-container {
      overflow: auto;
      height: calc(100% - 2px);
    }
  }
`;

loadMomentLocales(moment);

const Notifications = () => {
  const history = useHistory();
  const {refreshStats} = useNotificationsStats();
  const {organizationConfig} = useContext(SettingsContext);
  const {hasWallets} = useOrganization();

  const {
    data,
    pagination: notificationsPagination,
    fetchData: fetchMoreNotifications,
  } = useFetcher<Array<Notification>>({
    fetcher: getNotifications,
    key: CACHE_KEYS.NOTIFICATIONS,
    paginate: true,
    limit: 20,
    initialValue: [],
  });

  const notifications = useMemo(() => {
    const notificationIcons: Record<string, string> = {
      recoin: recoinIcon,
      activity: activityIcon,
      challenge: challengeIcon,
      marketplace: marketplaceIcon,
      metering: meteringIcon,
      tree: treeIcon,
      user_referral: referralIcon,
      ...(organizationConfig.theme.components.notifications?.icons || null),
    };

    const notificationRoutes: Record<string, string> = {
      activity: routes.DASHBOARD.CONTRIBUTIONS.WIDGETS.CUSTOM_ACTIVITIES.href,
      badge: routes.DASHBOARD.CONTRIBUTIONS.WIDGETS.BADGES.href,
      challenge: routes.DASHBOARD.COMPETITION.href,
      marketplace: routes.DASHBOARD.REWARDS.href,
      lottery: routes.DASHBOARD.REWARDS.LOTS.href,
      metering: routes.DASHBOARD.CONTRIBUTIONS.WIDGETS.METERING.href,
      recoin: hasWallets
        ? routes.DASHBOARD.WALLET.href
        : routes.DASHBOARD.CONTRIBUTIONS.WIDGETS.CO2_TOKEN.href,
      tree: routes.DASHBOARD.CONTRIBUTIONS.WIDGETS.TREE_PLANTING.href,
    };

    return (
      data?.map((notification) => ({
        ...notification,
        link: notificationRoutes[notification.category],
        image: notification.image || notificationIcons[notification.category],
      })) || []
    );
  }, [organizationConfig, hasWallets, data]);

  useEffect(() => {
    markNotificationsAsSeen().then(refreshStats);
  }, [refreshStats]);

  const handleRefresh = useCallback(() => {
    return fetchMoreNotifications(null, {resetPage: true});
  }, [fetchMoreNotifications]);

  return (
    <Wrapper className="notifications" id="scrollContainer">
      <PullToRefresh onRefresh={handleRefresh}>
        <div className="notifications__scroll-container">
          <InfiniteScroll
            dataLength={notifications.length}
            next={fetchMoreNotifications}
            hasMore={notificationsPagination.hasMore}
            loader={<Loader sm />}
            scrollableTarget="scrollContainer">
            <div>
              {notifications.map(
                ({id, category, date, title, image, link, body}) => (
                  <div
                    className="notifications__item"
                    key={id}
                    id={category}
                    onClick={() => (link ? history.push(link) : undefined)}>
                    <div className="notifications__item-wrapper">
                      <div className="notifications__content">
                        {image && <img src={image} alt={category} />}
                        <div
                          className={classnames('notifications__text', {
                            'notifications__text-without-image': !image,
                          })}>
                          <div className="notifications__top-content">
                            <CopyTextSm className="notifications__title">
                              {title}
                            </CopyTextSm>
                            <CopyTextSm className="notifications__time">
                              {moment(date).startOf('second').fromNow()}
                            </CopyTextSm>
                          </div>
                          <div className="notifications__bottom-content">
                            <CopyTextSm className="">{body}</CopyTextSm>
                            {link && (
                              <div className="notifications__cta-icon-wrapper">
                                <CaretIcon width="100%" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          </InfiniteScroll>
        </div>
      </PullToRefresh>
    </Wrapper>
  );
};

export default Notifications;

import React, {useCallback, useMemo, useState} from 'react';
import chunk from 'lodash/chunk';
import styled from 'styled-components';
import routes from 'config/routes/co2Compensation';

import useClimateSuns, {ClimateMonth, EmptySun} from 'hooks/use-climate-suns';
import {useHistory} from 'react-router-dom';
import {useTranslations} from 'hooks/use-translations';
import {getCurrentMonth} from 'utils/date';

import Loader from 'components/Loader/Loader';
import CopyText from 'components/Text/CopyText/CopyText';
import CompensationPrompt from './CompensationPrompt';
import TextXMedium from 'components/Text/TextXMedium';
import {PurchasedCompensation} from 'types/Compensation';

const Wrapper = styled.div``;

const Title = styled(CopyText)`
  text-align: center;
  display: block;
  margin-bottom: 20px;
`;

const Suns = styled.div`
  border-radius: 10px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Sun = styled.div`
  width: calc(25% - 5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.black};
  font-family: ${(props) => props.theme.font.secondary.name};
`;

const SunIcon = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  svg {
    width: 100%;
  }
`;

const currentMonthKey = getCurrentMonth();

type ClimateCalendarProps = {
  userCompensations: Array<PurchasedCompensation>;
  isLoading: boolean;
};

const ClimateCalendar = (props: ClimateCalendarProps) => {
  const {userCompensations, isLoading} = props;
  const [selectedMonth, setSelectedMonth] = useState<ClimateMonth>();
  const {translate} = useTranslations();
  const history = useHistory();

  const {climateSuns} = useClimateSuns();
  const climateMonths = useMemo(
    () =>
      climateSuns.map((sun) => {
        const compensation = userCompensations.find(
          (compensation: PurchasedCompensation) =>
            compensation.month === sun.monthIndex + 1,
        );

        return {
          ...sun,
          compensation,
          isActive: !!compensation,
        };
      }),
    [climateSuns, userCompensations],
  );

  const splitClimateSuns = useMemo(
    () => chunk(climateMonths, 4),
    [climateMonths],
  );

  const isNeutralForMonth = useMemo(
    () =>
      climateMonths.find(({monthIndex}) => monthIndex === currentMonthKey)
        ?.isActive,
    [climateMonths],
  );

  const handleSunClick = useCallback(
    (climateMonth: ClimateMonth) => {
      if (climateMonth.isActive) {
        setSelectedMonth(climateMonth);
      } else {
        history.push(`${routes.href}/${climateMonth.monthIndex}`);
      }
    },
    [history],
  );

  if (isLoading) {
    return <Loader sm={true} />;
  }

  return (
    <Wrapper>
      <Title>
        {isNeutralForMonth
          ? translate('sdk.web.compensation.widget.neutral')
          : translate('sdk.web.compensation.widget.neutral.cta')}
      </Title>
      <Suns>
        {splitClimateSuns.map((row, index) => (
          <Row key={index}>
            {row.map((climateMonth) => (
              <Sun
                key={climateMonth.monthText}
                onClick={() => handleSunClick(climateMonth)}>
                <SunIcon>
                  {climateMonth.isActive ? (
                    <climateMonth.Icon width="100%" />
                  ) : (
                    <EmptySun />
                  )}
                </SunIcon>
                <TextXMedium>{climateMonth.monthText}</TextXMedium>
              </Sun>
            ))}
          </Row>
        ))}
      </Suns>
      <CompensationPrompt
        isVisible={!!selectedMonth}
        climateMonth={selectedMonth}
        onClose={() => setSelectedMonth(undefined)}
      />
    </Wrapper>
  );
};

export default ClimateCalendar;

import {getNewsFeed} from 'services/agt/news-feed';

import Coin from 'components/ClimateWidgets/Co2Token/AGTCoin/AGTCoin';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/AGTCoin/images/coins/full.svg';

import onboardingOneImg from 'components/Onboarding/images/agt/onboarding-1.svg';
import onboardingTwoImg from 'components/Onboarding/images/agt/onboarding-2.svg';
import onboardingThreeImg from 'components/Onboarding/images/agt/onboarding-3.svg';
import onboardingRedeemImg from 'components/Onboarding/images/agt/onboarding-3.svg';
import onboardingGpsImg from 'components/Onboarding/images/agt/onboarding-gps.svg';
import onboardingMotionImg from 'components/Onboarding/images/agt/onboarding-motion.svg';
import onboardingNotificationsImg from 'components/Onboarding/images/agt/onboarding-notifications.svg';
import onboardingTeamsImg from 'components/Onboarding/images/agt/onboarding-teams.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/agt/onboarding-privacy.svg';

import treePlantingImage from 'components/ClimateWidgets/TreePlanting/images/agt/tree.svg';
import treePlantingGoalImage from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/images/agt/banner.svg';
import treePlantingNewTreeImage from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/images/agt/banner.svg';
import treePlantingHalfwayImage from 'components/ClimateWidgets/TreePlanting/HalfwayTreePrompt/images/agt/banner.svg';

import customActivitiesImg from 'components/ClimateWidgets/Mobility/images/agt/custom-activities.svg';
import {ReactComponent as Co2TokenInfoIcon} from 'components/ClimateWidgets/Co2Token/images/agt/info.svg';

import inviteFriendsSuccessBanner from 'components/ClimateWidgets/InviteFriends/InviteFriendsSuccessPrompt/images/agt/banner.svg';
import {ReactComponent as InviteFriendsInfoIcon} from 'components/ClimateWidgets/InviteFriends/icons/agt/info.svg';

import {ReactComponent as LocationLinkIcon} from 'icons/agt/location-link.svg';

import img0 from 'components/ClimateWidgets/Co2Compensation/images/gallery/agt/0.jpeg';
import img1 from 'components/ClimateWidgets/Co2Compensation/images/gallery/agt/1.jpeg';
import img2 from 'components/ClimateWidgets/Co2Compensation/images/gallery/agt/2.jpeg';
import img3 from 'components/ClimateWidgets/Co2Compensation/images/gallery/agt/3.jpeg';
import buttons from 'config/organizations/preset/buttons';

const config = {
  name: 'africagreentech',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Roboto',
          secondary: 'Roboto',
        },
        ios: {
          primary: 'Roboto',
          secondary: 'Roboto',
        },
      },
      de: {
        android: {
          primary: 'Roboto',
          secondary: 'Roboto',
        },
        ios: {
          primary: 'Roboto',
          secondary: 'Roboto',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#1f4755',
      secondary: '#d0b083',
      'secondary-300': '#e3d5c1',
      'secondary-100': '#fff6ed',
      tertiary: '#fff6ed',
      light: '#fff',
      dark: '#30454A',
      white: '#ffffff',
      black: '#1F4755',
      gray: '#D8D9DA',
      sunDown: '#FFAFAF',
      catSkillWhite: '#EAF5F6',
    },
    components: {
      dashboard: {
        bgColor: 'secondary-300',
      },
      competition: {
        bgColor: 'secondary-300',
      },
      nav: {
        bgColor: 'secondary',
        menuColor: 'white',
        fontWeight: '600',
        routes: ['impact', 'offers', 'news', 'teams', 'more'],
      },
      widgetCard: {
        bgColor: 'light',
        titleBgColor: 'primary',
        hasSquareCorners: true,
      },
      offers: {
        navBgColor: 'secondary-300',
        bodyBgColor: 'secondary-300',
        offerScanBgColor: 'white',
        navColor: 'black',
        activeTabBgColor: 'primary',
        recoinsLeftBgColor: 'light',
        recoinsRequiredBgColor: 'light',
        locationTypeBgColor: 'secondary-100',
        hasSquareCorners: true,
        isDetailsCardTransparent: true,
        climateCoinsFontWeight: '600',
        LocationLinkIcon,
      },
      climatePartnerMap: {
        bgColor: 'secondary-300',
      },
      badges: {},
      treePlanting: {
        treeImage: treePlantingImage,
        goalBanner: treePlantingGoalImage,
        newTreeBanner: treePlantingNewTreeImage,
        halfwayBanner: treePlantingHalfwayImage,
      },
      links: {},
      buttons: {
        ...buttons,
      },
      mobilityIcons: {
        progressPathColor: 'primary',
        walking: {
          bgColor: '#e3d5c1',
        },
        cycling: {
          bgColor: '#e3d5c1',
        },
        publicTransit: {
          bgColor: '#e3d5c1',
        },
        customActivities: {
          bgColor: '#e3d5c1',
          img: customActivitiesImg,
        },
      },
      prompts: {
        bgColor: 'light',
        overlayBgColor: '#00000099',
      },
      customActivities: {
        detailsBgColor: 'secondary',
        listBgColor: 'white',
        successPromptColor: 'white',
      },
      settings: {
        bgColor: 'tertiary',
        itemBorderColor: 'primary',
        itemCaretColor: 'primary',
      },
      co2Compensation: {
        pageBgColor: 'secondary-100',
        emptySunBgColor: 'secondary-100',
        priceBgColor: 'secondary-300',
        buttonBgColor: 'secondary',
        buttonColor: 'primary',
        compensateButtonFontWeight: '500',
        hasSquareCorners: true,
        galleryImage: {
          hasSquareCorners: true,
        },
        galleryImages: [img0, img1, img2, img3],
      },
      leaderboard: {},
      inviteFriends: {
        progressBarBgColor: 'secondary',
        progressBarColor: 'primary',
        successBanner: inviteFriendsSuccessBanner,
        InfoIcon: InviteFriendsInfoIcon,
      },
      vouchers: {
        bgColor: 'white',
        recoinsTagBgColor: 'secondary-100',
        active: {
          activeBgColor: 'secondary',
          pendingBgColor: 'secondary',
          expiredBgColor: 'sunDown',
          progressColor: 'primary',
          progressTrailColor: 'ziggurat',
          headerFontFamily: 'secondary',
        },
        online: {
          codeColor: 'primary',
        },
        wishlistBgColor: '#d0b083',
      },
      news: {
        bgColor: 'tertiary',
        titleBgColor: 'secondary-300',
        titleColor: 'black',
        style: 'comment',
      },
      summaryPage: {},
      form: {
        inputBgColor: 'tertiary',
      },
      co2Token: {
        InfoIcon: Co2TokenInfoIcon,
      },
      onboarding: {
        images: {
          one: {
            image: onboardingOneImg,
          },
          two: {
            image: onboardingTwoImg,
          },
          three: {
            image: onboardingThreeImg,
          },
          privacy: {
            image: onboardingPrivacyImg,
          },
          gps: {
            image: onboardingGpsImg,
          },
          motion: {
            image: onboardingMotionImg,
          },
          teams: {
            image: onboardingTeamsImg,
          },
          notifications: {
            image: onboardingNotificationsImg,
          },
        },
      },
      detailsNavigation: {
        color: 'primary',
        fontWeight: '600',
      },
      infoPage: {
        bgColor: 'tertiary',
      },
    },
    colors: {
      primary: '#1f4755',
      body: '#d0b083',
      secondary: '#fff',
      white: '#ffffff',
      black: '#1F4755',
      promptOverlayBg: '#00000099',
      onboardingBg: '#fff6ed',
      teamRankingHighlight: '#e3d5c1',
    },
  },
  translations: {
    tokenTitle: 'sdk.web.agt.contributions.your.token',
    co2Needed: 'sdk.web.agt.contributions.savings.left.for.token',
    fullTokenText: 'sdk.web.agt.contributions.full.token.text',
    offersTitle: 'sdk.web.agt.offers.your.token',
    shortTokenSingular: 'sdk.web.agt.offers.token.required.singular',
    shortTokenPlural: 'sdk.web.agt.offers.token.required.plural',
    rewardType1Singular:
      'sdk.web.agt.contributions.inviteFriends.rewardType1.singular',
    rewardType1Plural:
      'sdk.web.agt.contributions.inviteFriends.rewardType1.plural',
    notEnoughTokens: 'sdk.web.agt.offers.not.enough.tokens',
    aboutTokenLabel: 'sdk.web.agt.settings.about.title',
    onBoarding: {
      one: {
        title: 'sdk.web.agt.onboarding.one.title',
        content: 'sdk.web.agt.onboarding.one.text',
      },
      two: {
        title: 'sdk.web.agt.onboarding.two.title',
        content: 'sdk.web.agt.onboarding.two.text',
        image: fullTokenImg,
      },
      three: {
        title: 'sdk.web.agt.onboarding.three.title',
        content: 'sdk.web.agt.onboarding.three.text',
        image: onboardingRedeemImg,
      },
      privacy: {
        title: 'sdk.web.onboarding.privacy.title',
        content: 'sdk.web.onboarding.privacy.text',
        confirmLink: 'sdk.web.onboarding.privacy.link',
        image: onboardingPrivacyImg,
      },
      gps: {
        title: 'sdk.web.onboarding.gps.title',
        content: 'sdk.web.onboarding.gps.text',
      },
    },
    competition: {
      title: 'sdk.web.teams.germany.leaderboard.title',
      subtitle: 'sdk.web.teams.germany.leaderboard.subtitle',
    },
    nav: {
      impact: 'sdk.web.agt.nav.your.impact',
      offers: 'sdk.web.agt.nav.offers',
      teams: 'sdk.web.agt.nav.top.teams',
      news: 'sdk.web.agt.nav.news',
      more: 'sdk.web.agt.nav.more',
    },
  },
  services: {
    getNewsFeed,
  },
  features: {
    hideAppClose: true,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCo2Compensation: true,
  },
  applangaTag: '617280c71d20f67dccbfc8a8',
  stripePublicKey: 'pk_test_9HduX1Vcn0IAZrTbSFEbzPGJ004y9AWUki',
  matomoTrackingId: 5,
  tokenType: 'Coin',
  fullTokenImg,
  FullTokenComponent: Coin,
};

export default config;

import styled from 'styled-components';
import {addGlobalBorderColor} from 'utils/theme';

export const InputStyles = styled.input`
  display: block;
  width: 100%;
  line-height: 1.5;
  background-color: ${(props) =>
    props.theme.components.form?.inputBgColor || 'white'};
  color: ${(props) =>
    props.theme.components.form?.inputColor || props.theme.colors.black};
  padding-bottom: 5px;
  padding-left: 0;
  border: none;
  outline: none;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: ${(props) =>
    props.theme.components.form?.borderColor || '#ced4da'};

  ${addGlobalBorderColor()}
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ::placeholder,
  ::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) =>
      props.theme.components.form?.inputColor || props.theme.colors.black};
    opacity: 1; /* Firefox */
  }
`;

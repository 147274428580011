import React, {useMemo} from 'react';
import styled from 'styled-components';

import config from '../config';
import {MAX_COMPARISON} from '../constants';
import useRelativeText from 'hooks/utils/use-relative-text';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useTokenTranslation} from 'hooks/use-token-translation';
import useElementTranslation from '../hooks/use-element-translation';
import useVersionCheck from '../hooks/use-version-check';
import {getDaysLeftInMonth, getMonthTranslationKey} from 'utils/date';
import {addBoldText} from 'utils/theme';
import {formatNumber} from 'utils/numbers';
import {getStatsData} from '../utils';
import {ELEMENT_SCREENS, ElementScreenProps} from '../types';

import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import CopyText from 'components/Text/CopyText/CopyText';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import ProgressIcon from '../components/ProgressIcon';
import AboutElement from './AboutElement';

import {ReactComponent as InfoIcon} from 'icons/info-alt.svg';

const Wrapper = styled(CopyText)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 20px;
  height: 100%;

  .e-summary {
    &__heading {
      margin-bottom: 25px;
    }

    &__title {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &__info-icon {
      margin-left: 10px;
      width: 24px;
      display: flex;
      fill: ${(props) =>
        props.theme.components.metering?.infoIconColor ||
        props.theme.colors.primary};
    }

    &__progress {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__consumption {
      margin-bottom: 20px;
    }

    &__left {
      margin-bottom: 25px;
    }

    &__info {
      margin-bottom: 10px;
    }

    &__bold {
      ${(props) =>
        addBoldText({
          props,
        })}
    }

    &__cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__btn {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;

const Summary = (props: ElementScreenProps) => {
  const {element, elementData, stats, onClose, onComplete} = props;
  const translate = useElementTranslation({element});
  const {checkVersion, VersionPrompt} = useVersionCheck();
  const getRelativeText = useRelativeText({translate});
  const {getTokenText} = useTokenTranslation();
  const [isAboutElementVbl, showAboutElement, hideAboutElement] =
    useBoolean(false);

  const {
    consumption,
    progressPercent,
    overBudget,
    comparison,
    left,
    projection,
    info,
  } = useMemo(() => {
    if (!stats || typeof stats?.current_consumption === 'undefined') {
      return {};
    }

    const {overBudget, progressPercent} = getStatsData({
      stats,
    });
    const currentMonth = translate(getMonthTranslationKey());

    return {
      progressPercent,
      overBudget,

      consumption: translate(
        'sdk.web.meter.summary.consumption',
        [
          {
            key: '{month}',
            value: currentMonth,
          },
          {
            key: '{budget}',
            value: formatNumber({
              number: stats.goal,
              dp: 1,
              ignoreThousandRule: true,
            }),
          },
          {
            key: '{used}',
            value: formatNumber({
              number: stats.current_consumption,
              dp: 1,
              ignoreThousandRule: true,
            }),
          },
        ],
        {capitalizeElement: true},
      ),

      left: translate('sdk.web.meter.summary.left', [
        {
          key: '{days}',
          value: formatNumber({
            number: getDaysLeftInMonth(),
            dp: 0,
          }),
        },
        {
          key: '{amount}',
          value: formatNumber({
            number: Math.max(stats.goal - stats.current_consumption, 0),
            dp: 1,
            ignoreThousandRule: true,
          }),
        },
      ]),

      projection: {
        __html: translate('sdk.web.meter.summary.projection', {
          key: '{token}',
          value: getTokenText(2),
        }),
      },

      info: translate(
        stats.projected_savings_percentage < 0
          ? 'sdk.web.meter.summary.projection.above'
          : 'sdk.web.meter.summary.projection.below',
        [
          {
            key: '{percent}',
            value: formatNumber({
              number: Math.abs(stats.projected_savings_percentage),
              dp: 0,
            }),
          },
          {
            key: '{month}',
            value: currentMonth,
          },
          {
            key: '{token}',
            value: getTokenText(stats.projected_recoins),
          },
          {
            key: '{nr}',
            value: formatNumber({
              number: stats.projected_recoins,
            }),
          },
        ],
      ),
      comparison: config.hideComparison
        ? ''
        : getRelativeText({
            key: 'sdk.web.meter.summary.comparison',
            percent: stats.relative_performance,
            max: MAX_COMPARISON,
          }),
    };
  }, [stats, translate, getRelativeText, getTokenText]);

  if (!stats || !consumption) {
    return null;
  }

  return (
    <Wrapper as="div">
      <div>
        <Title as="div" className="e-summary__heading">
          <div className="e-summary__title">
            <span>{translate('sdk.web.meter.summary.title')}</span>
            <span className="e-summary__info-icon" onClick={showAboutElement}>
              <InfoIcon width="100%" />
            </span>
          </div>
        </Title>
        <div className="e-summary__consumption">
          <span dangerouslySetInnerHTML={{__html: consumption}} />
        </div>
        <div className="e-summary__info">
          <div className="e-summary__progress">
            <ProgressIcon
              lg
              percent={progressPercent}
              icon={elementData.summaryIcon || elementData.icon}
              alt="light bulb"
              danger={overBudget}
              pathColor={elementData.progressPathColor}
            />
          </div>
        </div>
        {stats.current_consumption ? (
          <>
            <div className="e-summary__left">
              <span dangerouslySetInnerHTML={{__html: left}} />
            </div>
            <div className="e-summary__info">
              <span dangerouslySetInnerHTML={{__html: info}} />
            </div>
            <div dangerouslySetInnerHTML={{__html: comparison || ''}} />
          </>
        ) : (
          <div
            className="e-summary__text"
            dangerouslySetInnerHTML={projection}
          />
        )}
      </div>
      <div className="e-summary__cta">
        <div className="e-summary__btn">
          <Button
            onClick={() =>
              checkVersion({
                onSuccess() {
                  onComplete({
                    nextScreen: ELEMENT_SCREENS.CONSUMPTION,
                  });
                },
              })
            }>
            {translate('sdk.web.meter.summary.update')}
          </Button>
        </div>
        {stats.has_previous_month_consumption && (
          <CtaLink
            onClick={() =>
              onComplete({nextScreen: ELEMENT_SCREENS.PREVIOUS_RESULTS})
            }>
            {translate('sdk.web.meter.summary.prev.results')}
          </CtaLink>
        )}
        <CtaLink
          onClick={() => onComplete({nextScreen: ELEMENT_SCREENS.SETTINGS})}>
          {translate('sdk.web.meter.summary.settings')}
        </CtaLink>
      </div>
      <AboutElement
        isVisible={isAboutElementVbl}
        element={element}
        onClose={hideAboutElement}
      />
      <VersionPrompt onClose={onClose} />
    </Wrapper>
  );
};

export default Summary;

import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import useAllowScale from 'hooks/app/use-allow-scale';
import {useFetcher} from 'hooks/use-fetcher';
import {getOrganizationInfo} from 'services/user';

import InfoPage from 'components/InfoPage/InfoPage';

const FaqPage = () => {
  useAllowScale();
  const history = useHistory();
  const {translate} = useTranslations();
  const {appName} = useContext(SettingsContext);

  const {
    isLoading,
    data: organizationData,
  }: {isLoading: boolean; data: any} = useFetcher({
    fetcher: getOrganizationInfo,
    key: 'organizationInfo',
  });

  const oldFaq = (organizationData?.data?.faq || '').replace(
    /{Name Host-App}/g,
    appName,
  );
  const content = [<div dangerouslySetInnerHTML={{__html: oldFaq}} />];

  const onClose = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.SETTINGS.href);
    }
  };

  return (
    <InfoPage
      title={translate('sdk.web.settings.faq.title')}
      rows={content}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default FaqPage;

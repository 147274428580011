import React from 'react';
import styled from 'styled-components';

import {defaultEmissions} from './config';
import {useTranslations} from 'hooks/use-translations';
import {formatNumber} from 'utils/numbers';

import CopyText from 'components/Text/CopyText/CopyText';

const Wrapper = styled(CopyText)`
  text-align: center;

  .mobility-header {
    &__img {
      display: inline-block;
      width: 100%;
      margin-bottom: 12px;
    }

    &__emissions {
      background-color: #ebf5f6;
      border-radius: 5px;
      padding: 14px;
    }
  }
`;

interface HeaderProps {
  subtitle: string;
  image?: string;

  emissions?: number;
}

const Header = (props: HeaderProps) => {
  const {subtitle, image, emissions} = props;
  const {translate} = useTranslations();

  return (
    <Wrapper as="div" className="mobility-header mb-base">
      <div className="mb-base">{subtitle}</div>
      {image && (
        <img src={image} alt="vehicle" className="mobility-header__img" />
      )}
      {typeof emissions === 'number' && (
        <div
          className="mobility-header__emissions"
          dangerouslySetInnerHTML={{
            __html: translate('sdk.web.settings.mobility.emissions', [
              {
                key: '{amount}',
                value: formatNumber({
                  number: emissions,
                  dp: 1,
                }),
              },
              {
                key: '{unit}',
                value: defaultEmissions.unit,
              },
            ]),
          }}
        />
      )}
    </Wrapper>
  );
};

export default Header;

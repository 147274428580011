export const BREAKPOINTS = {
  HEIGHT: {
    sm: '568px',
    md: '668px',
    lg: '738px',
  },
  WIDTH: {
    sm: '320px',
    md: '375px',
    lg: '414px',
  },
};

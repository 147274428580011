import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import routes from 'config/routes';

import Modal from 'components/Modal/Modal';
import OngoingLot from 'components/Rewards/Lots/OngoingLot/OngoingLot';
import LotSuccess from 'components/Rewards/Lots/LotResults/LotSuccess/LotSuccess';
import LotLoss from 'components/Rewards/Lots/LotResults/LotLoss/LotLoss';
import Loader from 'components/Loader/Loader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.white};
`;

const getContent = (lot: any, onClose: (e: any) => void) => {
  switch (lot.status) {
    case 'ongoing':
      return <OngoingLot lot={lot} onClose={onClose} />;
    case 'won':
      return <LotSuccess lot={lot.lots[0]} onClose={onClose} />;
    case 'lost':
      return <LotLoss lot={lot} onClose={onClose} />;
    default:
      return <OngoingLot lot={lot} onClose={onClose} />;
  }
};

const LotDetailsPage = (props: any) => {
  const {
    lots,
    match,
    isLoading,
  }: {lots: Array<any>; match: any; isLoading: boolean} = props;
  const {id} = match.params;
  const lot = lots.find((lot) => lot.lottery.id.toString() === id.toString());

  const onClose = (e: any) => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.DASHBOARD.REWARDS.LOTS.href);
    }
  };

  return (
    <React.Fragment>
      <Wrapper>
        <Modal>
          <Content>
            {!!lot && getContent(lot, onClose)}
            {isLoading && <Loader color={'#000'} />}
          </Content>
        </Modal>
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(LotDetailsPage);

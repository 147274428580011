import React from 'react';
import styled from 'styled-components';

import {QrComponentProps, withQrScanner} from 'components/QrScanner';

import Loader from 'components/Loader/Loader';
import Icon from 'components/Rewards/Header/Icon';

import {ReactComponent as QrIcon} from 'icons/qr-code.svg';

const Wrapper = styled.div`
  display: flex;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  align-items: center;
`;

const OffersScanner = (props: QrComponentProps) => {
  const {openScanner, startListening, isLoading} = props;

  return (
    <Wrapper>
      {isLoading ? (
        <Loader sm={true} width={25} height={25} />
      ) : (
        <Icon
          Icon={QrIcon}
          onClick={() => {
            startListening();
            openScanner();
          }}
        />
      )}
    </Wrapper>
  );
};

export default withQrScanner(OffersScanner);

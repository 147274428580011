import React from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';

import Tag from './Tag';

const Wrapper = styled(Tag)<{isMax: boolean; bgColor?: string}>`
  background-color: ${(props) =>
    props.bgColor ||
    (props.isMax
      ? props.theme.components.offers.recoinsLeftBgColor ||
        props.theme.colors.secondary
      : props.theme.components.offers.lessRecoinsLeftBgColor || '#FCCD61')};

  ${(props) =>
    props.theme.components.offers.recoinsLeftColor
      ? `color: ${
          props.isMax
            ? props.theme.components.offers.recoinsLeftColor
            : props.theme.components.offers.lessRecoinsLeftColor
        }`
      : ''}
`;

interface RecoinsLeftProps {
  offersCount?: number;
  bgColor?: string;
}

const RecoinsLeft = (props: RecoinsLeftProps) => {
  const {offersCount, bgColor} = props;
  const {translate} = useTranslations();

  const moreThanTenLeft = !!offersCount && offersCount > 10;
  const offersCountText = moreThanTenLeft ? '10+' : offersCount;

  return (
    <>
      {!!offersCount && (
        <Wrapper isMax={moreThanTenLeft} bgColor={bgColor}>
          {translate('sdk.web.offers.left', {
            key: '{nr}',
            value: offersCountText,
          })}
        </Wrapper>
      )}
    </>
  );
};

export default RecoinsLeft;

import {fetchAPiClient} from './api';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

export interface Prompt {
  id: number;
  type: string;
  data: {
    reward_quantity: number;
    invited_users: number;
    users_needed: number;
    reward_type_id: number;
    co2_per_recoin: number;
    user_trees: string;
    rankings: {
      user: {
        position: number;
      };
      teams: [
        {
          position: number;
        },
      ];
    };
    tree_planting: {
      amount: number;
    };
  };
}

export const getPrompts = async (): Promise<Prompt[]> => {
  const client = defaultApiClient;
  const url = '/prompt';
  const {data} = await client.get(url);
  return data;
};

export const hidePrompt = ({id}: {id: number}) => {
  const client = defaultApiClient;
  const url = `/prompt/${id}/seen`;
  return client.post(url);
};

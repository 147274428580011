import {useContext, useMemo} from 'react';
import {usePermissionsCheck} from 'hooks/use-permissions-check';
import {SettingsContext} from 'contexts/SettingsContext';
import {useOrganization} from 'hooks/use-organization';

const useNotifications = () => {
  const {isNotificationEnabled} = useContext(SettingsContext);
  const {hasNotifications: hasPushNotifications} = usePermissionsCheck();
  const {hasNotifications} = useOrganization();

  const showNotifications = useMemo(() => {
    return hasNotifications && hasPushNotifications && isNotificationEnabled;
  }, [hasNotifications, hasPushNotifications, isNotificationEnabled]);

  return {showNotifications};
};

export default useNotifications;

import React, {useRef} from 'react';
import {Route, Switch} from 'react-router-dom';
import routes from 'config/routes/customActivities';
import {useScrollToTop} from 'hooks/ui/use-scroll-to-top';
import CustomActivitiesPage from './CustomActivitiesPage';

const CustomActivities = () => {
  const wrapperRef = useRef(null);
  useScrollToTop({wrapperRef});

  return (
    <div ref={wrapperRef}>
      <Switch>
        <Route path={`${routes.href}/:category`}>
          <CustomActivitiesPage />
        </Route>
      </Switch>
    </div>
  );
};

export default CustomActivities;

import React, {useRef} from 'react';
import {Route, Switch} from 'react-router-dom';
import routes from 'config/routes/water';
import {useScrollToTop} from 'hooks/ui/use-scroll-to-top';
import WaterPage from './WaterPage';
import AboutPage from './AboutPage';
import HowToPage from './HowToPage';
import TipsPage from './TipsPage';

const Water = () => {
  const wrapperRef = useRef(null);
  useScrollToTop({wrapperRef});

  return (
    <div ref={wrapperRef}>
      <Switch>
        <Route path={routes.ABOUT.href}>
          <AboutPage />
        </Route>
        <Route path={routes.HOW_TO.href}>
          <HowToPage />
        </Route>
        <Route path={routes.TIPS.href}>
          <TipsPage />
        </Route>
        <Route>
          <WaterPage />
        </Route>
      </Switch>
    </div>
  );
};

export default Water;

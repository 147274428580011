import React, {useMemo} from 'react';
import styled from 'styled-components';

import {CHALLENGE_TYPES, CHALLENGE_TYPES_UNITS} from 'constants/challenges';
import useUnit from 'hooks/utils/use-unit';
import {useOrganization} from 'hooks/use-organization';
import {formatNumber} from 'utils/numbers';
import {addGlobalBorderColor} from 'utils/theme';
import {Team} from 'types/Team';

import CopyText from 'components/Text/CopyText/CopyText';
import goldImg from '../images/gold.svg';
import silverImg from '../images/silver.svg';
import bronzeImg from '../images/bronze.svg';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 15%;
  text-align: left;
`;

export const Row = styled.li<{isHighlighted?: boolean}>`
  display: flex;
  padding: 5px 1px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isHighlighted
      ? props.theme.colors.teamRankingHighlight
      : 'transparent'};
  border-bottom: 0.5px solid
    ${(props) =>
      props.theme.components.leaderboard.rankingBorderColor || '#015b7a'};

  ${addGlobalBorderColor()}
`;

const Position = styled(CopyText)`
  display: block;
  width: 30px;
`;

export const TeamName = styled(CopyText)`
  display: block;
  flex: 1;
  padding-right: 15px;
`;

export const Co2Savings = styled(CopyText)`
  display: block;
  color: ${(props) =>
    props.theme.components.leaderboard.rankingSavingsColor || '#00930E'};
`;

export const Icon = styled.img`
  display: inline-block;
  height: 24px;
`;

interface TeamRankingProps {
  teams: Team[];
  isOwnTeam: ({team}: {team: Team}) => boolean;
  challengeType: CHALLENGE_TYPES;
}

const TeamRanking = (props: TeamRankingProps) => {
  const {isOwnTeam, teams, challengeType} = props;
  const {getUnit} = useUnit();

  const {organizationConfig} = useOrganization();
  const leaderboardConfig = organizationConfig.theme.components.leaderboard;

  const allTeams = useMemo(
    () =>
      (teams || []).map((team: Team, index: number) => {
        let challengeTypeData = CHALLENGE_TYPES_UNITS[CHALLENGE_TYPES.CO2];

        if (CHALLENGE_TYPES_UNITS[challengeType])
          challengeTypeData = CHALLENGE_TYPES_UNITS[challengeType];

        const field = challengeTypeData.field;

        const fieldValue = team[field as keyof typeof team];

        const value =
          ((fieldValue as number) || team.co2_avoided || 0) /
          challengeTypeData.denominator;

        const unit = getUnit({
          unitType: challengeTypeData.unit,
          value,
        });

        let positionLabel = <span>{team.position}.</span>;
        switch (index) {
          case 0:
            positionLabel = (
              <Icon src={leaderboardConfig?.goldImg || goldImg} alt={''} />
            );
            break;
          case 1:
            positionLabel = (
              <Icon src={leaderboardConfig?.silverImg || silverImg} alt={''} />
            );
            break;
          case 2:
            positionLabel = (
              <Icon src={leaderboardConfig?.bronzeImg || bronzeImg} alt={''} />
            );
            break;
        }

        return {
          ...team,
          unit,
          positionLabel,
          value: formatNumber({number: value, dp: 0}),
          isOwn: isOwnTeam({team}),
        };
      }),
    [teams, leaderboardConfig, challengeType, getUnit, isOwnTeam],
  );

  return (
    <div>
      <List>
        {allTeams.map((team) => (
          <Row key={team.id} isHighlighted={team.isOwn}>
            <Position>{team.positionLabel}</Position>
            <TeamName>{team.name}</TeamName>
            <Co2Savings>
              {team.value} {team.unit}
            </Co2Savings>
          </Row>
        ))}
      </List>
    </div>
  );
};

export default TeamRanking;

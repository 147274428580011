import React, {useCallback, useContext, useMemo, useState} from 'react';

import {joinTeam, leaveTeam} from 'services/teams';
import {useTranslations} from 'hooks/use-translations';
import {ActivityContext} from 'contexts/ActivityContext';

import ErrorDialog from '../ErrorDialog';
import {Team} from 'types/Team';

type useTeamsResponse = {
  title: string;
  content: string;
  selectedTeam?: Team;
  currentTeam?: Team;
  teams: Array<Team>;
  isSubmitting: boolean;
  handleTeamChange: (team: Team) => void;
  handleJoinTeam: (team?: Team) => Promise<boolean>;
  handleLeaveTeam: (id: number) => Promise<boolean>;
  ErrorBlock: () => JSX.Element;
};

interface useTeamsProps {
  teamTypeId: number;
  teams: Array<Team>;
  selectedTeams?: Record<number, Partial<Team>>;
}

export const useTeams = (props: useTeamsProps): useTeamsResponse => {
  const {teamTypeId, teams} = props;
  const {translate} = useTranslations();

  const {selectedTeams, updateSelectedTeams} = useContext(ActivityContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team>(
    selectedTeams![teamTypeId] as Team,
  );
  const [errorMessage, setErrorMessage] = useState('');

  const title = translate('sdk.web.onboarding.teams.title');
  const content = translate('sdk.web.onboarding.teams.text');

  const currentTeamType = selectedTeams![teamTypeId];
  const currentTeam = useMemo(
    () => (currentTeamType?.id ? (currentTeamType as Team) : undefined),
    [currentTeamType],
  );

  const handleTeamChange = useCallback((team: Team) => {
    setSelectedTeam(team);
  }, []);

  const handleJoinTeam = useCallback(
    async (team?: Team) => {
      const teamToJoin = team || selectedTeam;

      if (!teamToJoin || !teamToJoin.id) {
        setErrorMessage(translate('sdk.web.onboarding.error.text'));
        return false;
      }

      setIsSubmitting(true);

      try {
        await joinTeam({
          teamId: teamToJoin.id,
          passphrase: teamToJoin.passphrase,
        });
        setIsSubmitting(false);

        const updatedTeam = {
          ...teamToJoin,
        };
        delete updatedTeam.users;
        updateSelectedTeams!({teamTypeId: teamTypeId, team: updatedTeam});

        return true;
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = errorKey
          ? translate(errorKey)
          : translate('sdk.web.onboarding.teams.error.fallback');
        setErrorMessage(message);
        setIsSubmitting(false);

        return false;
      }
    },
    [selectedTeam, teamTypeId, updateSelectedTeams, translate],
  );

  const handleLeaveTeam = useCallback(
    async (id: number) => {
      try {
        await leaveTeam(id);
        updateSelectedTeams!({teamTypeId});

        return true;
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = errorKey
          ? translate(errorKey)
          : translate('sdk.web.teams.leave.error.fallback');
        setErrorMessage(message);
        setIsSubmitting(false);

        return false;
      }
    },
    [teamTypeId, updateSelectedTeams, translate],
  );

  const handleDialogBoxConfirmation = () => {
    setErrorMessage('');
  };

  const ErrorBlock = useCallback(
    () =>
      !!errorMessage ? (
        <ErrorDialog
          message={errorMessage}
          onConfirmation={handleDialogBoxConfirmation}
        />
      ) : (
        <React.Fragment />
      ),
    [errorMessage],
  );

  return {
    title,
    content,
    teams,
    selectedTeam,
    currentTeam,
    isSubmitting,
    handleTeamChange,
    handleJoinTeam,
    handleLeaveTeam,
    ErrorBlock,
  };
};

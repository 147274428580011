import {useTranslations} from './use-translations';
import {useCallback, useContext, useMemo} from 'react';
import {SettingsContext} from 'contexts/SettingsContext';

export const useTokenTranslation = () => {
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);

  const tokenAbbreviation = useMemo(
    () => translate(organizationConfig.translations.tokenAbbreviation),
    [translate, organizationConfig],
  );

  const getShortTokenText = useCallback(
    (recoins: number = 0) =>
      translate(
        recoins > 1 || recoins === 0
          ? organizationConfig.translations.shortTokenPlural
          : organizationConfig.translations.shortTokenSingular,
      ),
    [organizationConfig, translate],
  );

  const getTokenText = useCallback(
    (recoins?: number) =>
      translate(
        !recoins || recoins > 1 || recoins === 0
          ? organizationConfig.translations.tokenFullPlural
          : organizationConfig.translations.tokenFullSingular,
      ),
    [organizationConfig, translate],
  );

  return {
    getTokenText,
    getShortTokenText,
    tokenAbbreviation,
  };
};

import contributions from './contributions';
import rewards from './rewards';
import competition from './competition';
import settings from './settings';
import onboarding from './onboarding';
import co2Compensation from './co2Compensation';
import customActivities from './customActivities';
import water from './water';
import co2Emissions from './co2Emissions';
import newsFeed from './newsFeed';
import dresden from './dresden';
import notifications from './notifications';
import climateTreasure from './climateTreasure';

const routes = {
  DASHBOARD: {
    href: '/dashboard',
    NOTIFICATIONS: {
      href: '/dashboard/notifications',
    },
    WALLET: {
      href: '/dashboard/wallet',
    },
    WATER_WIDGET: {
      href: '/dashboard/contributions',
    },
    ...contributions,
    ...rewards,
    ...competition,
    ...dresden,
    ...newsFeed,
  },
  SETTINGS: settings,
  CO2_COMPENSATION: co2Compensation,
  CUSTOM_ACTIVITIES: customActivities,
  WATER: water,
  CO2_EMISSIONS: co2Emissions,
  ONBOARDING: onboarding,
  NOTIFICATIONS: notifications,
  CLIMATE_TREASURE: climateTreasure,
};

export default routes;

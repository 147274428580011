import React, {useContext, useMemo} from 'react';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import Countdown, {zeroPad} from 'react-countdown';
import styled from 'styled-components';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

import routes from 'config/routes/rewards';
import {BREAKPOINTS} from 'constants/breakpoints';
import {SettingsContext} from 'contexts/SettingsContext';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import {addBoldText} from 'utils/theme';

import {PurchasedVoucher, Voucher} from 'types/Offers';
import PartnerPrompt from './PartnerPrompt';
import {ReactComponent as CheckIconLg} from 'icons/check-lg.svg';
import {ReactComponent as TimesIconLg} from 'icons/times-lg.svg';

const ACTIVE_DURATION = 5 * 60 * 1000;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 139px;
  height: 139px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 100%;

  @media only screen and (max-height: ${BREAKPOINTS.HEIGHT.md}) {
    width: 120px;
    height: 120px;
  }

  @media only screen and (max-height: ${BREAKPOINTS.HEIGHT.sm}) {
    width: 100px;
    height: 100px;
  }
`;

const Icon = styled.div<{color: string}>`
  width: 80px;
  padding: 10px 0;
  fill: ${(props) => props.color};

  @media only screen and (max-height: ${BREAKPOINTS.HEIGHT.md}) {
    width: 70px;
  }

  @media only screen and (max-height: ${BREAKPOINTS.HEIGHT.sm}) {
    width: 60px;
  }
`;

const DateText = styled.div`
  margin-bottom: 5px;
  display: block;
  font-size: ${(props) =>
    props.theme.components.vouchers?.active?.dateFontSize || 50}px;
  line-height: 60px;

  @media only screen and (max-height: ${BREAKPOINTS.HEIGHT.lg}) {
    font-size: 40px;
    line-height: 44px;
  }

  @media only screen and (max-height: ${BREAKPOINTS.HEIGHT.md}) {
    font-size: 36px;
    line-height: 42px;
  }

  @media only screen and (max-height: ${BREAKPOINTS.HEIGHT.sm}) {
    font-size: 28px;
    line-height: 30px;
  }

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const TimerWrapper = styled.div`
  margin-bottom: 10px;
`;

const TimerText = styled.div`
  display: block;
  font-size: 30px;
  line-height: 30px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

type ActiveVoucherProps = {
  voucher: Voucher;
  purchasedVoucher?: PurchasedVoucher;
  onClose?: () => void;
};

const ActiveVoucher = (props: ActiveVoucherProps) => {
  const {voucher, purchasedVoucher} = props;
  const history = useHistory();
  const {organizationConfig} = useContext(SettingsContext);
  const {translate} = useTranslations();
  const activeVoucherColors =
    organizationConfig.theme.components.vouchers?.active;

  const isVoucherExpired =
    !!purchasedVoucher &&
    moment().isAfter(moment(purchasedVoucher.purchased_at).add(5, 'minutes'));
  const [isTimerComplete, stopTimer] = useBoolean(isVoucherExpired);

  const voucherDate = purchasedVoucher?.purchased_at
    ? new Date(purchasedVoucher?.purchased_at)
    : new Date();
  const countdownDate = purchasedVoucher
    ? moment(purchasedVoucher.purchased_at).add(5, 'minutes').valueOf()
    : Date.now() + ACTIVE_DURATION;

  const dateLanguage = navigator.language || 'en-US';
  const {voucherColor, progressTrailColor} = useMemo(
    () => ({
      voucherColor: isTimerComplete
        ? '#b71e3f'
        : activeVoucherColors?.progressColor || '#c8d85c99',
      progressTrailColor: activeVoucherColors?.progressTrailColor || '#bfdee2',
    }),
    [isTimerComplete, activeVoucherColors],
  );

  const handleComplete = () => {
    stopTimer();
  };

  const handleClose = () => {
    history.push(routes.REWARDS.VOUCHERS.href);
  };

  return (
    <PartnerPrompt
      voucher={voucher}
      status={isVoucherExpired ? 'expired' : 'active'}
      onClose={handleClose}>
      <Wrapper>
        <IconWrapper>
          <Countdown
            date={countdownDate}
            intervalDelay={300}
            precision={3}
            onComplete={handleComplete}
            renderer={({total}) => (
              <CircularProgressbarWithChildren
                value={(total / (5 * 1000 * 60)) * 100 || 100}
                strokeWidth={7}
                styles={buildStyles({
                  pathColor: voucherColor,
                  trailColor: progressTrailColor,
                  strokeLinecap: 'round',
                })}>
                <Icon color={voucherColor}>
                  {isVoucherExpired ? <TimesIconLg /> : <CheckIconLg />}
                </Icon>
              </CircularProgressbarWithChildren>
            )}
          />
        </IconWrapper>
        <TimerWrapper>
          {!isTimerComplete ? (
            <Countdown
              date={countdownDate}
              intervalDelay={0}
              precision={3}
              onComplete={handleComplete}
              renderer={({minutes, seconds}) => (
                <TimerText>
                  {translate('sdk.web.vouchers.expires.in')} {zeroPad(minutes)}:
                  {zeroPad(seconds)}
                </TimerText>
              )}
            />
          ) : (
            <TimerText>{translate('sdk.web.vouchers.expired')}</TimerText>
          )}
        </TimerWrapper>
        <div>
          <DateText>
            {voucherDate.toLocaleDateString(dateLanguage, {
              day: 'numeric',
              month: 'short',
              weekday: 'short',
            })}
            <br />
            {voucherDate.toLocaleTimeString(dateLanguage, {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </DateText>
        </div>
      </Wrapper>
    </PartnerPrompt>
  );
};

export default ActiveVoucher;

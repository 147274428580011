import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import routes from 'config/routes/customActivities';
import {WidgetProps} from 'hooks/services/use-custom-activity';
import {addFontFamily, addGlobalSquareCorners} from 'utils/theme';

import TextXMedium from 'components/Text/TextXMedium';
import {ReactComponent as CaretIcon} from 'components/CustomActivities/icons/right-arrow.svg';
import {CustomActivity} from 'types/CustomActivity';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ItemTitle = styled(TextXMedium)`
  font-family: ${(props) => props.theme.font.secondary.name};
  margin-bottom: 5px;
  display: block;
  color: ${(props) =>
    props.theme.components.customActivities.titleColor || 'inherit'};

  ${(props) =>
    addFontFamily({
      props,
      value: props.theme.components.customActivities?.listTitleFontFamily,
    })}
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled(Link)<{bgImage: string}>`
  background-image: url('${(props) => props.bgImage}');
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  border-radius: 5px;
  padding-right: 6px;
  height: 60px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  ${addGlobalSquareCorners()}
`;

const CaretIconWrapper = styled.div`
  width: 40px;
  fill: ${(props) => props.theme.colors.white};
  display: ${(props) =>
    props.theme.components.customActivities.hideCaret ? 'none' : 'flex'};
  align-items: center;
`;

const CategoryWidget = ({activities}: WidgetProps) => (
  <List>
    {activities.map((activity: CustomActivity) => (
      <Item key={activity.id || activity.categoryId}>
        <ItemTitle>{activity.categoryName}</ItemTitle>
        <ItemWrapper>
          <Content
            bgImage={`${activity.categoryImage}?v=1`}
            to={`${routes.href}/${activity.categoryId}?categoryName=${activity.categoryName}`}>
            <CaretIconWrapper>
              <CaretIcon width="100%" />
            </CaretIconWrapper>
          </Content>
        </ItemWrapper>
      </Item>
    ))}
  </List>
);

export default CategoryWidget;

import React, {useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';
import omit from 'lodash/omit';

import routes from 'config/routes';
import useAuthCheck from 'hooks/auth/use-auth-check';
import {useUserProfile} from 'hooks/use-user-profile';
import {useTranslations} from 'hooks/use-translations';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import {useForm} from 'hooks/forms/use-form';
import {signup} from 'services/account';

import Input from 'components/Form/Input';
import FormButton from 'components/Form/FormButton';
import FormRow from 'components/Form/FormRow';
import InfoPage from 'components/InfoPage/InfoPage';
import CtaLink from 'components/CtaLink/CtaLink';
import AccountTitle from 'components/Account/AccountTitle';
import AccountSubtitle from 'components/Account/AccountSubtitle';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import AccountForm from 'components/Account/AccountForm';
import AccountFooter from 'components/Account/AccountFooter';
import AccountButtonWrapper from 'components/Account/AccountButtonWrapper';
import {useAddNext, useGetNext} from 'hooks/utils/use-links';

const InfoRow = styled.div`
  margin: 20px 0;
`;

type RegistrationData = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const RegisterPage = () => {
  useAuthCheck();
  const {next} = useGetNext();
  const loginUrl = useAddNext({
    url: routes.SETTINGS.LOGIN.href,
    next,
  });
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {translate} = useTranslations();
  const {userProfile, reloadUserProfile} = useUserProfile();
  const {apiErrors, setApiErrors, validate} = useFormValidation();
  const {getKeyDownListener} = useForm();
  const toast = useToast();

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .required(translate('sdk.web.validations.firstName.required'))
      .max(255, translate('sdk.web.validations.text.max.characters')),
    lastname: yup
      .string()
      .required(translate('sdk.web.validations.lastName.required'))
      .max(255, translate('sdk.web.validations.text.max.characters')),
    email: yup
      .string()
      .required(translate('sdk.web.validations.email.required'))
      .email(translate('sdk.web.contact.form.email.error.invalid')),
    password: yup
      .string()
      .required(translate('sdk.web.validations.password.required'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        translate('sdk.web.validations.password.invalid'),
      )
      .min(8, translate('sdk.web.validations.password.short')),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), undefined],
        translate('sdk.web.validations.password.match'),
      )
      .required(translate('sdk.web.validations.confirmPassword.required')),
  });

  const onClose = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(next || routes.SETTINGS.href);
    }
  };

  const onSubmit = async (registrationData: RegistrationData) => {
    setIsSubmitting(true);
    const data = {
      account_type: 1,
      ...omit(registrationData, 'confirmPassword'),
    };

    try {
      await signup({id: userProfile?.user.id as number, data});
      onSignup();
    } catch (e:any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const fieldErrorKeys = e.response
        ? e.response?.data?.fieldErrorKeys
        : undefined;
      const message = translate(
        errorKey || 'sdk.web.account.register.error.fallback',
      );
      toast.error(message);
      setIsSubmitting(false);

      if (fieldErrorKeys?.email?.includes('error.api.UNIQUE_PARAMETER')) {
        fieldErrorKeys.email[
          fieldErrorKeys.email.indexOf('error.api.UNIQUE_PARAMETER')
        ] = 'sdk.web.account.register.error.unique';
      }

      setApiErrors(fieldErrorKeys);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    validate,
  });

  const onSignup = async () => {
    await reloadUserProfile();
    setIsSubmitting(false);
    formik.resetForm();

    if (next) {
      history.replace(next);
    } else {
      history.push(routes.SETTINGS.ACCOUNT.href);
    }
  };

  return (
    <InfoPage
      title={translate('sdk.web.account.profile.title')}
      onClose={onClose}>
      <AccountForm onSubmit={formik.handleSubmit} ref={formRef}>
        <div>
          <AccountTitle>
            {translate('sdk.web.account.register.title')}
          </AccountTitle>
          <AccountSubtitle>
            {translate('sdk.web.account.register.message')}
          </AccountSubtitle>
          <FormRow>
            <Input
              placeholder={translate('sdk.web.inputs.firstName.label')}
              name={'firstname'}
              type={'text'}
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.firstname}
              onChange={formik.handleChange}
              enterkeyhint={'next'}
              onKeyDown={getKeyDownListener({form: formRef.current})}
            />
          </FormRow>
          <FormRow>
            <Input
              placeholder={translate('sdk.web.inputs.lastName.label')}
              name={'lastname'}
              type={'text'}
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.lastname}
              onChange={formik.handleChange}
              enterkeyhint={'next'}
              onKeyDown={getKeyDownListener({form: formRef.current})}
            />
          </FormRow>
          <FormRow>
            <Input
              placeholder={translate('sdk.web.inputs.email.label')}
              name={'email'}
              type={'email'}
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.email}
              onChange={formik.handleChange}
              enterkeyhint={'next'}
              onKeyDown={getKeyDownListener({form: formRef.current})}
            />
          </FormRow>
          <FormRow>
            <Input
              placeholder={translate('sdk.web.inputs.password.label')}
              name={'password'}
              type={'password'}
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.password}
              onChange={formik.handleChange}
              enterkeyhint={'next'}
              onKeyDown={getKeyDownListener({form: formRef.current})}
            />
          </FormRow>
          <div>
            <Input
              placeholder={translate('sdk.web.inputs.password.confirm.label')}
              name={'confirmPassword'}
              type={'password'}
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              enterkeyhint={'go'}
            />
          </div>
          <InfoRow>
            <CopyTextSm>
              {translate('sdk.web.account.register.disclaimer')}
            </CopyTextSm>
          </InfoRow>
        </div>
        <AccountFooter>
          <AccountButtonWrapper>
            <FormButton isLoading={isSubmitting}>
              {translate('sdk.web.account.register.button')}
            </FormButton>
          </AccountButtonWrapper>
          <Link to={loginUrl}>
            <CtaLink noMargin={true}>
              {translate('sdk.web.account.login.cta')}
            </CtaLink>
          </Link>
        </AccountFooter>
      </AccountForm>
    </InfoPage>
  );
};

export default RegisterPage;

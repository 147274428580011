import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';

import routes from 'config/routes';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {getStats} from 'services/water';
import {CACHE_KEYS} from 'constants/cache-keys';

import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import Info from './Info';
import Registration from './Registration';
import Settings from './Settings';

import waterIcon from './images/icon.svg';
import {ReactComponent as CloseIcon} from 'icons/close.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 80px 40px 0;
`;

const CloseIconWrapper = styled.div`
  width: 30px;
  position: absolute;
  color: #000;
  left: 15px;
  top: 20px;
`;

const Title = styled(WidgetTitle)`
  margin-bottom: 4vh;
`;

const Icon = styled.img`
  width: 80px;
  margin-bottom: 4vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

const SCREENS = {
  INFO: 'info',
  REGISTRATION: 'registration',
  SETTINGS: 'settings',
};

const SCREEN_URL_QUERY_KEY = 'screen';

const WaterSettings = () => {
  const history = useHistory();
  const {search} = useLocation();
  const {translate} = useTranslations();

  const {initialScreen, searchParams} = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return {
      searchParams,
      initialScreen: searchParams.get(SCREEN_URL_QUERY_KEY) || SCREENS.INFO,
    };
  }, [search]);
  const [currentScreen, setCurrentScreen] = useState(initialScreen);

  const {data: stats} = useFetcher({
    fetcher: getStats,
    initialValue: {},
    key: CACHE_KEYS.WATER_STATS,
    // Return default data on prevent fetch
    // preventFetch: true,
  });

  const handleCloseSettings = useCallback(() => {
    history.push(routes.DASHBOARD.WATER_WIDGET.href);
  }, [history]);

  const screen = useMemo(() => {
    switch (currentScreen) {
      case SCREENS.REGISTRATION:
        return (
          <Registration
            onCancel={() => setCurrentScreen(SCREENS.INFO)}
            onConfirm={() => setCurrentScreen(SCREENS.SETTINGS)}
          />
        );
      case SCREENS.SETTINGS:
        return <Settings onComplete={handleCloseSettings} />;
      default:
        return (
          <Info
            onConnect={() => setCurrentScreen(SCREENS.REGISTRATION)}
            onCancel={handleCloseSettings}
          />
        );
    }
  }, [currentScreen, handleCloseSettings]);

  useEffect(() => {
    if (stats.connected) {
      setCurrentScreen(SCREENS.SETTINGS);
    }
  }, [stats]);

  useEffect(
    () => {
      searchParams.set(SCREEN_URL_QUERY_KEY, currentScreen);
      history.replace(`?${searchParams.toString()}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screen],
  );

  return (
    <Wrapper>
      <CloseIconWrapper onClick={handleCloseSettings}>
        <CloseIcon />
      </CloseIconWrapper>
      <Title>{translate('sdk.web.water.settings.title')}</Title>
      <Icon src={waterIcon} alt="" />
      <Content>{screen}</Content>
    </Wrapper>
  );
};

export default WaterSettings;

import * as Sentry from '@sentry/react';

interface CaptureExceptionParams {
  error: Error | any;
  errorInfo?: string;
}

export const captureException = ({
  error,
  errorInfo,
}: CaptureExceptionParams) => {
  console.error(error);
  Sentry.captureException(error);

  if (errorInfo) {
    Sentry.captureMessage(errorInfo, 'error');
  }
};

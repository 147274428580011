import {useCallback, useContext, useEffect} from 'react';
import {RootStore} from './providers';
import {log} from './actions';

const axios = require('axios').default;

/**
 * SDK Effects/Functions/Hooks
 *
 * Respect React's lifecycles.
 */

/**
 * Get Native App Data
 *
 *
 * Use this hook to communicate with the underliying
 * native application to get app details;
 */
export const useNativeApp = (callback: any) => {
  const {state, dispatch} = useContext(RootStore);

  /**
   * call IOS or Android service here
   * using payload {}
   */
  useEffect(() => {
    // dispatch(log(payload));
  }, []); // called once

  /**
   * Setup a way to wait
   * using callbacks
   */
  useEffect(
    () => {
      const middleware = (e: any) => {
        const {detail} = e;
        dispatch(log(detail));
        callback(e);
      };
      window.addEventListener('Native_App_Response', middleware);

      return () =>
        window.removeEventListener('Native_App_Response', (e) => middleware);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    state,
  };
};

/**
 * SDK Backend API Helper Function
 *
 * Returns a function that returns an
 * axios instance based on the subdomain
 */
export const useBackend = (token: string) => {
  const Backend = useCallback(
    (subdomain = '') => {
      return axios.create({
        baseURL: `https://${subdomain}.${process.env.REACT_APP_BASE_DOMAIN}/api/`,
        timeout: 1000,
        headers: {
          'x-agent': 'Admin',
          authorization: `Bearer ${token}`,
        },
      });
    },
    [token],
  );

  return Backend;
};

import React, {useMemo} from 'react';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {useTranslations} from 'hooks/use-translations';
import {Compensation} from 'types/Compensation';
import PromptModal from 'components/PromptModal/PromptModal';
import sunImage from './images/sun.svg';

type SuccessPromptProps = {
  compensation: Compensation;
  isVisible: boolean;
  onClose: () => void;
};

const SuccessPrompt = ({
  compensation,
  isVisible,
  onClose,
}: SuccessPromptProps) => {
  const {getShortTokenText} = useTokenTranslation();
  const {translate} = useTranslations();
  const textContent = useMemo(
    () => (
      <div
        dangerouslySetInnerHTML={{
          __html: translate('sdk.web.compensation.purchase.success.message')
            .replace('{nr}', compensation.recoins.toString())
            .replace('{token}', getShortTokenText(compensation.recoins)),
        }}
      />
    ),
    [compensation, getShortTokenText, translate],
  );

  return (
    <PromptModal
      title={translate('sdk.web.compensation.purchase.success.title')}
      textContent={textContent}
      image={sunImage}
      isVisible={isVisible}
      btnText={translate('sdk.web.compensation.purchase.success.cta')}
      onClose={onClose}
      onBtnClick={onClose}
    />
  );
};
export default SuccessPrompt;

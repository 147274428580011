import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';
import pick from 'lodash/pick';

import routes from 'config/routes';
import {CACHE_KEYS} from 'constants/cache-keys';
import {NEW_FEATURES} from 'constants/app-features';
import useNewFeatures from 'hooks/app/use-new-features';
import useErrorMessage from 'hooks/forms/use-error-message';
import {useTranslations} from 'hooks/use-translations';
import {useToast} from 'hooks/use-toast';
import {useFetcher} from 'hooks/use-fetcher';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {
  getCarTypes,
  getUserCarRating,
  saveCarRating,
} from 'services/mobility-settings';
import {STEPS_KEY} from './types';
import {CarType, UserCarRating} from 'types/Mobility';

import FormGroup from 'components/Form/FormGroup';
import FormLabel from 'components/Form/FormLabel';
import Rating from 'components/Form/Rating';
import TextArea from 'components/Form/TextArea';
import CopyText from 'components/Text/CopyText/CopyText';
import PromptModal from 'components/PromptModal/PromptModal';
import AutoComplete from 'components/Form/AutoComplete';
import Page from './Page';

import banner from './images/banner.svg';

const Wrapper = styled.form`
  text-align: initial;
  padding-top: 10px;
`;

const Info = styled(CopyText)`
  text-align: center;
`;

const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const Label = styled(FormLabel)`
  display: block;
`;

const STEPS = {
  [STEPS_KEY.CAR_TYPE]: {
    key: STEPS_KEY.CAR_TYPE,
    requiredFields: ['mobility_car_type_id'],
    next: STEPS_KEY.RATING,
  },
  [STEPS_KEY.RATING]: {
    key: STEPS_KEY.RATING,
    requiredFields: ['range_rating', 'speed_rating', 'nps_rating'],
    next: STEPS_KEY.COMMENTS,
  },
  [STEPS_KEY.COMMENTS]: {
    key: STEPS_KEY.COMMENTS,
    requiredFields: [],
    next: null,
  },
};

const Form = () => {
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const {apiErrors, validate} = useFormValidation();
  const {translate} = useTranslations();
  const {markFeatureAsSeen} = useNewFeatures();
  const errorMessage = useErrorMessage({translate});
  const toast = useToast();

  const [currentStep, setCurrentStep] = useState<STEPS_KEY>(STEPS_KEY.CAR_TYPE);
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();
  const [isSuccessModalOpen, openSuccessModal] = useBoolean(false);

  const validationSchema = useMemo(() => {
    const {getRequiredError} = errorMessage;

    return yup.object().shape({
      mobility_car_type_id: yup
        .number()
        .required(
          getRequiredError({
            label: 'e-car',
            skipLabelTranslation: true,
          }),
        )
        .nullable(),
      range_rating: yup
        .number()
        .required(
          getRequiredError({
            label: translate('sdk.web.eCar.form.range'),
          }),
        )
        .nullable(),
      speed_rating: yup
        .number()
        .required(
          getRequiredError({
            label: translate('sdk.web.eCar.form.speedRating'),
          }),
        )
        .nullable(),
      nps_rating: yup
        .number()
        .required(
          getRequiredError({
            label: translate('sdk.web.eCar.form.nps'),
            skipLabelTranslation: true,
          }),
        )
        .nullable(),
      comments: yup.string().nullable(),
    });
  }, [translate, errorMessage]);

  const {data: carRating, addDataItem: saveCarRatingToCache} =
    useFetcher<UserCarRating>({
      fetcher: getUserCarRating,
      key: CACHE_KEYS.USER_CAR_RATING,
    });

  const initialValues = useMemo(() => {
    return carRating
      ? pick(carRating, [
          'mobility_car_type_id',
          'comments',
          'range_rating',
          'speed_rating',
          'nps_rating',
        ])
      : {
          mobility_car_type_id: '',
          comments: '',
          range_rating: undefined,
          speed_rating: undefined,
          nps_rating: undefined,
        };
  }, [carRating]);

  const {data: carTypes, fetchData: fetchCarTypes} = useFetcher<Array<CarType>>(
    {
      fetcher: getCarTypes,
      initialValue: [],
      key: CACHE_KEYS.CAR_TYPES,
      params: {
        include: initialValues?.mobility_car_type_id,
      },
    },
  );

  useEffect(
    () => {
      if (initialValues?.mobility_car_type_id) {
        fetchCarTypes();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues?.mobility_car_type_id],
  );

  const carTypesOptions = useMemo(() => {
    return (
      carTypes?.map((carType) => ({
        label: carType.name,
        value: carType.id,
        id: carType.id,
      })) || []
    );
  }, [carTypes]);

  const handleCarTypeSearch = useCallback(
    ({query}: {query: string}) => {
      fetchCarTypes({
        search: query,
      });
    },
    [fetchCarTypes],
  );

  const onSubmit = useCallback(
    async (data: typeof initialValues) => {
      try {
        startSubmitting();
        const response = await saveCarRating({
          ...data,
          mobility_car_type_id: Number(data.mobility_car_type_id),
        });
        saveCarRatingToCache({data: response});

        markFeatureAsSeen(NEW_FEATURES.E_CAR_RATING);
        openSuccessModal();
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.eCar.form.error.fallback',
        );
        toast.error(message);
      } finally {
        stopSubmitting();
      }
    },
    [
      startSubmitting,
      saveCarRatingToCache,
      openSuccessModal,
      translate,
      stopSubmitting,
      markFeatureAsSeen,
      toast,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validate,
  });

  const handleNextStep = useCallback(() => {
    if (!formik.dirty && !carRating) {
      return false;
    }

    const step = STEPS[currentStep];
    const errors = step.requiredFields
      .map(
        // @ts-ignore
        (field) => formik.errors[field],
      )
      .filter(Boolean)
      .join(', ');
    if (errors) {
      toast.error(errors);
      return;
    }

    const nextStep = step.next ? STEPS[step.next] : null;
    if (!nextStep) {
      formik.handleSubmit();
      return;
    }

    setCurrentStep(nextStep.key);
  }, [formik, currentStep, toast, carRating]);

  const handleClose = () => history.push(routes.DASHBOARD.href);

  return (
    <>
      <Page
        title={translate('sdk.web.eCar.form.title')}
        currentStep={currentStep}
        btn={{
          text: STEPS[currentStep].next
            ? translate('sdk.web.eCar.form.cta.next')
            : translate('sdk.web.eCar.form.cta.submit'),
          type: 'button',
          onClick: handleNextStep,
          isLoading: isSubmitting,
        }}>
        <Wrapper ref={formRef} onSubmit={formik.handleSubmit}>
          {currentStep === STEPS_KEY.CAR_TYPE && (
            <>
              <Info as="div" className="mb-base">
                {translate('sdk.web.eCar.form.info')}
              </Info>
              <FormGroup>
                <FormLabel>{translate('sdk.web.eCar.form.carType')}</FormLabel>
                <AutoComplete
                  name="mobility_car_type_id"
                  defaultValue={initialValues.mobility_car_type_id}
                  value={(formik.values.mobility_car_type_id as string) || ''}
                  list={carTypesOptions}
                  onChange={formik.handleChange}
                  formik={formik}
                  apiErrors={apiErrors}
                  onSearch={handleCarTypeSearch}
                />
              </FormGroup>
            </>
          )}
          {currentStep === STEPS_KEY.RATING && (
            <CenterBlock>
              <FormGroup>
                <Label as="div">{translate('sdk.web.eCar.form.range')}</Label>
                <Rating
                  name="range_rating"
                  value={formik.values.range_rating}
                  onChange={formik.handleChange}
                  formik={formik}
                  apiErrors={apiErrors}
                />
              </FormGroup>
              <FormGroup>
                <Label as="div">
                  {translate('sdk.web.eCar.form.chargingSpeed')}
                </Label>
                <Rating
                  name="speed_rating"
                  value={formik.values.speed_rating}
                  onChange={formik.handleChange}
                  formik={formik}
                  apiErrors={apiErrors}
                />
              </FormGroup>
              <FormGroup>
                <Label as="div">{translate('sdk.web.eCar.form.nps')}</Label>
                <Rating
                  name="nps_rating"
                  value={formik.values.nps_rating}
                  onChange={formik.handleChange}
                  formik={formik}
                  apiErrors={apiErrors}
                />
              </FormGroup>
            </CenterBlock>
          )}
          {currentStep === STEPS_KEY.COMMENTS && (
            <FormGroup>
              <FormLabel>{translate('sdk.web.eCar.form.comments')}</FormLabel>
              <TextArea
                placeholder=""
                name="comments"
                formik={formik}
                value={formik.values.comments || ''}
                onChange={formik.handleChange}
              />
            </FormGroup>
          )}
        </Wrapper>
      </Page>
      <PromptModal
        image={banner}
        onClose={handleClose}
        isVisible={isSuccessModalOpen}
        title={translate('sdk.web.eCar.form.success.title')}
        textContent={translate('sdk.web.eCar.form.success.text')}
        btnText={translate('sdk.web.eCar.form.success.cta')}
        onBtnClick={handleClose}
      />
    </>
  );
};

export default Form;

import React from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {addBoldText} from 'utils/theme';
import {Badge, BadgeGroup} from 'types/Badge';

import DetailsNavigation from 'components/DetailsNavigation/DetailsNavigation';
import TextMedium from 'components/Text/TextMedium/TextMedium';
import placeholder from './images/placeholder.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 40000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.theme.components.badges.bgColor || props.theme.colors.lightSecondary};
`;

const Body = styled.div`
  overflow-y: scroll;
  padding: 20px;
  padding-top: 93px;
`;

const Group = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const GroupTitle = styled(TextMedium)`
  display: block;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 500;

  .title {
    ${(props) =>
      addBoldText({
        props,
      })}
  }
`;

const GroupItem = styled.li`
  margin-bottom: 20px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Item = styled.li`
  width: calc(33.33% - 12px);
  margin-bottom: 20px;
`;
const Img = styled.img`
  width: 100%;
`;

type AllBadgesProps = {
  badgesGroup: BadgeGroup[];
  onClose: () => void;
  onOpenBadgeDetails: (badge: Badge) => void;
};

const AllBadges = (props: AllBadgesProps) => {
  const {badgesGroup, onOpenBadgeDetails, onClose} = props;
  const {translate} = useTranslations();

  return createPortal(
    <Wrapper>
      <DetailsNavigation
        onClose={onClose}
        text={translate('sdk.web.badges.all.title')}
      />
      <Body>
        <Group>
          {badgesGroup.map((group, index) => (
            <GroupItem key={index}>
              <GroupTitle>
                <span className="title">{group.title}</span>
                <br />
                {group.description}
              </GroupTitle>
              <List>
                {group.badges.map((badge, badgeIndex: number) => (
                  <Item key={badgeIndex}>
                    <Img
                      src={badge.achieved_at ? badge.icon : placeholder}
                      alt={badge.title}
                      onClick={() => onOpenBadgeDetails(badge)}
                    />
                  </Item>
                ))}
              </List>
            </GroupItem>
          ))}
        </Group>
      </Body>
    </Wrapper>,
    document.body,
  );
};

export default AllBadges;

const settings = {
  appId: process.env.REACT_APP_SETTINGS_APP_ID || '',
  appName: process.env.REACT_APP_SETTINGS_APP_ID || '',
  isAppInitialized: !!process.env.REACT_APP_SETTINGS_INIT,
  isPrivacyPolicyAccepted: !!process.env.REACT_APP_SETTINGS_PP,
  appFeatures: (process.env.REACT_APP_SETTINGS_FEATURES || '').split(','),
  appPermissions: (process.env.REACT_APP_SETTINGS_PERMISSIONS || 'gps').split(
    ',',
  ),
  appVersion: process.env.REACT_APP_APP_VERSION,
  sdkVersion: process.env.REACT_APP_SDK_VERSION,
  debugInfo: process.env.REACT_APP_DEBUG_INFO
    ? JSON.parse(process.env.REACT_APP_DEBUG_INFO)
    : process.env.REACT_APP_DEBUG_INFO,
};

export default settings;

import React from 'react';
import styled from 'styled-components';

import DefaultInput, {DefaultInputEl, InputProps} from 'components/Form/Input';

const El = styled(DefaultInputEl)`
  background-color: ${(props) => props.theme.components.metering?.inputBgColor};
  border-bottom-color: ${(props) =>
    props.theme.components.metering?.borderBottomColor};
`;
const Input = (props: InputProps) => {
  return <DefaultInput {...props} InputEl={El} />;
};

export default Input;

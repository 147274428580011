import React from 'react';
import styled from 'styled-components';

import {CHALLENGE_TYPES} from 'constants/challenges';
import {Team} from 'types/Team';

import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import CopyText from 'components/Text/CopyText/CopyText';
import Loader from 'components/Loader/Loader';
import TeamRanking from './TeamRanking/TeamRanking';

const ContributionCard = styled(WidgetCard)<{minHeight?: string}>`
  background: ${(props) =>
    props.theme.components.leaderboard.bgColor ||
    `linear-gradient(
    180deg,
    ${props.theme.colors.secondary} 0%,
    ${props.theme.colors.secondary} 23%,
    #fff 95%
  )`};
  min-height: ${(props) => props.minHeight};
`;

const Subtitle = styled(CopyText)`
  margin-top: 5%;
  margin-bottom: 9%;
  display: block;
  text-align: center;
`;

const Banner = styled.img`
  width: 100%;
  display: block;
  margin-bottom: 7%;
`;

interface LeaderboardWidgetInterface {
  title: string;
  subtitle?: string;
  image?: string;
  isLoading: boolean;
  challengeType: CHALLENGE_TYPES;
  teams?: Team[];
  isOwnTeam: ({team}: {team: Team}) => boolean;
  children?: any;
  minHeight?: string;
}

const LeaderboardWidget = (props: LeaderboardWidgetInterface) => {
  const {
    title,
    subtitle,
    image,
    isLoading,
    teams,
    isOwnTeam,
    minHeight,
    challengeType,
    children,
  } = props;

  return (
    <ContributionCard minHeight={minHeight}>
      <WidgetTitle>{title}</WidgetTitle>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {image && <Banner src={image} alt={''} />}
      {!isLoading ? (
        teams && (
          <TeamRanking
            teams={teams}
            isOwnTeam={isOwnTeam}
            challengeType={challengeType}
          />
        )
      ) : (
        <Loader sm={true} />
      )}
      {!!children && children}
    </ContributionCard>
  );
};

export default LeaderboardWidget;

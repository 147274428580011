import React from 'react';
import styled from 'styled-components';

import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';

import {ReactComponent as SintraBannerIcon} from './images/banner.svg';

const Wrapper = styled.div`
  width: 100%;

  svg {
    width: 100%;
  }
`;

const SintraBanner = () => {
  return (
    <WidgetCard>
      <Wrapper>
        <SintraBannerIcon />
      </Wrapper>
    </WidgetCard>
  );
};

export default SintraBanner;

import {fetchAPiClient} from './api';
import {
  EMISSION_TRANSACTIONS,
  EMISSION_TRANSACTIONS_KEYS,
  TRANSACTION_TYPE,
  TRANSACTION_TYPES,
  TRANSACTION_TYPES_KEYS,
} from 'constants/transaction-type';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});
const activityApiClient = fetchAPiClient({subdomain: 'activity'});

export type UserData = {
  id: number;
  recoins: number;
  account_type: number;
  email: string;
  firstname: string;
  lastname: string;
  guest_firstname: string;
  guest_lastname: string;
  guest_email: string;
  created_at: string;
  open_widgets: {
    enable: boolean;
    auth?: {
      token: string;
    };
  };
};

export type UserProfile = {
  user: UserData;
  organisation: {
    id: number;
    emission_description: string;
    compensation_description: string;
    co2_offset_info_text: string;
    water_saving_tips: string;
    description_enabled: boolean;
    faq_enabled: boolean;
    privacy_policy_enabled: boolean;
    terms_of_service_enabled: boolean;
    saving_electricity: string;
    saving_gas: string;
    saving_water: string;
    metering_description: string;
    settings: {
      dynamic_links: {
        disable: boolean;
      };
    };
  };
};

export const getMyProfile = () => {
  const client = defaultApiClient;
  const url = '/user/me';
  return client.get(url);
};

type UserStats = {
  recoins: number;
  recoins_earned: number;
  recoins_spent: number;
  walking: number;
  cycling: number;
  co2_avoided: number;
  co2_emissions: number;
  co2_offset: number;
  co2_pending: number;
  summary: any[];
  co2_avoided_summary: any[];
  co2_emissions_summary: any[];
  co2_pending_summary: Co2PendingSummary[];
  co2_offset_summary: any[];
  co2_per_recoin: number;
  co2_for_next_recoin: number;
};

type TreePlantingSummary = {
  treesPlanted: number;
  totalTreesPlanted: number;
  totalTreesGoal: number;
  nextTreeIn: number;
  costType: number;
  costPerUnit: number;
  percent: number;
  isActive: boolean;
  hasGoalBeenReached: boolean;
};

type SavingsSummary = Record<TRANSACTION_TYPES_KEYS, number>;

export type ActivitySummary = {
  co2Avoided: number;
  co2Needed: number;
  co2NeededForRecoin: number;
  treePlanting: TreePlantingSummary;
  referralsData: ReferralsData;
  percent: number;
  savings: SavingsSummary;
  emissions: Record<EMISSION_TRANSACTIONS_KEYS, number>;
  totalSavings: number;
  totalOrganizationSavings: number;
  user: UserStats;
};

export const getMyActivitySummary = async ({
  userProfile,
  hasCustomActivities,
}: {
  userProfile: any;
  hasCustomActivities: boolean;
}): Promise<ActivitySummary> => {
  const [referralStatsResponse, sdkStatsResponse]: Array<any> =
    await Promise.all([getReferralStats(), getSDKStats()]);

  const {user, organisation, charity} = sdkStatsResponse;

  const activities = [
    TRANSACTION_TYPES.CYCLING,
    TRANSACTION_TYPES.WALKING,
    TRANSACTION_TYPES.PUBLIC_TRANSIT,
    ...(hasCustomActivities ? [TRANSACTION_TYPES.CUSTOM_ACTIVITIES] : []),
  ];

  const {savings, totalSavings} = getSavingsByTransaction({
    summary: user.summary || [],
    activities,
  });

  const emissions = getUsageByTransaction({
    summary: user.co2_pending_summary || [],
    activities: EMISSION_TRANSACTIONS,
  });

  const totalOrganizationSavings = organisation.co2_avoided;
  const co2Avoided = user.co2_avoided;
  const co2NeededForRecoin = user.co2_per_recoin;
  const co2Needed = user.co2_for_next_recoin;
  const percent = ((user.recoins % 1) * 100) % 100;

  const treePlanting = {
    treesPlanted: 0,
    totalTreesPlanted: 0,
    totalTreesGoal: 0,
    nextTreeIn: 0,
    costType: 0,
    costPerUnit: 0,
    percent: 0,
    timerDaysLeft: 0,
    timerProgress: 0,
    isActive: false,
    hasGoalBeenReached: false,
    hasTimer: false,
  };

  if (charity.tree_planting.project) {
    treePlanting.isActive = true;
    treePlanting.treesPlanted = charity.tree_planting.user.trees;
    treePlanting.totalTreesPlanted = charity.tree_planting.project.amount;
    treePlanting.totalTreesGoal = charity.tree_planting.project.goal;
    treePlanting.nextTreeIn = charity.tree_planting.project.user.units_left;
    treePlanting.costType = charity.tree_planting.project.cost_type;
    treePlanting.costPerUnit = charity.tree_planting.project.cost_per_unit;
    treePlanting.hasGoalBeenReached =
      treePlanting.totalTreesPlanted === treePlanting.totalTreesGoal;
    treePlanting.percent =
      ((treePlanting.costPerUnit - treePlanting.nextTreeIn) /
        treePlanting.costPerUnit) *
      100;

    treePlanting.hasTimer =
      charity.tree_planting.project.timer &&
      charity.tree_planting.project.timer > 0;
    treePlanting.timerProgress =
      charity.tree_planting.project.user.timer_progress;
    treePlanting.timerDaysLeft = charity.tree_planting.project.user.timer_days;
  }

  const referralsData = getReferralsSettings({
    referralStatsResponse,
    ...userProfile,
  });

  return {
    user,
    co2Avoided,
    co2Needed,
    co2NeededForRecoin,
    treePlanting,
    referralsData,
    percent,
    savings,
    totalSavings,
    emissions,
    totalOrganizationSavings,
  };
};

export const getMyActivity = async ({id}: {id: number}) => {
  const client = activityApiClient;
  const url = `/statistics/users/${id}`;
  return client.get(url);
};

export const getOrganizationActivity = async ({id}: {id: number}) => {
  const client = activityApiClient;
  const url = `/statistics/organisations/${id}`;
  return client.get(url);
};

export const getOrganizationInfo = async () => {
  const client = defaultApiClient;
  const url = '/organisation/logo';
  return client.get(url);
};

export const getCharity = async ({id}: {id: number}) => {
  const client = activityApiClient;
  const url = `/statistics/users/${id}/charity`;
  return client.get(url);
};

export const getReferralStats = async () => {
  const client = defaultApiClient;
  const url = 'user-referral/user-statistics';
  return client.get(url);
};

export const getSDKStats = async () => {
  const client = activityApiClient;
  const url = 'statistics/sdk';
  return client.get(url);
};

type Co2PendingSummary = {
  transaction_type_id: number;
  co2_avoided: number;
  co2_emissions: number;
  recoins_earned: number;
  recoins_spent: number;
  quantity: number;
  duration: number;
  count: number;
  meters: number;
  steps: number;
  challenge_source_id: number;
  value: number;
  percentage: number;
};
export const getUsageByTransaction = ({
  summary,
  activities,
}: {
  summary: Array<Co2PendingSummary>;
  activities: Array<TRANSACTION_TYPE>;
}) => {
  const savings = {} as Record<typeof activities[number]['key'], number>;
  activities.forEach((activity) => {
    const activitySummary = summary.find(({transaction_type_id}) =>
      activity.transactionTypes.includes(transaction_type_id),
    );

    savings[activity.key] = activitySummary?.percentage || 0;
  });

  return savings;
};

export const getSavingsByTransaction = ({
  summary,
  activities,
}: {
  summary: Array<any>;
  activities: Array<any>;
}) => {
  const activitiesSummary = activities.map((activity) => {
    const totalC02Avoided = summary
      .filter(({transaction_type_id}) =>
        activity.transactionTypes.includes(transaction_type_id),
      )
      .reduce(
        (total, activitySummary) => total + activitySummary.co2_avoided,
        0,
      );

    return {
      key: activity.key,
      totalC02Avoided,
    };
  });

  const totalSavings = activitiesSummary.reduce(
    (total, activitySummary) => total + activitySummary.totalC02Avoided,
    0,
  );
  const savings: any = {};
  activitiesSummary.forEach((activitySummary) => {
    savings[activitySummary.key] =
      totalSavings === 0
        ? 0
        : (activitySummary.totalC02Avoided / totalSavings) * 100;
  });

  return {savings, totalSavings};
};

export type ReferralsData = {
  isActive: boolean;
  referralsNeeded: number;
  rewardTypeId: number;
  totalReferrals: number;
  rewardQuantity: number;
  referralsGotten: number;
  referralsQuantity: number;
  totalRewardsEarned: number;
  referralLink: string;
  qrCode: string;
  shareText: string;
  percent: number;
  rewardsEarned: Array<any>;
};

const getReferralsSettings = ({
  referralStatsResponse,
  user,
  organisation,
}: {
  referralStatsResponse: any;
  user: any;
  organisation: any;
}) => {
  const isActive = !!(
    user.referral_link &&
    organisation.referral_settings &&
    organisation.referral_settings.enabled
  );

  if (!isActive) {
    return {
      isActive: false,
      referralsNeeded: 0,
      rewardTypeId: 0,
      totalReferrals: 0,
      referralsGotten: 0,
      rewardQuantity: 0,
      referralsQuantity: 0,
      totalRewardsEarned: 0,
      percent: 0,
      qrCode: '',
      shareText: '',
      referralLink: '',
      rewardsEarned: [],
    };
  }

  const {data} = referralStatsResponse;
  const nextReward = data.next_reward[0];

  const referralsNeeded = nextReward.referrals_until_next_reward;
  const rewardTypeId = nextReward.reward_type_id;
  const totalReferrals = data.total_referrals;
  const referralLink = user.referral_link.link;
  const qrCode = user.referral_link.qr_code;
  const referralsQuantity = organisation.referral_settings.referrals;
  const rewardQuantity = organisation.referral_settings.reward_quantity;
  const shareText = organisation.referral_settings.share_text;
  const referralsGotten = referralsQuantity - referralsNeeded;

  const percent = (referralsGotten / referralsQuantity) * 100;
  const rewardsEarned = data.rewards.map((reward: any) => ({
    totalRewards: reward.total_rewards,
    rewardTypeId: reward.reward_type_id,
  }));
  const totalRewardsEarned = rewardsEarned.reduce(
    (sum: number, reward: any) => sum + reward.totalRewards,
    0,
  );

  return {
    isActive,
    referralsNeeded,
    rewardTypeId,
    totalReferrals,
    referralLink,
    referralsQuantity,
    referralsGotten,
    qrCode,
    rewardQuantity,
    percent,
    rewardsEarned,
    totalRewardsEarned,
    shareText,
  };
};

import styled from 'styled-components';
import Button from 'components/Button/Button';

export const AltButton = styled(Button)`
  color: ${(props) =>
    props.theme.components.buttons[props.variant || 'primary'].bgColor};
  box-shadow: 0 0 0 2px
    ${(props) =>
      props.theme.components.buttons[props.variant || 'primary'].bgColor}
    inset;
  background-color: ${(props) =>
    props.theme.components.buttons[props.variant || 'primary'].color};
`;

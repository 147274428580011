import React from 'react';

import {useTranslations} from 'hooks/use-translations';

import LotResults from '../LotResults';

const LotLoss = (props: any) => {
  const {translate} = useTranslations();

  return (
    <LotResults
      title={translate('sdk.web.lots.winners.drawn')}
      btnTitle={translate('sdk.web.offers.ok')}
      onClose={props.onClose}
      onBtnClick={props.onClose}>
      <div>{translate('sdk.web.lots.loss.message')}</div>
    </LotResults>
  );
};

export default LotLoss;

import React, {useContext, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import {AuthContext} from 'contexts/AuthContext';
import {ActivityContext} from 'contexts/ActivityContext';
import {useToast} from 'hooks/use-toast';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import {ENV} from 'types/App';

import InfoPage from 'components/InfoPage/InfoPage';
import Button from 'components/Button/Button';

import packageJson from '../../../../package.json';

const Row = styled.div`
  margin-bottom: 20px;
  word-break: break-all;
`;

const Input = styled.input`
  margin-right: 10px;
`;

const ButtonWrapper = styled.div`
  padding-top: 30px;
  text-align: center;
`;

const DeviceInfoPage = (props: any) => {
  const {translate} = useTranslations();
  const {appVersion, sdkVersion, debugInfo, organizationConfig} =
    useContext(SettingsContext);
  const {auth} = useContext(AuthContext);
  const {userProfile} = useUserProfile();
  const toast = useToast();
  const {isDiagnosticsEnabled, updateDiagnostics} = useContext(ActivityContext);

  const {copyText, versionWithEnv} = useMemo(() => {
    const version = [appVersion, sdkVersion, packageJson.version]
      .filter((v) => v)
      .join(' - ');
    const versionWithEnv =
      auth.env === ENV.PRODUCTION ? version : `${version} - ${auth.env}`;

    const copyText = [
      `${translate('sdk.web.device.info.user.id')}: ${userProfile?.user.id}`,
      `${translate('sdk.web.device.info.app.version')}: ${versionWithEnv}`,
    ].join('\n');

    return {
      copyText,
      version,
      versionWithEnv,
    };
  }, [appVersion, sdkVersion, auth, userProfile, translate]);

  const onClose = () => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.SETTINGS.href);
    }
  };

  const onCopy = () => {
    toast.success(translate('sdk.web.device.info.copy.message'));
  };

  const handleDiagnosticsChange = (e: any) => {
    updateDiagnostics!(e.target.checked);
  };

  return (
    <InfoPage title={translate('sdk.web.device.info')} onClose={onClose}>
      <Row>
        <b>{translate('sdk.web.device.info.user.id')}: </b>{' '}
        {userProfile?.user.id}
      </Row>
      <Row>
        <b>{translate('sdk.web.device.info.app.version')}: </b> {versionWithEnv}
      </Row>
      <Row>
        <Input
          type={'checkbox'}
          id={'diagnostics'}
          checked={isDiagnosticsEnabled}
          onChange={handleDiagnosticsChange}
        />
        <label htmlFor="diagnostics">
          <b>{translate('sdk.web.device.info.enable.diagnostics')}</b>
        </label>
      </Row>
      {auth.env !== ENV.PRODUCTION && (
        <Row>
          <b>Debug info</b>
          <br />
          <pre>
            <code>
              {JSON.stringify(organizationConfig?.features || {}, null, 2)}
            </code>
            <code>{JSON.stringify(debugInfo || {}, null, 2)}</code>
          </pre>
        </Row>
      )}
      <ButtonWrapper>
        <CopyToClipboard text={copyText} onCopy={onCopy}>
          <Button>{translate('sdk.web.device.info.copy.cta')}</Button>
        </CopyToClipboard>
      </ButtonWrapper>
    </InfoPage>
  );
};

export default withRouter(DeviceInfoPage);

import React, {useContext, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useTranslations} from 'hooks/use-translations';

import Onboarding from 'components/Onboarding/Onboarding';
import Loader from 'components/Loader/Loader';
import onboardingImg from 'components/Onboarding/images/onboarding-1.svg';

const PageLoader = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  justify-content: center;
`;

const OnboardingOne = (props: any) => {
  const {translate} = useTranslations();
  const {updateBg} = useBodyBg({type: 'light'});
  const {
    appName,
    isPrivacyPolicyAccepted,
    isAppInitialized,
    organizationConfig,
  } = useContext(SettingsContext);

  useEffect(
    () => {
      if (isPrivacyPolicyAccepted) {
        return;
      }

      const type = isAppInitialized ? 'onboarding' : 'light';
      updateBg({type});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAppInitialized, organizationConfig],
  );

  const {title, content} = useMemo(() => {
    const cityName = translate(organizationConfig.translations.cityName);

    return {
      title: translate(organizationConfig.translations.onBoarding.one.title, [
        {
          key: '{cityName}',
          value: cityName,
        },
        {
          key: '{appName}',
          value: appName,
        },
      ]),
      content: translate(
        organizationConfig.translations.onBoarding.one.content,
        [
          {
            key: '{cityName}',
            value: cityName,
          },
          {
            key: '{appName}',
            value: appName,
          },
        ],
      ),
    };
  }, [organizationConfig, appName, translate]);

  if (isPrivacyPolicyAccepted) {
    props.history.push(routes.DASHBOARD.href);
    return <div />;
  }

  const onBtnClick = () => {
    props.history.push(routes.ONBOARDING.TWO.href);
  };

  if (!isAppInitialized)
    console.log("Showing loader - app not initialized");

  return (
    <>
      {isAppInitialized ? (
        <Onboarding
          title={title}
          content={content}
          onboardingImg={
            organizationConfig.theme.components.onboarding?.images?.one
              ?.image || onboardingImg
          }
          isImageTop={true}
          btnCta={translate('sdk.web.onboarding.next')}
          onBtnClick={onBtnClick}
        />
      ) : (
        <PageLoader>
          <Loader color={'#222'} />
        </PageLoader>
      )}
    </>
  );
};

export default withRouter(OnboardingOne);

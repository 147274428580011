import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import {QR_CODE_ACTIONS} from 'constants/qr-codes';
import {useTranslations} from 'hooks/use-translations';
import {useQrScanner} from 'hooks/use-qr-scanner';
import {Voucher} from 'types/Offers';

import Button from 'components/Button/Button';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

import {ReactComponent as QrCodeIcon} from 'icons/qr-code.svg';

const IconWrapper = styled.div`
  position: absolute;
  left: 22px;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
`;

const Icon = styled.div`
  fill: ${(props) => props.theme.colors.white};
  width: 22px;
`;

type OfferScanProps = {
  voucher: Voucher;
};

const OfferScan = (props: OfferScanProps) => {
  const {voucher} = props;
  const history = useHistory();
  const {translate} = useTranslations();

  const handleScanResponse = useCallback(() => {
    history.replace('?scanned=true');
  }, [history]);

  const {openScanner, isLoading} = useQrScanner({
    climatePartnerId: voucher.climate_partner?.id,
    onScan: handleScanResponse,
    allowedActions: [QR_CODE_ACTIONS.VIEW_OFFERS],
  });

  return (
    <Button isLoading={isLoading} onClick={openScanner}>
      <CopyTextSm>{translate('sdk.web.offers.onsite.scan')}</CopyTextSm>
      <IconWrapper>
        <Icon>
          <QrCodeIcon />
        </Icon>
      </IconWrapper>
    </Button>
  );
};

export default OfferScan;

import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import flatten from 'lodash/flatten';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import {getBadges, sortByDateOrder} from 'services/badges';

import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import HeaderTextLg from 'components/Text/HeaderTextLg/HeaderTextLg';
import CtaLink from 'components/CtaLink/CtaLink';
import AllBadges from './AllBadges';
import BadgeDetails from './BadgeDetails';

import placeholder from './images/placeholder.svg';
import {Badge, BadgeGroup} from 'types/Badge';

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled(HeaderTextLg)`
  font-family: ${(props) => props.theme.font.secondary.name};
  display: block;
  color: ${(props) => props.theme.components.badges.titleColor || 'inherit'};
`;

const All = styled(CtaLink)`
  display: block;
  margin-bottom: 0;
  font-weight: bold;
  text-decoration: ${(props) =>
    props.theme.components.badges?.noUnderline ? 'none' : undefined};

  &,
  &:hover,
  &:focus {
    color: ${(props) =>
      props.theme.components.badges.allLinkColor || props.theme.colors.black};
  }
`;

const List = styled.ul`
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    overflow: -moz-scrollbars-none;
  }
`;

const Item = styled.li`
  width: 27.94%;
  flex-shrink: 0;
  margin-right: 10px;
`;

const Img = styled.img`
  width: 100%;
`;

const Badges = () => {
  const {hasBadges} = useOrganization();
  const {translate} = useTranslations();
  const [isAllBadgesOpen, showAllBadges, hideAllBadges] = useBoolean();
  const [selectedBadge, setSelectedBadge] = useState<Badge | undefined>();

  const {data}: {data: BadgeGroup[]} = useFetcher({
    fetcher: getBadges,
    initialValue: [],
    key: CACHE_KEYS.BADGES,
    preventFetch: !hasBadges,
  });

  const {badges, badgesGroup} = useMemo(() => {
    const badges = flatten(
      data.map((group) => [...group.badges].sort(sortByDateOrder).slice(0, 10)),
    )
      .sort(sortByDateOrder)
      .slice(0, 10);

    return {
      badges,
      badgesGroup: data,
    };
  }, [data]);

  useEffect(() => {
    if (badges.length) {
      // setSelectedBadge(badges[1]);
    }
  }, [badges]);

  if (!(badgesGroup.length && badges.length)) {
    return null;
  }

  return (
    <WidgetCard minHeight="20px">
      <Wrapper>
        <Header>
          <Title>{translate('sdk.web.badges.widget.title')}</Title>
          <All onClick={showAllBadges}>
            {translate('sdk.web.badges.widget.all')}
          </All>
        </Header>
        <List>
          {badges.map((badge, index: any) => (
            <Item key={index}>
              <Img
                src={badge.achieved_at ? badge.icon : placeholder}
                alt={badge.title}
                onClick={() => setSelectedBadge(badge)}
              />
            </Item>
          ))}
        </List>
      </Wrapper>
      {isAllBadgesOpen && (
        <AllBadges
          badgesGroup={badgesGroup}
          onOpenBadgeDetails={(badge: any) => {
            setSelectedBadge(badge);
          }}
          onClose={hideAllBadges}
        />
      )}
      {selectedBadge && (
        <BadgeDetails
          badge={selectedBadge}
          onOpenAllBadges={() => {
            setSelectedBadge(undefined);
            showAllBadges();
          }}
          onClose={() => setSelectedBadge(undefined)}
        />
      )}
    </WidgetCard>
  );
};

export default Badges;

import React, {useContext, useMemo} from 'react';
import {withRouter} from 'react-router-dom';

import {useTranslations} from 'hooks/use-translations';
import routes from 'config/routes';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {SettingsContext} from 'contexts/SettingsContext';

import Onboarding from 'components/Onboarding/Onboarding';
import onboardingImg from 'components/Onboarding/images/onboarding-3.svg';

const OnboardingThree = (props: any) => {
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {getTokenText} = useTokenTranslation();

  const {title, content} = useMemo(
    () => ({
      title: translate(organizationConfig.translations.onBoarding.three.title, {
        key: '{token}',
        value: getTokenText(),
      }),
      content: translate(
        organizationConfig.translations.onBoarding.three.content,
        {
          key: '{token}',
          value: getTokenText(),
        },
      ),
    }),
    [organizationConfig, translate, getTokenText],
  );

  const onBtnClick = () => {
    props.history.push(routes.ONBOARDING.FOUR.href);
  };

  return (
    <Onboarding
      title={title}
      content={content}
      onboardingImg={
        organizationConfig.theme.components.onboarding?.images?.three?.image ||
        organizationConfig.translations.onBoarding.three.image ||
        onboardingImg
      }
      btnCta={translate('sdk.web.onboarding.next')}
      onBtnClick={onBtnClick}
    />
  );
};

export default withRouter(OnboardingThree);

import React, {useContext} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {ReactComponent as Times} from './images/times.svg';
import {ReactComponent as Caret} from './images/caret.svg';

const getCloseIcon = ({closeIconType}: {closeIconType: string}) => {
  switch (closeIconType) {
    case 'times':
      return <Times />;
    case 'caret':
      return <Caret />;

    default:
      return <Times />;
  }
};

interface CloseIconProps {
  iconType?: string;
}

const CloseIcon = (props: CloseIconProps) => {
  const {iconType} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const closeIconType = iconType || organizationConfig.features.closeIconType;

  return <React.Fragment>{getCloseIcon({closeIconType})}</React.Fragment>;
};

export default CloseIcon;

import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {ActivityContext} from 'contexts/ActivityContext';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';

import WidgetTile from 'components/ClimateWidgets/Widget/WidgetTile';
import Modal from './Modal';

import goalImage from './images/goal.svg';

const URL_KEY = 'inviteFriends';

const InviteFriends = (props: any) => {
  const {activitySummary} = props;
  const {search} = useLocation();
  const history = useHistory();
  const {userProfile} = useUserProfile();

  const {isModalOpen, searchParams} = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const isModalOpen = searchParams.get(URL_KEY);
    return {isModalOpen, searchParams};
  }, [search]);

  const {isInviteFriendsActive} = useContext(ActivityContext);
  const {translate} = useTranslations();

  const openModal = useCallback(() => {
    searchParams.set(URL_KEY, 'true');
    history.replace(`?${searchParams.toString()}`);
  }, [history, searchParams]);

  const closeModal = useCallback(() => {
    searchParams.delete(URL_KEY);
    history.replace(`?${searchParams.toString()}`);
  }, [history, searchParams]);

  if (
    !isInviteFriendsActive ||
    userProfile.organisation?.settings?.dynamic_links?.disable
  ) {
    return null;
  }

  return (
    <div>
      <WidgetTile
        title={translate('sdk.web.invite.friends.widget.title')}
        subtitle={translate('sdk.web.invite.friends.widget.subtitle')}
        image={goalImage}
        onClick={openModal}
      />
      <Modal
        isVisible={isModalOpen}
        activitySummary={activitySummary}
        onClose={closeModal}
      />
    </div>
  );
};

export default InviteFriends;

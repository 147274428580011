import React from 'react';
import {withRouter} from 'react-router-dom';
import InfoPage from 'components/InfoPage/InfoPage';
import routes from '../../../config/routes';
import {useFetcher} from 'hooks/use-fetcher';
import {getOrganizationInfo} from 'services/user';
import {useTranslations} from 'hooks/use-translations';

const TermsPage = (props: any) => {
  const {translate} = useTranslations();
  const {
    isLoading,
    data: organizationData,
  }: {isLoading: boolean; data: any} = useFetcher({
    fetcher: getOrganizationInfo,
    params: {},
    key: 'organizationInfo',
  });

  const terms = organizationData ? organizationData.data?.terms_of_service : '';
  const termsContent = [
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: terms,
        }}
      />
    </div>,
  ];
  const onClose = (e: any) => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.SETTINGS.href);
    }
  };

  return (
    <React.Fragment>
      <InfoPage
        title={translate('sdk.web.settings.terms.title')}
        rows={termsContent}
        onClose={onClose}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default withRouter(TermsPage);

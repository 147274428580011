import React, {createContext, useState} from 'react';

type ReviewObject = {
  isReviewComplete?: boolean;
  isReviewOpen?: boolean;
  setIsReviewComplete?: (isReviewComplete: boolean) => void;
  setIsReviewOpen?: (isReviewComplete: boolean) => void;
};

export const ReviewContext = createContext<ReviewObject>({});

const ReviewProvider = (props: any) => {
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const reviewSettings = {
    isReviewOpen,
    setIsReviewOpen,
  };

  return (
    <ReviewContext.Provider value={reviewSettings}>
      {props.children}
    </ReviewContext.Provider>
  );
};

export default ReviewProvider;

type GenerateCounterListParams = {
  start: number;
  end: number;
};
export const generateCounterList = ({start, end}: GenerateCounterListParams) =>
  Array.from({length: end + 1 - start}, (_, i) => {
    const value = i + start;
    return {
      id: value,
      value: value,
      label: value.toString(),
    };
  });

import React from 'react';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes/rewards';
import {useTranslations} from 'hooks/use-translations';

import CopyText from 'components/Text/CopyText/CopyText';
import LotResults from '../LotResults';

const LotSuccess = (props: any) => {
  const {translate} = useTranslations();
  const {lot} = props;
  const history = useHistory();

  const onBtnClick = (e: any) => {
    e.preventDefault();
    history.push(`${routes.REWARDS.LOTS.HOW_TO.href}/${lot.lottery.id}`);
  };

  return (
    <LotResults
      title={translate('sdk.web.lots.win.message', {
        key: '{name}',
        value: '',
      })}
      btnTitle={translate('sdk.web.lots.how.to.get.prize')}
      onClose={props.onClose}
      onBtnClick={onBtnClick}>
      <CopyText>
        <br />
        <b>{translate('sdk.web.lots.prize')}</b>: {lot.prize.prize_name}
      </CopyText>
    </LotResults>
  );
};

export default LotSuccess;

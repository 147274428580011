import React, {useContext, useMemo, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import {ACCEPT_PP_URL_PARAM, LOGIN_ONLY_URL_PARAM} from 'constants/auth';
import {useTranslations} from 'hooks/use-translations';
import {useAddNext} from 'hooks/utils/use-links';
import {useOrganization} from 'hooks/use-organization';
import {useUserProfile} from 'hooks/use-user-profile';
import {closeApp, sendMessageToNativeApp} from 'services/native-api';
import {AccountType} from 'types/User';

import Onboarding from 'components/Onboarding/Onboarding';
import Button from 'components/Button/Button';
import DialogBox from 'components/DialogBox/DialogBox';
import CtaLink from 'components/CtaLink/CtaLink';
import CopyText from 'components/Text/CopyText/CopyText';

import onboardingImg from 'components/Onboarding/images/onboarding-privacy.svg';

const DATA_ID_TERMS = 'terms';

const Cta = styled.div``;

const PrivacyCheck = styled.div`
  display: flex;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
`;

const PrivacyCheckBox = styled.input`
  background-color: ${(props) => props.theme.colors.white};
  width: 20px;
  height: 20px;
  min-width: 20px;
  display: block;
`;

const PrivacyLabel = styled.label`
  display: block;
  margin-left: 10px;
  line-height: 20px;
  margin-bottom: 0;
  text-align: left;
`;

const InlinePrivacyLink = styled(CtaLink)`
  margin: 0 4px;
  margin-bottom: 0;
  margin-top: 0;
`;

const nextRoute = routes.ONBOARDING.GPS.href;

interface ComponentBlockProps {
  isPrivacyChecked: boolean;
}

const ComponentBlock = (props: ComponentBlockProps) => {
  const {isPrivacyChecked} = props;
  const {translate} = useTranslations();

  const loginUrl = useAddNext({
    url: routes.SETTINGS.LOGIN.href,
    next: nextRoute,
    params: {
      [LOGIN_ONLY_URL_PARAM]: 'true',
      ...(isPrivacyChecked ? null : {[ACCEPT_PP_URL_PARAM]: 'true'}),
    },
  });

  return (
    <div>
      <CopyText>{translate('sdk.web.onboarding.have.account')}</CopyText>
      <br />
      <CtaLink as={Link} to={loginUrl}>
        {translate('sdk.web.onboarding.login')}
      </CtaLink>
    </div>
  );
};

const OnboardingPrivacy = () => {
  const history = useHistory();
  const {translate} = useTranslations();
  const {setIsPrivacyPolicyAccepted, organizationConfig} =
    useContext(SettingsContext);
  const {userProfile} = useUserProfile();
  const {hasUserAccount} = useOrganization();

  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [isPrivacyDialogVisible, setIsPrivacyDialogVisible] = useState(false);

  const {title, content, confirmLink} = useMemo(
    () => ({
      title: translate(
        organizationConfig.translations.onBoarding.privacy.title,
      ),
      content: translate(
        organizationConfig.translations.onBoarding.privacy.content,
      ),
      confirmLink: translate(
        organizationConfig.translations.onBoarding.privacy.confirmLink,
      ),
    }),
    [organizationConfig, translate],
  );

  const onBtnClick = () => {
    if (!isPrivacyChecked) {
      setIsPrivacyDialogVisible(true);
      return;
    }

    const message = {type: 'pp_accepted'};
    sendMessageToNativeApp({message});
    setIsPrivacyPolicyAccepted(true);
    history.push(nextRoute);
  };

  const onPrivacyCheckChange = (e: any) => {
    setIsPrivacyChecked(e.target.checked);
  };

  const onPrivacyLinkNav = (e: any) => {
    const dataId = e.target.getAttribute('data-id');
    const url =
      dataId === DATA_ID_TERMS
        ? routes.SETTINGS.TERMS.href
        : routes.SETTINGS.PRIVACY.href;
    history.push(url);
  };

  const onDialogBoxConfirmation = () => {
    setIsPrivacyDialogVisible(false);
  };

  return (
    <Onboarding
      title={title}
      content={content}
      onboardingImg={
        organizationConfig.theme.components.onboarding?.images?.privacy
          ?.image ||
        organizationConfig.translations.onBoarding.privacy.image ||
        onboardingImg
      }
      ComponentBlock={
        hasUserAccount &&
        userProfile.user.account_type === AccountType.GUEST ? (
          <ComponentBlock isPrivacyChecked={isPrivacyChecked} />
        ) : undefined
      }
      onClose={organizationConfig.features.hideAppClose ? false : closeApp}>
      <Cta>
        <PrivacyCheck className="pp-here">
          <PrivacyCheckBox
            type={'checkbox'}
            checked={isPrivacyChecked}
            onChange={onPrivacyCheckChange}
          />
          <PrivacyLabel>
            <InlinePrivacyLink onClick={onPrivacyLinkNav}>
              <div
                dangerouslySetInnerHTML={{
                  __html: confirmLink,
                }}
              />
            </InlinePrivacyLink>
          </PrivacyLabel>
        </PrivacyCheck>
        <div>
          <Button onClick={onBtnClick}>
            {translate('sdk.web.onboarding.privacy.confirm')}
          </Button>
        </div>
        {isPrivacyDialogVisible && (
          <DialogBox
            title={translate('sdk.web.onboarding.privacy.prompt.title')}
            onConfirmation={onDialogBoxConfirmation}
            promptMessage={translate(
              'sdk.web.onboarding.privacy.prompt.message',
            )}
            singleText={translate('sdk.web.dialog.box.ok')}
          />
        )}
      </Cta>
    </Onboarding>
  );
};

export default OnboardingPrivacy;

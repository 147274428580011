import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';

const defaultEvents = {
  tokensUpdated: 0,
};
export type Events = typeof defaultEvents;

type EventsContextProps = {
  events: Events;
  updateEvents: (events: Partial<Events>) => void;
  resetEvents: () => void;
};

const EventsContext = createContext<EventsContextProps>({
  events: defaultEvents,
  updateEvents(): void {},
  resetEvents(): void {},
});

export const EventsProvider = (props: PropsWithChildren<{}>) => {
  const [events, setEvents] = useState<Events>(defaultEvents);
  const updateEvents = useCallback((events: Partial<Events>) => {
    setEvents((prevEvents) => ({
      ...prevEvents,
      ...events,
    }));
  }, []);

  const resetEvents = useCallback(() => {
    setEvents(defaultEvents)
  },[])

  return (
    <EventsContext.Provider
      value={{
        events,
        updateEvents,
        resetEvents,
      }}>
      {props.children}
    </EventsContext.Provider>
  );
};

export default EventsContext;

import {WidgetKeys, WIDGETS} from 'constants/widgets';

const mainRoute = '/dashboard';
const baseRoute = `${mainRoute}/contributions`;

const routes = {
  CONTRIBUTIONS: {
    href: baseRoute,
    WIDGETS: {} as Record<WidgetKeys, {href: string}>,
    WIDGETS_SEARCH: {} as Record<WidgetKeys, string>,
  },
};

Object.keys(WIDGETS).forEach((key) => {
  const widgetKey = key as unknown as WidgetKeys;

  const widgetSearch = `?widget=${WIDGETS[widgetKey]}`;

  routes.CONTRIBUTIONS.WIDGETS[widgetKey] = {
    href: `${baseRoute}${widgetSearch}`,
  };
  routes.CONTRIBUTIONS.WIDGETS_SEARCH[widgetKey] = widgetSearch;
});

export default routes;

import {useState} from 'react';

export type ApiErrors =
  | {
      [key: string]: string[];
    }
  | undefined;

export const useFormValidation = () => {
  const [apiErrors, setApiErrors] = useState<ApiErrors>();
  const validate = () => {
    if (apiErrors) {
      setApiErrors(undefined);
    }
  };

  return {
    apiErrors,
    setApiErrors,
    validate,
  };
};

const baseRoute = '/settings';

const routes = {
  href: baseRoute,
  ACCOUNT: {
    href: `${baseRoute}/account`,
  },
  PROFILE: {
    href: `${baseRoute}/profile`,
  },
  REGISTER: {
    href: `${baseRoute}/register`,
  },
  LOGIN: {
    href: `${baseRoute}/login`,
  },
  ABOUT: {
    href: `${baseRoute}/about`,
  },
  SOCIO_DEMO_DATA: {
    href: `${baseRoute}/socio-demo-data`,
  },
  ABOUT_TREE_PLANTING: {
    href: `${baseRoute}/about-tree-planting`,
  },
  ABOUT_INVITE_FRIENDS: {
    href: `${baseRoute}/about-invite-friends`,
  },
  FAQ: {
    href: `${baseRoute}/faq`,
  },
  PRIVACY: {
    href: `${baseRoute}/privacy`,
  },
  TERMS: {
    href: `${baseRoute}/terms`,
  },
  DEVICE_INFO: {
    href: `${baseRoute}/device-info`,
  },
  CONTACT: {
    href: `${baseRoute}/contact`,
    CATEGORY: {
      href: `${baseRoute}/contact/category`,
    },
    DETAILS: {
      href: `${baseRoute}/contact/details`,
    },
    FORM: {
      href: `${baseRoute}/contact/form`,
    },
  },
  REQUEST_DATA_DELETION: {
    href: `${baseRoute}/request-data-deletion`,
  },
  LOTTERY_FORM: {
    href: `${baseRoute}/lottery-form`,
  },
  NOTIFICATIONS: {
    href: `${baseRoute}/notifications`,
  },
  WIDGETS_CUSTOMIZATION: {
    href: `${baseRoute}/widgets-customization`,
    WIDGET: {
      href: `${baseRoute}/widgets-customization/widget`,
    },
  },
  VEHICLE_SETTINGS: {
    href: `${baseRoute}/vehicle-settings`,
  },
  CARPOOLING_SETTING: {
    href: `${baseRoute}/carpooling-settings`,
  },
  E_CAR_RATING: {
    href: `${baseRoute}/e-car-rating`,
    FORM: {
      href: `${baseRoute}/e-car-rating/form`,
    },
  },
  BAMBOO_COIN: {
    href: `${baseRoute}/bamboo-coin`,
  },
};

export default routes;

import buttons from 'config/organizations/preset/buttons';

import Co2Heart from 'components/ClimateWidgets/Co2Token/Co2Heart/Co2Heart';
import contributionsTokenImg from 'components/ClimateWidgets/Co2Token/images/mini-heart.svg';

import {ReactComponent as LocationLinkIcon} from 'icons/darmstadt/location-link.svg';

import customActivitiesImgEn from 'components/ClimateWidgets/Mobility/images/darmstadt/custom-activities-en.svg';
import customActivitiesImgDe from 'components/ClimateWidgets/Mobility/images/darmstadt/custom-activities-de.svg';
import cyclingImg from 'components/ClimateWidgets/Mobility/images/darmstadt/cycling.svg';
import publicTransitImg from 'components/ClimateWidgets/Mobility/images/darmstadt/public-transit.svg';
import walkingImg from 'components/ClimateWidgets/Mobility/images/darmstadt/walking.svg';

import treePlantingImage from 'components/ClimateWidgets/TreePlanting/images/aachen/tree.svg';

import onboardingOneImg from 'components/Onboarding/images/darmstadt/onboarding-1.svg';
import onboardingTwoImg from 'components/Onboarding/images/darmstadt/onboarding-2.svg';
import onboardingThreeImg from 'components/Onboarding/images/darmstadt/onboarding-3.svg';
import onboardingGpsImg from 'components/Onboarding/images/darmstadt/onboarding-gps.svg';
import onboardingMotionImg from 'components/Onboarding/images/darmstadt/onboarding-motion.svg';
import onboardingTeamsImg from 'components/Onboarding/images/darmstadt/onboarding-teams.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/darmstadt/onboarding-privacy.svg';
import onboardingNotificationsImg from 'components/Onboarding/images/darmstadt/onboarding-notifications.svg';

import meteringWaterBanner from 'components/ClimateWidgets/Metering/icons/darmstadt/water-banner.svg';
import meteringWaterSummaryIcon from 'components/ClimateWidgets/Metering/icons/darmstadt/water-progress-icon.svg';
import {ReactComponent as MeteringTokenIcon} from 'components/ClimateWidgets/Metering/icons/darmstadt/token.svg';

import water0 from 'components/ClimateWidgets/Metering/components/Consumption/images/badges/darmstadt/water/0.svg';
import water1 from 'components/ClimateWidgets/Metering/components/Consumption/images/badges/darmstadt/water/1.svg';
import water2 from 'components/ClimateWidgets/Metering/components/Consumption/images/badges/darmstadt/water/2.svg';
import water3 from 'components/ClimateWidgets/Metering/components/Consumption/images/badges/darmstadt/water/3.svg';
import water4 from 'components/ClimateWidgets/Metering/components/Consumption/images/badges/darmstadt/water/4.svg';
import water5 from 'components/ClimateWidgets/Metering/components/Consumption/images/badges/darmstadt/water/5.svg';

const meteringWaterBadges = [water0, water1, water2, water3, water4, water5];

const config = {
  name: 'darmstadt',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Roboto',
          secondary: 'Roboto',
        },
        ios: {
          primary: 'Roboto',
          secondary: 'Roboto',
        },
      },
      de: {
        android: {
          primary: 'Roboto',
          secondary: 'Roboto',
        },
        ios: {
          primary: 'Roboto',
          secondary: 'Roboto',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#3A5885',
      secondary: '#FFFFFF',
      tertiary: '#9CABC2',
      darkBg: '#30454A',
      white: '#ffffff',
      white50: 'rgba(255, 255, 255, 0.5)',
      dark: '#30454A',
      black: '#30454A',
      gray: '#D8D9DA',
      disabled: '#dee2e6',
      navMenu: '#FFFFFF',
      glacierLight: '#80BCC3FA',
      sunDown: '#FFAFAF',
      ziggurat: '#BFDEE2',
      catSkillWhite: '#EAF5F6',
      purple: '#552C83',
    },
    components: {
      offers: {
        navBgColor: 'tertiary',
        activeTabColor: 'white',
        activeTabBgColor: 'primary',
        bodyBgColor: 'secondary',
        recoinsLeftBgColor: 'primary',
        recoinsLeftColor: 'white',
        recoinsRequiredBgColor: 'primary',
        recoinsRequiredTextColor: 'white',
        lessRecoinsLeftBgColor: '#FFF1D5',
        lessRecoinsLeftColor: 'black',
        locationTypeBgColor: 'primary',
        locationTypeColor: 'white',
        LocationLinkIcon,
      },
      climatePartnerMap: {
        bgColor: 'secondary',
      },
      global: {
        boxShadow: {
          border: '0.1px solid #ddd',
          boxShadow: '0px 0px 6px #00000029',
        },
      },
      dashboard: {
        bgColor: 'white',
      },
      competition: {
        bgColor: 'secondary',
      },
      nav: {
        bgColor: 'purple',
        menuColor: 'white',
      },
      widgetCard: {
        bgColor: 'secondary',
        titleBgColor: 'primary',
        borderRadius: '12px',
      },
      badges: {},
      treePlanting: {
        bgColor: 'secondary',
        treeImage: treePlantingImage,
        progressBgColor: '#EBEEF3',
      },
      links: {},
      buttons: {
        ...buttons,
      },
      mobilityIcons: {
        progressPathColor: 'primary',
        walking: {
          bgColor: 'transparent',
          img: walkingImg,
        },
        cycling: {
          bgColor: 'transparent',
          img: cyclingImg,
        },
        publicTransit: {
          bgColor: 'transparent',
          img: publicTransitImg,
        },
        customActivities: {
          bgColor: 'transparent',
          en: {
            img: customActivitiesImgEn,
          },
          de: {
            img: customActivitiesImgDe,
          },
        },
      },
      prompts: {
        bgColor: 'secondary',
        overlayBgColor: '#00000099',
      },
      customActivities: {
        detailsBgColor: 'secondary',
        listBgColor: 'white',
        successPromptColor: 'white',
      },
      settings: {
        bgColor: 'secondary',
        itemCaretColor: 'primary',
        itemBorderColor: 'primary',
      },
      co2Compensation: {
        pageBgColor: 'secondary',
        emptySunBgColor: 'secondary',
      },
      leaderboard: {
        bgColor: 'secondary',
      },
      inviteFriends: {
        progressBarBgColor: '#E4E4E4',
      },
      vouchers: {
        bgColor: 'white',
        active: {
          activeBgColor: 'tertiary',
          progressColor: 'primary',
          pendingBgColor: 'secondary',
          expiredBgColor: 'sunDown',
        },
      },
      news: {
        bgColor: 'white',
        titleBgColor: 'secondary',
        titleColor: 'primary',
      },
      form: {
        inputBgColor: 'transparent',
      },
      detailsNavigation: {
        bgColor: 'purple',
      },
      metering: {
        icons: {
          tokenIcon: MeteringTokenIcon,
          waterBanner: meteringWaterBanner,
          waterSummaryIcon: meteringWaterSummaryIcon,
          badges: {
            water: meteringWaterBadges,
          },
        },
        elements: ['water'],
        // inputBgColor: 'transparent',
        borderBottomColor: 'tertiary',
        prevResultsMonthBgColor: '#F1F1F1',
        updateReadingBgColor: '#E4E4E4',
        progressBar: {
          pathColor: 'primary',
          trailColor: '#E4E4E4',
          dangerPathColor: '#FF4D4D',
        },
        widget: {
          bgColor: '#E4E4E4',
          textColor: '#000000',
        },
      },
      summaryPage: {},
      pullToRefresh: {
        loaderColor: '#222',
      },
    },
    colors: {
      primary: '#3A5885',
      body: '#552C83',
      secondary: '#ffffff',
      white: '#ffffff',
      black: '#30454A',
      promptBg: '#ffffff',
      promptOverlayBg: '#00000099',
      onboardingBg: '#ffffff',
      teamRankingHighlight: '#FFF3D7',
    },
  },
  translations: {
    tokenTitle: 'sdk.web.darmstadt.contributions.your.token',
    co2Needed: 'sdk.web.darmstadt.contributions.savings.left.for.token',
    fullTokenText: 'sdk.web.darmstadt.contributions.full.token.text',
    offersTitle: 'sdk.web.darmstadt.offers.your.token',
    rewardType1Singular:
      'sdk.web.darmstadt.contributions.inviteFriends.rewardType1.singular',
    rewardType1Plural:
      'sdk.web.darmstadt.contributions.inviteFriends.rewardType1.plural',
    shortTokenSingular: 'sdk.web.darmstadt.offers.token.required.singular',
    shortTokenPlural: 'sdk.web.darmstadt.offers.token.required.plural',
    tokenFullSingular: 'sdk.web.darmstadt.token.singular',
    tokenFullPlural: 'sdk.web.darmstadt.token.plural',
    tokenAbbreviation: 'sdk.web.darmstadt.token.abbr',
    notEnoughTokens: 'sdk.web.darmstadt.offers.not.enough.tokens',
    aboutTokenLabel: 'sdk.web.darmstadt.settings.about.title',
    onBoarding: {
      one: {
        title: 'sdk.web.darmstadt.onboarding.one.title',
        content: 'sdk.web.darmstadt.onboarding.one.text',
        image: onboardingOneImg,
      },
      two: {
        title: 'sdk.web.darmstadt.onboarding.two.title',
        content: 'sdk.web.darmstadt.onboarding.two.text',
        image: onboardingTwoImg,
      },
      three: {
        title: 'sdk.web.darmstadt.onboarding.three.title',
        content: 'sdk.web.darmstadt.onboarding.three.text',
        image: onboardingThreeImg,
      },
      privacy: {
        title: 'sdk.web.onboarding.privacy.title',
        content: 'sdk.web.onboarding.privacy.text',
        confirmLink: 'sdk.web.onboarding.privacy.link',
        image: onboardingPrivacyImg,
      },
      gps: {
        title: 'sdk.web.darmstadt.onboarding.gps.title',
        content: 'sdk.web.darmstadt.onboarding.gps.text',
        image: onboardingGpsImg,
      },
      motion: {
        image: onboardingMotionImg,
      },
      teams: {
        image: onboardingTeamsImg,
      },
      notifications: {
        image: onboardingNotificationsImg,
      },
    },
    competition: {
      title: 'sdk.web.teams.germany.leaderboard.title',
      subtitle: 'sdk.web.teams.germany.leaderboard.subtitle',
    },
  },
  features: {
    hideAppClose: false,
    hasCompetition: true,
    hasCustomActivities: true,
    showCo2SavingsInfo: true,
    showContributionsToken: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hideTerms: true,
    hideLotsTab: false,
    hasMetering: true,
    hasLotteryForm: true,
    hasNewQRScanner: true,
    stage: {},
  },
  closeIconType: 'caret',
  applangaTag: '5f6f82943dce811cd115a353',
  matomoTrackingId: 3,
  tokenType: 'Heart',
  fullTokenImg: onboardingTwoImg,
  contributionsTokenImg,
  FullTokenComponent: Co2Heart,
};

export default config;

import React from 'react';
import styled from 'styled-components';

import {useTokenTranslation} from 'hooks/use-token-translation';

import Tag from './Tag';

const Wrapper = styled(Tag)<{bgColor?: string}>`
  background-color: ${(props) =>
    props.bgColor ||
    props.theme.components.offers.recoinsRequiredBgColor ||
    props.theme.colors.white};
  color: ${(props) =>
    props.theme.components.offers.recoinsRequiredTextColor };
`;

interface RecoinsLeft {
  recoins: number;
  bgColor?: string;
}

const RecoinsRequired = (props: RecoinsLeft) => {
  const {recoins, bgColor} = props;
  const {getShortTokenText} = useTokenTranslation();

  return (
    <Wrapper bgColor={bgColor}>
      {recoins} {getShortTokenText(recoins)}
    </Wrapper>
  );
};

export default RecoinsRequired;

import React from 'react';
import DialogBox from '../../../DialogBox/DialogBox';
import {useTranslations} from 'hooks/use-translations';

interface ErrorDialogProps {
  onConfirmation: () => void;
  message: string;
}

const ErrorDialog = (props: ErrorDialogProps) => {
  const {onConfirmation, message} = props;
  const {translate} = useTranslations();

  return (
    <DialogBox
      title={translate('sdk.web.onboarding.error.title')}
      onConfirmation={onConfirmation}
      promptMessage={message}
      singleText={translate('sdk.web.dialog.box.ok')}
    />
  );
};

export default ErrorDialog;

import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import routes from 'config/routes';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import InfoPage from 'components/InfoPage/InfoPage';

const AboutContent = styled.div`
  figcaption {
    text-align: center;
  }
`;

const AboutPage = (props: any) => {
  useBodyBg({type: 'primary'});
  const {translate} = useTranslations();
  const {userProfile} = useUserProfile();

  const aboutContent = [
    <AboutContent>
      <div
        dangerouslySetInnerHTML={{
          __html: userProfile?.organisation?.saving_water || '',
        }}
      />
    </AboutContent>,
  ];

  const onClose = () => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.DASHBOARD.href);
    }
  };

  return (
    <InfoPage
      title={translate('sdk.web.water.about.title')}
      rows={aboutContent}
      isLoading={false}
      onClose={onClose}
    />
  );
};

export default withRouter(AboutPage);

export enum TRANSACTION_TYPES_KEYS {
  CYCLING = 'cycling',
  WALKING = 'walking',
  PUBLIC_TRANSIT = 'publicTransit',
  TREE_PLANTED = 'treesPlanted',
  CUSTOM_ACTIVITIES = 'customActivities',
  CAR = 'car',
  PLANE = 'plane',
  METERING = 'metering',
}

export const TRANSACTION_TYPES = {
  CYCLING: {
    key: TRANSACTION_TYPES_KEYS.CYCLING,
    transactionTypes: [2],
    string: 'sdk.web.mobility.cycling',
  },
  WALKING: {
    key: TRANSACTION_TYPES_KEYS.WALKING,
    transactionTypes: [10],
    string: 'sdk.web.mobility.walking',
  },
  PUBLIC_TRANSIT: {
    key: TRANSACTION_TYPES_KEYS.PUBLIC_TRANSIT,
    transactionTypes: [3, 4],
    string: 'sdk.web.mobility.pt',
  },
  TREE_PLANTED: {
    key: TRANSACTION_TYPES_KEYS.TREE_PLANTED,
    transactionTypes: [35],
  },
  CUSTOM_ACTIVITIES: {
    key: TRANSACTION_TYPES_KEYS.CUSTOM_ACTIVITIES,
    transactionTypes: [25],
  },
  CAR: {
    key: TRANSACTION_TYPES_KEYS.CAR,
    transactionTypes: [6],
  },
  PLANE: {
    key: TRANSACTION_TYPES_KEYS.PLANE,
    transactionTypes: [5],
  },
  GAS: {
    key: TRANSACTION_TYPES_KEYS.METERING,
    transactionTypes: [75],
  },
};

const emissionKeys = [
  TRANSACTION_TYPES_KEYS.PLANE,
  TRANSACTION_TYPES_KEYS.METERING,
  TRANSACTION_TYPES_KEYS.CAR,
] as const;

export type EMISSION_TRANSACTIONS_KEYS = typeof emissionKeys[number];

export type TRANSACTION_TYPE = {
  key: TRANSACTION_TYPES_KEYS;
  transactionTypes: Array<number>;
  string?: string;
};

export const EMISSION_TRANSACTIONS = emissionKeys.map((key) => {
  const transactionKeys = Object.keys(TRANSACTION_TYPES) as Array<
    keyof typeof TRANSACTION_TYPES
  >;
  const transactionKey = transactionKeys.find(
    (_key) => TRANSACTION_TYPES[_key].key === key,
  );

  return TRANSACTION_TYPES[transactionKey!];
});

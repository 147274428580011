import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {usePromptApi} from 'hooks/usePromptApi';
import {PROMPTS} from 'constants/prompts';
import routes from 'config/routes';

import InfoPrompt from './InfoPrompt';

const SocioDemoPrompt = (props: any) => {
  const {prompts} = props;
  const history = useHistory();
  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.SOCIO_DEMO_DATA,
  });

  const handleCta = useCallback(() => {
    history.push(routes.SETTINGS.SOCIO_DEMO_DATA.href);
  }, [history]);

  if (!prompt) {
    return null;
  }

  return (
    <InfoPrompt
      isVisible={isVisible}
      onClose={handleModalClose}
      onCta={handleCta}
    />
  );
};

export default SocioDemoPrompt;

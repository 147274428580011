const baseRoute = '/dashboard/rewards';

const routes = {
  REWARDS: {
    href: baseRoute,
    DEALS: {
      href: `${baseRoute}/deals`,
      TERMS: {
        href: `${baseRoute}/deals/terms`,
      },
      VOUCHER_TERMS: {
        href: `${baseRoute}/deals/vouchers/terms`,
      },
      LOTTERY_TERMS: {
        href: `${baseRoute}/deals/lottery/terms`,
      },
    },
    VOUCHERS: {
      href: `${baseRoute}/vouchers`,
      NEW: {
        href: `${baseRoute}/vouchers/new`,
      },
      TERMS: {
        href: `${baseRoute}/vouchers/terms`,
      },
      HOW_TO: {
        href: `${baseRoute}/vouchers/how-to`,
      },
    },
    LOTS: {
      href: `${baseRoute}/lots`,
      HOW_TO: {
        href: `${baseRoute}/lots/how-to`,
      },
    },
    CLIMATE_PARTNER: {
      href: `${baseRoute}/climate-partner`,
    },
    NEARBY_OFFERS: {
      href: `${baseRoute}/nearby-offers`,
    },
  },
};

export default routes;

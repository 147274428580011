import React, {useCallback, useContext, useMemo, useState} from 'react';
import styled from 'styled-components';

import {SettingsContext} from 'contexts/SettingsContext';
import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import useElementTranslation from './hooks/use-element-translation';
import useMeteringElements from './hooks/use-metering-elements';
import {getAllUserStats} from 'services/metering';
import {formatNumber} from 'utils/numbers';
import {addGlobalSquareCorners} from 'utils/theme';
import {getStatsData} from './utils';
import {AllMeteringStats, ElementData, METERING_ELEMENTS} from 'types/Metering';
import {WidgetGroupsData} from 'types/WidgetCustomization';

import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import TextMedium from 'components/Text/TextMedium/TextMedium';
import CopyText from 'components/Text/CopyText/CopyText';
import Loader from 'components/Loader/Loader';

import ProgressIcon from './components/ProgressIcon';
import Element from './components/Element';

import {ReactComponent as CaretIcon} from 'icons/caret-right-lg.svg';
import {ReactComponent as WarningIcon} from 'icons/warning.svg';

const Wrapper = styled.div`
  .metering {
    &__header {
      margin-bottom: 10px;
    }

    &__widget {
      background-color: ${(props) =>
        props.theme.components.metering?.widget?.bgColor ||
        props.theme.colors.secondary};
      padding: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      border-radius: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      ${addGlobalSquareCorners()}
    }

    &__info {
      position: absolute;
      top: 0;
      right: 15px;
      z-index: 1;
      width: 26px;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__progress {
      margin-right: 10px;
    }

    &__title {
      margin-bottom: 5px;
      font-family: ${(props) => props.theme.font.secondary.name};
    }

    &__details {
      flex-grow: 1;
    }

    &__caret-icon {
      flex-shrink: 0;
      width: 25px;
      fill: ${(props) =>
        props.theme.components.metering?.widget?.caretColor ||
        props.theme.colors.primary};
      padding: 7.5px;
    }

    &__warning-icon {
      flex-shrink: 0;
      width: 23px;
      fill: #ff4d4d;
    }
  }
`;

interface MeteringProps {
  widgetGroupsData: WidgetGroupsData[];
}

const Metering = (props: MeteringProps) => {
  const {widgetGroupsData} = props;
  const translate = useElementTranslation();
  const {organizationConfig} = useContext(SettingsContext);
  const meteringElements = useMeteringElements();

  const [activeWidget, setActiveWidget] = useState<
    {element: METERING_ELEMENTS; elementData: ElementData} | undefined
  >();

  const meteringConfig = organizationConfig.theme.components.metering;

  const elements: Array<ElementData> = useMemo(() => {
    const meteringFeatures = widgetGroupsData.find(
      ({group}) => group === 'metering',
    );

    return meteringElements.filter(
      (elementData) =>
        !meteringFeatures ||
        meteringFeatures?.types.find(
          ({type}) => type.replace('metering_', '') === elementData.element,
        )?.enabled,
    );
  }, [meteringElements, widgetGroupsData]);

  const {
    data: allStats,
    fetchData: reFetchAllStats,
    isLoading,
  }: {
    data: AllMeteringStats;
    fetchData: () => void;
    isLoading: boolean;
  } = useFetcher({
    key: CACHE_KEYS.METERING.ALL_STATS,
    fetcher: getAllUserStats,
    initialValue: {},
  });

  const widgets = useMemo(() => {
    if (!allStats) {
      return [];
    }

    return elements
      .filter((element) =>
        meteringConfig?.elements?.length
          ? meteringConfig.elements.includes(element.element)
          : true,
      )
      .map(({element, icon}) => {
        const connected = allStats[element]?.connected;
        const stats = connected ? allStats[element]! : undefined;
        const {overBudget, progressPercent, value, valueKey} = getStatsData({
          stats,
        });
        const showWarning = !!(
          stats &&
          stats.has_previous_month_consumption &&
          !stats.has_current_month_consumption
        );

        const elementData = elements.find(
          (meteringElement) => element === meteringElement.element,
        ) || {
          element,
          icon: '',
          banner: '',
          successBanner: '',
          resultsIcon: '',
          progressPathColor: '',
          showWarning: false,
        };
        elementData.showWarning = showWarning;

        return {
          element,
          elementData,
          icon,
          connected,
          progressPercent,
          overBudget,
          showWarning,
          title: translate(`sdk.web.meter.widget.${element}`, undefined, {
            element,
          }),
          cta: stats ? (
            <span>
              {translate(
                'sdk.web.meter.widget.used',
                {
                  key: '{value}',
                  value: formatNumber({
                    number: stats.current_consumption,
                    dp: 1,
                    ignoreThousandRule: true,
                  }),
                },
                {element},
              )}
              <br />
              {translate(
                `sdk.web.meter.widget.${valueKey}`,
                {
                  key: '{value}',
                  value: formatNumber({
                    number: value,
                    dp: 1,
                    ignoreThousandRule: true,
                  }),
                },
                {element},
              )}
            </span>
          ) : (
            translate('sdk.web.meter.widget.cta', undefined, {element})
          ),
        };
      });
  }, [translate, allStats, elements, meteringConfig]);

  const handleWidgetClose = useCallback(() => {
    setActiveWidget(undefined);
  }, []);

  if (widgets.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <WidgetCard>
        <div className="metering__header">
          <WidgetTitle
            aboutInfo={{
              title: translate('sdk.web.meter.widget.title'),
              userProfilePath: 'organisation.metering_description',
              enabled: true,
            }}>
            {translate('sdk.web.meter.widget.title')}
          </WidgetTitle>
        </div>
        {isLoading ? (
          <Loader color="#222" />
        ) : (
          <div className="metering__list">
            {widgets.map((widget) => (
              <div
                key={widget.element}
                className="metering__widget"
                onClick={() =>
                  setActiveWidget({
                    element: widget.element,
                    elementData: widget.elementData,
                  })
                }>
                <div className="metering__progress">
                  <ProgressIcon
                    percent={widget.progressPercent}
                    icon={widget.icon}
                    alt={widget.title}
                    danger={widget.overBudget}
                    pathColor={widget.elementData.progressPathColor}
                    trailColor="#FFFFFF"
                    hideTrail={!widget.progressPercent}
                  />
                </div>
                <div className="metering__details">
                  <div className="metering__title">
                    <TextMedium>{widget.title}</TextMedium>
                  </div>
                  <div className="metering__cta">
                    <CopyText>{widget.cta}</CopyText>
                  </div>
                </div>
                {widget.showWarning ? (
                  <div className="metering__warning-icon">
                    <WarningIcon />
                  </div>
                ) : (
                  <div className="metering__caret-icon">
                    <CaretIcon />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </WidgetCard>
      {activeWidget && (
        <Element
          key={activeWidget.element}
          element={activeWidget.element}
          elementData={activeWidget.elementData}
          onClose={handleWidgetClose}
          onRefresh={reFetchAllStats}
          stats={allStats[activeWidget.element]}
        />
      )}
    </Wrapper>
  );
};

export default Metering;

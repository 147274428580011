import {fetchAPiClient} from './api';
import {WidgetCustomization} from 'types/WidgetCustomization';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

export const getWidgetsCustomizations = async () => {
  const client = defaultApiClient;
  const url = '/user-feature';
  const {data} = await client.get(url);
  return data;
};

export const toggleWidget = async (
  data: WidgetCustomization,
): Promise<WidgetCustomization> => {
  const client = defaultApiClient;
  const url = '/user-feature/toggle';
  return client.post(url, data);
};

import React from 'react';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';

import CopyText from 'components/Text/CopyText/CopyText';
import Page from './Page';

const Home = () => {
  const history = useHistory();
  const {translate} = useTranslations();

  return (
    <Page
      title={translate('sdk.web.eCar.title')}
      btn={{
        text: translate('sdk.web.eCar.cta'),
        onClick: () => history.push(routes.SETTINGS.E_CAR_RATING.FORM.href),
      }}>
      <CopyText
        as="div"
        dangerouslySetInnerHTML={{__html: translate('sdk.web.eCar.info')}}
      />
    </Page>
  );
};

export default Home;

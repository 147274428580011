import {useCallback, useContext, useEffect} from 'react';

import {AuthContext} from 'contexts/AuthContext';
import {SettingsContext} from 'contexts/SettingsContext';

const useFeaturesSetup = () => {
  const {auth} = useContext(AuthContext);
  const {setOrganizationConfig} = useContext(SettingsContext);

  const reloadFeatures = useCallback(
    () => {
      if (!auth?.env) {
        return;
      }

      setOrganizationConfig((config: Record<string, any>) => ({
        ...config,
        features: {
          ...config.features,
          ...(config.features?.[auth.env] || null),
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, setOrganizationConfig],
  );

  useEffect(() => {
    reloadFeatures();
  }, [auth, reloadFeatures]);

  return {
    reloadFeatures,
  };
};

export default useFeaturesSetup;

import {useCallback, useContext, useEffect, useState} from 'react';

import {APP_PERMISSIONS} from 'constants/app-features';
import {SettingsContext} from 'contexts/SettingsContext';

export const usePermissionsCheck = () => {
  const {
    isMotionTrackingEnabled,
    isGPSEnabled,
    isMotionFitnessEnabled,
    isBatteryOptimizationEnabled,
    appPermissions,
  } = useContext(SettingsContext);

  const [permissionTitleKey, setPermissionTitleKey] = useState('');
  const [permissionMessageKey, setPermissionMessageKey] = useState('');
  const [hasAllPermissions, setHasAllPermissions] = useState(true);

  const hasNotifications = appPermissions.includes('notification');

  useEffect(() => {
    const motionCheck =
      !appPermissions.includes(APP_PERMISSIONS.MOTION) ||
      isMotionFitnessEnabled;

    switch (true) {
      case !motionCheck && !isGPSEnabled:
        setPermissionTitleKey('sdk.web.permission.location.motion.turned.off');
        setPermissionMessageKey('sdk.web.permission.location.motion.activate');
        setHasAllPermissions(false);
        break;
      case !motionCheck && isGPSEnabled:
        setPermissionTitleKey('sdk.web.permission.motion.turned.off');
        setPermissionMessageKey('sdk.web.permission.motion.activate');
        setHasAllPermissions(false);
        break;
      case motionCheck && !isGPSEnabled:
        setPermissionTitleKey('sdk.web.permission.location.turned.off');
        setPermissionMessageKey('sdk.web.permission.location.activate');
        setHasAllPermissions(false);
        break;
      case appPermissions.includes(APP_PERMISSIONS.BATTERY_OPTIMIZATION) &&
        isBatteryOptimizationEnabled:
        setPermissionTitleKey('');
        setPermissionMessageKey('sdk.web.permission.battery.optimization.cta');
        setHasAllPermissions(false);
        break;
      default:
        setHasAllPermissions(true);
        break;
    }
  }, [
    isMotionFitnessEnabled,
    isGPSEnabled,
    isBatteryOptimizationEnabled,
    appPermissions,
  ]);

  const getMissingPermissions = useCallback(
    () =>
      appPermissions.filter(
        (permission: string) =>
          (permission === APP_PERMISSIONS.GPS && !isGPSEnabled) ||
          (permission === APP_PERMISSIONS.MOTION && !isMotionFitnessEnabled) ||
          // Send battery optimization only when gps and motion (if available) has been enabled
          (permission === APP_PERMISSIONS.BATTERY_OPTIMIZATION &&
            isGPSEnabled &&
            (!appPermissions.includes(APP_PERMISSIONS.MOTION) ||
              isMotionFitnessEnabled) &&
            isBatteryOptimizationEnabled),
      ),
    [
      appPermissions,
      isGPSEnabled,
      isMotionFitnessEnabled,
      isBatteryOptimizationEnabled,
    ],
  );

  return {
    isMotionTrackingEnabled,
    permissionTitleKey,
    permissionMessageKey,
    hasAllPermissions,
    getMissingPermissions,
    hasNotifications,
  };
};

export const MAX_COMPARISON = 80;

export const SETTINGS_LIMIT = {
  SIZE_OF_APARTMENT: {
    MIN: 20,
    MAX: 500,
  },
  HOUSEHOLD_PEOPLE: {
    MIN: 1,
    MAX: 10,
  },
  APPLIANCES_COUNT: {
    MIN: 0,
    MAX: 10,
  },
};

import {SyntheticEvent, useCallback, useEffect} from 'react';
import {
  addVoucherToWishlist,
  removeVoucherFromWishlist,
} from 'services/vouchers';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import {useToast} from 'hooks/use-toast';

export type UpdateVoucherWishlist = (data: {
  id: number;
  wishlisted: boolean;
}) => void;

type WishlistProps = {
  id: number;
  wishlisted: boolean;
  updateVoucherWishlist: UpdateVoucherWishlist;
};

export const useWishlist = (props: WishlistProps) => {
  const {id, wishlisted, updateVoucherWishlist} = props;
  const {translate} = useTranslations();
  const toast = useToast();

  const [
    isUpdatingWishList,
    startUpdatingWishlist,
    stopUpdatingWishlist,
  ] = useBoolean(false);
  const [isWishlist, addWishlist, removeWishlist] = useBoolean(wishlisted);

  useEffect(
    () => {
      wishlisted ? addWishlist() : removeWishlist();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wishlisted],
  );

  const handleWishlist = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      startUpdatingWishlist();
      try {
        await (isWishlist
          ? removeVoucherFromWishlist({id})
          : addVoucherToWishlist({id}));

        updateVoucherWishlist({id, wishlisted: !isWishlist});

        isWishlist ? removeWishlist() : addWishlist();

        stopUpdatingWishlist();
        toast.success(
          isWishlist
            ? translate('sdk.web.vouchers.wishlist.remove.success')
            : translate('sdk.web.vouchers.wishlist.add.success'),
        );
      } catch (e: any) {
        stopUpdatingWishlist();
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.vouchers.wishlist.update.error',
        );
        toast.error(message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, isWishlist, addWishlist, removeWishlist],
  );

  return {
    handleWishlist,
    isWishlist,
    isUpdatingWishList,
  };
};

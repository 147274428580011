import React from 'react';
import styled from 'styled-components';

import useElementTranslation from '../hooks/use-element-translation';
import useVersionCheck from '../hooks/use-version-check';
import {ElementScreenProps} from '../types';

import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';

const Wrapper = styled.div`
  padding: 0 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .e-settings-confirmation {
    &__img {
      margin-bottom: 20px;
    }
  }
`;
const SettingsConfirmation = (props: ElementScreenProps) => {
  const {element, elementData, onClose, onComplete} = props;
  const translate = useElementTranslation({element});
  const {checkVersion, VersionPrompt} = useVersionCheck();

  return (
    <Wrapper className="e-settings-confirmation">
      <div>
        <Title>{translate('sdk.web.meter.settings.confirmation.title')}</Title>
        <img
          className="e-settings-confirmation__img"
          src={elementData.banner}
          alt="banner"
        />
        <div>
          <CopyText>
            {translate('sdk.web.meter.settings.confirmation.desc')}
          </CopyText>
        </div>
      </div>
      <Button
        onClick={() =>
          checkVersion({
            onSuccess: onComplete,
          })
        }>
        {translate('sdk.web.meter.settings.confirmation.cta')}
      </Button>
      <VersionPrompt onClose={onClose} />
    </Wrapper>
  );
};

export default SettingsConfirmation;

const baseRoute = '/co2-emissions';

const routes = {
  href: baseRoute,
  OFFSET: {
    href: `${baseRoute}/offset`,
  },
  ABOUT: {
    href: `${baseRoute}/about`,
  },
};

export default routes;

import {FuelType} from 'types/Mobility';

type GetEmissionsPerGram = {
  fuelType: FuelType;
  consumption: number;
};

export const getEmissionsPerGram = ({
  fuelType,
  consumption,
}: GetEmissionsPerGram) => (fuelType.co2_emissions * consumption) / 100;

export const usesElectricity = (fuelType?: FuelType) =>
  fuelType?.unit.toLowerCase() === 'kwh';

import {METERING_ELEMENTS} from 'types/Metering';

import {ElementTranslate} from 'components/ClimateWidgets/Metering/hooks/use-element-translation';

import electricity0 from '../images/badges/electricity/0.svg';
import electricity1 from '../images/badges/electricity/1.svg';
import electricity2 from '../images/badges/electricity/2.svg';
import electricity3 from '../images/badges/electricity/3.svg';
import electricity4 from '../images/badges/electricity/4.svg';
import electricity5 from '../images/badges/electricity/5.svg';

import gas0 from '../images/badges/gas/0.svg';
import gas1 from '../images/badges/gas/1.svg';
import gas2 from '../images/badges/gas/2.svg';
import gas3 from '../images/badges/gas/3.svg';
import gas4 from '../images/badges/gas/4.svg';
import gas5 from '../images/badges/gas/5.svg';

import water0 from '../images/badges/water/0.svg';
import water1 from '../images/badges/water/1.svg';
import water2 from '../images/badges/water/2.svg';
import water3 from '../images/badges/water/3.svg';
import water4 from '../images/badges/water/4.svg';
import water5 from '../images/badges/water/5.svg';

type BadgeElements = Record<METERING_ELEMENTS, Array<string>>;

const badgeElements: BadgeElements = {
  water: [water0, water1, water2, water3, water4, water5],
  gas: [gas0, gas1, gas2, gas3, gas4, gas5],
  electricity: [
    electricity0,
    electricity1,
    electricity2,
    electricity3,
    electricity4,
    electricity5,
  ],
};

const tiers = [
  {
    min: 0,
    max: 5,
    tier: 1,
  },
  {
    min: 5,
    max: 10,
    tier: 2,
  },
  {
    min: 10,
    max: 15,
    tier: 3,
  },
  {
    min: 15,
    max: 20,
    tier: 4,
  },
  {
    min: 20,
    tier: 5,
  },
];

interface GetBadgeParams {
  percentageSaved: number;
  element: METERING_ELEMENTS;
  translate: ElementTranslate;
  badgesIconConfig?: BadgeElements;
}

export const getBadge = ({
  percentageSaved,
  element,
  translate,
  badgesIconConfig,
}: GetBadgeParams) => {
  const matchedTier = tiers.find(
    ({min, max}) =>
      percentageSaved > min && (max ? percentageSaved <= max : true),
  );
  const tier = matchedTier?.tier || 0;

  return {
    image: badgesIconConfig?.[element]?.[tier] || badgeElements[element][tier],
    name: translate(`sdk.web.meter.badge.tier.${tier}`),
  };
};

import {ClimatePartner} from 'types/Offers';

interface AddSuffixIfExists {
  value?: string;
  suffix: string;
}

interface GetScaledFontSize {
  value: string;
  fontSize: number;
  maxChar: number;
}

export const addSuffixIfExists = ({value, suffix}: AddSuffixIfExists) => {
  return value ? `${value}${suffix}` : '';
};

export const getScaledFontSize = ({
  value,
  fontSize,
  maxChar,
}: GetScaledFontSize): number => {
  if (value.length <= maxChar) {
    return fontSize;
  }

  return (
    (1 - Math.log(((value.length - maxChar) / maxChar) * 10) / 10) *
    fontSize *
    0.85
  );
};

type Trim = {
  text: string;
  length: number;
};
export const trim = ({text, length}: Trim) => {
  if (text?.length > length) {
    const splitText = text.substring(0, length).split(' ');
    return `${splitText.slice(0, splitText.length - 1).join(' ')} ...`;
  }

  return text;
};

export const formatClimatePartnerAddress = ({
  climatePartner,
}: {
  climatePartner: undefined | ClimatePartner;
}) =>
  climatePartner
    ? [
        climatePartner.address,
        // climatePartner.postal_code,
        // climatePartner.city,
      ]
        .filter((value) => value)
        .join(', ')
    : '';

export const toBoolean = (value: any) => !!value && value !== 'false';

interface GlobalReplaceParams {
  text: string;
  search: string;
  replace: string;
}

export const globalReplace = ({text, search, replace}: GlobalReplaceParams) => {
  const escapedSearch = search.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(escapedSearch, 'g');
  return text.replace(regex, replace);
};

export const endsWithNumbers = ({text}: {text: string}) => {
  const regex = /\d+$/;
  return regex.test(text);
};

export const wrapLinksInAnchorTags = (text: string): string => {
  // Regular expression to match all URLs
  const urlRegex = /https?:\/\/[^\s<]+/g;

  // Function to check if the URL is already wrapped in an anchor tag
  const isUrlWrapped = (text: string, url: string): boolean => {
    const urlIndex = text.indexOf(url);
    if (urlIndex === -1) return false;
    const beforeUrl = text.slice(0, urlIndex);
    const afterUrl = text.slice(urlIndex + url.length);

    // Check if the URL is already within an <a> tag
    const beforeAnchor = beforeUrl.lastIndexOf('<a');
    const afterAnchor = afterUrl.indexOf('</a>');

    return (
      beforeAnchor !== -1 &&
      afterAnchor !== -1 &&
      beforeAnchor < beforeUrl.length - url.length
    );
  };

  // Replace URLs in the text with anchor tags if they are not already wrapped
  return text.replace(urlRegex, (url) => {
    if (isUrlWrapped(text, url)) {
      return url;
    }
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
};

import React from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {addGlobalBoxShadow, addGlobalSquareCorners} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Wrapper = styled.div`
  display: flex;
  background-color: ${(props) =>
    props.theme.components.vouchers?.bgColor ||
    props.theme.components.vouchers.wishlistBgColor ||
    '#DBF3F2'};
  color: ${(props) => props.theme.colors.black};
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;

  ${addGlobalSquareCorners()}
  ${addGlobalBoxShadow()}
`;

const Text = styled(CopyTextSm)`
  display: block;
`;

const WishListIntro = () => {
  const {translate} = useTranslations();

  return (
    <Wrapper>
      <Text>{translate('sdk.web.vouchers.wishlist.desc')}</Text>
    </Wrapper>
  );
};

export default WishListIntro;

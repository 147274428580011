const baseRoute = '/co2-compensation';

const routes = {
  href: baseRoute,
  ABOUT: {
    href: `${baseRoute}/about`,
  },
};

export default routes;

import React, {useCallback, useContext, useMemo, useState} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';
import {UserRankings} from 'types/Challenge';

import LeaderboardWidget from 'components/ClimateWidgets/Leaderboard/LeaderboardWidget';
import Ranking, {Rank} from './Ranking';
import TeamModal from './TeamModal';

type UserLeaderBoardProps = {
  isLoading: boolean;
  userRankings: UserRankings;
};

const UserLeaderboard = (props: UserLeaderBoardProps) => {
  const {userRankings, isLoading} = props;
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {hideUserRankingParticipants, hideUserRankingInCities} =
    useOrganization();

  const [selectedRank, setSelectedRank] = useState<Rank | undefined>();

  const isOwnTeam = useCallback(() => false, []);

  const rankings = useMemo(() => {
    const mobilityIcons =
      organizationConfig.theme.components.leaderboard.mobilityIcons;
    const cityName = translate(organizationConfig.translations.cityName);

    if (!userRankings || Object.keys(userRankings).length === 0) {
      return [];
    }

    const rankings = userRankings.teams.map((team) => ({
      key: team.team_id,
      name: translate('sdk.web.teams.user.ranking.team', {
        key: '{teamName}',
        value: team.team_name,
      }),
      participants: team.users_count,
      showParticipants: !hideUserRankingParticipants,
      position: team.user_rank.position,
      sources: team.user_rank.sources,
      hasDetails: team.user_rank.sources.length > 0,
      Icon: mobilityIcons?.All,
    }));

    if (
      !hideUserRankingInCities &&
      typeof userRankings.position !== 'undefined'
    ) {
      rankings.push({
        key: 0,
        name: translate('sdk.web.teams.user.ranking.total', {
          key: '{cityName}',
          value: cityName,
        }),
        participants: 0,
        showParticipants: false,
        position: userRankings.position,
        sources: userRankings.sources,
        hasDetails: userRankings.sources.length > 0,
        Icon: mobilityIcons?.All,
      });
    }

    return rankings;
  }, [
    userRankings,
    translate,
    hideUserRankingInCities,
    hideUserRankingParticipants,
    organizationConfig,
  ]);

  const handleRankingClick = useCallback(({ranking}: {ranking: Rank}) => {
    setSelectedRank(ranking);
  }, []);

  if (!isLoading && !rankings.length) {
    return null;
  }

  return (
    <>
      <LeaderboardWidget
        title={translate('sdk.web.teams.user.ranking.title')}
        isLoading={isLoading}
        isOwnTeam={isOwnTeam}
        minHeight="auto"
        challengeType={userRankings.challenge?.type}>
        <Ranking rankings={rankings} onClick={handleRankingClick} />
      </LeaderboardWidget>
      <TeamModal
        rank={selectedRank}
        onClose={() => setSelectedRank(undefined)}
      />
    </>
  );
};
export default UserLeaderboard;

import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import routes from 'config/routes/water';
import {useTranslations} from 'hooks/use-translations';

import CopyText from 'components/Text/CopyText/CopyText';
import Footer from './Footer';
import Button from './Button';
import CtaLink from './CtaLink';

const Content = styled.div`
  padding: 0 20px;
  text-align: center;
`;

const Text = styled(CopyText)`
  text-align: center;
  margin-bottom: 15px;
  display: block;
`;

const LearnMore = styled(CopyText)`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.black};
`;

type InfoProps = {
  onConnect: () => void;
  onCancel: () => void;
};

const Info = (props: InfoProps) => {
  const {onConnect, onCancel} = props;
  const {translate} = useTranslations();

  return (
    <>
      <Content>
        <Text>{translate('sdk.web.water.settings.info.text')}</Text>
        <Link to={routes.ABOUT.href}>
          <LearnMore>
            {translate('sdk.web.water.settings.info.learn')}
          </LearnMore>
        </Link>
      </Content>
      <Footer>
        <Button onClick={onConnect}>
          {translate('sdk.web.water.settings.info.connect')}
        </Button>
        <CtaLink onClick={onCancel}>
          {translate('sdk.web.water.settings.info.skip')}
        </CtaLink>
      </Footer>
    </>
  );
};

export default Info;

import React from 'react';
import styled, {css, keyframes, withTheme} from 'styled-components';

import {useBodyBg} from 'hooks/ui/use-body-bg';
import {addFontFamily} from 'utils/theme';

import Button from 'components/Button/Button';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import CopyText from 'components/Text/CopyText/CopyText';
import CloseIcon from 'components/CloseIcon/CloseIcon';

const slideLeft = keyframes`
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

const animation = css`
  ${slideLeft} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
`;

const Wrapper = styled.div`
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 51px 20px 40px;
  background: ${(props) => props.theme.colors.onboardingBg};
  color: ${(props) =>
    props.theme.colors.onboardingColor || props.theme.colors.black};
`;

const NavIcon = styled.div<{right?: boolean}>`
  fill: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  width: 25px;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled(HeaderText)`
  display: block;
  margin-bottom: 20px;
  animation: ${animation};
  order: 0;

  ${(props) =>
    addFontFamily({
      props,
      value: props.theme.components.onboarding?.titleFontFamily,
    })}
`;

const Rows = styled.div`
  display: block;
  animation: ${animation};
  margin-bottom: 20px;
  order: 3;
`;

export const ImgWrapper = styled.div<{imageOrder?: string}>`
  display: block;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  animation: ${animation};
  order: ${(props) => props.imageOrder || 1};
`;

export const Img = styled.img`
  width: 100%;
`;

const ComponentBlockWrapper = styled.div`
  order: 4;
`;

const Footer = styled.div`
  display: block;
`;

const ButtonContainer = styled.div`
  display: block;
`;

type OnboardingProps = {
  title: string;
  content: string;
  ComponentBlock?: any;
  onboardingImg: any;
  isImageTop?: boolean;
  imageOrder?: string;
  btnCta: string;
  children: any;
  onBtnClick: any;
  onClose: any;
};

const Onboarding = (props: any) => {
  const {
    title,
    content,
    ComponentBlock,
    onboardingImg,
    imageOrder,
    btnCta,
    onBtnClick,
    onClose,
  }: OnboardingProps = props;
  useBodyBg({type: 'onboarding'});

  return (
    <Wrapper>
      <Body>
        {onClose && (
          <NavIcon onClick={onClose}>
            <CloseIcon />
          </NavIcon>
        )}
        <Title>{title}</Title>
        {onboardingImg && (
          <ImgWrapper imageOrder={imageOrder}>
            <Img src={onboardingImg} alt="" />
          </ImgWrapper>
        )}
        <Rows>
          <CopyText dangerouslySetInnerHTML={{__html: content}} />
        </Rows>
        {!!ComponentBlock && (
          <ComponentBlockWrapper>{ComponentBlock}</ComponentBlockWrapper>
        )}
      </Body>
      <Footer>
        {props.children ? (
          props.children
        ) : (
          <ButtonContainer>
            <Button onClick={onBtnClick}>{btnCta}</Button>
          </ButtonContainer>
        )}
      </Footer>
    </Wrapper>
  );
};

export default withTheme(Onboarding);

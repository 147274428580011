import React from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {formatToken} from 'utils/numbers';

import CopyText from 'components/Text/CopyText/CopyText';

const Wrapper = styled.div`
  .available-coins {
    &__title {
      margin-bottom: 1px;
    }

    &__coins {
      font-size: 45px;
      font-weight: 700;
      color: ${(props) =>
        props.theme.components.wallets?.textColor ||
        props.theme.colors.primary};
    }
  }
`;

interface AvailableCoinsProps {
  coinBalance: number;
}

const AvailableCoins = (props: AvailableCoinsProps) => {
  const {coinBalance} = props;
  const {translate} = useTranslations();
  const {getTokenText} = useTokenTranslation();

  return (
    <Wrapper className="available-coins">
      <CopyText as="div" className="available-coins__title">
        <b>
          {translate('sdk.web.wallets.tokens.available', {
            key: '{tokens}',
            value: getTokenText(2),
          })}
        </b>
      </CopyText>
      <span className="available-coins__coins">
        {formatToken({
          number: coinBalance,
        })}
      </span>
    </Wrapper>
  );
};

export default AvailableCoins;

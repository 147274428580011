import React, {
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';

import {CACHE_KEYS} from 'constants/cache-keys';
import {ActivityContext} from 'contexts/ActivityContext';
import {WIDGETS} from 'constants/widgets';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import useActivitySummary from 'hooks/useActivitySummary';
import {useUserProfile} from 'hooks/use-user-profile';
import useEventsListener from 'hooks/events/use-events-listener';
import {getPrompts} from 'services/prompts';
import {getLeaderBoards} from 'services/teams';
import {ActivitySummary} from 'services/user';
import {getWidgetsCustomizations} from 'services/widgets-customization';
import {WidgetGroupsData} from 'types/WidgetCustomization';

import AppPrompts from 'components/AppPrompts/AppPrompts';
import OpenWidgetSlot from 'components/OpenWidgets/OpenWidgetSlot';
import PullToRefresh from 'components/PullToRefresh';

import Co2Token from 'components/ClimateWidgets/Co2Token/Co2Token';
import Co2Savings from 'components/ClimateWidgets/Co2Savings/Co2Savings';
import TreePlanting from 'components/ClimateWidgets/TreePlanting/TreePlanting';
import InviteFriends from 'components/ClimateWidgets/InviteFriends/InviteFriends';
import Co2Compensation from 'components/ClimateWidgets/Co2Compensation/Co2Compensation';
import CustomActivities from 'components/ClimateWidgets/CustomActivities/CustomActivities';
import WaterWidget from 'components/ClimateWidgets/WaterWidget';
import Badges from 'components/ClimateWidgets/Badges';
import Metering from 'components/ClimateWidgets/Metering';
import Co2Emissions from 'components/ClimateWidgets/Co2Emissions';
import ClimateTreasure from 'components/ClimateWidgets/ClimateTreasure';
import NewFeatures from 'components/ClimateWidgets/NewFeatures';
import SintraBanner from 'components/ClimateWidgets/SintraBanner';

const Wrapper = styled.div`
  // background-color: ${(props) =>
    props.theme.colors.page ||
    props.theme.colors.body ||
    props.theme.colors.primary};
  padding: 10px;
`;

const dummyWidgetRefMap = new Map<string, HTMLElement>();
type WidgetsRef = typeof dummyWidgetRefMap;

interface WidgetWrapperProps {
  widget: WIDGETS;
  widgetsRef: RefObject<WidgetsRef>;
  children: ReactNode;
}

const WidgetWrapper = (props: WidgetWrapperProps) => {
  const {widget, widgetsRef, children} = props;

  return (
    <div ref={(ref) => widgetsRef.current?.set(widget, ref!)}>{children}</div>
  );
};

const ContributionPage = () => {
  const {search} = useLocation();
  const history = useHistory();
  const {isTreePlantingActive} = useContext(ActivityContext);
  const {reloadUserProfile} = useUserProfile();
  const {
    hasCustomActivities,
    hasMetering,
    hasCo2Emissions,
    hasClimateTreasure,
    hasSintraBannerWidget,
  } = useOrganization();
  const [widgetKey, setWidgetKey] = useState<number>(Date.now());
  const widgetsRef = useRef(new Map());
  const {data: widgetGroupsData} = useFetcher<WidgetGroupsData[]>({
    fetcher: getWidgetsCustomizations,
    key: CACHE_KEYS.WIDGETS_CUSTOMIZATION,
    initialValue: [],
  });

  const {
    activitySummary,
    fetchActivitySummary,
  }: {
    activitySummary: ActivitySummary;
    fetchActivitySummary: () => any;
  } = useActivitySummary();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (!searchParams.get('widget')) {
      return;
    }

    const widget = searchParams.get('widget');
    const widgetRef = widgetsRef.current.get(widget);
    if (widgetRef) {
      setTimeout(() => {
        widgetRef.scrollIntoView({block: 'center'});
        setTimeout(() => {
          history.replace({search: '?'});
        }, 100);
      }, 50);
    }
  }, [searchParams, widgetsRef, history]);

  const {data: prompts, fetchData: fetchPrompts} = useFetcher({
    fetcher: getPrompts,
    params: {},
    initialValue: [],
  });

  const {
    data: leaderboards,
  }: {data: any; isLoading: boolean; fetchData: () => void} = useFetcher({
    fetcher: getLeaderBoards,
    params: {},
    key: CACHE_KEYS.LEADERBOARD,
    initialValue: [],
  });

  const handleRefresh = useCallback(async () => {
    await Promise.all([
      fetchActivitySummary(),
      fetchPrompts(),
      reloadUserProfile(),
    ]);
    setWidgetKey(Date.now());
  }, [fetchActivitySummary, fetchPrompts, reloadUserProfile]);

  useEventsListener({
    event: 'tokensUpdated',
    callback: fetchActivitySummary,
  });

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh}>
        <Wrapper>
          <WidgetWrapper widget={WIDGETS.CO2_TOKEN} widgetsRef={widgetsRef}>
            <Co2Token activitySummary={activitySummary} />
          </WidgetWrapper>
          <OpenWidgetSlot position="after-token" load={true} />

          <NewFeatures />

          {hasClimateTreasure && (
            <WidgetWrapper
              widget={WIDGETS.CLIMATE_TREASURE}
              widgetsRef={widgetsRef}>
              <ClimateTreasure key={`climate-treasure-${widgetKey}`} />
            </WidgetWrapper>
          )}
          <OpenWidgetSlot position="after-water-fountain" />

          <InviteFriends activitySummary={activitySummary} />
          <OpenWidgetSlot position="after-invite-friends" />

          {isTreePlantingActive && (
            <WidgetWrapper
              widget={WIDGETS.TREE_PLANTING}
              widgetsRef={widgetsRef}>
              <TreePlanting activitySummary={activitySummary} />
            </WidgetWrapper>
          )}
          <OpenWidgetSlot position="after-tree-planting" />

          <WidgetWrapper widget={WIDGETS.BADGES} widgetsRef={widgetsRef}>
            <Badges key={`badges-${widgetKey}`} />
          </WidgetWrapper>
          <OpenWidgetSlot position="after-badges" />

          {hasMetering && (
            <WidgetWrapper widget={WIDGETS.METERING} widgetsRef={widgetsRef}>
              <Metering
                key={`metering-${widgetKey}`}
                widgetGroupsData={widgetGroupsData}
              />
            </WidgetWrapper>
          )}

          <WaterWidget key={`water-${widgetKey}`} />

          {hasCustomActivities && (
            <WidgetWrapper
              widget={WIDGETS.CUSTOM_ACTIVITIES}
              widgetsRef={widgetsRef}>
              <CustomActivities />
            </WidgetWrapper>
          )}
          <OpenWidgetSlot position="after-custom-activities" />

          <Co2Compensation key={`co2-compensation-${widgetKey}`} />
          <OpenWidgetSlot position="after-compensations" />

          <WidgetWrapper widget={WIDGETS.CO2_SAVINGS} widgetsRef={widgetsRef}>
            <Co2Savings activitySummary={activitySummary} />
          </WidgetWrapper>
          <OpenWidgetSlot position="after-co2-savings" />

          {hasCo2Emissions && (
            <WidgetWrapper
              widget={WIDGETS.CO2_EMISSIONS}
              widgetsRef={widgetsRef}>
              <Co2Emissions activitySummary={activitySummary} />
            </WidgetWrapper>
          )}

          <OpenWidgetSlot position="after-co2-emissions" />

          {hasSintraBannerWidget && <SintraBanner />}
        </Wrapper>
      </PullToRefresh>
      <AppPrompts
        prompts={prompts}
        activitySummary={activitySummary}
        leaderboards={leaderboards}
      />
    </>
  );
};

export default ContributionPage;

import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {formatAmount} from 'utils/numbers';
import {addGlobalSquareCorners} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import TextXs from 'components/Text/TextXs/TextXs';
import DialogBox from 'components/DialogBox/DialogBox';
import MonthPicker from './MonthPicker';
import PaymentForm from './PaymentForm';

import {Compensation, CompensationType, ImpactType} from 'types/Compensation';

const Card = styled.div`
  position: relative;
  margin-bottom: 30px;
  width: calc(50% - 5px);
  border-radius: 6px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;

  ${(props) => {
    if (props.theme.components.co2Compensation?.hasSquareCorners) {
      return `
        border-radius: 0;
      `;
    }
    return '';
  }}

  ${addGlobalSquareCorners()}
`;

const CardLink = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const Recoins = styled(TextXs)`
  display: block;
  position: absolute;
  background-color: ${(props) =>
    props.theme.components.co2Compensation.pageBgColor ||
    props.theme.colors.secondary};
  top: 10px;
  left: 10px;
  padding: 3px 6px;
  border-radius: 6px;

  ${(props) => {
    if (props.theme.components.co2Compensation?.hasSquareCorners) {
      return `
        border-radius: 0;
      `;
    }
    return '';
  }}

  ${addGlobalSquareCorners()}
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 127px;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const Text = styled(CopyTextSm)<{fontWeight?: number}>`
  margin-bottom: 5px;
  display: block;
  font-weight: ${(props) => props.fontWeight || 'normal'};
`;

const Footer = styled.div`
  display: block;
  width: 100%;
  padding-top: 30px;
`;

const Pricing = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Price = styled(CopyTextSm)`
  display: block;
  background-color: ${(props) =>
    props.theme.components.co2Compensation?.priceBgColor ||
    props.theme.components.co2Compensation.pageBgColor ||
    props.theme.colors.secondary};
  padding: 2px 10px;
  border-radius: 6px;
  font-size: ${(props) =>
    props.theme.components.co2Compensation?.priceFontSize}px;

  ${(props) => {
    if (props.theme.components.co2Compensation?.hasSquareCorners) {
      return `
        border-radius: 0;
      `;
    }
    return '';
  }}

  ${addGlobalSquareCorners()}
`;

const Sale = styled(TextXs)`
  display: block;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 15px;
  width: 100%;
  text-align: right;
`;

type ProjectProps = {
  compensation: Compensation;
};

const Project = (props: ProjectProps) => {
  const {compensation} = props;
  const params = useParams<{month?: string}>();
  const {getShortTokenText} = useTokenTranslation();
  const {translate} = useTranslations();
  const [isPaymentFormOpen, openPaymentForm, closePaymentForm] = useBoolean(
    false,
  );
  const [
    hasSubscribedError,
    showSubscribedError,
    hideSubscribedError,
  ] = useBoolean(false);
  const [selectedMonth, setSelectedMonth] = useState<number>();

  const header = useMemo(
    () =>
      compensation.type === CompensationType.ONE_TIME
        ? compensation.impact === ImpactType.SINGLE
          ? translate('sdk.web.compensation.single.time')
          : translate('sdk.web.compensation.double.time')
        : compensation.impact === ImpactType.SINGLE
        ? translate('sdk.web.compensation.plant.monthly')
        : translate('sdk.web.compensation.double.plant.monthly'),
    [compensation, translate],
  );

  const grossPrice = useMemo(
    () => formatAmount({number: compensation.gross_price, dp: 2}),
    [compensation.gross_price],
  );

  const handleMonthChange = useCallback((month: number) => {
    setSelectedMonth(+month);
  }, []);

  const handleCardClick = useCallback(() => {
    if (compensation.subscribed) {
      return showSubscribedError();
    }

    openPaymentForm();
  }, [compensation.subscribed, showSubscribedError, openPaymentForm]);

  return (
    <>
      <Card>
        <CardLink onClick={handleCardClick} />
        <Image src={compensation.image} alt={compensation.title} />
        <Recoins>
          + {compensation.recoins} {getShortTokenText(compensation.recoins)}
        </Recoins>
        <Content>
          <div>
            <Text fontWeight={500}>{header}</Text>
            <Text>{compensation.title}</Text>
          </div>
          <Footer>
            {compensation.sale && (
              <Sale dangerouslySetInnerHTML={{__html: compensation.sale}} />
            )}
            <Pricing>
              <div>
                {compensation.type === CompensationType.ONE_TIME && (
                  <MonthPicker
                    initialValue={
                      typeof params.month !== 'undefined'
                        ? Number(params.month)
                        : undefined
                    }
                    onMonthChange={handleMonthChange}
                  />
                )}
              </div>
              <Price>{grossPrice} €</Price>
            </Pricing>
          </Footer>
        </Content>
      </Card>
      {isPaymentFormOpen && (
        <PaymentForm
          compensation={compensation}
          month={selectedMonth}
          onClose={closePaymentForm}
        />
      )}
      {hasSubscribedError && (
        <DialogBox
          title={translate('sdk.web.compensation.error.alert.title')}
          promptMessage={translate('sdk.web.compensation.error.subscribed')}
          singleText={translate('sdk.web.dialog.box.ok')}
          onConfirmation={hideSubscribedError}
        />
      )}
    </>
  );
};

export default Project;

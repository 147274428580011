interface pusherConfig {
  keys: {
    [key: string]: string;
  };
}

const config: pusherConfig = {
  keys: {
    development: 'cacda1986087b8748205',
    stage: '2e76a902506272bb21ac',
    production: '2ea25d78dccf940cc757',
  },
};

export default config;

import {METERING_ELEMENTS, METERING_UNITS, MeteringStats} from 'types/Metering';

export const getUnitString = ({
  element,
}: {
  element: METERING_ELEMENTS;
}): METERING_UNITS => {
  if (element === METERING_ELEMENTS.WATER) {
    return METERING_UNITS.LITRE;
  }

  return METERING_UNITS.KWH;
};

export const getStatsData = ({stats}: {stats?: MeteringStats}) => {
  if (!stats) {
    return {
      overBudget: false,
      percent: 0,
      progressPercent: 0,
      value: 0,
      valueKey: '',
    };
  }

  const overBudget = stats.current_consumption > stats.goal;
  const percent = Math.abs(
    100 - (stats.current_consumption / stats.goal) * 100,
  );
  const progressPercent = Math.min(percent, 100);

  return {
    overBudget,
    percent,
    progressPercent,
    value: Math.abs(stats.goal - stats.current_consumption),
    valueKey: overBudget ? 'over.budget' : 'available',
  };
};

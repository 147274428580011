import {fetchAPiClient} from './api';
import {Badge} from 'types/Badge';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

export const getBadges = async () => {
  const client = defaultApiClient;
  const url = '/badge';
  const response = await client.get(url);
  return response?.data;
};

export const sortByDateOrder = (a: Badge, b: Badge) =>
  (b.achieved_at
    ? new Date(b.achieved_at.replace(/-/g, '/')).getTime()
    : 1 / (b.order + 1)) -
  (a.achieved_at
    ? new Date(a.achieved_at.replace(/-/g, '/')).getTime()
    : 1 / (a.order + 1));

import React, {useContext} from 'react';
import styled from 'styled-components';

import {usePermissionsCheck} from 'hooks/use-permissions-check';
import {useTranslations} from 'hooks/use-translations';
import {addBoldText, addGlobalSquareCorners} from 'utils/theme';

import CopyText from '../Text/CopyText/CopyText';
import SettingsButton from '../Button/SettingsButton/SettingsButton';
import {SettingsContext} from 'contexts/SettingsContext';

const Wrapper = styled.div`
  display: block;
  position: absolute;
  z-index: 40000;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  color: ${(props) => props.theme.colors.black};
`;

const Overlay = styled.div`
  display: block;
  padding: 30% 6% 0;
  height: 100%;
  background: ${(props) => props.theme.colors.promptOverlayBg};
`;

const ContentBox = styled.div`
  background-color: #fcd921;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  padding: 10px;

  ${addGlobalSquareCorners()}
`;

const Title = styled(CopyText)`
  display: block;
  padding-top: 10px;
  margin-bottom: 10px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Message = styled(CopyText)`
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const Cta = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
`;

const CtaBtn = styled(CopyText)`
  min-width: 30%;
  display: block;
  padding: 10px 0;
  cursor: pointer;
  vertical-align: middle;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const LocationDialogBox = (props: any) => {
  const {onConfirmation} = props;
  const {translate} = useTranslations();
  const {appName} = useContext(SettingsContext);
  const {permissionTitleKey, permissionMessageKey} = usePermissionsCheck();

  return (
    <React.Fragment>
      <Wrapper>
        <Overlay>
          <ContentBox>
            <Title>
              {translate(permissionTitleKey, {
                key: '{appName}',
                value: appName,
              })}
            </Title>
            <Message>
              {translate(permissionMessageKey, {
                key: '{appName}',
                value: appName,
              })}
            </Message>
            <Cta>
              <CtaBtn onClick={() => onConfirmation(false)}>
                {translate('sdk.web.permission.cancel')}
              </CtaBtn>
              <CtaBtn onClick={() => onConfirmation(true)}>
                <SettingsButton>
                  {translate('sdk.web.permission.go.to.settings')}
                </SettingsButton>
              </CtaBtn>
            </Cta>
          </ContentBox>
        </Overlay>
      </Wrapper>
    </React.Fragment>
  );
};

export default LocationDialogBox;

import React, {useContext, useMemo} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {getOrganizationInfo} from 'services/user';

import InfoPage from 'components/InfoPage/InfoPage';

const AboutContent = styled.div`
  figcaption {
    text-align: center;
  }
`;

const AboutPage = (props: any) => {
  const {translate} = useTranslations();
  const {organizationConfig, appName} = useContext(SettingsContext);

  const {
    isLoading,
    data: organizationData,
  }: {isLoading: boolean; data: any} = useFetcher({
    fetcher: getOrganizationInfo,
    params: {},
    key: 'organizationInfo',
  });

  const {title, aboutContent} = useMemo(
    () => ({
      title: translate(organizationConfig.translations.aboutTokenLabel, {
        key: '{appName}',
        value: appName,
      }),
      aboutContent: [
        <AboutContent>
          <div
            dangerouslySetInnerHTML={{
              __html: organizationData
                ? organizationData.data?.description
                : '',
            }}
          />
        </AboutContent>,
      ],
    }),
    [translate, organizationConfig, organizationData, appName],
  );

  const onClose = (e: any) => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.SETTINGS.href);
    }
  };

  return (
    <InfoPage
      title={title}
      rows={aboutContent}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default withRouter(AboutPage);

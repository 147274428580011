import React from 'react';
import styled from 'styled-components';
import {TailSpin} from 'react-loader-spinner';

const Wrapper = styled.div<{sm: boolean}>`
  display: flex;
  width: 100%;
  min-height: ${(props) => (props.sm ? '40px' : '40vh')};
  max-height: 100%;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`;

type LoaderProps = {
  color?: string;
  sm?: boolean;
  width?: number;
  height?: number;
};

const Loader = (props: LoaderProps) => {
  const {color = '#fff', sm = false, ...rest} = props;

  return (
    <Wrapper sm={sm}>
      <TailSpin color={color} height={40} width={40} {...rest} />
    </Wrapper>
  );
};

export default Loader;

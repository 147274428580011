import {useCallback, useEffect} from 'react';

export const useBodyHiddenScroll = () => {
  const hideBodyScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
    window.scrollTo(0, -100);
  }, []);

  useEffect(() => {
    hideBodyScroll();

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [hideBodyScroll]);

  return {
    hideBodyScroll,
  };
};

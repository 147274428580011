import React, {useContext, useEffect} from 'react';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {SettingsContext} from 'contexts/SettingsContext';


const AppPageTracker = (props: any) => {
  const {trackPageView} = useMatomo();
  const {organizationConfig} = useContext(SettingsContext);

  useEffect(
    () => {
      trackPageView({
        documentTitle: `${organizationConfig.name} -  ${
          props.documentTitle || 'Root Page'
        }`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AppPageTracker;

import React, {SyntheticEvent, useContext, useMemo, useState} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import Countdown, {zeroPad} from 'react-countdown';

import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {SettingsContext} from 'contexts/SettingsContext';
import {PurchasedVoucher, VoucherType} from 'types/Offers';
import {addBoldText, addGlobalSquareCorners} from 'utils/theme';

import DialogBox from 'components/DialogBox/DialogBox';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import TextXs from 'components/Text/TextXs/TextXs';
import ImageCard from '../../ImageCard/ImageCard';
import LocationType from '../../LocationType/LocationType';
import RecoinsRequired from 'components/Rewards/Tags/RecoinsRequired';
import RecoinsLeft from 'components/Rewards/Tags/RecoinsLeft';
import Progress from './Progress';

import {ReactComponent as DeleteIcon} from 'icons/trash.svg';
import placeholderImg from '../images/placeholder.png';

const Item = styled(Link)`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  position: relative;

  &,
  :focus,
  :hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Text = styled(CopyTextSm)`
  display: block;
  text-decoration: none;
  margin-bottom: 10px;
`;

const DeleteVoucher = styled.div<{show?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props) => (props.show ? '72px' : '0')};
  background-color: #ffafaf;
  height: 100%;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: width 0.4s;
  overflow: hidden;

  ${addGlobalSquareCorners()}
`;

const DeleteVoucherIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  background-color: ${(props) => props.theme.colors.white};
  stroke: ${(props) => props.theme.colors.primary};

  svg {
    width: 25px;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const RecoinsWrapper = styled.div`
  margin: 0 5px;
`;

const ActiveDetails = styled(TextXs)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VOUCHER_STATES = {
  DEFAULT: 'DEFAULT',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
};

const dateLanguage = navigator.language || 'en-US';

type VoucherCardProps = {
  bgColor?: string;
  voucher: PurchasedVoucher;
  voucherUrl: string;
  isDeleteActive: boolean;
  showClimateIcon?: boolean;
  hideLocation?: boolean;
  toggleDeleteActions: () => void;
  reloadVouchers: () => void;
  removeVoucher?: {
    title: string;
    desc: string;
    delete: ({id}: {id: number}) => void;
  };
};

const VoucherCard = (props: VoucherCardProps) => {
  const {
    bgColor,
    voucher,
    voucherUrl,
    isDeleteActive,
    hideLocation,
    toggleDeleteActions,
    removeVoucher,
    reloadVouchers,
  } = props;
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);

  const [isVoucherExpired, expireVoucher] = useBoolean();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<any>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const purchasedDate = new Date(voucher.purchased_at as Date);
  const recoinsTagBgColor =
    organizationConfig.theme.components.vouchers?.recoinsTagBgColor;
  const recoinsLeftBgColor =
    organizationConfig.theme.components.vouchers?.recoinsLeftBgColor ||
    organizationConfig.theme.components.vouchers?.recoinsTagBgColor;

  const voucherState = useMemo(() => {
    if (
      voucher.offer?.offer_type_id === VoucherType.ONLINE ||
      !voucher.purchased_at
    ) {
      return VOUCHER_STATES.DEFAULT;
    }

    if (
      !isVoucherExpired &&
      moment().isBefore(moment(voucher.purchased_at).add(5, 'minutes'))
    ) {
      return VOUCHER_STATES.ACTIVE;
    }

    return VOUCHER_STATES.EXPIRED;
  }, [voucher, isVoucherExpired]);

  const toggleDeleteDialogBox = () => {
    setIsDeleteDialogOpen(!isDeleteDialogOpen);
  };

  const handleSelectVoucherForDelete = (e: SyntheticEvent, voucher: any) => {
    e.preventDefault();
    setSelectedVoucher(voucher);
    toggleDeleteDialogBox();

    return false;
  };

  const handleVoucherDelete = async (shouldDeleteVoucher: boolean) => {
    if (!shouldDeleteVoucher) {
      toggleDeleteDialogBox();
      return;
    }

    try {
      setIsDeleting(true);
      await removeVoucher!.delete({id: selectedVoucher.id});
      setSelectedVoucher(null);
      setIsDeleting(false);

      reloadVouchers();
      toggleDeleteActions();
      toggleDeleteDialogBox();
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const message = translate(
        errorKey || 'sdk.web.vouchers.delete.fallback.error.message',
      );
      setErrorMessage(message);
      setIsDeleting(false);
    }
  };

  if (!voucher.offer) {
    return null;
  }

  return (
    <>
      <Item to={`${voucherUrl}/${voucher.id}`}>
        <ImageCard
          img={voucher.offer.image_file.url || placeholderImg}
          bgColor={bgColor}>
          <TextContainer>
            <Text>{voucher.offer.title}</Text>
            {voucherState === VOUCHER_STATES.DEFAULT ? (
              <TagsWrapper>
                {!hideLocation && (
                  <LocationType
                    voucher={voucher.offer}
                    optimizeDistance={true}
                  />
                )}
                <RecoinsWrapper>
                  <RecoinsRequired
                    recoins={voucher.offer.cost}
                    bgColor={recoinsTagBgColor}
                  />
                </RecoinsWrapper>
                <RecoinsLeft
                  offersCount={voucher.offer.remaining}
                  bgColor={recoinsLeftBgColor}
                />
              </TagsWrapper>
            ) : (
              <ActiveDetails>
                {voucherState === VOUCHER_STATES.ACTIVE && (
                  <Countdown
                    date={moment(voucher.purchased_at)
                      .add(5, 'minutes')
                      .format()}
                    intervalDelay={0}
                    precision={3}
                    onComplete={expireVoucher}
                    renderer={({minutes, seconds, total}) => (
                      <>
                        <div>
                          {translate('sdk.web.vouchers.valid.timer', {
                            key: '{nr}',
                            value: `${zeroPad(minutes)}:${zeroPad(seconds)}`,
                          })}
                        </div>
                        <Progress
                          percent={(total / (5 * 1000 * 60)) * 100 || 100}
                        />
                      </>
                    )}
                  />
                )}
                {voucherState === VOUCHER_STATES.EXPIRED && (
                  <>
                    <div>
                      {translate('sdk.web.vouchers.used.on', {
                        key: '{date}',
                        value: purchasedDate.toLocaleDateString(dateLanguage, {
                          month: 'short',
                          day: 'numeric',
                        }),
                      })}
                    </div>
                    <Progress expired={true} />
                  </>
                )}
              </ActiveDetails>
            )}
          </TextContainer>
          <DeleteVoucher
            onClick={(e) => handleSelectVoucherForDelete(e, voucher)}
            show={isDeleteActive}>
            <DeleteVoucherIcon>
              <DeleteIcon />
            </DeleteVoucherIcon>
          </DeleteVoucher>
        </ImageCard>
      </Item>
      {isDeleteDialogOpen && removeVoucher && (
        <DialogBox
          title={removeVoucher.title}
          promptMessage={removeVoucher.desc}
          onConfirmation={handleVoucherDelete}
          yesText={translate('sdk.web.dialog.box.confirm')}
          noText={translate('sdk.web.dialog.box.cancel')}
          isLoading={isDeleting}
        />
      )}
      {errorMessage && (
        <DialogBox
          onConfirmation={() => setErrorMessage('')}
          promptMessage={errorMessage}
          singleText={translate('sdk.web.dialog.box.ok')}
        />
      )}
    </>
  );
};

export default VoucherCard;

import React, {useCallback, useContext, useMemo} from 'react';
import styled from 'styled-components';

import {CACHE_KEYS} from 'constants/cache-keys';
import {SettingsContext} from 'contexts/SettingsContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useUserProfile} from 'hooks/use-user-profile';
import {useTranslations} from 'hooks/use-translations';
import {getCustomActivityCategories} from 'services/customActivity';

import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import CopyText from 'components/Text/CopyText/CopyText';
import ActivityWidget from './AcitivityWidget';
import CategoryWidget from './CategoryWidget';

const Header = styled.div`
  margin-bottom: 20px;
`;

const Title = styled(CopyText)`
  margin-bottom: 20px;
  text-align: center;
  display: block;
`;

const CustomActivities = () => {
  const {reloadUserProfile} = useUserProfile();
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);

  const {
    data: activities,
    fetchData,
    updateDataById,
  } = useFetcher({
    fetcher: getCustomActivityCategories,
    initialValue: [],
    key: CACHE_KEYS.CUSTOM_ACTIVITIES_CATEGORIES,
  });

  const Widget = useMemo(
    () =>
      organizationConfig.theme.components.customActivities?.widgetStyle ===
      'activity'
        ? ActivityWidget
        : CategoryWidget,
    [organizationConfig],
  );

  const title = useMemo(
    () =>
      translate(
        organizationConfig.translations?.climateWidgets?.activities?.title ||
          'sdk.web.ca.widget.title',
      ),
    [translate, organizationConfig.translations],
  );

  const handleActivityComplete = useCallback(
    ({activity}: any) => {
      updateDataById({
        id: activity.id,
        idKey: 'id',
        updates: {
          completed: true,
        },
      });

      fetchData();
      reloadUserProfile();
    },
    [updateDataById, fetchData, reloadUserProfile],
  );

  if (!activities?.length) {
    return null;
  }

  return (
    <WidgetCard>
      <Header>
        <WidgetTitle
          aboutInfo={{
            title,
            userProfilePath: 'organisation.custom_activity_info_text',
            enabled: false,
          }}>
          {title}
        </WidgetTitle>
      </Header>
      <Title>{translate('sdk.web.ca.widget.subtitle')}</Title>
      <Widget
        activities={activities}
        handleActivityComplete={handleActivityComplete}
      />
    </WidgetCard>
  );
};

export default CustomActivities;

export type Wallets = {
  default: {
    id: string;
    owner_type: string;
    owner_id: number;
    tag: string;
    coin_id: string;
    created_at: string;
    updated_at: string;
    coin_balance: number;
    owner_name: string;
    coin: {
      id: string;
      name: string;
      slug: string;
      conversion_rate: number;
      owner_type: any;
      owner_id: any;
      created_at: string;
      updated_at: string;
    };
    qr_code: {
      id: number;
      type: string;
      token: string;
      payload: {
        action: string;
        params: {
          wallet_id: string;
        };
      };
      payload_url: string;
      entity_type: string;
      entity_id: string;
      scan_count: number;
      legacy_payload_url: any;
      app: string;
      file_disk: string;
      file_path: string;
      file_url: string;
      file_visibility: string;
      locked: boolean;
      created_at: string;
      updated_at: string;
      deleted_at: any;
    };
    owner: {
      id: number;
      firstname: string;
      lastname: string;
      email: string;
      organisation_id: number;
      is_admin: boolean;
      visible: boolean;
      settings: string;
      verified: boolean;
      enabled: number;
      locale: string;
      image: any;
      background_image: any;
      about: any;
      card_brand: any;
      card_last_four: any;
      trial_ends_at: any;
      created_at: string;
      updated_at: string;
      email_opt_in: boolean;
      account_verified: boolean;
      region: any;
      company_email: any;
      company_email_verified: boolean;
      organisation_entity_id: any;
      organisation_area_id: any;
      account_type: number;
      last_active_at: string;
      timezone: string;
      remember_token: any;
      deleted_at: any;
      guest_email: any;
      guest_email_verified: number;
      completed_onboarding: boolean;
      pipedrive_id: any;
      admin_account_verified: boolean;
      guest_firstname: any;
      guest_lastname: any;
    };
  };
};

export enum WalletTransactionActionTypes {
  RE_SHARE_LINK = 'reshare-link',
}

export type WalletTransactionAction = {
  params: {
    link: string;
  };
  type: WalletTransactionActionTypes;
};

export type WalletTransaction = {
  month: string;
  items: Array<{
    date: string;
    coins: number;
    description: string;
    action?: WalletTransactionAction;
  }>;
};

export type Wallet = {
  id: number;
  type: string;
  identifier: string;
  token: string;
  payload: {
    action: string;
    params: {
      wallet_id: string;
    };
  };
  created_at: string;
  payload_url: string;
  entity_type: string;
  entity_id: string;
  scan_count: number;
  legacy_payload_url: any;
  app: string;
  file_disk: string;
  file_path: string;
  file_url: string;
  file_visibility: string;
};

export enum TRANSFER_TYPE {
  SCANNER = 'SCANNER',
  LINK = 'LINK',
}

import React, {useContext} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';

import PromptModal from 'components/PromptModal/PromptModal';

import banner from './images/banner.svg';

interface PromptProps {
  isVisible: boolean;
  onClose: () => any;
  onCta: () => any;
}

const InfoPrompt = (props: PromptProps) => {
  const {isVisible, onClose, onCta} = props;

  const {translate} = useTranslations();
  const {appName} = useContext(SettingsContext);

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.sdd.info.title')}
      textContent={translate('sdk.web.sdd.info.text', {
        key: '{appName}',
        value: appName,
      })}
      image={banner}
      btnText={translate('sdk.web.sdd.info.cta')}
      onClose={onClose}
      onBtnClick={onCta}
    />
  );
};

export default InfoPrompt;

import React from 'react';
import styled from 'styled-components';
import {addGlobalBoxShadow, addGlobalSquareCorners} from 'utils/theme';

const Wrapper = styled.div<{bgColor?: string}>`
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
  cursor: pointer;
  overflow: hidden;
  display: flex;
  padding: 10px;
  border-radius: ${(props) =>
    props.theme.components.offers?.hasSquareCorners || '10px'};

  ${addGlobalSquareCorners()}
  ${addGlobalBoxShadow()}
`;

const Image = styled.img`
  width: 95px;
  height: 71px;
  object-fit: cover;
  border-radius: 5px;

  ${addGlobalSquareCorners()}
`;

const Content = styled.div`
  flex-grow: 1;
  padding-left: 10px;
`;

const ImageCard = (props: any) => {
  const {bgColor, img, children} = props || {};

  return (
    <Wrapper bgColor={bgColor}>
      <Image src={img} alt="" />
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default ImageCard;

import React, {useContext} from 'react';
import styled from 'styled-components';
import {sendMessageToNativeApp} from 'services/native-api';
import {SettingsContext} from 'contexts/SettingsContext';
import {NATIVE_MESSAGES} from 'constants/native-events';
import {PROMPTS} from 'constants/prompts';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';
import {usePermissionsCheck} from 'hooks/use-permissions-check';
import PromptModal from 'components/PromptModal/PromptModal';
import CtaLink from 'components/CtaLink/CtaLink';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import img from 'components/Onboarding/images/onboarding-notification.svg';

const MoreContent = styled.div`
  margin-top: 20px;
`;

const MoreText = styled(CopyTextSm)`
  margin-top: 20px;
  padding: 0 10px;
`;

const OnboardingNotificationPrompt = (props: any) => {
  const {prompts} = props;
  const {translate} = useTranslations();
  const {isNotificationEnabled, setIsNotificationEnabled} = useContext(
    SettingsContext,
  );
  const {hasNotifications} = usePermissionsCheck();
  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.NOTIFICATION_PERMISSION,
    preventAutoHide: !hasNotifications,
  });

  if (!prompt || isNotificationEnabled || !hasNotifications) {
    return <React.Fragment />;
  }

  const handlePromptClose = () => {
    const message = {
      type: NATIVE_MESSAGES.UPDATE_NOTIFICATION,
      enabled: true,
    };
    setIsNotificationEnabled(true);
    sendMessageToNativeApp({message});
    handleModalClose();
  };

  const TextContent = (
    <div>{translate('sdk.web.onboarding.notification.text')}</div>
  );

  const ExtraContent = (
    <MoreContent>
      <CtaLink onClick={handleModalClose}>
        {translate('sdk.web.onboarding.notification.skip.link')}
      </CtaLink>
      <MoreText>{translate('sdk.web.onboarding.notification.more')}</MoreText>
    </MoreContent>
  );

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.onboarding.notification.title')}
      textContent={TextContent}
      image={img}
      btnText={translate('sdk.web.onboarding.notification.confirm')}
      onBtnClick={handlePromptClose}
      extraContent={ExtraContent}
    />
  );
};

export default OnboardingNotificationPrompt;

import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import routes from 'config/routes/water';
import {useTranslations} from 'hooks/use-translations';
import TextMedium from 'components/Text/TextMedium/TextMedium';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import infoImage from './images/info.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextGroup = styled.div`
  padding-left: 5px;
`;

const Title = styled(HeaderText)`
  display: block;
  font-family: ${(props) => props.theme.font.secondary.name};
`;

const Subtitle = styled(TextMedium)``;

const Img = styled.img`
  width: 120px;
`;

const Info = () => {
  const {push} = useHistory();
  const {translate} = useTranslations();

  return (
    <Wrapper onClick={() => push(routes.href)}>
      <TextGroup>
        <Title>{translate('sdk.web.water.widget.info.title')}</Title>
        <Subtitle>{translate('sdk.web.water.widget.info.subtitle')}</Subtitle>
      </TextGroup>
      <Img src={infoImage} alt="" />
    </Wrapper>
  );
};

export default Info;

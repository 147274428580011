import {TranslationStrings} from './types';

/**
 * SDK Actions
 *
 * Keep them simple. Use inference/optional
 *
 * @see {@link https://blog.usejournal.com/writing-better-reducers-with-react-and-typescript-3-4-30697b926ada}
 */

/** Root Provider */
export const APP_INIT = '@SDK/APP_INIT';
export const init = () => ({
  type: APP_INIT,
});
export const APP_LOG = '@SDK/APP_LOG';
export const log = (payload: any) => ({
  type: APP_LOG,
  payload,
});

/** Intl Provider Actions */
export const INTL_SET_LOCALE = '@SDK/INTL_SET_LOCALE';
export const setLocale = (locale: string) => ({
  type: INTL_SET_LOCALE,
  payload: locale,
});
export const INTL_SET_TRANSLATION_STRINGS = '@SDK/INTL_SET_TRANSLATION_STRINGS';
export const setTranslationStrings = (applangaStrings: TranslationStrings) => ({
  type: INTL_SET_TRANSLATION_STRINGS,
  payload: applangaStrings,
});
export const INTL_SET_STATUS = '@SDK/INTL_SET_STATUS';
export const setStatus = (status: string) => ({
  type: INTL_SET_TRANSLATION_STRINGS,
  payload: status,
});

import React, {useCallback, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {NEW_FEATURES} from 'constants/app-features';
import {CACHE_KEYS} from 'constants/cache-keys';
import {useTranslations} from 'hooks/use-translations';
import useNavigation from 'hooks/app/use-navigation';
import useNewFeatures from 'hooks/app/use-new-features';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import {useFetcher} from 'hooks/use-fetcher';
import {useBoolean} from 'hooks/utils/use-boolean';
import useSocioDemoData from 'hooks/use-socio-demo-data';
import {useOrganization} from 'hooks/use-organization';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {getSocioDemoData, saveSocioDemoData} from 'services/socio-demo-data';
import {SocioDemoData} from 'types/SocioDemoData';
import {addBoldText} from 'utils/theme';
import {formatToken} from 'utils/numbers';

import InfoPage from 'components/InfoPage/InfoPage';
import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import Dropdown from 'components/Form/Dropdown';
import DefaultFormGroup from 'components/Form/FormGroup';
import DefaultLabel from 'components/Form/FormLabel';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Radio from 'components/Form/Radio';
import PromptModal from 'components/PromptModal/PromptModal';
import InfoPrompt from './InfoPrompt';

import banner from './images/banner.svg';

const validationSchema = yup.object().shape({});

const Wrapper = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const FormGroup = styled(DefaultFormGroup)<{
  marginTop?: string;
  marginBottom?: string;
}>`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;

const FormLabel = styled(DefaultLabel)`
  ${(props) =>
    addBoldText({
      props,
    })}
`;

const RadioLabelComponent = styled(CopyTextSm)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SocioDemoDataPage = () => {
  const history = useHistory();
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});
  const {markFeatureAsSeen} = useNewFeatures();
  const {getTokenText} = useTokenTranslation();
  const {organizationConfig, hasWallets} = useOrganization();

  const formRef = useRef<HTMLFormElement>(null);
  const {apiErrors, validate} = useFormValidation();
  const toast = useToast();
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();
  const [isSuccessModalVbl, showSuccessModal, closeSuccessModal] =
    useBoolean(false);
  const [isInfoVbl, showInfo, hideInfo] = useBoolean(false);

  const [isTokenModalVbl, showTokenModal, closeTokenModal] = useBoolean(false);
  const [tokensEarned, setTokensEarned] = useState({
    tokens: 0,
    formattedTokens: '',
  });

  const {data: socioDemoData, addDataItem: saveDataCache} =
    useFetcher<SocioDemoData>({
      fetcher: getSocioDemoData,
      key: CACHE_KEYS.SOCIO_DEMO_DATA,
    });

  const {fields, initialValues} = useSocioDemoData(socioDemoData);

  const handleSubmit = useCallback(
    async (data: SocioDemoData) => {
      try {
        startSubmitting();
        if (data.tp_sharing === 'no') {
          data.tp_sharing_mode = [];
        }

        const {new_coins, ...response} = await saveSocioDemoData(data);
        saveDataCache({data: response});

        markFeatureAsSeen(NEW_FEATURES.SOCIO_DEMO_DATA);
        showSuccessModal();

        if (new_coins && new_coins > 0.1) {
          setTokensEarned({
            tokens: new_coins,
            formattedTokens: formatToken({
              number: new_coins,
              dp: 1,
            }).toString(),
          });
          showTokenModal();
        }
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(errorKey || 'sdk.web.sdd.error.fallback');
        toast.error(message);
      } finally {
        stopSubmitting();
      }
    },
    [
      toast,
      translate,
      startSubmitting,
      stopSubmitting,
      markFeatureAsSeen,
      saveDataCache,
      showSuccessModal,
      showTokenModal,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate,
  });

  const completedMinFields = useMemo(
    () => Object.values(formik.values).some((fieldValue) => !!fieldValue),
    [formik.values],
  );

  return (
    <InfoPage title={translate('sdk.web.sdd.title')} onClose={handleGoBack}>
      <Wrapper ref={formRef} onSubmit={formik.handleSubmit}>
        <div>
          <div className="mb-base">
            <CopyTextSm as="div">
              <div
                dangerouslySetInnerHTML={{
                  __html: translate('sdk.web.sdd.header', [
                    {
                      key: '{tokenPlural}',
                      value: getTokenText(2),
                    },
                    {
                      key: '{tokenSingular}',
                      value: getTokenText(1),
                    },
                  ]),
                }}
              />
            </CopyTextSm>
          </div>
          {fields.map(
            ({name, label, options, type, placeholder, dependsOn}, index) => {
              if (
                dependsOn &&
                // @ts-ignore
                formik.values[dependsOn.name] !== dependsOn.value
              ) {
                return null;
              }

              const formOptions = {
                name,
                formik,
                apiErrors,
                optional: true,
                list: options,
                onChange: formik.handleChange,
                defaultLabel: placeholder,
                // @ts-ignore
                value: formik.values[name] || '',
              };

              const isRadio = type === 'radio';
              const marginTop = dependsOn ? '-20px' : undefined;
              const marginBottom =
                isRadio && fields.length !== index + 1 ? '20px' : undefined;

              return (
                <FormGroup marginTop={marginTop} marginBottom={marginBottom}>
                  {label && (
                    <div>
                      <FormLabel>{label}</FormLabel>
                    </div>
                  )}
                  {isRadio ? (
                    <Radio
                      {...formOptions}
                      multi={!!dependsOn}
                      type="checkbox"
                      LabelComponent={RadioLabelComponent}
                    />
                  ) : (
                    <Dropdown {...formOptions} />
                  )}
                </FormGroup>
              );
            },
          )}
        </div>
        <div className="text-center">
          <Button
            type="submit"
            className="mb-base"
            isLoading={isSubmitting}
            disabled={!formik.dirty || !completedMinFields}>
            {translate('sdk.web.sdd.cta')}
          </Button>
          <CtaLink onClick={showInfo} noMargin>
            {translate('sdk.web.sdd.cta.more')}
          </CtaLink>
        </div>
        <br />
      </Wrapper>
      <PromptModal
        isVisible={isSuccessModalVbl}
        title={translate('sdk.web.sdd.success.title')}
        textContent={translate('sdk.web.sdd.success.text')}
        image={banner}
        btnText={translate('sdk.web.sdd.success.cta')}
        onClose={closeSuccessModal}
        onBtnClick={closeSuccessModal}
      />
      <PromptModal
        isVisible={isTokenModalVbl && !isSuccessModalVbl}
        title={translate('sdk.web.sdd.success.token.title')}
        textContent={translate('sdk.web.sdd.success.token.text', [
          {
            key: '{nr}',
            value: tokensEarned.formattedTokens,
          },
          {
            key: '{token}',
            value: getTokenText(tokensEarned.tokens),
          },
        ])}
        image={organizationConfig.fullTokenImg}
        btnText={translate('sdk.web.sdd.success.token.cta')}
        moreInfo={
          hasWallets
            ? {
                text: translate('sdk.web.sdd.success.token.cta.wallet'),
                onClick: () => history.push(routes.DASHBOARD.WALLET.href),
              }
            : null
        }
        onClose={closeTokenModal}
        onBtnClick={closeTokenModal}
      />
      <InfoPrompt isVisible={isInfoVbl} onClose={hideInfo} onCta={hideInfo} />
    </InfoPage>
  );
};
export default SocioDemoDataPage;

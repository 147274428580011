import React, {useCallback, useMemo, useRef} from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';
import classnames from 'classnames';

import routes from 'config/routes';
import {defaultEmissions} from './config';
import {NEW_FEATURES} from 'constants/app-features';
import {CACHE_KEYS} from 'constants/cache-keys';
import {useTranslations} from 'hooks/use-translations';
import useNavigation from 'hooks/app/use-navigation';
import useNewFeatures from 'hooks/app/use-new-features';
import {useForm} from 'hooks/forms/use-form';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import {useFetcher} from 'hooks/use-fetcher';
import {useBoolean} from 'hooks/utils/use-boolean';
import useErrorMessage from 'hooks/forms/use-error-message';
import {
  getMobilitySettings,
  updateMobilitySettings,
} from 'services/mobility-settings';
import {MobilitySettings} from 'types/Mobility';
import {getEmissionsPerGram} from './utils';

import InfoPage from 'components/InfoPage/InfoPage';
import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import FormLabel from 'components/Form/FormLabel';
import DialogBox from 'components/DialogBox/DialogBox';
import Header from './Header';

import carpoolImage from './images/carpool.svg';

const Wrapper = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const CarpoolingSettings = () => {
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});
  const {markFeatureAsSeen} = useNewFeatures();

  const formRef = useRef<HTMLFormElement>(null);
  const {getKeyDownListener} = useForm();
  const {apiErrors, validate} = useFormValidation();
  const errorMessage = useErrorMessage({translate});
  const toast = useToast();
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();
  const [isStopDialogVbl, openStopDialog, closeStopDialog] = useBoolean();
  const [isStopping, startStopping, completeStopping] = useBoolean();

  const validationSchema = useMemo(() => {
    const {getRequiredError, getMinError, getMaxError} = errorMessage;

    return yup.object().shape({
      pooling_quantity: yup
        .number()
        .required(getRequiredError({}))
        .min(1, getMinError({}))
        .max(6, getMaxError({}))
        .nullable(),
      pooling_percentage: yup
        .number()
        .required(getRequiredError({}))
        .min(1, getMinError({}))
        .max(100, getMaxError({}))
        .nullable(),
    });
  }, [errorMessage]);

  const {data: mobilitySettings, addDataItem: saveMobilitySettingsToCache} =
    useFetcher<MobilitySettings>({
      fetcher: getMobilitySettings,
      key: CACHE_KEYS.MOBILITY_SETTINGS,
    });

  const initialValues = useMemo(() => {
    return mobilitySettings
      ? {
          pooling_quantity: mobilitySettings.pooling_quantity || '',
          pooling_percentage: mobilitySettings.pooling_percentage || '',
        }
      : {
          pooling_quantity: '',
          pooling_percentage: '',
        };
  }, [mobilitySettings]);

  const onSubmit = useCallback(
    async (data: typeof initialValues) => {
      try {
        startSubmitting();
        const response = await updateMobilitySettings({
          ...data,
          pooling_enabled: true,
        });
        saveMobilitySettingsToCache({data: response});
        markFeatureAsSeen(NEW_FEATURES.VEHICLE_SETTINGS);

        toast.success(translate('sdk.web.settings.carpool.success'));
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.settings.carpool.error.fallback',
        );
        toast.error(message);
      } finally {
        stopSubmitting();
      }
    },
    [
      toast,
      markFeatureAsSeen,
      startSubmitting,
      stopSubmitting,
      translate,
      saveMobilitySettingsToCache,
    ],
  );

  const handleStopConfirmation = useCallback(
    async (shouldStop: boolean) => {
      if (!shouldStop) {
        closeStopDialog();
        return;
      }

      try {
        startStopping();
        const response = await updateMobilitySettings({
          pooling_enabled: false,
        });
        saveMobilitySettingsToCache({data: response});
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.settings.carpool.error.fallback',
        );
        toast.error(message);
      } finally {
        completeStopping();
      }

      closeStopDialog();
    },
    [
      toast,
      translate,
      startStopping,
      completeStopping,
      closeStopDialog,
      saveMobilitySettingsToCache,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validate,
  });

  const emissions = useMemo(() => {
    if (!formik.isValid) {
      return;
    }

    const {pooling_quantity, pooling_percentage} = formik.values;
    if (!pooling_quantity || !pooling_percentage) {
      return;
    }

    const vehicleEmissionsPerGram = mobilitySettings?.fuel_type
      ? getEmissionsPerGram({
          fuelType: mobilitySettings.fuel_type,
          consumption: mobilitySettings.fuel_consumption,
        })
      : defaultEmissions.value;

    return (
      (vehicleEmissionsPerGram * (100 - +pooling_percentage)) / 100 +
      ((vehicleEmissionsPerGram / +pooling_quantity) * +pooling_percentage) /
        100
    );
  }, [formik, mobilitySettings]);

  return (
    <InfoPage
      title={translate('sdk.web.settings.carpool.title')}
      onClose={handleGoBack}>
      <Wrapper ref={formRef} onSubmit={formik.handleSubmit}>
        <div>
          <Header
            subtitle={translate('sdk.web.settings.carpool.subtitle')}
            image={carpoolImage}
            emissions={emissions}
          />
          <FormGroup>
            <FormLabel>
              {translate('sdk.web.settings.carpool.people')}
            </FormLabel>
            <Input
              placeholder=""
              name="pooling_quantity"
              type="number"
              min="0"
              formik={formik}
              value={formik.values.pooling_quantity as string}
              onChange={formik.handleChange}
              onKeyDown={getKeyDownListener({form: formRef.current})}
              enterkeyhint={'next'}
              apiErrors={apiErrors}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>
              {translate('sdk.web.settings.carpool.percent')}
            </FormLabel>
            <Input
              placeholder=""
              name="pooling_percentage"
              type="number"
              min="0"
              max="100"
              formik={formik}
              value={formik.values.pooling_percentage as string}
              onChange={formik.handleChange}
              onKeyDown={getKeyDownListener({form: formRef.current})}
              enterkeyhint={'go'}
              apiErrors={apiErrors}
            />
          </FormGroup>
        </div>
        <div className="text-center">
          <Button
            type="submit"
            className={classnames({
              'mb-base': mobilitySettings?.pooling_enabled,
            })}
            isLoading={isSubmitting}>
            {translate('sdk.web.settings.carpool.cta.save')}
          </Button>
          {mobilitySettings?.pooling_enabled && (
            <CtaLink noMargin onClick={openStopDialog}>
              {translate('sdk.web.settings.carpool.cta.stop')}
            </CtaLink>
          )}
        </div>
        {isStopDialogVbl && (
          <DialogBox
            promptMessage={translate('sdk.web.settings.carpool.stop.message')}
            yesText={translate('sdk.web.dialog.box.confirm')}
            noText={translate('sdk.web.dialog.box.cancel')}
            isLoading={isStopping}
            onConfirmation={handleStopConfirmation}
          />
        )}
      </Wrapper>
    </InfoPage>
  );
};

export default CarpoolingSettings;

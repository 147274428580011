import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';

import Settings from 'components/Settings/Settings';
import DetailsNavigation from 'components/DetailsNavigation/DetailsNavigation';
import Wrapper from 'components/Wrapper/Wrapper';

const higherWrapperStyleProps = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
};

const Body = styled.div`
  background: ${(props) =>
    props.theme.components.settings.bgColor || props.theme.colors.secondary};
  flex-grow: 1;
  display: flex;
  padding: 73px 20px 0;
`;

const SettingsHome = (props: any) => {
  const {translate} = useTranslations();

  const onClose = (e: any) => {
    props.history.push(routes.DASHBOARD.href);
  };

  return (
    <Wrapper {...higherWrapperStyleProps}>
      <DetailsNavigation
        onClose={onClose}
        text={translate('sdk.web.settings.title')}
      />
      <Body>
        <Settings />
      </Body>
    </Wrapper>
  );
};

export default withRouter(SettingsHome);

import {useCallback, useEffect, useState} from 'react';
import {useNativeListener} from 'hooks/use-native-listener';
import {sendMessageToNativeApp} from 'services/native-api';

type UseNativeListenerProps = {
  key: string;
  type: string;
  event: string;
};

const useNativeEvent = <T extends any>(
  props: UseNativeListenerProps & {isDataValid: (data: T) => boolean},
) => {
  const {isDataValid, key, type, event} = props;

  const [resolveCallback, setResolveCallback] = useState<
    (coordinates?: T) => void
  >();
  const [data, setData] = useState<false | undefined | T>();

  const handleNativeResponse = useCallback(
    (details: Record<string, T>) => {
      const isValid = isDataValid(details?.[key]);
      if (!isValid) {
        setData(false);
        return;
      }

      setData(details[key]);
    },
    [key, isDataValid],
  );

  useEffect(() => {
    if (typeof resolveCallback === 'undefined' || !data) {
      return;
    }

    resolveCallback(data || undefined);
    setResolveCallback(undefined);
    setData(undefined);
  }, [data, resolveCallback]);

  useNativeListener({
    callback: handleNativeResponse,
    event,
  });

  const requestNativeEvent = useCallback((): Promise<T> => {
    const message = {type};
    sendMessageToNativeApp({message});

    return new Promise((resolve) => {
      setResolveCallback(() => resolve);
    });
  }, [type]);

  return {
    requestNativeEvent,
  };
};

export default useNativeEvent;

import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Close} from './images/close.svg';
import HeaderText from '../Text/HeaderText/HeaderText';
import CopyText from '../Text/CopyText/CopyText';

const Wrapper = styled.div`
  padding: 20px;
  padding-bottom: 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const NavIcon = styled.div<{right?: boolean}>`
  fill: ${(props) =>
    props.theme.components.summaryPage?.closeIconColor ||
    props.theme.colors.black};
  cursor: pointer;
  width: 25px;
  margin-bottom: 20px;
`;

const Title = styled(HeaderText)`
  margin-bottom: 15px;
  text-align: center;
  display: block;
`;

const Body = styled(CopyText)`
  width: 100%;
  display: block;
  text-align: center;
  flex: 1;
`;

type SummaryPageProps = {
  title?: string;
  onClose?: () => void;
  children: React.ReactNode;
};

const SummaryPage = (props: SummaryPageProps) => {
  const {title, onClose} = props;

  return (
    <Wrapper>
      {onClose && (
        <NavIcon onClick={onClose}>
          <Close />
        </NavIcon>
      )}
      {title && <Title>{title}</Title>}
      <Body as="div">{props.children}</Body>
    </Wrapper>
  );
};

export default SummaryPage;

import React, {useCallback, useRef, useState} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {useToast} from 'hooks/use-toast';
import {useForm} from 'hooks/forms/use-form';
import {useFetcher} from 'hooks/use-fetcher';
import {useBoolean} from 'hooks/utils/use-boolean';
import useWarning from 'hooks/use-warning';
import {CACHE_KEYS} from 'constants/cache-keys';
import {
  disconnect,
  getSettings,
  SettingsData,
  updateSettings,
} from 'services/water';

import DialogBox from 'components/DialogBox/DialogBox';
import CopyText from 'components/Text/CopyText/CopyText';
import Input from './Input';
import Label from './Label';
import FormGroup from './FormGroup';
import Footer from './Footer';
import Button from './Button';
import CtaLink from './CtaLink';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000;
  height: 100%;
`;

const Title = styled(CopyText)`
  display: block;
  margin-bottom: 15px;
`;

const InputsWrapper = styled.div`
  margin: 0 -1px;
`;

type SettingsProps = {
  onComplete: () => void;
};

const Settings = (props: SettingsProps) => {
  const {onComplete} = props;

  const formRef = useRef<HTMLFormElement>(null);
  const {translate} = useTranslations();
  const toast = useToast();
  const {getKeyDownListener} = useForm();
  const {showWarning, WarningDialog} = useWarning();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisconnectVisible, showDisconnect, hideDisconnect] = useBoolean(
    false,
  );
  const [isDisconnecting, startDisconnecting, stopDisconnecting] = useBoolean(
    false,
  );

  const {data: initialValues, fetchData: reFetchSettings} = useFetcher({
    fetcher: getSettings,
    initialValue: {
      people: '',
      size: '',
    },
    key: CACHE_KEYS.WATER_SETTINGS,
  });

  const validationSchema = yup.object().shape({
    size: yup.number(),
    people: yup.number(),
  });

  const onSubmit = async (data: SettingsData) => {
    if (!data.size || !data.people) {
      showWarning({
        title: translate('sdk.web.water.settings.error.dialog.title'),
        content: translate('sdk.web.water.settings.hh.error.inputs'),
      });
      return;
    }

    setIsSubmitting(true);

    try {
      await updateSettings(data);
      await reFetchSettings();
      setIsSubmitting(false);
      onComplete();
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const message = translate(errorKey);
      showWarning({
        title: translate('sdk.web.water.settings.hh.error.title'),
        content:
          message || translate('sdk.web.water.settings.hh.error.fallback'),
      });
      setIsSubmitting(false);
    }
  };

  const handleDisconnection = useCallback(
    async (shouldDisconnect: boolean) => {
      if (!shouldDisconnect) {
        hideDisconnect();
        return;
      }

      try {
        startDisconnecting();
        await disconnect();
        onComplete();
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(errorKey || '');
        toast.error(message);
      } finally {
        stopDisconnecting();
      }
    },
    [
      hideDisconnect,
      startDisconnecting,
      onComplete,
      stopDisconnecting,
      toast,
      translate,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit} ref={formRef}>
        <div>
          <Title>{translate('sdk.web.water.settings.hh.title')}</Title>
          <InputsWrapper>
            <FormGroup>
              <Label>{translate('sdk.web.water.settings.hh.size')}</Label>
              <Input
                placeholder={translate(
                  'sdk.web.water.settings.hh.size.placeholder',
                )}
                type="number"
                name={'size'}
                formik={formik}
                // @ts-ignore
                min={0}
                // @ts-ignore
                value={formik.values.size}
                onChange={formik.handleChange}
                onKeyDown={getKeyDownListener({form: formRef.current})}
                enterkeyhint={'next'}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                <Label>
                  {translate('sdk.web.water.settings.hh.population')}
                </Label>
              </Label>
              <Input
                placeholder={translate(
                  'sdk.web.water.settings.hh.population.placeholder',
                )}
                type="number"
                name={'people'}
                formik={formik}
                // @ts-ignore
                min={0}
                // @ts-ignore
                value={formik.values.people}
                onChange={formik.handleChange}
                onKeyDown={getKeyDownListener({form: formRef.current})}
                enterkeyhint={'go'}
              />
            </FormGroup>
          </InputsWrapper>
        </div>
        <Footer>
          <Button isLoading={isSubmitting}>
            {translate('sdk.web.water.settings.hh.confirm')}
          </Button>
          <CtaLink onClick={onComplete}>
            {translate('sdk.web.water.settings.hh.cancel')}
          </CtaLink>
          <CtaLink onClick={showDisconnect}>
            {translate('sdk.web.water.settings.hh.disconnect')}
          </CtaLink>
        </Footer>
      </Form>
      <WarningDialog />
      {isDisconnectVisible && (
        <DialogBox
          title={translate('sdk.web.water.settings.hh.disconnect.title')}
          promptMessage={translate(
            'sdk.web.water.settings.hh.disconnect.message',
          )}
          onConfirmation={handleDisconnection}
          noText={translate('sdk.web.water.settings.hh.disconnect.cancel')}
          yesText={translate('sdk.web.water.settings.hh.disconnect.confirm')}
          isLoading={isDisconnecting}
        />
      )}
    </>
  );
};

export default Settings;

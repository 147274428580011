import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import useErrorMessage from 'hooks/forms/use-error-message';

import Button from 'components/Button/Button';
import Input from 'components/Form/Input';
import FormLabel from 'components/Form/FormLabel';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';

const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const initialValues = {
  password: '',
};

interface WalletQrPasswordProps {
  isVisible: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (password: string) => void;
}

const QrPassword = (props: WalletQrPasswordProps) => {
  const {isVisible, isSubmitting, onClose, onSubmit} = props;
  const {translate} = useTranslations();
  const {getRequiredError} = useErrorMessage({translate});

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      password: yup.string().required(
        getRequiredError({
          label: 'sdk.web.inputs.password.label',
        }),
      ),
    });
  }, [getRequiredError]);

  const handleOnSubmit = useCallback(
    (data: typeof initialValues) => {
      onSubmit(data.password);
    },
    [onSubmit],
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleOnSubmit,
  });

  useEffect(
    () => {
      formik.resetForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible],
  );

  return (
    <PromptWrapper
      title={translate('sdk.web.qr.scan.password.title')}
      isVisible={isVisible}
      onClose={onClose}>
      <Wrapper>
        <div>
          <FormLabel>{translate('sdk.web.inputs.password.label')}</FormLabel>
          <Input
            name="password"
            type="password"
            value={formik.values.password}
            formik={formik}
            onChange={formik.handleChange}
          />
        </div>
        <Button isLoading={isSubmitting} onClick={formik.handleSubmit}>
          {translate('sdk.web.qr.scan.password.cta')}
        </Button>
      </Wrapper>
    </PromptWrapper>
  );
};

export default QrPassword;

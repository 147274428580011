interface loadFontInterface {
  url: string;
}

export const loadFont = ({url}: loadFontInterface) => {
  const font = document.createElement('link');
  font.rel = 'stylesheet';
  font.href = url;
  document.head.appendChild(font);
};

import React, {useCallback, useContext} from 'react';
import styled from 'styled-components';

import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import {useBoolean} from 'hooks/utils/use-boolean';
import {LeaderBoard, Team} from 'types/Team';

import CtaLink from 'components/CtaLink/CtaLink';
import LeaderboardWidget from './LeaderboardWidget';
import PreviousLeaderboardPrompt from './PreviousLeaderboardPrompt/PreviousLeaderboardPrompt';

import bannerImg from './images/competition-banner.svg';

const Cta = styled(CtaLink)`
  text-align: center;
  color: ${(props) =>
    props.theme.components.leaderboard.ctaColor || props.theme.colors.black};
  margin-bottom: 10px;
`;

type GroupLeaderBoardProps = {
  isLoading: boolean;
  group: LeaderBoard;
};

const GroupLeaderBoard = (props: GroupLeaderBoardProps) => {
  const {group, isLoading} = props;
  const {teams, challengeType, previousLeaderboards} = group;
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {userProfile} = useUserProfile();
  const [
    isPreviousLeaderboardVisible,
    openPreviousLeaderboard,
    closePreviousLeaderBoard,
  ] = useBoolean();

  const isOwnTeam = useCallback(
    ({team}: {team: Team}) => userProfile?.organisation?.id === team.id,
    [userProfile],
  );

  if (!isLoading && !teams.length) {
    return null;
  }

  const title = translate(organizationConfig.translations.competition.title);
  const subtitle = translate(
    organizationConfig.translations.competition.subtitle,
  );

  return (
    <>
      <LeaderboardWidget
        title={title}
        subtitle={subtitle}
        image={
          organizationConfig.theme.components.leaderboard?.groupBanner ||
          bannerImg
        }
        isLoading={isLoading}
        isOwnTeam={isOwnTeam}
        teams={teams}
        challengeType={challengeType}>
        <>
          {!!previousLeaderboards?.length && (
            <Cta onClick={openPreviousLeaderboard}>
              {translate('sdk.web.teams.show.previous.results')}
            </Cta>
          )}
        </>
      </LeaderboardWidget>
      <PreviousLeaderboardPrompt
        isVisible={isPreviousLeaderboardVisible}
        previousLeaderboards={previousLeaderboards || []}
        onClose={closePreviousLeaderBoard}
      />
    </>
  );
};
export default GroupLeaderBoard;

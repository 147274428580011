import React, {useEffect} from 'react';
import styled from 'styled-components';

import {useBoolean} from 'hooks/utils/use-boolean';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {addBoldText, addFontFamily} from 'utils/theme';
import {getPaymentMethods} from 'services/billing';

import CardForm from './CardForm';
import TextXs from 'components/Text/TextXs/TextXs';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import PaymentCard from './PaymentCard';
import {PaymentMethod} from 'types/Billing';
import masterImg from 'assets/images/mastercard.jpg';
import visaImg from 'assets/images/visa.jpg';

const Title = styled(CopyTextSm)`
  display: block;
  margin-bottom: 12px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Method = styled.div`
  display: block;
`;

const Card = styled.a`
  display: flex;
  justify-content: space-between;
  width: 100px;
`;

const Img = styled.img`
  width: 48px;
  object-fit: contain;
`;

const MethodName = styled(TextXs)`
  display: block;
  margin-top: 3px;
  ${(props) =>
    addFontFamily({
      props,
      value: props.theme.components.co2Compensation?.paymentForm?.fontFamily,
    })}
`;

type PaymentMethodsProps = {
  onUpdate: (hasPaymentMethod: boolean) => void;
};

const PaymentMethods = ({onUpdate}: PaymentMethodsProps) => {
  const [isCardFormOpen, openCardForm, closeCardForm] = useBoolean(false);
  const {translate} = useTranslations();

  const {data: paymentMethods, fetchData} = useFetcher({
    fetcher: getPaymentMethods,
    initialValue: [],
    key: 'paymentMethods',
  });

  useEffect(() => {
    onUpdate(paymentMethods.length);
  }, [paymentMethods, onUpdate]);

  const handleCardFormClose = async (isSuccessful?: boolean) => {
    if (!isSuccessful) {
      closeCardForm();
    }

    await fetchData();
    closeCardForm();
  };

  return (
    <div>
      <Title>
        {paymentMethods.length
          ? translate('sdk.web.compensation.payment.details')
          : translate('sdk.web.compensation.payment.method.choose')}
      </Title>
      <List>
        {paymentMethods.length ? (
          paymentMethods.map((paymentMethod: PaymentMethod) => (
            <PaymentCard
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              onDelete={fetchData}
            />
          ))
        ) : (
          <Method onClick={openCardForm}>
            <Card>
              <Img src={visaImg} />
              <Img src={masterImg} />
            </Card>
            <MethodName>
              {translate('sdk.web.compensation.payment.method.card')}
            </MethodName>
          </Method>
        )}
      </List>
      {isCardFormOpen && <CardForm onClose={handleCardFormClose} />}
    </div>
  );
};

export default PaymentMethods;

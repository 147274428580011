import React, {FunctionComponent} from 'react';
import styled from 'styled-components';

import {useOrganization} from 'hooks/use-organization';
import {UserRankingSource} from 'types/Challenge';

import {
  Co2Savings,
  List,
  Row,
  TeamName,
} from 'components/ClimateWidgets/Leaderboard/TeamRanking/TeamRanking';
import {ReactComponent as Caret} from 'icons/caret-right-lg.svg';

const WrapperList = styled(List)`
  padding: 5px 0;
  margin-bottom: 0;
`;

const Icon = styled.div`
  display: flex;
  margin-right: 10px;
  height: 40px;
  max-width: 40px;
  flex-shrink: 0;

  svg {
    width: 100%;
  }
`;

const CaretIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8px;
  margin-left: 15px;
  flex-shrink: 0;
  fill: ${(props) => props.theme.colors.primary};

  svg {
    width: 100%;
  }
`;

export type Rank = {
  key: string | number;
  Icon?: FunctionComponent | string;
  name: string;
  position: number;
  participants?: number;
  showParticipants?: boolean;
  hasDetails?: boolean;
  sources?: Array<UserRankingSource>;
};

interface RankingProps {
  rankings: Array<Rank>;
  onClick?: ({ranking}: {ranking: Rank}) => void;
}

const Ranking = (props: RankingProps) => {
  const {rankings, onClick} = props;
  const {hasUserRankingMobility} = useOrganization();

  return (
    <WrapperList>
      {rankings.map((ranking) => (
        <Row
          key={ranking.key}
          onClick={() =>
            hasUserRankingMobility &&
            ranking.hasDetails &&
            onClick &&
            onClick({ranking})
          }>
          <Icon>{ranking.Icon && <ranking.Icon />}</Icon>
          <TeamName>{ranking.name}</TeamName>
          <Co2Savings>
            {ranking.position ? `#${ranking.position}` : '-'}
          </Co2Savings>
          {hasUserRankingMobility && ranking.hasDetails && (
            <CaretIcon>
              <Caret />
            </CaretIcon>
          )}
        </Row>
      ))}
    </WrapperList>
  );
};

export default Ranking;

import React from 'react';
import {PROMPTS} from 'constants/prompts';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';
import {useOrganization} from 'hooks/use-organization';
import {useInviteFriendsTranslations} from '../hooks/useInviteFriendsTranslations';
import PromptModal from 'components/PromptModal/PromptModal';
import progressImage from 'components/ClimateWidgets/InviteFriends/InviteFriendsProgressPrompt/images/banner.svg';

const InviteFriendsProgressPrompt = (props: any) => {
  const {prompts} = props;
  const {translate} = useTranslations();
  const {getRewardsText} = useInviteFriendsTranslations();

  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.INVITE_FRIENDS_PROGRESS,
  });

  const {organizationConfig} = useOrganization();
  const inviteFriendsConfig = organizationConfig.theme.components.inviteFriends;

  if (!prompt) {
    return <React.Fragment />;
  }

  const referralData = prompt.data;

  const invitesAccepted =
    referralData.invited_users === 1
      ? 'sdk.web.contributions.inviteFriends.progress.accepted.singular'
      : 'sdk.web.contributions.inviteFriends.progress.accepted.plural';

  const referralsNeededKey =
    referralData.users_needed === 1
      ? 'sdk.web.contributions.inviteFriends.progress.left.singular'
      : 'sdk.web.contributions.inviteFriends.progress.left.plural';

  const rewardsText = getRewardsText({
    activitySummary: {},
    quantity: referralData.reward_quantity,
  });

  const TextContent = (
    <div>
      <div>
        {translate(invitesAccepted, [
          {
            key: '{nr}',
            value: referralData.invited_users,
          },
        ])}
      </div>
      <div>
        {translate(referralsNeededKey, [
          {
            key: '{nr}',
            value: referralData.users_needed,
          },
          {
            key: '{reward}',
            value: rewardsText,
          },
        ])}
      </div>
    </div>
  );

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.contributions.inviteFriends.progress.title')}
      textContent={TextContent}
      image={inviteFriendsConfig?.progressBanner || progressImage}
      btnText={translate('sdk.web.contributions.inviteFriends.thanks')}
      onBtnClick={handleModalClose}
    />
  );
};

export default InviteFriendsProgressPrompt;

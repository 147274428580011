interface DataUrlToFileParams {
  dataUrl: string;
  fileName: string;
}

export const dataUrlToFile = async ({
  dataUrl,
  fileName,
}: DataUrlToFileParams): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, {type: 'image/jpg'});
};

export const PROMPTS = {
  NOTIFICATION_PERMISSION: 'notification_permission',
  RECOIN_EARNED: 'recoin_earned',
  INVITE_FRIENDS_SUCCESS: 'invite_friends_success',
  INVITE_FRIENDS_PROGRESS: 'invite_friends_progress',
  LOTTERY_WON: 'lottery_won',
  LOTTERY_LOST: 'lottery_lost',
  TREE_PLANTED: 'tree_planted',
  HALFWAY_TREE_PLANTING_GOAL: 'tree_planting_half_goal_reached',
  TREE_PLANTING_GOAL_REACHED: 'tree_planting_goal_reached',
  CHALLENGE_ENDED: 'challenge_ended',
  NEW_PRIVACY_POLICY_PROMPT: 'privacy_policy_updated',
  NEW_METERING_MONTH: 'new_metering_month',
  SOCIO_DEMO_DATA: 'demographic_data',
};

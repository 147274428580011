import React, {createContext, PropsWithChildren, useState} from 'react';

type LayoutContextProps = {
  isFullScreenModal: boolean;
  setFullScreenMode: (isFullScreen: boolean) => void;
};

const LayoutContext = createContext<LayoutContextProps>({
  isFullScreenModal: false,
  setFullScreenMode(): void {},
});

export const LayoutProvider = (props: PropsWithChildren<{}>) => {
  const [isFullScreenModal, setFullScreenMode] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        isFullScreenModal,
        setFullScreenMode,
      }}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;

import {RootProviderState, IntlProviderState} from './types';
import {sdkIntlFallbackTools} from './utils';
import {getDeviceLanguage} from 'utils/language';

const language = getDeviceLanguage();

const [
  translationsStringsFallback,
  availableLanguages,
] = sdkIntlFallbackTools();
/**
 * SDK Initial States
 *
 * Initial values for providers.
 * This is for observing how the state changes over time.
 * All in one file, no sweating.
 */

/**
 * Initial state for RootProvider
 * @type
 */
export const RootState: RootProviderState = {
  version: '0.1',
  state: 'off', // Machine State
};

/**
 * Initial state for RootProvider
 * @type
 */
export const IntlState: IntlProviderState = {
  locale: availableLanguages.includes(language) ? language : 'de',
  translations: translationsStringsFallback,
  status: 'off',
};

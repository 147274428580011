import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';

import {NATIVE_MESSAGES} from 'constants/native-events';
import {SettingsContext} from 'contexts/SettingsContext';
import {ScanResponse, useQrScanner} from 'hooks/use-qr-scanner';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {sendMessageToNativeApp} from 'services/native-api';
import {TRANSFER_TYPE, Wallets} from 'types/Wallet';

import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import CtaLink from 'components/CtaLink/CtaLink';
import TransferModal, {TransferData} from './TransferModal';
import AvailableCoins from './AvailableCoins';

import {ReactComponent as ShareIcon} from 'icons/share.svg';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  padding: 50px 0 20px;

  .wallet-tab {
    &__qr-wrapper {
      position: relative;
    }

    &__qr-share {
      position: relative;
    }

    &__qr-code {
      width: 105px;
      height: 105px;
      background-color: #f5f5f5f5;
    }

    &__share-icon {
      position: absolute;
      width: 40px;
      top: 50%;
      left: calc(50% + 80px);
      transform: translateY(-50%);
      stroke: ${(props) =>
        props.theme.components.wallets?.textColor ||
        props.theme.colors.primary};

      svg {
        width: 100%;
      }
    }

    &__cta {
      width: 100%;
    }
  }
`;

interface WalletProps {
  isLoading: boolean;
  wallets: Wallets;
  searchParams: URLSearchParams;
  onReload: () => void;
}

const Wallet = (props: WalletProps) => {
  const {isLoading, wallets, searchParams, onReload} = props;
  const history = useHistory();
  const location = useLocation();
  const {translate} = useTranslations();
  const {appName} = useContext(SettingsContext);
  const {getTokenText, getShortTokenText} = useTokenTranslation();
  const [transferData, setTransferData] = useState<TransferData | undefined>();

  useEffect(
    () => {
      const fromScan = searchParams.get('scan');
      const walletId = searchParams.get('walletId');
      const name = searchParams.get('name');

      if (!fromScan || !walletId || !name) {
        return;
      }

      setTransferData({
        name,
        walletId,
        transferType: TRANSFER_TYPE.SCANNER,
      });

      history.replace(location.pathname);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams],
  );

  const handleShare = useCallback(() => {
    const shareMessage = translate('sdk.web.wallets.qr.share.text', [
      {
        key: '{tokens}',
        value: getTokenText(1),
      },
      {
        key: '{tokensShort}',
        value: getShortTokenText(2),
      },
      {
        key: '{appName}',
        value: appName,
      },
    ]);

    const message = {
      type: NATIVE_MESSAGES.SHARE_BADGE,
      share_message: shareMessage,
      share_link: wallets.default.qr_code.payload_url,
    };
    sendMessageToNativeApp({message});
  }, [wallets, appName, translate, getTokenText, getShortTokenText]);

  const handleTransferLink = useCallback(() => {
    setTransferData({
      transferType: TRANSFER_TYPE.LINK,
    });
  }, []);

  const handleScanResponse = useCallback((params: ScanResponse) => {
    if (!params.data) {
      return;
    }

    setTransferData({
      name: params.data.metadata.name,
      walletId: params.data.payload.params.wallet_id,
      transferType: TRANSFER_TYPE.SCANNER,
    });
  }, []);

  const {openScanner, isLoading: isScanning} = useQrScanner({
    preventListen: true,
    isOneTime: true,
    onScan: handleScanResponse,
  });

  return (
    <Wrapper>
      {!isLoading ? (
        wallets && (
          <>
            <div className="wallet-tab__header">
              <AvailableCoins coinBalance={wallets.default.coin_balance} />
            </div>
            <div className="wallet-tab__qr-wrapper">
              <img
                className="wallet-tab__qr-code"
                src={wallets.default.qr_code.file_url}
                alt="QR code"
              />
              <div className="wallet-tab__share-icon" onClick={handleShare}>
                <ShareIcon />
              </div>
            </div>
            <div className="wallet-tab__cta">
              <Button
                className="mb-base"
                onClick={handleTransferLink}
                disabled={isScanning}>
                {translate('sdk.web.wallets.cta.send', {
                  key: '{tokens}',
                  value: getShortTokenText(2),
                })}
              </Button>
              <Button className="mb-base" onClick={handleShare}>
                {translate('sdk.web.wallets.cta.receive', {
                  key: '{tokens}',
                  value: getShortTokenText(2),
                })}
              </Button>
              <CtaLink noMargin onClick={openScanner}>
                {isScanning ? (
                  <Loader color="#222" sm />
                ) : (
                  translate('sdk.web.wallets.scanner.cta')
                )}
              </CtaLink>
            </div>
            {transferData && (
              <TransferModal
                isVisible={!!transferData}
                transferData={transferData}
                coinBalance={wallets.default.coin_balance}
                onClose={() => setTransferData(undefined)}
                onComplete={onReload}
              />
            )}
          </>
        )
      ) : (
        <Loader color="#222" />
      )}
    </Wrapper>
  );
};

export default Wallet;

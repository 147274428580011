import React, {useContext} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {ReceiveCoinsResponse} from 'services/wallets';
import {formatToken} from 'utils/numbers';

import PromptModal from 'components/PromptModal/PromptModal';

import successImage from './images/success.svg';

interface CoinsReceivedPromptProps {
  data: ReceiveCoinsResponse | undefined;
  onClose: () => void;
}

const CoinsReceivedPrompt = (props: CoinsReceivedPromptProps) => {
  const {data, onClose} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const {translate} = useTranslations();
  const {getTokenText} = useTokenTranslation();

  return (
    <PromptModal
      isVisible={Boolean(data)}
      title={translate('sdk.web.wallets.transfer.success.title')}
      textContent={translate('sdk.web.wallets.transfer.received.success.text', [
        {
          key: '{nr}',
          value: formatToken({
            number: +(data?.amount || '0'),
            dp: 1,
          }),
        },
        {
          key: '{tokens}',
          value: getTokenText(parseInt(data?.amount || '0')),
        },
        {
          key: '{name}',
          value: data?.name,
        },
      ])}
      image={
        organizationConfig.theme.components.transfer?.successImage ||
        successImage
      }
      btnText={translate('sdk.web.wallets.transfer.received.success.cta')}
      onBtnClick={onClose}
      onClose={onClose}
    />
  );
};

export default CoinsReceivedPrompt;

import React from 'react';

import T0 from './images/thalers/0.svg';
import T1 from './images/thalers/1.svg';
import T2 from './images/thalers/2.svg';
import T3 from './images/thalers/3.svg';
import T4 from './images/thalers/4.svg';
import T5 from './images/thalers/5.svg';
import T6 from './images/thalers/6.svg';
import T7 from './images/thalers/7.svg';
import T8 from './images/thalers/8.svg';
import T9 from './images/thalers/9.svg';
import T10 from './images/thalers/10.svg';

const thalers = [T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10];

const CityThaler = (props: any) => {
  const {percent} = props;
  const thalerIndex = Math.floor((percent / 100) * (thalers.length - 1));
  const thalerImg = thalers[thalerIndex];

  return <img src={thalerImg} alt="" />;
};

export default CityThaler;

import axios from 'axios';
import Parser from 'rss-parser';

const defaultOptions = {
  baseURL: 'https://www.cleema.de',
  auth: {
    username: 'cleema',
    password: 'preview',
  },
};
const axiosInstance = axios.create(defaultOptions);

const parser = new Parser({
  customFields: {
    item: ['headerImage'],
  },
});

export const getDresdenNewsFeed = async () => {
  try {
    const {data} = await axiosInstance.get('/news.xml');
    const feed = await parser.parseString(data);

    return feed.items;
  } catch (error) {
    return [];
  }
};

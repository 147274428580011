import {useContext, useEffect} from 'react';

import {CACHE_KEYS} from 'constants/cache-keys';
import {
  NotificationContext,
  NotificationStats,
} from 'contexts/NotificationContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import {getNotificationStats} from 'services/notifications';

const useNotificationsStats = () => {
  const {updateStats} = useContext(NotificationContext);
  const {hasNotifications} = useOrganization();

  const {data: notificationStats, fetchData: refreshStats} =
    useFetcher<NotificationStats>({
      fetcher: getNotificationStats,
      initialValue: {
        unseen: 0,
        unread: 0,
      },
      key: CACHE_KEYS.NOTIFICATION_STATS,
      preventFetch: !hasNotifications,
    });

  useEffect(() => {
    updateStats(notificationStats);
  }, [updateStats, notificationStats]);

  return {
    refreshStats,
  };
};

export default useNotificationsStats;

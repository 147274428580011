import React, {createContext, useCallback, useEffect, useState} from 'react';
import pick from 'lodash/pick';

import {sendMessageToNativeApp} from 'services/native-api';
import {ReferralsData} from 'services/user';
import {logData} from 'services/log';
import {Team} from 'types/Team';

type ActivityObject = {
  showFullTokenPrompt?: boolean;
  isTreePlantingActive?: boolean;
  isInviteFriendsActive?: boolean;
  isReviewComplete?: boolean;
  isDiagnosticsEnabled?: boolean;
  selectedTeams?: Record<number, Partial<Team>>;
  hideFullTokenPrompt?: () => void;
  updateActivityData?: ({data}: {data: any}) => void;
  setIsTreePlantingActive?: (isActive: boolean) => void;
  updateIsTreePlantingActive?: (isActive: boolean) => void;
  updateIsInviteFriendsActive?: (isActive: boolean) => void;
  updateReferralsData?: (referralsData: ReferralsData) => void;
  updateDiagnostics?: (enabled: boolean) => void;
  updateSelectedTeams?: ({
    teamTypeId,
    team,
  }: {
    teamTypeId: number;
    team?: Team;
  }) => void;
  completeReview?: () => void;
};

export const ActivityContext = createContext<ActivityObject>({});

const ActivityProvider = (props: any) => {
  const [isReviewComplete, setIsReviewComplete] = useState(false);
  const [showFullTokenPrompt, setShowFullTokenPrompt] = useState(false);
  const [isTreePlantingActive, setIsTreePlantingActive] = useState(false);
  const [isInviteFriendsActive, setIsInviteFriendsActive] = useState(false);
  const [isDiagnosticsEnabled, setIsDiagnosticsEnabled] = useState(false);
  const [lastDataUpdate, setLastDataUpdate] = useState(0);

  const [selectedTeams, setSelectedTeams] = useState<
    Record<number, Partial<Team>>
  >({});

  useEffect(
    () => {
      if (lastDataUpdate === 0) {
        return;
      }

      const message = {
        type: 'save_data',
        data: {
          isTreePlantingActive,
          isInviteFriendsActive,
          isReviewComplete,
          isDiagnosticsEnabled,
        },
      };
      sendMessageToNativeApp({message});

      if (isDiagnosticsEnabled) {
        logData({
          message: 'Data saved to native',
          context: message,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastDataUpdate],
  );

  const updateActivityData = ({data}: {data: any}) => {
    const {
      isTreePlantingActive = false,
      isInviteFriendsActive = false,
      isReviewComplete = false,
      isDiagnosticsEnabled = false,
    } = data || {};
    setIsTreePlantingActive(isTreePlantingActive);
    setIsInviteFriendsActive(isInviteFriendsActive);
    setIsReviewComplete(isReviewComplete);
    setIsDiagnosticsEnabled(isDiagnosticsEnabled);
  };

  const updateDiagnostics = (isEnabled: boolean) => {
    setIsDiagnosticsEnabled(isEnabled);
    setLastDataUpdate(Date.now());
  };

  const updateSelectedTeams = useCallback(
    ({teamTypeId, team}: {teamTypeId: number; team?: Team}) => {
      setSelectedTeams((selectedTeams) => {
        if (!team) {
          delete selectedTeams[teamTypeId];
          return selectedTeams;
        }

        selectedTeams[teamTypeId] = pick(team, [
          'id',
          'name',
          'co2_avoided',
          'position',
          'passphrase_required',
        ]);
        return selectedTeams;
      });
      setLastDataUpdate(Date.now());
    },
    [],
  );

  const updateIsTreePlantingActive = (isActive: boolean) => {
    setIsTreePlantingActive(isActive);
    setLastDataUpdate(Date.now());
  };

  const updateIsInviteFriendsActive = (isActive: boolean) => {
    setIsInviteFriendsActive(isActive);
    setLastDataUpdate(Date.now());
  };

  const completeReview = () => {
    setIsReviewComplete(true);
    setLastDataUpdate(Date.now());
  };

  const hideFullTokenPrompt = () => {
    setShowFullTokenPrompt(false);
  };

  const settings = {
    showFullTokenPrompt,
    isTreePlantingActive,
    isReviewComplete,
    isDiagnosticsEnabled,
    isInviteFriendsActive,
    selectedTeams,
    hideFullTokenPrompt,
    updateActivityData,
    updateIsTreePlantingActive,
    setIsTreePlantingActive,
    completeReview,
    updateIsInviteFriendsActive,
    updateDiagnostics,
    updateSelectedTeams,
  };

  return (
    <ActivityContext.Provider value={settings}>
      {props.children}
    </ActivityContext.Provider>
  );
};

export default ActivityProvider;

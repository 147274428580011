import {useCallback} from 'react';
import capitalize from 'lodash/capitalize';

import {useTranslations} from 'hooks/use-translations';
import {getUnitString} from '../utils';
import {METERING_UNITS, METERING_ELEMENTS} from 'types/Metering';

type KeyValue = {
  key: string;
  value: string | number;
};

type TranslateOptions = Array<KeyValue> | KeyValue;

interface TranslateElementParams {
  element?: METERING_ELEMENTS;
  unit?: METERING_UNITS;

  capitalizeElement?: boolean;
}

interface UseElementTranslationParams {
  element?: METERING_ELEMENTS;
}

export type ElementTranslate = (
  key: string,
  options?: TranslateOptions,
  elementParams?: TranslateElementParams,
) => string;

const useElementTranslation = (props?: UseElementTranslationParams) => {
  const {translate} = useTranslations();

  const elementTranslate: ElementTranslate = useCallback(
    (
      key: string,
      options?: TranslateOptions,
      elementParams?: TranslateElementParams,
    ) => {
      const {element, unit, capitalizeElement} = {
        element: props?.element,
        unit: '',
        capitalizeElement: false,
        ...(elementParams || null),
      };

      const translateOptions = options
        ? options instanceof Array
          ? [...options]
          : [options]
        : [];

      if (element) {
        const elementString = translate(`sdk.web.meter.element.${element}`);
        translateOptions.push({
          key: '{element}',
          value: capitalizeElement ? capitalize(elementString) : elementString,
        });

        const unitKey = unit || getUnitString({element});
        const unitString = translate(`sdk.web.meter.unit.${unitKey}`);

        translateOptions.push({
          key: '{unit}',
          value: unitString,
        });
      }

      return translate(key, translateOptions);
    },
    [translate, props?.element],
  );

  return elementTranslate;
};

export default useElementTranslation;

export const APP_FEATURES = {
  CLIMATE_PARTNERS: 'climate-partners',
  HOME_QR_CODE_SCANNER: 'home-qr-code-scanner',
  USER_ACCOUNT: 'user-account',
  BADGE_SHARE: 'share-badge',
};

export const APP_PERMISSIONS = {
  GPS: 'gps',
  MOTION: 'motion',
  BATTERY_OPTIMIZATION: 'battery-optimization',
  CAMERA: 'camera',
};

export enum NEW_FEATURES {
  VEHICLE_SETTINGS = 'vehicle-settings',
  E_CAR_RATING = 'e-car-rating',
  SOCIO_DEMO_DATA = 'socio-demo-data',
}

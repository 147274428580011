import Parser from 'rss-parser';
import axios from 'axios';

const axiosInstance = axios.create({baseURL: 'https://news.klima-taler.com'});

const parser = new Parser({
  customFields: {
    item: ['headerImage', 'thumbnail'],
  },
});

export const getNewsFeed = async () => {
  try {
    const {data} = await axiosInstance.get('/feed?limit=10000');
    
    const feed = await parser.parseString(data);

    console.log('feed', feed)

    return feed.items.map((item) => ({
      ...item,
      id: item.guid,
      summary: item.contentSnippet,
      // @ts-ignore
      content: item['content:encoded'],
      // @ts-ignore
      headerImage: item['thumbnail'],
    }));
  } catch (error) {
    return [];
  }
};

import React, {useRef, useState} from 'react';
import * as yup from 'yup';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import styled from 'styled-components';
import {useTranslations} from 'hooks/use-translations';
import {useForm} from 'hooks/forms/use-form';
import useWarning from 'hooks/use-warning';
import routes from 'config/routes/water';
import {connect, RegisterData} from 'services/water';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import CopyText from 'components/Text/CopyText/CopyText';
import Input from './Input';
import Label from './Label';
import FormGroup from './FormGroup';
import Footer from './Footer';
import Button from './Button';
import CtaLink from './CtaLink';
import {ReactComponent as InfoIcon} from 'icons/info-alt.svg';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000;
  height: 100%;
`;

const Title = styled(CopyText)`
  display: block;
  margin-bottom: 15px;
`;

const InputsWrapper = styled.div`
  margin: 0 -6px;
`;

const Info = styled(Link)`
  display: flex;
  color: #000;
  padding-top: 3px;

  &:focus,
  &:hover {
    text-decoration: none;
    color: #000;
  }
`;

const InfoIconWrapper = styled.div`
  width: 26.5px;
  display: block;
  color: ${(props) => props.theme.colors.primary};
  fill: ${(props) => props.theme.colors.primary};
  flex-shrink: 0;
  margin-right: 7.5px;
`;

const initialValues = {
  username: '',
  password: '',
};

type RegistrationProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

const Registration = (props: RegistrationProps) => {
  const {onCancel, onConfirm} = props;

  const formRef = useRef<HTMLFormElement>(null);
  const {translate} = useTranslations();
  const {getKeyDownListener} = useForm();
  const {showWarning, WarningDialog} = useWarning();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = yup.object().shape({
    username: yup.string(),
    password: yup.string(),
  });

  const onSubmit = async (data: RegisterData) => {
    if (!data.username || !data.password) {
      showWarning({
        title: translate('sdk.web.water.settings.error.dialog.title'),
        content: translate('sdk.web.water.connect.error.input'),
      });
      return;
    }

    setIsSubmitting(true);

    try {
      await connect(data);
      formik.resetForm();
      setIsSubmitting(false);
      onConfirm();
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const message = translate(errorKey);
      showWarning({
        title: translate('sdk.web.water.connect.error.title'),
        content: message || translate('sdk.web.water.connect.error.fallback'),
      });
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit} ref={formRef}>
        <div>
          <Title>{translate('sdk.web.water.connect.title')}</Title>
          <InputsWrapper>
            <FormGroup>
              <Label>{translate('sdk.web.water.connect.name')}</Label>
              <Input
                placeholder={translate(
                  'sdk.web.water.connect.name.placeholder',
                )}
                name={'username'}
                formik={formik}
                value={formik.values.username}
                onChange={formik.handleChange}
                onKeyDown={getKeyDownListener({form: formRef.current})}
                enterkeyhint={'next'}
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('sdk.web.water.connect.password')}</Label>
              <Input
                placeholder={translate(
                  'sdk.web.water.connect.password.placeholder',
                )}
                name={'password'}
                formik={formik}
                value={formik.values.password}
                onChange={formik.handleChange}
                onKeyDown={getKeyDownListener({form: formRef.current})}
                enterkeyhint={'go'}
              />
            </FormGroup>
          </InputsWrapper>
          <Info to={routes.HOW_TO.href}>
            <InfoIconWrapper>
              <InfoIcon />
            </InfoIconWrapper>
            <CopyTextSm>{translate('sdk.web.water.connect.info')}</CopyTextSm>
          </Info>
        </div>
        <Footer>
          <Button isLoading={isSubmitting}>
            {translate('sdk.web.water.connect.confirm')}
          </Button>
          <CtaLink onClick={onCancel}>
            {translate('sdk.web.water.connect.cancel')}
          </CtaLink>
        </Footer>
      </Form>
      <WarningDialog />
    </>
  );
};

export default Registration;

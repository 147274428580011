import {useCallback, useEffect} from 'react';

import routes from 'config/routes';
import {NEW_FEATURES} from 'constants/app-features';
import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';

import vehicleSettingsIcon from './icons/vehicle-settings.svg';
import eCarRatingIcon from './icons/e-car-rating.svg';
import socioDemoDataIcon from './icons/socio-demo-data.svg';

const newFeatures = [
  {
    key: NEW_FEATURES.VEHICLE_SETTINGS,
    title: 'sdk.web.features.vehicle.settings.title',
    subtitle: 'sdk.web.features.vehicle.settings.subtitle',
    removalText: 'sdk.web.features.vehicle.settings.remove',
    icon: vehicleSettingsIcon,
    link: routes.SETTINGS.VEHICLE_SETTINGS.href,
    // publishDate: '2023-09-01T00:00:00.776Z',
    settingsFeatureKey: 'hasVehicleSettings',
    dependsOn: [],
    visible: true,
  },
  {
    key: NEW_FEATURES.E_CAR_RATING,
    title: 'sdk.web.features.eCar.title',
    subtitle: 'sdk.web.features.eCar.subtitle',
    removalText: 'sdk.web.features.eCar.remove',
    icon: eCarRatingIcon,
    link: routes.SETTINGS.E_CAR_RATING.href,
    settingsFeatureKey: 'hasVehicleSettings',
    dependsOn: [NEW_FEATURES.VEHICLE_SETTINGS],
    visible: false,
  },
  {
    key: NEW_FEATURES.SOCIO_DEMO_DATA,
    title: 'sdk.web.features.ssd.title',
    subtitle: 'sdk.web.features.ssd.subtitle',
    removalText: 'sdk.web.features.ssd.remove',
    icon: socioDemoDataIcon,
    link: routes.SETTINGS.SOCIO_DEMO_DATA.href,
    settingsFeatureKey: 'hasSocioDemoData',
    dependsOn: [],
    visible: true,
  },
];

export type NewFeature = typeof newFeatures[0];

const SEEN_FEATURES_KEY = 'seenFeatures';
const VISIBLE_FEATURES_KEY = 'visibleFeatures';
const INIT_DATE_KEY = 'seenFeaturesInitDate';

const getFeaturesFromStorage = (storageKey: string) => {
  try {
    return JSON.parse(localStorage.getItem(storageKey) || '[]');
  } catch (e) {}
  return [];
};

const addFeatureToStorage = (key: NEW_FEATURES, storageKey: string) => {
  const seenFeatures = getFeaturesFromStorage(storageKey);
  if (seenFeatures.includes(key)) {
    return false;
  }

  localStorage.setItem(storageKey, JSON.stringify([...seenFeatures, key]));

  return true;
};
const removeFeatureFromStorage = (key: NEW_FEATURES, storageKey: string) => {
  const seenFeatures = getFeaturesFromStorage(storageKey);

  localStorage.setItem(
    storageKey,
    JSON.stringify(seenFeatures.filter((f: NEW_FEATURES) => f !== key)),
  );

  return true;
};

const useNewFeatures = () => {
  // const {userProfile} = useUserProfile();
  const {translate} = useTranslations();
  const organizationData = useOrganization();

  const getNewFeatures = useCallback(() => {
    const seenFeatures = getFeaturesFromStorage(SEEN_FEATURES_KEY);
    const visibleFeatures = getFeaturesFromStorage(VISIBLE_FEATURES_KEY);
    // const initDate = new Date(userProfile.user.created_at);

    return newFeatures
      .filter(
        ({key, settingsFeatureKey, dependsOn, visible}) =>
          !seenFeatures.includes(key) &&
          (visible || visibleFeatures.includes(key)) &&
          // @ts-ignore
          organizationData[settingsFeatureKey],
        // new Date(publishDate) > initDate &&
      )
      .filter(
        ({dependsOn}, _, features) =>
          dependsOn.filter((key) =>
            features.find((feature) => feature.key === key),
          ).length === 0,
      )
      .map((feature) => {
        return {
          ...feature,
          title: translate(feature.title),
          subtitle: translate(feature.subtitle),
          removalText: translate(feature.removalText),
        };
      });
  }, [organizationData, translate]);

  const markFeatureAsSeen = useCallback((key: NEW_FEATURES) => {
    addFeatureToStorage(key, SEEN_FEATURES_KEY);
  }, []);

  const removeSeenFeature = useCallback((key: NEW_FEATURES) => {
    removeFeatureFromStorage(key, SEEN_FEATURES_KEY);
  }, []);

  const markFeatureAsVisible = useCallback((key: NEW_FEATURES) => {
    addFeatureToStorage(key, VISIBLE_FEATURES_KEY);
  }, []);

  const removeVisibleFeature = useCallback((key: NEW_FEATURES) => {
    removeFeatureFromStorage(key, VISIBLE_FEATURES_KEY);
  }, []);

  useEffect(() => {
    const initDate = localStorage.getItem(INIT_DATE_KEY);
    if (!initDate) {
      localStorage.setItem(INIT_DATE_KEY, new Date().toISOString());
    }
  }, []);

  return {
    getNewFeatures,
    markFeatureAsSeen,
    removeSeenFeature,
    markFeatureAsVisible,
    removeVisibleFeature,
  };
};

export default useNewFeatures;

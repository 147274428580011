import {useCallback} from 'react';

import {useTokenTranslation} from 'hooks/use-token-translation';
import {ReferralsData} from 'services/user';

export const useInviteFriendsTranslations = () => {
  const {getTokenText} = useTokenTranslation();

  const getRewardsText = useCallback(
    ({
      activitySummary,
      quantity,
    }: {
      activitySummary: any;
      quantity?: number;
    }) => {
      const referralsData = (
        activitySummary ? activitySummary.referralsData : {}
      ) as ReferralsData;

      const rewardQuantity =
        typeof quantity === 'undefined'
          ? referralsData.rewardQuantity
          : quantity;
      return `${rewardQuantity} ${getTokenText(rewardQuantity)}`;
    },
    [getTokenText],
  );

  return {
    getRewardsText,
  };
};

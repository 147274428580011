import merge from 'lodash/merge';

import base from 'config/organizations/base';

import DDorfCoin from 'components/ClimateWidgets/Co2Token/DDorfCoin/DDorfCoin';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/DDorfCoin/images/coins/full.svg';
import onboardingRedeemImg from 'components/Onboarding/images/duesseldorf/onboarding-redeem.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/duesseldorf/onboarding-privacy.svg';
import notificationsRecoinIcon from 'icons/push-notifications/ddorf/recoins.svg';

const config = {
  name: 'duesseldorf',
  theme: {
    fonts: {},
    colors: {},
    components: {
      nav: {
        routes: ['impact', 'offers', 'teams', 'notifications', 'more'],
      },
      notifications: {
        icons: {
          recoin: notificationsRecoinIcon,
        },
      },
    },
  },
  translations: {
    tokenTitle: 'sdk.web.duesseldorf.contributions.your.token',
    co2Needed: 'sdk.web.duesseldorf.contributions.savings.left.for.token',
    fullTokenText: 'sdk.web.duesseldorf.contributions.full.token.text',
    offersTitle: 'sdk.web.duesseldorf.offers.your.token',
    shortTokenSingular: 'sdk.web.duesseldorf.offers.token.required.singular',
    shortTokenPlural: 'sdk.web.duesseldorf.offers.token.required.plural',
    rewardType1Singular:
      'sdk.web.duesseldorf.contributions.inviteFriends.rewardType1.singular',
    rewardType1Plural:
      'sdk.web.duesseldorf.contributions.inviteFriends.rewardType1.plural',
    notEnoughTokens: 'sdk.web.duesseldorf.offers.not.enough.tokens',
    aboutTokenLabel: 'sdk.web.duesseldorf.settings.about.title',
    onBoarding: {
      one: {
        title: 'sdk.web.duesseldorf.onboarding.one.title',
        content: 'sdk.web.duesseldorf.onboarding.one.text',
      },
      two: {
        title: 'sdk.web.duesseldorf.onboarding.two.title',
        content: 'sdk.web.duesseldorf.onboarding.two.text',
        image: fullTokenImg,
      },
      three: {
        title: 'sdk.web.duesseldorf.onboarding.three.title',
        content: 'sdk.web.duesseldorf.onboarding.three.text',
        image: onboardingRedeemImg,
      },
      privacy: {
        title: 'sdk.web.duesseldorf.onboarding.privacy.title',
        content: 'sdk.web.duesseldorf.onboarding.privacy.text',
        confirmLink: 'sdk.web.duesseldorf.onboarding.privacy.link',
        image: onboardingPrivacyImg,
      },
      gps: {
        title: 'sdk.web.onboarding.gps.title',
        content: 'sdk.web.onboarding.gps.text',
      },
    },
    competition: {
      title: 'sdk.web.teams.germany.leaderboard.title',
      subtitle: 'sdk.web.teams.germany.leaderboard.subtitle',
    },
  },
  features: {
    hideAppClose: true,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCo2Compensation: true,
    hasCustomActivities: true,
    hasMetering: true,
    hasBadges: true,
    hasNewFAQ: true,
    hasCo2Emissions: true,
    hasNotifications: true,
    hasWidgetCustomization: true,
    hasWallets: true,
    hasNewQRScanner: true,
    hasVehicleSettings: true,
    hasClimateTreasure: true,
    hasUserRanking: true,
    hasUserRankingMobility: true,
    hideUserRankingParticipants: true,
    hideUserRankingInCities: true,
    stage: {},
  },
  applangaTag: '603aac9d8d40e003961bf96c',
  matomoTrackingId: 5,
  tokenType: 'DDorfCoin',
  fullTokenImg,
  FullTokenComponent: DDorfCoin,
};

export default merge({}, base, config);

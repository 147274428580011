import {useEffect} from 'react';
import {updateMetaTagContent} from 'utils/dom';

const useAllowScale = () => {
  useEffect(() => {
    updateMetaTagContent({
      name: 'viewport',
      key: 'maximum-scale',
      value: '3.0',
    });

    return () => {
      updateMetaTagContent({
        name: 'viewport',
        key: 'maximum-scale',
        value: '1.0',
      });
    };
  }, []);
};

export default useAllowScale;

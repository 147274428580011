import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';

import routes from 'config/routes';
import {NATIVE_MESSAGES} from 'constants/native-events';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useToast} from 'hooks/use-toast';
import {sendMessageToNativeApp} from 'services/native-api';
import {sendMessage} from 'services/contact';

import Input from 'components/Form/Input';
import TextArea from 'components/Form/TextArea';
import FormButton from 'components/Form/FormButton';
import InfoPage from 'components/InfoPage/InfoPage';

const Form = styled.form``;

const Row = styled.div`
  margin-bottom: 35px;
`;

const ButtonWrapper = styled.div`
  padding-top: 10px;
  text-align: center;
`;

const initialValues = {
  name: '',
  email: '',
  message: '',
};

type FormData = {
  name?: string;
  email?: string;
  message: string;
};

type ContactData = FormData & {
  subject: string;
  debug_info?: object | null;
};

const ContactForm = (props: any) => {
  const {requestDataDeletion} = props;
  const {debugInfo} = useContext(SettingsContext);
  const {translate} = useTranslations();
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    sendMessageToNativeApp({
      message: {
        type: NATIVE_MESSAGES.REQUEST_DEBUG_INFO,
      },
    });
  }, []);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(translate('sdk.web.contact.form.email.error.invalid'))
      .email(translate('sdk.web.contact.form.email.error.invalid')),
    name: yup.string(),
    message: yup
      .string()
      .required(translate('sdk.web.contact.form.message.error.required')),
  });

  const onClose = () => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.SETTINGS.href);
    }
  };

  const onSubmit = async (contactData: FormData) => {
    setIsSubmitting(true);
    const data: ContactData = {
      subject: title,
      ...contactData,
      debug_info: debugInfo || null,
    };

    try {
      await sendMessage({data});
      onMessageSent();
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const message = errorKey
        ? translate(errorKey)
        : translate('sdk.web.contact.form.error.fallback');
      toast.error(message);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const onMessageSent = () => {
    setIsSubmitting(false);
    formik.resetForm();
    toast.success(translate('sdk.web.contact.form.success.message'));
  };

  const title = requestDataDeletion
    ? 'Request Data Deletion'
    : translate('sdk.web.contact.form.title');

  return (
    <InfoPage title={title} onClose={onClose}>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Input
            name={'name'}
            formik={formik}
            placeholder={translate('sdk.web.contact.form.name.label')}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </Row>
        <Row>
          <Input
            placeholder={translate('sdk.web.contact.form.email.label')}
            name={'email'}
            type={'email'}
            formik={formik}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </Row>
        <Row>
          <TextArea
            placeholder={translate('sdk.web.contact.form.message.label')}
            name={'message'}
            formik={formik}
            value={formik.values.message}
            onChange={formik.handleChange}
          />
        </Row>
        <ButtonWrapper>
          <FormButton isLoading={isSubmitting}>
            {translate('sdk.web.contact.form.cta')}
          </FormButton>
        </ButtonWrapper>
      </Form>
    </InfoPage>
  );
};

export default withRouter(ContactForm);

import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import Modal from '../Modal/Modal';
import SummaryPage from '../SummaryPage/SummaryPage';

const Wrapper = styled.div`
  display: block;
  background-color: ${(props) => props.theme.colors.white};
`;

const ContentWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  min-height: 100vh;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface ForgotPasswordPromptProps {
  title: string;
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const AccountPrompt = (props: ForgotPasswordPromptProps) => {
  const {isVisible, title, onClose, children} = props;
  const {updateBg} = useBodyBg({});

  useEffect(
    () => {
      if (isVisible) {
        updateBg({type: 'light'});
      }

      return () => updateBg({type: 'primary'});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible],
  );

  return (
    <React.Fragment>
      {isVisible && (
        <Wrapper>
          <Modal>
            <ContentWrapper>
              <SummaryPage onClose={onClose} title={title}>
                <Content>{children}</Content>
              </SummaryPage>
            </ContentWrapper>
          </Modal>
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default AccountPrompt;

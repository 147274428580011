import React, {createContext, useRef, useState} from 'react';

import en from '../i18n/en.json';
import de from '../i18n/de.json';
import fi from '../i18n/fi.json';
import ja from '../i18n/ja.json';
import svFi from '../i18n/sv-FI.json';
import pt from '../i18n/pt.json';

const axios = require('axios').default;

const APPLANGA_APP_ID = process.env.REACT_APP_APPLANGA_APP_ID;
const APPLANGA_APP_TOKEN = process.env.REACT_APP_APPLANGA_API_TOKEN;

interface Translation {
  translations: {[index: string]: string};
  isFromApplanga?: boolean;
}

interface TranslationList {
  [index: string]: Translation;
}

interface TranslationsLoader {
  locale: string;
  tagId: string;
}

const defaultTranslations: TranslationList = {
  en: {
    translations: en,
  },
  de: {
    translations: de,
  },
  fi: {
    translations: fi,
  },
  ja: {
    translations: ja,
  },
  svFi: {
    translations: svFi,
  },
  sv: {
    translations: svFi,
  },
  pt: {
    translations: pt,
  },
};

type TranslationsObject = {
  translations: TranslationList;
  loadTranslations?: (options: TranslationsLoader) => void;
};

export const TranslationsContext = createContext<TranslationsObject>({
  translations: defaultTranslations,
});

const TranslationsProvider = (props: any) => {
  const [translations, setTranslations] = useState(defaultTranslations);
  const isLoading = useRef(false);

  const loadTranslations = async ({locale, tagId}: TranslationsLoader) => {
    if (isLoading.current) {
      return;
    }

    if (translations[locale].isFromApplanga) {
      return;
    }

    try {
      isLoading.current = true;

      const res = await axios({
        url: `https://api.applanga.com/v1/api?app=${APPLANGA_APP_ID}&requestedLanguages=["${locale}"]&includeSrc=false&requestedTagIds=["5f203d41b1dbe033da8bc090","${tagId}"]`,
        headers: {
          authorization: `Bearer ${APPLANGA_APP_TOKEN}`,
        },
      });
      const applangaStrings = res.data.data[locale].main.entries;
      Object.keys(applangaStrings).map((key) => {
        applangaStrings[key] = applangaStrings[key].v;
        return key;
      });

      const newTranslations = {
        ...translations,
        [locale]: {
          translations: {
            ...(translations[locale]?.translations || {}),
            ...applangaStrings,
          },
          isFromApplanga: true,
        },
      };

      setTranslations(newTranslations);
      isLoading.current = false;
    } catch (e: any) {
      isLoading.current = false;
    }
  };

  return (
    <TranslationsContext.Provider value={{translations, loadTranslations}}>
      {props.children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsProvider;

import React, {useCallback} from 'react';
import styled from 'styled-components';
import {FormikContextType} from 'formik';

import {ApiErrors} from 'hooks/forms/use-form-validation';

import FormError from 'components/Form/FormError';

import {ReactComponent as StarIcon} from 'icons/star.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Item = styled.div`
  margin-right: 5px;
`;

const Icon = styled.div<{selected: boolean}>`
  width: 40px;

  svg {
    width: 100%;
    fill: ${(props) =>
      props.selected ? props.theme.colors.primary : '#F2F2F2'};
  }
`;

type RatingProps = {
  name?: string;
  value?: string | number;
  ranges?: Array<number>;
  onChange: (e: any) => void;
  formik: FormikContextType<any>;
  apiErrors?: ApiErrors;
};

const Rating = (props: RatingProps) => {
  const {
    name,
    value,
    formik,
    apiErrors,
    onChange,
    ranges = [1, 2, 3, 4, 5],
  } = props;

  const handleChange = useCallback(
    (value: number) => {
      onChange({
        target: {
          name,
          value,
        },
      });
    },
    [name, onChange],
  );

  return (
    <>
      <Wrapper>
        {ranges.map((range) => (
          <Item key={range} onClick={() => handleChange(range)}>
            <Icon selected={!!(value && value >= range)}>
              <StarIcon />
            </Icon>
          </Item>
        ))}
      </Wrapper>
      <FormError name={name} formik={formik} apiErrors={apiErrors} />
    </>
  );
};

export default Rating;

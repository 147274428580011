import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';

import routes from 'config/routes/rewards';
import {useTranslations} from 'hooks/use-translations';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useToast} from 'hooks/use-toast';
import {addBoldText, addFontFamily} from 'utils/theme';

import CopyText from 'components/Text/CopyText/CopyText';
import SummaryPage from 'components/SummaryPage/SummaryPage';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';

import {ReactComponent as ShareIcon} from './icons/share.svg';
import {ReactComponent as CopyIcon} from './icons/copy.svg';

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled(HeaderText)`
  display: block;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;

  ${(props) =>
    addFontFamily({
      props,
      value: props.theme.components.vouchers?.online?.titleFontFamily,
    })}
`;

const Content = styled(CopyText)`
  display: block;
  text-align: center;
`;

const Info = styled(CopyText)`
  display: block;
  margin-top: 20px;
`;

const Footer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Img = styled.img`
  margin-bottom: 20px;
  width: 60%;
`;

const Share = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CodeLabel = styled(CopyText)`
  display: block;
  margin-bottom: 10px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Code = styled(CopyText)`
  display: block;
  color: ${(props) =>
    props.theme.components.vouchers?.online?.codeColor ||
    props.theme.colors.black};
  position: relative;
  margin-right: 10px;
`;

const Icon = styled.div<{fill?: boolean}>`
  display: block;
  width: 15px;
  flex-shrink: 0;

  ${(props) => {
    return `
      ${props.fill ? 'fill' : 'stroke'}: ${
      props.theme.components.vouchers?.online?.codeColor ||
      props.theme.colors.black
    };
    `;
  }}
  .svg {
    width: 100%;
  }
`;

const PageLink = styled.a`
  display: block;
  color: ${(props) =>
    props.theme.components.vouchers?.online?.codeColor ||
    props.theme.colors.black};
  text-decoration: underline;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Text = styled(CopyText)<{bold?: boolean}>`
  display: block;
  margin-bottom: 10px;

  ${(props) =>
    props.bold
      ? addBoldText({
          props,
        })
      : ''}
`;

const VoucherOffer = (props: any) => {
  const {voucher, onClose, onVoucherShare} = props;
  const {translate} = useTranslations();
  const toast = useToast();
  useBodyBg({
    type: 'light',
  });
  const hasCode = !!voucher.code;

  const handleCopy = useCallback(() => {
    toast.success(translate('sdk.web.vouchers.copied'));
  }, [toast, translate]);

  return (
    <SummaryPage onClose={onClose}>
      <Container>
        <div>
          <Title>{voucher.offer.title}</Title>
          <Content>
            <Img src={voucher.offer.image_file.url} alt="" />
            <Share>
              {hasCode ? (
                <>
                  <CodeLabel>
                    {translate('sdk.web.vouchers.code.label')}
                  </CodeLabel>
                  <CopyToClipboard text={voucher.code} onCopy={handleCopy}>
                    <CodeWrapper>
                      <Code>{voucher.code}</Code>
                      <Icon fill={true}>
                        <CopyIcon />
                      </Icon>
                    </CodeWrapper>
                  </CopyToClipboard>
                </>
              ) : (
                <Icon onClick={onVoucherShare}>
                  <ShareIcon />
                </Icon>
              )}
            </Share>
            {voucher.offer.website_url && (
              <>
                {hasCode && (
                  <Text bold={true}>
                    {translate('sdk.web.vouchers.redeem')}:
                  </Text>
                )}
                <Text>
                  <PageLink href={voucher.offer.website_url}>
                    {voucher.offer.website_name}
                  </PageLink>
                </Text>
              </>
            )}
          </Content>
          <Info>{translate('sdk.web.vouchers.saved.message')}</Info>
        </div>
        <Footer>
          <ButtonContainer>
            <Button onClick={onClose}>{translate('sdk.web.offers.ok')}</Button>
          </ButtonContainer>
          <CtaLink
            as={Link}
            to={`${routes.REWARDS.VOUCHERS.HOW_TO.href}/${voucher.offer.id}`}>
            {translate('sdk.web.vouchers.how.to.use')}
          </CtaLink>
          <CtaLink
            as={Link}
            to={`${routes.REWARDS.DEALS.VOUCHER_TERMS.href}/${voucher.offer.id}`}
            noMargin={true}>
            {translate('sdk.web.offers.terms')}
          </CtaLink>
        </Footer>
      </Container>
    </SummaryPage>
  );
};

export default VoucherOffer;

import {zeroPad} from 'react-countdown';

export const getDateElements = ({date, utc}: {date: Date; utc?: boolean}) => {
  if (utc) {
    return {
      month: date.getUTCMonth(),
      day: date.getUTCDate(),
      year: date.getUTCFullYear(),
      hours: date.getUTCHours(),
      minutes: date.getUTCMinutes(),
    };
  }

  return {
    month: date.getMonth(),
    day: date.getDate(),
    year: date.getFullYear(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};

export const formatDate = ({
  date,
  addTime,
  skipYear,
  utc = true,
}: {
  date: string | Date;
  skipYear?: boolean;
  addTime?: boolean;
  utc?: boolean;
}) => {
  const dateObject = date instanceof Date ? date : getDateFromString({date});
  const {year, month, day, hours, minutes} = getDateElements({
    date: dateObject,
    utc,
  });

  let results = `${day}.${month + 1}.${skipYear ? '' : year}`;
  if (addTime) {
    results = `${results} ${zeroPad(hours)}:${zeroPad(minutes)}`;
  }

  return results;
};

export const getDateFromString = ({date}: {date: string}) =>
  new Date(date.includes('T') ? date : date.replace(/-/g, '/'));

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

type DateParams = {
  date: Date;
};

export const getCurrentMonth = () => new Date().getMonth();

export const getMonthKeyFromDate = ({date}: DateParams = {date: new Date()}) =>
  getMonthKey(date.getUTCMonth());

export const getMonthKey = (index: number) => months[index];

export const getMonthTranslationKey = (
  {date}: DateParams = {date: new Date()},
) => `sdk.web.month.${getMonthKeyFromDate({date})}`;

export const getDaysInMonth = ({date}: DateParams = {date: new Date()}) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const getDaysLeftInMonth = ({date}: DateParams = {date: new Date()}) => {
  const daysInMonth = getDaysInMonth({date});
  const currentDay = date.getDate();

  return daysInMonth - currentDay;
};

export const formatLotteryDate = ({date}: {date: string | Date}) => {
  const dateObject = date instanceof Date ? date : getDateFromString({date});
  // Add 1 hr, 1 minute
  const newDate = new Date(dateObject.getTime() + 61 * 60000);

  return formatDate({
    date: newDate,
    utc: true,
  });
};

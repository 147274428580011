import React from 'react';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import WaterSettings from 'components/Water/WaterSettings';

const WaterPage = () => {
  useBodyBg({
    type: 'lightSecondary',
  });

  return <WaterSettings />;
};

export default WaterPage;

import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Link} from 'react-router-dom';

import routes from 'config/routes/rewards';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {Voucher} from 'types/Offers';

import Loader from 'components/Loader/Loader';
import LocationType from 'components/Rewards/LocationType/LocationType';
import CardNew from 'components/Rewards/Deals/Card';
import PullToRefresh from 'components/PullToRefresh';

const LoaderContainer = styled.div`
  padding: 30px;
`;

const Wrapper = styled.div`
  padding: 10px;
  height: 100%;
  padding-bottom: 0;
`;

const List = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
`;

const LotteryItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const Item = styled.div`
  width: calc(50% - 5px);
  margin-bottom: 10px;
`;

const Deal = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;
  height: 100%;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const ScrollContainer = styled.div`
  overflow: auto;
  height: calc(100% - 2px);
`;

const Deals = (props: any) => {
  const {
    lotteries,
    vouchers,
    isRefreshing,
    isVoucherLoading,
    userRecoins,
    updateVoucherWishlist,
    fetchMoreVouchers,
    vouchersPagination,
    onRefresh,
    currentItem,
    onSelectItem,
  } = props;

  const voucherItemsRef = useRef(new Map());

  useBodyBg({type: 'primary'});

  useEffect(() => {
    voucherItemsRef.current?.get(currentItem)?.scrollIntoView({
      block: 'center',
    });
  }, [currentItem]);

  return (
    <ScrollContainer id="scrollContainer">
      {isRefreshing && (
        <LoaderContainer>
          <Loader sm={true} />
        </LoaderContainer>
      )}
      <PullToRefresh onRefresh={onRefresh}>
        <Wrapper>
          <ScrollContainer>
            <List>
              {lotteries?.map((lottery: any) => (
                <LotteryItem key={lottery.id}>
                  <Deal
                    to={`${routes.REWARDS.DEALS.href}/lottery/${lottery.id}`}>
                    <CardNew {...lottery} userRecoins={userRecoins} />
                  </Deal>
                </LotteryItem>
              ))}
            </List>
            {!isVoucherLoading ? (
              <InfiniteScroll
                dataLength={vouchers?.length || 0}
                next={fetchMoreVouchers}
                hasMore={vouchersPagination.hasMore}
                scrollableTarget="scrollContainer"
                loader={<Loader sm />}>
                <List>
                  {vouchers?.map((voucher: Voucher) => (
                    <Item
                      key={voucher.id}
                      ref={(ref) =>
                        voucherItemsRef.current?.set(voucher.id, ref!)
                      }>
                      <Deal
                        to={`${routes.REWARDS.DEALS.href}/vouchers/${voucher.id}`}
                        onClick={() => onSelectItem(voucher.id)}>
                        <CardNew
                          {...voucher}
                          userRecoins={userRecoins}
                          updateVoucherWishlist={updateVoucherWishlist}
                          imageHeight="129px">
                          <LocationType voucher={voucher} />
                        </CardNew>
                      </Deal>
                    </Item>
                  ))}
                </List>
              </InfiniteScroll>
            ) : (
              <Loader />
            )}
          </ScrollContainer>
        </Wrapper>
      </PullToRefresh>
    </ScrollContainer>
  );
};

export default Deals;

import styled from 'styled-components';
import React, {useCallback, useMemo} from 'react';

import routes from 'config/routes';
import {useFetcher} from 'hooks/use-fetcher';
import {CACHE_KEYS} from 'constants/cache-keys';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';
import {
  getNotificationPreferences,
  updateNotificationPreference,
} from 'services/notifications';
import {NotificationPreference} from 'types/Notification';

import Loader from 'components/Loader/Loader';
import InfoPage from 'components/InfoPage/InfoPage';
import RadioButton from 'components/Form/RadioButton';
import CopyText from 'components/Text/CopyText/CopyText';

import PushImage from './push-notif.svg';

type NotificationPreferenceData = {
  type: string;
  enabled: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  padding-bottom: 0;

  .notif-settings {
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__list {
      overflow-y: auto;
      padding-bottom: 10px;
    }

    &__row {
      display: flex;
      margin-bottom: 20px;
    }

    &__indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border: 1px solid
        ${(props) =>
          props.theme.components.form?.radioColor ||
          props.theme.colors.primary};
      border-radius: 100%;
      margin-right: 5px;
      padding: 2px;

      &--checked {
        background-color: ${(props) =>
          props.theme.components.form?.radioColor ||
          props.theme.colors.primary};
      }
    }

    &__check-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #ffffff;
      width: 100%;

      svg {
        width: 100%;
      }
    }

    &__texts {
      margin-left: 10px;

      &__title {
        font-weight: 600;
        margin-bottom: 10px;
        display: block;
      }
    }
  }
`;

const NotificationsPage = () => {
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});

  const {data, isLoading, updateDataById} = useFetcher<
    Array<NotificationPreference>
  >({
    fetcher: getNotificationPreferences,
    key: CACHE_KEYS.NOTIFICATION_PREFERENCES,
    initialValue: [],
  });

  const handleNotificationsMap = useMemo(() => {
    return data
      .map((notification) => {
        return {
          ...notification,
          title: translate(`sdk.web.notifs.${notification.type}.title`),
          subtitle: translate(`sdk.web.notifs.${notification.type}.subtitle`),
        };
      })
      .filter(({title}) => title);
  }, [data, translate]);

  const onHandleNotificationPush = useCallback(
    async (data: NotificationPreferenceData) => {
      try {
        await updateNotificationPreference(data);
        updateDataById({
          idKey: 'type',
          id: data.type,
          updates: {
            enabled: data.enabled,
          },
        });
      } catch (error) {
        return;
      }
    },
    [updateDataById],
  );

  return (
    <InfoPage
      title={translate('sdk.web.notifs.header')}
      onClose={handleGoBack}
      noPadding>
      <Wrapper className="notif-settings">
        <div className="notif-settings__header mb-base">
          <CopyText className="mb-base">
            {translate('sdk.web.notifs.title')}
          </CopyText>
          <img src={PushImage} alt="" />
        </div>
        <div className="notif-settings__list">
          {isLoading ? (
            <Loader sm />
          ) : (
            handleNotificationsMap.map(({type, enabled, title, subtitle}) => (
              <div className="notif-settings__row" key={type}>
                <RadioButton
                  checked={enabled}
                  onClick={() =>
                    onHandleNotificationPush({type, enabled: !enabled})
                  }
                />
                <div className="notif-settings__texts">
                  <CopyText className="notif-settings__texts__title">
                    {title}
                  </CopyText>
                  <CopyText>{subtitle}</CopyText>
                </div>
              </div>
            ))
          )}
        </div>
      </Wrapper>
    </InfoPage>
  );
};

export default NotificationsPage;

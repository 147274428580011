import React, {useCallback, useMemo, useRef, useState} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import styled from 'styled-components';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';

import {LIMITS_ERROR_CODES} from './constants';
import {useForm} from 'hooks/forms/use-form';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useToast} from 'hooks/use-toast';
import useImageRequest from './hooks/use-image-request';
import useElementTranslation from '../../hooks/use-element-translation';
import {captureException} from 'services/errors';
import {uploadImageData} from 'services/images';
import {addManualMeasurement} from 'services/metering';
import {normalizeConsumption, sumConsumptionValues} from './utils';
import {addGlobalSquareCorners} from 'utils/theme';
import {getDeviceLanguage} from 'utils/language';
import {ConsumptionScreenProps} from './types';

import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import CtaLink from 'components/CtaLink/CtaLink';
import FormGroup from 'components/Form/FormGroup';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import PromptModal from 'components/PromptModal/PromptModal';

import Input from '../Input';
import Label from '../Label';
import PermissionDialog from './PermissionDialog';
import {Error} from 'components/Form/FormError';

const language = getDeviceLanguage();
const numberSeparator = language === 'de' ? 'comma' : 'dot';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px 40px;
  text-align: left;

  .e-consumption-settings {
    &__title,
    &__subtitle {
      text-align: center;
      margin-bottom: 40px;
    }

    &__img-wrapper {
      width: 100%;
      margin-bottom: 30px;
    }

    &__img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      object-position: center;
    }

    &__img-help {
      text-align: center;
      margin-bottom: 10px;
    }

    &__input-group {
      display: flex;
      width: 100%;
    }

    &__integer-input {
      flex-grow: 1;

      .form-error {
        width: calc(100% + 105px);
      }
    }

    &__decimal-input {
      width: 95px;
      flex-shrink: 0;
    }

    &__dot-wrapper {
      height: 40px;
      margin: 0 4px;
      padding-bottom: 2px;
      display: flex;
      align-items: flex-end;
    }

    &__dot {
      width: 5px;
      height: 5px;
      background-color: #ff0000;
      border-radius: 100%;
    }

    &__comma {
      width: 5.3px;
      height: 9px;
      background-color: #ff0000;
      clip-path: polygon(0 0, 100% 0, 0 100%);
    }

    &__cta {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__btn {
      margin-bottom: 20px;
    }
  }
`;

const NumberInput = styled(Input)<{textAlign?: string}>`
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 5px;
  height: 40px;
  padding: 5px;
  text-align: ${(props) => props.textAlign};

  ${addGlobalSquareCorners()}
`;

const DecimalInput = styled(NumberInput)`
  border-color: #ff0000;
  background-color: #ffdddd;
`;

const Settings = (props: ConsumptionScreenProps) => {
  const {element, elementData, stats, manualMeasurement, image, onComplete} =
    props;
  const translate = useElementTranslation({element});
  const toast = useToast();

  const [meterImage, setMeterImage] = useState(image);

  const formRef = useRef<HTMLFormElement>(null);
  const {getKeyDownListener} = useForm();
  const {apiErrors, validate} = useFormValidation();
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();

  const [isSoftLimitOpen, openSoftLimit, closeSoftLimit] = useBoolean(false);
  const [isHardLimitOpen, openHardLimit, closeHardLimit] = useBoolean();
  const [isDailyLimitOpen, openDailyLimit, closeDailyLimit] = useBoolean();

  const {
    title,
    subtitle,
    label,
    initialValues,
    validationSchema,
    normalizedValue,
  } = useMemo(() => {
    let title = translate('sdk.web.meter.consumption.title');
    let subtitle = translate('sdk.web.meter.consumption.settings.subtitle');

    if (manualMeasurement) {
      title = translate('sdk.web.meter.consumption.edit.title');
      subtitle = translate(
        'sdk.web.meter.consumption.edit.subtitle',
        {
          key: '{nr}',
          value: normalizeConsumption({
            value: manualMeasurement.value,
            dp: 3,
            element,
            unit: elementData.consumptionUnit,
            formatResults: true,
          }),
        },
        {
          unit: elementData.consumptionUnit,
        },
      );
    }

    const unit = elementData.consumptionUnit;
    const normalizedValue = stats?.last_reading
      ? normalizeConsumption({
          unit,
          element,
          value: stats.last_reading,
          formatResults: false,
        })
      : '';
    const initialValues = {
      integerValue: normalizedValue
        ? Math.floor(Number(normalizedValue)).toString()
        : '',
      decimalValue: '',
    };
    const label = translate('sdk.web.meter.consumption.label', undefined, {
      unit,
    });

    const validationSchema = yup.object().shape({
      integerValue: yup
        .number()
        .required(translate('sdk.web.meter.consumption.settings.required')),
    });

    return {
      title,
      subtitle,
      label,
      initialValues,
      validationSchema,
      unit,
      normalizedValue,
    };
  }, [element, elementData, stats, manualMeasurement, translate]);

  const showImageError = useCallback(() => {
    const message = translate('sdk.web.meter.consumption.upload.error');
    toast.error(message);
  }, [translate, toast]);

  const uploadPhoto = useCallback(async () => {
    try {
      const imageUrl = await uploadImageData({
        type: 'image',
        category: 'manual_metering',
        image: meterImage!,
      });

      if (imageUrl) {
        return imageUrl;
      }
    } catch (error) {
      captureException({error});
    }
  }, [meterImage]);

  const handleImageResponse = useCallback(({image}: {image: string}) => {
    setMeterImage(image);
  }, []);

  const {isPermDialogVbl, handlePermissionCheck, handleTakePhoto} =
    useImageRequest({onComplete: handleImageResponse});

  const submit = useCallback(
    async ({data, force}: {data: typeof initialValues; force?: boolean}) => {
      try {
        startSubmitting();
        let _image = meterImage;
        if (!manualMeasurement) {
          _image = await uploadPhoto();
          if (!_image) {
            showImageError();
            stopSubmitting();
          }
        }

        const value = sumConsumptionValues(data);

        const manualMeasurementData = await addManualMeasurement({
          data: {
            value,
            type: element,
            image: _image as string,
            force,
          },
          update: !!manualMeasurement,
        });

        onComplete({
          data: {manualMeasurement: manualMeasurementData, image: _image},
        });
      } catch (e: any) {
        const errorDetails = e.response?.data?._details;

        switch (errorDetails) {
          case LIMITS_ERROR_CODES.SOFT_LIMIT:
            return openSoftLimit();
          case LIMITS_ERROR_CODES.HARD_LIMIT:
            return openHardLimit();
          case LIMITS_ERROR_CODES.DAILY_LIMIT:
            return openDailyLimit();
          default:
            break;
        }

        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.meter.consumption.settings.error.fallback',
        );
        toast.error(message);
      } finally {
        stopSubmitting();
      }
    },
    [
      meterImage,
      element,
      manualMeasurement,
      toast,
      translate,
      startSubmitting,
      stopSubmitting,
      onComplete,
      openSoftLimit,
      openHardLimit,
      openDailyLimit,
      showImageError,
      uploadPhoto,
    ],
  );

  const onSubmit = useCallback(
    async (data: typeof initialValues) => {
      submit({data});
    },
    [submit],
  );

  const formik = useFormik({
    validationSchema,
    initialValues: {
      integerValue: '',
      decimalValue: '',
    },
    onSubmit,
    enableReinitialize: true,
    validate,
  });

  const {value, isValid} = useMemo(() => {
    const value = sumConsumptionValues(formik.values);
    return {value, isValid: value >= Number(normalizedValue)};
  }, [formik.values, normalizedValue]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const regex = /^[0-9]*$/;
      if (!regex.test(event.target.value)) {
        return;
      }

      formik.handleChange(event);
    },
    [formik],
  );

  return (
    <Wrapper ref={formRef} onSubmit={formik.handleSubmit}>
      <div>
        {manualMeasurement && (
          <>
            <div className="e-consumption-settings__title">
              <Title dangerouslySetInnerHTML={{__html: title}} />
            </div>
            <div className="e-consumption-settings__subtitle">
              <CopyText dangerouslySetInnerHTML={{__html: subtitle}} />
            </div>
          </>
        )}
        {!manualMeasurement && meterImage && (
          <div>
            <CopyTextSm as="div" className="e-consumption-settings__img-help">
              {translate('sdk.web.meter.consumption.image.zoom')}
            </CopyTextSm>
            <div className="e-consumption-settings__img-wrapper">
              <TransformWrapper>
                <TransformComponent>
                  <img
                    className="e-consumption-settings__img"
                    src={meterImage}
                    alt=""
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          </div>
        )}
        <FormGroup>
          <Label>{label}</Label>
          <div className="e-consumption-settings__input-group">
            <div className="e-consumption-settings__integer-input">
              <NumberInput
                placeholder=""
                name="integerValue"
                type="number"
                step="1"
                pattern="[0-9]"
                textAlign="right"
                min={(initialValues.integerValue || 0).toString()}
                formik={formik}
                value={formik.values.integerValue}
                onChange={handleInputChange}
                onKeyDown={getKeyDownListener({form: formRef.current})}
                apiErrors={apiErrors}
              />
            </div>
            <div className="e-consumption-settings__dot-wrapper">
              <div className={`e-consumption-settings__${numberSeparator}`} />
            </div>
            <div className="e-consumption-settings__decimal-input">
              <DecimalInput
                placeholder=""
                name="decimalValue"
                type="number"
                step="1"
                pattern="[0-9]"
                min="0"
                formik={formik}
                value={formik.values.decimalValue}
                onChange={handleInputChange}
                onKeyDown={getKeyDownListener({form: formRef.current})}
                enterkeyhint={'go'}
                apiErrors={apiErrors}
              />
            </div>
          </div>
          {!isValid && formik.dirty && (
            <Error>
              {translate('sdk.web.meter.consumption.settings.min', {
                // eslint-disable-next-line no-template-curly-in-string
                key: '${more}',
                value: normalizedValue.toString(),
              })}
            </Error>
          )}
        </FormGroup>
      </div>
      <div className="e-consumption-settings__cta">
        <Button
          className="e-consumption-settings__btn"
          isLoading={isSubmitting}
          disabled={!isValid}>
          {translate('sdk.web.meter.consumption.cta')}
        </Button>
        {manualMeasurement && (
          <CtaLink
            noMargin
            className="e-consumption-settings__cancel"
            onClick={() =>
              onComplete({
                data: {
                  manualMeasurement,
                },
              })
            }>
            {translate('sdk.web.meter.consumption.edit.cancel')}
          </CtaLink>
        )}
        {!manualMeasurement && meterImage && (
          <CtaLink noMargin onClick={handleTakePhoto}>
            {translate('sdk.web.meter.consumption.photo.re')}
          </CtaLink>
        )}
      </div>
      <PermissionDialog
        isVisible={isPermDialogVbl}
        onConfirmation={handlePermissionCheck}
      />
      <PromptModal
        isVisible={isSoftLimitOpen}
        title={translate('sdk.web.meter.consumption.limit.soft.title')}
        textContent={translate(
          'sdk.web.meter.consumption.limit.soft.text',
          {
            key: '{value}',
            value: normalizeConsumption({
              element,
              value,
              formatResults: true,
            }),
          },
          {
            unit: elementData.consumptionUnit,
          },
        )}
        isButtonLoading={isSubmitting}
        btnText={translate('sdk.web.meter.consumption.limit.soft.confirm')}
        onBtnClick={() => submit({data: formik.values, force: true})}
        moreInfo={{
          text: translate('sdk.web.meter.consumption.limit.soft.edit'),
          onClick: isSubmitting ? undefined : closeSoftLimit,
        }}
      />
      <PromptModal
        isVisible={isHardLimitOpen}
        title={translate('sdk.web.meter.consumption.limit.hard.title')}
        textContent={translate('sdk.web.meter.consumption.limit.hard.text')}
        btnText={translate('sdk.web.dialog.box.ok')}
        onBtnClick={closeHardLimit}
      />
      <PromptModal
        isVisible={isDailyLimitOpen}
        title={translate('sdk.web.meter.consumption.limit.daily.title')}
        textContent={translate('sdk.web.meter.consumption.limit.daily.text')}
        btnText={translate('sdk.web.dialog.box.ok')}
        onBtnClick={closeDailyLimit}
      />
    </Wrapper>
  );
};

export default Settings;

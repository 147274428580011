import buttons from 'config/organizations/preset/buttons';
import languageFonts from 'config/organizations/preset/language-fonts';

import Co2Thaler from 'components/ClimateWidgets/Co2Token/CityThaler';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/CityThaler/images/thalers/full.svg';

import customActivitiesImgEn from 'components/ClimateWidgets/Mobility/images/sdksample/custom-activities-en.svg';
import customActivitiesImgDe from 'components/ClimateWidgets/Mobility/images/sdksample/custom-activities-de.svg';
import cyclingImg from 'components/ClimateWidgets/Mobility/images/sdksample/cycling.svg';
import publicTransitImg from 'components/ClimateWidgets/Mobility/images/sdksample/public-transit.svg';
import walkingImg from 'components/ClimateWidgets/Mobility/images/sdksample/walking.svg';

import treePlantingImage from 'components/ClimateWidgets/TreePlanting/images/sdksample/tree.png';
import treePlantingGoalImage from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/images/sdksample/banner.svg';
import treePlantingNewTreeImage from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/images/sdksample/banner.svg';
import treePlantingHalfwayImage from 'components/ClimateWidgets/TreePlanting/HalfwayTreePrompt/images/sdksample/banner.svg';
import {images as treePlantingImages} from 'components/ClimateWidgets/TreePlanting/TreeProgress/sdksample';

import {ReactComponent as InviteFriendsInfoIcon} from 'components/ClimateWidgets/InviteFriends/icons/sdksample/info.svg';
import {ReactComponent as LocationLinkIcon} from 'icons/sdksample/location-link.svg';

import transferSucessImage from 'pages/Dashboard/WalletsPage/images/base/success.svg';
import co2EmissionsSucessImage from 'pages/Co2Emissions/OffsetPage/icons/base/success.svg';

const config = {
  name: '',
  theme: {
    fonts: languageFonts,
    colorPalette: {
      primary: '#007D8A',
      'primary-500': '#047D8C',
      'primary-300': '#409AA7',
      secondary: '#C0E4E8',
      light: '#FFFFFF',
      white: '#FFFFFF',
      dark: '#30454A',
      black: '#000000',
      gray: '#D8D9DA',
      glacierLight: '#80BCC3FA',
      sunDown: '#FFAFAF',
      ziggurat: '#BFDEE2',
      catSkillWhite: '#EAF5F6',
      disabled: '#dee2e6',
    },
    components: {
      global: {
        borderColor: 'glacierLight',
      },
      dashboard: {
        bgColor: 'secondary',
      },
      competition: {
        bgColor: 'secondary',
      },
      nav: {
        bgColor: 'primary-500',
        menuColor: 'white',
        routes: ['impact', 'offers', 'news', 'teams', 'more'],
      },
      widgetCard: {
        bgColor: 'light',
        titleBgColor: 'dark',
        titleColor: 'white',
        textColor: 'black',
      },
      offers: {
        activeTabBgColor: 'primary',
        navBgColor: 'primary-300',
        bodyBgColor: 'secondary',
        recoinsLeftBgColor: 'light',
        recoinsRequiredBgColor: 'light',
        locationTypeBgColor: 'catSkillWhite',
        headerIconsBgColor: '#C0E4E8',
        LocationLinkIcon,
      },
      badges: {
        bgColor: 'light',
        allLinkColor: 'primary',
      },
      treePlanting: {
        bgColor: 'white',
        treeImage: treePlantingImage,
        goalBanner: treePlantingGoalImage,
        newTreeBanner: treePlantingNewTreeImage,
        halfwayBanner: treePlantingHalfwayImage,
        progressImages: treePlantingImages,
      },
      links: {
        noUnderline: true,
        textColor: 'primary',
      },
      buttons: {
        ...buttons,
      },
      mobilityIcons: {
        progressPathColor: 'primary',
        walking: {
          bgColor: '#5AE6BE',
          img: walkingImg,
        },
        cycling: {
          bgColor: '#5AE6BE',
          img: cyclingImg,
        },
        publicTransit: {
          bgColor: '#5AE6BE',
          img: publicTransitImg,
        },
        customActivities: {
          bgColor: '#5AE6BE',
          en: {
            img: customActivitiesImgEn,
          },
          de: {
            img: customActivitiesImgDe,
          },
        },
      },
      prompts: {
        bgColor: 'light',
        overlayBgColor: '#00000099',
      },
      customActivities: {
        detailsBgColor: 'secondary',
        listBgColor: 'white',
        successPromptColor: 'white',
      },
      settings: {
        bgColor: 'light',
        itemCaretColor: 'primary',
      },
      co2Compensation: {
        pageBgColor: 'secondary',
        emptySunBgColor: 'secondary',
      },
      leaderboard: {
        bgColor: 'white',
        ctaColor: 'primary',
      },
      inviteFriends: {
        modalBgColor: 'light',
        progressBarBgColor: '#CACACA',
        progressBarColor: 'primary',
        shareIconColor: 'primary',
        InfoIcon: InviteFriendsInfoIcon,
      },
      vouchers: {
        bgColor: 'white',
        recoinsTagBgColor: 'catSkillWhite',
        active: {
          activeBgColor: 'secondary',
          pendingBgColor: 'secondary',
          expiredBgColor: 'sunDown',
          progressColor: 'primary',
          progressTrailColor: 'ziggurat',
          headerFontFamily: 'secondary',
        },
        online: {
          codeColor: 'primary',
        },
        wishlistBgColor: '#DBF3F2',
      },
      voucherCard: {
        progressColor: 'primary',
      },
      news: {
        bgColor: 'white',
        titleBgColor: 'secondary',
        titleColor: 'black',
        style: 'comment',
      },
      summaryPage: {
        closeIconColor: 'primary',
      },
      climatePartnerMap: {
        bgColor: 'secondary',
      },
      climatePartner: {
        bgColor: 'secondary',
      },
      transfer: {
        successImage: transferSucessImage,
      },
      co2Emissions: {
        successImage: co2EmissionsSucessImage,
      },
    },
    colors: {
      primary: '#007D8A',
      secondary: '#EBF5F6',
      tertiary: '#028fc6',
      darkBg: '#30454A',
      white: '#ffffff',
      black: '#3c3c3c',
      gray: '#D8D9DA',
      disabled: '#dee2e6',
      navMenu: '#4bafdc',
      promptBg: '#8ad7f9',
      promptOverlayBg: '#00000099',
      onboardingBg: '#FFFFFF',
      teamRankingHighlight: '#FFF3D7',
      teamRankingSavings: '#016817',
    },
  },
  translations: {},
  services: {
    // getNewsFeed,
  },
  features: {
    hideAppClose: true,
    hasCompetition: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasBadges: true,
    hasClimatePartners: false,
    hasCustomActivities: false,
    hasCo2Compensation: false,
  },
  applangaTag: '',
  matomoTrackingId: 3,
  tokenType: 'Thaler',
  fullTokenImg,
  // closeIconType: 'caret',
  FullTokenComponent: Co2Thaler,
};

export default config;

import {METERING_ELEMENTS, METERING_UNITS} from 'types/Metering';
import {formatNumber} from 'utils/numbers';

const METER_KWH_FACTOR = {
  [METERING_ELEMENTS.WATER]: 1000,
  [METERING_ELEMENTS.GAS]: 10,
  [METERING_ELEMENTS.ELECTRICITY]: 1,
};

interface ConversionParams {
  value: number;
  element: METERING_ELEMENTS;
  formatResults?: boolean;
  dp?: number;
}

export const meterToKwh = ({element, value}: ConversionParams) =>
  Math.round(value * METER_KWH_FACTOR[element]);

export const kwhToMeter = ({
  element,
  value,
  dp = 3,
  formatResults = true,
}: ConversionParams) => {
  const results = value / METER_KWH_FACTOR[element];

  return formatResults
    ? formatNumber({
        dp,
        number: results,
        ignoreThousandRule: true,
      })
    : results.toFixed(dp);
};

interface NormalizedConsumptionParams {
  value: number;
  unit?: METERING_UNITS;
  element: METERING_ELEMENTS;
  formatResults?: boolean;
  ignoreThousandRule?: boolean;
  dp?: number;
}

export const normalizeConsumption = ({
  value,
  element,
  unit,
  formatResults,
  ignoreThousandRule,
  dp,
}: NormalizedConsumptionParams) => {
  return unit === METERING_UNITS.METER
    ? kwhToMeter({
        value,
        element,
        formatResults,
        dp,
      }).toString()
    : formatResults
    ? formatNumber({
        number: value,
        dp: 3,
        ignoreThousandRule: true,
      })
    : value;
};

interface SumConsumptionValues {
  integerValue: number | string;
  decimalValue: number | string;
}

export const sumConsumptionValues = ({
  integerValue,
  decimalValue,
}: SumConsumptionValues) => Number(`${integerValue}.${decimalValue}`);

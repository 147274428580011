import React, {useContext} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import routes from 'config/routes/settings';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {formatNumber, numberToKm} from 'utils/numbers';

import CopyText from 'components/Text/CopyText/CopyText';
import Loader from 'components/Loader/Loader';
import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import TreeProgress from './TreeProgress/TreeProgress';
import ProgressBar from './ProgressBar';

import {ReactComponent as InfoIcon} from './images/info.svg';
import tree from './images/tree.svg';
import infoBird from './images/info-bird.svg';

const ContributionCard = styled(WidgetCard)`
  background: ${(props) =>
    props.theme.components.treePlanting.bgColor ||
    `linear-gradient(
        180deg,
        ${props.theme.colors.secondary} 0%,
        #fff 85%
      )
  `};
`;

const InfoIconWrapper = styled(Link)`
  width: 27px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Title = styled(WidgetTitle)<{lg: boolean}>`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-right: ${(props) => (props.lg ? '10px' : 0)};
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }

  ${InfoIconWrapper} {
    position: ${(props) => (props.lg ? 'relative' : 'absolute')};
    top: 0;
    right: ${(props) => (props.lg ? 0 : '15px')};
    z-index: 1;
  }
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const GoalReachedImageWrapper = styled.div`
  text-align: center;
`;

const GoalReachedImage = styled.img`
  max-width: 150px;
`;

const TreesPlanted = styled(CopyText)`
  display: block;
  text-align: center;
  margin-bottom: 20px;
`;

const Text = styled(CopyText)`
  display: block;
  line-height: 20px;
  text-align: center;
  font-weight: 400;
  width: 100%;
`;

const BottomImg = styled.div`
  display: block;
  width: 100%;
`;

const InnerProgressBar = styled.div`
  width: 90px;
`;

const OuterProgressBar = styled.div`
  width: 110px;
  background-color: ${(props) =>
    props.theme.components.treePlanting?.progressBgColor || '#ebf5f6'};
  border-radius: 100%;
`;

const TreeImage = styled.img`
  width: 100%;
`;

const TreePlanting = (props: any) => {
  const {activitySummary} = props;
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);

  const treePlantingTranslations =
    organizationConfig.translations.treePlanting || {};
  const treePlantingConfig =
    organizationConfig.theme.components.treePlanting || {};

  const {treePlanting} = activitySummary || {};
  const costType = treePlanting ? treePlanting.costType : 0;
  const nextTreeIn = treePlanting ? treePlanting.nextTreeIn : 0;

  const getUnitsLeft = () => {
    switch (costType) {
      case 1: {
        return `${activitySummary ? numberToKm({number: nextTreeIn}) : 0} km`;
      }
      case 2: {
        return `${formatNumber({number: nextTreeIn})} ${translate(
          nextTreeIn > 1 || nextTreeIn === 0
            ? organizationConfig.translations.shortTokenPlural
            : organizationConfig.translations.shortTokenSingular,
        )}`;
      }
      default:
        return 0;
    }
  };

  const unitsLeft = getUnitsLeft();
  const titleTranslation = translate(
    treePlantingTranslations.title ||
      'sdk.web.contributions.treePlanting.title',
  );
  const hasNr = titleTranslation.includes('{nr}');
  const title = !!activitySummary
    ? `${translate(
        treePlantingTranslations.title ||
          'sdk.web.contributions.treePlanting.title',
        {
          key: '{nr}',
          value: treePlanting.treesPlanted,
        },
      )}${!hasNr && `: ${treePlanting.treesPlanted}`}`
    : '';

  return (
    <ContributionCard>
      {!!activitySummary ? (
        <>
          <Title lg={title.length > 18}>
            <span>{title}</span>
            <InfoIconWrapper to={routes.ABOUT_TREE_PLANTING.href}>
              <InfoIcon width="100%" />
            </InfoIconWrapper>
          </Title>
          <TreesPlanted>
            <Text>
              {treePlanting.hasGoalBeenReached ? (
                <>
                  {translate(
                    treePlantingTranslations.goalReached ||
                      'sdk.web.contributions.treePlanting.goal.reached.text',
                    [
                      {
                        key: '{nr}',
                        value: treePlanting.totalTreesPlanted.toLocaleString(),
                      },
                    ],
                  )}
                </>
              ) : (
                <>
                  {translate(
                    treePlantingTranslations.treesPlanted ||
                      'sdk.web.contributions.treePlanting.trees.planted.v2',
                    [
                      {
                        key: '{amount}',
                        value: treePlanting.totalTreesPlanted.toLocaleString(),
                      },
                      {
                        key: '{goal}',
                        value: treePlanting.totalTreesGoal.toLocaleString(),
                      },
                    ],
                  )}
                  {treePlanting.hasTimer && (
                    <>
                      <br />
                      {translate(
                        'sdk.web.contributions.treePlanting.timer.left',
                        [
                          {
                            key: '{amount}',
                            value: treePlanting.timerDaysLeft,
                          },
                        ],
                      )}
                    </>
                  )}
                </>
              )}
            </Text>
          </TreesPlanted>
          {treePlanting.hasGoalBeenReached ? (
            <GoalReachedImageWrapper>
              <Link to={routes.ABOUT_TREE_PLANTING.href}>
                <GoalReachedImage src={infoBird} alt={''} />
              </Link>
            </GoalReachedImageWrapper>
          ) : (
            <>
              <Columns>
                <Link to={routes.ABOUT_TREE_PLANTING.href}>
                  <OuterProgressBar>
                    <ProgressBar
                      pathColor={treePlantingConfig.timerPathColor || '#FACD61'}
                      percent={treePlanting.timerProgress}
                      strokeWidth={5.5}>
                      <InnerProgressBar>
                        <ProgressBar
                          pathColor={
                            treePlantingConfig.progressPathColor ||
                            organizationConfig.theme.colors.primary
                          }
                          percent={treePlanting.percent}
                          strokeWidth={6.5}>
                          <TreeImage
                            src={
                              organizationConfig.theme.components.treePlanting
                                ?.treeImage || tree
                            }
                            alt="tree"
                          />
                        </ProgressBar>
                      </InnerProgressBar>
                    </ProgressBar>
                  </OuterProgressBar>
                </Link>
              </Columns>
              <Text>
                {translate(
                  treePlantingTranslations.nextTreeIn ||
                    'sdk.web.contributions.treePlanting.your.next.tree.in.v2',
                  {
                    key: '{amount}',
                    value: unitsLeft,
                  },
                )}
              </Text>
            </>
          )}
          <BottomImg>
            <TreeProgress treesPlanted={treePlanting.treesPlanted} />
          </BottomImg>
        </>
      ) : (
        <Loader sm={true} />
      )}
    </ContributionCard>
  );
};

export default TreePlanting;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import mapboxgl, {MapMouseEvent} from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import styled from 'styled-components';
import {AnimatePresence} from 'framer-motion';

import routes from 'config/routes/rewards';
import {NATIVE_MESSAGES} from 'constants/native-events';
import {LocationContext} from 'contexts/LocationContext';
import {CACHE_KEYS} from 'constants/cache-keys';
import {SettingsContext} from 'contexts/SettingsContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {sendMessageToNativeApp} from 'services/native-api';
import {getVouchers, searchMap, getFeatures} from 'services/vouchers';
import {PurchasedVoucher, Voucher} from 'types/Offers';
import {getOptimizedDistance} from 'utils/numbers';
import {calculateDistance} from 'utils/location';
import {noop} from 'utils/functions';

import Loader from 'components/Loader/Loader';
import VoucherCard from 'components/Rewards/Vouchers/VoucherCard/VoucherCard';
import MapInfo from 'components/MapInfo/MapInfo';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {useLocation} from 'react-router-dom';

mapboxgl.accessToken =
  'pk.eyJ1Ijoia2xpbWEtdGFsZXIiLCJhIjoiY2xrYXZ2YWdkMGM1NzNtcWQ5MHFqbHVlMiJ9.rzkcS6tScdgN9PkYZBtsXg';

enum EntityType {
  CLIMATE_PARTNER = 'climate-partner',
}

type MapFeature = {
  type: string;
  state: {};
  geometry: {
    type: string;
    coordinates: Array<number>;
  };
  properties: {
    city?: string;
    id?: string;
    address?: string;
    title?: string;
    description?: string;
    url?: string;
    postal_code?: string;
    image?: string;
    layer?: string;
    icon_active?: string;
    icon_inactive?: string;
    entity_type?: EntityType;
    entity_id?: string;
  };
  id: number;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .wf {
    &__container {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow-y: hidden;
    }

    &__fountain {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;
      border-radius: 10px 10px 0 0;
    }

    &__fountain {
    }
  }
`;

const VouchersList = styled.div`
  max-height: 330px;
  overflow-y: auto;
`;

const VoucherCardContent = styled.div`
  margin: 0 auto;
  position: relative;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DEFAULT_LOCATION = {
  longitude: 9.4069,
  latitude: 49.7897,
};

const Map = () => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map>();
  const {search} = useLocation();
  const {location, updateCurrentLocation} = useContext(LocationContext);
  const {organizationConfig} = useContext(SettingsContext);
  const {translate} = useTranslations();

  const [latLng, setLatLng] = useState({
    lng: location?.longitude || DEFAULT_LOCATION.longitude,
    lat: location?.latitude || DEFAULT_LOCATION.latitude,
  });
  const [zoom, setZoom] = useState(12.5);
  const [feature, setFeature] = useState<MapFeature>();
  const [vouchers, setVouchers] = useState<PurchasedVoucher[]>([]);

  const {
    isLoading: isLoadingVouchers,
    fetchDataWithKey,
    data: vouchersData,
  } = useFetcher<Voucher[]>({
    fetcher: getVouchers,
    initialValue: [],
    preventFetch: true,
  });

  useEffect(() => {
    updateCurrentLocation();
  }, [updateCurrentLocation]);

  useEffect(() => {
    if (!location) {
      return;
    }

    const currentLatLng = {
      lng: location.longitude,
      lat: location.latitude,
    };
    setLatLng(currentLatLng);

    if (mapRef.current) {
      mapRef.current?.setCenter(currentLatLng);
    }
  }, [location]);

  useEffect(() => {
    if (!mapContainerRef.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/klima-taler/clv28jrci00aq01qpchync4vz',
      center: [latLng.lng, latLng.lat],
      zoom,
    });

    mapRef.current = map;

    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false,
        enableGeolocation: false,
        externalGeocoder: async (query) => {
          console.log('searching features', query);

          var features = await searchMap(query);

          console.log('search results', features);

          return features;
        },
      }),
    );

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    let geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });

    map.addControl(geolocate);

    map.on('load', async function () {
      console.log("Map loaded, adding new layers..");

      map.addSource('poi-pending', {
        "type": "geojson",
        "data": {
          type: 'FeatureCollection',
          features: []
        }
      });

      map.addSource('partners-pending', {
        "type": "geojson",
        "data": {
          type: 'FeatureCollection',
          features: []
        }
      });

      map.addLayer({
        "id": "poi-pending",
        "type": "symbol",
        "paint": {},
        "layout": {
            "icon-image": [
                "to-string",
                [
                    "get",
                    "icon_inactive"
                ]
            ]
        },
        "source": "poi-pending"
      });
  
      map.addLayer({
        "id": "partners-pending",
        "type": "symbol",
        "paint": {},
        "layout": {
            "icon-image": [
                "to-string",
                [
                    "get",
                    "icon_inactive"
                ]
            ]
        },
        "source": "partners-pending"
      });

      console.log("New layers added, triggering geolocate..");

      geolocate.trigger();

      const searchParams = new URLSearchParams(search);
      
      if (searchParams.get('source') === 'offers') {
        console.log("Disabling POI layers..");

        map.setLayoutProperty('poi', 'visibility', 'none');
        map.setLayoutProperty('poi-pending', 'visibility', 'none');
      }

      console.log("Fetching features from backend...");

      let features = await getFeatures();

      console.log("Got features from backend", features);

      // @ts-ignore
      map.getSource('poi-pending').setData(features.poi);
      // @ts-ignore
      map.getSource('partners-pending').setData(features.partners);

      console.log("Updated map sources, all done!"); 
    });

    map.on('move', () => {
      // setLatLng({lat, lng});
      setZoom(map.getZoom());
    });

    map.on('click', (event: MapMouseEvent) => {
      const features = map.queryRenderedFeatures(event.point, {
        layers: ['poi', 'partners', "partners-pending", "poi-pending"],
      }); 

      console.log('feature selected', features);

      if (!features.length) {
        return;
      }

      const feature = features[0] as unknown as MapFeature;

      if (!feature) {
        return;
      }

      setFeature(feature);
      setLatLng(event.lngLat);
    });

    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVouchers([]);
    if (feature?.properties?.entity_type !== EntityType.CLIMATE_PARTNER) {
      return;
    }

    const id = feature.properties.entity_id;
    const key = `${CACHE_KEYS.CLIMATE_PARTNER_OFFERS}/${id}`;

    fetchDataWithKey({
      key,
      newParams: {climate_partner_id: id},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature]);

  useEffect(() => {
    setVouchers(
      vouchersData.map((offer) => ({
        id: offer.id,
        offer,
      })),
    );
  }, [vouchersData]);

  const treasure = useMemo(() => {
    if (!feature) {
      return;
    }

    const {title, description, address, city, postal_code, image} =
      feature.properties;
    let optimizedDistance;
    if (location) {
      const distance = calculateDistance(location, {
        longitude: latLng.lng,
        latitude: latLng.lat,
      });
      optimizedDistance = getOptimizedDistance({
        distance,
        optimizeDistance: true,
      });
    }

    return {
      title,
      description,
      image,
      address: [address, city, postal_code].filter((a) => a).join(', '),
      location: optimizedDistance,
    };
  }, [feature, location, latLng]);

  const handleDirectionRequest = useCallback(() => {
    if (!latLng || !treasure) {
      return;
    }

    const message = {
      type: NATIVE_MESSAGES.REQUEST_GIVE_DIRECTION,
      coordinates: {
        longitude: latLng.lng,
        latitude: latLng.lat,
      },
      formatted_address: treasure.address,
    };
    sendMessageToNativeApp({message});
  }, [treasure, latLng]);

  return (
    <Wrapper className="wf">
      <div className="wf__container" ref={mapContainerRef} />
      <AnimatePresence>
        {treasure && (
          <MapInfo
            name={<b>{treasure.title}</b>}
            description={treasure.description}
            address={treasure.address}
            location={treasure.location}
            image={treasure.image}
            onDirectionRequest={handleDirectionRequest}
            styles={vouchers.length === 0 ? {bgColor: '#FFFFFF'} : {}}
            onClose={() => setFeature(undefined)}>
            {feature?.properties?.entity_type ===
              EntityType.CLIMATE_PARTNER && (
              <AnimatePresence>
                <VouchersList>
                  {isLoadingVouchers ? (
                    <LoaderWrapper>
                      <Loader color="#222" sm />
                    </LoaderWrapper>
                  ) : vouchers.length > 0 ? (
                    vouchers.map((voucher) => (
                      <VoucherCardContent key={voucher.id}>
                        <VoucherCard
                          bgColor={
                            organizationConfig.theme.components
                              .climatePartnerMap?.voucherBgColor
                          }
                          voucher={voucher}
                          voucherUrl={`${routes.REWARDS.DEALS.href}/vouchers`}
                          isDeleteActive={false}
                          showClimateIcon={true}
                          hideLocation={true}
                          toggleDeleteActions={noop}
                          removeVoucher={{title: '', desc: '', delete: noop}}
                          reloadVouchers={noop}
                        />
                      </VoucherCardContent>
                    ))
                  ) : (
                    <div className="text-center">
                      <b>{translate('sdk.web.treasure.map.no.offers')}</b>
                    </div>
                  )}
                </VouchersList>
              </AnimatePresence>
            )}
          </MapInfo>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default Map;

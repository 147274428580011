import momentLocaleDe from './moment/de';
import momentLocaleFi from './moment/fi';
import momentLocalePt from './moment/pt';
import momentLocaleSv from './moment/sv';

const availableLanguages = ['en', 'de', 'fi', 'ja', 'pt', 'sv'];

export const getDeviceLanguage = (): string => {
  const splitDeviceLanguage = (navigator.language || '').split('-');
  const deviceLanguage = splitDeviceLanguage[0];
  return availableLanguages.includes(deviceLanguage) ? deviceLanguage : 'en';
};

let hasLoadedMomentLocales = false;

export const loadMomentLocales = (moment: any) => {
  if (hasLoadedMomentLocales) {
    return;
  }

  moment.updateLocale('fi', momentLocaleFi);
  moment.updateLocale('de', momentLocaleDe);
  moment.updateLocale('pt', momentLocalePt);
  moment.updateLocale('sv', momentLocaleSv);

  hasLoadedMomentLocales = true;

  const locale = getDeviceLanguage();
  moment.locale(locale);
};

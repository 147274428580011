import {useCallback} from 'react';
import {Translate} from 'hooks/use-translations';
import {ElementTranslate} from 'components/ClimateWidgets/Metering/hooks/use-element-translation';

type GetErrorParams = {label?: string; skipLabelTranslation?: boolean};

type useErrorMessageParams = {
  translate: Translate | ElementTranslate;
};
const useErrorMessage = ({translate}: useErrorMessageParams) => {
  const getError = useCallback(
    ({field, label, skipLabelTranslation}: {field: string} & GetErrorParams) =>
      translate(field, {
        key: '{field}',
        value: label
          ? skipLabelTranslation
            ? label
            : translate(label)
          : translate('sdk.web.error.field'),
      }),
    [translate],
  );

  const getMinError = useCallback(
    (params: GetErrorParams) =>
      getError({
        field: 'sdk.web.error.field.min',
        ...params,
      }),
    [getError],
  );

  const getMaxError = useCallback(
    (params: GetErrorParams) =>
      getError({
        field: 'sdk.web.error.field.max',
        ...params,
      }),
    [getError],
  );

  const getRequiredError = useCallback(
    (params: GetErrorParams) =>
      getError({
        field: 'sdk.web.error.field.required',
        ...params,
      }),
    [getError],
  );

  return {getMinError, getMaxError, getRequiredError};
};

export default useErrorMessage;

const locale = {
  relativeTime: {
    future: 'in %s',
    past: 'vor %s',
    s: 'ein paar Sekunden',
    ss: '%d Sekunden',
    m: processDeRelativeTime,
    mm: '%d Minuten',
    h: processDeRelativeTime,
    hh: '%d Stunden',
    d: processDeRelativeTime,
    dd: processDeRelativeTime,
    w: processDeRelativeTime,
    ww: '%d Wochen',
    M: processDeRelativeTime,
    MM: processDeRelativeTime,
    y: processDeRelativeTime,
    yy: processDeRelativeTime,
  },
};

function processDeRelativeTime(
  number: number,
  withoutSuffix: boolean,
  key: string,
) {
  const format: Record<string, Array<string>> = {
    m: ['eine Minute', 'einer Minute'],
    h: ['eine Stunde', 'einer Stunde'],
    d: ['ein Tag', 'einem Tag'],
    dd: [number + ' Tage', number + ' Tagen'],
    w: ['eine Woche', 'einer Woche'],
    M: ['ein Monat', 'einem Monat'],
    MM: [number + ' Monate', number + ' Monaten'],
    y: ['ein Jahr', 'einem Jahr'],
    yy: [number + ' Jahre', number + ' Jahren'],
  };
  return withoutSuffix ? format[key][0] : format[key][1];
}

export default locale;

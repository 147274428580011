import React, {useCallback, useRef} from 'react';
import {Route, Switch} from 'react-router-dom';

import routes from 'config/routes/co2Emissions';
import useActivitySummary from 'hooks/useActivitySummary';
import {useScrollToTop} from 'hooks/ui/use-scroll-to-top';
import {useUserProfile} from 'hooks/use-user-profile';

import OffsetPage from './OffsetPage';
import StatsPage from './StatsPage';
import AboutPage from './AboutPage';

const Co2Emissions = () => {
  const wrapperRef = useRef(null);
  useScrollToTop({wrapperRef});
  const {activitySummary, fetchActivitySummary} = useActivitySummary();
  const {reloadUserProfile} = useUserProfile();

  const handleOffsetSuccess = useCallback(async () => {
    await fetchActivitySummary();
    reloadUserProfile();
  }, [fetchActivitySummary, reloadUserProfile]);

  return (
    <div ref={wrapperRef}>
      <Switch>
        <Route path={routes.OFFSET.href}>
          <OffsetPage
            activitySummary={activitySummary}
            onSuccess={handleOffsetSuccess}
          />
        </Route>
        <Route path={routes.ABOUT.href}>
          <AboutPage />
        </Route>
        <Route>
          <StatsPage activitySummary={activitySummary} />
        </Route>
      </Switch>
    </div>
  );
};

export default Co2Emissions;

import React from 'react';

import T0 from './images/coins/0-DDorf-Coin.svg';
import T1 from './images/coins/1-DDorf-Coin.svg';
import T2 from './images/coins/2-DDorf-Coin.svg';
import T3 from './images/coins/3-DDorf-Coin.svg';
import T4 from './images/coins/4-DDorf-Coin.svg';
import T5 from './images/coins/5-DDorf-Coin.svg';
import T6 from './images/coins/6-DDorf-Coin.svg';
import T7 from './images/coins/7-DDorf-Coin.svg';
import T8 from './images/coins/8-DDorf-Coin.svg';
import T9 from './images/coins/9-DDorf-Coin.svg';
import T10 from './images/coins/10-DDorf-Coin.svg';

const coins = [T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10];

const DDorfCoin = (props: any) => {
  const {percent} = props;
  const tokenIndex = Math.floor((percent / 100) * (coins.length - 1));
  const tokenImg = coins[tokenIndex];

  return (
    <React.Fragment>
      <img src={tokenImg} alt="" />
    </React.Fragment>
  );
};

export default DDorfCoin;

import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import {useFetcher} from 'hooks/use-fetcher';
import {getClimatePartnerVouchers} from 'services/vouchers';

import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import Offers from 'components/Rewards/ClimatePartner/Offers';

const LoaderWrapper = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
`;

const ClimatePartnerPage = () => {
  const {id}: {id: string} = useParams();
  const {isLoading, data: vouchers} = useFetcher({
    fetcher: getClimatePartnerVouchers,
    params: {
      id,
    },
    key: `climateVouchers-${id}`,
  });

  if (!vouchers) {
    return (
      <Modal>
        <LoaderWrapper>{!isLoading && <Loader color={'#000'} />}</LoaderWrapper>
      </Modal>
    );
  }

  return <Offers vouchers={vouchers} />;
};

export default ClimatePartnerPage;

import React, {useEffect, useState} from 'react';
import {default as DefaultAutoComplete} from 'react-autocomplete';
import styled from 'styled-components';
import {FormikContextType} from 'formik';

import {ApiErrors} from 'hooks/forms/use-form-validation';

import {InputStyles} from 'components/Form/InputStyles';
import FormError from 'components/Form/FormError';
import TextXs from 'components/Text/TextXs/TextXs';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled(InputStyles)<{defaultLabel?: boolean}>`
  display: block;
  opacity: ${(props) => (props.defaultLabel ? 0.6 : 1)};
`;

const List = styled.ul`
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 10;
  max-height: 250px;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  background-color: white;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Item = styled(TextXs)`
  padding: 8px 15px;
  border-bottom: 1px solid #f9f9f9;
  display: block;
  width: 100%;
  text-transform: capitalize;

  &:last-of-type {
    border-bottom: none;
  }
`;

type DropdownItem = {
  id: number | string;
  value: any;
  label: string;
};

interface AutoCompleteProps {
  name?: string;
  defaultValue?: string | number;
  defaultLabel?: string;
  value?: string;
  optional?: boolean;
  list: Array<DropdownItem>;
  onChange: (e: any) => void;
  onSearch?: ({query}: {query: string}) => void;
  formik: FormikContextType<any>;
  apiErrors?: ApiErrors;
}

const AutoComplete = (props: AutoCompleteProps) => {
  const {
    name,
    value,
    defaultLabel,
    defaultValue,
    list,
    formik,
    apiErrors,
    onChange,
    onSearch,
  } = props;
  const [touched, setTouched] = useState(false);
  const [text, setText] = useState(value || defaultLabel || '');

  useEffect(() => {
    if (!defaultValue || touched) {
      return;
    }

    const foundLabel = list.find(
      ({value}) => value.toString() === defaultValue.toString(),
    );

    if (foundLabel) {
      setText(foundLabel.label);
    }
  }, [touched, list, defaultValue]);

  return (
    <>
      <Wrapper>
        <DefaultAutoComplete
          value={text}
          getItemValue={(item) => item.label.toString()}
          items={list}
          onChange={(e) => {
            setText(e.target.value);
            onSearch && onSearch({query: e.target.value});
            setTouched(true)
          }}
          onSelect={(selectedValue, item) => {
            setText(selectedValue);
            onChange({target: {name, value: item.value}});
          }}
          renderInput={(props) => {
            // @ts-ignore
            return <Input {...props} />;
          }}
          renderMenu={(items) => <List children={items} />}
          renderItem={(item) => (
            <Item as="li" key={item.id}>
              {item.label}
            </Item>
          )}
          wrapperStyle={{display: 'block', width: '100%'}}
        />
      </Wrapper>
      <FormError name={name} formik={formik} apiErrors={apiErrors} />
    </>
  );
};

export default AutoComplete;

import {useCallback} from 'react';

import {NATIVE_MESSAGES} from 'constants/native-events';
import {sendMessageToNativeApp} from 'services/native-api';
import {ClimatePartner} from 'types/Offers';

interface UseLocationProps {
  climatePartner?: ClimatePartner;
}

const useClimatePartnerLocation = ({climatePartner}: UseLocationProps) => {
  const address = climatePartner?.address || '';

  const handleDirectionRequest = useCallback(() => {
    if (!climatePartner) {
      return;
    }

    const message = {
      type: NATIVE_MESSAGES.REQUEST_GIVE_DIRECTION,
      coordinates: {
        longitude: climatePartner.longitude,
        latitude: climatePartner.latitude,
      },
      formatted_address: climatePartner.address,
    };
    sendMessageToNativeApp({message});
  }, [climatePartner]);

  return {address, handleDirectionRequest};
};

export default useClimatePartnerLocation;

import {useContext, useEffect, useRef} from 'react';

import EventsContext, {Events} from 'contexts/EventsContext';

interface UseEventsListenerProps {
  event: keyof Events;
  callback: () => void;
}

const useEventsListener = (props: UseEventsListenerProps) => {
  const {event, callback} = props;
  const {events} = useContext(EventsContext);
  const initialEventData = useRef(events[event]);
  const eventData = events[event];

  useEffect(
    () => {
      if (eventData === 0 || eventData === initialEventData.current) {
        return;
      }

      callback();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventData],
  );
};

export default useEventsListener;

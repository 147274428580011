import styled from 'styled-components';

import {addBoldText} from 'utils/theme';
import LinkText from '../Text/LinkText/LinkText';

const CtaLink = styled(LinkText).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    // @ts-ignore
    !['noMargin', 'color'].includes(prop) || defaultValidatorFn(prop),
})<{color?: string; noMargin?: boolean}>`
  display: block;
  margin-bottom: ${(props) => (props.noMargin ? '0' : '20px')};
  cursor: pointer;

  &,
  &:hover,
  &:focus {
    text-decoration: ${(props) =>
      props.theme.components.links?.noUnderline ? 'none' : 'underline'};
    color: ${(props) =>
      props.color
        ? props.theme.colors[props.color]
        : props.theme.components.links?.textColor || props.theme.colors.black};
  }

  ${(props) =>
    addBoldText({
      props,
    })}
`;

export default CtaLink;

import React from 'react';
import routes from 'config/routes';
import {PROMPTS} from 'constants/prompts';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';
import {useOrganization} from 'hooks/use-organization';
import PromptModal from 'components/PromptModal/PromptModal';
import img from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/images/banner.svg';

const TreeGoalReachedPrompt = (props: any) => {
  const {prompts} = props;
  const {translate} = useTranslations();
  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.TREE_PLANTING_GOAL_REACHED,
  });

  const {organizationConfig} = useOrganization();
  const treePlantingConfig = organizationConfig.theme.components.treePlanting;
  const treePlantingTranslations =
    organizationConfig.translations.treePlanting || {};

  if (!prompt) {
    return <React.Fragment />;
  }

  const treePlanting = prompt.data.tree_planting;

  const TextContent = (
    <div>
      {translate(
        treePlantingTranslations.goalReached ||
          'sdk.web.contributions.treePlanting.goal.reached.text',
        [
          {
            key: '{nr}',
            value: treePlanting.amount.toLocaleString(),
          },
        ],
      )}
    </div>
  );

  const moreInfo = {
    text: translate('sdk.web.contributions.treePlanting.more.info'),
    link: routes.SETTINGS.ABOUT_TREE_PLANTING.href,
  };

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.contributions.treePlanting.goal.reached.title')}
      textContent={TextContent}
      image={treePlantingConfig?.goalBanner || img}
      btnText={translate('sdk.web.contributions.treePlanting.thanks')}
      onBtnClick={handleModalClose}
      moreInfo={moreInfo}
    />
  );
};

export default TreeGoalReachedPrompt;

import {useEffect} from 'react';
import {compare} from 'compare-versions';

import packageJson from '../../../package.json';
import useFeaturesSetup from 'hooks/app/use-features-setup';

// @ts-ignore
global.appVersion = packageJson.version;

const skipCacheBuster = true;

const useCacheBuster = () => {
  const {reloadFeatures} = useFeaturesSetup();

  useEffect(() => {
    if (skipCacheBuster) {
      return;
    }

    const checkAndUpdateApp = () => {
      fetch(`/meta.json?v=${Date.now()}`)
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          // @ts-ignore
          const currentVersion = global.appVersion;
          const isNewVersion = compare(latestVersion, currentVersion, '>');

          if (!isNewVersion) {
            return;
          }

          setTimeout(() => {
            if (caches) {
              // Service worker cache should be cleared with caches.delete()
              caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
              });
            }

            reloadFeatures();
            // delete browser cache and hard reload
            window.location.reload();
          }, 500);
        })
        .catch(console.error);
    };

    function onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        checkAndUpdateApp();
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange);
    checkAndUpdateApp();

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [reloadFeatures]);
};

export default useCacheBuster;

import {fetchAPiClient} from 'services/api';
import {dataUrlToFile} from 'utils/images';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

type ImageParams = {
  category: string;
  type: string;
};

interface UploadImageFileParams extends ImageParams {
  image: File;
}

export const uploadImageFile = async (
  data: UploadImageFileParams,
): Promise<string> => {
  const url = '/image';

  const formData = new FormData();
  formData.append('image', data.image);
  formData.append('category', data.category);
  formData.append('type', data.type);

  const response = await defaultApiClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data?.url as string;
};

interface UploadImageDataParams extends ImageParams {
  image: string;
}

export const uploadImageData = async (data: UploadImageDataParams) => {
  const fileData = {
    category: data.category,
    type: data.type,
    image: await dataUrlToFile({dataUrl: data.image, fileName: data.category}),
  };

  return uploadImageFile(fileData);
};

import React, {useContext, useMemo} from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {SettingsContext} from 'contexts/SettingsContext';

import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import Ranking, {Rank} from './Ranking';
import {TRANSACTION_TYPES} from 'constants/transaction-type';
import {formatNumber} from 'utils/numbers';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Description = styled.div`
  margin-bottom: 40px;
`;

interface TeamModalProps {
  rank?: Rank;
  onClose: () => void;
}

const TeamModal = (props: TeamModalProps) => {
  const {rank, onClose} = props;
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);

  const rankings = useMemo(() => {
    if (!rank) {
      return [];
    }

    const mobilityIcons =
      organizationConfig.theme.components.leaderboard.mobilityIcons;

    const activityRankings = [
      {
        ...TRANSACTION_TYPES.WALKING,
        Icon: mobilityIcons?.Walking,
      },
      {
        ...TRANSACTION_TYPES.CYCLING,
        Icon: mobilityIcons?.Cycling,
      },
      {
        ...TRANSACTION_TYPES.PUBLIC_TRANSIT,
        Icon: mobilityIcons?.PublicTransit,
      },
    ]
      .map((activity) => {
        const sourceRank = rank.sources?.find(
          (source) => activity.transactionTypes[0] === source.source_id,
        );

        if (!sourceRank) {
          return undefined;
        }

        return {
          key: activity.key,
          name: translate(activity.string),
          position: sourceRank.position,
          Icon: activity.Icon,
        } as Rank;
      })
      .filter((rank): rank is Rank => !!rank);

    if (activityRankings && activityRankings.length > 3) {
      activityRankings.push({
        key: 'all',
        name: translate('sdk.web.mobility.w.c.pt'),
        position: rank.position,
        Icon: mobilityIcons?.All,
      });
    }

    return activityRankings;
  }, [organizationConfig, rank, translate]);

  return (
    <PromptWrapper
      title={translate('sdk.web.teams.user.ranking.title')}
      onClose={onClose}
      isVisible={!!rank}>
      {rank && (
        <Content>
          <Description>
            {rank.name}
            {rank.showParticipants && (
              <>
                <br />
                {translate('sdk.web.teams.user.ranking.participants', {
                  key: '{nr}',
                  value: formatNumber({number: rank.participants || 0, dp: 0}),
                })}
              </>
            )}
          </Description>
          <Ranking rankings={rankings} />
        </Content>
      )}
    </PromptWrapper>
  );
};

export default TeamModal;

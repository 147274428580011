import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import {SDKProvider} from './store/providers';
import reportWebVitals from './reportWebVitals';

import App from './App';

import 'bootstrap/dist/css/bootstrap-reboot.css';
import './index.css';

Sentry.init({
  dsn: 'https://8f68ea7f77464c26a624686ca4e237fc@o292127.ingest.sentry.io/5438237',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
  environment: process.env.REACT_APP_ENV || 'development',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const noJSLoader = document.getElementById('no-js-app-loader');
if (noJSLoader) {
  noJSLoader.style.display = 'none';
}

root.render(
  <React.Fragment>
    {/* @ts-ignore */}
    <SDKProvider>
      <App />
    </SDKProvider>
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

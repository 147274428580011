import {useCallback, useContext, useEffect} from 'react';
import {SettingsContext} from 'contexts/SettingsContext';
import {TranslationsContext} from 'contexts/TranslationsContext';
import {getDeviceLanguage} from 'utils/language';
import {globalReplace} from 'utils/strings';

type KeyValue = {
  key: string;
  value: string | number| undefined;
};

type TranslateOptions = Array<KeyValue> | KeyValue;

export type Translate = (val: string, options?: TranslateOptions) => string;

export const useTranslations = () => {
  const {organizationConfig} = useContext(SettingsContext);
  const {translations, loadTranslations} = useContext(TranslationsContext);
  const locale = getDeviceLanguage();

  useEffect(
    () => {
      if (!organizationConfig || organizationConfig.name === 'preset') {
        return;
      }
      loadTranslations &&
        loadTranslations({locale, tagId: organizationConfig.applangaTag});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationConfig],
  );

  const translate: Translate = useCallback(
    (key, options): string => {
      const localeTranslations = translations[locale].translations;
      let translatedText = localeTranslations[key];
      if (!translatedText && locale !== 'en') {
        translatedText = translations['en'].translations[key];
      }

      if (translatedText) {
        if (!options) {
          return translatedText;
        }

        const updatedOptions = options instanceof Array ? options : [options];
        return updatedOptions.reduce((translation: string, option: any) => {
          return option.key
            ? globalReplace({
                text: translation,
                search: option.key,
                replace: option.value,
              })
            : translation;
        }, translatedText);
      }

      return '';
    },
    [translations, locale],
  );

  return {
    translate,
    locale,
  };
};

import React, {useMemo} from 'react';
import styled from 'styled-components';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {useTranslations} from 'hooks/use-translations';
import {getPreviousResults} from 'services/water';
import {getMonthKeyFromDate} from 'utils/date';
import {formatNumber} from 'utils/numbers';
import {PreviousMonthResults, PreviousResultsData} from 'types/Water';

import Loader from 'components/Loader/Loader';
import PromptWrapper, {
  Title,
} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

import {ReactComponent as HomeIcon} from 'icons/home.svg';

const Header = styled.div`
  padding: 0 15px;
  margin-bottom: 16px;
`;

const Summary = styled(CopyTextSm)`
  display: block;
  padding: 0 5px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0 12px;
  margin: 0;
  height: 60vh;
  overflow-y: scroll;
`;

const Item = styled.li`
  display: block;
  padding: 16px;
  border-radius: 15px;
  background-color: #ffffffb3;
  margin-bottom: 8px;
`;

const ItemHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => props.theme.colors.primary};
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const Month = styled(CopyTextSm)`
  font-weight: 600;
`;

const LiterSaved = styled(CopyTextSm)<{extra: boolean}>`
  color: ${(props) => (props.extra ? '#ff0000' : props.theme.colors.primary)};
  font-weight: 600;
`;

const IconContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Icon = styled.div`
  width: 28px;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  img {
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
  }
`;

const IconText = styled(CopyTextSm)`
  display: block;
  padding-left: 15px;
  text-align: left;
`;

const CenterWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type PreviousResultsProps = {
  isOpen: boolean;
  onClose: () => void;
};

type PreviousMonths = Partial<PreviousMonthResults> & {
  month: string;
  summary: string;
  nextLevel: string;
  comparison: string;
  year: number;
  amountSaved: string;
  extra: boolean;
};

const PreviousResults = (props: PreviousResultsProps) => {
  const {isOpen, onClose} = props;
  const {organizationConfig} = useOrganization();
  const {translate} = useTranslations();
  const {getShortTokenText} = useTokenTranslation();

  const {data, isLoading}: {data: PreviousResultsData; isLoading: boolean} =
    useFetcher({
      fetcher: getPreviousResults,
      initialValue: {
        months: [],
      },
      key: CACHE_KEYS.WATER_PREVIOUS_RESULTS,
    });

  const {title, months, yearSummary} = useMemo(() => {
    const title = translate('sdk.web.water.previous.title');
    const yearSummary =
      data.year && data.months.length
        ? translate('sdk.web.water.previous.summary', [
            {
              key: '{nr}',
              value: formatNumber({
                number:
                  data.year.total_savings < 0 ? 0 : data.year.total_savings,
                dp: 0,
              }),
            },
            {
              key: '{year}',
              value: new Date().getFullYear(),
            },
            {
              key: '{nr}',
              value: formatNumber({
                number: data.year.recoins_earned,
              }),
            },
            {
              key: '{token}',
              value: getShortTokenText(data.year.recoins_earned),
            },
          ])
        : '';

    const months = data.months.map((result) => {
      const date = new Date(result.month);
      const monthKey = getMonthKeyFromDate({date});
      const month = translate(`sdk.web.month.${monthKey}`);

      const savingsKey =
        result.savings < 0
          ? 'sdk.web.water.previous.month.extra'
          : 'sdk.web.water.previous.month.saved';
      const amountSaved = translate(savingsKey, {
        key: '{nr}',
        value: formatNumber({
          number: Math.abs(result.savings || 0),
          dp: 0,
        }),
      });

      const summary = translate('sdk.web.water.previous.month.summary', [
        {
          key: '{nrCoins}',
          value: formatNumber({
            number: result.recoins_earned,
          }),
        },
        {
          key: '{token}',
          value: getShortTokenText(result.recoins_earned),
        },
      ]);

      const nextLevel = result.until_next_recoin
        ? translate('sdk.web.water.previous.month.next.level', [
            {
              key: '{nr}',
              value: formatNumber({
                number: result.until_next_recoin,
                dp: 0,
              }),
            },
          ])
        : '';

      const performance = formatNumber({
        number: Math.abs(result.relative_performance),
        dp: 0,
      });
      const comparison =
        result.relative_performance === 0
          ? translate('sdk.web.water.previous.performance.same')
          : result.relative_performance < 0
          ? translate('sdk.web.water.previous.performance.worse', {
              key: '{nr}',
              value: performance,
            })
          : translate('sdk.web.water.previous.performance.better', {
              key: '{nr}',
              value: performance,
            });

      return {
        ...result,
        month,
        amountSaved,
        summary,
        nextLevel,
        comparison,
        extra: result.savings < 0,
        year: date.getFullYear(),
      };
    });

    return {
      title,
      months,
      yearSummary,
    };
  }, [data, translate, getShortTokenText]);

  return (
    <PromptWrapper isVisible={isOpen} title={''} onClose={onClose}>
      <Header>
        <Title>{title}</Title>
        {yearSummary && <Summary>{yearSummary}</Summary>}
      </Header>
      <List>
        {!isLoading ? (
          months.map((month: PreviousMonths, index: number) => (
            <Item key={index}>
              <ItemHeader>
                <Month>
                  {month.month} {month.year}
                </Month>
                <LiterSaved extra={month.extra}>{month.amountSaved}</LiterSaved>
              </ItemHeader>
              <IconContent>
                <Icon>
                  <organizationConfig.FullTokenComponent percent={100} />
                </Icon>
                <IconText>
                  {month.summary} {month.nextLevel}
                </IconText>
              </IconContent>
              <IconContent>
                <Icon>
                  <HomeIcon />
                </Icon>
                <IconText>{month.comparison}</IconText>
              </IconContent>
            </Item>
          ))
        ) : (
          <CenterWrapper>
            <Loader sm={true} color={'#000'} />
          </CenterWrapper>
        )}
      </List>
    </PromptWrapper>
  );
};

export default PreviousResults;

import React, {useContext, useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import {useFetcher} from 'hooks/use-fetcher';
import {closeApp} from 'services/native-api';
import {getAllTeams} from 'services/teams';

import OnboardingOne from './OnboardingOne/OnboardingOne';
import OnboardingTwo from './OnboardingTwo/OnboardingTwo';
import OnboardingThree from './OnboardingThree/OnboardingThree';
import OnboardingPrivacy from 'pages/Onboarding/OnboardingPrivacy/OnboardingPrivacy';
import OnboardingGps from './OnboardingGps/OnboardingGps';
import OnboardingMotion from './OnboardingMotion/OnboardingMotion';
import OnboardingTeam from './OnboardingTeam/OnboardingTeam';
import OnboardingNotification from './OnboardingNotification/OnboardingNotification';

const Onboarding = () => {
  const history = useHistory();

  const {isOnboardingComplete} = useContext(SettingsContext);
  const {data: teams} = useFetcher({
    fetcher: getAllTeams,
    params: {},
    initialValue: [],
    key: 'allTeams',
  });

  useEffect(
    () => {
      if (isOnboardingComplete) {
        history.push(routes.DASHBOARD.href);
        closeApp();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOnboardingComplete],
  );

  return (
    <Switch>
      <Route path={routes.ONBOARDING.TWO.href}>
        <OnboardingTwo />
      </Route>
      <Route path={routes.ONBOARDING.THREE.href}>
        <OnboardingThree />
      </Route>
      <Route path={routes.ONBOARDING.FOUR.href}>
        <OnboardingPrivacy />
      </Route>
      <Route path={routes.ONBOARDING.GPS.href}>
        <OnboardingGps teams={teams} />
      </Route>
      <Route path={routes.ONBOARDING.MOTION.href}>
        <OnboardingMotion teams={teams} />
      </Route>
      <Route path={routes.ONBOARDING.NOTIFICATION.href}>
        <OnboardingNotification teams={teams} />
      </Route>
      <Route path={routes.ONBOARDING.TEAM.href}>
        <OnboardingTeam />
      </Route>
      <Route path={['', routes.ONBOARDING.href]}>
        <OnboardingOne />
      </Route>
    </Switch>
  );
};

export default Onboarding;

import {fetchAPiClient} from './api';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

export const getPartners = async () => {
  const url = '/compensation-partner';
  const {data} = await defaultApiClient.get(url, {params: {limit: 500}});
  return data.sort((a: any, b: any) => a.order - b.order);
};

export const getUserCompensations = async () => {
  const url = '/compensation/user/statistics';
  return defaultApiClient.get(url, {params: {limit: 500}});
};

export type GetCompensation = {
  id: number;
  countryCode?: string;
};

export const getCompensation = async ({id, countryCode}: GetCompensation) => {
  const url = `/compensation/${id}`;
  return defaultApiClient.get(url, {
    params: {country: countryCode?.toLowerCase()},
  });
};

interface PurchaseCompensation {
  compensation_id: number;
  month?: number;
}

export const purchaseCompensation = async (data: PurchaseCompensation) => {
  const url =
    typeof data.month === 'undefined'
      ? 'compensation/user/subscribe'
      : '/compensation/user/purchase';
  return await defaultApiClient.post(url, data);
};

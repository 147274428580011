import {isAndroid, isIOS} from 'react-device-detect';

import {NATIVE_MESSAGES} from 'constants/native-events';

export const sendMessageToNativeApp = ({message}: {message: any}) => {
  console.log(JSON.stringify({device: {isAndroid, isIOS}}));
  console.log('sendMessageToNativeApp');
  console.log(JSON.stringify(message));
  console.log(message);

  try {
    const stringifiedMessage = JSON.stringify(message);
    const escapedMessage = stringifiedMessage.replace(/:null/g, ':false');

    if (
      isIOS &&
      (window as any).webkit &&
      (window as any).webkit.messageHandlers
    ) {
      (window as any).webkit.messageHandlers.sumbitToiOS.postMessage(
        escapedMessage,
      );

      console.log('Sent message to IOS');
    } else if (isAndroid && (window as any).Android) {
      (window as any).Android.postMessage(escapedMessage);

      console.log('Sent message to Android');
    } else {
      if (
        (window as any).webkit &&
        (window as any).webkit.messageHandlers &&
        (window as any).webkit.messageHandlers.sumbitToiOS &&
        (window as any).webkit.messageHandlers.sumbitToiOS.postMessage
      ) {
        console.log('Falling back to iOS');
        (window as any).webkit.messageHandlers.sumbitToiOS.postMessage(
          escapedMessage,
        );
      } else {
        console.log('iOS fallback failed becuase of missing variables');
        console.log(
          'Device type not found! Not able to pass messages to native sdk!!!',
        );

        console.log({
          webkit: (window as any)?.webkit && true,
          messageHandlers: (window as any)?.webkit?.messageHandlers && true,
          sumbitToiOS:
            (window as any)?.webkit?.messageHandlers?.sumbitToiOS && true,
          postMessage:
            (window as any)?.webkit?.messageHandlers?.sumbitToiOS
              ?.postMessage && true,
        });

        console.log(
          JSON.stringify({
            webkit: (window as any)?.webkit && true,
            messageHandlers: (window as any)?.webkit?.messageHandlers && true,
            sumbitToiOS:
              (window as any)?.webkit?.messageHandlers?.sumbitToiOS && true,
            postMessage:
              (window as any)?.webkit?.messageHandlers?.sumbitToiOS
                ?.postMessage && true,
          }),
        );
      }
    }
  } catch (error) {
    console.log('Error occured in sendMessageToNativeApp');
    console.log(error);
    console.log("There's been an error", error);
  }
};

export const openEmailApp = () =>
  sendMessageToNativeApp({message: {type: NATIVE_MESSAGES.REQUEST_OPEN_MAIL}});

export const closeApp = () =>
  sendMessageToNativeApp({message: {type: NATIVE_MESSAGES.CLOSE_APP}});

import styled from 'styled-components';

import {addBoldText} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const FormLabel = styled(CopyTextSm)`
  display: flex;
  margin-bottom: 8px;
  font-weight: 500;
  width: 100%;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

export default FormLabel;

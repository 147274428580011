import {isAndroid} from 'react-device-detect';

import HammCoin from 'components/ClimateWidgets/Co2Token/HammCoin';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/HammCoin/images/coins/full.svg';

import onboardingOneImg from 'components/Onboarding/images/hamm/onboarding-1.svg';
import onboardingTwoImg from 'components/Onboarding/images/hamm/onboarding-2.svg';
import onboardingThreeImg from 'components/Onboarding/images/hamm/onboarding-3.svg';
import onboardingGpsImg from 'components/Onboarding/images/hamm/onboarding-gps.svg';
import onboardingMotionImg from 'components/Onboarding/images/hamm/onboarding-motion.svg';
import onboardingTeamsImg from 'components/Onboarding/images/hamm/onboarding-teams.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/hamm/onboarding-privacy.svg';
import treePlantingNewTreeImage from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/images/hamm/banner.svg';
import treePlantingGoalImage from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/images/hamm/banner.svg';

import inviteFriendsSuccessBanner from 'components/ClimateWidgets/InviteFriends/InviteFriendsSuccessPrompt/images/hamm/banner.svg';

import {ReactComponent as LocationLinkIcon} from 'icons/hamm/location-link.svg';
import {ReactComponent as AllMobilityIcon} from 'components/ClimateWidgets/Leaderboard/images/hamm/all-mobility.svg';

const config = {
  name: 'hamm',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Roboto',
          secondary: 'Adobe Sketchnote',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Adobe Sketchnote',
        },
      },
      de: {
        android: {
          primary: 'Roboto',
          secondary: 'Adobe Sketchnote',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Adobe Sketchnote',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#38bde4',
      'primary-300': '#c5f0fa',
      secondary: '#FFFFFF',
      gradient1: 'linear-gradient(to bottom, #421826, #8b1646)',
      gradient2: 'linear-gradient(to bottom, #421826, #8b1646)',
      gradient3: 'linear-gradient(to bottom, #8b1646, #8b1646)',
      tertiary: '#409DA9',
      darkBg: '#30454A',
      white: '#ffffff',
      black: '#3C3C3C',
      fullBlack: '#000000',
      gray: '#D8D9DA',
      green: '#cdd516',
      disabled: '#dee2e6',
      navMenu: '#ffffff66',
      promptBg: 'linear-gradient(to bottom, #421826, #8b1646)',
      promptColor: '#FFFFFF',
      promptLinkColor: '#38bde4',
      promptOverlayBg: '#00000099',
      onboardingBg: 'linear-gradient(to bottom, #421826, #8b1646)',
    },
    components: {
      global: {
        borderColor: 'glacierLight',
      },
      dashboard: {
        bgColor: 'gradient1',
      },
      competition: {
        bgColor: 'gradient1',
      },
      nav: {},
      widgetCard: {
        bgColor: 'gradient2',
        titleBgColor: 'fullBlack',
        titleColor: 'white',
        textColor: 'white',
      },
      offers: {
        navBgColor: 'gradient3',
        bodyBgColor: 'gradient2',
        activeTabBgColor: 'primary',
        recoinsLeftBgColor: 'green',
        recoinsRequiredBgColor: 'primary',
        recoinsTextColor: 'white',
        locationTypeBgColor: 'catSkillWhite',
        LocationLinkIcon,
      },
      climatePartnerMap: {
        bgColor: 'white',
        voucherBgColor: 'primary-300',
      },
      badges: {
        allLinkColor: 'primary',
      },
      treePlanting: {
        bgColor: 'gradient1',
        newTreeBanner: treePlantingNewTreeImage,
        goalBanner: treePlantingGoalImage,
        progressPathColor: '#38BDE4',
        progressBgColor: '#FFFFFF',
      },
      links: {
        textColor: 'primary',
      },
      buttons: {
        primary: {
          bgColor: 'primary',
          disabledBgColor: 'disabled',
          color: 'white',
        },
        gray: {
          bgColor: 'gray',
          color: 'white',
        },
        link: {
          bgColor: 'white',
          color: 'primary',
          borderRadius: 'none',
        },
      },
      mobilityIcons: {
        progressPathColor: 'primary',
        walking: {
          bgColor: '#29a6dd',
        },
        cycling: {
          bgColor: '#15e5da',
        },
        publicTransit: {
          bgColor: '#93cf21',
        },
      },
      prompts: {
        bgColor: 'gradient1',
        textColor: 'white',
        overlayBgColor: '#00000099',
      },
      customActivities: {
        detailsBgColor: 'gradient2',
        listBgColor: 'white',
        listTextColor: 'black',
        activeCheckColor: 'primary',
        caretColor: 'primary',
        detailsFooterBgColor: 'gradient2',
        detailsFooterColor: 'white',
      },
      settings: {
        itemBorderColor: 'white',
        labelColor: 'white',
        bgColor: 'gradient1',
        itemCaretColor: 'primary',
      },
      co2Compensation: {
        pageBgColor: 'secondary',
        emptySunBgColor: 'secondary',
      },
      leaderboard: {
        bgColor: 'gradient1',
        rankingSavingsColor: 'green',
        ctaColor: 'primary',
        rankingBorderColor: 'white',
        dotsColor: 'primary',
        mobilityIcons: {
          All: AllMobilityIcon,
        },
      },
      teams: {
        selectBorderColor: 'primary',
        caretColor: 'primary',
        banner: onboardingTeamsImg,
      },
      inviteFriends: {
        progressBarColor: 'green',
        shareIconColor: 'primary',
        successBanner: inviteFriendsSuccessBanner,
      },
      vouchers: {
        bgColor: 'white',
        textColor: 'white',
        recoinsTagBgColor: 'primary',
        recoinsLeftBgColor: 'green',
        active: {
          activeBgColor: 'gradient1',
          pendingBgColor: 'gradient1',
          expiredBgColor: 'gradient1',
          progressColor: 'primary',
          companyColor: 'white',
          closeIconColor: 'white',
        },
        online: {
          codeColor: 'primary',
        },
        offlinePageBg: 'gradient2',
        offlinePageColor: 'white',
      },
      lots: {
        textColor: 'white',
        ongoingLotBgColor: 'gradient1',
      },
      news: {
        bgColor: 'white',
        titleBgColor: 'secondary',
        titleColor: 'black',
      },
      summaryPage: {},
      form: {},
      onboarding: {
        images: {
          one: {
            image: onboardingOneImg,
          },
          two: {
            image: onboardingTwoImg,
          },
          three: {
            image: onboardingThreeImg,
          },
          privacy: {
            image: onboardingPrivacyImg,
          },
          gps: {
            image: onboardingGpsImg,
          },
          motion: {
            image: onboardingMotionImg,
          },
          teams: {
            image: onboardingTeamsImg,
          },
        },
      },
    },
    colors: {
      primary: '#38bde4',
      secondary: '#FFFFFF',
      body: 'linear-gradient(to bottom, #421826, #8b1646)',
      white: '#ffffff',
      black: '#3C3C3C',
      navMenu: '#ffffff66',
      promptColor: '#FFFFFF',
      promptLinkColor: '#38bde4',
      promptOverlayBg: '#00000099',
      onboardingBg: 'linear-gradient(to bottom, #421826, #8b1646)',
      onboardingColor: '#FFFFFF',
      teamRankingHighlight: '#00000066',
    },
  },
  translations: {
    cityName: 'sdk.web.hamm.city.name',
    shortTokenSingular: 'sdk.web.hamm.token.short.singular',
    shortTokenPlural: 'sdk.web.hamm.token.short.plural',
  },
  features: {
    hideAppClose: !isAndroid,
    hideLotsTab: false,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCo2Compensation: false,
    hasCustomActivities: true,
    hasBadges: true,
    hasUserRanking: true,
    hasNewQRScanner: true,
  },
  applangaTag: '620fa30a995af950f79dbe71',
  matomoTrackingId: 5,
  tokenType: 'HammCoin',
  fullTokenImg,
  FullTokenComponent: HammCoin,
};

export default config;

import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {noop} from 'utils/functions';
import {openEmailApp} from 'services/native-api';
import {changeEmail, EmailChangeData} from 'services/account';
import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useUserProfile} from 'hooks/use-user-profile';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import CtaLink from 'components/CtaLink/CtaLink';
import Input from 'components/Form/Input';
import FormRow from 'components/Form/FormRow';
import FormButton from 'components/Form/FormButton';
import AccountPrompt from './AccountPrompt';
import AccountText from './AccountText';
import AccountFooter from './AccountFooter';
import AccountButtonWrapper from './AccountButtonWrapper';
import AccountForm from './AccountForm';

const initialValues = {
  email: '',
};

interface EmailChangePromptProps {
  isVisible: boolean;
  onClose: () => void;
}

const EmailChangePrompt = (props: EmailChangePromptProps) => {
  const {isVisible, onClose} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailSent, emailSent, emailNotSent] = useBoolean(false);
  const {translate} = useTranslations();
  const {userProfile} = useUserProfile();
  const {apiErrors, setApiErrors, validate} = useFormValidation();
  const toast = useToast();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(translate('sdk.web.contact.form.email.error.invalid')),
  });

  const onSubmit = async (data: EmailChangeData) => {
    setIsSubmitting(true);

    try {
      await changeEmail({id: userProfile?.user.id as number, data});
      onChangeRequest();
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const fieldErrorKeys = e.response
        ? e.response?.data?.fieldErrorKeys
        : undefined;
      const message = translate(
        errorKey || 'sdk.web.account.email.error.fallback',
      );
      toast.error(message);
      setIsSubmitting(false);
      setApiErrors(fieldErrorKeys);
      emailNotSent();
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    validate,
  });

  const onChangeRequest = () => {
    setIsSubmitting(false);
    formik.resetForm();
    emailSent();
    toast.success(translate('sdk.web.account.email.success'));
  };

  const handleClose = () => {
    emailNotSent();
    onClose();
  };

  const text = isEmailSent
    ? 'sdk.web.account.email.success'
    : 'sdk.web.account.email.message';

  const btnText = isEmailSent
    ? 'sdk.web.account.open.mail.app'
    : 'sdk.web.account.send';
  const ctaText = isEmailSent
    ? 'sdk.web.account.done'
    : 'sdk.web.account.cancel';

  return (
    <AccountPrompt
      isVisible={isVisible}
      onClose={handleClose}
      title={translate('sdk.web.account.email.title')}>
      <AccountForm onSubmit={formik.handleSubmit}>
        <AccountText>{translate(text)}</AccountText>
        {!isEmailSent && (
          <FormRow>
            <Input
              placeholder={translate('sdk.web.contact.form.email.label')}
              name={'email'}
              type={'email'}
              formik={formik}
              apiErrors={apiErrors}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </FormRow>
        )}
        <AccountFooter>
          <AccountButtonWrapper>
            <FormButton
              type={isEmailSent ? 'button' : 'submit'}
              isLoading={isSubmitting}
              onClick={isEmailSent ? openEmailApp : noop}>
              {translate(btnText)}
            </FormButton>
          </AccountButtonWrapper>
          <CtaLink noMargin={true} onClick={handleClose}>
            {translate(ctaText)}
          </CtaLink>
        </AccountFooter>
      </AccountForm>
    </AccountPrompt>
  );
};

export default EmailChangePrompt;

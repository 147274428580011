import React from 'react';

import T0 from './images/coins/J0.svg';
import T1 from './images/coins/J1.svg';
import T2 from './images/coins/J2.svg';
import T3 from './images/coins/J3.svg';
import T4 from './images/coins/J4.svg';
import T5 from './images/coins/J5.svg';
import T6 from './images/coins/J6.svg';
import T7 from './images/coins/J7.svg';
import T8 from './images/coins/J8.svg';
import T9 from './images/coins/J9.svg';
import T10 from './images/coins/J10.svg';

const coins = [T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10];

const JTCoin = (props: any) => {
  const {percent} = props;
  const tokenIndex = Math.floor((percent / 100) * (coins.length - 1));
  const tokenImg = coins[tokenIndex];

  return (
    <React.Fragment>
      <img src={tokenImg} alt="" />
    </React.Fragment>
  );
};

export default JTCoin;

import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

import DetailsNavigation from 'components/DetailsNavigation/DetailsNavigation';
import Loader from 'components/Loader/Loader';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Wrapper = styled.div<{bgColor?: string}>`
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: scroll;
  top: 0;
  left: 0;
  background: ${(props) =>
    props.bgColor || props.theme.components.infoPage?.bgColor || 'white'};
  word-break: break-word;

  img {
    max-width: 100%;
  }
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const Body = styled(CopyTextSm)<{bgColor?: string; noPadding?: boolean}>`
  display: block;
  width: 100%;
  height: 100%;
  padding: 93px 20px 40px;
  background: ${(props) =>
    props.bgColor || props.theme.components.infoPage?.bgColor || 'white'};

  ${(props) => {
    if (props.noPadding) {
      return {
        padding: '73px 0 0',
      };
    }
  }}
  /*
      ==================================================
        Styles for the custom CKEditor accordion list item
      ==================================================
    */
  .accordion-item-title,
  .accordion-item-description {
    padding: 0;
    margin: 0;
  }

  .accordion-item-title {
    padding: 0;
    padding-right: 15px;
    position: relative;
    color: ${(props) => props.theme.colors.primary};

    &:after {
      content: ' +';
      position: absolute;
      right: 0;
      top: -3px;
    }

    &:focus,
    &:active {
      outline: 0;
      border: 0;
    }
  }

  .accordion-item summary::-webkit-details-marker {
    display: none;
  }

  .accordion-item > summary {
    list-style: none;
  }

  .accordion-item-description {
    padding: 15px 0 10px;
  }

  .accordion-item[open] {
    .accordion-item-title {
      &:after {
        content: ' -';
        float: right;
      }
    }
  }

  /*
  ==================================================
  Styles for the custom CKEditor accordion list item
  ==================================================
  */
`;

interface InfoPageProps {
  rows?: Array<any>;
  isLoading?: boolean;
  title?: string;
  bgColor?: string;
  noPadding?: boolean;
  onClose?: (e?: any) => void;
}

const InfoPage = (props: PropsWithChildren<InfoPageProps>) => {
  const {rows, isLoading, bgColor, noPadding} = props;

  return (
    <Wrapper bgColor={bgColor}>
      <DetailsNavigation onClose={props.onClose} text={props.title} />
      <Body as="div" bgColor={bgColor} noPadding={noPadding}>
        {isLoading ? (
          <Loader color={'#222'} />
        ) : (
          <>
            {props.children
              ? props.children
              : rows?.map((content, index) => <Row key={index}>{content}</Row>)}
          </>
        )}
      </Body>
    </Wrapper>
  );
};

export default InfoPage;

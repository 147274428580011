export enum WIDGETS {
  BADGES = 'badges',
  CO2_EMISSIONS = 'co2-emissions',
  CUSTOM_ACTIVITIES = 'activities',
  CO2_TOKEN = 'co2-token',
  CO2_SAVINGS = 'co2-savings',
  METERING = 'metering',
  TREE_PLANTING = 'tree-planting',
  CLIMATE_TREASURE = 'climate-treasure',
}

export type Widgets = typeof WIDGETS;

export type WidgetKeys = keyof Widgets;

import React, {useState} from 'react';
import styled, {StyledComponent} from 'styled-components';
import {FormikContextType} from 'formik';

import {ApiErrors} from 'hooks/forms/use-form-validation';
import {addGlobalBorderColor} from 'utils/theme';

import FormError from './FormError';
import {InputStyles} from './InputStyles';

import {ReactComponent as EyeIcon} from './icons/eye.svg';
import {ReactComponent as EyeClosedIcon} from './icons/eye-closed.svg';

export const DefaultInputEl = styled(InputStyles).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    // @ts-ignore
    prop === 'enterkeyhint' || defaultValidatorFn(prop),
})``;

const Wrapper = styled.div`
  position: relative;
`;

const Eye = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 21px;
  height: 21px;
  color: ${(props) =>
    props.theme.components.form?.borderColor || props.theme.colors.primary};

  ${addGlobalBorderColor()}
`;

export interface InputProps extends Partial<HTMLInputElement> {
  onChange: any;
  onKeyDown?: any;
  onBlur?: any;
  onInvalid?: any;
  enterkeyhint?: string;

  // Non-standard Attributes
  autoCapitalize?: string;
  autoComplete?: string;
  autoCorrect?: string;
  spellCheck?: boolean;

  formik: FormikContextType<any>;
  apiErrors?: ApiErrors;
  InputEl?: StyledComponent<'input', any>;
}

const Input = (props: InputProps) => {
  const {name, formik, type, apiErrors, InputEl, ...rest} = props;
  const [currentType, setCurrentType] = useState(type);

  const handlePasswordTypeToggle = () => {
    setCurrentType(currentType === 'password' ? 'text' : 'password');
  };

  const El = InputEl || DefaultInputEl;

  // @ts-ignore
  return (
    <Wrapper>
      {/*
  // @ts-ignore */}
      <El {...rest} name={name} type={currentType} onBlur={formik.handleBlur} />
      {type === 'password' && (
        <Eye onClick={handlePasswordTypeToggle}>
          {currentType === 'password' ? <EyeClosedIcon /> : <EyeIcon />}
        </Eye>
      )}
      <FormError name={name} formik={formik} apiErrors={apiErrors} />
    </Wrapper>
  );
};

export default Input;

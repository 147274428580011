import React, {useMemo} from 'react';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import toArray from 'lodash/toArray';

import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import {addBoldText} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import CopyText from 'components/Text/CopyText/CopyText';
import Project from './Project';
import CompensationDescription from './CompensationDescription';

import {CompensationPartner} from 'types/CompensationPartner';
import {ImpactType} from 'types/Compensation';
import {ReactComponent as InfoIcon} from 'icons/info-alt.svg';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const ImpactGroup = styled.div`
  display: block;
`;

const TitleBlock = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SubTitle = styled(CopyText)`
  display: block;
  padding-right: 5px;
  font-size: ${(props) =>
    props.theme.components.co2Compensation?.titleFontSize}px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Icon = styled.a`
  width: 24px;
  display: block;
  color: ${(props) => props.theme.colors.primary};
  fill: ${(props) => props.theme.colors.primary};
  flex-shrink: 0;
`;

const Description = styled(CopyTextSm)`
  margin-bottom: 15px;
  display: block;
`;

const Projects = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

type PartnerProps = {
  partner: CompensationPartner;
};

const Partners = ({partner}: PartnerProps) => {
  const [isDescVisible, showDesc, hideDesc] = useBoolean();
  const {translate} = useTranslations();

  const impactGroups = useMemo(
    () =>
      toArray(
        groupBy(
          partner.compensations.sort((a, b) => a.type - b.type),
          'impact',
        ),
      ).sort((a, b) => a[0].impact - b[0].impact),
    [partner.compensations],
  );

  return (
    <Wrapper>
      <TitleBlock>
        <SubTitle>{partner.slogan}</SubTitle>
        <Icon onClick={showDesc}>
          <InfoIcon />
        </Icon>
      </TitleBlock>
      {impactGroups.map((group) => (
        <ImpactGroup key={group[0].impact}>
          <Projects>
            {group[0].impact === ImpactType.DOUBLE && (
              <div>
                <TitleBlock>
                  <SubTitle>
                    {translate('sdk.web.compensation.double.impact')}
                  </SubTitle>
                </TitleBlock>
                <Description>
                  {translate('sdk.web.compensation.double.impact.desc')}
                </Description>
              </div>
            )}
            {group.map((compensation) => (
              <Project key={compensation.id} compensation={compensation} />
            ))}
          </Projects>
        </ImpactGroup>
      ))}
      {isDescVisible && (
        <CompensationDescription partner={partner} onClose={hideDesc} />
      )}
    </Wrapper>
  );
};

export default Partners;

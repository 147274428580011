import {CompensationPartner} from './CompensationPartner';

export enum ImpactType {
  SINGLE = 1,
  DOUBLE = 2,
}

export enum CompensationType {
  ONE_TIME = 1,
  SUBSCRIPTION = 2,
}

export enum TransactionType {
  TREE_PLANTING = 35,
  REGULAR = 55,
}

export type Compensation = {
  id: number;
  title: string;
  description: string;
  partner_id: number;
  partner: CompensationPartner;
  currency: number;
  price: number;
  gross_price: number;
  image: string;
  type: CompensationType;
  impact: ImpactType;
  quantity: number;
  co2_avoided: number;
  recoins: number;
  sale: string;
  transaction_type: TransactionType;
  taxable: boolean;
  tax_rate: number;
  order: number;
  enabled: boolean;
  subscribed: boolean;
  user_impact: number;
  user_co2_avoided: number;
};

export type UserCompensation = {
  id: number;
  compensation_date: string;
  compensation: Compensation;
  currency: string;
  price: number;
  co2_avoided: number;
  recoins_earned: number;
  quantity: number;
  transaction_type: TransactionType;
};

export type PurchasedCompensation = {
  id: number;
  month: number;
  total_user_impact: number;
  user_compensations: Array<UserCompensation>;
};

import React, {useMemo} from 'react';
import {useOrganization} from 'hooks/use-organization';
import Button from 'components/Button/Button';

const FormButton = (props: any) => {
  const {organizationConfig} = useOrganization();
  const variants = useMemo(
    () =>
      organizationConfig.theme.components.buttons?.form
        ? {
            variant: 'form',
          }
        : {},
    [organizationConfig],
  );

  return <Button {...props} {...variants} />;
};

export default FormButton;

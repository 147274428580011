import React, {useMemo} from 'react';
import styled from 'styled-components';

import {useTokenTranslation} from 'hooks/use-token-translation';
import useElementTranslation from '../../hooks/use-element-translation';
import {useMeterUnits} from './hooks';
import {formatDate} from 'utils/date';
import {formatNumber} from 'utils/numbers';
import {kwhToMeter} from './utils';
import {ELEMENT_CONSUMPTION_SCREENS} from '../../types';
import {ConsumptionScreenProps} from './types';

import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import HeaderTextLg from 'components/Text/HeaderTextLg/HeaderTextLg';
import ConsumptionValue from './ConsumptionValue';
import UpdateReadingCta from './UpdateReadingCta';

const Wrapper = styled(CopyText)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px 40px;

  .e-consumption-first {
    &__title {
      text-align: center;
      margin-bottom: 40px;
    }

    &__reading {
      margin-bottom: 40px;
    }

    &__projection {
      margin-bottom: 20px;
    }

    &__info {
      margin-bottom: 20px;
    }

    &__date {
      margin-top: 10px;
    }

    &__btn {
      margin-bottom: 20px;
    }
  }
`;

const FirstTime = (props: ConsumptionScreenProps) => {
  const {element, elementData, onComplete, manualMeasurement} = props;
  const translate = useElementTranslation({element});
  const {unit, meterUnit, showMeter} = useMeterUnits({element, elementData});
  const {getTokenText} = useTokenTranslation();

  const date = formatDate({date: new Date()});

  const {currentReading, currentReadingInMeter} = useMemo(
    () => ({
      currentReading: formatNumber({
        number: manualMeasurement?.value || 0,
        dp: 3,
        ignoreThousandRule: true,
      }),
      currentReadingInMeter: showMeter
        ? kwhToMeter({
            value: manualMeasurement?.value || 0,
            element,
            dp: 3,
          })
        : undefined,
    }),
    [manualMeasurement, showMeter, element],
  );

  return (
    <Wrapper as="div">
      <div>
        <div className="e-consumption-first__title">
          <Title>{translate('sdk.web.meter.consumption.first.title')}</Title>
        </div>
        <div className="e-consumption-first__reading">
          <HeaderTextLg>
            <ConsumptionValue
              showMeter={showMeter}
              unit={unit}
              meterUnit={meterUnit}
              value={currentReading}
              meterValue={currentReadingInMeter}
            />
          </HeaderTextLg>
          <div className="e-consumption-first__date">{date}</div>
        </div>
        <div
          className="e-consumption-first__projection"
          dangerouslySetInnerHTML={{
            __html: translate('sdk.web.meter.consumption.first.projection', {
              key: '{token}',
              value: getTokenText(2),
            }),
          }}
        />
        <div className="e-consumption-first__info">
          {translate('sdk.web.meter.consumption.first.info')}
        </div>
        <UpdateReadingCta
          onClick={() =>
            onComplete({nextScreen: ELEMENT_CONSUMPTION_SCREENS.SETTINGS})
          }
        />
      </div>
      <Button onClick={() => onComplete()}>
        {translate('sdk.web.meter.consumption.first.cta')}
      </Button>
    </Wrapper>
  );
};

export default FirstTime;

import styled from 'styled-components';

const Footer = styled.div`
  min-height: 18vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Footer;

import {RefObject, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

interface UseScrollToTopProps {
  wrapperRef: RefObject<HTMLElement>;
}

export const useScrollToTop = (props: UseScrollToTopProps) => {
  const {wrapperRef} = props;
  const {pathname} = useLocation();

  useEffect(() => {
    if (wrapperRef.current) {
      setTimeout(() => {
        wrapperRef.current?.scroll(0, 0);
      }, 0);

      // @ts-ignore
      // window.currentWrapperRef = wrapperRef.current;
    }
  }, [wrapperRef, pathname]);
};

import React from 'react';
import {FormikContextType} from 'formik';

import {useTranslations} from 'hooks/use-translations';
import Label from './Label';
import Input from 'components/Form/Input';

interface CodewordProps {
  formik: FormikContextType<any>;
  onChange: any;
}

const Codeword = (props: CodewordProps) => {
  const {formik, onChange} = props;
  const {translate} = useTranslations();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Label>{translate('sdk.web.teams.passphrase.label')}</Label>
      <Input
        placeholder={translate('sdk.web.teams.passphrase.placeholder')}
        name={'passphrase'}
        value={formik.values.passphrase}
        onChange={onChange}
        formik={formik}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellcheck={false}
        spellCheck={false}
      />
    </form>
  );
};

export default Codeword;

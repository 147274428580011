import React from 'react';
import {
  IntlActions,
  IntlProviderState,
  RootActions,
  RootProviderState,
} from './types';
import {IntlState, RootState} from './initialStates';
import {IntlReducer, RootReducer} from './reducers';
import {composeComponent, storeFactory} from './utils';
import ErrorBoundary from 'components/ErrorBoundary';
import AuthProvider from 'contexts/AuthContext';
import SettingsProvider from 'contexts/SettingsContext';
import ActivityProvider from 'contexts/ActivityContext';
import TranslationsProvider from 'contexts/TranslationsContext';
import ReviewProvider from 'contexts/ReviewContext';
import TeamProvider from 'contexts/TeamContext';
import NotificationProvider from 'contexts/NotificationContext';
import LocationProvider from '../contexts/LocationContext';
import OpenWidgetProvider from '../contexts/OpenWidgetContext';
import {LayoutProvider} from 'contexts/LayoutContext';
import {EventsProvider} from 'contexts/EventsContext';

// import { RootActionsCreator, IntlActionsCreator } from './actions';

/**
 * SDK Providers
 *
 * We use context providers to allow passing complex
 * data structures and logic in a simply redux way.
 *
 * For possible issues please:
 * @see {@link https://stackoverflow.com/questions/54577865/react-createcontext-issue-in-typescript/54667477}
 * @see {@link https://gist.github.com/sw-yx/f18fe6dd4c43fddb3a4971e80114a052}
 * @see {@link https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c}
 */

// Typescript needs a default export for tsx files xD
const Providers = 'Providers';
export default Providers;

/** Root Provider */
export const [RootStore, RootStoreProvider] = storeFactory<
  RootProviderState,
  RootActions
>(RootReducer, RootState);

/** Intl Provider */
export const [IntlStore, IntlStoreProvider] = storeFactory<
  IntlProviderState,
  IntlActions
>(IntlReducer, IntlState);

/** SDKProvider is just to clean component tree a bit */
export const SDKProvider = composeComponent([
  (props) => <RootStoreProvider {...props} />,
  (props) => <IntlStoreProvider {...props} />,
  (props) => <ErrorBoundary {...props} />,
  (props) => <SettingsProvider {...props} />,
  (props) => <AuthProvider {...props} />,
  (props) => <TranslationsProvider {...props} />,
  (props) => <ActivityProvider {...props} />,
  (props) => <ReviewProvider {...props} />,
  (props) => <TeamProvider {...props} />,
  (props) => <LocationProvider {...props} />,
  (props) => <NotificationProvider {...props} />,
  (props) => <LayoutProvider {...props} />,
  (props) => <EventsProvider {...props} />,
  (props) => <OpenWidgetProvider {...props} />,
]);

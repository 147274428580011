import React from 'react';
import {FormikContextType} from 'formik';
import styled from 'styled-components';

import {ApiErrors} from 'hooks/forms/use-form-validation';

import FormError from 'components/Form/FormError';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import RadioButton from 'components/Form/RadioButton';

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .form-radio {
    &__list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    &__label {
      display: flex;
      margin-right: 20px;
      align-items: center;
      flex-shrink: 0;
    }

    &__input {
      display: none;
    }
  }
`;

type RadioItem = {
  id: number | string;
  value: any;
  label: string;
};

type RadioProps = {
  name?: string;
  type?: string;
  value?: string;
  multi?: boolean;
  list: Array<RadioItem>;
  onChange: (e: any) => void;
  formik?: FormikContextType<any>;
  apiErrors?: ApiErrors;

  LabelComponent?: typeof CopyTextSm;
};

const Radio = (props: RadioProps) => {
  const {
    name,
    value,
    type,
    list,
    multi,
    formik,
    apiErrors,
    onChange,
    LabelComponent = CopyTextSm,
  } = props;

  return (
    <Wrapper>
      <div className="form-radio__list">
        {list.map((item) => {
          const checked =
            type === 'checkbox' && multi
              ? Boolean(value?.includes(item.value.toString()))
              : value?.toString() === item.value.toString();

          return (
            <label key={item.id} className="form-radio__label">
              <input
                className="form-radio__input"
                type={type || 'radio'}
                name={name}
                value={item.value}
                onChange={onChange}
                checked={checked}
              />
              <RadioButton checked={checked} />
              <LabelComponent>{item.label}</LabelComponent>
            </label>
          );
        })}
      </div>
      {formik && (
        <FormError name={name} formik={formik} apiErrors={apiErrors} />
      )}
    </Wrapper>
  );
};
export default Radio;

import React, {useCallback, useContext, useMemo, useState} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import styled from 'styled-components';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import {useTokenTranslation} from 'hooks/use-token-translation';
import useCoinsTransferLinkShare from 'hooks/wallet/use-coins-transfer-link-share';
import {getCoinsTransferLink, transferCoins} from 'services/wallets';
import {TRANSFER_TYPE} from 'types/Wallet';
import {formatToken} from 'utils/numbers';

import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import DialogBox from 'components/DialogBox/DialogBox';
import Input from 'components/Form/Input';
import CopyText from 'components/Text/CopyText/CopyText';
import PromptWrapper, {
  Title,
} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import AvailableCoins from './AvailableCoins';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

import successImage from './images/success.svg';

export type TransferData =
  | {
      walletId: string;
      name: string;
      transferType: TRANSFER_TYPE.SCANNER;
    }
  | {
      walletId?: string;
      name?: string;
      transferType: TRANSFER_TYPE.LINK;
    };

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;

  .trx-modal {
    &__title {
      margin-bottom: 30px;
    }

    &__coins {
      margin-bottom: 10vh;
    }

    &__form-group {
      label {
        margin-bottom: 10px;
      }

      // text-align: left;
      input {
        text-align: center;
      }
    }
  }
`;

interface TransferModalProps {
  isVisible: boolean;
  transferData: TransferData;
  coinBalance: number;
  onComplete: () => any;
  onClose: () => any;
}

const transferConfig = {
  [TRANSFER_TYPE.LINK]: {
    confirmation: {
      title: 'sdk.web.wallets.transfer.link.prompt.title',
      text: 'sdk.web.wallets.transfer.link.prompt.text',
    },
    infoText: 'sdk.web.wallets.transfer.link.info',
    successText: 'sdk.web.wallets.transfer.link.success.text',
    cta: 'sdk.web.wallets.transfer.link.cta',
  },
  [TRANSFER_TYPE.SCANNER]: {
    confirmation: {
      title: 'sdk.web.wallets.transfer.prompt.title',
      text: 'sdk.web.wallets.transfer.prompt.text',
    },
    infoText: '',
    successText: 'sdk.web.wallets.transfer.success.text',
    cta: 'sdk.web.wallets.transfer.confirm',
  },
};

const TransferModal = (props: TransferModalProps) => {
  const {isVisible, coinBalance, transferData, onComplete, onClose} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const {translate} = useTranslations();
  const {getTokenText, getShortTokenText} = useTokenTranslation();
  const {apiErrors, setApiErrors, validate} = useFormValidation();
  const toast = useToast();
  const {shareCoinsTransferLink} = useCoinsTransferLinkShare();

  const [isComplete, complete] = useBoolean();
  const [isConfirmationOpen, openConfirmation, closeConfirmation] =
    useBoolean();
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();
  const [transferLink, setTransferLink] = useState<string | undefined>();

  const validationSchema = useMemo(() => {
    const coinsWithDp = formatToken({
      number: coinBalance,
      ignoreThousandRule: true,
      toNumber: true,
    }) as number;
    const token = getShortTokenText(Math.floor(coinsWithDp));
    const fieldSub = {
      key: '{field}',
      value: token,
    };

    return yup.object().shape({
      amount: yup
        .number()
        .required(translate('sdk.web.error.field.required', fieldSub))
        .min(1, translate('sdk.web.error.field.min', fieldSub))
        .max(coinsWithDp, translate('sdk.web.error.field.max', fieldSub))
        .test(
          'is-whole-number',
          translate('sdk.web.wallets.transfer.warning.coins.whole', {
            key: '{tokens}',
            value: getTokenText(2),
          }),
          (v) => Number.isInteger(v),
        ),
    });
  }, [coinBalance, getShortTokenText, getTokenText, translate]);

  const handleTransferLinkShare = useCallback(
    (link?: string) => {
      const shareLink = link || transferLink || '';
      shareCoinsTransferLink(shareLink);
    },
    [transferLink, shareCoinsTransferLink],
  );

  const handleTransfer = useCallback(
    async ({transfer, amount}: {transfer: boolean; amount: number}) => {
      if (!transfer) {
        closeConfirmation();
        return;
      }

      setTransferLink(undefined);
      startSubmitting();
      try {
        switch (transferData.transferType) {
          case TRANSFER_TYPE.LINK: {
            const response = await getCoinsTransferLink({
              amount,
            });

            setTransferLink(response.link);
            handleTransferLinkShare(response.link);
            break;
          }

          case TRANSFER_TYPE.SCANNER:
            await transferCoins({
              amount,
              wallet_id: transferData.walletId,
            });
            break;
        }

        closeConfirmation();
        onComplete();
        complete();
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const fieldErrorKeys = e.response
          ? e.response?.data?.fieldErrorKeys
          : undefined;
        const message = translate(
          errorKey || 'sdk.web.wallets.transfer.error.fallback',
        );
        toast.error(message);
        setApiErrors(fieldErrorKeys);
      } finally {
        stopSubmitting();
      }
    },
    [
      transferData,
      toast,
      translate,
      startSubmitting,
      stopSubmitting,
      closeConfirmation,
      setApiErrors,
      complete,
      onComplete,
      handleTransferLinkShare,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues: {
      amount: '',
    },
    onSubmit: openConfirmation,
    validate,
  });

  return (
    <>
      <PromptWrapper isVisible={isVisible} onClose={onClose}>
        <Wrapper className="trx-modal" onSubmit={formik.handleSubmit}>
          {!isComplete ? (
            <>
              <div>
                <Title className="trx-modal__title">
                  {translate('sdk.web.wallets.transfer.title', {
                    key: '{tokens}',
                    value: getShortTokenText(2),
                  })}
                </Title>
                <div className="trx-modal__coins">
                  <AvailableCoins coinBalance={coinBalance} />
                </div>
                <div className="trx-modal__form-group">
                  <label>
                    <b>{translate('sdk.web.wallet.transfer.title')}</b>
                  </label>
                  <Input
                    name="amount"
                    type="number"
                    min="1"
                    step="1"
                    pattern="[0-9]"
                    value={formik.values.amount}
                    formik={formik}
                    apiErrors={apiErrors}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div>
                {transferConfig[transferData.transferType].infoText && (
                  <CopyTextSm as="div" className="mb-base">
                    {translate(
                      transferConfig[transferData.transferType].infoText,
                      {
                        key: '{tokens}',
                        value: getTokenText(2),
                      },
                    )}
                  </CopyTextSm>
                )}
                <Button className="mb-base" isLoading={isSubmitting}>
                  {translate(transferConfig[transferData.transferType].cta)}
                </Button>
                <CtaLink noMargin onClick={onClose}>
                  {translate('sdk.web.wallets.transfer.cancel')}
                </CtaLink>
              </div>
            </>
          ) : (
            <>
              <div>
                <Title className="trx-modal__title">
                  {translate('sdk.web.wallets.transfer.success.title')}
                </Title>
                <img
                  src={
                    organizationConfig.theme.components.transfer
                      ?.successImage || successImage
                  }
                  alt="Success"
                  className="mb-base"
                />
                <CopyText
                  as="div"
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      transferConfig[transferData.transferType].successText,
                      {
                        key: '{tokens}',
                        value: getTokenText(1),
                      },
                    ),
                  }}
                />
              </div>
              <Button type="button" onClick={onClose}>
                {translate('sdk.web.dialog.box.ok')}
              </Button>
            </>
          )}
        </Wrapper>
      </PromptWrapper>
      {isConfirmationOpen && (
        <DialogBox
          title={translate(
            transferConfig[transferData.transferType].confirmation.title,
            {
              key: '{tokens}',
              value: getTokenText(1),
            },
          )}
          promptMessage={translate(
            transferConfig[transferData.transferType].confirmation.text,
            [
              {
                key: '{amount}',
                value: formatToken({
                  number: +formik.values.amount,
                }),
              },
              {
                key: '{tokens}',
                value: getTokenText(+formik.values.amount),
              },
              {
                key: '{name}',
                value: transferData.name,
              },
            ],
          )}
          noText={translate('sdk.web.dialog.box.cancel')}
          yesText={translate('sdk.web.dialog.box.confirm')}
          isLoading={isSubmitting}
          onConfirmation={(transfer) =>
            handleTransfer({transfer, amount: +formik.values.amount})
          }
        />
      )}
    </>
  );
};

export default TransferModal;

import {useContext} from 'react';
import {SettingsContext} from 'contexts/SettingsContext';
import {
  CLIMATE_ICONS_TEMPLATES,
  FALLBACK_CLIMATE_ICON,
} from 'constants/climate-categories';

export enum IconVariants {
  PRIMARY = 'primary',
  INVERTED_PRIMARY = 'inverted-primary',
}

type GetIconString = {
  icon: string;
  variant: IconVariants;
};

export const useClimateMapIcon = () => {
  const {organizationConfig} = useContext(SettingsContext);
  const whiteColor = organizationConfig.theme.colors.white;
  const primaryColor = organizationConfig.theme.colors.primary;

  const getIconString = ({icon, variant}: GetIconString) => {
    const template =
      CLIMATE_ICONS_TEMPLATES[icon] ||
      CLIMATE_ICONS_TEMPLATES[FALLBACK_CLIMATE_ICON];
    const backgroundColor =
      variant === IconVariants.PRIMARY ? whiteColor : primaryColor;
    const strokeColor =
      variant === IconVariants.PRIMARY ? primaryColor : whiteColor;

    const svgString = template
      .replace(
        '<defs class="template"></defs>',
        `
        <defs>
            <style>.climate-caty{fill: ${strokeColor};}.climate-caty-stroke{stroke: ${strokeColor};}</style>
        </defs>
        <circle cx="24" cy="24" r="24" fill="${backgroundColor}"/>
    `,
      )
      .replace('xmlns="http://www.w3.org/2000/svg"', '')
      .replace(
        '<svg',
        '<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px"',
      );

    return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgString)}`;
  };

  return {getIconString};
};

import React, {useCallback, useContext, useMemo} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {APP_PERMISSIONS} from 'constants/app-features';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';

import PromptModal from 'components/PromptModal/PromptModal';

const useVersionCheck = () => {
  const {translate} = useTranslations();
  const {appPermissions} = useContext(SettingsContext);
  const [isDialogVbl, showDialog] = useBoolean();

  const hasPermissions = useMemo(
    () => appPermissions.includes(APP_PERMISSIONS.CAMERA),
    [appPermissions],
  );

  const checkVersion = useCallback(
    ({onSuccess}: {onSuccess: () => any}) => {
      if (hasPermissions) {
        return onSuccess();
      }

      showDialog();
    },
    [hasPermissions, showDialog],
  );

  const VersionPrompt = useMemo(
    () =>
      isDialogVbl
        ? ({onClose}: {onClose: () => any}) => (
            <PromptModal
              isVisible={isDialogVbl}
              title={translate('sdk.web.meter.outdated.title')}
              textContent={translate('sdk.web.meter.outdated.body')}
              btnText={translate('sdk.web.dialog.box.ok')}
              onBtnClick={onClose}
              onClose={onClose}
            />
          )
        : React.Fragment,
    [translate, isDialogVbl],
  );

  return {
    hasPermissions,
    checkVersion,
    VersionPrompt,
  };
};

export default useVersionCheck;

import React, {useContext} from 'react';
import styled from 'styled-components';

import {ReviewContext} from 'contexts/ReviewContext';
import {useTranslations} from 'hooks/use-translations';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {formatLotteryDate} from 'utils/date';
import {addBoldText} from 'utils/theme';

import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import HeaderText from 'components/Text/HeaderText/HeaderText';

import lotteryCrab from '../images/lottery-crab-blank.svg';
import {ReactComponent as CloseIcon} from './icons/close.svg';

const Wrapper = styled.div`
  position: relative;
  padding: 20px 10px;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) =>
    props.theme.components.prompts.bgColor ||
    props.theme.colorPalette.promptBg ||
    props.theme.colors.promptBg};
  color: ${(props) =>
    props.theme.components.prompts?.textColor || props.theme.colors.black};
`;

const Icon = styled.div`
  width: 25px;
  margin-bottom: 20px;
  fill: ${(props) =>
    props.theme.components.prompts?.textColor || props.theme.colors.black};
`;

const Title = styled(HeaderText)`
  text-align: center;
  display: block;
  margin: 0 auto;
  max-width: 80%;
  margin-bottom: 50px;
  width: 100%;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const LotContainer = styled.div`
  position: relative;
  margin-bottom: 50px;
  color: ${(props) => props.theme.colors.black};
`;

const Img = styled.img`
  width: 300px;
  max-width: 100%;
`;

const Lot = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  top: 20px;
`;

const LotTitle = styled(CopyText)`
  display: block;
  margin-bottom: 3px;
`;

const LotDrawing = styled(CopyText)``;

const Info = styled(CopyText)`
  display: block;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  padding-bottom: 10%;
`;

const NewLot = (props: any) => {
  useBodyBg({type: 'prompt'});
  const {translate} = useTranslations();
  const {setIsReviewOpen} = useContext(ReviewContext);

  const {lot, onClose} = props;

  const handleClose = () => {
    setIsReviewOpen && setIsReviewOpen(true);
    onClose();
  };

  return (
    <Wrapper>
      <div>
        <Icon onClick={handleClose}>
          <CloseIcon />
        </Icon>
        <Title>{lot.lottery.title}</Title>
        <LotContainer>
          <Img src={lotteryCrab} alt="" />
          <Lot>
            <LotTitle>
              <b>{translate('sdk.web.lots.drawing')}</b>{' '}
              {formatLotteryDate({date: lot.lottery.end_date})}
            </LotTitle>
            <LotDrawing>{lot.code}</LotDrawing>
          </Lot>
        </LotContainer>
        <Info>{translate('sdk.web.lots.saved.message')}</Info>
      </div>
      <ButtonContainer>
        <Button onClick={handleClose}>{translate('sdk.web.offers.ok')}</Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default NewLot;

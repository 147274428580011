import React, {useCallback, useContext, useMemo} from 'react';
import {Route, Switch} from 'react-router-dom';

import routes from 'config/routes/rewards';
import {CACHE_KEYS} from 'constants/cache-keys';
import {LocationContext} from 'contexts/LocationContext';
import {useOrganization} from 'hooks/use-organization';
import {useFetcher} from 'hooks/use-fetcher';
import {getPurchasedVouchers, getWishlistedVouchers} from 'services/vouchers';

import Vouchers, {VOUCHER_KEYS} from 'components/Rewards/Vouchers/Vouchers';
import HowToPage from './HowToPage/HowToPage';
import VoucherOfferPage from './VoucherOfferPage/VoucherOfferPage';

const VouchersPage = () => {
  const {location} = useContext(LocationContext);
  const {hasClimatePartners, voucherTypes} = useOrganization();

  const {
    isLoading: isPurchasedVouchersLoading,
    data: purchasedVouchers,
    fetchData: reloadPurchasedVouchers,
  } = useFetcher({
    fetcher: getPurchasedVouchers,
    params: {
      redeemed: 0,
      types: voucherTypes,
    },
    key: CACHE_KEYS.PURCHASED_VOUCHERS,
  });

  const {
    data: usedVouchers,
    isLoading: isUsedVouchersLoading,
    fetchData: reloadUsedVouchers,
  } = useFetcher({
    fetcher: getPurchasedVouchers,
    params: {
      types: voucherTypes,
      redeemed: 1,
    },
    key: CACHE_KEYS.USED_VOUCHERS,
  });

  const {
    isLoading: isWishlistsLoading,
    data: wishlists,
    fetchData: reloadWishlists,
  } = useFetcher({
    fetcher: getWishlistedVouchers,
    params: {location},
    key: CACHE_KEYS.WISHLISTED_VOUCHERS,
    initialValue: [],
    preventFetch: !hasClimatePartners,
  });

  const {vouchers, isVouchersLoading, reloadVouchers} = useMemo(
    () => ({
      isVouchersLoading: isPurchasedVouchersLoading || isUsedVouchersLoading,
      vouchers: [...(purchasedVouchers || []), ...(usedVouchers || [])],
      reloadVouchers() {
        reloadPurchasedVouchers();
        reloadUsedVouchers();
      },
    }),
    [
      purchasedVouchers,
      usedVouchers,
      isPurchasedVouchersLoading,
      isUsedVouchersLoading,
      reloadUsedVouchers,
      reloadPurchasedVouchers,
    ],
  );

  const handleReRefresh = useCallback(async () => {
    await Promise.all([
      reloadPurchasedVouchers(),
      reloadUsedVouchers(),
      reloadWishlists(),
    ]);
  }, [reloadPurchasedVouchers, reloadUsedVouchers, reloadWishlists]);

  const vouchersData = {
    [VOUCHER_KEYS.PURCHASED]: {
      data: purchasedVouchers || [],
      isLoading: isPurchasedVouchersLoading,
      reload: reloadVouchers,
    },
    [VOUCHER_KEYS.WISHLIST]: {
      data: wishlists || [],
      isLoading: isWishlistsLoading,
      reload: reloadWishlists,
    },
    [VOUCHER_KEYS.USED]: {
      data: usedVouchers || [],
      isLoading: isUsedVouchersLoading,
      reload: reloadVouchers,
    },
  };

  return (
    <Switch>
      <Route path={`${routes.REWARDS.VOUCHERS.href}/how-to/:id`}>
        <HowToPage vouchers={vouchers} isLoading={isVouchersLoading} />
      </Route>
      <Route path={`${routes.REWARDS.VOUCHERS.NEW.href}/:id`}>
        <VoucherOfferPage
          vouchers={vouchers}
          isLoading={isVouchersLoading}
          isNew={true}
        />
      </Route>
      <Route path={`${routes.REWARDS.VOUCHERS.href}/:id`}>
        <VoucherOfferPage vouchers={vouchers} isLoading={isVouchersLoading} />
      </Route>
      <Route>
        <Vouchers vouchersData={vouchersData} onRefresh={handleReRefresh} />
      </Route>
    </Switch>
  );
};

export default VouchersPage;

const locale = {
  relativeTime: {
    future: '%s päästä',
    past: '%s sitten',
    s: translate,
    ss: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate,
  },
};

function translate(
  number: number,
  withoutSuffix: boolean,
  key: string,
  isFuture: boolean,
) {
  var result = '';
  switch (key) {
    case 's':
      return isFuture ? 'muutaman sekunnin' : 'muutama sekunti';
    case 'ss':
      result = isFuture ? 'sekunnin' : 'sekuntia';
      break;
    case 'm':
      return isFuture ? 'minuutin' : 'minuutti';
    case 'mm':
      result = isFuture ? 'minuutin' : 'minuuttia';
      break;
    case 'h':
      return isFuture ? 'tunnin' : 'tunti';
    case 'hh':
      result = isFuture ? 'tunnin' : 'tuntia';
      break;
    case 'd':
      return isFuture ? 'päivän' : 'päivä';
    case 'dd':
      result = isFuture ? 'päivän' : 'päivää';
      break;
    case 'M':
      return isFuture ? 'kuukauden' : 'kuukausi';
    case 'MM':
      result = isFuture ? 'kuukauden' : 'kuukautta';
      break;
    case 'y':
      return isFuture ? 'vuoden' : 'vuosi';
    case 'yy':
      result = isFuture ? 'vuoden' : 'vuotta';
      break;
  }
  result = verbalNumber(number, isFuture) + ' ' + result;
  return result;
}

const numbersPast =
  'nolla yksi kaksi kolme neljä viisi kuusi seitsemän kahdeksan yhdeksän'.split(
    ' ',
  );

const numbersFuture = [
  'nolla',
  'yhden',
  'kahden',
  'kolmen',
  'neljän',
  'viiden',
  'kuuden',
  numbersPast[7],
  numbersPast[8],
  numbersPast[9],
];

function verbalNumber(number: number, isFuture: boolean) {
  return number < 10
    ? isFuture
      ? numbersFuture[number]
      : numbersPast[number]
    : number;
}

export default locale;

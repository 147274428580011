import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import useEventsListener from 'hooks/events/use-events-listener';
import useCoinsTransferLinkShare from 'hooks/wallet/use-coins-transfer-link-share';
import {getWalletTransactions} from 'services/wallets';
import {
  WalletTransactionAction,
  WalletTransactionActionTypes,
  WalletTransaction,
} from 'types/Wallet';
import {formatDate, getDateElements, getMonthTranslationKey} from 'utils/date';
import {formatNumber} from 'utils/numbers';

import Loader from 'components/Loader/Loader';
import PullToRefresh from 'components/PullToRefresh';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import {ReactComponent as RepeatIcon} from 'icons/repeat.svg';

const Wrapper = styled.div`
  overflow: auto;
  padding-top: 50px;
  height: 100%;

  .wallet-trx {
    &__scroll-container {
      overflow: auto;
      height: 100%;
    }

    &__month {
      &-title {
        margin-bottom: 20px;
      }

      &-table {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }

    &__table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }

    &__th {
      padding-bottom: 10px;
      border-bottom: 2px solid ${(props) => props.theme.colors.primary};

      &--day {
        width: 100px;
      }

      &--activity {
        width: calc(100% - 150px);
      }

      &--coins {
        width: 70px;
        text-align: right;
      }
    }

    &__td-coins {
      color: ${(props) =>
        props.theme.components.wallets?.textColor ||
        props.theme.colors.primary};
      text-align: right;

      &--danger {
        color: #d20000;
      }
    }

    &__coin-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
    }

    &__action {
      width: 20px;
      color: #000;

      svg {
        width: 100%;
      }
    }
  }

  &.wallet-trx {
    td {
      padding: 14px 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.primary}5D;
    }
  }
`;

const WalletTransactions = () => {
  const {translate} = useTranslations();
  const {tokenAbbreviation} = useTokenTranslation();
  const {shareCoinsTransferLink} = useCoinsTransferLinkShare();

  const {
    data,
    pagination,
    fetchData: fetchMoreTransactions,
  } = useFetcher<WalletTransaction[]>({
    fetcher: getWalletTransactions,
    key: CACHE_KEYS.WALLET_TRANSACTIONS,
    paginate: true,
    limit: 4,
    initialValue: [],
  });

  const transactions = useMemo(
    () =>
      data.map((transaction) => {
        const monthDate = new Date(transaction.month);
        const {year} = getDateElements({date: monthDate});
        const month = `${translate(
          getMonthTranslationKey({
            date: monthDate,
          }),
        )} ${year}`;

        const items = transaction.items.map((item) => ({
          ...item,
          day: formatDate({date: item.date, skipYear: true}),
          coins: formatNumber({
            number: Math.abs(item.coins),
            skipNormalization: true,
            dp: 2,
          }),
          coinsModifier: item.coins < 0 ? '-' : '+',
          classModifier: item.coins < 0 ? 'danger' : '',
        }));

        return {
          ...transaction,
          month,
          items,
        };
      }),
    [data, translate],
  );

  const handleRefresh = useCallback(() => {
    return fetchMoreTransactions(null, {resetPage: true});
  }, [fetchMoreTransactions]);

  const handleTransactionAction = useCallback(
    (action: WalletTransactionAction) => {
      switch (action.type) {
        case WalletTransactionActionTypes.RE_SHARE_LINK: {
          shareCoinsTransferLink(action.params.link);
          break;
        }
        default:
          break;
      }
    },
    [shareCoinsTransferLink],
  );

  useEventsListener({
    event: 'tokensUpdated',
    callback: handleRefresh,
  });

  return (
    <Wrapper className="wallet-trx" id="scrollContainer">
      <PullToRefresh loaderColor="#222" onRefresh={handleRefresh}>
        <div className="wallet-trx__scroll-container">
          <InfiniteScroll
            dataLength={transactions.length}
            next={fetchMoreTransactions}
            hasMore={pagination.hasMore}
            loader={<Loader sm color="#222" />}
            scrollableTarget="scrollContainer">
            <div className="wallet-trx__content">
              {transactions.map((monthTransaction) => (
                <div key={monthTransaction.month}>
                  <WidgetTitle className="wallet-trx__month-title">
                    {monthTransaction.month}
                  </WidgetTitle>
                  <div className="wallet-trx__month-table">
                    <table className="wallet-trx__table">
                      <thead>
                        <tr>
                          <th className="wallet-trx__th wallet-trx__th--day">
                            {translate('sdk.web.wallets.trx.day')}
                          </th>
                          <th className="wallet-trx__th wallet-trx__th--activity">
                            {translate('sdk.web.wallets.trx.activity')}
                          </th>
                          <th className="wallet-trx__th wallet-trx__th--coins">
                            {tokenAbbreviation}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {monthTransaction.items.map((transaction) => (
                          <tr key={transaction.date}>
                            <td>{transaction.day}</td>
                            <td>{transaction.description}</td>
                            <td
                              className={`wallet-trx__td-coins wallet-trx__td-coins--${transaction.classModifier}`}>
                              <div className="wallet-trx__coin-actions">
                                {transaction.action?.type ===
                                WalletTransactionActionTypes.RE_SHARE_LINK ? (
                                  <div
                                    className="wallet-trx__action"
                                    onClick={() =>
                                      handleTransactionAction(
                                        transaction.action!,
                                      )
                                    }>
                                    <RepeatIcon />
                                  </div>
                                ) : (
                                  <div />
                                )}
                                <div>
                                  {transaction.coinsModifier}
                                  {transaction.coins}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </PullToRefresh>
    </Wrapper>
  );
};

export default WalletTransactions;

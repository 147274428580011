import React, {useState} from 'react';
import {changePassword} from 'services/account';
import {openEmailApp} from 'services/native-api';
import {useToast} from 'hooks/use-toast';
import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import CtaLink from 'components/CtaLink/CtaLink';
import FormButton from 'components/Form/FormButton';
import AccountPrompt from './AccountPrompt';
import AccountText from './AccountText';
import AccountFooter from './AccountFooter';
import AccountButtonWrapper from './AccountButtonWrapper';

interface ChangePasswordPromptProps {
  isVisible: boolean;
  onClose: () => void;
}

const PasswordChangePrompt = (props: ChangePasswordPromptProps) => {
  const {isVisible, onClose} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailSent, emailSent, emailNotSent] = useBoolean(false);
  const {translate} = useTranslations();
  const toast = useToast();

  const handlePasswordChangeRequest = async () => {
    setIsSubmitting(true);

    try {
      await changePassword();
      onChangeRequest();
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const message = translate(
        errorKey || 'sdk.web.account.password.change.error.fallback',
      );
      toast.error(message);
      setIsSubmitting(false);
      emailNotSent();
    }
  };

  const onChangeRequest = () => {
    setIsSubmitting(false);
    emailSent();
    toast.success(translate('sdk.web.account.password.change.success'));
  };

  const handleClose = () => {
    emailNotSent();
    onClose();
  };

  const text = isEmailSent
    ? 'sdk.web.account.password.change.success'
    : 'sdk.web.account.password.change.message';

  const btnText = isEmailSent
    ? 'sdk.web.account.open.mail.app'
    : 'sdk.web.account.send';
  const ctaText = isEmailSent
    ? 'sdk.web.account.done'
    : 'sdk.web.account.cancel';

  return (
    <AccountPrompt
      isVisible={isVisible}
      onClose={handleClose}
      title={translate('sdk.web.account.password.change.title')}>
      <AccountText>{translate(text)}</AccountText>
      <AccountFooter>
        <AccountButtonWrapper>
          <FormButton
            type={isEmailSent ? 'button' : 'submit'}
            isLoading={isSubmitting}
            onClick={isEmailSent ? openEmailApp : handlePasswordChangeRequest}>
            {translate(btnText)}
          </FormButton>
        </AccountButtonWrapper>
        <CtaLink noMargin={true} onClick={handleClose}>
          {translate(ctaText)}
        </CtaLink>
      </AccountFooter>
    </AccountPrompt>
  );
};

export default PasswordChangePrompt;

import ls from 'localstorage-slim';

import config from 'config/cache';
import {CACHE_KEYS} from 'constants/cache-keys';
import {UserProfile} from 'services/user';

const {cacheTtlSecs, flushInterval} = config;
setInterval(() => {
  ls.flush();
}, flushInterval); // Flush expired items every interval

interface AddCacheData {
  key?: string;
  data: any;
  ttl?: number;
}

export const addCacheData = ({key, data, ttl = cacheTtlSecs}: AddCacheData) => {
  if (!key) {
    return;
  }

  try {
    ls.set(key, data, {ttl});
  } catch (e) {
    ls.flush(true);
  }
};

interface GetCachedData {
  key?: string;
}

export const getCachedData = ({key}: GetCachedData) => {
  if (!key) {
    return;
  }

  try {
    return ls.get(key);
  } catch (e) {
    console.error(e);
  }
};

export const getCachedProfile = (): UserProfile | undefined => {
  const cachedProfile = localStorage.getItem(CACHE_KEYS.USER_PROFILE);
  if (!cachedProfile) {
    return;
  }

  try {
    return JSON.parse(cachedProfile);
  } catch (e) {
    console.error(e);
  }
};
export const saveProfileToCache = (profile: Record<any, any>) => {
  localStorage.setItem(CACHE_KEYS.USER_PROFILE, JSON.stringify(profile));
};

import React from 'react';

import {CACHE_KEYS} from 'constants/cache-keys';
import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import useNavigation from 'hooks/app/use-navigation';
import {useFetcher} from 'hooks/use-fetcher';
import {getBambooCoin} from 'services/wallets';
import {Wallet} from 'types/Wallet';
import {formatDate} from 'utils/date';

import Loader from 'components/Loader/Loader';
import InfoPage from 'components/InfoPage/InfoPage';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import CopyText from 'components/Text/CopyText/CopyText';
import ClimateIconScan from 'components/Rewards/ClimatePartner/ClimateIconScan';

const BambooCoin = () => {
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});

  const {
    data: bambooCoin,
    isLoading,
    fetchData: refetchBambooCoin,
  } = useFetcher<Wallet>({
    fetcher: getBambooCoin,
    hideErrorToast: true,
    key: CACHE_KEYS.BAMBOO_COIN,
  });

  return (
    <InfoPage title={translate('sdk.web.bamboo.title')} onClose={handleGoBack}>
      <div>
        {isLoading ? (
          <Loader color="#222" />
        ) : (
          <>
            {bambooCoin ? (
              <div>
                <CopyText as="div">
                  {translate('sdk.web.bamboo.connected')}
                </CopyText>
                <br />
                <CopyText as="div">
                  <b>
                    {translate('sdk.web.bamboo.id', {
                      key: '{id}',
                      value: bambooCoin.identifier,
                    })}
                  </b>
                  <br />
                  <b>
                    {translate('sdk.web.bamboo.connected.date', {
                      key: '{date}',
                      value: formatDate({
                        date: bambooCoin.created_at,
                      }),
                    })}
                  </b>
                </CopyText>
              </div>
            ) : (
              <div className="center-block">
                <HeaderText as="div">
                  {translate('sdk.web.bamboo.scan')}
                </HeaderText>
                <br />
                <ClimateIconScan onComplete={refetchBambooCoin} />
              </div>
            )}
          </>
        )}
      </div>
    </InfoPage>
  );
};

export default BambooCoin;

import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes/customActivities';
import {WidgetProps} from 'hooks/services/use-custom-activity';
import ActivityList from 'components/CustomActivities/ActivityList';

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const ActivityWidget = ({activities, handleActivityComplete}: WidgetProps) => {
  const history = useHistory();

  return (
    <List>
      <ActivityList
        activities={activities}
        onCaretClick={({activity}) =>
          history.push(
            `${routes.href}/${activity.categoryId}?categoryName=${activity.categoryName}`,
          )
        }
        onActivityComplete={handleActivityComplete}
      />
    </List>
  );
};

export default ActivityWidget;

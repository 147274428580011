import React, {useCallback, useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';
import {useFormik} from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useToast} from 'hooks/use-toast';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {offset} from 'services/co2Emission';
import {ActivitySummary} from 'services/user';
import {formatNumber, formatToken, numberToKm} from 'utils/numbers';
import {addBoldText, addGlobalSquareCorners} from 'utils/theme';

import InfoPage from 'components/InfoPage/InfoPage';
import Button from 'components/Button/Button';
import Input from 'components/Form/Input';
import CopyText from 'components/Text/CopyText/CopyText';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import SuccessModal from './SuccessModal';
import NeutralModal from './NeutralModal';
import ConfirmationModal, {ConfirmationParams} from './ConfirmationModal';

import bannerImage from '../icons/banner.svg';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  overflow-y: scroll;

  .emission-offset {
    &__coins {
      text-align: center;
      margin-bottom: 10px;
    }

    &__emissions {
      text-align: center;
      margin-bottom: 40px;
    }

    &__input-group {
      margin-bottom: 15px;
    }

    &__info {
      margin-top: 32px;
      border-radius: 10px;
      padding: 10px;
      color: #000;

      &--success {
        background-color: #c0e4e8;
      }

      &--error {
        background-color: #ffafaf;
      }

      &--warning {
        background-color: #fee5aa;
      }

      ${addGlobalSquareCorners()}
    }

    b {
      ${(props) => addBoldText({props})}
    }
  }
`;

interface OffsetPageProps {
  activitySummary: ActivitySummary;
  onSuccess: () => any;
}

const OffsetPage = (props: OffsetPageProps) => {
  const {activitySummary, onSuccess} = props;
  const history = useHistory();
  const {handleGoBack} = useNavigation({
    replaceLocation:
      routes.DASHBOARD.CONTRIBUTIONS.WIDGETS_SEARCH.CO2_EMISSIONS,
  });
  const {translate} = useTranslations();
  const {getTokenText} = useTokenTranslation();
  const {apiErrors, setApiErrors, validate} = useFormValidation();
  const toast = useToast();
  const {userProfile} = useUserProfile();

  const [recoinsAvailable, setRecoinsAvailable] = useState(
    Math.floor(userProfile.user.recoins),
  );
  const [emissionsRemaining, setEmissionsRemaining] = useState(
    activitySummary?.user?.co2_pending || 0,
  );

  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean(false);
  const [isConfirmationOpen, showConfirmation, hideConfirmation] =
    useBoolean(false);
  const [isSuccessModalVbl, showSuccessModal, hideSuccessModal] =
    useBoolean(false);
  const [isNeutralModalVbl, showNeutralModal, hideNeutralModal] =
    useBoolean(false);

  useEffect(() => {
    setEmissionsRemaining(activitySummary?.user?.co2_pending || 0);
  }, [activitySummary]);

  const {emissions, availableCoins, hint} = useMemo(() => {
    if (!activitySummary) {
      return {
        hint: '',
        emissions: '',
        availableCoins: '',
      };
    }

    const emissions = translate('sdk.web.emissions.offset.emissions', {
      key: '{nr}',
      value: numberToKm({
        number: emissionsRemaining,
        ignoreThousandRule: true,
      }),
    });

    const availableCoins = translate('sdk.web.emissions.offset.available', [
      {
        key: '{token}',
        value: getTokenText(2),
      },
      {
        key: '{nr}',
        value: formatToken({
          number: recoinsAvailable,
        }),
      },
    ]);

    const getHintKeys = (value: number) => [
      {
        key: `{${value}-nr}`,
        value: value,
      },
      {
        key: `{${value}-token}`,
        value: getTokenText(value),
      },
      {
        key: `{${value}-co2}`,
        value: formatNumber({
          number: (value * activitySummary.user.co2_per_recoin) / 1000,
          dp: 0,
        }),
      },
    ];

    const hint = translate('sdk.web.emissions.offset.hint', [
      ...getHintKeys(1),
      ...getHintKeys(2),
    ]);

    return {
      hint,
      emissions,
      availableCoins,
    };
  }, [
    emissionsRemaining,
    recoinsAvailable,
    activitySummary,
    translate,
    getTokenText,
  ]);

  const validationSchema = yup.object().shape({
    coins: yup.number().required(
      translate('sdk.web.error.field.required', {
        key: '{field}',
        value: translate('sdk.web.emissions.offset.label'),
      }),
    ),
  });

  const handleConfirmation = useCallback(
    async (params?: ConfirmationParams) => {
      if (!params?.recoins) {
        hideConfirmation();
        return;
      }

      const {recoins} = params;
      startSubmitting();

      try {
        const response = await offset({
          recoins_spent: recoins,
          co2_pending: Math.min(
            params.recoins * activitySummary!.user.co2_per_recoin,
            emissionsRemaining,
          ),
        });

        setRecoinsAvailable(Math.floor(response.recoins_available));
        setEmissionsRemaining(response.co2_pending);
        await onSuccess();

        hideConfirmation();
        if (response.co2_pending === 0) {
          showNeutralModal();
        } else {
          showSuccessModal();
        }
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const fieldErrorKeys = e.response
          ? e.response?.data?.fieldErrorKeys
          : undefined;
        const message = translate(
          errorKey || 'sdk.web.emissions.offset.error.fallback',
        );
        toast.error(message);
        setApiErrors(fieldErrorKeys);
      } finally {
        stopSubmitting();
      }
    },
    [
      emissionsRemaining,
      activitySummary,
      toast,
      translate,
      onSuccess,
      showNeutralModal,
      showSuccessModal,
      hideConfirmation,
      startSubmitting,
      stopSubmitting,
      setApiErrors,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues: {
      coins: '',
    },
    onSubmit: showConfirmation,
    validate,
  });

  const handleSuccessModalClose = useCallback(
    (action?: {offset: boolean}) => {
      hideSuccessModal();
      formik.resetForm();

      if (!action?.offset) {
        handleGoBack();
      }
    },
    [formik, hideSuccessModal, handleGoBack],
  );

  const feedback = useMemo(() => {
    const co2PerRecoin = activitySummary?.user.co2_per_recoin || 0;
    const offset = +formik.values.coins * co2PerRecoin;
    const maxEmissions = Math.max(
      emissionsRemaining,
      Math.ceil(emissionsRemaining / co2PerRecoin) * co2PerRecoin,
    );
    const emissionsToOffset = numberToKm({
      number: Math.min(emissionsRemaining, offset),
      dp: 1,
      ignoreThousandRule: true,
    });

    if (+formik.values.coins > recoinsAvailable) {
      return {
        message: translate('sdk.web.emissions.offset.warning', {
          key: '{token}',
          value: getTokenText(2),
        }),
        status: 'error',
        emissionsToOffset,
      };
    } else if (offset > maxEmissions) {
      const amount = maxEmissions / co2PerRecoin;
      return {
        message: translate('sdk.web.emissions.offset.max.warning', [
          {
            key: '{amount}',
            value: formatNumber({
              number: amount,
              dp: 1,
              ignoreThousandRule: true,
            }),
          },
          {
            key: '{token}',
            value: getTokenText(amount),
          },
        ]),
        status: 'warning',
        emissionsToOffset,
      };
    }

    return {
      message: translate('sdk.web.emissions.offset.compensation', {
        key: '{co2}',
        value: emissionsToOffset,
      }),
      status: 'success',
      emissionsToOffset,
    };
  }, [
    emissionsRemaining,
    recoinsAvailable,
    activitySummary,
    translate,
    getTokenText,
    formik,
  ]);

  return (
    <InfoPage
      title={translate('sdk.web.emissions.offset.header')}
      onClose={handleGoBack}>
      <Wrapper className="emission-offset" onSubmit={formik.handleSubmit}>
        <div>
          <CopyText
            as="div"
            className="emission-offset__coins"
            dangerouslySetInnerHTML={{__html: availableCoins}}
          />
          <img className="mb-base" src={bannerImage} alt="" />
          <CopyText
            as="div"
            className="emission-offset__emissions"
            dangerouslySetInnerHTML={{__html: emissions}}
          />
          <div className="emission-offset__input-group">
            <label>
              <b>{translate('sdk.web.emissions.offset.label')}</b>
            </label>
            <Input
              name="coins"
              type="number"
              min="1"
              step="1"
              value={formik.values.coins}
              formik={formik}
              apiErrors={apiErrors}
              onChange={formik.handleChange}
            />
          </div>
          {emissionsRemaining >= activitySummary?.user.co2_per_recoin && (
            <CopyTextSm dangerouslySetInnerHTML={{__html: hint}} />
          )}
          {!!formik.values.coins && (
            <CopyText
              as="div"
              className={classnames(
                'emission-offset__info',
                `emission-offset__info--${feedback.status}`,
              )}>
              {feedback.message}
            </CopyText>
          )}
        </div>
        <Button
          isLoading={isSubmitting}
          disabled={!formik.isValid || feedback.status !== 'success'}
          type="submit">
          {translate('sdk.web.emissions.offset.cta')}
        </Button>
      </Wrapper>
      <ConfirmationModal
        recoins={+formik.values.coins}
        emissionsToOffset={feedback.emissionsToOffset as string}
        isVisible={isConfirmationOpen}
        isSubmitting={isSubmitting}
        onConfirmation={handleConfirmation}
      />
      <SuccessModal
        emissionsRemaining={emissionsRemaining}
        recoinsAvailable={recoinsAvailable}
        isVisible={isSuccessModalVbl}
        onClose={handleSuccessModalClose}
      />
      <NeutralModal
        isVisible={isNeutralModalVbl}
        onClose={() => {
          hideNeutralModal();
          history.push(
            routes.DASHBOARD.CONTRIBUTIONS.WIDGETS.CO2_EMISSIONS.href,
          );
        }}
      />
    </InfoPage>
  );
};

export default OffsetPage;

import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import routes from 'config/routes';
import {useOrganization} from 'hooks/use-organization';
import {useTranslations} from 'hooks/use-translations';
import {useInviteFriendsTranslations} from './hooks/useInviteFriendsTranslations';
import {sendMessageToNativeApp} from 'services/native-api';
import {ReferralsData} from 'services/user';

import CopyText from 'components/Text/CopyText/CopyText';
import Loader from 'components/Loader/Loader';
import PromptWrapper, {
  Title,
} from 'components/PromptModal/PromptWrapper/PromptWrapper';

import {ReactComponent as InfoIcon} from 'components/ClimateWidgets/InviteFriends/icons/info.svg';
import {ReactComponent as ShareIcon} from './icons/share.svg';

const Content = styled.div`
  padding: 0 20px;
`;

const InfoContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const InfoLink = styled(Link)`
  text-align: right;
  display: inline-block;
  width: 165px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const Text = styled(CopyText)`
  display: block;
  line-height: 20px;
  text-align: center;
`;

const Description = styled.div`
  margin-bottom: 40px;
`;

const GetRewards = styled.div`
  margin-bottom: 20px;
`;

const ProgressBarContainer = styled.div`
  margin: 10px 0;
  border-radius: 10px;
  background-color: ${(props) =>
    props.theme.components.inviteFriends.progressBarBgColor ||
    props.theme.colors.white};
  width: 100%;
`;

const ProgressBar = styled.div<{percent: number}>`
  border-radius: 10px;
  background-color: ${(props) =>
    props.theme.components.inviteFriends.progressBarColor ||
    props.theme.colors.primary};
  width: ${(props) => props.percent}%;
  height: 10px;
`;

const QrCodeContainer = styled.div`
  text-align: center;
  position: relative;
  margin-bottom: 20px;
`;

const QrCode = styled.img`
  width: 116px;
  display: inline-block;
  border-radius: 5px;
`;

const Share = styled.div`
  width: 40px;
  display: inline-block;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% + 75px);
  stroke: ${(props) =>
    props.theme.components.inviteFriends.shareIconColor ||
    props.theme.colors.black};
`;

const Modal = (props: any) => {
  const {activitySummary, isVisible, onClose} = props;
  const {translate} = useTranslations();
  const {getRewardsText} = useInviteFriendsTranslations();
  const {organizationConfig} = useOrganization();

  const inviteFriendsConfig = organizationConfig.theme.components.inviteFriends;

  const referralsData = (
    activitySummary ? activitySummary.referralsData : {}
  ) as ReferralsData;

  const invitesLeftMoreKey = referralsData.referralsGotten === 0 ? '' : '.more';
  const invitesLeftKey =
    referralsData.referralsNeeded === 1
      ? `sdk.web.contributions.inviteFriends.invitesLeft.singular${invitesLeftMoreKey}`
      : `sdk.web.contributions.inviteFriends.invitesLeft.plural${invitesLeftMoreKey}`;

  const rewardsText = getRewardsText({activitySummary});

  const handleReferralShare = () => {
    const message = {
      type: 'share_referral',
      share_message: referralsData.shareText,
      qr_code: referralsData.qrCode || null,
      user_referral_link: referralsData.referralLink,
    };
    sendMessageToNativeApp({message});
  };

  return (
    <PromptWrapper isVisible={isVisible} onClose={onClose}>
      <Content>
        <Title>{translate('sdk.web.contributions.inviteFriends.title')}</Title>
        <InfoContainer>
          <InfoLink to={routes.SETTINGS.ABOUT_INVITE_FRIENDS.href}>
            {inviteFriendsConfig?.InfoIcon ? (
              <inviteFriendsConfig.InfoIcon />
            ) : (
              <InfoIcon />
            )}
          </InfoLink>
        </InfoContainer>
        <Description>
          <Text>
            {translate('sdk.web.contributions.inviteFriends.description')}
          </Text>
        </Description>
        {!!activitySummary ? (
          <>
            <Text>
              {translate(invitesLeftKey, {
                key: '{nr}',
                value: referralsData.referralsNeeded,
              })}
            </Text>
            <ProgressBarContainer>
              <ProgressBar percent={referralsData.percent} />
            </ProgressBarContainer>
            <GetRewards>
              <Text>
                {translate('sdk.web.contributions.inviteFriends.getRewards', {
                  key: '{reward}',
                  value: rewardsText,
                })}
              </Text>
            </GetRewards>
            <QrCodeContainer>
              <QrCode src={referralsData.qrCode} />
              <Share onClick={handleReferralShare}>
                <ShareIcon />
              </Share>
            </QrCodeContainer>
          </>
        ) : (
          <Loader sm={true} />
        )}
      </Content>
    </PromptWrapper>
  );
};

export default Modal;

import styled from 'styled-components';

import {addBoldText} from 'utils/theme';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Label = styled(CopyTextSm)`
  display: block;
  text-align: left;
  margin-bottom: 15px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

export default Label;

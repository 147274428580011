import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {AnimatePresence} from 'framer-motion';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

import routes from 'config/routes/rewards';
import googleMapConfig from 'config/googleMap';
import {CACHE_KEYS} from 'constants/cache-keys';
import {useOrganization} from 'hooks/use-organization';
import {useTranslations} from 'hooks/use-translations';
import {IconVariants, useClimateMapIcon} from 'hooks/use-climate-map-icon';
import useClimatePartnerLocation from 'hooks/use-climate-partner-location';
import {useFetcher} from 'hooks/use-fetcher';
import {getVouchers} from 'services/vouchers';
import {noop} from 'utils/functions';
import {OptimizedDistance} from 'utils/numbers';

import Loader from 'components/Loader/Loader';
import MapInfo from 'components/MapInfo/MapInfo';
import VoucherCard from '../Vouchers/VoucherCard/VoucherCard';

import {Location} from 'types/Location';
import {ClimatePartner, PurchasedVoucher, Voucher} from 'types/Offers';
import CurrentLocation from 'icons/CurrentLocation.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  position: relative;
`;

const VouchersList = styled.div`
  max-height: 330px;
  overflow-y: auto;
`;

const VoucherCardContent = styled.div`
  margin: 0 auto;
  position: relative;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const containerStyle = {
  width: '100%',
  height: '100%',
};

export type ClimatePartnerData = {
  vouchers: PurchasedVoucher[];
  data: ClimatePartner;
  location: OptimizedDistance;
};

type OffersMapProps = {
  vouchers: Voucher[];
  climatePartners: ClimatePartnerData[];
  location?: Location;
  mapCenter: any;
  userLocation: any;
};

const OffersMap = (props: OffersMapProps) => {
  const {climatePartners, userLocation, mapCenter} = props;
  const {isLoaded, loadError} = useJsApiLoader({
    googleMapsApiKey: googleMapConfig.key,
  });
  const {translate} = useTranslations();
  const {getIconString} = useClimateMapIcon();
  const {organizationConfig} = useOrganization();

  const [selectedPartner, setSelectedPartner] = useState<ClimatePartnerData>();
  const {address, handleDirectionRequest} = useClimatePartnerLocation({
    climatePartner: selectedPartner?.data,
  });

  const {isLoading, fetchDataWithKey, data} = useFetcher<Voucher[]>({
    fetcher: getVouchers,
    // key: `${CACHE_KEYS.CLIMATE_PARTNER_OFFERS}/${selectedPartner?.data?.id}`,
    initialValue: [],
    preventFetch: true,
  });

  useEffect(
    () => {
      if (!selectedPartner?.data?.id) {
        return;
      }

      const id = selectedPartner.data?.id;
      const key = `${CACHE_KEYS.CLIMATE_PARTNER_OFFERS}/${id}`;

      fetchDataWithKey({
        key,
        newParams: {climate_partner_id: id},
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPartner],
  );

  const vouchers = useMemo(
    () =>
      data.map((offer) => ({
        id: offer.id,
        offer,
      })),
    [data],
  );

  if (loadError) {
    return <div>{translate('sdk.web.offers.map.error')}</div>;
  }

  const handlePartnerSelect = (partner: ClimatePartnerData) => {
    setSelectedPartner(partner);
  };

  const handlePartnerClose = () => {
    setSelectedPartner(undefined);
  };

  return (
    <Wrapper>
      {isLoaded && (
        <GoogleMap
          center={mapCenter}
          mapContainerStyle={containerStyle}
          zoom={14}>
          {climatePartners.map((climatePartner) => (
            <Marker
              key={climatePartner.data.id}
              position={{
                lat: climatePartner.data.latitude,
                lng: climatePartner.data.longitude,
              }}
              icon={getIconString({
                icon: climatePartner.data.business_type.slug,
                variant:
                  selectedPartner?.data.id === climatePartner.data.id
                    ? IconVariants.INVERTED_PRIMARY
                    : IconVariants.PRIMARY,
              })}
              onClick={() => handlePartnerSelect(climatePartner)}
            />
          ))}
          {userLocation && (
            <Marker position={userLocation} icon={CurrentLocation} />
          )}
        </GoogleMap>
      )}
      <AnimatePresence>
        {selectedPartner && (
          <MapInfo
            key={selectedPartner?.data.id}
            name={selectedPartner.data.name}
            image={selectedPartner.data.logo_file?.url}
            address={address}
            onDirectionRequest={handleDirectionRequest}
            onClose={handlePartnerClose}
            location={selectedPartner.location}>
            <VouchersList>
              {isLoading ? (
                <LoaderWrapper>
                  <Loader color="#222" sm />
                </LoaderWrapper>
              ) : (
                vouchers?.map((voucher) => (
                  <VoucherCardContent key={voucher.id}>
                    <VoucherCard
                      bgColor={
                        organizationConfig.theme.components.climatePartnerMap
                          ?.voucherBgColor
                      }
                      voucher={voucher}
                      voucherUrl={`${routes.REWARDS.DEALS.href}/voucher/${voucher.offer.id}`}
                      isDeleteActive={false}
                      showClimateIcon={true}
                      hideLocation={true}
                      toggleDeleteActions={noop}
                      removeVoucher={{title: '', desc: '', delete: noop}}
                      reloadVouchers={noop}
                    />
                  </VoucherCardContent>
                ))
              )}
            </VouchersList>
          </MapInfo>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default React.memo(OffersMap, (props, nextProps) => {
  return (
    nextProps.climatePartners.length === 0 &&
    props.climatePartners.length !== nextProps.climatePartners.length &&
    props.location?.longitude !== nextProps.location?.longitude &&
    props.location?.latitude !== nextProps.location?.latitude
  );
});

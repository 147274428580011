import {useCallback, useEffect, useRef} from 'react';

const NATIVE_TYPE = 'Native_App_Response';

type UseNativeListenerProps = {
  callback: (detail: any) => void;
  event?: string;
  preventListen?: boolean;
  isOneTime?: boolean;
};

export const useNativeListener = (props: UseNativeListenerProps) => {
  const {callback, event, preventListen, isOneTime} = props;
  const listenerRef = useRef<EventListener>();

  const stopListening = useCallback(() => {
    if (listenerRef.current) {
      window.removeEventListener(NATIVE_TYPE, listenerRef.current);
      listenerRef.current = undefined;
    }
  }, []);

  const startListening = useCallback(() => {
    if (listenerRef.current) {
      return;
    }

    listenerRef.current = (e: any) => {
      const {detail} = e;
      if (event && detail.type !== event) {
        return;
      }

      callback(detail);

      if (isOneTime) {
        stopListening();
      }
    };

    window.addEventListener(NATIVE_TYPE, listenerRef.current);
  }, [callback, event, isOneTime, stopListening]);

  useEffect(
    () => {
      if (preventListen) {
        return;
      }

      startListening();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preventListen],
  );

  useEffect(() => {
    return stopListening;
  }, [stopListening]);

  return {
    startListening,
    stopListening,
  };
};

import React from 'react';
import styled from 'styled-components';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import useNavigation from 'hooks/app/use-navigation';

import InfoPage from 'components/InfoPage/InfoPage';
import LotteryForm from 'components/Rewards/Deals/LotteryForm';

const Wrapper = styled.div`
  // height: 100%;
  margin: 0 -20px;
  width: calc(100% + 40px);
  text-align: center;
`;
const LotteryFormPage = () => {
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});

  return (
    <InfoPage
      title={translate('sdk.web.settings.lots.form.title')}
      onClose={handleGoBack}>
      <Wrapper>
        <LotteryForm
          title=""
          subtitle={translate('sdk.web.settings.lots.form.subtitle')}
          onClose={handleGoBack}
        />
      </Wrapper>
    </InfoPage>
  );
};

export default LotteryFormPage;

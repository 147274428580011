import {UNIT_TYPES} from 'constants/unit';

export enum CHALLENGE_TYPES {
  CO2 = 1,
  DISTANCE = 2,
  RECOINS = 3,
  STEPS = 4,
}

export const CHALLENGE_TYPES_UNITS: Record<
  CHALLENGE_TYPES,
  {
    unit: typeof UNIT_TYPES[keyof typeof UNIT_TYPES];
    denominator: number;
    field: string;
  }
> = {
  1: {
    unit: UNIT_TYPES.CO2,
    denominator: 1000,
    field: 'co2_avoided',
  },
  2: {
    unit: UNIT_TYPES.DISTANCE,
    denominator: 1000,
    field: 'meters',
  },
  3: {
    unit: UNIT_TYPES.RECOINS,
    denominator: 1,
    field: 'recoins_earned',
  },
  4: {
    unit: UNIT_TYPES.STEPS,
    denominator: 1,
    field: 'steps',
  },
};

import merge from 'lodash/merge';

import base from 'config/organizations/base';

const config = {
  name: 'muenster',
  theme: {
    fonts: {},
    colors: {},
    components: {
      nav: {
        routes: ['impact', 'offers', 'teams', 'notifications', 'more'],
      },
    },
  },
  translations: {
    tokenTitle: 'sdk.web.muenster.contributions.your.token',
    co2Needed: 'sdk.web.muenster.contributions.savings.left.for.token',
    fullTokenText: 'sdk.web.muenster.contributions.full.token.text',
    offersTitle: 'sdk.web.muenster.offers.your.token',
    shortTokenSingular: 'sdk.web.muenster.offers.token.required.singular',
    shortTokenPlural: 'sdk.web.muenster.offers.token.required.plural',
    rewardType1Singular:
      'sdk.web.muenster.contributions.inviteFriends.rewardType1.singular',
    rewardType1Plural:
      'sdk.web.muenster.contributions.inviteFriends.rewardType1.plural',
    notEnoughTokens: 'sdk.web.muenster.offers.not.enough.tokens',
    aboutTokenLabel: 'sdk.web.muenster.settings.about.title',
    onBoarding: {
      one: {
        title: 'sdk.web.muenster.onboarding.one.title',
        content: 'sdk.web.muenster.onboarding.one.text',
      },
      two: {
        title: 'sdk.web.muenster.onboarding.two.title',
        content: 'sdk.web.muenster.onboarding.two.text',
      },
      three: {
        title: 'sdk.web.muenster.onboarding.three.title',
        content: 'sdk.web.muenster.onboarding.three.text',
      },
      privacy: {
        title: 'sdk.web.onboarding.privacy.title',
        content: 'sdk.web.onboarding.privacy.text',
        confirmLink: 'sdk.web.onboarding.privacy.link',
      },
      gps: {
        title: 'sdk.web.onboarding.gps.title',
        content: 'sdk.web.onboarding.gps.text',
      },
    },
    competition: {
      title: 'sdk.web.teams.germany.leaderboard.title',
      subtitle: 'sdk.web.teams.germany.leaderboard.subtitle',
    },
  },
  features: {
    hideAppClose: true,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCo2Compensation: true,
    hasCustomActivities: true,
    hasMetering: true,
    hasBadges: true,
    hasNewFAQ: true,
    hasCo2Emissions: true,
    hasNotifications: true,
    hasWidgetCustomization: true,
    hasWallets: true,
    hasNewQRScanner: true,
    hasVehicleSettings: true,
    hasClimateTreasure: true,
    hasUserRanking: true,
    hasUserRankingMobility: true,
    hideUserRankingParticipants: true,
    hideUserRankingInCities: true,
    stage: {},
  },
  applangaTag: '5f4ae66d91adce6141e1fe42',
  matomoTrackingId: 2,
  tokenType: 'Thaler',
};

export default merge({}, base, config);

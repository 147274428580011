import React, {useCallback, useState} from 'react';
import styled from 'styled-components';

import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';
import {toggleWidget} from 'services/widgets-customization';
import {
  WidgetGroup as IWidgetGroup,
  WidgetGroupType,
} from 'types/WidgetCustomization';
import {useBoolean} from 'hooks/utils/use-boolean';

import InfoPage from 'components/InfoPage/InfoPage';
import CopyText from 'components/Text/CopyText/CopyText';
import Radio from 'components/Form/Radio';
import PromptModal from 'components/PromptModal/PromptModal';

const Wrapper = styled.div``;

const initialWidgetState = {
  widget: undefined,
  enabled: false,
  disableSuccess: false,
};

interface WidgetGroupProps {
  widgetGroup: IWidgetGroup;
  onUpdate: (data: IWidgetGroup) => any;
}

const WidgetGroup = (props: WidgetGroupProps) => {
  const {widgetGroup, onUpdate} = props;
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation();

  const [isUpdating, startUpdating, stopUpdating] = useBoolean();
  const [selectedWidget, setSelectedWidget] = useState<{
    widget: WidgetGroupType | undefined;
    enabled: boolean;
    disableSuccess: boolean;
  }>(initialWidgetState);

  const handleReset = useCallback(() => {
    setSelectedWidget(initialWidgetState);
  }, []);

  const handleUpdate = useCallback(
    async (params: {onSuccess?: () => any}) => {
      const {onSuccess} = params || {};
      startUpdating();

      try {
        const data = await toggleWidget({
          enabled: selectedWidget.enabled,
          type: selectedWidget.widget!.type,
        });

        await onUpdate({
          ...widgetGroup,
          types: widgetGroup.types.map((widgetType) => ({
            ...widgetType,
            enabled:
              widgetType.type === data.type ? data.enabled : widgetType.enabled,
          })),
        });

        if (!selectedWidget.enabled) {
          setSelectedWidget((widget) => ({
            ...widget,
            disableSuccess: true,
          }));
        } else {
          handleReset();
        }

        onSuccess && onSuccess();
      } catch (e) {
        console.error(e);
      } finally {
        stopUpdating();
      }
    },
    [
      selectedWidget,
      widgetGroup,
      handleReset,
      onUpdate,
      startUpdating,
      stopUpdating,
    ],
  );

  if (!widgetGroup) {
    return null;
  }

  return (
    <InfoPage title={widgetGroup.title} onClose={handleGoBack}>
      <Wrapper>
        <CopyText as="div" className="mb-base">
          <b>{widgetGroup.subtitle}</b>
        </CopyText>
        <div>
          {widgetGroup.types.map((widget) => (
            <div key={widget.type} className="mb-base">
              <Radio
                type="checkbox"
                name={widget.type}
                list={[
                  {
                    id: widget.type,
                    value: 'true',
                    label: widget.title,
                  },
                ]}
                value={String(widget.enabled) + ''}
                onChange={(e) => {
                  setSelectedWidget({
                    widget,
                    disableSuccess: false,
                    enabled: e.target.checked,
                  });
                }}
                LabelComponent={CopyText}
              />
            </div>
          ))}
        </div>
      </Wrapper>
      {selectedWidget.widget && (
        <>
          <PromptModal
            isVisible={!selectedWidget.enabled}
            title={selectedWidget.widget.prompts.disable.title}
            textContent={selectedWidget.widget.prompts.disable.text}
            image={selectedWidget.widget.prompts.disable.banner}
            btnText={
              selectedWidget.widget.prompts.disable.cta ||
              translate('sdk.web.dialog.box.confirm')
            }
            onBtnClick={handleUpdate}
            isButtonLoading={isUpdating}
            onClose={handleReset}
          />
          <PromptModal
            isVisible={selectedWidget.disableSuccess}
            title={selectedWidget.widget.prompts.disableSuccess.title}
            textContent={selectedWidget.widget.prompts.disableSuccess.text}
            image={selectedWidget.widget.prompts.disableSuccess.banner}
            btnText={
              selectedWidget.widget.prompts.disableSuccess.cta ||
              translate('sdk.web.dialog.box.ok')
            }
            onClose={handleReset}
            onBtnClick={handleReset}
          />
          <PromptModal
            isVisible={selectedWidget.enabled}
            title={selectedWidget.widget.prompts.enable.title}
            textContent={selectedWidget.widget.prompts.enable.text}
            image={selectedWidget.widget.prompts.enable.banner}
            btnText={
              selectedWidget.widget.prompts.enable.cta ||
              translate('sdk.web.dialog.box.confirm')
            }
            onBtnClick={() =>
              handleUpdate({
                onSuccess: selectedWidget.widget!.prompts.enable.onSuccess,
              })
            }
            moreInfo={{
              text: selectedWidget.widget.prompts.enable.secondaryCta,
              onClick: handleUpdate,
            }}
            isButtonLoading={isUpdating}
            onClose={handleReset}
          />
        </>
      )}
    </InfoPage>
  );
};

export default WidgetGroup;

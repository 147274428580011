import styled from 'styled-components';
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  textAlign,
  TextAlignProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  zIndex,
  ZIndexProps,
  shadow,
  ShadowProps,
  compose,
} from 'styled-system';

export type WrapperProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TextAlignProps &
  DisplayProps &
  FlexboxProps &
  BorderProps &
  OverflowProps &
  PositionProps &
  ZIndexProps &
  ShadowProps;

const Wrapper = styled('div')(
  compose(
    color,
    space,
    layout,
    textAlign,
    display,
    flexbox,
    border,
    overflow,
    position,
    zIndex,
    shadow,
  ),
);

export default Wrapper;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useFetcher} from 'hooks/use-fetcher';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useOrganization} from 'hooks/use-organization';
import {useUserProfile} from 'hooks/use-user-profile';
import {useAddNext} from 'hooks/utils/use-links';
import {trackOfferDetailsView} from 'services/analytics';
import {purchaseLottery} from 'services/lots';
import {getVoucher, purchaseVoucher} from 'services/vouchers';
import {OfferTypes} from 'types/Offers';
import {AccountType} from 'types/User';

import {UpdateVoucherWishlist} from 'components/Rewards/Deals/WishList/use-wishlist';
import DetailsNavigation from 'components/DetailsNavigation/DetailsNavigation';
import OfferDetails from 'components/Rewards/Deals/OfferDetails/OfferDetails';
import OfferRestrictions from 'components/Rewards/Deals/OfferRestrictions';
import Modal from 'components/Modal/Modal';
import LotteryPrizes from 'components/Rewards/Lots/LotteryPrizes/LotteryPrizes';
import NewLot from 'components/Rewards/Lots/NewLot/NewLot';
import Loader from 'components/Loader/Loader';
import DialogBox from 'components/DialogBox/DialogBox';
import LotteryForm from 'components/Rewards/Deals/LotteryForm';

const SKIP_HISTORY_KEY = 'skip-history';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) =>
    props.theme.components.offers.bodyBgColor || props.theme.colors.secondary};
`;

const Body = styled.div`
  background: ${(props) =>
    props.theme.components.offers.bodyBgColor || props.theme.colors.secondary};
  flex-grow: 1;
  display: flex;
  padding: 73px 10px 10px;
  min-height: 100vh;
`;

const Prizes = styled.div`
  margin-bottom: 15px;
`;

const OfferPage = (props: any) => {
  const location = useLocation();
  const params = useParams<{id: string; type: string}>();
  const history = useHistory();

  const {hasUserAccount, hasLotteryForm} = useOrganization();
  const {userProfile} = useUserProfile();
  const {updateBg} = useBodyBg({type: 'primary'});
  const {translate} = useTranslations();
  const {getShortTokenText} = useTokenTranslation();
  const {organizationConfig, setLastVoucherUpdate} =
    useContext(SettingsContext);
  const registerURL = useAddNext({
    url: routes.SETTINGS.REGISTER.href,
    next: `${location.pathname}?${SKIP_HISTORY_KEY}=true`,
  });

  const {
    lotteries,
    vouchers,
    isLoading,
    userRecoins,
    updateVoucherWishlist,
  }: {
    vouchers: Array<any>;
    lotteries: Array<any>;
    isLoading: boolean;
    userRecoins: number;
    updateVoucherWishlist: UpdateVoucherWishlist;
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newLot, setNewLot] = useState(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isNotEnoughHeartsDialogOpen, setIsNotEnoughHeartsDialogOpen] =
    useState(false);
  const [isLotAccountCheckOpen, showLotAccountCheck, hideLotAccountCheck] =
    useBoolean();
  const [isLotsFormOpen, showLotsForm, hideLotsForm] = useBoolean(false);

  const {id, type} = params;

  const {skipHistory} = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return {
      skipHistory: searchParams.get(SKIP_HISTORY_KEY),
    };
  }, [location]);

  const {promptKeys, isScanned, isLottery, deal, purchaseOffer} =
    useMemo(() => {
      const isLottery = type === OfferTypes.LOTTERY;
      const isScanned =
        !isLottery && location.search && location.search.includes('scanned');

      const purchaseOffer = isLottery ? purchaseLottery : purchaseVoucher;

      const deal = isLottery
        ? (lotteries || []).find(
            (lottery: any) => lottery.id.toString() === id.toString(),
          )
        : (vouchers || []).find(
            (voucher: any) => voucher.id.toString() === id.toString(),
          );

      const promptKeys = {
        title: isScanned ? 'sdk.web.offers.get.voucher' : '',
        message: isLottery
          ? 'sdk.web.lots.confirmation.prompt'
          : isScanned
          ? 'sdk.web.vouchers.activate.confirm'
          : 'sdk.web.vouchers.confirmation.prompt',
        cta: isScanned ? 'sdk.web.dialog.box.confirm' : 'sdk.web.dialog.box.ok',
      };

      return {
        isLottery,
        promptKeys,
        purchaseOffer,
        isScanned,
        deal,
      };
    }, [location, type, id, lotteries, vouchers]);

  const {
    data: voucher,
    isLoading: isVoucherLoading,
    updateDataById: updateVoucherById,
  } = useFetcher({
    fetcher: getVoucher,
    params: {id},
    key: `voucher-${id}`,
    preventFetch: isLottery || deal,
  });

  useEffect(
    () => {
      if (isLottery) {
        return;
      }

      trackOfferDetailsView(Number(id));
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [],
  );

  const updateSingleVoucherWishlist: UpdateVoucherWishlist = useCallback(
    ({id, wishlisted}) => {
      updateVoucherById({
        idKey: 'id',
        id,
        updates: {
          wishlisted,
        },
      });
    },
    [updateVoucherById],
  );

  const {offer, offerData} = useMemo(() => {
    const offer = deal || voucher;
    const offerData = {
      isLoading: isLoading || isVoucherLoading,
      termsLink: `${routes.DASHBOARD.REWARDS.DEALS.href}/${type}/terms/${id}`,
      isRecoinEnough: false,
      updateVoucherWishlist: deal
        ? updateVoucherWishlist
        : updateSingleVoucherWishlist,
    };

    offerData.isRecoinEnough =
      offer && userRecoins >= (isLottery ? offer.recoins : offer.cost);

    return {offer, offerData};
  }, [
    type,
    voucher,
    deal,
    userRecoins,
    id,
    isLottery,
    isLoading,
    isVoucherLoading,
    updateVoucherWishlist,
    updateSingleVoucherWishlist,
  ]);

  const onClose = () => {
    setNewLot(null);
    updateBg({type: 'primary'});

    if (history.length > 2 && !skipHistory) {
      history.goBack();
    } else {
      history.push(routes.DASHBOARD.REWARDS.DEALS.href);
    }
  };

  const onOfferPurchase = async () => {
    if (!offerData.isRecoinEnough) {
      setIsNotEnoughHeartsDialogOpen(true);
      return;
    }

    if (type === OfferTypes.LOTTERY) {
      if (hasLotteryForm) {
        if (!userProfile!.user.guest_email) {
          showLotsForm();
          return;
        }
      } else if (
        hasUserAccount &&
        userProfile!.user.account_type !== AccountType.USER
      ) {
        showLotAccountCheck();
        return;
      }
    }

    setIsConfirmationDialogOpen(true);
  };

  const onPurchaseConfirmation = async (makePurchase: boolean) => {
    setIsConfirmationDialogOpen(false);
    if (!makePurchase) {
      return;
    }

    setIsSubmitting(true);

    try {
      const dataKey = isLottery ? 'lottery_id' : 'voucher_id';
      const data = {
        [dataKey]: offer.id,
      };

      const purchaseResponse: any = await purchaseOffer({data});
      setIsSubmitting(false);
      setLastVoucherUpdate(Date.now().toString());

      if (isLottery) {
        const newLot = {
          ...purchaseResponse.data,
          lottery: offer,
        };

        // @ts-ignore
        setNewLot(newLot);
      } else {
        history.push(
          `${routes.DASHBOARD.REWARDS.VOUCHERS.NEW.href}/${purchaseResponse.id}`,
        );
      }
    } catch (e: any) {
      const errorKey = e.response ? e.response?.data?.errorKey : '';
      const message =
        translate(
          errorKey || 'sdk.web.offers.default.purchase.error.message',
        ) ||
        e.response?.data?.errorMessage ||
        '';
      setErrorMessage(message);
      setIsSubmitting(false);
    }
  };

  const handleLotAccountCheck = useCallback(
    (register: boolean) => {
      if (register) {
        history.push(registerURL);
        return;
      }

      hideLotAccountCheck();
    },
    [hideLotAccountCheck, history, registerURL],
  );

  return (
    <Wrapper>
      <Modal>
        {!!newLot ? (
          <NewLot lot={newLot} onClose={onClose} />
        ) : (
          <>
            <DetailsNavigation
              onClose={onClose}
              text={translate('sdk.web.offers.description')}
            />
            <Body>
              {offer && !offerData.isLoading && (
                <OfferDetails
                  deal={offer}
                  isLottery={isLottery}
                  termsLink={offerData.termsLink}
                  isSubmitting={isSubmitting}
                  userRecoins={userRecoins}
                  isRecoinEnough={offerData.isRecoinEnough}
                  isScanned={isScanned}
                  onOfferPurchase={onOfferPurchase}
                  updateVoucherWishlist={offerData.updateVoucherWishlist}>
                  {isLottery && (
                    <Prizes>
                      <LotteryPrizes lottery={offer} />
                    </Prizes>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: offer.description,
                    }}
                  />
                  <OfferRestrictions offer={offer} />
                </OfferDetails>
              )}
              {isConfirmationDialogOpen && (
                <DialogBox
                  onConfirmation={onPurchaseConfirmation}
                  title={translate(promptKeys.title) || undefined}
                  promptMessage={translate(promptKeys.message)}
                  yesText={translate(promptKeys.cta)}
                  noText={translate('sdk.web.dialog.box.cancel')}
                  isLoading={isSubmitting}
                />
              )}
              {isNotEnoughHeartsDialogOpen && (
                <DialogBox
                  onConfirmation={() => setIsNotEnoughHeartsDialogOpen(false)}
                  promptMessage={translate(
                    organizationConfig.translations.notEnoughTokens,
                    {
                      key: '{token}',
                      value: getShortTokenText(),
                    },
                  )}
                  singleText={translate('sdk.web.dialog.box.ok')}
                />
              )}
              {errorMessage && (
                <DialogBox
                  onConfirmation={() => setErrorMessage('')}
                  promptMessage={errorMessage}
                  singleText={translate('sdk.web.dialog.box.ok')}
                />
              )}
              {offerData.isLoading && <Loader />}
            </Body>
            {isLotAccountCheckOpen && (
              <DialogBox
                promptMessage={translate('sdk.web.offers.lots.auth.required')}
                noText={translate('sdk.web.offers.lots.auth.cancel')}
                yesText={translate('sdk.web.offers.lots.auth.cta')}
                onConfirmation={handleLotAccountCheck}
              />
            )}
            {isLotsFormOpen && (
              <LotteryForm
                title={translate('sdk.web.offers.lots.form.title')}
                subtitle={translate('sdk.web.offers.lots.form.subtitle')}
                showSuccess={true}
                usePrompt={true}
                onClose={hideLotsForm}
                onComplete={() => {
                  hideLotsForm();
                  onOfferPurchase();
                }}
              />
            )}
          </>
        )}
      </Modal>
    </Wrapper>
  );
};

export default OfferPage;

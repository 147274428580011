import React, {useMemo} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes/co2Emissions';
import {useTranslations} from 'hooks/use-translations';
import {ActivitySummary} from 'services/user';
import {numberToKm} from 'utils/numbers';

import CopyText from 'components/Text/CopyText/CopyText';
import Button from 'components/Button/Button';
import {AltButton} from 'components/Button/AltButton';
import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import WidgetLoader from 'components/ClimateWidgets/WidgetLoader';
import {ReactComponent as InfoIcon} from 'icons/info-white.svg';

import cloudIcon from './icons/cloud.svg';
import successIcon from './icons/success.svg';
import unicornIcon from './icons/unicorn.svg';

const Wrapper = styled.div`
  padding-top: 20px;

  .emission-wgt {
    &__title {
      text-align: center;
      margin-bottom: 10px;

      &--mb-lg {
        margin-bottom: 40px;
      }
    }

    &__content {
      display: flex;
      width: 100%;
      margin-bottom: 20px;

      &--center {
        justify-content: center;
      }

      &-child {
        display: flex;
        width: 50%;
        justify-content: center;
      }
    }

    &__summary {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-self: stretch;
      align-items: center;
    }

    &__icon {
      max-width: 110px;
    }

    &__cta {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__btn {
      width: calc(50% - 3.5px);
      border-radius: 5px;
      padding: 10px 2px;
    }
  }
`;

const InfoIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 1;
  width: 26px;
  height: 100%;
  display: flex;
  align-items: center;
`;

interface Co2EmissionsProps {
  activitySummary?: ActivitySummary;
}

const Co2Emissions = (props: Co2EmissionsProps) => {
  const {activitySummary} = props;
  const history = useHistory();
  const {translate} = useTranslations();

  const {climateNeutral, title, co2Pending} = useMemo(() => {
    if (!activitySummary) {
      return {
        climateNeutral: false,
        title: '',
        co2Pending: 0,
      };
    }

    const climateNeutral = activitySummary.user.co2_pending <= 0;
    const co2Pending = numberToKm({
      number: activitySummary.user.co2_pending,
      dp: 1,
      ignoreThousandRule: true,
    });

    return {
      climateNeutral,
      co2Pending,
      title: climateNeutral
        ? translate('sdk.web.emissions.widget.stats.success')
        : translate('sdk.web.emissions.widget.stats.title'),
    };
  }, [activitySummary, translate]);

  return (
    <WidgetCard>
      <WidgetTitle>
        {translate('sdk.web.emissions.widget.title')}
        {climateNeutral && (
          <InfoIconWrapper onClick={() => history.push(routes.ABOUT.href)}>
            <InfoIcon width="100%" />
          </InfoIconWrapper>
        )}
      </WidgetTitle>
      {activitySummary ? (
        <Wrapper className="emission-wgt">
          <CopyText
            as="div"
            className={classnames('emission-wgt__title', {
              'emission-wgt__title--mb-lg': climateNeutral,
            })}
            dangerouslySetInnerHTML={{__html: title}}
          />
          {climateNeutral ? (
            <div className="emission-wgt__content emission-wgt__content--center">
              <img src={successIcon} alt="" />
            </div>
          ) : (
            <div className="emission-wgt__content">
              <div className="emission-wgt__content-child emission-wgt__summary">
                <img src={cloudIcon} alt="" className="emission-wgt__icon" />
                <CopyText>{co2Pending} kg</CopyText>
              </div>
              <div className="emission-wgt__content-child">
                <img src={unicornIcon} alt="" />
              </div>
            </div>
          )}
          {!climateNeutral && (
            <div className="emission-wgt__cta">
              <AltButton
                className="emission-wgt__btn"
                onClick={() => history.push(routes.ABOUT.href)}>
                {translate('sdk.web.emissions.widget.info')}
              </AltButton>
              <Button
                className="emission-wgt__btn"
                onClick={() => history.push(routes.OFFSET.href)}>
                {translate('sdk.web.emissions.widget.stats.offset')}
              </Button>
            </div>
          )}
        </Wrapper>
      ) : (
        <WidgetLoader />
      )}
    </WidgetCard>
  );
};

export default Co2Emissions;

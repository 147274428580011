import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }

  figure.media, figure.media div {
    width: 100%;
  }

  figure.table {
    overflow-x: scroll;
  }

  figure.image img {
    max-width: 100%;
  }

  .default-styles {

    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }

    blockquote {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 40px;
      margin-inline-end: 40px;
    }

    figcaption {
      display: block;
    }

    figure {
      display: block;
      margin: 0;
    }

    q {
      display: inline;
    }

    q::before {
      content: open-quote;
    }

    q::after {
      content: close-quote;
    }

    center {
      display: block;
      /* special centering to be able to emulate the html4/netscape behaviour */
      text-align: -webkit-center;
    }

    hr {
      display: block;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      margin-inline-start: auto;
      margin-inline-end: auto;
      border-style: inset;
      border-width: 1px;
    }

    audio {
      width: 200px;
      height: 25px;
    }

    /* heading elements */
    h1 {
      display: block;
      font-size: 2em;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
    }

    :is(article, aside, nav, section) h1 {
      font-size: 1.5em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
    }

    :is(article, aside, nav, section) :is(article, aside, nav, section) h1 {
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }

    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    h1 {
      font-size: 1em;
      margin-block-start: 1.33em;
      margin-block-end: 1.33em;
    }

    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    h1 {
      font-size: 0.83em;
      margin-block-start: 1.67em;
      margin-block-end: 1.67em;
    }

    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    :is(article, aside, nav, section)
    h1 {
      font-size: 0.67em;
      margin-block-start: 2.33em;
      margin-block-end: 2.33em;
    }

    h2 {
      display: block;
      font-size: 1.5em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
      line-height: 1.25em;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
    }

    h4 {
      display: block;
      margin-block-start: 1.33em;
      margin-block-end: 1.33em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
    }

    h5 {
      display: block;
      font-size: 1.2em;
      margin-block-start: 1.67em;
      margin-block-end: 1.67em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
    }

    h6 {
      display: block;
      font-size: 1.1em;
      margin-block-start: 2.33em;
      margin-block-end: 2.33em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
    }

    /* tables */
    table {
      display: table;
      border-collapse: separate;
      border-spacing: 2px;
      border-color: gray;
    }

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
    }

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    tfoot {
      display: table-footer-group;
      vertical-align: middle;
      border-color: inherit;
    }

    /* for tables without table section elements (can happen with XHTML or dynamically created tables) */
    table > tr {
      vertical-align: middle;
    }

    col {
      display: table-column;
    }

    colgroup {
      display: table-column-group;
    }

    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
    }

    td,
    th {
      display: table-cell;
      vertical-align: inherit;
    }

    th {
      font-weight: bold;
    }

    caption {
      display: table-caption;
      text-align: -webkit-center;
    }

    /* lists */
    ul,
    menu,
    dir {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
    }

    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
    }

    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }

    /* FIXME: this should also match ::before::marker and ::after::marker but we don't support
       this yet. When we do, we can remove the code specific to ::before and ::after in
       RenderListItem::computeMarkerStyle(), see bugs.webkit.org/b/218897. */
    ::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      white-space: pre;
      text-transform: none;
    }

    ul ul,
    ol ul {
      list-style-type: circle;
    }

    ol ol ul,
    ol ul ul,
    ul ol ul,
    ul ul ul {
      list-style-type: square;
    }

    dd {
      display: block;
      margin-inline-start: 40px;
    }

    dl {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }

    dt {
      display: block;
    }

    ol ul,
    ul ol,
    ul ul,
    ol ol {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    /* form elements */
    form {
      display: block;
      margin-top: 0em;
    }

    label {
      cursor: default;
    }

    legend {
      display: block;
      padding-inline-start: 2px;
      padding-inline-end: 2px;
      border: none;
    }

    fieldset {
      display: block;
      margin-inline-start: 2px;
      margin-inline-end: 2px;
      padding-block-start: 0.35em;
      padding-inline-start: 0.75em;
      padding-inline-end: 0.75em;
      padding-block-end: 0.625em;
      border: 2px groove ThreeDFace;
      min-width: min-content;
    }

    button {
      -webkit-appearance: button;
    }

    /* Form controls don't go vertical. */
    input,
    textarea,
    keygen,
    select,
    button,
    meter,
    progress {
      -webkit-writing-mode: horizontal-tb !important;
    }

    input,
    textarea,
    keygen,
    select,
    button {
      margin: 0em;
      letter-spacing: normal;
      word-spacing: normal;
      line-height: normal;
      text-transform: none;
      text-indent: 0;
      text-shadow: none;
      display: inline-block;
      text-align: start;
    }

    input[type='hidden'] {
      display: none;
    }

    /* progress */
    progress {
      -webkit-appearance: progress-bar;
      box-sizing: border-box;
      display: inline-block;
      height: 1em;
      width: 10em;
      vertical-align: -0.2em;
    }

    progress::-webkit-progress-inner-element {
      -webkit-appearance: inherit;
      box-sizing: inherit;
      height: 100%;
      width: 100%;
    }

    progress::-webkit-progress-bar {
      background-color: gray;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    }

    progress::-webkit-progress-value {
      background-color: green;
      height: 100%;
      width: 50%; /* should be removed later */
      box-sizing: border-box;
    }

    /* inline elements */
    u,
    ins {
      text-decoration: underline;
    }

    strong,
    b {
      font-weight: bold;
    }

    i,
    cite,
    em,
    var,
    address,
    dfn {
      font-style: italic;
    }

    tt,
    code,
    kbd,
    samp {
      font-family: monospace;
    }

    pre,
    xmp,
    plaintext,
    listing {
      display: block;
      font-family: monospace;
      white-space: pre;
      margin: 1em 0;
    }

    mark {
      background-color: yellow;
      color: black;
    }

    big {
      font-size: larger;
    }

    small {
      font-size: smaller;
    }

    s,
    strike,
    del {
      text-decoration: line-through;
    }

    sub {
      vertical-align: sub;
      font-size: smaller;
    }

    sup {
      vertical-align: super;
      font-size: smaller;
    }

    nobr {
      white-space: nowrap;
    }

    html:focus,
    body:focus,
    input[readonly]:focus,
    applet:focus,
    embed:focus,
    iframe:focus,
    object:focus {
      outline: none;
    }

    a:any-link {
      color: -webkit-link;
      text-decoration: underline;
      cursor: pointer;
    }

    a:any-link:active {
      color: -webkit-activelink;
    }

    /* HTML5 ruby elements */
    ruby,
    rt {
      text-indent: 0; /* blocks used for ruby rendering should not trigger this */
    }

    rt {
      line-height: normal;
      -webkit-text-emphasis: none;
    }

    ruby > rt {
      display: block;
      text-align: start;
    }

    ruby > rp {
      display: none;
    }

    /* other elements */
    noframes {
      display: none;
    }

    frameset,
    frame {
      display: block;
    }

    frameset {
      border-color: inherit;
    }

    details {
      display: block;
    }

    summary {
      display: block;
    }

    summary::-webkit-details-marker {
      display: inline-block;
      width: 0.66em;
      height: 0.66em;
      margin-right: 0.4em;
    }

    template {
      display: none;
    }

    bdi,
    output {
      unicode-bidi: isolate;
    }

    bdo {
      unicode-bidi: bidi-override;
    }

    slot {
      display: contents;
    }

    .elementor-heading-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }

    iframe {
      max-width: 100%;
    }

    .blocks-gallery-grid {
      list-style: none;
      padding-left: 0;
    }

    .wp-block-embed.wp-block-embed-vimeo {
      padding: 0.5em 0 2em;

      iframe {
        margin: 0 auto 5px;
      }

      @media only screen and (max-width: 768px) {
        iframe {
          margin: -2.8em auto -2.3em;
        }
      }
    }
  }

`;

export default GlobalStyle;

import React, {useMemo} from 'react';
import styled from 'styled-components';
import {FormikContextType} from 'formik';
import {useTranslations} from 'hooks/use-translations';
import {ApiErrors} from 'hooks/forms/use-form-validation';
import {InputStyles} from 'components/Form/InputStyles';
import FormError from 'components/Form/FormError';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import {Country} from 'types/Country';
import {ReactComponent as CaretIcon} from 'icons/right-caret.svg';

const SelectBlock = styled(CopyTextSm)`
  position: relative;
  display: flex;
`;

const SelectValue = styled(InputStyles)`
  display: block;
`;

const SelectDropdown = styled.select`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Icon = styled.div`
  position: absolute;
  width: 25px;
  top: 0;
  right: 0;
`;

type CountryPickerProps = {
  name?: string;
  value?: string;
  countries: Array<Country>;
  onChange: (e: any) => void;
  formik: FormikContextType<any>;
  apiErrors?: ApiErrors;
};

const CountryPicker = (props: CountryPickerProps) => {
  const {name, countries, formik, apiErrors, value, onChange} = props;
  const {translate} = useTranslations();
  const label = useMemo(
    () =>
      countries.find((country) => country.country_code === value)
        ?.country_name || value,
    [countries, value],
  );

  return (
    <div>
      <SelectBlock>
        <SelectValue as={'div'}>
          {label || translate('sdk.web.inputs.country.label')}
        </SelectValue>
        <SelectDropdown name={'country'} onChange={onChange} value={value}>
          <option value="" disabled>
            {translate('sdk.web.inputs.country.label')}
          </option>
          {countries.map((country) => (
            <option key={country.id} value={country.country_code}>
              {country.country_name}
            </option>
          ))}
        </SelectDropdown>
        <Icon>
          <CaretIcon />
        </Icon>
        <FormError name={name} formik={formik} apiErrors={apiErrors} />
      </SelectBlock>
    </div>
  );
};

export default CountryPicker;

import React, {useContext, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import {isIOS} from 'react-device-detect';
import styled from 'styled-components';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import {sendMessageToNativeApp} from 'services/native-api';
import {SettingsContext} from 'contexts/SettingsContext';

import Onboarding from 'components/Onboarding/Onboarding';
import Button from 'components/Button/Button';
import onboardingImg from 'components/Onboarding/images/onboarding-gps.svg';
import OnboardLink from 'components/Onboarding/OnboardLink';

const Link = styled(OnboardLink)`
  margin-top: 20px;
`;

const OnboardingGps = (props: any) => {
  const {teams} = props;
  const {translate} = useTranslations();
  const {appPermissions, organizationConfig} = useContext(SettingsContext);

  const nextRoute = appPermissions.includes('motion')
    ? routes.ONBOARDING.MOTION.href
    : appPermissions.includes('notification')
    ? routes.ONBOARDING.NOTIFICATION.href
    : teams.length
    ? routes.ONBOARDING.TEAM.href
    : routes.DASHBOARD.href;

  const {title, content} = useMemo(
    () => ({
      title: translate(organizationConfig.translations.onBoarding.gps.title),
      content: translate(
        organizationConfig.translations.onBoarding.gps.content,
      ),
    }),
    [organizationConfig, translate],
  );

  const onBtnClick = () => {
    const message = {
      type: 'request_permissions',
      permission: ['gps'],
    };
    sendMessageToNativeApp({message});
    props.history.push(nextRoute);
  };

  return (
    <Onboarding
      title={title}
      content={content}
      onboardingImg={
        organizationConfig.theme.components.onboarding?.images?.gps?.image ||
        onboardingImg
      }>
      <div>
        <Button onClick={onBtnClick}>
          {translate('sdk.web.onboarding.gps.understood')}
        </Button>
      </div>
      {!isIOS && (
        <Link to={nextRoute} noMargin={true}>
          {translate('sdk.web.onboarding.gps.skip.link')}
        </Link>
      )}
    </Onboarding>
  );
};

export default withRouter(OnboardingGps);

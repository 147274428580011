import {fetchAPiClient} from './api';

const defaultClient = fetchAPiClient({
  subdomain: 'api',
  context: 'coins',
});

type TrackAnalyticsData = Array<{
  action: string;
  type: string;
  id: number;
}>;
export const trackAnalytics = async (data: TrackAnalyticsData) => {
  const client = defaultClient;
  const url = '/v1/analytics';
  try {
    await client.post(url, data);
  } catch (error) {}
};

export const trackOfferDetailsView = async (id: number) =>
  trackAnalytics([{id, action: 'view-details', type: 'offer'}]);

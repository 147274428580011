import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import {addBoldText} from 'utils/theme';

import HeaderText from 'components/Text/HeaderText/HeaderText';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Loader from 'components/Loader/Loader';
import Partners from './Partners';
import {CompensationPartner} from 'types/CompensationPartner';
import {ReactComponent as CloseIcon} from 'icons/close.svg';

const Wrapper = styled.div`
  padding: 50px 15px 10px;
`;

const Title = styled(HeaderText)`
  margin-bottom: 15px;
  font-size: ${(props) =>
    props.theme.components.co2Compensation?.titleFontSize}px;
  display: block;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Description = styled(CopyTextSm)`
  margin-bottom: 30px;
  display: block;
  font-size: ${(props) =>
    props.theme.components.co2Compensation?.descriptionFontSize}px;
`;

const Icon = styled.div`
  width: 20px;
  position: absolute;
  color: #000;
  left: 15px;
  top: 10px;
`;

type CompensationProjectsProps = {
  isLoading: boolean;
  partners: CompensationPartner[];
};

const CompensationProjects = ({
  isLoading,
  partners,
}: CompensationProjectsProps) => {
  const {translate} = useTranslations();

  return (
    <Wrapper>
      <Link to={routes.DASHBOARD.href}>
        <Icon>
          <CloseIcon />
        </Icon>
      </Link>
      <Title>{translate('sdk.web.compensation.projects.title')}</Title>
      <Description>
        {translate('sdk.web.compensation.projects.description')}
      </Description>
      {!isLoading ? (
        partners.map(
          (partner) =>
            !!partner.compensations.length && (
              <Partners key={partner.id} partner={partner} />
            ),
        )
      ) : (
        <Loader color={'#222'} />
      )}
    </Wrapper>
  );
};

export default CompensationProjects;

import React from 'react';

import routes from 'config/routes';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';

import InfoPage from 'components/InfoPage/InfoPage';
import Map from 'pages/ClimateTreasure/Map';

const ClimateTreasure = () => {
  const {handleGoBack} = useNavigation({
    replaceLocation:
      routes.DASHBOARD.CONTRIBUTIONS.WIDGETS_SEARCH.CLIMATE_TREASURE,
  });
  const {translate} = useTranslations();

  return (
    <InfoPage
      title={translate('sdk.web.treasure.map.title')}
      noPadding
      onClose={handleGoBack}>
      <Map />
    </InfoPage>
  );
};

export default ClimateTreasure;

import {getDeviceLanguage} from 'utils/language';

const domains = {
  app: {
    development: 'changersdev.de',
    stage: 'maroshi.de',
    production: 'changers.com',
  },
  coins: {
    development: 'coin-stage.de',
    stage: 'coin-stage.de',
    production: 'klima-taler.com',
  },
};
const DEFAULT_CONTEXT = 'app';

const language = getDeviceLanguage();

export type DomainContext = 'app' | 'coins';

export const getAuthConfig = ({
  context = DEFAULT_CONTEXT,
}: {context?: DomainContext} = {}) => {
  const config = {
    env: '',
    baseDomain: '',
    headers: {
      Authorization: '',
    },
  };

  try {
    const authString = localStorage.getItem('auth');
    if (authString) {
      const auth = JSON.parse(authString);
      const {token, env, headers} = auth;
      config.baseDomain =
        // @ts-ignore
        domains[context][env] || process.env.REACT_APP_BASE_DOMAIN;
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      config.headers = {
        ...config.headers,
        ...headers,
        'accept-language': language,
      };
      config.env = env;
    }
  } catch (e: any) {}

  return config;
};

import React from 'react';
import styled from 'styled-components';
import {useOrganization} from 'hooks/use-organization';

import Loader from 'components/Loader/Loader';
import {QrComponentProps, withQrScanner} from 'components/QrScanner';
import {ReactComponent as QrIcon} from 'icons/panda-qr.svg';

const Wrapper = styled.div`
  display: flex;
`;

const Icon = styled.div`
  width: 120px;
  fill: ${(props) => props.theme.colors.primary};

  svg {
    max-width: 100%;
  }
`;

const CurvedInfoWrapper = styled.div`
  width: 110px;
  height: 110px;
  position: relative;
  margin-bottom: -20px;
  margin-right: -20px;
  overflow: hidden;
`;

const CurvedInfoIcon = styled.div`
  position: absolute;
  width: 60px;
  right: 10px;
  bottom: 10px;
`;

const CurvedCircle = styled.div`
  position: absolute;
  width: 220px;
  height: 220px;
  top: 0;
  background-color: ${(props) => props.theme.colors.body};
  border-radius: 100%;
`;

const ClimateIconScan = (props: QrComponentProps) => {
  const {openScanner, startListening, isLoading} = props;
  const {organizationConfig} = useOrganization();
  const co2TokenConfig = organizationConfig.theme.components.co2Token;

  return (
    <Wrapper>
      {isLoading ? (
        <Loader sm={true} color="#222" />
      ) : co2TokenConfig?.InfoIcon && co2TokenConfig?.hasCurvedInfo ? (
        <CurvedInfoWrapper
          onClick={() => {
            startListening();
            openScanner();
          }}>
          <CurvedCircle />
          <CurvedInfoIcon>
            <co2TokenConfig.InfoIcon />
          </CurvedInfoIcon>
        </CurvedInfoWrapper>
      ) : (
        <Icon
          onClick={() => {
            startListening();
            openScanner();
          }}>
          {co2TokenConfig?.InfoIcon ? <co2TokenConfig.InfoIcon /> : <QrIcon />}
        </Icon>
      )}
    </Wrapper>
  );
};

export default withQrScanner(ClimateIconScan);

import {useCallback, useContext, useEffect} from 'react';
import {sendMessageToNativeApp} from 'services/native-api';
import {SettingsContext} from 'contexts/SettingsContext';

interface UseBodyBg {
  type?: string;
  color?: string;
}

export const useBodyBg = ({type, color}: UseBodyBg) => {
  const {setBodyBgColor, organizationConfig} = useContext(SettingsContext);
  const {theme} = organizationConfig;
  const {
    colors,
    components: {prompts},
  } = theme;

  useEffect(
    () => {
      if (type || color) {
        updateBg({type, color});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateBg = useCallback(
    ({type, color}: UseBodyBg) => {
      let bgColor = color || colors.white;

      switch (type) {
        case 'light':
          bgColor = colors.white;
          break;
        case 'primary':
          bgColor = colors.body || colors.primary;
          break;
        case 'body':
          bgColor = colors.secondary;
          break;
        case 'lightSecondary':
          bgColor = colors.lightSecondary;
          break;
        case 'onboarding':
          bgColor = colors.onboardingBg;
          break;
        case 'prompt':
          bgColor = prompts?.bgColor || colors.promptBg;
          break;
        default:
          bgColor = type && colors[type] ? colors[type] : bgColor;
          break;
      }

      setBodyBgColor(bgColor);
      const message = {
        type: 'status_bar',
        value: bgColor,
      };
      sendMessageToNativeApp({message});
    },
    [prompts, colors, setBodyBgColor],
  );

  return {
    updateBg,
  };
};

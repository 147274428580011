import React, {useMemo} from 'react';
import styled from 'styled-components';

import {useUserProfile} from 'hooks/use-user-profile';
import useElementTranslation from '../hooks/use-element-translation';
import {UserProfile} from 'services/user';
import {METERING_ELEMENTS} from 'types/Metering';

import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import CopyText from 'components/Text/CopyText/CopyText';

const aboutKeys: Record<
  METERING_ELEMENTS,
  Extract<keyof UserProfile['organisation'], string>
> = {
  [METERING_ELEMENTS.ELECTRICITY]: 'saving_electricity',
  [METERING_ELEMENTS.GAS]: 'saving_gas',
  [METERING_ELEMENTS.WATER]: 'saving_water',
};

const Content = styled(CopyText)`
  text-align: left;
  padding: 0 20px 20px;
`;

interface AboutElementProps {
  isVisible: boolean;
  element: METERING_ELEMENTS;
  onClose: () => any;
}

const AboutElement = (props: AboutElementProps) => {
  const {element, isVisible, onClose} = props;
  const translate = useElementTranslation({element});
  const {userProfile} = useUserProfile();

  const content = useMemo(
    () => (userProfile.organisation[aboutKeys[element]] || '') as string,
    [element, userProfile],
  );

  return (
    <PromptWrapper
      isVisible={isVisible}
      title={translate('sdk.web.meter.about.saving.element')}
      onClose={onClose}
      styles={{pb: '0'}}>
      <Content as="div" dangerouslySetInnerHTML={{__html: content}} />
    </PromptWrapper>
  );
};

export default AboutElement;

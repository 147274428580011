import {useCallback, useContext, useEffect} from 'react';

import EventsContext from 'contexts/EventsContext';

const useEvents = () => {
  const {updateEvents} = useContext(EventsContext);

  const onTokensUpdate = useCallback(() => {
    updateEvents({
      tokensUpdated: Date.now(),
    });
  }, [updateEvents]);

  useEffect(() => {}, []);

  return {
    onTokensUpdate,
  };
};

export default useEvents;

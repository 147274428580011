import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import {CACHE_KEYS} from 'constants/cache-keys';
import routes from 'config/routes/co2Compensation';
import {useTranslations} from 'hooks/use-translations';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import {getPartners, getUserCompensations} from 'services/co2Compensation';
import {addGlobalSquareCorners} from 'utils/theme';

import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import Button from 'components/Button/Button';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import CopyText from 'components/Text/CopyText/CopyText';
import ClimateCalendar from './ClimateCalendar';
import Gallery from './Gallery';

const Title = styled.div`
  margin-bottom: 20px;
`;

const Info = styled(CopyTextSm)`
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

const Cta = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

const CtaButton = styled(Button)<{altColor?: boolean}>`
  display: flex;
  min-width: auto;
  width: calc(50% - 3.5px);
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.theme.components.co2Compensation?.buttonBgColor};
  color: ${(props) => props.theme.components.co2Compensation?.buttonColor};
  border-radius: 5px;

  ${(props) => {
    if (props.altColor) {
      return `
      background-color: ${props.theme.colors.white};
      color: ${props.theme.colors.black};
      border: 2px solid ${props.theme.components.buttons.primary.bgColor};
      `;
    }

    return '';
  }}
  ${(props) => {
    if (props.theme.components.co2Compensation?.hasSquareCorners) {
      return `
        border-radius: 0;
      `;
    }
    return '';
  }}
  span {
    font-weight: ${(props) =>
      props.theme.components.co2Compensation?.compensateButtonFontWeight};
  }

  ${addGlobalSquareCorners()}
`;

const GalleryWrapper = styled.div`
  margin-top: 10px;
`;

const Co2Compensation = () => {
  const history = useHistory();
  const {translate} = useTranslations();
  const {hasCo2Compensation, organizationConfig} = useOrganization();

  const {data: userCompensations, isLoading} = useFetcher({
    fetcher: getUserCompensations,
    initialValue: [],
    key: CACHE_KEYS.USER_COMPENSATIONS,
    preventFetch: !hasCo2Compensation,
  });

  const {data: partners} = useFetcher({
    fetcher: getPartners,
    initialValue: [],
    key: 'compensationPartners',
    preventFetch: !hasCo2Compensation,
  });

  if (!hasCo2Compensation || !partners.length) {
    return null;
  }

  const hasClimateCalendar = !!userCompensations.length;
  const bgColor = organizationConfig.theme.components.co2Compensation?.widgetBg;

  return (
    <WidgetCard bgColor={bgColor || hasClimateCalendar ? '' : 'white'}>
      <Title>
        <WidgetTitle>
          {hasClimateCalendar && !!userCompensations.length
            ? translate('sdk.web.compensation.widget.title.light')
            : translate('sdk.web.compensation.widget.title.neutral')}
        </WidgetTitle>
      </Title>
      {hasClimateCalendar ? (
        <ClimateCalendar
          userCompensations={userCompensations}
          isLoading={isLoading}
        />
      ) : (
        <Info
          dangerouslySetInnerHTML={{
            __html: translate('sdk.web.compensation.widget.desc'),
          }}
        />
      )}
      <Cta>
        <CtaButton
          altColor={true}
          onClick={() => history.push(routes.ABOUT.href)}>
          <CopyText>{translate('sdk.web.compensation.widget.learn')}</CopyText>
        </CtaButton>
        <CtaButton onClick={() => history.push(routes.href)}>
          <CopyText>
            {translate('sdk.web.compensation.widget.compensate')}
          </CopyText>
        </CtaButton>
      </Cta>
      {!hasClimateCalendar && (
        <GalleryWrapper>
          <Gallery />
        </GalleryWrapper>
      )}
    </WidgetCard>
  );
};

export default Co2Compensation;

import React, {useRef} from 'react';
import {Route, Switch} from 'react-router-dom';

import {useScrollToTop} from 'hooks/ui/use-scroll-to-top';

import NotificationsPage from './NotificationsPage';

const Notifications = () => {
  const wrapperRef = useRef(null);
  useScrollToTop({wrapperRef});

  return (
    <div ref={wrapperRef}>
      <Switch>
        <Route>
          <NotificationsPage />
        </Route>
      </Switch>
    </div>
  );
};

export default Notifications;

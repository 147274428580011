import React, {useCallback, useMemo, useState} from 'react';
import {useTranslations} from 'hooks/use-translations';
import DialogBox from 'components/DialogBox/DialogBox';

const useWarning = () => {
  const {translate} = useTranslations();
  const [isVisible, setIsVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleConfirmation = useCallback(() => {
    setIsVisible(false);
    setTitle('');
    setContent('');
  }, []);

  const showWarning = useCallback(({title, content}: any) => {
    setTitle(title);
    setContent(content);
    setIsVisible(true);
  }, []);

  const WarningDialog = useMemo(
    () => () =>
      isVisible ? (
        <DialogBox
          title={title}
          promptMessage={content}
          onConfirmation={handleConfirmation}
          singleText={translate('sdk.web.dialog.box.ok')}
        />
      ) : null,
    [title, content, isVisible, handleConfirmation, translate],
  );

  return {
    showWarning,
    WarningDialog,
  };
};

export default useWarning;

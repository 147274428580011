import React, {useMemo} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {useLocation} from 'react-router-dom';

import routes from 'config/routes';
import {OnActivityComplete} from 'hooks/services/use-custom-activity';
import {useOrganization} from 'hooks/use-organization';
import useNavigation from 'hooks/app/use-navigation';

import InfoPage from 'components/InfoPage/InfoPage';
import ActivityList from 'components/CustomActivities/ActivityList';
import Loader from 'components/Loader/Loader';
import {CustomActivity} from 'types/CustomActivity';

const Wrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 40000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
`;

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  padding-bottom: 20px;
  margin: 0 -10px;
  margin-top: -2px;
`;

type ActivityListDetailsProps = {
  activities: CustomActivity[];
  onActivityComplete: OnActivityComplete;
  isLoading: boolean;
};

const ActivityListDetails = (props: ActivityListDetailsProps) => {
  const {activities, isLoading, onActivityComplete} = props;
  const {search} = useLocation();
  const {organizationConfig} = useOrganization();
  const {handleGoBack} = useNavigation({
    replaceLocation:
      routes.DASHBOARD.CONTRIBUTIONS.WIDGETS_SEARCH.CUSTOM_ACTIVITIES,
  });

  const categoryName = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('categoryName');
  }, [search]) as string;

  const bgColor =
    organizationConfig.theme.components.customActivities.detailsBgColor;

  return (
    <Wrapper>
      <Container>
        <InfoPage
          title={categoryName}
          isLoading={false}
          bgColor={bgColor}
          onClose={handleGoBack}>
          <Content>
            {!isLoading ? (
              <ActivityList
                activities={activities}
                hideCaret={true}
                onActivityComplete={onActivityComplete}
              />
            ) : (
              <Loader color={'#222'} />
            )}
          </Content>
        </InfoPage>
      </Container>
    </Wrapper>
  );
};

export default ActivityListDetails;

import {ElementData, METERING_ELEMENTS, MeteringStats} from 'types/Metering';

export enum ELEMENT_SCREENS {
  SETTINGS = 'SETTINGS',
  SUMMARY = 'SUMMARY',
  CONSUMPTION = 'CONSUMPTION',
  NEW_MONTH = 'NEW_MONTH',
  PREVIOUS_RESULTS = 'PREVIOUS_RESULTS',
  SETTINGS_CONFIRMATION = 'SETTINGS_CONFIRMATION',
}

export enum ELEMENT_CONSUMPTION_SCREENS {
  PHOTO = 'PHOTO',
  SUCCESS = 'SUCCESS',
  SETTINGS = 'SETTINGS',
  FIRST_TIME = 'FIRST_TIME',
  MONTH_RESULTS = 'MONTH_RESULTS',
}

export interface ElementScreenProps {
  element: METERING_ELEMENTS;
  elementData: ElementData;
  closeKey: number;
  onClose: () => void;
  onComplete: (data?: {nextScreen: ELEMENT_SCREENS}) => void;
  stats?: MeteringStats;
}

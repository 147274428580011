import {useCallback, useEffect, useState} from 'react';
import {hidePrompt, Prompt} from 'services/prompts';

interface UsePromptApi {
  prompts: Array<Prompt>;
  type: string;
  preventAutoHide?: boolean;
}

export const usePromptApi = (props: UsePromptApi) => {
  const {prompts, type, preventAutoHide} = props;
  const [isVisible, setIsVisible] = useState(true);

  const prompt = prompts.find((prompt: any) => prompt.type === type);

  useEffect(
    () => {
      if (!preventAutoHide) {
        handleHidePrompt();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prompt],
  );

  const handleHidePrompt = useCallback(async () => {
    if (!prompt) {
      return;
    }

    try {
      await hidePrompt({id: prompt.id});
    } catch (e: any) {}
  }, [prompt]);

  const handleModalClose = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return {
    isVisible,
    prompt,
    handleModalClose,
    handleHidePrompt,
  };
};

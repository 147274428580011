import merge from 'lodash/merge';
import base from 'config/organizations/base';

const config = {
  name: 'sdksample',
  features: {
    hideAppClose: true,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCustomActivities: true,
    hasCo2Compensation: true,
    hasBadges: true,
    hasNewFAQ: true,
    hasWaterWidget: false,
    hasMetering: false,
  },
  applangaTag: '60086398da59613269f44eaa',
};

export default merge({}, base, config);

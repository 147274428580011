import React from 'react';
import styled from 'styled-components';

import routes from 'config/routes/rewards';
import {markVoucherAsUsed, removeVoucherFromWishlist} from 'services/vouchers';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';

import PullToRefresh from 'components/PullToRefresh';
import VouchersList from './VouchersList/VouchersList';
import WishListIntro from './WishListIntro/WishListIntro';

import {ReactComponent as HeartIcon} from 'icons/heart-active.svg';
import {PurchasedVoucher} from 'types/Offers';

const Wrapper = styled.div`
  position: relative;
  padding: 10px;
  min-height: 80vh;
  color: ${(props) =>
    props.theme.components.vouchers?.textColor || props.theme.colors.black};
`;
const Title = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  fill: ${(props) =>
    props.theme.components.vouchers.wishlistIcon?.fill ||
    props.theme.colors.primary};
  width: 25px;
  margin-right: 10px;
`;

const Block = styled.div`
  margin-bottom: 25px;
`;

export enum VOUCHER_KEYS {
  PURCHASED = 'purchased',
  WISHLIST = 'wishlist',
  USED = 'used',
}

const WishListTitle = ({title}: {title: string}) => (
  <Title>
    <Icon>
      <HeartIcon />
    </Icon>
    <span>{title}</span>
  </Title>
);

interface VouchersProps {
  vouchersData: Record<
    VOUCHER_KEYS,
    {
      data: Array<PurchasedVoucher>;
      isLoading: boolean;
      reload: () => void;
    }
  >;
  onRefresh: () => any;
}

const Vouchers = (props: VouchersProps) => {
  const {vouchersData, onRefresh} = props;

  useBodyBg({type: 'primary'});
  const {translate} = useTranslations();
  const {hasClimatePartners} = useOrganization();

  return (
    <PullToRefresh onRefresh={onRefresh}>
      <Wrapper>
        {vouchersData.purchased.data.length > 0 && (
          <Block>
            <VouchersList
              title={translate('sdk.web.vouchers.purchased.title')}
              emptyText={translate('sdk.web.vouchers.empty')}
              voucherUrl={routes.REWARDS.VOUCHERS.href}
              vouchers={vouchersData.purchased.data}
              isLoading={vouchersData.purchased.isLoading}
              reloadVouchers={vouchersData.purchased.reload}
              removeVoucher={{
                text: translate('sdk.web.vouchers.remove.used'),
                title: translate('sdk.web.vouchers.delete.prompt.title'),
                desc: translate('sdk.web.vouchers.delete.prompt.desc'),
                delete: markVoucherAsUsed,
              }}
            />
          </Block>
        )}
        {hasClimatePartners && (
          <Block>
            <VouchersList
              title={
                <WishListTitle
                  title={translate('sdk.web.vouchers.wishlist.title')}
                />
              }
              voucherUrl={`${routes.REWARDS.DEALS.href}/vouchers`}
              vouchers={vouchersData.wishlist.data}
              isLoading={vouchersData.wishlist.isLoading}
              reloadVouchers={vouchersData.wishlist.reload}
              removeVoucher={{
                text: translate('sdk.web.vouchers.remove.wishlist'),
                title: translate(
                  'sdk.web.vouchers.remove.wishlist.prompt.title',
                ),
                desc: translate('sdk.web.vouchers.remove.wishlist.prompt.desc'),
                delete: removeVoucherFromWishlist,
              }}
              header={<WishListIntro />}
              showClimateIcon={true}
            />
          </Block>
        )}
        {vouchersData.used.data.length > 0 && (
          <Block>
            <VouchersList
              title={translate('sdk.web.vouchers.used.title')}
              emptyText={translate('sdk.web.vouchers.empty')}
              voucherUrl={routes.REWARDS.VOUCHERS.href}
              vouchers={vouchersData.used.data}
              isLoading={vouchersData.used.isLoading}
              reloadVouchers={vouchersData.used.reload}
            />
          </Block>
        )}
      </Wrapper>
    </PullToRefresh>
  );
};

export default Vouchers;

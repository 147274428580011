import React from 'react';

import routes from 'config/routes';
import {PROMPTS} from 'constants/prompts';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';
import {useOrganization} from 'hooks/use-organization';

import PromptModal from 'components/PromptModal/PromptModal';
import img from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/images/banner.svg';

const NewTreePrompt = (props: any) => {
  const {prompts} = props;
  const {translate} = useTranslations();
  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.TREE_PLANTED,
  });

  const {organizationConfig} = useOrganization();
  const treePlantingConfig = organizationConfig.theme.components.treePlanting;
  const treePlantingTranslations =
    organizationConfig.translations.treePlanting || {};

  if (!prompt) {
    return <React.Fragment />;
  }

  const userTrees = prompt.data.user_trees;

  const TextContent = (
    <div
      dangerouslySetInnerHTML={{
        __html: translate(
          treePlantingTranslations.newTree ||
            'sdk.web.contributions.treePlanting.new.tree.text',
          [
            {
              key: '{nr}',
              value: parseInt(userTrees).toLocaleString(),
            },
          ],
        ),
      }}
    />
  );

  const moreInfo = {
    text: translate('sdk.web.contributions.treePlanting.more.info'),
    link: routes.SETTINGS.ABOUT_TREE_PLANTING.href,
  };

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.contributions.treePlanting.new.tree.title')}
      textContent={TextContent}
      image={treePlantingConfig?.newTreeBanner || img}
      btnText={translate('sdk.web.contributions.treePlanting.thanks')}
      onBtnClick={handleModalClose}
      moreInfo={moreInfo}
    />
  );
};

export default NewTreePrompt;

import React from 'react';
import {Prompt} from 'services/prompts';

import FullTokenModal from 'components/ClimateWidgets/Co2Token/FullTokenModal/FullTokenModal';
import InviteFriendsSuccessPrompt from 'components/ClimateWidgets/InviteFriends/InviteFriendsSuccessPrompt/InviteFriendsSuccessPrompt';
import InviteFriendsProgressPrompt from 'components/ClimateWidgets/InviteFriends/InviteFriendsProgressPrompt/InviteFriendsProgressPrompt';
import LotSuccessPrompt from 'components/Rewards/Lots/LotResults/LotSuccessPrompt/LotSuccessPrompt';
import LotLossPrompt from 'components/Rewards/Lots/LotResults/LotLossPrompt/LotLossPrompt';
import NewTreePrompt from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/NewTreePrompt';
import HalfwayTreePrompt from 'components/ClimateWidgets/TreePlanting/HalfwayTreePrompt/HalfwayTreePrompt';
import TreeGoalReachedPrompt from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/TreeGoalReachedPrompt';
import ChallengeEndPrompt from 'components/ClimateWidgets/Leaderboard/ChallengeEndPrompt';
import OnboardingNotificationPrompt from 'components/Onboarding/OnboardingNotificationPrompt';
import NewPrivacyPolicyPrompt from 'components/AppPrompts/NewPrivacyPolicyPrompt';
import NewMonthPrompt from 'components/ClimateWidgets/Metering/NewMonthPrompt';
import SocioDemoPrompt from 'pages/Settings/SocioDemoDataPage/SocioDemoPrompt';

interface AppPromptsInterface {
  prompts: Prompt[];
  leaderboards: any;
  activitySummary: any;
}

const AppPrompts = (props: AppPromptsInterface) => {
  const {prompts, activitySummary, leaderboards} = props;

  return (
    <>
      <FullTokenModal prompts={prompts} />
      <InviteFriendsSuccessPrompt
        activitySummary={activitySummary}
        prompts={prompts}
      />
      <InviteFriendsProgressPrompt
        activitySummary={activitySummary}
        prompts={prompts}
      />
      <LotSuccessPrompt prompts={prompts} />
      <LotLossPrompt prompts={prompts} />
      <NewTreePrompt prompts={prompts} />
      <HalfwayTreePrompt prompts={prompts} />
      <TreeGoalReachedPrompt prompts={prompts} />
      <ChallengeEndPrompt prompts={prompts} leaderboards={leaderboards} />
      <OnboardingNotificationPrompt prompts={prompts} />
      <NewPrivacyPolicyPrompt prompts={prompts} />
      <NewMonthPrompt prompts={prompts} />
      <SocioDemoPrompt prompts={prompts} />
    </>
  );
};

export default AppPrompts;

import React, {useMemo} from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';
import {addBoldText} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import {ReactComponent as OffersIcon} from './icons/offers.svg';
import {ReactComponent as MoreIcon} from './icons/more.svg';
import {ReactComponent as TopTeamsIcon} from './icons/teams.svg';
import {ReactComponent as ImpactIcon} from './icons/impact.svg';
import {ReactComponent as NewsIcon} from './icons/news.svg';

const NavigationWrapper = styled.div`
  background-color: ${(props) =>
    props.theme.colors.body || props.theme.colors.primary};
  position: relative;
  padding: 17px 0 13px;
`;

const MenuWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

export const MenuIcon = styled.div`
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;

  .dresden-nav-2 {
    fill: ${(props) => props.theme.colors.primary};
  }

  .dresden-nav-3 {
    fill: ${(props) => props.theme.colors.white};
  }
`;

export const Menu = styled(NavLink)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  text-decoration: none;
  border-radius: 100%;

  &.active .dresden-nav-2 {
    fill: ${(props) => props.theme.colors.white};
  }

  &.active .dresden-nav-3 {
    fill: ${(props) => props.theme.colors.primary};
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Text = styled(CopyTextSm)`
  display: block;
  margin-top: 10px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Navigation = () => {
  const {translate} = useTranslations();
  const {organizationConfig} = useOrganization();
  const translations = organizationConfig.translations.nav;

  const homeRoutes = useMemo(
    () => [
      {
        translationKey: translations.impact,
        to: routes.DASHBOARD.CONTRIBUTIONS.href,
        Component: ImpactIcon,
      },
      {
        translationKey: translations.offers,
        to: routes.DASHBOARD.REWARDS.href,
        Component: OffersIcon,
      },
      {
        translationKey: translations.news,
        to: routes.DASHBOARD.NEWS_FEED.href,
        Component: NewsIcon,
      },
      {
        translationKey: translations.teams,
        to: routes.DASHBOARD.COMPETITION.href,
        Component: TopTeamsIcon,
      },
      {
        translationKey: translations.more,
        to: routes.SETTINGS.href,
        Component: MoreIcon,
      },
    ],
    [translations],
  );

  return (
    <NavigationWrapper>
      <MenuWrapper>
        {homeRoutes.map(({translationKey, to, Component}, index) => (
          <MenuItem key={index}>
            <Menu to={to} activeStyle={{}}>
              <MenuIcon>
                <Component />
              </MenuIcon>
              <Text>{translate(translationKey)}</Text>
            </Menu>
          </MenuItem>
        ))}
      </MenuWrapper>
    </NavigationWrapper>
  );
};

export default Navigation;

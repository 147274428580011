import {
  RootProviderState,
  IntlProviderState,
  RootActions,
  IntlActions,
} from './types';

import {createReducer} from './utils';
import {RootState, IntlState} from './initialStates';
import {
  APP_INIT,
  INTL_SET_TRANSLATION_STRINGS,
  INTL_SET_STATUS,
  APP_LOG,
} from './actions';

/**
 * SDK Reducers
 *
 * Use with responsibility.
 *
 * Handler example:
 * {
 *  [ADD_ITEM]: (state:<Interface>, data:<type|any>) => {
 *    // do something with state...
 *    return aNewState;
 *  }
 * }
 *
 * @param handlers @interface ReducersHandlers
 *
 * @todo: create actions types using discriminated unions
 */

/** Root context provider reducer */
export const RootReducer = createReducer<RootProviderState, RootActions>({
  [APP_INIT]: (state = RootState) => {
    return {
      ...state,
      state: 'loading',
      version: 'some',
    };
  },
  [APP_LOG]: (state = RootState, action: any) => {
    const logs = state.logs
      ? [...state.logs, action.payload]
      : [action.payload];
    return {
      ...state,
      logs: logs,
    };
  },
});

/** Intl context provider reducer */
export const IntlReducer = createReducer<IntlProviderState, IntlActions>({
  [INTL_SET_TRANSLATION_STRINGS]: (state = IntlState, action: any) => {
    return {
      ...state,
      translations: {
        ...state.translations,
        [state.locale]: {
          ...state.translations[state.locale],
          ...action.payload,
        },
      },
      status: 'on',
    };
  },
  [INTL_SET_STATUS]: (state = IntlState, action: any) => {
    return {
      ...state,
      status: action.payload,
    };
  },
});

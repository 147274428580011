import React from 'react';
import Notifications from 'components/Notifications';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: calc(100vh - 95px);
  width: 100%;
  position: relative;
`;
const NotificationsPage = () => (
  <Wrapper>
    <Notifications />
  </Wrapper>
);
export default NotificationsPage;

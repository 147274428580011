import React, {useCallback} from 'react';
import styled from 'styled-components';

import {ConsumptionScreenProps} from './types';
import useImageRequest from './hooks/use-image-request';
import useElementTranslation from '../../hooks/use-element-translation';

import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import PermissionDialog from './PermissionDialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 20px;

  .e-photo {
    &__title {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__text,
    &__cta {
      padding: 0 20px;
    }

    &__text {
      margin-bottom: 20px;
    }

    &__img {
      margin-bottom: 40px;
      max-width: 100%;
      max-height: 45vh;
    }

    &__btn {
      margin-bottom: 20px;
    }
  }
`;

const Photo = (props: ConsumptionScreenProps) => {
  const {onComplete, element} = props;
  const translate = useElementTranslation({element});

  const handleImageResponse = useCallback(
    ({image}: {image: string}) => {
      onComplete({
        data: {
          image,
        },
      });
    },
    [onComplete],
  );

  const {isPermDialogVbl, handlePermissionCheck, handleTakePhoto} =
    useImageRequest({onComplete: handleImageResponse});

  return (
    <Wrapper>
      <div>
        <Title className="e-photo__title">
          {translate('sdk.web.meter.consumption.title')}
        </Title>
        <div className="e-photo__text">
          <CopyText>{translate('sdk.web.meter.consumption.info')}</CopyText>
        </div>
        <div className="e-photo__text">
          <CopyText>{translate('sdk.web.meter.consumption.confirm')}</CopyText>
        </div>
      </div>
      <div className="e-photo__cta">
        <div className="e-photo__btn">
          <Button onClick={handleTakePhoto}>
            {translate('sdk.web.meter.consumption.photo')}
          </Button>
        </div>
      </div>
      <PermissionDialog
        isVisible={isPermDialogVbl}
        onConfirmation={handlePermissionCheck}
      />
    </Wrapper>
  );
};

export default Photo;

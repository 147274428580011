import React, {useState} from 'react';

import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {useUserProfile} from 'hooks/use-user-profile';
import {useTranslations} from 'hooks/use-translations';
import {useNativeListener} from 'hooks/use-native-listener';
import {deleteAccount} from 'services/account';
import {sendMessageToNativeApp} from 'services/native-api';

import DialogBox from 'components/DialogBox/DialogBox';

interface DeleteAccountDialogBoxProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteAccountDialogBox = (props: DeleteAccountDialogBoxProps) => {
  const {isOpen, onClose} = props;
  const {userProfile} = useUserProfile();
  const {translate} = useTranslations();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAccountConfirmation = async (
    shouldDeleteAccount: boolean,
  ) => {
    if (!shouldDeleteAccount) {
      onClose();
      return;
    }

    try {
      setIsDeleting(true);
      await deleteAccount({id: userProfile?.user?.id as number});
      const message = {
        type: NATIVE_MESSAGES.REQUEST_NEW_TOKEN,
      };
      sendMessageToNativeApp({message});
    } catch (e: any) {
      setIsDeleting(false);
    }
  };

  const handleNewToken = async () => {
    setIsDeleting(false);
  };

  useNativeListener({
    event: NATIVE_EVENTS.NEW_TOKEN,
    callback: handleNewToken,
  });

  return (
    <React.Fragment>
      {isOpen && (
        <DialogBox
          title={translate('sdk.web.account.delete.title')}
          promptMessage={translate('sdk.web.account.delete.prompt')}
          onConfirmation={handleDeleteAccountConfirmation}
          yesText={translate('sdk.web.dialog.box.confirm')}
          noText={translate('sdk.web.dialog.box.cancel')}
          isLoading={isDeleting}
        />
      )}
    </React.Fragment>
  );
};

export default DeleteAccountDialogBox;

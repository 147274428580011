import styled from 'styled-components';

const HeaderTextLg = styled.span`
  font-size: ${(props) => props.theme.font.styles.xLarge.fontSize}px;
  line-height: ${(props) => props.theme.font.styles.xLarge.fontSize * 1.2}px;
  font-weight: ${(props) => props.theme.font.styles.xLarge.fontWeight};

  @media only screen and (min-width: 375px) {
    font-size: ${(props) => props.theme.font.styles.xLarge.s375.fontSize}px;
    line-height: ${(props) =>
      props.theme.font.styles.xLarge.s375.fontSize * 1.2}px;
  }
`;

export default HeaderTextLg;

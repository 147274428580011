import React, {useContext} from 'react';
import {createInstance, MatomoProvider} from '@datapunt/matomo-tracker-react';
import {SettingsContext} from 'contexts/SettingsContext';

const AppTracker = (props: any) => {
  const {organizationConfig} = useContext(SettingsContext);
  if (!organizationConfig || !organizationConfig.name) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  const instance = createInstance({
    urlBase: 'https://matomo.changersdev.de/',
    userId: props.user.id.toString(),
    siteId: organizationConfig.matomoTrackingId,
    configurations: {
      disableCookies: true,
    },
  });

  // @ts-ignore
  return <MatomoProvider value={instance}>{props.children}</MatomoProvider>;
};

export default AppTracker;

import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {PROMPTS} from 'constants/prompts';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useOrganization} from 'hooks/use-organization';
import {AppLeaderBoardInterface} from 'services/teams';

import PromptModal from 'components/PromptModal/PromptModal';
import LinkText from 'components/Text/LinkText/LinkText';
import PreviousLeaderboardPrompt from './PreviousLeaderboardPrompt/PreviousLeaderboardPrompt';
import img from './images/team-form.svg';

const NotNowLink = styled(LinkText)`
  color: ${(props) =>
    props.theme.colors.promptLinkColor || props.theme.colors.black};
  display: block;
  margin-top: 15px;
  cursor: pointer;
  text-decoration: underline;
`;

interface ChallengeEndPromptProps {
  prompts: any;
  leaderboards: AppLeaderBoardInterface[];
}

const ChallengeEndPrompt = (props: ChallengeEndPromptProps) => {
  const {prompts, leaderboards} = props;
  const {translate} = useTranslations();
  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.CHALLENGE_ENDED,
  });
  const [
    isPreviousLeaderboardVisible,
    openPreviousLeaderboard,
    closePreviousLeaderBoard,
  ] = useBoolean();

  const {organizationConfig} = useOrganization();
  const teamsConfig = organizationConfig.theme.components.teams;

  const handlePromptClose = useCallback(
    (showPreviousLeaderboard = true) => {
      if (showPreviousLeaderboard) {
        openPreviousLeaderboard();
      }

      handleModalClose();
    },
    [handleModalClose, openPreviousLeaderboard],
  );

  const Footer = useMemo(
    () => (
      <NotNowLink onClick={() => handlePromptClose(false)}>
        {translate('sdk.web.teams.challenge.ended.skip')}
      </NotNowLink>
    ),
    [translate, handlePromptClose],
  );

  const previousLeaderboards = useMemo(() => {
    const sortedLeaderboards = [...leaderboards].sort((a) =>
      a.previousLeaderboards.length &&
      a.current?.teams?.find((team) => team.users?.me)
        ? 0
        : 1,
    );

    return sortedLeaderboards.length
      ? sortedLeaderboards[0].previousLeaderboards
      : [];
  }, [leaderboards]);

  if (!prompt) {
    return <React.Fragment />;
  }

  const challengeData = prompt?.data;

  const TextContent = (
    <div>
      {challengeData?.rankings?.teams.length ? (
        <div
          dangerouslySetInnerHTML={{
            __html: translate('sdk.web.teams.challenge.ranking', [
              {
                key: '{nr}',
                value: challengeData.rankings.teams[0].position,
              },
            ]),
          }}
        />
      ) : (
        ''
      )}
    </div>
  );

  return (
    <>
      <PromptModal
        isVisible={isVisible}
        title={translate('sdk.web.teams.challenge.ended')}
        textContent={TextContent}
        image={teamsConfig?.banner || img}
        btnText={translate('sdk.web.teams.show.previous.results')}
        onBtnClick={() => handlePromptClose()}
        extraContent={Footer}
      />
      <PreviousLeaderboardPrompt
        isVisible={isPreviousLeaderboardVisible}
        previousLeaderboards={previousLeaderboards}
        onClose={closePreviousLeaderBoard}
      />
    </>
  );
};

export default ChallengeEndPrompt;

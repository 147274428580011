import {useCallback, useState} from 'react';

type emptyFunc = () => void;

type useBooleanResponse = [boolean, emptyFunc, emptyFunc, emptyFunc];

export const useBoolean = (initialState?: boolean): useBooleanResponse => {
  const [isTrue, setIsTrue] = useState(!!initialState);

  const setToTrue = useCallback(() => setIsTrue(true), [setIsTrue]);

  const setToFalse = useCallback(() => setIsTrue(false), [setIsTrue]);

  const toggle = useCallback(() => setIsTrue(!isTrue), [isTrue, setIsTrue]);

  return [isTrue, setToTrue, setToFalse, toggle];
};

import React, {createContext, useEffect, useState} from 'react';
import {isAndroid} from 'react-device-detect';

import {
  aachen,
  agt,
  darmstadt,
  diehlmetering,
  dresden,
  duesseldorf,
  hamm,
  jt,
  klimataler,
  munster,
  payiq,
  sdksample,
  sintra,
} from 'config/organizations';
import fontFamilies from 'config/organizations/preset/font-families';
import {getDeviceLanguage} from 'utils/language';
import defaultSettings from 'config/settings';
import translations from 'config/translations';
import {loadFont} from 'utils/font';
import {mixColors, lightenColor} from 'utils/color';

export const SettingsContext = createContext();

const SettingsProvider = (props) => {
  // @TODO  use reducer
  const [appName, setAppName] = useState(defaultSettings.appName);
  const [appId, setAppId] = useState(defaultSettings.appId);
  const [appVersion, setAppVersion] = useState(
    defaultSettings.appVersion || '1.0.0',
  );
  const [sdkVersion, setSdkVersion] = useState(defaultSettings.sdkVersion);
  const [organizationConfig, setOrganizationConfig] = useState({});
  const [isAppInitialized, setIsAppInitialized] = useState(
    defaultSettings.isAppInitialized,
  );
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(
    defaultSettings.isPrivacyPolicyAccepted,
  );
  const [didAppInitFail, setDidAppInitFail] = useState(false);
  const [initFailedMessage, setInitFailedMessage] = useState('');
  const [isMotionTrackingEnabled, setIsMotionTrackingEnabled] = useState(false);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const [appPermissions, setAppPermissions] = useState(
    defaultSettings.appPermissions,
  );
  const [isGPSEnabled, setIsGPSEnabled] = useState(false);
  const [isMotionFitnessEnabled, setIsMotionFitnessEnabled] = useState(false);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
  const [isBatteryOptimizationEnabled, setIsBatteryOptimizationEnabled] =
    useState(false);

  const [lastTransmitted, setLastTransmitted] = useState('');
  const [lastVoucherUpdate, setLastVoucherUpdate] = useState('');
  const [bodyBgColor, setBodyBgColor] = useState('#FFFFFF');
  const [appFeatures, setAppFeatures] = useState(defaultSettings.appFeatures);
  const [debugInfo, setDebugInfo] = useState(defaultSettings.debugInfo);
  const [initialPage, setInitialPage] = useState('');

  const settings = {
    appName,
    appId,
    appVersion,
    sdkVersion,
    isAppInitialized,
    didAppInitFail,
    initFailedMessage,
    isPrivacyPolicyAccepted,
    isMotionTrackingEnabled,
    appPermissions,
    isGPSEnabled,
    isMotionFitnessEnabled,
    isNotificationEnabled,
    isBatteryOptimizationEnabled,
    isOnboardingComplete,
    lastTransmitted,
    bodyBgColor,
    lastVoucherUpdate,
    organizationConfig,
    appFeatures,
    debugInfo,
    initialPage,
    setIsAppInitialized,
    setInitFailedMessage,
    setDidAppInitFail,
    setLastTransmitted,
    setAppPermissions,
    setIsMotionTrackingEnabled,
    setIsGPSEnabled,
    setIsMotionFitnessEnabled,
    setIsPrivacyPolicyAccepted,
    setIsNotificationEnabled,
    setIsBatteryOptimizationEnabled,
    setIsOnboardingComplete,
    setBodyBgColor,
    setAppName,
    setAppId,
    setAppVersion,
    setSdkVersion,
    setLastVoucherUpdate,
    setAppFeatures,
    setInitialPage,
    setDebugInfo,
    setOrganizationConfig,
  };

  useEffect(() => {
    let config = {};

    switch (appId) {
      case 'darmstadt':
        config = darmstadt;
        break;
      case 'muenster':
        config = munster;
        break;
      case 'jt':
        config = jt;
        break;
      case 'sdksample':
        config = sdksample;
        break;
      case 'duesseldorf':
        config = duesseldorf;
        break;
      case 'dresden':
        config = dresden;
        break;
      case 'diehlmetering':
        config = diehlmetering;
        break;
      case 'africagreentech':
        config = agt;
        break;
      case 'hamm':
        config = hamm;
        break;
      case 'aachen':
        config = aachen;
        break;
      case 'sintra':
        config = sintra;
        break;
      case 'klimataler':
        config = klimataler;
        break;
      case 'payiq':
        config = payiq;
        break;
      default:
        setOrganizationConfig({});
        return;
    }

    setOrganizationConfig(parseConfig({config}));
  }, [appId]);

  const parseConfig = ({config}) => {
    const deviceLanguage = getDeviceLanguage();
    const device = isAndroid ? 'android' : 'ios';
    const deviceFonts = (config.theme.fonts[deviceLanguage] ||
      config.theme.fonts['en'])[device];
    const defaultFont = fontFamilies.default;
    const defaultStyles = defaultFont.styles;

    const mergeDefaultStyles = () => {
      const primaryFont = fontFamilies[deviceFonts.primary] || defaultFont;
      const secondaryFont = fontFamilies[deviceFonts.secondary] || {};

      if (primaryFont.url) {
        loadFont(primaryFont);
      }

      if (secondaryFont.url) {
        loadFont(secondaryFont);
      }

      return {
        ...primaryFont,
        primary: {
          name: primaryFont.name,
        },
        secondary: {
          name: secondaryFont.name,
        },
        styles: {
          ...defaultStyles,
          ...primaryFont.styles,
          ...secondaryFont.styles,
        },
      };
    };

    const font = mergeDefaultStyles();
    if (
      !(config.theme.colors.body || config.theme.colors.primary).includes(
        'gradient',
      )
    ) {
      config.theme.colors.overlayBodyBg = mixColors({
        bg: config.theme.colors.body || config.theme.colors.primary,
        fg: config.theme.colors.promptOverlayBg,
      });
    }

    config.theme.colors.lightSecondary = lightenColor({
      bg: config.theme.colors.secondary,
      fg: config.theme.colors.white,
      opacity: 0.55,
    });
    config.theme.colors.lighterSecondary = lightenColor({
      bg: config.theme.colors.secondary,
      fg: config.theme.colors.white,
      opacity: 0.2,
    });

    const parseColors = (component) => {
      if (!component) {
        return;
      }

      Object.keys(component).forEach((key) => {
        const value = component[key];
        if (typeof value === 'object') {
          return parseColors(value);
        }

        if (key.endsWith('Color') || key === 'color') {
          component[key] = config.theme.colorPalette[value] || value;
        }
      });
    };

    parseColors(config.theme.components);

    return {
      ...config,
      theme: {
        ...config.theme,
        font,
      },
      translations: {
        ...translations,
        ...config.translations,
      },
    };
  };

  return (
    <SettingsContext.Provider value={settings}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;

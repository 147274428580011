import {useCallback, useContext} from 'react';
import {toast} from 'react-toastify';

import {SettingsContext} from 'contexts/SettingsContext';

export const useToast = () => {
  const {organizationConfig} = useContext(SettingsContext);

  const success = useCallback(
    (message: string) =>
      toast.success(message, {
        style: {
          backgroundColor: organizationConfig.theme.colors.primary,
        },
        className: `Toastify__toast--app-${
          organizationConfig.theme.components.alerts?.success?.theme ||
          'default'
        }`,
      }),
    [organizationConfig],
  );

  const error = useCallback((message: string) => toast.error(message), []);

  return {success, error};
};

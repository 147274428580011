import React from 'react';
import styled from 'styled-components';
import {PurchasedCompensation} from 'types/Compensation';
import {ClimateSun} from 'hooks/use-climate-suns';
import {useTranslations} from 'hooks/use-translations';
import PromptWrapper, {
  Title,
} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import TextMedium from 'components/Text/TextMedium/TextMedium';

const Content = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SunIcon = styled.div`
  width: 172px;
  margin-bottom: 20px;
`;

const WrapperTextMedium = styled(TextMedium)`
  font-size: ${(props) =>
    props.theme.components.co2Compensation?.descriptionFontSize}px;
`;

const currentYear = new Date().getFullYear();

type CompensationPromptProps = {
  isVisible: boolean;
  climateMonth?: ClimateSun & {compensation?: PurchasedCompensation};
  onClose: () => void;
};

const CompensationPrompt = ({
  isVisible,
  climateMonth,
  onClose,
}: CompensationPromptProps) => {
  const {translate} = useTranslations();

  return (
    <PromptWrapper isVisible={isVisible} onClose={onClose}>
      {climateMonth && climateMonth?.compensation && (
        <Content>
          <Title>
            {climateMonth.monthText} {currentYear}
          </Title>
          <SunIcon>
            <climateMonth.Icon />
          </SunIcon>
          <WrapperTextMedium>
            {translate('sdk.web.compensation.widget.month.prompt')}{' '}
            {climateMonth?.compensation.total_user_impact}x
          </WrapperTextMedium>
        </Content>
      )}
    </PromptWrapper>
  );
};

export default CompensationPrompt;

import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';

import HeaderText from 'components/Text/HeaderText/HeaderText';
import InfoPage from 'components/InfoPage/InfoPage';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import {ReactComponent as Caret} from 'icons/caret-right-lg.svg';

const CategoryEl = styled.div`
  margin-bottom: 40px;

  .category {
    &__title {
      margin-bottom: 27px;
      color: #000;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
`;

const Question = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};

  .question {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 7px;
      color: #000;
    }

    &__title {
      font-weight: bold;
      display: block;
      padding-right: 20px;
      color: #000;
    }

    &__caret {
      flex-shrink: 0;
      width: 9px;
      fill: ${(props) => props.theme.colors.primary};
    }

    &__answer {
      display: block;
      padding-right: 30px;
    }
  }
`;

const Details = styled.div`
  padding: 0 20px;
  text-align: left;

  .details {
    &__title {
      margin-bottom: 20px;
    }
  }

  img {
    max-width: 100%;
  }
`;

const Contact = styled.div`
  padding-bottom: 40px;

  .contact {
    &__title {
      margin-bottom: 20px;
      color: #000;
    }
  }
`;

export type Category = {
  id: string;
  title: string;
  cta: string;
  questions: {
    id: string;
    title: string;
    answer: string;
    answerSummary: string;
  }[];
};

interface FaqCategoryProps {
  category: Category;
}

const FaqCategory = (props: FaqCategoryProps) => {
  const {category} = props;
  const history = useHistory();
  const {translate} = useTranslations();
  const [activeQuestion, setActiveQuestion] = useState<
    Category['questions'][0]
  >();

  const onClose = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.SETTINGS.FAQ.href);
    }
  };

  const handleContact = useCallback(() => {
    history.push(routes.SETTINGS.CONTACT.FORM.href);
  }, [history]);

  if (!category) {
    return null;
  }

  return (
    <>
      <InfoPage
        title={translate('sdk.web.contact.title')}
        onClose={onClose}>
        <CategoryEl className="category">
          <div className="category__title">
            <HeaderText>{translate('sdk.web.faq.category.intro')}</HeaderText>
          </div>
          <ul className="category__list">
            {category.questions.map((question) => (
              <Question
                key={question.id}
                className="category__question question"
                onClick={() => setActiveQuestion(question)}>
                <div className="question__header">
                  <CopyText className="question__title">
                    {question.title}
                  </CopyText>
                  <div className="question__caret">
                    <Caret />
                  </div>
                </div>
                <CopyTextSm>{question.answerSummary}</CopyTextSm>
              </Question>
            ))}
          </ul>
        </CategoryEl>
        <Contact>
          <Button onClick={handleContact}>
            {translate('sdk.web.faq.contact.cta')}
          </Button>
        </Contact>
      </InfoPage>
      {!!activeQuestion && (
        <PromptWrapper
          isVisible={true}
          onClose={() => setActiveQuestion(undefined)}>
          <Details>
            <div className="details__title">
              <HeaderText>{activeQuestion.title}</HeaderText>
            </div>
            <CopyTextSm as="div" className="default-styles details__answer">
              <div dangerouslySetInnerHTML={{__html: activeQuestion.answer}} />
            </CopyTextSm>
          </Details>
        </PromptWrapper>
      )}
    </>
  );
};

export default FaqCategory;

const config = {
  widgets: [
    {
      enviroments: ['production', 'staging', 'local'],
      apps: ['sintra'],
      elementName: 'app-root',
      scriptUrl: 'https://www.dstinovacao.com/_widget/bundle.js',
      position: 'after-token',
    },
    {
      enviroments: ['production'],
      apps: ['sintra'],
      elementName: 'my-smile-psi',
      scriptUrl: 'https://mysmile.4cloud.pt/Share/bundle.js',
      position: 'after-token',
      additionalElements: []
    },
    {
      enviroments: ['staging', 'local'],
      apps: ['sintra'],
      elementName: 'my-smile-psi',
      scriptUrl: 'https://mysmile.4cloud.pt/Share/bundle.js',
      position: 'after-token',
      additionalElements: ['my-smile-dod']
    },
    {
      enviroments: ['local'],
      apps: ['klimataler'],
      elementName: 'hello-world-valila',
      scriptUrl: '/open-widgets/demo/hello-world-valila.js',
      position: 'after-token',
      additionalElements: []
    }
  ],
};

export default config;

import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useOrganization} from 'hooks/use-organization';

import img0 from './images/0.svg';
import img1 from './images/1.svg';
import img2 from './images/2.svg';
import img3 from './images/3.svg';
import img4 from './images/4.svg';
import img5 from './images/5.svg';
import img6 from './images/6.svg';
import img7 from './images/7.svg';
import img8 from './images/8.svg';
import img9 from './images/9.svg';
import img10 from './images/10.svg';
import img11 from './images/11.svg';
import img12 from './images/12.svg';
import img13 from './images/13.svg';
import img14 from './images/14.svg';
import img15 from './images/15.svg';
import img16 from './images/16.svg';
import img17 from './images/17.svg';
import img18 from './images/18.svg';
import img19 from './images/19.svg';
import img20 from './images/20.svg';
import img21 from './images/21.svg';
import img22 from './images/22.svg';
import img23 from './images/23.svg';
import img24 from './images/24.svg';
import img25 from './images/25.svg';
import img26 from './images/26.svg';
import img27 from './images/27.svg';
import img28 from './images/28.svg';
import img29 from './images/29.svg';
import img30 from './images/30.svg';
import img31 from './images/31.svg';
import img32 from './images/32.svg';
import img33 from './images/33.svg';
import img34 from './images/34.svg';
import img35 from './images/35.svg';
import img36 from './images/36.svg';
import img37 from './images/37.svg';
import img38 from './images/38.svg';
import img39 from './images/39.svg';
import img40 from './images/40.svg';

const images = [
  img0,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
];

const Img = styled.img`
  display: block;
  width: 100%;
  max-width: 200%;
`;

const TreeProgress = (props: any) => {
  const {treesPlanted} = props;
  const {organizationConfig} = useOrganization();

  const treePlantingImages = useMemo(
    () =>
      organizationConfig.theme.components.treePlanting?.progressImages ||
      images,
    [organizationConfig],
  );

  const index =
    treesPlanted >= treePlantingImages.length
      ? treePlantingImages.length - 1
      : treesPlanted;
  const img = treePlantingImages[index];

  return <Img src={img} alt="" />;
};

export default TreeProgress;

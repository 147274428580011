import React from 'react';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useOrganization} from 'hooks/use-organization';
import {getPartners} from 'services/co2Compensation';

import CompensationProjects from 'components/CompensationProjects/CompensationProjects';

const CompensationPage = () => {
  const {organizationConfig} = useOrganization();

  useBodyBg(
    organizationConfig.theme.components.co2Compensation.pageBgColor
      ? {
          color:
            organizationConfig.theme.components.co2Compensation.pageBgColor,
        }
      : {
          type:
            organizationConfig.theme.components.co2Compensation?.pageBg ||
            'lightSecondary',
        },
  );

  const {isLoading, data} = useFetcher({
    fetcher: getPartners,
    initialValue: [],
    key: CACHE_KEYS.COMPENSATION_PARTNERS,
  });

  return <CompensationProjects isLoading={isLoading} partners={data} />;
};

export default CompensationPage;

import React from 'react';
import {renderToString} from 'react-dom/server';
import {ReactComponent as ApparelIcon} from 'icons/climate-categories/apparel.svg';
import {ReactComponent as BakeryIcon} from 'icons/climate-categories/bakery.svg';
import {ReactComponent as BarIcon} from 'icons/climate-categories/bar.svg';
import {ReactComponent as BookStoreIcon} from 'icons/climate-categories/book-store.svg';
import {ReactComponent as Bicycle} from 'icons/climate-categories/bicycle.svg';
import {ReactComponent as CafeIcon} from 'icons/climate-categories/cafe.svg';
import {ReactComponent as CinemaIcon} from 'icons/climate-categories/cinema.svg';
import {ReactComponent as CosmeticsIcon} from 'icons/climate-categories/cosmetics.svg';
import {ReactComponent as CultureIcon} from 'icons/climate-categories/culture.svg';
import {ReactComponent as EChargingIcon} from 'icons/climate-categories/e-charging.svg';
import {ReactComponent as ElectronicsIcon} from 'icons/climate-categories/electronics.svg';
import {ReactComponent as GroceryIcon} from 'icons/climate-categories/grocery.svg';
import {ReactComponent as GymIcon} from 'icons/climate-categories/gym.svg';
import {ReactComponent as HairdresserIcon} from 'icons/climate-categories/hairdresser.svg';
import {ReactComponent as HealthIcon} from 'icons/climate-categories/health.svg';
import {ReactComponent as HomeGardenIcon} from 'icons/climate-categories/home-garden.svg';
import {ReactComponent as HomeAppliancesIcon} from 'icons/climate-categories/home-appliances.svg';
import {ReactComponent as HotelIcon} from 'icons/climate-categories/hotel.svg';
import {ReactComponent as HouseWareIcon} from 'icons/climate-categories/houseware.svg';
import {ReactComponent as JewelryIcon} from 'icons/climate-categories/jewelry.svg';
import {ReactComponent as MobilityIcon} from 'icons/climate-categories/mobility.svg';
import {ReactComponent as MuseumIcon} from 'icons/climate-categories/museum.svg';
import {ReactComponent as OutdoorIcon} from 'icons/climate-categories/outdoor.svg';
import {ReactComponent as PharmacyIcon} from 'icons/climate-categories/pharmacy.svg';
import {ReactComponent as PhotoIcon} from 'icons/climate-categories/photo.svg';
import {ReactComponent as Restaurant} from 'icons/climate-categories/restaurant.svg';
import {ReactComponent as ShoesIcon} from 'icons/climate-categories/shoes.svg';
import {ReactComponent as SightIcon} from 'icons/climate-categories/sight.svg';
import {ReactComponent as SportsEquipmentIcon} from 'icons/climate-categories/sport-equipment.svg';
import {ReactComponent as SportsEventIcon} from 'icons/climate-categories/sport-event.svg';
import {ReactComponent as StationeryIcon} from 'icons/climate-categories/stationery.svg';
import {ReactComponent as SwimmingPoolIcon} from 'icons/climate-categories/swimming-pool.svg';
import {ReactComponent as ToysIcon} from 'icons/climate-categories/toys.svg';
import {ReactComponent as WellnessIcon} from 'icons/climate-categories/wellness.svg';
import {ReactComponent as YogaIcon} from 'icons/climate-categories/yoga.svg';
import {ReactComponent as Fallback} from 'icons/climate-categories/fallback.svg';

export const APPAREL = 'apparel';
export const BAKERY = 'bakery';
export const BAR = 'bar';
export const BOOK_STORE = 'book_store';
export const CAFE = 'cafe';
export const BICYCLE = 'bicycle';
export const CINEMA = 'cinema';
export const COSMETICS = 'cosmetics';
export const CULTURE = 'culture';
export const E_CHARGING = 'e_charging';
export const ELECTRONICS = 'electronic';
export const GROCERY = 'grocery';
export const GYM = 'gym';
export const HAIRDRESSER = 'hairdresser';
export const HEALTH = 'health';
export const HOME_GARDEN = 'home_garden';
export const HOME_APPLIANCES = 'home_appliances';
export const HOTEL = 'hotel';
export const HOUSE_WARE = 'houseware';
export const JEWELRY = 'jewelry';
export const MOBILITY = 'mobility';
export const MUSEUM = 'museum';
export const OUTDOOR = 'outdoor';
export const PHARMACY = 'pharmacy';
export const PHOTO = 'photo';
export const RESTAURANT = 'restaurant';
export const SHOES = 'shoes';
export const SIGHT = 'sight';
export const SPORTS_EQUIPMENT = 'sports';
export const SPORTS_EVENT = 'sports_event';
export const STATIONERY = 'stationery';
export const SWIMMING_POOL = 'swimming_pool';
export const TOYS = 'toys';
export const WELLNESS = 'wellness';
export const YOGA = 'yoga';
export const FALLBACK_CLIMATE_ICON = 'default';

type ClimateCategoriesIcon = {
  [key: string]: React.FunctionComponent;
};

export const CLIMATE_CATEGORIES_ICONS: ClimateCategoriesIcon = {
  [APPAREL]: ApparelIcon,
  [BAKERY]: BakeryIcon,
  [BAR]: BarIcon,
  [BICYCLE]: Bicycle,
  [BOOK_STORE]: BookStoreIcon,
  [CAFE]: CafeIcon,
  [CINEMA]: CinemaIcon,
  [COSMETICS]: CosmeticsIcon,
  [CULTURE]: CultureIcon,
  [E_CHARGING]: EChargingIcon,
  [ELECTRONICS]: ElectronicsIcon,
  [GROCERY]: GroceryIcon,
  [GYM]: GymIcon,
  [HAIRDRESSER]: HairdresserIcon,
  [HEALTH]: HealthIcon,
  [HOME_GARDEN]: HomeGardenIcon,
  [HOME_APPLIANCES]: HomeAppliancesIcon,
  [HOTEL]: HotelIcon,
  [HOUSE_WARE]: HouseWareIcon,
  [JEWELRY]: JewelryIcon,
  [MOBILITY]: MobilityIcon,
  [MUSEUM]: MuseumIcon,
  [OUTDOOR]: OutdoorIcon,
  [PHARMACY]: PharmacyIcon,
  [PHOTO]: PhotoIcon,
  [RESTAURANT]: Restaurant,
  [SHOES]: ShoesIcon,
  [SIGHT]: SightIcon,
  [SPORTS_EQUIPMENT]: SportsEquipmentIcon,
  [SPORTS_EVENT]: SportsEventIcon,
  [STATIONERY]: StationeryIcon,
  [SWIMMING_POOL]: SwimmingPoolIcon,
  [TOYS]: ToysIcon,
  [WELLNESS]: WellnessIcon,
  [YOGA]: YogaIcon,
  [FALLBACK_CLIMATE_ICON]: Fallback,
};

type ClimateIconTemplates = {
  [key: string]: string;
};

export const CLIMATE_ICONS_TEMPLATES: ClimateIconTemplates = {};

Object.keys(CLIMATE_CATEGORIES_ICONS).forEach((key) => {
  const Icon = CLIMATE_CATEGORIES_ICONS[key];
  CLIMATE_ICONS_TEMPLATES[key] = renderToString(<Icon />);
});

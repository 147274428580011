export enum AccountType {
  USER = 1,
  GUEST = 2,
}

export type UserProfile = {
  user: {
    id: number;
    recoins: number;
  };
  organization: {
    id: number;
  };
};

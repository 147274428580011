import {useContext, useEffect} from 'react';

import {CACHE_KEYS} from 'constants/cache-keys';
import {NotificationContext} from 'contexts/NotificationContext';
import {AuthContext} from 'contexts/AuthContext';
import useNotificationsStats from 'hooks/services/notifications/use-notifications-stats';
import {useFetcher} from 'hooks/use-fetcher';
import {getNotifications} from 'services/notifications';
import {Notification} from 'types/Notification';

const useNotificationsLoader = () => {
  const {init, addPrivateChannelListener} = useContext(NotificationContext);
  const {userProfile} = useContext(AuthContext);
  const {refreshStats} = useNotificationsStats();

  const {addDataItem} = useFetcher<Array<Notification>>({
    fetcher: getNotifications,
    key: CACHE_KEYS.NOTIFICATIONS,
    initialValue: [],
  });

  useEffect(
    () => {
      if (userProfile) {
        init({userProfile}).then(() => {});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userProfile],
  );

  useEffect(
    () => {
      const event =
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';
      const channel = addPrivateChannelListener!({
        event,
        callback: (data: any) => {
          refreshStats();
          addDataItem({data});
        },
      });

      return () => {
        channel?.unbind(event);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [addPrivateChannelListener],
  );

  useEffect(() => {
    function onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        refreshStats();
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [refreshStats]);
};

export default useNotificationsLoader;

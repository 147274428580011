import React, {useContext, useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {CACHE_KEYS} from 'constants/cache-keys';
import {LocationContext} from 'contexts/LocationContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';
import {getClimatePartners} from 'services/vouchers';
import {getOptimizedDistance} from 'utils/numbers';
import {ClimatePartner} from 'types/Offers';

import Loader from 'components/Loader/Loader';
import DetailsNavigation from 'components/DetailsNavigation/DetailsNavigation';
import Modal from 'components/Modal/Modal';
import OffersMap from 'components/Rewards/OffersMap/OffersMap';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
`;

const Body = styled.div`
  background: ${(props) => props.theme.colors.white};
  flex-grow: 1;
  display: flex;
  padding-top: 73px;
  height: 100vh;
`;

const defaultCenter = {
  lat: 6.465422,
  lng: 3.406448,
};

const NearbyOffersPage = () => {
  const history = useHistory();
  const {translate} = useTranslations();
  const {location, updateCurrentLocation} = useContext(LocationContext);
  const {organizationConfig} = useOrganization();

  const {isLoading, data, fetchData} = useFetcher<ClimatePartner[]>({
    fetcher: getClimatePartners,
    key: CACHE_KEYS.CLIMATE_PARTNERS,
    initialValue: [],
    params: {location},
  });
  const handleClose = () => {
    history.goBack();
  };

  const userLocation = useMemo(
    () => (location ? {lat: location.latitude, lng: location.longitude} : null),
    [location],
  );

  useEffect(
    () => {
      if (!location) {
        return;
      }

      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  );

  useEffect(() => {
    updateCurrentLocation();
  }, [updateCurrentLocation]);

  const climatePartners = useMemo(
    () =>
      data.map((climatePartner) => ({
        data: climatePartner,
        location: getOptimizedDistance({
          distance: climatePartner.distance,
          optimizeDistance: true,
        }),
        vouchers: [],
      })),
    [data],
  );

  const mapCenter = useMemo(
    () =>
      userLocation
        ? userLocation
        : climatePartners.length
        ? {
            lat: climatePartners[0].data.latitude,
            lng: climatePartners[0].data.longitude,
          }
        : defaultCenter,
    [userLocation, climatePartners],
  );

  return (
    <Wrapper>
      <Modal>
        <DetailsNavigation
          onClose={handleClose}
          text={translate(
            organizationConfig.translations?.offers?.mapTitle ||
              'sdk.web.offers.climate.partners',
          )}
        />
        <Body>
          {isLoading && !data.length ? (
            <Loader color={'#222'} />
          ) : (
            <OffersMap
              vouchers={[]}
              location={location}
              mapCenter={mapCenter}
              userLocation={userLocation}
              climatePartners={climatePartners}
            />
          )}
        </Body>
      </Modal>
    </Wrapper>
  );
};

export default NearbyOffersPage;

import React from 'react';
import styled from 'styled-components';
import {usePromptApi} from 'hooks/usePromptApi';
import {PROMPTS} from 'constants/prompts';
import LotLoss from '../LotLoss/LotLoss';

const Wrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 40000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  color: ${(props) => props.theme.colors.black};
`;

const LotLossPrompt = (props: any) => {
  const {prompts} = props;
  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.LOTTERY_LOST,
  });

  if (!prompt) {
    return <React.Fragment />;
  }

  const lot = prompt.data;

  return (
    <React.Fragment>
      {isVisible && (
        <Wrapper>
          <LotLoss lot={lot} onClose={handleModalClose} />
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default LotLossPrompt;

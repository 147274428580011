import React, {useCallback, useMemo} from 'react';

import {QR_CODE_ACTIONS} from 'constants/qr-codes';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useToast} from 'hooks/use-toast';
import {useTranslations} from 'hooks/use-translations';
import {ScanResponse, useQrScanner} from 'hooks/use-qr-scanner';
import {completeActivity} from 'services/customActivity';
import {CustomActivity} from 'types/CustomActivity';

import DefaultSuccessPrompt from 'components/CustomActivities/SuccessPrompt';
import DialogBox from 'components/DialogBox/DialogBox';

export type OnActivityComplete = ({
  activity,
}: {
  activity: CustomActivity;
}) => void;

type SuccessPromptProps = {
  onClose?: () => void;
};

type UseCustomActivityProps = {
  activity: CustomActivity;
  onActivityComplete: OnActivityComplete;
};

export type WidgetProps = {
  activities: CustomActivity[];
  handleActivityComplete: OnActivityComplete;
};

const useCustomActivity = (props: UseCustomActivityProps) => {
  const {activity, onActivityComplete} = props;
  const toast = useToast();
  const {translate} = useTranslations();
  const [isCompleting, startCompleting, stopCompleting] = useBoolean(false);
  const [isPromptVisible, showPrompt, hidePrompt] = useBoolean(false);
  const [isDialogVisible, showDialog, hideDialog] = useBoolean();

  const performActivityRequest = useCallback(
    async ({token}: {token?: string}) => {
      try {
        startCompleting();
        await completeActivity({id: activity.id, token});

        showPrompt();
        onActivityComplete({activity});
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.ca.complete.error.fallback',
        );
        toast.error(message);
      } finally {
        stopCompleting();
      }
    },
    [
      activity,
      startCompleting,
      stopCompleting,
      showPrompt,
      toast,
      translate,
      onActivityComplete,
    ],
  );

  const handleQRScan = useCallback(
    async ({id: token, action}: ScanResponse) => {
      if (!token || action !== QR_CODE_ACTIONS.CUSTOM_ACTIVITY) {
        toast.error(translate('sdk.web.ca.qrCode.error'));
        stopCompleting();
        return;
      }

      await performActivityRequest({token: token as string});
    },
    [toast, performActivityRequest, stopCompleting, translate],
  );

  const {openScanner} = useQrScanner({
    preventListen: true,
    isOneTime: true,
    onScan: handleQRScan,
    onClose: stopCompleting,
  });

  const performActivity = useCallback(async () => {
    if (activity.qr_code) {
      openScanner();
      startCompleting();
    } else {
      await performActivityRequest({});
    }
  }, [activity, performActivityRequest, openScanner, startCompleting]);

  const handleConfirmation = useCallback(
    (completeActivity: boolean) => {
      if (completeActivity) {
        performActivity();
      }

      hideDialog();
    },
    [performActivity, hideDialog],
  );

  const SuccessPrompt = useMemo(
    () =>
      ({onClose}: SuccessPromptProps) =>
        (
          <DefaultSuccessPrompt
            isVisible={isPromptVisible}
            onClose={() => {
              hidePrompt();
              onClose && onClose();
            }}
            activity={activity}
          />
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hidePrompt, isPromptVisible],
  );

  const DialogPrompt = useMemo(
    () => () =>
      isDialogVisible ? (
        <DialogBox
          title={activity.name}
          promptMessage={translate('sdk.web.ca.confirm')}
          yesText={translate('sdk.web.dialog.box.ok')}
          noText={translate('sdk.web.dialog.box.cancel')}
          onConfirmation={handleConfirmation}
        />
      ) : null,
    [activity.name, translate, handleConfirmation, isDialogVisible],
  );

  return {
    isCompleting,
    isDialogVisible,
    performActivity: showDialog,
    SuccessPrompt,
    DialogPrompt,
  };
};

export default useCustomActivity;

import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslations} from 'hooks/use-translations';
import useNewFeatures, {NewFeature} from 'hooks/app/use-new-features';

import DialogBox from 'components/DialogBox/DialogBox';
import WidgetTile from 'components/ClimateWidgets/Widget/WidgetTile';

const NewFeatures = () => {
  const history = useHistory();
  const {translate} = useTranslations();
  const {getNewFeatures, markFeatureAsSeen} = useNewFeatures();
  const [features, setFeatures] = useState<NewFeature[]>(getNewFeatures);

  const [featureToRemove, setFeatureToRemove] = useState<NewFeature | null>(
    null,
  );

  const handleRemovalConfirmation = useCallback(
    (remove: boolean) => {
      if (!remove) {
        setFeatureToRemove(null);
        return;
      }

      markFeatureAsSeen(featureToRemove!.key);
      setFeatureToRemove(null);
      setFeatures(getNewFeatures);
    },
    [getNewFeatures, markFeatureAsSeen, featureToRemove],
  );

  return (
    <>
      {features.map((feature) => (
        <WidgetTile
          key={feature.key}
          title={feature.title}
          subtitle={feature.subtitle}
          image={feature.icon}
          onClick={() => history.push(feature.link)}
          onClose={() => setFeatureToRemove(feature)}
        />
      ))}
      {featureToRemove && (
        <DialogBox
          title={translate('sdk.web.features.new.remove')}
          promptMessage={featureToRemove.removalText}
          onConfirmation={handleRemovalConfirmation}
          yesText={translate('sdk.web.dialog.box.confirm')}
          noText={translate('sdk.web.dialog.box.cancel')}
        />
      )}
    </>
  );
};

export default NewFeatures;

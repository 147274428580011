import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

import CopyText from 'components/Text/CopyText/CopyText';
import {ReactComponent as Caret} from 'icons/caret-right-lg.svg';

const Wrapper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;

  .list-item {
    &__caret {
      flex-shrink: 0;
      width: 9px;
      fill: ${(props) => props.theme.colors.primary};
    }
  }
}
`;

interface ListItemProps extends PropsWithChildren {
  className?: string;
  onClick: () => any;
}

const ListItem = (props: ListItemProps) => {
  const {children, ...rest} = props;

  return (
    <Wrapper {...rest}>
      <CopyText as="div">{children}</CopyText>
      <div className="list-item__caret">
        <Caret />
      </div>
    </Wrapper>
  );
};

export default ListItem;

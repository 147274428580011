import {useCallback, useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';

import {IGNORE_ERROR_CODE} from 'constants/api-errors';
import {useTranslations} from 'hooks/use-translations';
import {addCacheData, getCachedData} from 'services/cache';

interface UpdateDataById {
  idKey: string;
  id: number;
  updates: any;
}

interface useFetcherPropsInterface {
  fetcher: (params: any) => any;
  key?: string;
  params?: any;
  initialValue?: any;
  preventFetch?: boolean;
  hideErrorToast?: boolean;
  paginate?: boolean;
  limit?: number;
}

type FetchDataOptions = {
  resetPage?: boolean;
  newKey?: string;
};

type FetchParams = {
  key?: string;
  page?: number;
  newParams?: any;
  options?: FetchDataOptions;
};

type FetchDataWithKeyParams = {
  newParams?: any;
  key: string;
  options?: FetchDataOptions;
};

type UseFetcherData<T> = {
  isLoading: boolean;
  data: T;
  error: any;
  addDataItem: (params: {data: any}) => any;
  fetchData: (newParams?: any, options?: FetchDataOptions) => any;
  fetchDataWithKey: (params: FetchDataWithKeyParams) => any;
  updateDataById: any;
  pagination: {
    page: number | undefined;
    hasMore: boolean;
  };
};

export const useFetcher = <T = any>(
  props: useFetcherPropsInterface,
): UseFetcherData<T> => {
  const {
    fetcher,
    params,
    initialValue,
    paginate,
    limit,
    key,
    preventFetch,
    hideErrorToast,
  } = props;
  const {translate} = useTranslations();

  const activeKey = useRef(key);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialValue);
  const [error, setError] = useState(null);
  const [page, setPage] = useState<number | undefined>(
    paginate ? 1 : undefined,
  );
  const [hasMore, setHasMore] = useState(false);

  const fetch = useCallback(
    async ({page, options, newParams, key}: FetchParams) => {
      try {
        // Set active key to prevent race conditions
        activeKey.current = key;

        const data = await fetcher({
          ...(newParams || params || null),
          ...(paginate ? {page, limit} : null),
        });

        // Update data only if the cache key is still the same to prevent race conditions
        if (activeKey.current === key) {
          if (paginate && data instanceof Array) {
            setData((prevData: Array<any>) => [
              ...(page === 1 ? [] : prevData),
              ...data,
            ]);

            setPage((prevPage) => (options?.resetPage ? 1 : prevPage || 0) + 1);
            // Set hasMore to true if there's new data and data length is less than the limit
            setHasMore(data.length > 0 && (!limit || data.length >= limit));
          } else {
            setData(data);
          }

          setIsLoading(false);
        }

        return data;
      } catch (e: any) {
        setError(e);
        setIsLoading(false);
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        if (errorKey && errorKey !== IGNORE_ERROR_CODE) {
          if (!hideErrorToast) {
            toast.error(translate(errorKey));
          }
        }
      }
    },
    [params, limit, paginate, hideErrorToast, fetcher, translate],
  );

  const fetchData = useCallback(
    async (newParams?: any, options?: FetchDataOptions) => {
      const cachedData = getCachedData({key});
      const _page = options?.resetPage ? 1 : page;

      if (cachedData) {
        // Only save data to state if it's the first page or there's no page
        if (!_page || _page === 1) {
          setData(cachedData);
          setIsLoading(false);
        }
      } else {
        // Start loading if there's no cached data
        setIsLoading(true);
      }

      await fetch({page: _page, options, key, newParams});
    },
    [fetch, key, page],
  );

  const fetchDataWithKey = useCallback(
    async ({key, newParams, options}: FetchDataWithKeyParams) => {
      const cachedData = getCachedData({key});
      if (cachedData) {
        setData(cachedData);
        setIsLoading(false);
      } else {
        // Reset data and loader
        setData(initialValue);
        setIsLoading(true);
      }

      // Save new data with new key
      const newData = await fetch({newParams, options, key});
      if (newData) {
        addCacheData({key, data: newData});
      }
    },
    [initialValue, fetch],
  );

  const addDataItem = useCallback(({data}: {data: any}) => {
    setData((prevData: any) => {
      if (prevData instanceof Array) {
        return [data, ...prevData];
      } else {
        return data;
      }
    });
  }, []);

  const updateDataById = useCallback(({idKey, id, updates}: UpdateDataById) => {
    setData((data: any) =>
      data instanceof Array
        ? data.map((item: any) => {
            const foundItem = item[idKey] === id;
            return foundItem ? {...item, ...updates} : {...item};
          })
        : {...data, ...updates},
    );
  }, []);

  useEffect(
    () => {
      if (!preventFetch) {
        fetchData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preventFetch],
  );

  useEffect(() => {
    if (!key || (page && page > 2) || !data) {
      return;
    }

    addCacheData({
      key,
      data,
    });
  }, [data, key, page]);

  return {
    isLoading,
    data,
    error,
    fetchData,
    fetchDataWithKey,
    addDataItem,
    updateDataById,
    pagination: {
      page,
      hasMore,
    },
  };
};

import React, {useCallback} from 'react';
import {Route, Switch} from 'react-router-dom';

import routes from 'config/routes/rewards';
import {useFetcher} from 'hooks/use-fetcher';
import {getPurchasedLots} from 'services/lots';
import {useUserProfile} from 'hooks/use-user-profile';

import Lots from 'components/Rewards/Lots/Lots';
import LotDetailsPage from './LotDetailsPage/LotDetailsPage';
import HowToPage from './HowToPage/HowToPage';

const LotsPage = () => {
  const {userProfile} = useUserProfile();
  const {isLoading, data, fetchData} = useFetcher({
    fetcher: getPurchasedLots,
    params: {
      userProfile,
    },
    key: 'purchasedLots',
  });
  const lots = data || [];

  const handleRefresh = useCallback(() => fetchData(), [fetchData]);

  return (
    <Switch>
      <Route path={`${routes.REWARDS.LOTS.href}/how-to/:id`}>
        <HowToPage lots={lots} isLoading={isLoading} />
      </Route>
      <Route path={`${routes.REWARDS.LOTS.href}/:id`}>
        <LotDetailsPage lots={lots} isLoading={isLoading} />
      </Route>
      <Route>
        <Lots lots={lots} isLoading={isLoading} onRefresh={handleRefresh} />
      </Route>
    </Switch>
  );
};

export default LotsPage;

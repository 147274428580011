import styled from 'styled-components';
import CopyText from '../Text/CopyText/CopyText';

const AccountTitle = styled(CopyText)`
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
`;

export default AccountTitle;

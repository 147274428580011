import {useCallback} from 'react';

export const useForm = () => {
  const getKeyDownListener = useCallback(
    ({form}: {form: HTMLFormElement | null}) => {
      if (!form) {
        return form;
      }

      const inputs = Array.prototype.slice.call(
        form?.querySelectorAll('input'),
      );

      return (e: any) => {
        const elIndex = inputs.findIndex(
          (element: any) => element === e.target,
        );

        if (
          e.target.enterKeyHint === 'next' &&
          e.nativeEvent?.code === 'Enter' &&
          elIndex !== -1 &&
          inputs.length > elIndex + 1
        ) {
          e.preventDefault();
          inputs[elIndex + 1].focus();
        }
      };
    },
    [],
  );

  return {
    getKeyDownListener,
  };
};

import React, {createContext, useState} from 'react';

type TeamObject = {
  isTeamPromptOpen: boolean;
  setIsTeamPromptOpen: (isTeamPromptOpen: boolean) => void;
  isPreviousLeaderboardVisible: boolean;
  setIsPreviousLeaderboardVisible: (isTeamPromptOpen: boolean) => void;
};

const defaultValue = {
  isTeamPromptOpen: false,
  setIsTeamPromptOpen: () => {},
  isPreviousLeaderboardVisible: false,
  setIsPreviousLeaderboardVisible: () => {},
};

export const TeamContext = createContext<TeamObject>(defaultValue);

const TeamProvider = (props: any) => {
  const [isTeamPromptOpen, setIsTeamPromptOpen] = useState(true);
  const [
    isPreviousLeaderboardVisible,
    setIsPreviousLeaderboardVisible,
  ] = useState(false);

  const teamSettings = {
    isTeamPromptOpen,
    setIsTeamPromptOpen,
    isPreviousLeaderboardVisible,
    setIsPreviousLeaderboardVisible,
  };

  return (
    <TeamContext.Provider value={teamSettings}>
      {props.children}
    </TeamContext.Provider>
  );
};

export default TeamProvider;

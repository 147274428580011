import {useCallback} from 'react';

import {UNIT_TYPES} from 'constants/unit';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';

type GetUnit = {unitType: string; value: number};

const useUnit = () => {
  const {translate} = useTranslations();
  const {getShortTokenText} = useTokenTranslation();

  const getUnit = useCallback(
    ({unitType, value}: GetUnit) => {
      switch (unitType) {
        case UNIT_TYPES.RECOINS: {
          return getShortTokenText(value);
        }
        default: {
          return translate(`sdk.web.unit.${unitType}`);
        }
      }
    },
    [translate, getShortTokenText],
  );

  return {
    getUnit,
  };
};

export default useUnit;

import {fetchAPiClient} from './api';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

interface LogDataInterface {
  level?: string;
  message: string;
  context?: any;
}

export const logData = async (data: LogDataInterface) => {
  if (!data.level) {
    data.level = 'debug';
  }

  if (!data.context) {
    data.context = {};
  }

  const dataArray = [data];
  try {
    logDataToApi({data: dataArray}).then(() => {});
  } catch (e: any) {
    console.log(e);
  }
};

export const logDataToApi = async ({data}: {data: any}) => {
  const client = defaultApiClient;
  const url = '/log';
  return client.post(url, data);
};

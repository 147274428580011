import React from 'react';
import styled from 'styled-components';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import {CACHE_KEYS} from 'constants/cache-keys';
import {getStats} from 'services/water';
import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import Info from './Info';
import Widget from './Widget';

const Card = styled(WidgetCard)<{id?: string}>`
  min-height: 100px;
`;

const WaterWidget = () => {
  const {hasWaterWidget} = useOrganization();
  const {data: stats, isLoading} = useFetcher({
    fetcher: getStats,
    initialValue: {},
    key: CACHE_KEYS.WATER_STATS,
    preventFetch: !hasWaterWidget,
  });

  if (isLoading || !hasWaterWidget) {
    return null;
  }

  return (
    <Card id="water-widget">
      {stats?.connected && stats?.has_settings ? (
        <Widget stats={stats} />
      ) : (
        <Info />
      )}
    </Card>
  );
};

export default WaterWidget;

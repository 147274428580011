import React, {useMemo} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import useEventsListener from 'hooks/events/use-events-listener';
import {getWallets} from 'services/wallets';
import {Wallets} from 'types/Wallet';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import WalletTransactions from './WalletTransactions';
import Wallet from './Wallet';

const TAB_FILTER = 'tab';

const TABS = {
  WALLET: {
    key: 'WALLET',
    label: 'Wallet',
    labelKey: 'sdk.web.wallets.tab.wallet',
  },
  TRANSACTIONS: {
    key: 'TRANSACTIONS',
    label: 'Transactions',
    labelKey: 'sdk.web.wallets.tab.trx',
  },
};

const tabList = Object.values(TABS);

const Wrapper = styled.div`
  padding: 10px;
  padding-bottom: 0;
  overflow-y: hidden;
  height: calc(100vh - 95px);

  .wallets {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;
      background-color: ${(props) => props.theme.colors.white};
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 28px 20px 10px;
    }

    &__tabs {
      display: flex;
      justify-content: space-between;
    }

    &__tab {
      width: calc(50% - 10px);
      padding-bottom: 4px;
      color: ${(props) =>
        props.theme.components.wallets?.textColor ||
        props.theme.colors.primary};
      text-align: center;
      border-bottom: 3px solid ${(props) => props.theme.colors.primary};
      opacity: 1;

      :not(&--active) {
        border-width: 1px;
        opacity: 0.6;
      }
    }

    &__tab-content {
      flex-grow: 1;
      overflow-y: hidden;
    }
  }
`;

const WalletsPage = () => {
  const {search} = useLocation();
  const history = useHistory();
  const {translate} = useTranslations();

  const {
    data: wallets,
    isLoading: isWalletsLoading,
    fetchData: refetchWallets,
  } = useFetcher<Wallets>({
    fetcher: getWallets,
    key: CACHE_KEYS.WALLETS,
  });

  useEventsListener({
    event: 'tokensUpdated',
    callback: refetchWallets,
  });

  const {activeTab, searchTab, searchParams} = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const searchTab = searchParams.get(TAB_FILTER);
    const tab = (searchTab as keyof typeof TABS) || TABS.WALLET.key;

    return {activeTab: TABS[tab] || TABS.WALLET, searchTab, searchParams};
  }, [search]);

  return (
    <Wrapper className="wallets">
      <div className="wallets__content">
        <div className="wallets__tabs">
          {tabList.map((tab) => (
            <div
              key={tab.key}
              className={classnames('wallets__tab', {
                'wallets__tab--active': tab.key === activeTab.key,
              })}
              onClick={() => {
                if (tab.key === activeTab.key) {
                  return;
                }

                const func = searchTab ? history.replace : history.push;
                func(`?${TAB_FILTER}=${tab.key}`);
              }}>
              <CopyTextSm>
                <b>{translate(tab.labelKey)}</b>
              </CopyTextSm>
            </div>
          ))}
        </div>
        <div className="wallets__tab-content">
          {activeTab === TABS.WALLET && (
            <Wallet
              isLoading={isWalletsLoading}
              wallets={wallets}
              searchParams={searchParams}
              onReload={refetchWallets}
            />
          )}
          {activeTab === TABS.TRANSACTIONS && <WalletTransactions />}
        </div>
      </div>
    </Wrapper>
  );
};

export default WalletsPage;

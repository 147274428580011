import React, {useMemo} from 'react';

import {useTranslations} from 'hooks/use-translations';
import {formatDate} from 'utils/date';
import {Voucher as OfferType} from 'types/Offers';

type OfferRestrictionsProps = {
  offer: OfferType;
};
const OfferRestrictions = ({offer}: OfferRestrictionsProps) => {
  const {translate} = useTranslations();
  const {limit_per_user, limit_timeframe, expires_at} = offer;

  const period = useMemo(() => {
    /* Returns the period as a string (e.g, Daily, Monthly) */
    switch (limit_timeframe) {
      case 0:
        return 'unlimited';
      case 1:
        return 'day';
      case 2:
        return 'week';
      case 3:
        return 'month';
      case 4:
        return 'year';
      default:
        break;
    }
  }, [limit_timeframe]);

  return (
    <div>
      {Boolean(limit_timeframe && limit_per_user) && (
        <div>
          <strong>
            {translate('sdk.web.offers.restrictions.purchase.limit')}
          </strong>
          <p>
            {translate(
              `sdk.web.offers.restrictions.purchase.limit.text.${period}`,
              [
                {
                  key: '{value}',
                  value: limit_per_user,
                },
              ],
            )}
          </p>
        </div>
      )}
      {Boolean(expires_at) && (
        <div>
          <strong>
            {translate('sdk.web.offers.restrictions.purchase.expiry')}
          </strong>
          <p>
            {translate('sdk.web.offers.restrictions.purchase.expiry.text', [
              {
                key: '{date}',
                value: formatDate({
                  date: new Date(expires_at),
                  addTime: false,
                  utc: false,
                }),
              },
            ])}
          </p>
        </div>
      )}

      {(Boolean(offer.email) || Boolean(offer.phone_number)) && (
        <div>
          <strong>
            {translate('sdk.web.offers.restrictions.contact.vendor')}
          </strong>
          {Boolean(offer.email) && (
            <p>
              <a href={`mailto:${offer.email}`}>{offer.email}</a>
            </p>
          )}
          {Boolean(offer.phone_number) && <p>{offer.phone_number}</p>}
        </div>
      )}
    </div>
  );
};

export default OfferRestrictions;

import React, {useContext, useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {SettingsContext} from 'contexts/SettingsContext';

const ThemeWrapper = (props: any) => {
  const {organizationConfig} = useContext(SettingsContext);
  const [theme, setTheme] = useState({});

  useEffect(() => {
    if (!organizationConfig.theme) {
      return;
    }

    setTheme(organizationConfig.theme);
  }, [organizationConfig]);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default ThemeWrapper;

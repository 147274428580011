import React, {useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';

import useElementTranslation from '../../hooks/use-element-translation';
import {useMeterUnits} from './hooks';
import {addBoldText} from 'utils/theme';
import {formatNumber} from 'utils/numbers';
import {formatDate, getDateFromString} from 'utils/date';
import {kwhToMeter} from './utils';
import {ConsumptionScreenProps} from './types';
import {ELEMENT_CONSUMPTION_SCREENS} from '../../types';

import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import ConsumptionValue from './ConsumptionValue';
import UpdateReadingCta from './UpdateReadingCta';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px 40px;

  .e-consumption-success {
    &__title {
      text-align: center;
      margin-bottom: 60px;
    }

    &__reading {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 30px;
      text-align: left;

      &--border {
        padding-bottom: 30px;
        border-bottom: 0.5px solid ${(props) => props.theme.colors.primary};
      }

      &--mb-lg {
        margin-bottom: 40px;
      }
    }

    &__btn {
      margin-bottom: 20px;
    }
  }
`;

const Text = styled(CopyText)`
  display: block;
  flex-shrink: 0;
  width: calc(50% - 5px);

  &:last-of-type {
    text-align: right;
  }

  b {
    ${(props) =>
      addBoldText({
        props,
      })}
  }
`;

const Success = (props: ConsumptionScreenProps) => {
  const {element, elementData, onComplete, manualMeasurement} = props;
  const translate = useElementTranslation({element});
  const {unit, meterUnit, showMeter} = useMeterUnits({element, elementData});

  const {
    currentDate,
    lastMeasuredAt,
    previousReading,
    currentReading,
    currentReadingInMeter,
    previousReadingInMeter,
    consumptionDifference,
    daysSinceLastReading,
  } = useMemo(() => {
    const lastMeasuredAt = manualMeasurement?.last_measured_at
      ? getDateFromString({date: manualMeasurement.last_measured_at})
      : '';

    return {
      currentDate: formatDate({date: new Date(), addTime: true, utc: false}),
      lastMeasuredAt: lastMeasuredAt
        ? formatDate({
            date: lastMeasuredAt,
            addTime: true,
            utc: false,
          })
        : '',
      previousReading: formatNumber({
        number: manualMeasurement?.previous_value || 0,
        dp: 3,
        ignoreThousandRule: true,
      }),
      previousReadingInMeter: showMeter
        ? kwhToMeter({
            value: manualMeasurement?.previous_value || 0,
            element,
            dp: 3,
          })
        : '',
      currentReading: formatNumber({
        number: manualMeasurement?.value || 0,
        dp: 3,
        ignoreThousandRule: true,
      }),
      currentReadingInMeter: showMeter
        ? kwhToMeter({
            value: manualMeasurement?.value || 0,
            element,
          })
        : undefined,
      consumptionDifference: formatNumber({
        number:
          (manualMeasurement?.value || 0) -
          (manualMeasurement?.previous_value || 0),
        dp: 3,
        ignoreThousandRule: true,
      }),
      daysSinceLastReading: lastMeasuredAt
        ? moment()
            .startOf('day')
            .diff(moment(lastMeasuredAt).startOf('day'), 'days')
        : '',
    };
  }, [element, showMeter, manualMeasurement]);

  return (
    <Wrapper>
      <div>
        <div className="e-consumption-success__title">
          <Title>{translate('sdk.web.meter.consumption.success')}</Title>
        </div>
        <div className="e-consumption-success__reading">
          <Text>
            {translate('sdk.web.meter.consumption.previous')}
            <br />
            {lastMeasuredAt}
          </Text>
          <Text>
            <ConsumptionValue
              showMeter={showMeter}
              unit={unit}
              meterUnit={meterUnit}
              value={previousReading}
              meterValue={previousReadingInMeter}
            />
          </Text>
        </div>
        <div className="e-consumption-success__reading e-consumption-success__reading--border">
          <Text>
            {translate('sdk.web.meter.consumption.today')}
            <br />
            {currentDate}
          </Text>
          <Text>
            <ConsumptionValue
              showMeter={showMeter}
              unit={unit}
              meterUnit={meterUnit}
              value={currentReading}
              meterValue={currentReadingInMeter}
            />
          </Text>
        </div>
        <div className="e-consumption-success__reading e-consumption-success__reading--mb-lg">
          <Text>{translate('sdk.web.meter.consumption.last.scan')}</Text>
          <Text>
            <b>
              {consumptionDifference} {unit}
            </b>
            <br />
            <br />
            {!!consumptionDifference &&
              daysSinceLastReading !== 'undefined' &&
              translate('sdk.web.meter.consumption.period', {
                key: '{nr}',
                value: daysSinceLastReading,
              })}
          </Text>
        </div>
        <UpdateReadingCta
          onClick={() =>
            onComplete({nextScreen: ELEMENT_CONSUMPTION_SCREENS.SETTINGS})
          }
        />
      </div>
      <div className="e-consumption-success__cta">
        <Button
          className="e-consumption-success__btn"
          onClick={() => onComplete()}>
          {translate('sdk.web.meter.consumption.cta')}
        </Button>
      </div>
    </Wrapper>
  );
};

export default Success;

import React, {useContext} from 'react';

import {PROMPTS} from 'constants/prompts';
import {SettingsContext} from 'contexts/SettingsContext';
import {ReviewContext} from 'contexts/ReviewContext';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';
import {useUserProfile} from 'hooks/use-user-profile';
import {useTokenTranslation} from 'hooks/use-token-translation';

import PromptModal from 'components/PromptModal/PromptModal';

const FullTokenModal = (props: any) => {
  const {prompts} = props;
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {setIsReviewOpen} = useContext(ReviewContext);
  const {reloadUserProfile} = useUserProfile();
  const {getTokenText} = useTokenTranslation();

  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.RECOIN_EARNED,
  });

  if (!prompt) {
    return <React.Fragment />;
  }

  const {co2_per_recoin} = prompt.data;
  const co2NeededForRecoin = Math.round(co2_per_recoin / 1000);

  const handleFullTokenModalClose = () => {
    handleModalClose();
    reloadUserProfile();
    setIsReviewOpen && setIsReviewOpen(true);
  };

  const TextContent = (
    <div
      dangerouslySetInnerHTML={{
        __html: translate(organizationConfig.translations.fullTokenText, [
          {
            key: '{co2NeededForRecoin}',
            value: co2NeededForRecoin,
          },
          {
            key: '{token}',
            value: getTokenText(1),
          },
        ]),
      }}
    />
  );

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.contributions.full.token.title')}
      textContent={TextContent}
      image={organizationConfig.fullTokenImg}
      btnText={translate('sdk.web.contributions.full.token.cta')}
      onBtnClick={handleFullTokenModalClose}
    />
  );
};

export default FullTokenModal;

import React, {useContext} from 'react';
import styled from 'styled-components';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

import {SettingsContext} from 'contexts/SettingsContext';

const Wrapper = styled.div<{lg?: boolean}>`
  width: 88px;
  height: 88px;

  .progress-icon {
    &__icon {
      width: 73px;
    }
  }

  ${(props) => {
    if (props.lg) {
      return `
         width: 110px;
         height: 110px;
         
         .progress-icon {
            &__icon {
              width: 92px;
            }
          }
      `;
    }

    return '';
  }}
`;

interface ProgressIconProps {
  percent: number;
  icon: string;
  alt: string;
  danger?: boolean;
  pathColor: string;
  trailColor?: string;
  hideTrail?: boolean;
  lg?: boolean;
}

const ProgressIcon = (props: ProgressIconProps) => {
  const {percent, icon, alt, danger, pathColor, trailColor, hideTrail, lg} =
    props;
  const {organizationConfig} = useContext(SettingsContext);
  const meteringConfig = organizationConfig.theme.components.metering;

  return (
    <Wrapper lg={lg}>
      <CircularProgressbarWithChildren
        value={percent}
        strokeWidth={lg ? 5 : 4}
        styles={buildStyles({
          pathColor: danger
            ? meteringConfig?.progressBar?.dangerPathColor || '#707071'
            : pathColor,
          trailColor: hideTrail
            ? 'transparent'
            : trailColor ||
              meteringConfig?.progressBar?.trailColor ||
              '#E8E8E8',
          strokeLinecap: 'round',
        })}
        counterClockwise={danger}>
        <img className="progress-icon__icon" src={icon} alt={alt} />
      </CircularProgressbarWithChildren>
    </Wrapper>
  );
};

export default ProgressIcon;

const baseRoute = '/water';

const routes = {
  href: baseRoute,
  ABOUT: {
    href: `${baseRoute}/about`,
  },
  HOW_TO: {
    href: `${baseRoute}/how-to`,
  },
  TIPS: {
    href: `${baseRoute}/tips`,
  },
};

export default routes;

import Coin from 'components/ClimateWidgets/Co2Token/DiehlCoin/DiehlCoin';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/DiehlCoin/images/coins/full.svg';
import onboardingRedeemImg from 'components/Onboarding/images/diehlmetering/onboarding-redeem.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/diehlmetering/onboarding-privacy.svg';
import buttons from 'config/organizations/preset/buttons';

const config = {
  name: 'diehlmetering',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Roboto',
          secondary: 'Adobe Sketchnote',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Adobe Sketchnote',
        },
      },
      de: {
        android: {
          primary: 'Roboto',
          secondary: 'Adobe Sketchnote',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Adobe Sketchnote',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#047D8C',
      secondary: '#DCF5f4',
      tertiary: '#409DA9',
      dark: '#30454A',
      white: '#ffffff',
      black: '#3C3C3C',
      gray: '#D8D9DA',
      sunDown: '#FFAFAF',
      catSkillWhite: '#EAF5F6',
    },
    components: {
      dashboard: {
        bgColor: 'primary',
      },
      competition: {
        bgColor: 'primary',
      },
      nav: {
        bgColor: 'primary',
        menuColor: 'white',
      },
      widgetCard: {
        bgColor: 'secondary',
        titleBgColor: 'dark',
      },
      offers: {
        activeTabBgColor: 'dark',
        navBgColor: 'tertiary',
        bodyBgColor: 'secondary',
        recoinsLeftBgColor: 'light',
        recoinsRequiredBgColor: 'light',
        locationTypeBgColor: 'catSkillWhite',
      },
      climatePartnerMap: {
        bgColor: 'secondary',
      },
      badges: {},
      treePlanting: {},
      links: {},
      buttons: {
        ...buttons,
      },
      mobilityIcons: {
        walking: {
          bgColor: '#29a6dd',
        },
        cycling: {
          bgColor: '#15e5da',
        },
        publicTransit: {
          bgColor: '#93cf21',
        },
      },
      prompts: {
        bgColor: 'secondary',
        overlayBgColor: '#00000099',
      },
      customActivities: {},
      settings: {},
      co2Compensation: {},
      leaderboard: {},
      inviteFriends: {},
      vouchers: {
        bgColor: 'white',
        recoinsTagBgColor: 'catSkillWhite',
        active: {
          activeBgColor: 'secondary',
          pendingBgColor: 'secondary',
          expiredBgColor: 'sunDown',
        },
      },
      news: {},
      summaryPage: {},
    },
    colors: {
      primary: '#047D8C',
      secondary: '#DCF5f4',
      white: '#ffffff',
      black: '#3C3C3C',
      gray: '#D8D9DA',
      promptOverlayBg: '#00000099',
      onboardingBg: '#BCEDED',
      teamRankingHighlight: '#FFF3D7',
    },
  },
  translations: {
    tokenTitle: 'sdk.web.diehlmetering.contributions.your.token',
    co2Needed: 'sdk.web.diehlmetering.contributions.savings.left.for.token',
    fullTokenText: 'sdk.web.diehlmetering.contributions.full.token.text',
    offersTitle: 'sdk.web.diehlmetering.offers.your.token',
    shortTokenSingular: 'sdk.web.diehlmetering.offers.token.required.singular',
    shortTokenPlural: 'sdk.web.diehlmetering.offers.token.required.plural',
    rewardType1Singular:
      'sdk.web.diehlmetering.contributions.inviteFriends.rewardType1.singular',
    rewardType1Plural:
      'sdk.web.diehlmetering.contributions.inviteFriends.rewardType1.plural',
    notEnoughTokens: 'sdk.web.diehlmetering.offers.not.enough.tokens',
    aboutTokenLabel: 'sdk.web.diehlmetering.settings.about.title',
    onBoarding: {
      one: {
        title: 'sdk.web.diehlmetering.onboarding.one.title',
        content: 'sdk.web.diehlmetering.onboarding.one.text',
      },
      two: {
        title: 'sdk.web.diehlmetering.onboarding.two.title',
        content: 'sdk.web.diehlmetering.onboarding.two.text',
        image: fullTokenImg,
      },
      three: {
        title: 'sdk.web.diehlmetering.onboarding.three.title',
        content: 'sdk.web.diehlmetering.onboarding.three.text',
        image: onboardingRedeemImg,
      },
      privacy: {
        title: 'sdk.web.onboarding.privacy.title',
        content: 'sdk.web.onboarding.privacy.text',
        confirmLink: 'sdk.web.onboarding.privacy.link',
        image: onboardingPrivacyImg,
      },
      gps: {
        title: 'sdk.web.onboarding.gps.title',
        content: 'sdk.web.onboarding.gps.text',
      },
    },
    competition: {
      title: 'sdk.web.teams.germany.leaderboard.title',
      subtitle: 'sdk.web.teams.germany.leaderboard.subtitle',
    },
  },
  features: {
    hideAppClose: true,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: false,
    hasWaterWidget: true,
  },
  applangaTag: '614a200803217b65ca867560',
  matomoTrackingId: 5,
  tokenType: 'Coin',
  fullTokenImg,
  FullTokenComponent: Coin,
};

export default config;

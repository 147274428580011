interface FormatNumber {
  number: number;
  dp?: number;
  preserveLeadingZero?: boolean;
  ignoreThousandRule?: boolean;
  toNumber?: boolean;
  skipNormalization?: boolean;
}

export const formatNumber = ({
  number,
  dp,
  preserveLeadingZero,
  ignoreThousandRule,
  toNumber,
  skipNormalization,
}: FormatNumber) => {
  if (!number) {
    return number.toString();
  }

  let updatedDp = !dp && dp !== 0 ? 1 : dp;
  updatedDp = !ignoreThousandRule && number > 1000 ? 0 : updatedDp;
  const normalizedNumber =
    !skipNormalization && number > 0 && number < 0.01 ? 0.01 : number;

  const roundingFactor = Math.pow(10, updatedDp);
  const roundedNumber =
    Math.floor((normalizedNumber + Number.EPSILON) * roundingFactor) /
    roundingFactor;

  if (toNumber) {
    return roundedNumber;
  }

  if (!preserveLeadingZero || normalizedNumber % 1 === 0) {
    return roundedNumber.toLocaleString();
  }

  return roundedNumber.toLocaleString(undefined, {minimumFractionDigits: dp});
};

export const formatAmount = ({number, dp}: {number: number; dp?: number}) =>
  formatNumber({
    number,
    dp,
    preserveLeadingZero: true,
  });

export const formatToken = ({
  number,
  ignoreThousandRule,
  toNumber,
  dp = 0,
}: {
  number: number;
  ignoreThousandRule?: boolean;
  toNumber?: boolean;
  dp?: number;
}) =>
  formatNumber({
    number,
    ignoreThousandRule,
    toNumber,
    dp,
  });

export const numberToKm = ({number, dp, ignoreThousandRule}: FormatNumber) =>
  formatNumber({
    number: number / 1000,
    dp,
    ignoreThousandRule,
  });

interface GetOptimizedDistance {
  distance?: number;
  optimizeDistance?: boolean;
}

export type OptimizedDistance = {
  showOffline?: boolean;
  showDistance?: boolean;
  distance?: number | string;
};

export const getOptimizedDistance = ({
  distance,
  optimizeDistance,
}: GetOptimizedDistance): OptimizedDistance => {
  if (!distance && distance !== 0) {
    return {
      showOffline: !optimizeDistance,
      showDistance: false,
    };
  }

  const distanceInKm = distance / 1000;
  if (distanceInKm > 999 && optimizeDistance) {
    return {
      showOffline: false,
    };
  }

  const optimizedDistance =
    distanceInKm > 100 || (distanceInKm > 10 && optimizeDistance)
      ? Math.floor(distanceInKm)
      : formatNumber({number: distanceInKm});

  return {
    distance: optimizedDistance,
    showOffline: true,
    showDistance: true,
  };
};

import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {getBusinessTypes} from 'services/vouchers';
import {OffersBusinessType} from 'types/Offers';

import RadioButton from 'components/Form/RadioButton';
import Loader from 'components/Loader/Loader';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import Icon from 'components/Rewards/Header/Icon';

import {ReactComponent as FilterIcon} from 'components/Rewards/Header/icons/filter.svg';

const Wrapper = styled.div`
  margin-right: 10px;

  .offers-filter {
    &__filter {
      position: relative;
    }

    &__indicator {
      position: absolute;
      right: -2px;
      top: -1px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #b71e3f;
      border: 1px solid #ffffff;
    }

    &__list {
      height: 100%;
      overflow-y: scroll;
    }
  }
`;

const OffersList = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 0 20px 20px;

  .offers-filter-list {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &__text {
      margin-left: 15px;
    }
  }
`;

interface OffersFilterProps {
  activeOffersFilter: Array<string>;
  onFilter: (filters: Array<string>) => any;
}

const OffersFilter = (props: OffersFilterProps) => {
  const {activeOffersFilter, onFilter} = props;
  const {translate} = useTranslations();

  const [showFilters, setShowFilters] = useState(false);

  const {isLoading, data} = useFetcher<Array<OffersBusinessType>>({
    fetcher: getBusinessTypes,
    key: CACHE_KEYS.OFFERS_BUSINESS_TYPES,
    initialValue: [],
  });

  const businessTypes = useMemo(() => {
    const allBusinessTypes = data.map((businessType) => ({
      ...businessType,
      checked: activeOffersFilter.includes(businessType.id.toString()),
    }));

    return [
      {
        id: 0,
        slug: 'all',
        name: translate('sdk.web.offers.filter.all'),
        checked: activeOffersFilter.length === 0,
      },
      ...allBusinessTypes,
    ];
  }, [translate, activeOffersFilter, data]);

  const handleFilter = useCallback(
    (id: number) => {
      if (id === 0) {
        onFilter([]);
        return;
      }

      const idStr = id.toString();
      const newActiveFilters = activeOffersFilter.includes(idStr)
        ? activeOffersFilter.filter((filter) => filter !== idStr)
        : [...activeOffersFilter, idStr];
      onFilter(newActiveFilters);
    },
    [onFilter, activeOffersFilter],
  );

  return (
    <Wrapper className="offers-filter">
      <div
        className="offers-filter__filter"
        onClick={() => setShowFilters(true)}>
        <Icon Icon={FilterIcon} />
        {activeOffersFilter.length > 0 && (
          <div className="offers-filter__indicator" />
        )}
      </div>
      <PromptWrapper
        isVisible={showFilters}
        onClose={() => setShowFilters(false)}
        title={translate('sdk.web.offers.filter.title')}
        styles={{pb: '0'}}>
        <OffersList className="offers-filter-list">
          {isLoading ? (
            <Loader color="#222" sm />
          ) : (
            businessTypes.map((businessType) => (
              <div
                key={businessType.id}
                className="offers-filter-list__item"
                onClick={() => handleFilter(businessType.id)}>
                <RadioButton checked={businessType.checked} />
                <CopyTextSm as="div" className="offers-filter-list__text">
                  {businessType.name}
                </CopyTextSm>
              </div>
            ))
          )}
        </OffersList>
      </PromptWrapper>
    </Wrapper>
  );
};

export default OffersFilter;

import styled from 'styled-components';
import React from 'react';
import {FormikContextType} from 'formik';
import get from 'lodash/get';

import {useTranslations} from 'hooks/use-translations';

export const Error = styled.div`
  padding-top: 10px;
  color: red;
`;

interface FormErrorProps {
  name?: string;
  formik: FormikContextType<any>;
  apiErrors?: {
    [key: string]: string[];
  };
}

const FormError = (props: FormErrorProps) => {
  const {translate} = useTranslations();
  const {name = '', formik, apiErrors} = props;
  const allApiErrors = get(apiErrors || {}, name);
  const error =
    (get(formik.touched, name) || formik.submitCount) && formik.errors
      ? get(formik.errors, name)
      : false;

  return (
    <>
      {/* @ts-ignore */}
      {!!error && <Error className="form-error">{error}</Error>}
      {!!allApiErrors &&
        allApiErrors.map((errorCode) => (
          <Error className="form-error" key={errorCode}>{translate(errorCode)}</Error>
        ))}
    </>
  );
};

export default FormError;

import React, {createContext, useCallback, useEffect, useState} from 'react';
import {CurrentLocationEvent} from 'types/NativeEvents';
import {useNativeListener} from 'hooks/use-native-listener';
import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {sendMessageToNativeApp} from 'services/native-api';
import {Location} from 'types/Location';

type LocationSettings = {
  location?: Location;
  updateCurrentLocation: () => void;
};

const defaultValue = {
  isLocationPromptOpen: false,
  updateCurrentLocation: () => {},
};

const LOCATION_STORAGE_KEY = 'user-location';

export const LocationContext = createContext<LocationSettings>(defaultValue);

const LocationProvider = (props: any) => {
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    const storedLocation = localStorage.getItem(LOCATION_STORAGE_KEY);
    if (!storedLocation) {
      return;
    }

    try {
      const parsedLocation = JSON.parse(storedLocation);
      setLocation(parsedLocation);
    } catch (e: any) {
      console.error(e);
    }
  }, []);

  const updateCurrentLocation = useCallback(() => {
    const message = {type: NATIVE_MESSAGES.REQUEST_CURRENT_LOCATION};
    sendMessageToNativeApp({message});
  }, []);

  const handleCurrentLocationResponse = (details: CurrentLocationEvent) => {
    if (
      !details?.coordinates ||
      !details.coordinates.latitude ||
      !details.coordinates.longitude
    ) {
      return;
    }

    setLocation(details.coordinates);
    localStorage.setItem(
      LOCATION_STORAGE_KEY,
      JSON.stringify(details.coordinates),
    );
  };

  useNativeListener({
    callback: handleCurrentLocationResponse,
    event: NATIVE_EVENTS.CURRENT_LOCATION,
  });

  const locationSettings = {
    location,
    updateCurrentLocation,
  };

  return (
    <LocationContext.Provider value={locationSettings}>
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;

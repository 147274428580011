import React, {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {getCustomActivities} from 'services/customActivity';
import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import ActivityListDetails from 'components/CustomActivities/ActivityListDetails';
import {CustomActivity} from 'types/CustomActivity';

const CustomActivitiesPage = () => {
  useBodyBg({type: 'primary'});
  const {category} = useParams<{category: string}>();

  const {isLoading, data, fetchData, updateDataById} = useFetcher({
    fetcher: getCustomActivities,
    initialValue: [],
    key: `${CACHE_KEYS.CUSTOM_ACTIVITIES}-${category}`,
    params: {
      category,
    },
  });

  const handleActivityComplete = useCallback(
    ({activity}: any) => {
      updateDataById({
        id: activity.id,
        idKey: 'id',
        updates: {
          completed: true,
        },
      });
      fetchData();
    },
    [updateDataById, fetchData],
  );

  return (
    <ActivityListDetails
      isLoading={isLoading}
      activities={data as Array<CustomActivity>}
      onActivityComplete={handleActivityComplete}
    />
  );
};

export default CustomActivitiesPage;

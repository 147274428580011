const buttons = {
  primary: {
    bgColor: 'primary',
    disabledBgColor: 'disabled',
    color: 'white',
  },
  gray: {
    bgColor: 'gray',
    color: 'white',
  },
  link: {
    bgColor: 'white',
    color: 'primary',
    borderRadius: 'none',
  },
};

export default buttons;

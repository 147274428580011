import React, {useCallback, useMemo} from 'react';

import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {formatNumber} from 'utils/numbers';

import PromptModal from 'components/PromptModal/PromptModal';
import bannerImg from '../icons/banner.svg';

export type ConfirmationParams = {
  recoins: number;
};

interface ConfirmationModalProps {
  recoins: number;
  emissionsToOffset: string;
  isVisible: boolean;
  isSubmitting: boolean;
  onConfirmation: (params?: ConfirmationParams) => void;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {isVisible, isSubmitting, recoins, emissionsToOffset, onConfirmation} =
    props;
  const {translate} = useTranslations();
  const {getTokenText} = useTokenTranslation();

  const textContent = useMemo(
    () => (
      <span
        dangerouslySetInnerHTML={{
          __html: translate('sdk.web.emissions.offset.confirm.text', [
            {
              key: '{amount}',
              value: emissionsToOffset,
            },
            {
              key: '{nr}',
              value: formatNumber({
                number: recoins,
                dp: 1,
                ignoreThousandRule: true,
              }),
            },
            {
              key: '{token}',
              value: getTokenText(recoins),
            },
          ]),
        }}
      />
    ),
    [emissionsToOffset, recoins, translate, getTokenText],
  );

  const handleClose = useCallback(() => {
    return isSubmitting ? false : onConfirmation();
  }, [isSubmitting, onConfirmation]);

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.emissions.offset.confirm.title')}
      textContent={textContent}
      image={bannerImg}
      onClose={handleClose}
      isButtonLoading={isSubmitting}
      btnText={translate('sdk.web.emissions.offset.confirm.ok')}
      onBtnClick={() => onConfirmation({recoins})}
      moreInfo={{
        text: translate('sdk.web.emissions.offset.confirm.skip'),
        onClick: handleClose,
      }}
    />
  );
};

export default ConfirmationModal;

import React from 'react';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import {useFetcher} from 'hooks/use-fetcher';
import useNavigation from 'hooks/app/use-navigation';
import {getCharity} from 'services/user';

import InfoPage from 'components/InfoPage/InfoPage';

const AboutContent = styled.div`
  figcaption {
    text-align: center;
  }
`;

const AboutTreePlantingPage = () => {
  const {translate} = useTranslations();
  const {userProfile} = useUserProfile();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});

  const {isLoading, data: charityData}: {isLoading: boolean; data: any} =
    useFetcher({
      fetcher: getCharity,
      params: {
        id: userProfile?.user?.id,
      },
      key: 'charity',
    });

  if (!userProfile || !userProfile.user) {
    return <Redirect to={'/'} />;
  }

  const organisationDescription = charityData
    ? charityData.tree_planting?.project?.description
    : '';

  const aboutContent = [
    <AboutContent>
      <div dangerouslySetInnerHTML={{__html: organisationDescription}} />
    </AboutContent>,
  ];

  return (
    <InfoPage
      title={translate('sdk.web.settings.treePlanting.about')}
      rows={aboutContent}
      isLoading={isLoading}
      onClose={handleGoBack}
    />
  );
};

export default AboutTreePlantingPage;

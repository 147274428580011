import React from 'react';
import routes from 'config/routes';
import {Route, Switch} from 'react-router-dom';

import Form from './Form';
import Home from './Home';

const ECarRating = () => {
  return (
    <Switch>
      <Route path={routes.SETTINGS.E_CAR_RATING.FORM.href}>
        <Form />
      </Route>
      <Route>
        <Home />
      </Route>
    </Switch>
  );
};

export default ECarRating;

import React, {useContext} from 'react';
import styled from 'styled-components';

import {SettingsContext} from 'contexts/SettingsContext';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useTranslations} from 'hooks/use-translations';
import {addBoldText} from 'utils/theme';

import HeaderText from 'components/Text/HeaderText/HeaderText';
import CopyText from 'components/Text/CopyText/CopyText';
import LotCard from '../LotCard/LotCard';

import {ReactComponent as CloseIcon} from './icons/close.svg';
import {ReactComponent as LotteryCrab} from '../images/lottery-crab.svg';

const getBgColor = (theme: Record<string, any>) =>
  theme.components.lots?.ongoingLotBgColor ||
  theme.colors.secondary ||
  theme.components.prompts.bgColor ||
  theme.colorPalette.promptBg ||
  theme.colors.promptBg;

const Wrapper = styled.div`
  background: ${(props) => getBgColor(props.theme)};
  color: ${(props) =>
    props.theme.components.prompts?.textColor || props.theme.colors.black};
  padding: 20px 10px;
  min-height: 100vh;
  text-align: center;
`;

const Icon = styled.div`
  width: 25px;
  margin-bottom: 20px;
  cursor: pointer;
  fill: ${(props) =>
    props.theme.components.prompts?.textColor || props.theme.colors.black};
`;

const Container = styled.div`
  text-align: center;
`;

const Title = styled(HeaderText)`
  text-align: center;
  margin-bottom: 25px;
  display: inline-block;
  width: 70%;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const LotteryCrabContainer = styled.div`
  width: 150px;
  display: inline-block;
  margin-bottom: 20px;
`;

const LotCardContainer = styled.div`
  margin-bottom: 5px;
  text-align: left;
`;

const Entries = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Entry = styled(CopyText)`
  display: block;
  border-radius: 2px;
  text-align: center;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 7px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const OngoingLot = (props: any) => {
  const {organizationConfig} = useContext(SettingsContext);
  useBodyBg({color: getBgColor(organizationConfig.theme)});
  const {translate} = useTranslations();
  const {lot, onClose}: {lot: any; onClose: () => void} = props;

  return (
    <Wrapper>
      <Icon onClick={onClose}>
        <CloseIcon />
      </Icon>
      <Container>
        <Title>{translate('sdk.web.lots.lottery.lots')}</Title>
        <LotteryCrabContainer>
          <LotteryCrab />
        </LotteryCrabContainer>
        <LotCardContainer>
          <LotCard lot={lot} />
        </LotCardContainer>
      </Container>
      <Entries>
        {(lot?.lots || []).map((purchasedLot: any, index: number) => (
          <Entry key={index}>{purchasedLot.code}</Entry>
        ))}
      </Entries>
    </Wrapper>
  );
};

export default OngoingLot;

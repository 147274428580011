import React from 'react';

interface ConsumptionValueProps {
  showMeter: boolean;
  unit: string;
  meterUnit: string;
  value: number | string | undefined;
  meterValue: number | string | undefined;
}

const ConsumptionValue = (props: ConsumptionValueProps) => {
  const {showMeter, unit, meterUnit, value, meterValue} = props;

  return (
    <b>
      {showMeter && (
        <>
          <b>
            {meterValue} {meterUnit}
            <br />
          </b>
        </>
      )}
      <span style={showMeter ? {fontWeight: 'normal'} : undefined}>
        {showMeter && '('}
        {value} {unit}
        {showMeter && ')'}
      </span>
    </b>
  );
};

export default ConsumptionValue;

import {useContext, useMemo} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {ElementData, METERING_ELEMENTS, METERING_UNITS} from 'types/Metering';

import waterIcon from 'components/ClimateWidgets/Metering/icons/water/icon.svg';
import waterBanner from 'components/ClimateWidgets/Metering/icons/water/banner.svg';
import waterResultsIcon from 'components/ClimateWidgets/Metering/icons/water/results.svg';
import waterSuccessBanner from 'components/ClimateWidgets/Metering/icons/water/success-banner.svg';

import electricityIcon from 'components/ClimateWidgets/Metering/icons/electricity/icon.svg';
import electricityResultsIcon from 'components/ClimateWidgets/Metering/icons/electricity/results.svg';
import electricityBanner from 'components/ClimateWidgets/Metering/icons/electricity/banner.svg';
import electricitySuccessBanner from 'components/ClimateWidgets/Metering/icons/electricity/success-banner.svg';

import gasIcon from 'components/ClimateWidgets/Metering/icons/gas/icon.svg';
import gasResultsIcon from 'components/ClimateWidgets/Metering/icons/gas/results.svg';
import gasBanner from 'components/ClimateWidgets/Metering/icons/gas/banner.svg';
import gasSuccessBanner from 'components/ClimateWidgets/Metering/icons/gas/success-banner.svg';

const useMeteringElements = () => {
  const {organizationConfig} = useContext(SettingsContext);
  const meteringConfig = organizationConfig.theme.components.metering;

  const elements: Array<ElementData> = useMemo(
    () => [
      {
        element: METERING_ELEMENTS.WATER,
        icon: waterIcon,
        banner: meteringConfig?.icons?.waterBanner || waterBanner,
        summaryIcon: meteringConfig?.icons?.waterSummaryIcon,
        resultsIcon: waterResultsIcon,
        successBanner: waterSuccessBanner,
        consumptionUnit: METERING_UNITS.METER,
        progressPathColor: meteringConfig?.progressBar?.pathColor || '#00B1F8',
      },
      {
        element: METERING_ELEMENTS.ELECTRICITY,
        icon: electricityIcon,
        resultsIcon: electricityResultsIcon,
        banner: electricityBanner,
        successBanner: electricitySuccessBanner,
        progressPathColor: meteringConfig?.progressBar?.pathColor || '#FFBB00',
      },
      {
        element: METERING_ELEMENTS.GAS,
        icon: gasIcon,
        resultsIcon: gasResultsIcon,
        banner: gasBanner,
        successBanner: gasSuccessBanner,
        consumptionUnit: METERING_UNITS.METER,
        progressPathColor: meteringConfig?.progressBar?.pathColor || '#FF4800',
      },
    ],
    [meteringConfig],
  );

  return elements;
};

export default useMeteringElements;

import {useCallback, useContext} from 'react';

import {NATIVE_MESSAGES} from 'constants/native-events';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {sendMessageToNativeApp} from 'services/native-api';

const useCoinsTransferLinkShare = () => {
  const {translate} = useTranslations();
  const {getTokenText} = useTokenTranslation();
  const {appName} = useContext(SettingsContext);

  const shareCoinsTransferLink = useCallback(async (link: string) => {
    const shareMessage = translate('sdk.web.wallets.transfer.link.share.text', [
      {
        key: '{tokens}',
        value: getTokenText(1),
      },
      {
        key: '{appName}',
        value: appName,
      },
    ]);

    const message = {
      type: NATIVE_MESSAGES.SHARE_BADGE,
      share_message: shareMessage,
      share_link: link,
    };

    sendMessageToNativeApp({message});
  }, [appName, translate, getTokenText]);

  return {shareCoinsTransferLink};
};

export default useCoinsTransferLinkShare;

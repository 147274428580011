import React from 'react';

import {PROMPTS} from 'constants/prompts';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';
import {useOrganization} from 'hooks/use-organization';
import {useInviteFriendsTranslations} from '../hooks/useInviteFriendsTranslations';

import PromptModal from 'components/PromptModal/PromptModal';
import successImage from 'components/ClimateWidgets/InviteFriends/InviteFriendsSuccessPrompt/images/banner.svg';

const InviteFriendsSuccessPrompt = (props: any) => {
  const {prompts} = props;
  const {translate} = useTranslations();
  const {getRewardsText} = useInviteFriendsTranslations();
  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.INVITE_FRIENDS_SUCCESS,
  });

  const {organizationConfig} = useOrganization();
  const inviteFriendsConfig = organizationConfig.theme.components.inviteFriends;

  if (!prompt) {
    return <React.Fragment />;
  }

  const referralData = prompt.data;
  const invitesKey =
    referralData.invited_users === 1
      ? 'sdk.web.contributions.inviteFriends.success.text.singular'
      : 'sdk.web.contributions.inviteFriends.success.text.plural';

  const rewardsText = getRewardsText({
    activitySummary: {},
    quantity: referralData.reward_quantity,
  });

  const TextContent = (
    <div>
      {translate(invitesKey, [
        {
          key: '{nr}',
          value: referralData.invited_users,
        },
        {
          key: '{reward}',
          value: rewardsText,
        },
      ])}
    </div>
  );

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.contributions.inviteFriends.success.title')}
      textContent={TextContent}
      image={inviteFriendsConfig?.successBanner || successImage}
      btnText={translate('sdk.web.contributions.inviteFriends.thanks')}
      onBtnClick={handleModalClose}
    />
  );
};

export default InviteFriendsSuccessPrompt;

import React, {useContext, useEffect} from 'react';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {SettingsContext} from 'contexts/SettingsContext';

const PermissionsTracker = (props: any) => {
  const {isGPSEnabled, isMotionFitnessEnabled, organizationConfig} = useContext(
    SettingsContext,
  );
  const {trackEvent} = useMatomo();

  useEffect(
    () => {
      trackEvent({
        category: 'permissions',
        action: `${organizationConfig.name} - MotionFitnessEnabled: ${isMotionFitnessEnabled}; GPSEnabled: ${isGPSEnabled}`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMotionFitnessEnabled, isGPSEnabled],
  );

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default PermissionsTracker;

import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import {ReactComponent as CheckIcon} from 'icons/check.svg';

const Wrapper = styled.div`
  .radio-btn {
    &__indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border: 1px solid
        ${(props) =>
          props.theme.components.form?.radioColor ||
          props.theme.colors.primary};
      border-radius: 100%;
      margin-right: 5px;
      padding: 2px;

      &--checked {
        background-color: ${(props) =>
          props.theme.components.form?.radioColor ||
          props.theme.colors.primary};
      }
    }

    &__check-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #ffffff;
      width: 100%;

      svg {
        width: 100%;
      }
    }
  }
`;

interface RadioButtonProps {
  checked: boolean;
  onClick?: () => void;
}

const RadioButton = (props: RadioButtonProps) => {
  const {checked, ...rest} = props;

  return (
    <Wrapper className="radio-btn" {...rest}>
      <div
        className={classnames('radio-btn__indicator', {
          'radio-btn__indicator--checked': checked,
        })}>
        {checked && (
          <div className="radio-btn__check-icon">
            <CheckIcon />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default RadioButton;

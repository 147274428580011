import {useHistory} from 'react-router-dom';
import {useCallback} from 'react';

import routes from 'config/routes/rewards';
import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {
  CUSTOM_ACTIVITY_REGEX,
  ID_REGEX,
  QR_CODE_ACTIONS,
  QRCodeDetails,
} from 'constants/qr-codes';

import {useToast} from './use-toast';
import {useTranslations} from './use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useNativeListener} from './use-native-listener';
import {useOrganization} from 'hooks/use-organization';

import {getAuthConfig} from 'services/auth';
import {sendMessageToNativeApp} from 'services/native-api';
import {
  getClimatePartnerVouchers,
  getQRCodeDetails,
  scanQRCodeURL,
} from 'services/vouchers';

import {QRCodeEvent} from 'types/NativeEvents';

export const QR_CODE_ERROR_KEY = 'sdk.web.qrcode.invalid';

export type ScanResponse = {
  id: number | string;
  action: QR_CODE_ACTIONS;
  data?: QRCodeDetails;
};
export type OnScan = (params: ScanResponse) => void;

type useQRScannerProps = {
  qrEvent?: string;
  allowedActions?: Array<QR_CODE_ACTIONS>;
  onScan?: OnScan;
  climatePartnerId?: number;
  loadOffer?: boolean;
  preventListen?: boolean;
  isOneTime?: boolean;
  onClose?: () => any;
};

export const useQrScanner = (props: useQRScannerProps) => {
  const {
    onScan,
    onClose,
    climatePartnerId,
    loadOffer,
    preventListen,
    isOneTime,
    qrEvent = NATIVE_EVENTS.QRCODE_URL,
    allowedActions = [],
  } = props;
  const [isLoading, startLoading, stopLoading] = useBoolean();
  const {hasNewQRScanner} = useOrganization();

  const toast = useToast();
  const {translate} = useTranslations();
  const history = useHistory();

  const openOffer = useCallback(
    async ({id}: {id: number}) => {
      startLoading();
      try {
        const vouchers = await getClimatePartnerVouchers({id});
        stopLoading();

        if (vouchers.length) {
          history.push(
            vouchers.length === 1
              ? `${routes.REWARDS.DEALS.href}/vouchers/${vouchers[0].id}?scanned=true`
              : `${routes.REWARDS.CLIMATE_PARTNER.href}/${id}`,
          );
        } else {
          toast.error(translate('sdk.web.offers.qrcode.no.offers'));
        }
      } catch (e: any) {
        stopLoading();
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(errorKey || QR_CODE_ERROR_KEY);
        toast.error(message);
      }
    },
    [history, startLoading, stopLoading, toast, translate],
  );

  const throwInvalidQrError = useCallback(
    () => toast.error(translate(QR_CODE_ERROR_KEY)),
    [translate, toast],
  );

  const handleScan = useCallback(
    (qrCodeDetails: QRCodeDetails) =>
      onScan &&
      onScan({
        id: qrCodeDetails.token,
        action: qrCodeDetails.payload?.action,
        data: qrCodeDetails,
      }),
    [onScan],
  );

  const handleOfferScan = useCallback(
    async (qrCodeDetails: QRCodeDetails) => {
      if (qrCodeDetails?.payload.action !== QR_CODE_ACTIONS.VIEW_OFFERS) {
        return throwInvalidQrError();
      }

      const id = qrCodeDetails.payload.params.climate_partner_id;
      if (!id || isNaN(id)) {
        return throwInvalidQrError();
      }

      if (loadOffer || (climatePartnerId && climatePartnerId !== id)) {
        await openOffer({id});
      } else {
        handleScan(qrCodeDetails);
      }
    },
    [handleScan, loadOffer, climatePartnerId, openOffer, throwInvalidQrError],
  );

  const handleQRCodeResponse = useCallback(
    async (details: QRCodeEvent) => {
      if (!details?.url) {
        return throwInvalidQrError();
      }

      const caToken = details?.url?.match(CUSTOM_ACTIVITY_REGEX)?.groups?.token;
      if (caToken) {
        onScan &&
          onScan({id: caToken, action: QR_CODE_ACTIONS.CUSTOM_ACTIVITY});
        return;
      }

      const authConfig = getAuthConfig({context: 'coins'});
      const coinsRegex = new RegExp(
        `${authConfig.baseDomain}/qrcodes/[a-zA-Z0-9-_]+`,
      );

      if (!ID_REGEX.test(details.url) && !coinsRegex.test(details.url)) {
        return;
      }

      startLoading();
      try {
        if (!hasNewQRScanner) {
          const qrCodeDetails = await getQRCodeDetails({url: details.url});
          await handleOfferScan(qrCodeDetails);
        } else {
          const qrCodeDetails = await scanQRCodeURL({url: details.url});
          await console.log(qrCodeDetails, allowedActions);
          if (
            allowedActions.length &&
            !allowedActions.includes(qrCodeDetails?.payload.action)
          ) {
            return throwInvalidQrError();
          }

          switch (qrCodeDetails?.payload.action) {
            case QR_CODE_ACTIONS.VIEW_OFFERS: {
              await handleOfferScan(qrCodeDetails);
              break;
            }
            case QR_CODE_ACTIONS.TRANSFER_COINS:
            case QR_CODE_ACTIONS.SEND_COINS:
            case QR_CODE_ACTIONS.ATTACH_WALLET: {
              handleScan(qrCodeDetails);
              break;
            }
            case QR_CODE_ACTIONS.CUSTOM_ACTIVITY: {
              onScan &&
                onScan({
                  id: qrCodeDetails.payload.params.token,
                  action: qrCodeDetails?.payload.action,
                });
              break;
            }
            default:
              return throwInvalidQrError();
          }
        }
      } finally {
        stopLoading();
      }
    },
    [
      startLoading,
      stopLoading,
      onScan,
      allowedActions,
      throwInvalidQrError,
      hasNewQRScanner,
      handleOfferScan,
      handleScan,
    ],
  );
  const {startListening, stopListening} = useNativeListener({
    callback: handleQRCodeResponse,
    event: qrEvent,
    preventListen,
    isOneTime,
  });

  const openScanner = () => {
    const message = {type: NATIVE_MESSAGES.REQUEST_QRCODE_SCAN};
    sendMessageToNativeApp({message});
    startListening();
  };

  useNativeListener({
    callback: () => {
      onClose && onClose();
      stopListening();
    },
    event: NATIVE_EVENTS.QRCODE_CLOSE,
  });

  return {
    openScanner,
    startListening,
    stopListening,
    isLoading,
  };
};

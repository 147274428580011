import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

import {NATIVE_MESSAGES} from 'constants/native-events';
import {SettingsContext} from 'contexts/SettingsContext';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useTranslations} from 'hooks/use-translations';
import useUnit from 'hooks/utils/use-unit';
import {useOrganization} from 'hooks/use-organization';
import {useUserProfile} from 'hooks/use-user-profile';
import {sendMessageToNativeApp} from 'services/native-api';
import {formatNumber} from 'utils/numbers';
import {formatDate} from 'utils/date';

import CopyText from 'components/Text/CopyText/CopyText';
import CtaLink from 'components/CtaLink/CtaLink';
import {Badge} from 'types/Badge';

import {ReactComponent as CloseIcon} from 'icons/close.svg';
import {ReactComponent as ShareIcon} from 'icons/share.svg';
import placeholder from './images/placeholder.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 40000;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.theme.components.badges.bgColor || props.theme.colors.lightSecondary};
  padding-top: 21vh;
`;

const CloseIconWrapper = styled.div`
  width: 30px;
  position: absolute;
  color: #000;
  left: 15px;
  top: 20px;
`;

const Content = styled.div`
  padding: 25px;
  width: 100%;
`;

const ImgWrapper = styled.div`
  position: relative;
  padding: 0 20px;
  width: 100%;
  margin-bottom: 40px;
`;

const ImgCircle = styled.div`
  position: relative;
  width: 100%;
`;

const PercentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const Img = styled.img`
  width: 100%;
`;

const Info = styled.div`
  width: 100%;
`;

const Text = styled(CopyText)`
  display: block;
  margin-bottom: 10px;
  text-align: center;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
`;

const Share = styled.div`
  width: 40px;
  margin-bottom: 20px;
  stroke: ${(props) =>
    props.theme.components.badges.shareColor || props.theme.colors.black};
  text-align: center;
`;

const AllBadges = styled(CtaLink)`
  color: ${(props) => props.theme.components.badges.shareColor};
  text-decoration: ${(props) =>
    props.theme.components.badges.noUnderline ? 'none' : undefined};
`;

type BadgeDetailsProps = {
  badge: Badge;
  onClose: () => void;
  onOpenAllBadges: () => void;
};

const BadgeDetails = (props: BadgeDetailsProps) => {
  const {badge, onClose, onOpenAllBadges} = props;
  const {translate} = useTranslations();
  const {getUnit} = useUnit();
  const {appName} = useContext(SettingsContext);
  const {organizationConfig, hasBadgeShare} = useOrganization();
  const {userProfile} = useUserProfile();

  const {updateBg} = useBodyBg(
    organizationConfig.theme.components.badges.bgColor
      ? {color: organizationConfig.theme.components.badges.bgColor}
      : {type: 'lightSecondary'},
  );

  useEffect(() => {
    return () => {
      updateBg({type: 'primary'});
    };
  }, [updateBg]);

  const {percent, achievedAt, showBadgeShare} = useMemo(() => {
    const percent =
      ((badge.units_needed - badge.units_missing) / badge.units_needed) * 100;
    const missingUnits = badge.units_missing
      ? translate('sdk.web.badges.missing', [
          {
            key: '{nr}',
            value: formatNumber({
              number: badge.units_missing,
              dp: 0,
            }),
          },
          {
            key: '{unit}',
            value: getUnit({
              unitType: badge.unit_type,
              value: badge.units_missing,
            }),
          },
        ])
      : '';

    return {
      percent,
      missingUnits,
      showBadgeShare:
        !userProfile.organisation?.settings?.dynamic_links?.disable &&
        !!(badge.achieved_at && hasBadgeShare),
      achievedAt: badge.achieved_at
        ? formatDate({date: badge.achieved_at})
        : null,
    };
  }, [badge, getUnit, translate, hasBadgeShare, userProfile]);

  const handleShare = useCallback(() => {
    const message = {
      type: NATIVE_MESSAGES.SHARE_BADGE,
      share_message:
        translate('sdk.web.badges.share.message', {
          key: '{appName}',
          value: appName,
        }) || badge.title,
      share_link: badge.share_link,
    };
    sendMessageToNativeApp({message});
  }, [badge, translate, appName]);

  return createPortal(
    <Wrapper>
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon />
      </CloseIconWrapper>
      <Content>
        <ImgWrapper>
          <ImgCircle>
            <Img
              src={badge.achieved_at ? badge.icon : placeholder}
              alt={badge.title}
            />
            {!badge.achieved_at && (
              <PercentWrapper>
                <CircularProgressbarWithChildren
                  value={percent}
                  strokeWidth={6}
                  styles={buildStyles({
                    pathColor: '#a3a2a2',
                    trailColor: 'transparent',
                    strokeLinecap: 'round',
                  })}
                />
              </PercentWrapper>
            )}
          </ImgCircle>
        </ImgWrapper>
        <Info>
          <BoldText>{badge.title}</BoldText>
          <Text>{badge.description}</Text>
          {achievedAt && <Text>{achievedAt}</Text>}
        </Info>
      </Content>
      <Footer>
        {showBadgeShare && (
          <Share onClick={handleShare}>
            <ShareIcon />
          </Share>
        )}
        <AllBadges onClick={onOpenAllBadges} noMargin>
          {translate('sdk.web.badges.all')}
        </AllBadges>
      </Footer>
    </Wrapper>,
    document.body,
  );
};

export default BadgeDetails;

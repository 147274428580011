import React, {useCallback, useMemo} from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import useAllowScale from 'hooks/app/use-allow-scale';
import {useOrganization} from 'hooks/use-organization';
import useNavigation from 'hooks/app/use-navigation';
import {trim} from 'utils/strings';

import InfoPage from 'components/InfoPage/InfoPage';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import FaqCategory from './FaqCategory';
import ContactForm from './ContactForm';

const categoriesStrings = [
  {
    key: 'general',
    name: 'faq_general_city_section_0',
    copy: 'faq_general_city_section_copy_0',
    questions: [
      {
        question: 'faq_general_city_question_0',
        answer: 'faq_general_city_answer_0',
      },
      {
        question: 'faq_general_city_question_1',
        answer: 'faq_general_city_answer_1',
      },
      {
        question: 'faq_general_city_question_2',
        answer: 'faq_general_city_answer_2',
      },
      {
        question: 'faq_general_city_question_3',
        answer: 'faq_general_city_answer_3',
      },
      {
        question: 'faq_general_city_question_4',
        answer: 'faq_general_city_answer_4',
      },
      {
        question: 'faq_general_city_question_5',
        answer: 'faq_general_city_answer_5',
      },
      {
        question: 'faq_general_city_question_6',
        answer: 'faq_general_city_answer_6',
      },
    ],
  },
  {
    key: 'technical',
    name: 'faq_technical_city_section_0',
    copy: 'faq_technical_city_section_copy_0',
    questions: [
      {
        question: 'faq_technical_city_question_0',
        answer: 'faq_technical_city_answer_0',
      },
      {
        question: 'faq_technical_city_question_1',
        answer: 'faq_technical_city_answer_1',
      },
      {
        question: 'faq_technical_city_question_2',
        answer: 'faq_technical_city_answer_2',
      },
      {
        question: 'faq_technical_city_question_3',
        answer: 'faq_technical_city_answer_3',
      },
      {
        question: 'faq_technical_city_question_4',
        answer: 'faq_technical_city_answer_4',
      },
      {
        question: 'faq_technical_city_question_5',
        answer: 'faq_technical_city_answer_5',
      },
      {
        question: 'faq_technical_city_question_6',
        answer: 'faq_technical_city_answer_6',
      },
      {
        question: 'faq_technical_city_question_7',
        answer: 'faq_technical_city_answer_7',
      },
    ],
  },
];

const Content = styled.div`
  display: block;

  .section {
    &__title {
      margin-bottom: 40px;
      color: #000;
    }

    &__info {
      margin-bottom: 20px;
    }

    &__btn {
      margin-bottom: 30px;
    }
  }
`;

const FaqButton = styled(Button)`
  padding-left: 10px;
  padding-right: 10px;
`;

const FaqPage = () => {
  useAllowScale();
  const history = useHistory();
  const {search} = useLocation();
  const {translate} = useTranslations();
  const {hasNewFAQ, organizationConfig} = useOrganization();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});

  const categories = useMemo(
    () =>
      categoriesStrings.map((category) => {
        const faqCategories = organizationConfig?.translations.faq?.categories;
        const customCategory =
          faqCategories?.find(
            (faqCategory: any) => faqCategory.key === category.key,
          ) || null;

        const name =
          translate(customCategory?.name || category.name) ||
          translate(category.name);

        return {
          id: category.key,
          cta: name,
          title: name,
          info: translate(category.copy),
          ...customCategory,
          questions: category.questions.map(({question, answer}) => {
            const answerEl = document.createElement('div');
            const customQuestion = customCategory?.questions?.find(
              ({key}: {key: string}) => key === question,
            );
            const answerText =
              translate(customQuestion?.answer || answer) || translate(answer);
            answerEl.innerHTML = answerText;

            return {
              id: question,
              title:
                translate(customQuestion?.question || question) ||
                translate(question),
              answer: answerText,
              answerSummary: trim({
                text: answerEl.innerText,
                length: 80,
              }),
            };
          }),
        };
      }),
    [translate, organizationConfig.translations],
  );

  const category = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const categoryId = searchParams.get('category');

    return categories.find(({id}) => id === categoryId);
  }, [categories, search]);

  const handleCategorySelect = useCallback(
    ({id}: {id: string}) => {
      history.push(`${routes.SETTINGS.CONTACT.CATEGORY.href}?category=${id}`);
    },
    [history],
  );

  return (
    <Switch>
      <Route path={routes.SETTINGS.CONTACT.CATEGORY.href}>
        <FaqCategory category={category!} />
      </Route>
      <Route path={routes.SETTINGS.CONTACT.FORM.href}>
        <ContactForm />
      </Route>
      <Route>
        {hasNewFAQ ? (
          <InfoPage
            title={translate('sdk.web.contact.title')}
            onClose={handleGoBack}>
            <Content>
              <div className="section__title">
                <HeaderText>{translate('sdk.web.faq.intro')}</HeaderText>
              </div>
              {categories.map((category) => (
                <div key={category.id}>
                  <div className="section__info">
                    <CopyText>{category.info}</CopyText>
                  </div>
                  <div className="section__btn">
                    <FaqButton
                      onClick={() => handleCategorySelect({id: category.id})}>
                      {category.cta}
                    </FaqButton>
                  </div>
                </div>
              ))}
            </Content>
          </InfoPage>
        ) : (
          <ContactForm />
        )}
      </Route>
    </Switch>
  );
};

export default FaqPage;

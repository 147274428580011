import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {
  EMISSION_TRANSACTIONS_KEYS,
  TRANSACTION_TYPES_KEYS,
} from 'constants/transaction-type';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';
import {ActivitySummary} from 'services/user';
import {useUserProfile} from 'hooks/use-user-profile';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {formatToken, numberToKm} from 'utils/numbers';

import InfoPage from 'components/InfoPage/InfoPage';
import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import Mobility from 'components/ClimateWidgets/Mobility/Mobility';

import carIcon from './icons/car.svg';
import planeIcon from './icons/plane.svg';
import meteringIcon from './icons/metering.svg';
import bannerImage from '../icons/banner.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 20px;
  overflow-y: scroll;

  .emission-stats {
    &__body {
      width: 100%;
    }

    &__header {
      margin-bottom: 20px;
    }

    &__banner {
      margin-bottom: 40px;
    }

    &__pending {
      margin-bottom: 25px;
    }

    &__activities {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-evenly;
    }

    &__activity-cr {
      width: 33.3%;
      flex-shrink: 0;
    }

    &__activity {
      width: 100px;
    }

    &__cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
`;

const ICONS: Record<EMISSION_TRANSACTIONS_KEYS, string> = {
  [TRANSACTION_TYPES_KEYS.PLANE]: planeIcon,
  [TRANSACTION_TYPES_KEYS.METERING]: meteringIcon,
  [TRANSACTION_TYPES_KEYS.CAR]: carIcon,
};

interface StatsPageProps {
  activitySummary: ActivitySummary;
}

const StatsPage = (props: StatsPageProps) => {
  const {activitySummary} = props;
  const {handleGoBack} = useNavigation({
    replaceLocation:
      routes.DASHBOARD.CONTRIBUTIONS.WIDGETS_SEARCH.CO2_EMISSIONS,
  });
  const {translate} = useTranslations();
  const {userProfile} = useUserProfile();
  const {getTokenText} = useTokenTranslation();
  const history = useHistory();

  const {co2Pending, token, activities} = useMemo(() => {
    if (!activitySummary) {
      return {activities: []};
    }

    const co2Emissions = numberToKm({
      number: activitySummary.user.co2_pending,
      ignoreThousandRule: true,
      dp: 1,
    });

    const activityKeys = Object.keys(activitySummary.emissions) as Array<
      keyof typeof activitySummary.emissions
    >;
    const activities = activityKeys.map((key) => {
      const percent = activitySummary.emissions[key];
      return {
        key,
        percent,
        icon: ICONS[key],
      };
    });
    const token = translate('sdk.web.emissions.stats.token', [
      {
        key: '{nr}',
        value: formatToken({
          number: userProfile.user.recoins,
          ignoreThousandRule: true,
        }),
      },
      {
        key: '{token}',
        value: getTokenText(userProfile.user.recoins),
      },
    ]);
    const co2Pending = translate('sdk.web.emissions.stats.pending', {
      key: '{amount}',
      value: co2Emissions,
    });

    return {
      co2Pending,
      activities,
      token,
    };
  }, [userProfile, activitySummary, translate, getTokenText]);

  return (
    <InfoPage
      title={translate('sdk.web.emissions.stats.header')}
      onClose={handleGoBack}>
      <Wrapper className="emission-stats">
        <div className="emission-stats__body">
          <CopyText
            as="div"
            className="emission-stats__header"
            dangerouslySetInnerHTML={{__html: token || ''}}
          />
          <img className="emission-stats__banner" src={bannerImage} alt="" />
          <CopyText
            as="div"
            className="emission-stats__pending"
            dangerouslySetInnerHTML={{__html: co2Pending || ''}}
          />
          <div className="emission-stats__activities">
            {activities.map((activity) => (
              <div key={activity.key} className="emission-stats__activity-cr">
                <div className="emission-stats__activity">
                  <Mobility
                    percent={activity.percent}
                    img={activity.icon}
                    bgColor=""
                    pathColor="#30454a"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <Button onClick={() => history.push(routes.CO2_EMISSIONS.OFFSET.href)}>
          {translate('sdk.web.emissions.stats.offset')}
        </Button>
      </Wrapper>
    </InfoPage>
  );
};

export default StatsPage;

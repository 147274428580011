import styled from 'styled-components';
import {addBoldText} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Label = styled(CopyTextSm)`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

export default Label;

import React from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import {ThreeDots} from 'react-loader-spinner';

import {useTranslations} from 'hooks/use-translations';
import {useBodyHiddenScroll} from 'hooks/ui/use-body-hidden-scroll';
import {addBoldText, addGlobalSquareCorners} from 'utils/theme';

import CopyText from 'components/Text/CopyText/CopyText';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Wrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 400000;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  color: ${(props) => props.theme.colors.black};
`;

const Overlay = styled.div`
  display: block;
  padding: 30% 10% 0;
  height: 100%;
  background: ${(props) => props.theme.colors.promptOverlayBg};
`;

const ContentBox = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  text-align: center;
  overflow: hidden;

  ${addGlobalSquareCorners()}
`;

const Title = styled(CopyText)`
  display: block;
  padding: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Message = styled(CopyTextSm)`
  display: block;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const Cta = styled(CopyText)`
  border-top: 1px solid #eee;
  display: flex;
`;

const CtaBtn = styled.button<{hasBorder?: boolean; singleRow?: boolean}>`
  width: ${(props) => (props.singleRow ? '100%' : '50%')};
  display: flex;
  padding: 10px 0;
  color: blue;
  outline: 0;
  border: 0;
  border-right: ${(props) => (props.hasBorder ? '1px solid #ddd' : 'none')};
  cursor: pointer;
  appearance: none;
  background-color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f7f7f7;
  }

  ${(props) =>
    addBoldText({
      props,
    })}
`;

type DialogBoxProps = {
  isLoading?: boolean;
  yesText?: string;
  noText?: string;
  singleText?: string;
  promptMessage: string;
  onConfirmation: (isConfirmed: boolean) => any;
  title?: string;
  hideCTA?: boolean;
};

const DialogBox = (props: DialogBoxProps) => {
  useBodyHiddenScroll();
  const {translate} = useTranslations();
  const defaultTitle = translate('sdk.web.dialog.box.default.title');
  const {
    isLoading,
    yesText,
    noText,
    singleText,
    promptMessage,
    onConfirmation,
    title = defaultTitle,
  } = props;

  return createPortal(
    <Wrapper>
      <Overlay>
        <ContentBox>
          <Title>{title}</Title>
          <Message dangerouslySetInnerHTML={{__html: promptMessage}} />
          {!props.hideCTA && (
            <Cta>
              {singleText ? (
                <CtaBtn
                  singleRow
                  autoFocus={true}
                  disabled={isLoading}
                  onClick={() => onConfirmation(true)}>
                  {singleText}
                </CtaBtn>
              ) : (
                <>
                  <CtaBtn
                    autoFocus={true}
                    disabled={isLoading}
                    hasBorder={true}
                    onClick={() => onConfirmation(false)}>
                    {noText}
                  </CtaBtn>
                  <CtaBtn
                    autoFocus={true}
                    disabled={isLoading}
                    onClick={() => onConfirmation(true)}>
                    {isLoading ? (
                      <ThreeDots color="blue" height={20} width={20} />
                    ) : (
                      yesText
                    )}
                  </CtaBtn>
                </>
              )}
            </Cta>
          )}
        </ContentBox>
      </Overlay>
    </Wrapper>,
    document.body,
  );
};

export default DialogBox;

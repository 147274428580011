import React, {useEffect} from 'react';
import styled from 'styled-components';

import routes from 'config/routes';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';

import InfoPage from 'components/InfoPage/InfoPage';

const AboutContent = styled.div`
  figcaption {
    text-align: center;
  }
`;

const AboutPage = () => {
  const {handleGoBack} = useNavigation({
    replaceLocation:
      routes.DASHBOARD.CONTRIBUTIONS.WIDGETS_SEARCH.CO2_EMISSIONS,
  });
  const {translate} = useTranslations();
  const {userProfile, reloadUserProfile} = useUserProfile();

  useEffect(() => {
    reloadUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const aboutContent = [
    <AboutContent>
      <div
        dangerouslySetInnerHTML={{
          __html: userProfile.organisation.co2_offset_info_text || '',
        }}
      />
    </AboutContent>,
  ];

  return (
    <InfoPage
      title={translate('sdk.web.emission.about.title')}
      rows={aboutContent}
      isLoading={false}
      onClose={handleGoBack}
    />
  );
};

export default AboutPage;

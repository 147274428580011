import React, {useEffect} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import routes from 'config/routes';
import {useUserProfile} from 'hooks/use-user-profile';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import InfoPage from 'components/InfoPage/InfoPage';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import TextXs from 'components/Text/TextXs/TextXs';
import AccountTitle from 'components/Account/AccountTitle';
import AccountSubtitle from 'components/Account/AccountSubtitle';
import PasswordChangePrompt from 'components/Account/PasswordChangePrompt';
import EmailChangePrompt from 'components/Account/EmailChangePrompt';
import LogoutDialogBox from 'components/Account/LogoutDialogBox';
import DeleteAccountDialogBox from 'components/Account/DeleteAccountDialogBox';
import {ReactComponent as NextIcon} from './icons/next.svg';
import {AccountType} from "types/User";

const Wrapper = styled.div`
  padding: 0 10px;
`;

const Item = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  padding: 12px 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Label = styled(CopyTextSm)`
  display: flex;
  color: ${(props) => props.theme.colors.black};
`;

const Icon = styled.div`
  width: 17px;
  fill: ${(props) => props.theme.colors.primary};
  stroke: ${(props) => props.theme.colors.primary};
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 10px;
  color: white;
  overflow: hidden;
`;

const AvatarBg = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
`;

const Name = styled.div`
  position: relative;
`;

const Info = styled.div`
  padding-right: 5px;
`;

const AccountPage = (props: any) => {
  const {userProfile, reloadUserProfile} = useUserProfile();
  const {translate} = useTranslations();
  const [
    isPasswordChangePromptOpen,
    openPasswordChangePrompt,
    closePasswordChangePrompt,
  ] = useBoolean(false);
  const [
    isEmailChangePromptOpen,
    openEmailChangePrompt,
    closeEmailChangePrompt,
  ] = useBoolean(false);
  const [isLogoutDialogOpen, openLogoutDialog, closeLogoutDialog] = useBoolean(
    false,
  );
  const [
    isDeleteAccountDialogOpen,
    openDeleteAccountDialog,
    closeDeleteAccountDialog,
  ] = useBoolean(false);

  useEffect(
    () => {
      reloadUserProfile();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onClose = (e: any) => {
    props.history.push(routes.SETTINGS.href);
  };

  if (userProfile?.user?.account_type === AccountType.GUEST) {
    return <Redirect to={routes.SETTINGS.REGISTER.href} />;
  }

  return (
    <InfoPage
      title={translate('sdk.web.account.profile.title')}
      onClose={onClose}>
      <Wrapper>
        <AccountTitle>
          {translate('sdk.web.account.profile.welcome')}
        </AccountTitle>
        <AccountSubtitle>
          {translate('sdk.web.account.profile.message')}
        </AccountSubtitle>
        <div>
          <Item
            onClick={() => props.history.push(routes.SETTINGS.PROFILE.href)}>
            <Label>
              <NameWrapper>
                <Avatar>
                  <AvatarBg />
                  <Name>{userProfile?.user?.firstname.substring(0, 1)}</Name>
                </Avatar>
                <Info>
                  {userProfile?.user?.firstname} {userProfile?.user?.lastname}
                  <br />
                  <TextXs>{userProfile?.user?.email}</TextXs>
                </Info>
              </NameWrapper>
            </Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
          <Item onClick={openPasswordChangePrompt}>
            <Label>
              {translate('sdk.web.account.profile.change.password')}
            </Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
          <Item onClick={openEmailChangePrompt}>
            <Label>{translate('sdk.web.account.profile.change.email')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
          <Item onClick={openLogoutDialog}>
            <Label>{translate('sdk.web.account.profile.logout')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
          <Item onClick={openDeleteAccountDialog}>
            <Label>{translate('sdk.web.account.profile.delete.account')}</Label>
            <Icon>
              <NextIcon />
            </Icon>
          </Item>
        </div>
        <PasswordChangePrompt
          isVisible={isPasswordChangePromptOpen}
          onClose={closePasswordChangePrompt}
        />
        <EmailChangePrompt
          isVisible={isEmailChangePromptOpen}
          onClose={closeEmailChangePrompt}
        />
        <LogoutDialogBox
          isOpen={isLogoutDialogOpen}
          onClose={closeLogoutDialog}
        />
        <DeleteAccountDialogBox
          isOpen={isDeleteAccountDialogOpen}
          onClose={closeDeleteAccountDialog}
        />
      </Wrapper>
    </InfoPage>
  );
};

export default withRouter(AccountPage);

import {ThemeProps} from 'styled-components';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import {lighten, multiply} from 'color-blend';

interface getColorInterface {
  props: ThemeProps<any>;
  color: string;
}

export const getThemeColor = ({props, color}: getColorInterface): string => {
  return props.theme.colors[color] || color;
};

export const mixColors = ({bg, fg}: {bg: string; fg: string}) => {
  const {r, g, b, a} = multiply(hex2Rgba({hex: bg}), hex2Rgba({hex: fg}));
  return `#${rgbHex(`rgba(${r}, ${g}, ${b}, ${a})`)}`;
};

export const lightenColor = ({
  bg,
  fg,
  opacity,
}: {
  bg: string;
  fg: string;
  opacity: number;
}) => {
  const {r, g, b, a} = lighten(
    hex2Rgba({hex: bg}),
    hex2Rgba({hex: fg, a: opacity}),
  );
  return `#${rgbHex(`rgba(${r}, ${g}, ${b}, ${a})`)}`;
};

export const hex2Rgba = ({hex, a}: {hex: string; a?: number}) => {
  const {red, green, blue, alpha} = hexRgb(hex);
  return {r: red, g: green, b: blue, a: a || alpha};
};

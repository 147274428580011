import React, {useCallback} from 'react';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';
import {PROMPTS} from 'constants/prompts';
import routes from 'config/routes';
import PromptModal from 'components/PromptModal/PromptModal';

const NewPrivacyPolicyPrompt = (props: any) => {
  const {prompts} = props;
  const {translate} = useTranslations();
  const {prompt, isVisible, handleModalClose, handleHidePrompt} = usePromptApi({
    prompts,
    type: PROMPTS.NEW_PRIVACY_POLICY_PROMPT,
    preventAutoHide: true,
  });

  const handlePromptClose = useCallback(async () => {
    handleHidePrompt();
    handleModalClose();
  }, [handleModalClose, handleHidePrompt]);

  if (!prompt) {
    return <React.Fragment />;
  }

  const TextContent = <div>{translate('sdk.web.pp.prompt.text')}</div>;

  const moreInfo = {
    text: translate('sdk.web.pp.prompt.link'),
    link: routes.SETTINGS.PRIVACY.href,
  };

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.pp.prompt.title')}
      textContent={TextContent}
      btnText={translate('sdk.web.pp.prompt.cta')}
      onBtnClick={handlePromptClose}
      moreInfo={moreInfo}
    />
  );
};

export default NewPrivacyPolicyPrompt;

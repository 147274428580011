import React, {useCallback} from 'react';
import styled from 'styled-components';
import useCustomActivity, {OnActivityComplete} from 'hooks/services/use-custom-activity';
import Loader from 'components/Loader/Loader';
import {CustomActivity} from 'types/CustomActivity';
import {ReactComponent as QrCodeIcon} from './icons/qr-code.svg';
import {ReactComponent as CheckIcon} from './icons/check.svg';

const Wrapper = styled.div`
  width: 40px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckIconWrapper = styled(IconWrapper)<{isCompleted: boolean}>`
  opacity: ${(props) => (props.isCompleted ? 1 : 0.15)};
  fill: ${(props) =>
    props.isCompleted
      ? props.theme.components.customActivities?.activeCheckColor ||
        props.theme.colors.primary
      : '#1a171b'};
`;

type ActivityCheckProps = {
  activity: CustomActivity;
  onActivityComplete: OnActivityComplete;
};

const ActivityCheck = (props: ActivityCheckProps) => {
  const {activity, onActivityComplete} = props;
  const {performActivity, isCompleting, DialogPrompt, SuccessPrompt} =
    useCustomActivity({
      activity,
      onActivityComplete,
    });

  const handleCompleteActivity = useCallback(
    async (e: any) => {
      e.preventDefault();

      if (activity.completed) {
        return;
      }

      await performActivity();
    },
    [activity, performActivity],
  );

  return (
    <>
      <Wrapper onClick={handleCompleteActivity}>
        {isCompleting ? (
          <Loader sm={true} color={'#222'} width={21} height={21} />
        ) : activity.qr_code && !activity.completed ? (
          <IconWrapper>
            <QrCodeIcon width="100%" />
          </IconWrapper>
        ) : (
          <CheckIconWrapper isCompleted={activity.completed}>
            <CheckIcon width="100%" />
          </CheckIconWrapper>
        )}
      </Wrapper>
      <SuccessPrompt />
      <DialogPrompt />
    </>
  );
};

export default ActivityCheck;

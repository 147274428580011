import {useCallback} from 'react';
import {formatNumber} from 'utils/numbers';

type GetRelativeTextParams = {percent: number; key: string; max?: number};

interface UseRelativeTextProps {
  translate: (key: string, options?: any) => string;
}

const useRelativeText = ({translate}: UseRelativeTextProps) => {
  return useCallback(
    ({percent, key, max}: GetRelativeTextParams) => {
      const relative = percent === 0 ? 'same' : percent < 0 ? 'less' : 'more';
      const number =
        typeof max === 'undefined'
          ? Math.abs(percent)
          : Math.min(Math.abs(percent), max);

      return translate(`${key}.${relative}`, {
        key: '{percent}',
        value: formatNumber({number, dp: 0}),
      });
    },
    [translate],
  );
};

export default useRelativeText;

const languageFonts = {
  en: {
    android: {
      primary: 'Roboto',
      secondary: 'Adobe Sketchnote',
    },
    ios: {
      primary: 'Helvetica Neue',
      secondary: 'Adobe Sketchnote',
    },
  },
  de: {
    android: {
      primary: 'Roboto',
      secondary: 'Adobe Sketchnote',
    },
    ios: {
      primary: 'Helvetica Neue',
      secondary: 'Adobe Sketchnote',
    },
  },
  pt: {
    android: {
      primary: 'Roboto',
      secondary: 'Adobe Sketchnote',
    },
    ios: {
      primary: 'Helvetica Neue',
      secondary: 'Adobe Sketchnote',
    },
  },
  ja: {
    android: {
      primary: 'Noto Sans',
      secondary: 'Noto Sans',
    },
    ios: {
      primary: 'Noto Sans',
      secondary: 'Noto Sans',
    },
  },
};

export default languageFonts;

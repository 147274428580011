import styled from 'styled-components';

import {addGlobalSquareCorners} from 'utils/theme';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Tag = styled(CopyTextSm)`
  display: block;
  padding: 1px 3px;
  border-radius: ${(props) =>
    props.theme.components.offers?.hasSquareCorners || '3px'};
  color: ${(props) =>
    props.theme.components.offers?.recoinsTextColor || 'black'};

  ${addGlobalSquareCorners()}
`;

export default Tag;

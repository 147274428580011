import React from 'react';
import Loader from 'components/Loader/Loader';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
`;

const WidgetLoader = () => (
  <Wrapper>
    <Loader color="#222" sm />
  </Wrapper>
);

export default WidgetLoader;

import {useContext, useMemo} from 'react';
import {generateCounterList} from 'utils/form';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {SocioDemoData} from 'types/SocioDemoData';

export const houseHoldOptions = generateCounterList({start: 1, end: 12});
export const carsOptions = generateCounterList({start: 0, end: 5});
export const ageOptions = generateCounterList({start: 1, end: 6});

export const genderOptions = [
  {
    id: 'male',
    value: 'male',
  },
  {
    id: 'female',
    value: 'female',
  },
  {
    id: 'other',
    value: 'other',
  },
];
export const ticketOptions = [
  {
    id: 'no',
    value: 'no',
  },
  {
    id: 'deutschlandticket',
    value: 'deutschlandticket',
  },
  {
    id: 'job_ticket',
    value: 'job_ticket',
  },
  {
    id: 'other',
    value: 'other',
  },
];

const jobOptions = [
  {
    id: 'student',
    value: 'student',
  },
  {
    id: 'employee',
    value: 'employee',
  },
  {
    id: 'self_employed',
    value: 'self_employed',
  },
  {
    id: 'retired',
    value: 'retired',
  },
  {
    id: 'jobless',
    value: 'jobless',
  },
  {
    id: 'other',
    value: 'other',
  },
];

const transportSharingOptions = [
  {
    id: 'no',
    value: 'no',
  },
  {
    id: 'yes',
    value: 'yes',
  },
];

const transportSharingModeOptions = [
  {
    id: 'car_sharing',
    value: 'car_sharing',
  },
  {
    id: 'car_pooling',
    value: 'car_pooling',
  },
  {
    id: 'bike_sharing',
    value: 'bike_sharing',
  },
  {
    id: 'e_scooter',
    value: 'e_scooter',
  },
];

const districtOptions = [
  {
    id: 'aachen-mitte',
    value: 'aachen_mitte',
    label: 'Aachen Mitte',
  },
  {
    id: 'brand',
    value: 'brand',
    label: 'Brand',
  },
  {
    id: 'eilendorf',
    value: 'eilendorf',
    label: 'Eilendorf',
  },
  {
    id: 'haaren',
    value: 'haaren',
    label: 'Haaren',
  },
  {
    id: 'kornelimünster/walheim',
    value: 'kornelimünster_walheim',
    label: 'Kornelimünster/Walheim',
  },
  {
    id: 'laurensberg',
    value: 'laurensberg',
    label: 'Laurensberg',
  },
  {
    id: 'richterich',
    value: 'richterich',
    label: 'Richterich',
  },
];

const useSocioDemoData = (socioDemoData?: SocioDemoData) => {
  const {organizationConfig} = useContext(SettingsContext);
  const {translate} = useTranslations();

  const data = useMemo(() => {
    const fields = [
      {
        name: 'gender',
        options: genderOptions,
      },
      {
        name: 'age',
        options: ageOptions,
        useCounterOptionsTranslation: true,
      },
      {
        name: 'job',
        options: jobOptions,
      },
      {
        name: 'household',
        options: houseHoldOptions,
        skipOptionsTranslations: true,
      },
      {
        name: 'cars',
        options: carsOptions,
        skipOptionsTranslations: true,
      },
      {
        name: 'ticket',
        options: ticketOptions,
      },
      {
        name: 'tp_sharing',
        options: transportSharingOptions,
      },
      {
        name: 'tp_sharing_mode',
        options: transportSharingModeOptions,
        type: 'radio',
        dependsOn: {
          name: 'tp_sharing',
          value: 'yes',
        },
      },
      {
        name: 'district',
        options: districtOptions,
        hiddenByDefault: true,
        skipOptionsTranslations: true,
      },
    ]
      .filter(
        (field) =>
          !field.hiddenByDefault ||
          organizationConfig.featuresConfig?.socioDemoData?.whitelistedFields?.includes(
            field.name,
          ),
      )
      .map(
        ({
          name,
          options,
          skipOptionsTranslations,
          useCounterOptionsTranslation,
          ...rest
        }) => ({
          ...rest,
          name,
          label: translate(`sdk.web.sdd.f.${name}.label`),
          placeholder: translate(`sdk.web.sdd.f.${name}.placeholder`),
          options: options.map(({id, ...rest}, index) => ({
            ...rest,
            id,
            ...(skipOptionsTranslations
              ? // @ts-ignore
                {label: rest.label || ''}
              : useCounterOptionsTranslation
              ? {
                  label: translate(`sdk.web.sdd.f.${name}.opt.${index + 1}`),
                  value: translate(`sdk.web.sdd.f.${name}.opt.${index + 1}`),
                }
              : {label: translate(`sdk.web.sdd.f.${name}.${id}`)}),
          })),
        }),
      );
    const initialValues = fields.reduce((acc, field) => {
      // @ts-ignore
      acc[field.name] = socioDemoData?.[field.name] || '';
      return acc;
    }, {});

    return {fields, initialValues};
  }, [translate, organizationConfig, socioDemoData]);

  return data;
};

export default useSocioDemoData;

import React, {useMemo} from 'react';
import styled from 'styled-components';
import {FormikContextType} from 'formik';

import {ApiErrors} from 'hooks/forms/use-form-validation';
import {InputStyles} from 'components/Form/InputStyles';

import FormError from 'components/Form/FormError';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import {ReactComponent as CaretIcon} from 'icons/caret-down.svg';
import {useTranslations} from 'hooks/use-translations';

const SelectBlock = styled(CopyTextSm)`
  position: relative;
  display: flex;
`;

const SelectValue = styled(InputStyles)<{defaultLabel: boolean}>`
  display: block;
  opacity: ${(props) => (props.defaultLabel ? 0.6 : 1)};
  padding-right: 28px;
`;

const SelectDropdown = styled.select`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Icon = styled.div`
  position: absolute;
  width: 25px;
  top: 0;
  right: 0;
  fill: ${(props) => props.theme.colors.primary};
`;

type DropdownItem = {
  id: number | string;
  value: any;
  label: string | number;
};

type DropdownProps = {
  name?: string;
  value?: string;
  optional?: boolean;
  defaultLabel?: string;
  list: Array<DropdownItem>;
  onChange: (e: any) => void;
  formik: FormikContextType<any>;
  apiErrors?: ApiErrors;
};

const Dropdown = (props: DropdownProps) => {
  const {
    name,
    value,
    optional,
    defaultLabel,
    list,
    formik,
    apiErrors,
    onChange,
  } = props;

  const {translate} = useTranslations();

  const label = useMemo(
    () =>
      list.find((item) => item.value.toString() === value?.toString())?.label ||
      value,
    [list, value],
  );

  return (
    <>
      <SelectBlock>
        <SelectValue as={'div'} defaultLabel={!label}>
          {label || defaultLabel || translate('sdk.web.input.select')}
        </SelectValue>
        <SelectDropdown name={name} onChange={onChange} value={value || ''}>
          <option value="" disabled={!optional}>
            {defaultLabel || translate('sdk.web.input.select')}
          </option>
          {list.map((item) => (
            <option key={item.id} value={item.value}>
              {item.label}
            </option>
          ))}
        </SelectDropdown>
        <Icon>
          <CaretIcon />
        </Icon>
      </SelectBlock>
      <FormError name={name} formik={formik} apiErrors={apiErrors} />
    </>
  );
};

export default Dropdown;

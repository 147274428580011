import React, {useRef} from 'react';
import {Route, Switch} from 'react-router-dom';
import routes from 'config/routes/co2Compensation';
import {useScrollToTop} from 'hooks/ui/use-scroll-to-top';
import CompensationPage from './CompensationPage/CompensationPage';
import AboutPage from './AboutPage/AboutPage';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) =>
    props.theme.components.co2Compensation.pageBgColor || 'transparent'};
`;

const Co2Compensation = () => {
  const wrapperRef = useRef(null);
  useScrollToTop({wrapperRef});

  return (
    <Wrapper ref={wrapperRef}>
      <Switch>
        <Route path={routes.ABOUT.href}>
          <AboutPage />
        </Route>
        <Route path={`${routes.href}/:month`}>
          <CompensationPage />
        </Route>
        <Route>
          <CompensationPage />
        </Route>
      </Switch>
    </Wrapper>
  );
};

export default Co2Compensation;

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 112px;
  height: 8px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #d6d6d6;
`;

const Inner = styled.div<{percent?: number; expired?: boolean}>`
  width: ${(props) => `${props.percent}%`};
  height: 100%;
  background-color: ${(props) =>
    props.expired
      ? '#ffafaf'
      : props.theme.components.vouchers.active?.progressColor || '#007D8A'};
`;

interface ProgressProps {
  percent?: number | 100;
  expired?: boolean;
}

const Progress = (props: ProgressProps) => {
  const {percent, expired} = props;

  return (
    <Wrapper>
      <Inner percent={percent} expired={expired} />
    </Wrapper>
  );
};

export default Progress;

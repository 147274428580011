import React, {useRef} from 'react';
import {Route, Switch} from 'react-router-dom';

import routes from 'config/routes';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useScrollToTop} from 'hooks/ui/use-scroll-to-top';

import SettingsHome from './SettingsHome/SettingsHome';

import AboutPage from './AboutPage/AboutPage';
import FaqPage from './FaqPage';
import PrivacyPage from './PrivacyPage/PrivacyPage';
import DeviceInfoPage from './DeviceInfoPage/DeviceInfoPage';
import ContactPage from './ContactPage';
import ContactForm from './ContactPage/ContactForm';
import TermsPage from './TermsPage/TermsPage';
import AboutTreePlantingPage from './AboutTreePlantingPage/AboutTreePlantingPage';
import AboutInviteFriendsPage from './AboutInviteFriendsPage/AboutInviteFriendsPage';

import RegisterPage from './RegisterPage/RegisterPage';
import LoginPage from './LoginPage/LoginPage';
import AccountPage from './AccountPage/AccountPage';
import ProfilePage from './ProfilePage/ProfilePage';
import LotteryFormPage from './LotteryFormPage';
import NotificationsPage from './NotificationsPage';
import WidgetCustomizationPage from './WidgetsCustomizationPage';
import VehicleSettings from './MobilitySettings/VehicleSettings';
import CarpoolingSettings from './MobilitySettings/CarpoolingSettings';
import ECarRating from './ECarRating';
import BambooCoin from './BambooCoin';
import SocioDemoDataPage from './SocioDemoDataPage';

const Settings = () => {
  const wrapperRef = useRef(null);
  useBodyBg({type: 'primary'});
  useScrollToTop({wrapperRef});

  return (
    <div ref={wrapperRef}>
      <Switch>
        <Route path={routes.SETTINGS.ACCOUNT.href}>
          <AccountPage />
        </Route>
        <Route path={routes.SETTINGS.PROFILE.href}>
          <ProfilePage />
        </Route>
        <Route path={routes.SETTINGS.REGISTER.href}>
          <RegisterPage />
        </Route>
        <Route path={routes.SETTINGS.LOGIN.href}>
          <LoginPage />
        </Route>
        <Route path={routes.SETTINGS.SOCIO_DEMO_DATA.href}>
          <SocioDemoDataPage />
        </Route>
        <Route path={routes.SETTINGS.ABOUT.href}>
          <AboutPage />
        </Route>
        <Route path={routes.SETTINGS.ABOUT_TREE_PLANTING.href}>
          <AboutTreePlantingPage />
        </Route>
        <Route path={routes.SETTINGS.ABOUT_INVITE_FRIENDS.href}>
          <AboutInviteFriendsPage />
        </Route>
        <Route path={routes.SETTINGS.FAQ.href}>
          <FaqPage />
        </Route>
        <Route path={routes.SETTINGS.PRIVACY.href}>
          <PrivacyPage />
        </Route>
        <Route path={routes.SETTINGS.TERMS.href}>
          <TermsPage />
        </Route>
        <Route path={routes.SETTINGS.DEVICE_INFO.href}>
          <DeviceInfoPage />
        </Route>
        <Route path={routes.SETTINGS.CONTACT.href}>
          <ContactPage />
        </Route>
        <Route path={routes.SETTINGS.REQUEST_DATA_DELETION.href}>
          <ContactForm requestDataDeletion={true} />
        </Route>
        <Route path={routes.SETTINGS.LOTTERY_FORM.href}>
          <LotteryFormPage />
        </Route>
        <Route path={routes.SETTINGS.NOTIFICATIONS.href}>
          <NotificationsPage />
        </Route>
        <Route path={routes.SETTINGS.WIDGETS_CUSTOMIZATION.href}>
          <WidgetCustomizationPage />
        </Route>
        <Route path={routes.SETTINGS.VEHICLE_SETTINGS.href}>
          <VehicleSettings />
        </Route>
        <Route path={routes.SETTINGS.CARPOOLING_SETTING.href}>
          <CarpoolingSettings />
        </Route>
        <Route path={routes.SETTINGS.E_CAR_RATING.href}>
          <ECarRating />
        </Route>
        <Route path={routes.SETTINGS.BAMBOO_COIN.href}>
          <BambooCoin />
        </Route>
        <Route>
          <SettingsHome />
        </Route>
      </Switch>
    </div>
  );
};

export default Settings;

import React from 'react';
import styled from 'styled-components';

import {addGlobalSquareCorners} from 'utils/theme';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: ${(props) =>
    props.theme.components.offers.headerIconsBgColor || 'white'};
  border-radius: 6px;
  padding: 6px;

  svg {
    width: 100%;
    fill: ${(props) =>
      props.theme.components.offers.headerIconsColor ||
      props.theme.colors.primary};
  }

  ${addGlobalSquareCorners()}
`;

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  Icon: React.ElementType;
}

const Icon = (props: IconProps) => {
  const {Icon, ...rest} = props;

  return (
    <Wrapper {...rest}>
      <Icon />
    </Wrapper>
  );
};

export default Icon;

export const addGlobalSquareCorners = () => {
  return (props: any) => {
    if (props.theme.components.global?.hasSquareCorners) {
      return `
        border-radius: 0;
      `;
    }
    return '';
  };
};

export const addGlobalBorderColor = () => {
  return (props: any) => {
    if (props.theme.components.global?.borderColor) {
      return `
        border-color: ${props.theme.components.global.borderColor};
      `;
    }
    return '';
  };
};

export const addGlobalBoxShadow = () => {
  return (props: any) => {
    if (props.theme.components.global?.boxShadow) {
      return `
        border: ${props.theme.components.global.boxShadow?.border};
        box-shadow: ${props.theme.components.global.boxShadow?.boxShadow};
      `;
    }
    return '';
  };
};

interface AddFontFamily {
  props: any;
  value: string | undefined;
}

export const addFontFamily = ({props, value}: AddFontFamily) => {
  if (value) {
    return `
        font-family: ${props.theme.font[value]?.name};
      `;
  }
  return '';
};

interface AddBoldText {
  props: any;
}

export const addBoldText = ({props}: AddBoldText) => {
  return `
      font-weight: ${
        props.theme.components.global?.boldTextFontWeight || '500'
      };
    `;
};

import React, {useState} from 'react';
import styled from 'styled-components';

import {OnActivityComplete} from 'hooks/services/use-custom-activity';
import {CustomActivity} from 'types/CustomActivity';
import {addGlobalBoxShadow, addGlobalSquareCorners} from 'utils/theme';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import ActivityDetails from 'components/CustomActivities/ActivityDetails';
import ActivityCheck from 'components/CustomActivities/ActivityCheck';
import TextXMedium from 'components/Text/TextXMedium';

import {ReactComponent as CaretIcon} from './icons/right-arrow.svg';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Title = styled(TextXMedium)`
  font-family: ${(props) => props.theme.font.secondary.name};
  margin-bottom: 6px;
  display: block;
  color: ${(props) =>
    props.theme.components.customActivities.titleColor || 'inherit'};

  ${(props) => {
    if (props.theme.components.customActivities?.listTitleFontFamily) {
      return `
        font-family: ${
          props.theme.font[
            props.theme.components.customActivities.listTitleFontFamily
          ]?.name
        };
      `;
    }
    return '';
  }}
`;

const ItemWrapper = styled.div<{hasCaret: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.hasCaret ? 'stretch' : 'center')};
`;

const Content = styled.div`
  background-color: ${(props) =>
    props.theme.components.customActivities?.listBgColor ||
    props.theme.colors.lightSecondary};
  border-radius: 6px;
  padding: 10px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  ${addGlobalBoxShadow()}
  ${addGlobalSquareCorners()}
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const Img = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: ${(props) =>
    props.theme.components.customActivities?.hasRoundedIcon ? '100%' : '5px'};
`;

const Text = styled(CopyTextSm)`
  width: calc(100% - 50px);
  color: ${(props) => props.theme.components.customActivities?.listTextColor};
`;

const CtaIconWrapper = styled.div<{sm?: boolean}>`
  width: ${(props) => (props.sm ? '32px' : '40px')};
  flex-shrink: 0;
  margin-left: 2px;
  fill: ${(props) =>
    props.theme.components.customActivities?.caretColor ||
    props.theme.colors.primary};
  display: flex;
  align-items: center;
`;

type ActivityItemProps = {
  activities: Array<CustomActivity>;
  hideCaret?: boolean;
  onCaretClick?: ({activity}: {activity: CustomActivity}) => void;
  onActivityComplete: OnActivityComplete;
};

const ActivityList = (props: ActivityItemProps) => {
  const {activities, hideCaret, onActivityComplete, onCaretClick} = props;

  const [selectedActivity, setSelectedActivity] = useState<
    CustomActivity | undefined
  >();

  return (
    <>
      <List>
        {activities.map((activity) => (
          <Item key={activity.id}>
            {activity.categoryName && <Title>{activity.categoryName}</Title>}
            <ItemWrapper hasCaret={!hideCaret}>
              <Content>
                <LeftContent onClick={() => setSelectedActivity(activity)}>
                  <Img src={activity.icon} alt="" />
                  <Text>{activity.name}</Text>
                </LeftContent>
                {!activity.editorial || activity.completed ? (
                  <ActivityCheck
                    activity={activity}
                    onActivityComplete={onActivityComplete}
                  />
                ) : (
                  hideCaret && (
                    <CtaIconWrapper
                      sm
                      onClick={() => setSelectedActivity(activity)}>
                      <CaretIcon width="100%" />
                    </CtaIconWrapper>
                  )
                )}
              </Content>
              {!hideCaret && (
                <CtaIconWrapper
                  onClick={() => onCaretClick && onCaretClick({activity})}>
                  <CaretIcon width="100%" />
                </CtaIconWrapper>
              )}
            </ItemWrapper>
          </Item>
        ))}
      </List>
      {selectedActivity && (
        <ActivityDetails
          onClose={() => setSelectedActivity(undefined)}
          onSuccess={onActivityComplete}
          activity={selectedActivity}
        />
      )}
    </>
  );
};

export default ActivityList;

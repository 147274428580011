import React, {useContext} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import routes from 'config/routes';
import {sendMessageToNativeApp} from 'services/native-api';
import {ReviewContext} from 'contexts/ReviewContext';

import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader/Loader';
import ActiveVoucher from 'components/Rewards/ClimatePartner/ActiveVoucher';
import VoucherOffer from 'components/Rewards/Vouchers/VoucherOffer';

import {VoucherType} from 'types/Offers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.white};
`;

const VoucherOfferPage = (props: any) => {
  const {setIsReviewOpen} = useContext(ReviewContext);

  const {
    vouchers,
    match,
    isLoading,
    isNew,
  }: {
    vouchers: Array<any>;
    match: any;
    isLoading: boolean;
    isNew?: boolean;
  } = props;
  const {id} = match.params;
  const voucher = vouchers.find(
    (voucher) => voucher.id.toString() === id.toString(),
  );

  const onClose = () => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.DASHBOARD.REWARDS.VOUCHERS.href);
    }

    if (isNew) {
      setIsReviewOpen && setIsReviewOpen(true);
    }
  };

  const handleVoucherShare = () => {
    const message = {
      type: 'share_voucher_code',
      display_format: voucher.display_format,
      voucher_code: voucher.code,
      qr_code: voucher.qr_code || null,
      email: voucher.email,
    };
    sendMessageToNativeApp({message});
  };

  if (voucher?.offer.offer_type_id === VoucherType.OFFLINE) {
    return (
      <ActiveVoucher
        voucher={voucher.offer}
        purchasedVoucher={voucher}
        onClose={onClose}
      />
    );
  }

  return (
    <Wrapper>
      <Modal>
        <Content>
          {voucher && (
            <VoucherOffer
              voucher={voucher}
              onClose={onClose}
              onVoucherShare={handleVoucherShare}
            />
          )}
          {isLoading && <Loader color={'#000'} />}
        </Content>
      </Modal>
    </Wrapper>
  );
};

export default withRouter(VoucherOfferPage);

import React from 'react';
import styled from 'styled-components';

import CopyText from 'components/Text/CopyText/CopyText';
import PromptModal from 'components/PromptModal/PromptModal';

import img from '../images/lot-results.svg';

const Content = styled(CopyText)`
  display: block;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
`;


const LotResults = (props: any) => {
  const {
    title,
    btnTitle,
    onClose = () => false,
    onBtnClick = () => false,
    children,
  } = props;

  return (
    <PromptModal
      onClose={onClose}
      isVisible={true}
      title={title}
      image={img}
      textContent={<Content>{children}</Content>}
      btnText={btnTitle}
      onBtnClick={onBtnClick}
    />
  );
};

export default LotResults;

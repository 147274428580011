import React from 'react';
// import HeartComponent from './Heart';

import T0 from './images/hearts/T0.svg';
import T1 from './images/hearts/T1.svg';
import T2 from './images/hearts/T2.svg';
import T3 from './images/hearts/T3.svg';
import T4 from './images/hearts/T4.svg';
import T5 from './images/hearts/T5.svg';
import T6 from './images/hearts/T6.svg';
import T7 from './images/hearts/T7.svg';
import T8 from './images/hearts/T8.svg';
import T9 from './images/hearts/T9.svg';
import T10 from './images/hearts/T10.svg';
import T11 from './images/hearts/T11.svg';
import T12 from './images/hearts/T12.svg';

const hearts = [T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12];

const Co2Heart = (props: any) => {
  const {percent} = props;
  const heartIndex = Math.floor((percent / 100) * (hearts.length - 1));
  const heartImg = hearts[heartIndex];

  return (
    <React.Fragment>
      <img src={heartImg} alt="" />
    </React.Fragment>
  );
};

export default Co2Heart;

import React, {useContext, useMemo} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import routes from 'config/routes/water';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {useTranslations} from 'hooks/use-translations';
import {WaterStats} from 'types/Water';
import {formatNumber} from 'utils/numbers';
import {getMonthKeyFromDate} from 'utils/date';
import {addBoldText} from 'utils/theme';

import Mobility from 'components/ClimateWidgets/Mobility/Mobility';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import CopyText from 'components/Text/CopyText/CopyText';
import CtaLink from 'components/CtaLink/CtaLink';
import PreviousResults from './PreviousResults';

import {ReactComponent as InfoIcon} from 'icons/info-white.svg';
import circleImage from './images/logo.svg';
import bannerImage from './images/tips.svg';
import {useBoolean} from 'hooks/utils/use-boolean';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const InfoIconWrapper = styled(Link)`
  position: absolute;
  top: 0;
  right: 12px;
  z-index: 1;
  width: 27px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Budget = styled.div`
  margin-top: 30px;
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  flex: 1;
  width: 30%;
`;

const Item = styled.div`
  padding: 20px 5px;
`;

const Text = styled(CopyText)<{textAlign?: string}>`
  display: block;
  line-height: normal;
  margin-bottom: 5px;
  text-align: ${(props) => props.textAlign || 'center'};
  color: ${(props) => props.theme.colors.black};
  font-weight: 400;

  b {
    ${(props) =>
      addBoldText({
        props,
      })}
  }
`;

const BoldText = styled.span`
  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Circle = styled.div`
  width: 85px;
`;

const Comparison = styled(CopyText)`
  margin: 0 auto;
  margin-bottom: 10px;
  width: 80%;
`;

const Bonus = styled.div`
  margin-bottom: 40px;
`;

const BannerLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BannerImage = styled.img`
  margin-bottom: 27px;
  width: 80%;
`;

const Settings = styled(CtaLink)`
  margin-bottom: 5px;
`;

type WidgetProps = {
  stats: WaterStats;
};

const Widget = (props: WidgetProps) => {
  const {stats} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const {getShortTokenText} = useTokenTranslation();
  const {translate} = useTranslations();
  const [isResultsOpen, showResults, hideResults] = useBoolean(false);

  const {
    comparison,
    percent,
    waterLeft,
    waterUsed,
    waterBudget,
    isSameDay,
    projectedBonus,
    projectionInfo,
    expectedBonus,
    settings,
    previousResults,
  } = useMemo(() => {
    const performance = formatNumber({
      number: Math.abs(stats.relative_performance),
      dp: 0,
    });
    const monthKey = getMonthKeyFromDate();
    const month = translate(`sdk.web.month.${monthKey}`);

    const projectionPercent = 20;
    const projectedBonus =
      (projectionPercent / 100) * stats.recoins_per_liter * stats.goal;

    const isSameDay = moment().isSame(moment(stats.connect_date), 'day');

    return {
      isSameDay,

      settings: translate('sdk.web.water.widget.settings'),
      previousResults: translate('sdk.web.water.widget.previous.results'),
      percent: ((stats.goal - stats.current_consumption) / stats.goal) * 100,

      waterUsed: translate('sdk.web.water.widget.water.used', {
        key: '{nr}',
        value: formatNumber({
          number: stats.current_consumption,
          dp: 0,
        }),
      }),

      waterLeft: translate('sdk.web.water.widget.water.left', {
        key: '{nr}',
        value: formatNumber({
          number: stats.goal - stats.current_consumption,
          dp: 0,
        }),
      }),

      waterBudget: translate('sdk.web.water.widget.water.budget', [
        {
          key: '{nr}',
          value: formatNumber({number: stats.goal, dp: 0}),
        },
        {
          key: '{month}',
          value: month,
        },
      ]),

      expectedBonus: translate('sdk.web.water.widget.expected.bonus', {
        key: '{nr}',
        value: `${formatNumber({
          number: stats.projected_recoins,
        })} ${getShortTokenText(stats.projected_recoins)}`,
      }),

      projectedBonus: translate('sdk.web.water.widget.projection.bonus', [
        {
          key: '{percent}',
          value: projectionPercent,
        },
        {
          key: '{nr}',
          value: `${formatNumber({
            number: projectedBonus,
            dp: 0,
          })} ${getShortTokenText(projectedBonus)}`,
        },
      ]),

      projectionInfo: translate('sdk.web.water.widget.projection.info'),

      comparison:
        stats.relative_performance === 0
          ? translate('sdk.web.water.widget.performance.same')
          : stats.relative_performance < 0
          ? translate('sdk.web.water.widget.performance.worse', {
              key: '{nr}',
              value: performance,
            })
          : translate('sdk.web.water.widget.performance.better', {
              key: '{nr}',
              value: performance,
            }),
    };
  }, [stats, getShortTokenText, translate]);

  return (
    <Wrapper>
      <WidgetTitle>
        {translate('sdk.web.water.widget.title')}
        <InfoIconWrapper to={routes.ABOUT.href}>
          <InfoIcon width="100%" />
        </InfoIconWrapper>
      </WidgetTitle>
      <Budget>
        <Text dangerouslySetInnerHTML={{__html: waterBudget}} />
      </Budget>
      <Columns>
        <Column>
          <Item>
            <Text textAlign="right">
              <BoldText>{waterUsed}</BoldText>
            </Text>
          </Item>
        </Column>
        <Column>
          <Item>
            <Link to={routes.ABOUT.href}>
              <Circle>
                <Mobility
                  percent={percent}
                  img={circleImage}
                  hidePercent={true}
                  sm={true}
                  pathColor={organizationConfig.theme.colors.primary}
                  bgColor={organizationConfig.theme.colors.white}
                />
              </Circle>
            </Link>
          </Item>
        </Column>
        <Column>
          <Item>
            <Text textAlign="left">
              <BoldText>{waterLeft}</BoldText>
            </Text>
          </Item>
        </Column>
      </Columns>
      {isSameDay ? (
        <>
          <Comparison>
            <Text dangerouslySetInnerHTML={{__html: projectedBonus}} />
          </Comparison>
          <Bonus>
            <Text dangerouslySetInnerHTML={{__html: projectionInfo}} />
          </Bonus>
        </>
      ) : (
        <>
          <Comparison>
            <Text dangerouslySetInnerHTML={{__html: comparison}} />
          </Comparison>
          <Bonus>
            <Text dangerouslySetInnerHTML={{__html: expectedBonus}} />
          </Bonus>
        </>
      )}
      <BannerLink to={routes.TIPS.href}>
        <BannerImage src={bannerImage} alt="" />
      </BannerLink>
      <Settings as={Link} to={routes.href}>
        <CopyText>{settings}</CopyText>
      </Settings>
      <Settings as={'a'} onClick={showResults}>
        <CopyText>{previousResults}</CopyText>
      </Settings>
      {stats.has_previous_results && (
        <PreviousResults isOpen={isResultsOpen} onClose={hideResults} />
      )}
    </Wrapper>
  );
};

export default Widget;

import React, {useMemo} from 'react';
import styled from 'styled-components';

import routes from 'config/routes';
import {useOrganization} from 'hooks/use-organization';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {CustomActivity} from 'types/CustomActivity';
import {numberToKm} from 'utils/numbers';

import CopyText from 'components/Text/CopyText/CopyText';
import PromptModal from 'components/PromptModal/PromptModal';

const Text = styled(CopyText)`
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
  text-align: center;
  width: 80%;
`;

type SuccessPromptProps = {
  isVisible: boolean;
  activity: CustomActivity;
  onClose: () => void;
};

const SuccessPrompt = (props: SuccessPromptProps) => {
  const {onClose, activity, isVisible} = props;
  const {translate} = useTranslations();
  const {getShortTokenText} = useTokenTranslation();
  const {organizationConfig, hasWallets} = useOrganization();

  const co2Savings = useMemo(() => {
    if (!activity.co2_avoided) {
      return '';
    }

    const savings = numberToKm({number: activity.co2_avoided});
    return translate('sdk.web.ca.success.co2Savings', {
      key: '{co2Savings}',
      value: savings,
    });
  }, [activity, translate]);

  const recoins = useMemo(() => {
    if (!activity.recoins) {
      return '';
    }

    return translate('sdk.web.ca.success.recoins', {
      key: '{recoins}',
      value: `${activity.recoins} ${getShortTokenText(activity.recoins)}`,
    });
  }, [activity, translate, getShortTokenText]);

  return (
    <PromptModal
      onClose={onClose}
      isVisible={isVisible}
      title={translate('sdk.web.ca.success.title')}
      textContent={
        isVisible ? (
          <>
            {co2Savings && <Text>{co2Savings}</Text>}
            {recoins && <Text>{recoins}</Text>}
          </>
        ) : undefined
      }
      image={organizationConfig.fullTokenImg}
      btnText={translate('sdk.web.ca.success.cta')}
      onBtnClick={onClose}
      moreInfo={
        hasWallets
          ? {
              text: translate('sdk.web.ca.success.wallet.cta'),
              link: routes.DASHBOARD.WALLET.href,
            }
          : null
      }
    />
  );
};

export default SuccessPrompt;

import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import routes from 'config/routes/rewards';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useTranslations} from 'hooks/use-translations';
import {addBoldText} from 'utils/theme';

import Loader from 'components/Loader/Loader';
import PullToRefresh from 'components/PullToRefresh';
import CopyText from 'components/Text/CopyText/CopyText';
import LotCard from './LotCard/LotCard';

const Wrapper = styled.div`
  padding: 20px 10px;
  min-height: 80vh;
  color: ${(props) =>
    props.theme.components.lots?.textColor || props.theme.colors.black};
`;

const Title = styled(CopyText)`
  display: block;
  margin-bottom: 8px;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const List = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled(Link)`
  width: 100%;
  margin-bottom: 8px;
  display: block;

  &,
  &:hover {
    text-decoration: none;
    color: ${(props) =>
      props.theme.components.lots?.textColor || props.theme.colors.black};
  }
`;

const Empty = styled(CopyText)`
  display: block;
  text-align: center;
  padding: 10% 2%;
`;

type LotsProps = {
  lots: Array<any>;
  isLoading: boolean;
  onRefresh: () => any;
};

const Lots = (props: LotsProps) => {
  const {lots, isLoading, onRefresh} = props;
  useBodyBg({type: 'primary'});
  const {translate} = useTranslations();

  return (
    <PullToRefresh onRefresh={onRefresh}>
      <Wrapper>
        {lots.length ? (
          <React.Fragment>
            <Title>{translate('sdk.web.lots.title')}</Title>
            <List>
              {lots.map((lot: any, index: number) => (
                <Item
                  key={index}
                  to={`${routes.REWARDS.LOTS.href}/${lot.lottery.id}`}>
                  <LotCard lot={lot} noOfLots={lot.lots.length} />
                </Item>
              ))}
            </List>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!isLoading && <Empty>{translate('sdk.web.lots.empty')}</Empty>}
          </React.Fragment>
        )}
        {isLoading && <Loader />}
      </Wrapper>
    </PullToRefresh>
  );
};

export default Lots;

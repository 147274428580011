import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import routes from 'config/routes';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useTranslations} from 'hooks/use-translations';
import {useFetcher} from 'hooks/use-fetcher';
import {getVoucher} from 'services/vouchers';

import Modal from 'components/Modal/Modal';
import SummaryPage from 'components/SummaryPage/SummaryPage';
import Loader from 'components/Loader/Loader';
import OfferRestrictions from 'components/Rewards/Deals/OfferRestrictions';

const Content = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  min-height: 100vh;
  overflow-y: scroll;
`;

const VouchersTermsPage = (props: any) => {
  const {match}: {match: any} = props;
  useBodyBg({type: 'light'});
  const {translate} = useTranslations();
  const {id} = match.params;

  const {isLoading, data: voucher}: {isLoading: boolean; data: any} =
    useFetcher({
      fetcher: getVoucher,
      params: {id},
      key: `voucher-${id}`,
    });

  const onClose = () => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.DASHBOARD.REWARDS.VOUCHERS.href);
    }
  };

  return (
    <Modal>
      <Content>
        <SummaryPage
          onClose={onClose}
          title={translate('sdk.web.offers.terms')}>
          {!!voucher && (
            <div>
              <div dangerouslySetInnerHTML={{__html: voucher.terms}} />
              <OfferRestrictions offer={voucher} />
            </div>
          )}
        </SummaryPage>
        {isLoading && <Loader color={'#000'} />}
      </Content>
    </Modal>
  );
};

export default withRouter(VouchersTermsPage);

import React from 'react';
import {Team} from 'types/Team';
import styled from 'styled-components';

import {CACHE_KEYS} from 'constants/cache-keys';
import {getTeamTypeTeams} from 'services/teams';
import {useFetcher} from 'hooks/use-fetcher';

import Loader from 'components/Loader/Loader';
import TeamRanking from './TeamRanking/TeamRanking';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import {CHALLENGE_TYPES} from 'constants/challenges';

const Wrapper = styled.div`
  padding: 0 20px;
`;

interface FullTeamLeaderboardProps {
  isVisible: boolean;
  title: string;
  image: string;
  challengeId: number;
  challengeType: CHALLENGE_TYPES;
  teamTypeId: number;
  isOwnTeam: ({team}: {team: Team}) => boolean;
  onClose: () => any;
}

const FullTeamLeaderboard = (props: FullTeamLeaderboardProps) => {
  const {
    isVisible,
    title,
    image,
    challengeId,
    challengeType,
    teamTypeId,
    isOwnTeam,
    onClose,
  } = props;
  const {data: teams, isLoading} = useFetcher({
    fetcher: getTeamTypeTeams,
    params: {challengeId, teamTypeId},
    preventFetch: !isVisible,
    key: `${CACHE_KEYS.TEAM_TYPES_TEAMS}-${challengeId}-${teamTypeId}`,
  });

  return (
    <PromptWrapper
      isVisible={isVisible}
      title={title}
      image={image}
      onClose={onClose}
      styles={{pb: '0'}}>
      <Wrapper>
        {isLoading && !teams && <Loader color="#222" />}
        {teams && (
          <TeamRanking
            teams={teams}
            isOwnTeam={isOwnTeam}
            challengeType={challengeType}
          />
        )}
      </Wrapper>
    </PromptWrapper>
  );
};

export default FullTeamLeaderboard;

import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import classnames from 'classnames';

import routes from 'config/routes';
import {CACHE_KEYS} from 'constants/cache-keys';
import {NotificationContext} from 'contexts/NotificationContext';
import {useTranslations} from 'hooks/use-translations';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import useNotifications from 'hooks/services/notifications/use-notifications';
import {getNewsFeed} from 'services/news-feed';
import {trim} from 'utils/strings';
import {addGlobalSquareCorners} from 'utils/theme';
import {NewsFeed} from 'types/NewsFeed';

import Loader from 'components/Loader/Loader';
import NewsFeedContent from './NewsFeedContent';
import TextMedium from 'components/Text/TextMedium/TextMedium';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import {ReactComponent as CaretIcon} from 'icons/caret-right.svg';

import blogImage from './images/blog.jpg';

const Wrapper = styled.div`
  height: 100%;
  margin-bottom: -24px;
  background-color: ${(props) =>
    props.theme.components.news?.bgColor || 'transparent'};

  .news {
    &__notifs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px 23px;
      text-decoration: none;
      background-color: ${(props) =>
        props.theme.components.news?.notificationsBgColor || '#80bcc3'};
      color: ${(props) => props.theme.colors.black};
      margin-bottom: 5px;

      &--new {
        background-color: #fcd921;
      }
    }

    &__notifs-caret {
      fill: ${(props) =>
        props.theme.components.news?.notificationsCaretColor ||
        props.theme.colors.primary};
      width: 20px;

      svg {
        width: 100%;
      }
    }
  }
`;

const List = styled.ul`
  display: block;
  margin: 0;
  padding: 10px;
  padding-bottom: 2px;
`;

const Item = styled.li`
  display: block;
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  ${(props) => {
    if (props.theme.components.news?.style === 'comment') {
      return `
        background-color: transparent;
      `;
    }
    return '';
  }}
  ${addGlobalSquareCorners()}
`;

const Title = styled(TextMedium)`
  position: relative;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-family: ${(props) => props.theme.font.secondary.name};

  ${(props) => {
    if (props.theme.components.news?.style === 'comment') {
      return `
        padding: 15px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;
        margin-bottom: 25px;
        border-radius: 10px;
        background-color: ${props.theme.components.news?.titleBgColor};
        color: ${props.theme.components.news?.titleColor};
      `;
    }
    return `
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `;
  }}
  span {
    position: relative;
    z-index: 2;
  }

  ${addGlobalSquareCorners()}
`;

const DownArrow = styled.div`
  position: absolute;
  display: ${(props) =>
    props.theme.components.news?.style === 'comment' ? 'block' : 'none'};
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 35px solid;
  border-top-color: ${(props) =>
    props.theme.components.news?.titleBgColor || 'transparent'};
  bottom: -20px;
  left: 15px;
  z-index: 1;
`;

const Image = styled.img`
  width: 100%;
  background-color: ${(props) =>
    props.theme.components.news?.bgColor || 'transparent'};
  ${(props) => {
    if (props.theme.components.news?.style === 'comment') {
      return `
        min-height: 220px;
        object-fit: cover;
        border-radius: 10px;
      `;
    }
    return '';
  }}
  ${addGlobalSquareCorners()}
`;

const Summary = styled(CopyTextSm)`
  display: block;
  padding: 10px;
  text-align: left;
  ${(props) => {
    if (props.theme.components.news?.style === 'comment') {
      return `
        position: absolute;
        bottom: 13px;
        left: 10px;
        width: calc(100% - 20px);
        background-color: rgba(255, 255, 255, 80%); 
        border-radius: 10px;
      `;
    }
    return '';
  }}
  ${addGlobalSquareCorners()}
`;

const NewsFeedPage = () => {
  const {organizationConfig} = useOrganization();
  const {stats} = useContext(NotificationContext);
  const {translate} = useTranslations();
  const {showNotifications} = useNotifications();

  const {data, isLoading} = useFetcher({
    fetcher: organizationConfig?.services?.getNewsFeed || getNewsFeed,
    key: CACHE_KEYS.NEWS_FEED,
  });

  const [newsFeed, setNewsFeed] = useState<NewsFeed>();

  return (
    <Wrapper>
      {showNotifications && (
        <Link
          to={routes.NOTIFICATIONS.href}
          className={classnames('news__notifs', {
            'news__notifs--new': stats?.unseen > 0,
          })}>
          <CopyTextSm>
            <b>
              {translate(
                stats?.unseen > 0
                  ? 'sdk.web.news.notifications.new'
                  : 'sdk.web.news.notifications',
              )}
            </b>
          </CopyTextSm>
          <div className="news__notifs-caret">
            <CaretIcon />
          </div>
        </Link>
      )}
      {isLoading ? (
        <Loader color="#222" />
      ) : (
        <List>
          {data?.map((feed: NewsFeed) => (
            <Item key={feed.id} onClick={() => setNewsFeed(feed)}>
              <Title as="div">
                <span>{trim({text: feed.title, length: 75})}</span>
                <DownArrow />
              </Title>
              <Image src={feed.headerImage || blogImage} alt={feed.title} />
              <Summary>{trim({text: feed.summary, length: 75})}</Summary>
            </Item>
          ))}
        </List>
      )}
      {newsFeed && (
        <NewsFeedContent
          newsFeed={newsFeed}
          onClose={() => setNewsFeed(undefined)}
        />
      )}
    </Wrapper>
  );
};

export default NewsFeedPage;

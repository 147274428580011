import {fetchAPiClient} from './api';
import {UserProfile} from './user';
import {groupBy, map} from 'lodash';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

interface GetPurchasedLots {
  userProfile: UserProfile;
}

export const getPurchasedLots = async ({userProfile}: GetPurchasedLots) => {
  const client = defaultApiClient;

  const url = '/lottery/lots/purchased';
  const purchasedLotsResponse = await client.get(url);

  const purchasedLots = purchasedLotsResponse.data.items.map((lot: any) => {
    const lottery = lot.lottery;
    const isLotteryDrawn =
      lottery.lottery_result && lottery.lottery_result.length;

    const lotData = {
      ...lot,
      status: isLotteryDrawn ? 'lost' : 'ongoing',
      prize: {},
    };

    if (isLotteryDrawn) {
      const wonLot = lottery.lottery_result.find(
        ({user_id}: {user_id: number}) => user_id === userProfile?.user.id,
      );

      if (wonLot) {
        lotData.status = 'won';
        lotData.prize = lottery.prizes.find(
          ({id}: {id: number}) => id === wonLot.lottery_prize_id,
        );
      }
    }

    return lotData;
  });

  const groupedLotteries = groupBy(purchasedLots, (lot) => lot.lottery.id);

  const purchasedLotteries = map(groupedLotteries, (lotteryGroup) => {
    return {
      lottery: lotteryGroup[0].lottery,
      status: lotteryGroup[0].status,
      lots: lotteryGroup,
    };
  });

  return purchasedLotteries;
};

export const getAllLotteries = async ({tags} = {tags: undefined}) => {
  const client = defaultApiClient;
  const url = '/lottery';
  const lotteries = await client.get(url, {params: {per_page: 500, tags}});

  const today = new Date();
  return lotteries.data.items.map((lottery: any) => {
    const endDate = new Date(lottery.end_date);
    const is_ongoing = endDate > today && !!lottery.activated_at;

    return {
      ...lottery,
      is_ongoing,
    };
  });
};

export const getActiveLotteries = async (params: any) => {
  const allLotteries = await getAllLotteries(params);
  return allLotteries
    .filter((lottery: any) => {
      return lottery.is_ongoing && lottery.enabled && lottery.lottery_available;
    })
    .sort((a: any, b: any) => a.end_date.localeCompare(b.end_date));
};

export const purchaseLottery = async ({data}: {data: any}) => {
  const client = defaultApiClient;
  const url = `/lottery/${data.lottery_id}/purchase`;
  return client.post(url, data);
};

export const getLottery = async ({id}: {id: number}) => {
  const client = defaultApiClient;
  const url = `/lottery/${id}`;
  const {data} = await client.get(url);
  return data;
};

export const getLotteryTags = () => {
  const client = defaultApiClient;
  const url = '/tag/lottery';
  return client.get(url, {params: {per_page: 500}});
};

import React from 'react';
import styled from 'styled-components';
import {InputStyles} from 'components/Form/InputStyles';
import DefaultInput, {InputProps} from 'components/Form/Input';

const InputEl = styled(InputStyles).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    // @ts-ignore
    prop === 'enterkeyhint' || defaultValidatorFn(prop),
})`
  padding: 12px;
  border-radius: 4px;
`;

const Input = (props: InputProps) => {
  return <DefaultInput {...props} InputEl={InputEl} />;
};

export default Input;

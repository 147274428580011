import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useUserProfile} from 'hooks/use-user-profile';
import {useOrganization} from 'hooks/use-organization';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {formatToken, numberToKm} from 'utils/numbers';

import CopyText from 'components/Text/CopyText/CopyText';
import Loader from 'components/Loader/Loader';
import ClimateIconScan from 'components/Rewards/ClimatePartner/ClimateIconScan';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import TrackingWarning from '../TrackingWarning/TrackingWarning';

import {ReactComponent as WalletIcon} from 'icons/wallet.svg';
import InfoPanda from './images/Info_Panda.svg';

const TokenContainer = styled.div`
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Details = styled(CopyText)`
  display: block;
  text-align: center;
`;

const InfoIconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const WalletWrapper = styled(HeaderText)`
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.font.secondary.name};
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;

const WalletIconWrapper = styled.div`
  display: flex;
  margin-bottom: 3px;
  width: 64px;

  svg {
    width: 100%;
    fill: ${(props) => props.theme.colors.primary};
    stroke: ${(props) =>
      props.theme.components.co2Token?.walletStrokeColor || 'white'};
  }
`;

const InfoLink = styled(Link)`
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const InfoIcon = styled.img`
  display: inline-block;
  width: 100px;
`;

const Token = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const TokenCount = styled(CopyText)`
  color: ${(props) => props.theme.colors.primary};
  margin-right: 7px;
  vertical-align: middle;
  display: inline-block;
`;

const TokenImage = styled.img`
  display: inline-block;
  width: 40px;
  vertical-align: middle;
`;

const Co2Token = (props: any) => {
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const [isTrackingWarningVisible, setIsTrackingWarningVisible] =
    useState(false);
  const {userProfile} = useUserProfile();
  const {hasHomeQrScanner, hasWallets} = useOrganization();
  const {getTokenText} = useTokenTranslation();

  const {activitySummary} = props;
  const co2Needed = activitySummary
    ? numberToKm({number: activitySummary.co2Needed, dp: 2})
    : 0;
  const recoins = activitySummary ? parseInt(activitySummary?.user.recoins) : 0;
  const percent = activitySummary ? activitySummary.percent : 0;

  useEffect(() => {
    setTimeout(() => {
      setIsTrackingWarningVisible(true);
    }, 1500);
  }, []);

  return (
    <>
      {isTrackingWarningVisible && <TrackingWarning />}
      <WidgetCard>
        <Header>
          <WidgetTitle>
            {translate(organizationConfig.translations.offersTitle, {
              key: '{token}',
              value: getTokenText(),
            })}
            :{' '}
            {formatToken({
              number: userProfile?.user?.recoins || 0,
            })}
          </WidgetTitle>
        </Header>
        <Details>
          {!!activitySummary ? (
            translate(organizationConfig.translations.co2Needed, [
              {
                key: '{co2Value}',
                value: co2Needed.toString(),
              },
              {
                key: '{token}',
                value: getTokenText(1),
              },
            ])
          ) : (
            <Loader sm={true} />
          )}
        </Details>
        <TokenContainer>
          {organizationConfig.FullTokenComponent && (
            <organizationConfig.FullTokenComponent percent={percent} />
          )}
        </TokenContainer>
        <InfoIconContainer>
          {hasWallets ? (
            <WalletWrapper
              className="link-base"
              as={Link}
              to={routes.DASHBOARD.WALLET.href}>
              <WalletIconWrapper>
                <WalletIcon />
              </WalletIconWrapper>
              <div>{translate('sdk.web.co2.widget.wallet')}</div>
            </WalletWrapper>
          ) : (
            <div />
          )}
          {hasHomeQrScanner ? (
            <ClimateIconScan />
          ) : organizationConfig.features.showContributionsToken ? (
            <Token>
              <TokenCount>{recoins}</TokenCount>
              <TokenImage
                src={organizationConfig.contributionsTokenImg}
                alt=""
              />
            </Token>
          ) : (
            <InfoLink to={routes.SETTINGS.ABOUT.href}>
              <InfoIcon src={InfoPanda} alt="" />
            </InfoLink>
          )}
        </InfoIconContainer>
      </WidgetCard>
    </>
  );
};

export default Co2Token;

import React, {useContext, useState} from 'react';
import styled from 'styled-components';

import {ActivityContext} from 'contexts/ActivityContext';
import {ReviewContext} from 'contexts/ReviewContext';
import {SettingsContext} from 'contexts/SettingsContext';
import {sendMessageToNativeApp} from 'services/native-api';
import {useTranslations} from 'hooks/use-translations';

import PromptModal from 'components/PromptModal/PromptModal';
import CtaLink from 'components/CtaLink/CtaLink';

import promptImage from './images/prompt-image.svg';

const PrimaryTextLink = styled(CtaLink)`
  margin-top: 20px;
`;

const TextLink = styled(CtaLink)`
  margin-bottom: 0;
  color: ${(props) =>
    props.theme.components.prompts?.textColor || props.theme.colors.black};
`;

const StoreReview = () => {
  const {translate} = useTranslations();
  const {isReviewOpen, setIsReviewOpen} = useContext(ReviewContext);
  const {appName} = useContext(SettingsContext);
  const {completeReview} = useContext(ActivityContext);
  const [showReview, setShowReview] = useState(isReviewOpen);

  const handleReviewConfirmation = () => {
    setShowReview(false);

    const message = {
      type: 'request_store_review',
    };
    sendMessageToNativeApp({message});
    setIsReviewOpen!(false);

    completeReview && completeReview();
  };

  const handleRemindLater = () => {
    setIsReviewOpen!(false);
  };

  const handleDontAskAgain = () => {
    setIsReviewOpen!(false);
    completeReview!();
  };

  return (
    <PromptModal
      isVisible={showReview}
      title={translate('sdk.web.storeReview.title')}
      textContent={translate('sdk.web.storeReview.text', {
        key: '{appName}',
        value: appName,
      })}
      image={promptImage}
      btnText={translate('sdk.web.storeReview.like')}
      onClose={handleRemindLater}
      onBtnClick={handleReviewConfirmation}
      extraContent={
        <>
          <PrimaryTextLink onClick={handleRemindLater}>
            {translate('sdk.web.storeReview.later')}
          </PrimaryTextLink>
          <TextLink onClick={handleDontAskAgain}>
            {translate('sdk.web.storeReview.dont.ask')}
          </TextLink>
        </>
      }
    />
  );
};

export default StoreReview;

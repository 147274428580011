import React, {useMemo} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import he from 'he';

import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useOrganization} from 'hooks/use-organization';

import InfoPage from 'components/InfoPage/InfoPage';
import HeaderText from 'components/Text/HeaderText/HeaderText';
import {NewsFeed} from 'types/dresden/NewsFeed';

const Wrapper = styled.div`
  z-index: 100000;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 100vh;
`;

const AboutContent = styled.div<{organization?: string}>`
  color: ${(props) => props.theme.colors.black};

  figcaption {
    text-align: center;
  }

  ${(props) => {
    if (props.organization === 'africagreentech') {
      return `
        h2 {
          line-height: 0.85;
          margin-bottom: 5px;
        }
      `;
    }
    return '';
  }}
  img {
    max-width: 100%;
    object-fit: contain;
    height: auto !important;
    margin: 10px 0;
  }
`;

const Title = styled(HeaderText)`
  display: block;
  font-family: ${(props) => props.theme.font.secondary.name};
  margin-bottom: 20px;
  text-align: left;
  font-weight: bold;
`;

type NewsFeedContentProps = {
  newsFeed: NewsFeed;
  onClose: () => void;
};

const NewsFeedContent = (props: NewsFeedContentProps) => {
  const {newsFeed, onClose} = props;
  useBodyBg({type: 'primary'});
  const {organizationConfig} = useOrganization();

  const aboutContent = useMemo(
    () => [
      <AboutContent organization={organizationConfig.name}>
        <Title>{newsFeed.title}</Title>
        <div
          className="default-styles"
          dangerouslySetInnerHTML={{
            __html: he.decode(newsFeed.content),
          }}
        />
      </AboutContent>,
    ],
    [organizationConfig, newsFeed],
  );

  return createPortal(
    <Wrapper>
      <InfoPage
        title={'News'}
        rows={aboutContent}
        isLoading={false}
        onClose={onClose}
      />
    </Wrapper>,
    document.body,
  );
};

export default NewsFeedContent;

import React from 'react';
import styled from 'styled-components';
import {InputStyles} from './InputStyles';
import {InputProps} from './Input';
import FormError from './FormError';

const TextAreaInput = styled(InputStyles)`
  resize: none;
  overflow: auto;
  max-height: 100px;
`;

const TextArea = (props: InputProps) => {
  const {name, formik, type, apiErrors, ...rest} = props;

  function handleTextAreaInput(e: any) {
    const element = e.target;
    element.style.height = '5px';
    element.style.height = element.scrollHeight + 'px';
  }

  return (
    <React.Fragment>
      {/*
  // @ts-ignore */}
      <TextAreaInput
        {...rest}
        as={'textarea'}
        rows={1}
        onInput={handleTextAreaInput}
        name={name}
        onBlur={formik.handleBlur}
        {...props}
      />
      <FormError name={name} formik={formik} apiErrors={apiErrors} />
    </React.Fragment>
  );
};

export default TextArea;

import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

const NEXT_PARAM_KEY = 'next';

interface UseAddNextProps {
  url: string;
  next?: string;
  params?: Record<string, string>;
}

export const useAddNext = ({url, next, params}: UseAddNextProps) =>
  useMemo(() => {
    if (!next) {
      return url;
    }

    const queryIndex = url.indexOf('?');
    const pathname = queryIndex === -1 ? url : url.substring(0, queryIndex);
    const search = queryIndex === -1 ? '?' : url.substring(queryIndex);
    const searchParams = new URLSearchParams(search);

    searchParams.set(NEXT_PARAM_KEY, next);
    if (params) {
      Object.keys(params).forEach((key) => {
        searchParams.set(key, params[key]);
      });
    }

    return decodeURIComponent(`${pathname}?${searchParams.toString()}`);
  }, [url, next, params]);

type UseGetNextResponse = {
  next: string;
};

export const useGetNext = (): UseGetNextResponse => {
  const location = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      next: searchParams.get(NEXT_PARAM_KEY) || '',
    };
  }, [location]);
};

import React, {HTMLAttributes, PropsWithChildren, useMemo} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import get from 'lodash/get';

import {useUserProfile} from 'hooks/use-user-profile';
import {useBoolean} from 'hooks/utils/use-boolean';
import {addGlobalSquareCorners} from 'utils/theme';

import HeaderTextLg from 'components/Text/HeaderTextLg/HeaderTextLg';
import AboutContent from './AboutContent';

import {ReactComponent as InfoIcon} from 'icons/info-white.svg';

const Wrapper = styled(HeaderTextLg)`
  display: block;
  position: relative;
  color: white;
  text-align: center;
  font-family: ${(props) => props.theme.font.secondary.name};
  padding: 15px 2px;
  width: 100%;
  background-color: ${(props) =>
    props.theme.components.widgetCard.titleBgColor};
  border-radius: 10px;

  ${(props) => {
    if (props.theme.components.widgetCard.titlePosition === 'top') {
      return `
        margin: -30px -25px 0;
        padding: 18px 2px;
        width: auto;
        border-radius: 0;
      `;
    }
    return '';
  }}

  ${addGlobalSquareCorners()}
`;

const InfoWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 12.5px;
  z-index: 1;
  width: 23px;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
`;

interface WidgetTitleProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  aboutInfo?: {
    title: string;
    link?: string;
    userProfilePath?: string;
    enabled: boolean;
  };
}

const WidgetTitle = (props: WidgetTitleProps) => {
  const {aboutInfo, children, ...rest} = props;
  const history = useHistory();
  const {userProfile} = useUserProfile();
  const [isAboutOpen, showAbout, closeAbout] = useBoolean(false);

  const content = useMemo(() => {
    if (!aboutInfo?.enabled) {
      return;
    }

    if (
      aboutInfo.userProfilePath &&
      get(userProfile, `${aboutInfo.userProfilePath}_enabled`)
    ) {
      return get(userProfile, aboutInfo.userProfilePath);
    }
  }, [userProfile, aboutInfo]);

  return (
    <>
      <Wrapper {...rest}>
        {children}
        {aboutInfo?.enabled && (content || aboutInfo.link) && (
          <InfoWrapper
            onClick={() =>
              aboutInfo?.link ? history.push(aboutInfo.link) : showAbout()
            }>
            <InfoIcon width="100%" />
          </InfoWrapper>
        )}
      </Wrapper>
      {aboutInfo && content && isAboutOpen && (
        <AboutContent
          title={aboutInfo.title}
          content={content}
          onClose={closeAbout}
        />
      )}
    </>
  );
};

export default WidgetTitle;

import React from 'react';
import styled from 'styled-components';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {formatNumber} from 'utils/numbers';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import walking from './images/walking.svg';

const Text = styled(CopyTextSm)`
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 5px;
  font-size: ${(props) => props.theme.components.mobility?.fontSize}px;
`;

const IconWrapper = styled.div<{sm?: boolean}>`
  padding: ${(props) => (props.sm ? '5.5px' : '10px')};
  width: 100%;
  height: 100%;
`;

const IconPadding = styled.div<{bgColor?: string}>`
  height: 100%;
  border-radius: 100%;
  display: block;
  background-color: ${(props) => props.bgColor};
  overflow: hidden;
`;

const Icon = styled.img`
  width: 100%;
`;

const Mobility = (props: any) => {
  const {
    bgColor = '#29abe2',
    pathColor = '#fff',
    img = walking,
    percent = 0,
    hidePercent,
    sm,
  } = props;

  return (
    <div>
      {!hidePercent && <Text>{formatNumber({number: percent})}%</Text>}
      <CircularProgressbarWithChildren
        value={percent}
        strokeWidth={sm ? 3 : 4}
        styles={buildStyles({
          pathColor,
          trailColor: 'transparent',
          strokeLinecap: 'round',
        })}>
        <IconWrapper sm={sm}>
          <IconPadding bgColor={bgColor}>
            <Icon src={img} alt="" />
          </IconPadding>
        </IconWrapper>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default Mobility;

import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';
import routes from 'config/routes';
import InfoPage from 'components/InfoPage/InfoPage';

const AboutContent = styled.div`
  figcaption {
    text-align: center;
  }
`;

const AboutInviteFriendsPage = (props: any) => {
  const {organizationConfig} = useOrganization();
  const {translate} = useTranslations();

  const aboutContent = [
    <AboutContent>
      <div
        dangerouslySetInnerHTML={{
          __html: translate(
            organizationConfig.translations?.inviteFriends?.info ||
              'sdk.web.settings.inviteFriends.info',
          ),
        }}
      />
    </AboutContent>,
  ];

  const onClose = () => {
    if (props.history.length > 2) {
      props.history.goBack();
    } else {
      props.history.push(routes.SETTINGS.href);
    }
  };

  return (
    <InfoPage
      title={translate('sdk.web.settings.inviteFriends.title')}
      rows={aboutContent}
      isLoading={false}
      onClose={onClose}
    />
  );
};

export default withRouter(AboutInviteFriendsPage);

import {useCallback, useContext} from 'react';
import {AuthContext, AuthObject} from 'contexts/AuthContext';
import {UserProfile} from 'services/user';
import {AccountType} from "types/User";

type UserProfileData = {
  isGuest: boolean;
  userProfile: UserProfile;
  reloadUserProfile: () => Promise<void>;
  updateUserData: AuthObject['updateUserData'];
};

export const useUserProfile = (): UserProfileData => {
  const {loadUserProfile, userProfile, updateUserData} = useContext(
    AuthContext,
  );

  const reloadUserProfile = useCallback(async () => {
    await loadUserProfile({reload: true});
  }, [loadUserProfile]);

  const isGuest = userProfile?.user.account_type === AccountType.GUEST;

  return {
    isGuest,
    reloadUserProfile,
    updateUserData,
    userProfile: userProfile!,
  };
};

const baseRoute = '/onboarding';

const routes = {
  href: baseRoute,
  TWO: {
    href: `${baseRoute}/two`,
  },
  THREE: {
    href: `${baseRoute}/three`,
  },
  FOUR: {
    href: `${baseRoute}/four`,
  },
  MOTION: {
    href: `${baseRoute}/motion`,
  },
  GPS: {
    href: `${baseRoute}/gps`,
  },
  NOTIFICATION: {
    href: `${baseRoute}/notification`,
  },
  TEAM: {
    href: `${baseRoute}/team`,
  },
  RETRY: {
    href: `${baseRoute}/retry`,
  },
};

export default routes;

import {fetchAPiClient} from './api';
import {Billing} from 'types/Billing';

const defaultApiClient = fetchAPiClient({subdomain: 'sst'});

interface SaveBilling {
  data: Billing;
}

export const getBillingDetails = async () => {
  const client = defaultApiClient;
  const url = '/billing/details';
  return client.get(url);
};

export const saveBilling = async ({data}: SaveBilling) => {
  const client = defaultApiClient;
  const url = '/billing/details';
  return client.post(url, data);
};

type GetStripeClientSecretResponse = {
  client_secret: string;
};

export const getStripeClientSecret = async (): Promise<
  GetStripeClientSecretResponse
> => {
  const client = defaultApiClient;
  const url = '/billing/payment-methods/initialize';
  return client.post(url, {});
};

export const getPaymentMethods = async () => {
  const client = defaultApiClient;
  const url = '/billing/payment-methods/card';
  return client.get(url);
};

export const deletePaymentMethod = async ({id}: {id: number}) => {
  const client = defaultApiClient;
  const url = `/billing/payment-methods/${id}`;
  return client.delete(url);
};

export const getCountries = async () => {
  const client = defaultApiClient;
  const url = '/billing/country';
  return client.get(url, {params: {limit: 500}});
};

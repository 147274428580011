import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import routes from 'config/routes';

interface UseNavigationProps {
  defaultRoute?: string;
  replaceLocation?: string;
}

const useNavigation = (props?: UseNavigationProps) => {
  const {defaultRoute, replaceLocation} = props || {};
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    if (history.length > 2) {
      history.goBack();

      if (replaceLocation) {
        setTimeout(() => {
          history.replace(replaceLocation);
        }, 5);
      }
    } else {
      history.push(defaultRoute || routes.DASHBOARD.href);
    }
  }, [history, defaultRoute, replaceLocation]);

  return {handleGoBack};
};

export default useNavigation;

import Co2Thaler from 'components/ClimateWidgets/Co2Token/DresdenCoin/DresdenCoin';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/DresdenCoin/images/coins/full.svg';

import Navigation from 'components/Navigation/dresden/Navigation';

import onboardingOneImg from 'components/Onboarding/images/dresden/onboarding-1.svg';
import onboardingTwoImg from 'components/Onboarding/images/dresden/onboarding-2.svg';
import onboardingThreeImg from 'components/Onboarding/images/dresden/onboarding-3.svg';
import onboardingRedeemImg from 'components/Onboarding/images/dresden/onboarding-3.svg';
import onboardingGpsImg from 'components/Onboarding/images/dresden/onboarding-gps.svg';
import onboardingMotionImg from 'components/Onboarding/images/dresden/onboarding-motion.svg';
import onboardingNotificationsImg from 'components/Onboarding/images/dresden/onboarding-notifications.svg';
import onboardingTeamsImg from 'components/Onboarding/images/dresden/onboarding-teams.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/dresden/onboarding-privacy.svg';

import teamBannerImg from 'components/ClimateWidgets/Leaderboard/images/dresden/banner.svg';
import teamGoldImg from 'components/ClimateWidgets/Leaderboard/images/dresden/gold.svg';
import teamSilverImg from 'components/ClimateWidgets/Leaderboard/images/dresden/silver.svg';
import teamBronzeImg from 'components/ClimateWidgets/Leaderboard/images/dresden/bronze.svg';

import {images as treePlantingImages} from 'components/ClimateWidgets/TreePlanting/TreeProgress/dresden';
import treePlantingImage from 'components/ClimateWidgets/TreePlanting/images/dresden/tree.svg';
import treePlantingGoalImage from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/images/dresden/banner.svg';
import treePlantingNewTreeImage from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/images/dresden/banner.svg';
import treePlantingHalfwayImage from 'components/ClimateWidgets/TreePlanting/HalfwayTreePrompt/images/dresden/banner.svg';

import customActivitiesImg from 'components/ClimateWidgets/Mobility/images/dresden/custom-activities.svg';
import cyclingImg from 'components/ClimateWidgets/Mobility/images/dresden/cycling.svg';
import publicTransitImg from 'components/ClimateWidgets/Mobility/images/dresden/public-transit.svg';
import walkingImg from 'components/ClimateWidgets/Mobility/images/dresden/walking.svg';

import inviteFriendsBanner from 'components/ClimateWidgets/InviteFriends/images/dresden/banner.svg';
import inviteFriendsProgressBanner from 'components/ClimateWidgets/InviteFriends/images/dresden/banner.svg';
import inviteFriendsSuccessBanner from 'components/ClimateWidgets/InviteFriends/InviteFriendsSuccessPrompt/images/dresden/banner.svg';
import {ReactComponent as InviteFriendsInfoIcon} from 'components/ClimateWidgets/InviteFriends/icons/dresden/info.svg';
import {ReactComponent as Co2TokenInfoIcon} from 'components/ClimateWidgets/Co2Token/images/dresden/info.svg';
import {getDresdenNewsFeed} from 'services/dresden/news-feed';
import buttons from 'config/organizations/preset/buttons';

const config = {
  name: 'dresden',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Roboto',
          secondary: 'Boston Angel Bold',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Boston Angel Bold',
        },
      },
      de: {
        android: {
          primary: 'Roboto',
          secondary: 'Boston Angel Bold',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Boston Angel Bold',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#00505A',
      secondary: '#5AE6BE',
      'secondary-300': '#D2FDE9',
      light: '#ffffff',
      tertiary: '#00505A',
      darkBg: '#30454A',
      white: '#ffffff',
      black: '#00505A',
      gray: '#D8D9DA',
      disabled: '#dee2e6',
      navMenu: '#4bafdc',
      permissionAlertBg: '#cfd621',
      permissionPromptBg: '#cfd621',
      permissionPromptButtonBg: '#39b04a',
      promptBg: '#ffffff',
      promptOverlayBg: '#00000099',
      onboardingBg: '#D2FDE9',
      teamRankingHighlight: '#BEFFE6',
      teamRankingSavings: '#00505A',
      sunDown: '#FFAFAF',
      catSkillWhite: '#EAF5F6',
    },
    components: {
      dashboard: {
        bgColor: 'secondary',
      },
      competition: {
        bgColor: 'secondary',
      },
      nav: {
        bgColor: 'secondary',
        menuColor: 'white',
      },
      widgetCard: {
        bgColor: 'light',
        titleBgColor: 'primary',
        titleColor: 'white',
        textColor: 'primary',
        titlePosition: 'top',
      },
      offers: {
        navBgColor: 'primary',
        bodyBgColor: 'secondary-300',
        activeTabBgColor: 'primary',
        recoinsLeftBgColor: 'light',
        recoinsRequiredBgColor: 'light',
        locationTypeBgColor: 'catSkillWhite',
      },
      climatePartnerMap: {
        bgColor: 'secondary-300',
      },
      badges: {},
      treePlanting: {
        bgColor: 'white',
        progressImages: treePlantingImages,
        treeImage: treePlantingImage,
        goalBanner: treePlantingGoalImage,
        newTreeBanner: treePlantingNewTreeImage,
        halfwayBanner: treePlantingHalfwayImage,
      },
      links: {},
      buttons: {
        ...buttons,
      },
      mobilityIcons: {
        progressPathColor: 'primary',
        walking: {
          bgColor: '#5AE6BE',
          img: walkingImg,
        },
        cycling: {
          bgColor: '#5AE6BE',
          img: cyclingImg,
        },
        publicTransit: {
          bgColor: '#5AE6BE',
          img: publicTransitImg,
        },
        customActivities: {
          bgColor: '#5AE6BE',
          img: customActivitiesImg,
        },
      },
      prompts: {
        bgColor: 'light',
        overlayBgColor: '#00000099',
        titleFontFamily: 'secondary',
      },
      customActivities: {
        detailsBgColor: 'light',
        listBgColor: 'secondary-300',
        listTitleFontFamily: 'primary',
        hasRoundedIcon: true,
        widgetStyle: 'activity',
      },
      settings: {
        itemCaretColor: 'primary',
        itemBorderColor: 'secondary',
      },
      co2Compensation: {
        pageBgColor: 'secondary-300',
        emptySunBgColor: 'secondary-300',
      },
      leaderboard: {
        bgColor: 'white',
        banner: teamBannerImg,
        goldImg: teamGoldImg,
        silverImg: teamSilverImg,
        bronzeImg: teamBronzeImg,
      },
      inviteFriends: {
        progressBarBgColor: 'secondary',
        bannerImg: inviteFriendsBanner,
        progressBanner: inviteFriendsProgressBanner,
        successBanner: inviteFriendsSuccessBanner,
        InfoIcon: InviteFriendsInfoIcon,
        hasCurvedInfo: true,
      },
      vouchers: {
        bgColor: 'white',
        recoinsTagBgColor: 'catSkillWhite',
        active: {
          activeBgColor: 'secondary',
          pendingBgColor: 'secondary',
          expiredBgColor: 'sunDown',
        },
      },
      news: {},
      form: {
        borderColor: 'primary',
      },
      detailsNavigation: {
        color: 'primary',
      },
      co2Token: {
        InfoIcon: Co2TokenInfoIcon,
        hasCurvedInfo: true,
      },
      onboarding: {
        titleFontFamily: 'secondary',
        images: {
          one: {
            image: onboardingOneImg,
          },
          two: {
            image: onboardingTwoImg,
          },
          three: {
            image: onboardingThreeImg,
          },
          privacy: {
            image: onboardingPrivacyImg,
          },
          gps: {
            image: onboardingGpsImg,
          },
          motion: {
            image: onboardingMotionImg,
          },
          teams: {
            image: onboardingTeamsImg,
          },
          notifications: {
            image: onboardingNotificationsImg,
          },
        },
      },
      teams: {
        banner: onboardingTeamsImg,
      },
      Navigation,
    },
    colors: {
      primary: '#00505A',
      body: '#5AE6BE',
      secondary: '#ffffff',
      white: '#ffffff',
      black: '#00505A',
      gray: '#D8D9DA',
      promptOverlayBg: '#00000099',
      onboardingBg: '#D2FDE9',
      teamRankingHighlight: '#BEFFE6',
    },
  },
  translations: {
    tokenTitle: 'sdk.web.dresden.contributions.your.token',
    co2Needed: 'sdk.web.dresden.contributions.savings.left.for.token',
    fullTokenText: 'sdk.web.dresden.contributions.full.token.text',
    offersTitle: 'sdk.web.dresden.offers.your.token',
    shortTokenSingular: 'sdk.web.dresden.offers.token.required.singular',
    shortTokenPlural: 'sdk.web.dresden.offers.token.required.plural',
    rewardType1Singular:
      'sdk.web.dresden.contributions.inviteFriends.rewardType1.singular',
    rewardType1Plural:
      'sdk.web.dresden.contributions.inviteFriends.rewardType1.plural',
    notEnoughTokens: 'sdk.web.dresden.offers.not.enough.tokens',
    aboutTokenLabel: 'sdk.web.dresden.settings.about.title',
    onBoarding: {
      one: {
        title: 'sdk.web.dresden.onboarding.one.title',
        content: 'sdk.web.dresden.onboarding.one.text',
      },
      two: {
        title: 'sdk.web.dresden.onboarding.two.title',
        content: 'sdk.web.dresden.onboarding.two.text',
        image: fullTokenImg,
      },
      three: {
        title: 'sdk.web.dresden.onboarding.three.title',
        content: 'sdk.web.dresden.onboarding.three.text',
        image: onboardingRedeemImg,
      },
      privacy: {
        title: 'sdk.web.onboarding.privacy.title',
        content: 'sdk.web.onboarding.privacy.text',
        confirmLink: 'sdk.web.onboarding.privacy.link',
        image: onboardingPrivacyImg,
      },
      gps: {
        title: 'sdk.web.onboarding.gps.title',
        content: 'sdk.web.onboarding.gps.text',
      },
    },
    competition: {
      title: 'sdk.web.teams.germany.leaderboard.title',
      subtitle: 'sdk.web.teams.germany.leaderboard.subtitle',
    },
    nav: {
      impact: 'sdk.web.dresden.nav.your.impact',
      offers: 'sdk.web.dresden.nav.offers',
      teams: 'sdk.web.dresden.nav.top.teams',
      news: 'sdk.web.dresden.nav.news',
      more: 'sdk.web.dresden.nav.more',
    },
    offers: {
      mapTitle: 'sdk.web.dresden.offers.map.title',
    },
    inviteFriends: {
      info: 'sdk.web.dresden.settings.inviteFriends.info',
    },
  },
  services: {
    getNewsFeed: getDresdenNewsFeed,
  },
  features: {
    hideAppClose: true,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCo2Compensation: false,
    hasCustomActivities: true,
    hasBadges: true,
  },
  applangaTag: '60e6c87728410130f9627416',
  matomoTrackingId: 3,
  tokenType: 'Thaler',
  fullTokenImg,
  FullTokenComponent: Co2Thaler,
};

export default config;

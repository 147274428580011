import {fetchAPiClient} from './api';
import {TRANSACTION_TYPES} from 'constants/transaction-type';
import {CarType, MobilitySettings, UserCarRating} from 'types/Mobility';

const activityApiClient = fetchAPiClient({subdomain: 'activity'});
const defaultClient = fetchAPiClient({subdomain: 'sst'});

export const getFuelTypes = async () => {
  const client = activityApiClient;
  const url = '/fuel-type';
  return client.get(url);
};

export const getMobilitySettings = async (): Promise<
  MobilitySettings | undefined
> => {
  const client = activityApiClient;
  const url = '/mobility-setting';
  const response = (await client.get(url)) as Array<MobilitySettings>;
  return response?.[0];
};

export interface MobilitySettingsData {
  pooling_enabled?: boolean;
  fuel_type_id?: number | string | null;
  fuel_consumption?: number | string | null;
  pooling_quantity?: number | string;
  pooling_percentage?: number | string;
}

export const updateMobilitySettings = async (
  data: MobilitySettingsData,
): Promise<MobilitySettings> => {
  const client = activityApiClient;
  const url = '/mobility-setting';
  return client.post(url, {
    activity_type_id: TRANSACTION_TYPES.CAR.transactionTypes[0],
    ...data,
  });
};

export interface UserCarRatingData {
  mobility_car_type_id?: number | string | null;
  range_rating?: number | string | null;
  speed_rating?: number;
  nps_rating?: number;
  comments?: string;
}

export const saveCarRating = async (
  data: UserCarRatingData,
): Promise<UserCarRating> => {
  const url = '/mobility/car-rating';
  return defaultClient.post(url, data);
};

export const getUserCarRating = async (): Promise<UserCarRating | null> => {
  const client = defaultClient;
  const url = '/mobility/car-rating';
  const response = await client.get(url);

  if (
    typeof response.data === 'string' &&
    // @ts-ignore
    Object.keys(response) === 0
  ) {
    return null;
  }

  return response as unknown as UserCarRating;
};

type GetCarTypesParams = {
  search?: string;
  include?: number;
};

export const getCarTypes = async (
  params: GetCarTypesParams = {},
): Promise<Array<CarType>> => {
  const client = defaultClient;
  const url = '/mobility/car-type';

  const {data} = await client.get(url, {
    params: {page: 1, limit: 500, ...params},
  });
  return data;
};

import React, {useCallback} from 'react';
import styled from 'styled-components';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useOrganization} from 'hooks/use-organization';
import useActivitySummary from 'hooks/useActivitySummary';
import {
  AppLeaderBoardInterface,
  getAllTeamTypes,
  getGroupLeaderboards,
  getLeaderBoards,
  getUserRankings,
} from 'services/teams';
import {LeaderBoard} from 'types/Team';

import PullToRefresh from 'components/PullToRefresh';
import GroupLeaderBoard from 'components/ClimateWidgets/Leaderboard/GroupLeaderBoard';
import InviteFriends from 'components/ClimateWidgets/InviteFriends/InviteFriends';
import TeamLeaderboard from 'components/ClimateWidgets/Leaderboard/TeamLeaderboard';
import UserLeaderboard from 'components/ClimateWidgets/Leaderboard/UserLeaderBoard';

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${(props) =>
    props.theme.components.competition.bgColor ||
    props.theme.colors.page ||
    props.theme.colors.body ||
    props.theme.colors.primary};
  padding: 10px;
`;

const CompetitionPage = () => {
  const {
    activitySummary,
  }: {
    activitySummary: any;
  } = useActivitySummary();
  const {hasUserRanking} = useOrganization();

  const {
    data: leaderboards,
    isLoading: isTeamsLoading,
    fetchData: fetchTeamData,
  } = useFetcher({
    fetcher: getLeaderBoards,
    params: {},
    initialValue: [],
    key: CACHE_KEYS.LEADERBOARD,
  });

  const {
    data: groups,
    isLoading: isGroupLeaderboardsLoading,
    fetchData: fetchGroupLeaderboardsData,
  } = useFetcher({
    fetcher: getGroupLeaderboards,
    params: {},
    initialValue: [],
    key: 'groupLeaderboards',
  });

  const {data: teamTypes, fetchData: fetchTeamTypes} = useFetcher({
    key: CACHE_KEYS.TEAM_TYPES,
    fetcher: getAllTeamTypes,
    initialValue: [],
  });

  const {data: userRankings, fetchData: fetchUserRankings} = useFetcher({
    key: CACHE_KEYS.USER_RANKINGS,
    fetcher: getUserRankings,
    initialValue: {},
    preventFetch: !hasUserRanking,
  });

  const handleRefresh = useCallback(() => {
    return Promise.all([
      fetchTeamData(),
      fetchGroupLeaderboardsData(),
      fetchTeamTypes(),
      fetchUserRankings(),
    ]);
  }, [
    fetchTeamData,
    fetchGroupLeaderboardsData,
    fetchTeamTypes,
    fetchUserRankings,
  ]);

  const handleTeamChange = useCallback(() => {
    fetchTeamData();
    hasUserRanking && fetchUserRankings();
  }, [hasUserRanking, fetchTeamData, fetchUserRankings]);

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <Wrapper>
        {leaderboards.map((leaderboard: AppLeaderBoardInterface, index = 0) => (
          <TeamLeaderboard
            key={leaderboard.id}
            teamTypeId={leaderboard.id}
            leaderboard={leaderboard}
            teamTypes={teamTypes}
            isLoading={isTeamsLoading}
            onTeamChange={handleTeamChange}
            showTeamName={leaderboards.length > 1}
            index={index}
          />
        ))}
        {hasUserRanking && (
          <UserLeaderboard
            userRankings={userRankings}
            isLoading={isTeamsLoading}
          />
        )}
        <InviteFriends activitySummary={activitySummary} />
        {groups.map((group: LeaderBoard) => (
          <GroupLeaderBoard
            key={group.name}
            group={group}
            isLoading={isGroupLeaderboardsLoading}
          />
        ))}
      </Wrapper>
    </PullToRefresh>
  );
};

export default CompetitionPage;

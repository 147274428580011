import React, {useState} from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {PurchasedVoucher} from 'types/Offers';
import {addBoldText} from 'utils/theme';

import Loader from 'components/Loader/Loader';
import TextXs from 'components/Text/TextXs/TextXs';
import CopyText from 'components/Text/CopyText/CopyText';
import VoucherCard from '../VoucherCard/VoucherCard';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import CtaLink from 'components/CtaLink/CtaLink';

const TitleBlock = styled(TextXs)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Title = styled(CopyTextSm)`
  display: block;
`;

const RemoveVouchersCta = styled(CtaLink)`
  display: block;
  cursor: pointer;
  margin-bottom: 0;
`;

const List = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Empty = styled(CopyText)`
  display: block;
  text-align: center;
  padding: 10% 2%;
`;

interface VouchersListProps {
  title: string | JSX.Element;
  emptyText?: string;
  voucherUrl: string;
  vouchers: Array<PurchasedVoucher>;
  isLoading: boolean;
  showClimateIcon?: boolean;
  reloadVouchers: () => void;
  removeVoucher?: {
    text: string;
    title: string;
    desc: string;
    delete: ({id}: {id: number}) => void;
  };
  header?: JSX.Element;
}

const VouchersList = (props: VouchersListProps) => {
  const {translate} = useTranslations();
  const {
    title,
    emptyText,
    voucherUrl,
    vouchers,
    isLoading,
    showClimateIcon,
    reloadVouchers,
    removeVoucher,
    header,
  } = props;
  const [isDeleteActive, setIsDeleteActive] = useState(false);

  const toggleDeleteActions = () => {
    setIsDeleteActive(!isDeleteActive);
  };

  return (
    <>
      <TitleBlock>
        <Title>{title}</Title>
        {removeVoucher && (
          <RemoveVouchersCta onClick={toggleDeleteActions}>
            {isDeleteActive
              ? translate('sdk.web.vouchers.remove.done')
              : removeVoucher.text}
          </RemoveVouchersCta>
        )}
      </TitleBlock>
      {!!header && header}
      {vouchers.length ? (
        <List>
          {vouchers.map((voucher: PurchasedVoucher) => (
            <VoucherCard
              key={voucher.id}
              voucher={voucher}
              voucherUrl={voucherUrl}
              isDeleteActive={isDeleteActive}
              showClimateIcon={showClimateIcon}
              toggleDeleteActions={toggleDeleteActions}
              reloadVouchers={reloadVouchers}
              removeVoucher={removeVoucher}
            />
          ))}
        </List>
      ) : (
        <>{!isLoading && emptyText && <Empty>{emptyText}</Empty>}</>
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default VouchersList;

import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import routes from 'config/routes';
import {NATIVE_MESSAGES} from 'constants/native-events';
import {sendMessageToNativeApp} from 'services/native-api';
import {useTranslations} from 'hooks/use-translations';
import {SettingsContext} from 'contexts/SettingsContext';

import Onboarding from 'components/Onboarding/Onboarding';
import Button from 'components/Button/Button';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import OnboardLink from 'components/Onboarding/OnboardLink';
import onboardingImg from 'components/Onboarding/images/onboarding-notification.svg';

const ButtonContainer = styled.div`
  display: block;
  margin-bottom: 20px;
`;

const MoreText = styled(CopyTextSm)`
  padding: 0 10px;
`;

const OnboardingNotification = (props: any) => {
  const {teams} = props;
  const {setIsNotificationEnabled, organizationConfig} = useContext(
    SettingsContext,
  );
  const {translate} = useTranslations();

  const title = translate('sdk.web.onboarding.notification.title');
  const content = translate('sdk.web.onboarding.notification.text');

  const nextRoute = teams.length
    ? routes.ONBOARDING.TEAM.href
    : routes.DASHBOARD.href;

  const onBtnClick = (e: any) => {
    const message = {
      type: NATIVE_MESSAGES.UPDATE_NOTIFICATION,
      enabled: true,
    };
    setIsNotificationEnabled(true);
    sendMessageToNativeApp({message});
    props.history.push(nextRoute);
  };

  return (
    <Onboarding
      title={title}
      content={content}
      onboardingImg={
        organizationConfig.theme.components.onboarding?.images?.notifications
          ?.image || onboardingImg
      }>
      <ButtonContainer>
        <Button onClick={onBtnClick}>
          {translate('sdk.web.onboarding.notification.confirm')}
        </Button>
      </ButtonContainer>
      <OnboardLink to={nextRoute}>
        {translate('sdk.web.onboarding.notification.skip.link')}
      </OnboardLink>
      <MoreText>{translate('sdk.web.onboarding.notification.more')}</MoreText>
    </Onboarding>
  );
};

export default withRouter(OnboardingNotification);

import React, {useEffect, useMemo, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import config from 'config/stripe';
import styled from 'styled-components';

import {getStripeClientSecret} from 'services/billing';
import {useOrganization} from 'hooks/use-organization';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useToast} from 'hooks/use-toast';
import {useTranslations} from 'hooks/use-translations';
import {addBoldText} from 'utils/theme';

import Button from 'components/Button/Button';
import CopyText from 'components/Text/CopyText/CopyText';
import Loader from 'components/Loader/Loader';
import {ReactComponent as CloseIcon} from 'icons/close.svg';

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 10;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.2;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  width: 90%;
  min-height: 30%;
  padding: 35px 20px 30px;
  border-radius: 8px;
`;

const Icon = styled.a`
  width: 20px;
  position: absolute;
  color: #000;
  right: 10px;
  top: 10px;
`;

const Title = styled(CopyText)`
  display: block;
  margin-bottom: 40px;
  text-align: center;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const CardWrapper = styled.div<{isActive: boolean}>`
  padding: 5px;
  border-bottom: ${(props) => (props.isActive ? '1px solid #ced4da' : 'none')};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const ButtonWrapper = styled.div`
  padding-top: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PrimaryButton = styled(Button)`
  min-width: 120px;
  padding: 5px 30px;
  border-radius: 25px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 10%;
`;

type PaymentFormProps = {
  onClose: (isSuccessful?: boolean) => void;
};

const CheckoutForm = ({onClose}: PaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const {organizationConfig} = useOrganization();
  const {translate} = useTranslations();
  const toast = useToast();
  const [isLoading, showLoader] = useBoolean(false);
  const [isElementsLoaded, loadElements] = useBoolean(false);
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean(false);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const loadClientSecret = async () => {
      try {
        const {client_secret} = await getStripeClientSecret();
        setClientSecret(client_secret);
      } catch (e: any) {}
    };

    loadClientSecret();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const card = elements?.getElement(CardElement);

    if (!card || !stripe) {
      return;
    }

    startSubmitting();

    const resp = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card,
      },
    });

    stopSubmitting();

    if (resp.error) {
      toast.error(
        resp.error.message ||
          translate('sdk.web.compensation.payment.card.fallback.error'),
      );
      return;
    }

    showLoader();
    onClose(true);
  };

  return (
    <Wrapper>
      <Overlay />
      <Content>
        {!isLoading ? (
          <>
            <div>
              <Icon onClick={() => onClose()}>
                <CloseIcon />
              </Icon>
              <Title>
                {translate('sdk.web.compensation.payment.card.new.title')}
              </Title>
              <CardWrapper isActive={isElementsLoaded}>
                <CardElement
                  onReady={loadElements}
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontSize: '16px',
                        color: organizationConfig.theme.colors.black,
                        '::placeholder': {
                          color: '#929aab',
                        },
                      },
                    },
                  }}
                />
                {!isElementsLoaded && <Loader color={'#222'} sm={true} />}
              </CardWrapper>
            </div>
            <ButtonWrapper>
              <PrimaryButton
                type="button"
                disabled={!stripe || !clientSecret || !isElementsLoaded}
                isLoading={isSubmitting}
                onClick={handleSubmit}>
                {translate('sdk.web.compensation.payment.card.new.cta')}
              </PrimaryButton>
            </ButtonWrapper>
          </>
        ) : (
          <LoaderWrapper>
            <Loader color={'#222'} sm={true} />
          </LoaderWrapper>
        )}
      </Content>
    </Wrapper>
  );
};

const CardForm = (props: PaymentFormProps) => {
  const {organizationConfig} = useOrganization();
  const stripePromise = useMemo(
    () => loadStripe(organizationConfig?.stripePublicKey || config.publicKey),
    [organizationConfig],
  );

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
};

export default CardForm;

import React from 'react';
import styled from 'styled-components';

import RecoinsLeft from 'components/Rewards/Tags/RecoinsLeft';
import RecoinsRequired from 'components/Rewards/Tags/RecoinsRequired';

const RecoinsLeftWrapper = styled.div`
  position: absolute;
  top: 5%;
  left: 10px;
`;

const RecoinsRequiredWrapper = styled.div`
  position: absolute;
  bottom: 5%;
  right: 10px;
`;

interface RecoinsProps {
  recoins: number;
  offersCount?: number;
}

const Recoins = (props: RecoinsProps) => {
  const {recoins, offersCount} = props;

  return (
    <>
      <RecoinsLeftWrapper>
        <RecoinsLeft offersCount={offersCount} />
      </RecoinsLeftWrapper>
      <RecoinsRequiredWrapper>
        <RecoinsRequired recoins={recoins} />
      </RecoinsRequiredWrapper>
    </>
  );
};

export default Recoins;

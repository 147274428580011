import React from 'react';
import styled from 'styled-components';

import {PROMPTS} from 'constants/prompts';
import {useTranslations} from 'hooks/use-translations';
import {usePromptApi} from 'hooks/usePromptApi';

import PromptModal from 'components/PromptModal/PromptModal';

const Title = styled.div`
  padding: 0 20px;
`;

const NewMonthPrompt = (props: any) => {
  const {prompts} = props;
  const {translate} = useTranslations();
  const {prompt, isVisible, handleModalClose} = usePromptApi({
    prompts,
    type: PROMPTS.NEW_METERING_MONTH,
  });

  if (!prompt) {
    return <React.Fragment />;
  }

  return (
    <PromptModal
      isVisible={isVisible}
      title={<Title>{translate('sdk.web.meter.new.month.prompt.title')}</Title>}
      textContent={translate('sdk.web.meter.new.month.prompt.desc')}
      btnText={translate('sdk.web.dialog.box.ok')}
      onBtnClick={handleModalClose}
      onClose={handleModalClose}
    />
  );
};

export default NewMonthPrompt;

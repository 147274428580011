type Coordinate = {
  latitude: number;
  longitude: number;
};

export const calculateDistance = (
  pointA: Coordinate,
  pointB: Coordinate,
): number => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = degreeToRadian(pointB.latitude - pointA.latitude);
  const dLon = degreeToRadian(pointB.longitude - pointA.longitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreeToRadian(pointA.latitude)) *
      Math.cos(degreeToRadian(pointB.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c * 1000; // to meters
};

function degreeToRadian(degree: number): number {
  return degree * (Math.PI / 180);
}

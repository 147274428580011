import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';

import routes from 'config/routes/rewards';
import {CACHE_KEYS} from 'constants/cache-keys';
import {LocationContext} from 'contexts/LocationContext';
import {useFetcher} from 'hooks/use-fetcher';
import {useUserProfile} from 'hooks/use-user-profile';
import {useOrganization} from 'hooks/use-organization';
import {getActiveLotteries} from 'services/lots';
import {getActiveVouchers} from 'services/vouchers';
import {endsWithNumbers} from 'utils/strings';
import {OfferTypes} from 'types/Offers';

import Deals from 'components/Rewards/Deals/Deals';
import OfferPage from './OfferPage/OfferPage';
import VouchersTermsPage from './VouchersTermsPage';
import LotteryTermsPage from './LotteryTermsPage';

interface UpdateVoucherWishlist {
  id: number;
  wishlisted: boolean;
}

interface DealsPageProps {
  activeOffersFilter: Array<string>;
}

const DealsPage = (props: DealsPageProps) => {
  const {activeOffersFilter} = props;
  const {pathname} = useLocation();
  const {location} = useContext(LocationContext);
  const {voucherTypes} = useOrganization();
  const {userProfile} = useUserProfile();

  const [fetchOffers, setFetchOffers] = useState(false);
  const [filterInitialized, setFilterInitialized] = useState(false);
  const [dealItem, setDealItem] = useState();

  const {isLoading: isLotteryLoading, data: activeLotteries} = useFetcher({
    fetcher: getActiveLotteries,
    params: {},
    key: CACHE_KEYS.ACTIVE_LOTTERIES,
    preventFetch: !fetchOffers,
  });

  const {
    isLoading: isVoucherLoading,
    data: activeVouchers,
    updateDataById: updateDealById,
    fetchData: fetchMoreVouchers,
    pagination: vouchersPagination,
  } = useFetcher({
    fetcher: getActiveVouchers,
    params: {
      location,
      types: voucherTypes,
      business_types: activeOffersFilter.join(','),
    },
    key: CACHE_KEYS.ACTIVE_VOUCHERS,
    preventFetch: !fetchOffers,
    paginate: true,
    limit: 20,
  });

  useEffect(() => {
    if (
      pathname.includes(OfferTypes.LOTTERY) ||
      !endsWithNumbers({text: pathname})
    ) {
      setFetchOffers(true);
    }
  }, [pathname]);

  useEffect(
    () => {
      // Prevent fetching vouchers on first render
      if (!filterInitialized) {
        setFilterInitialized(true);
        return;
      }

      fetchMoreVouchers(null, {resetPage: true});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeOffersFilter],
  );

  const updateVoucherWishlist = useCallback(
    ({id, wishlisted}: UpdateVoucherWishlist) => {
      updateDealById({
        idKey: 'id',
        id,
        updates: {
          wishlisted,
        },
      });
    },
    [updateDealById],
  );

  const handleRefresh = useCallback(() => {
    return fetchMoreVouchers(null, {resetPage: true});
  }, [fetchMoreVouchers]);

  const userRecoins = userProfile?.user?.recoins || 0;

  return (
    <Switch>
      <Route path={`${routes.REWARDS.DEALS.VOUCHER_TERMS.href}/:id`}>
        <VouchersTermsPage
          vouchers={activeVouchers}
          isLoading={isVoucherLoading}
        />
      </Route>
      <Route path={`${routes.REWARDS.DEALS.LOTTERY_TERMS.href}/:id`}>
        <LotteryTermsPage />
      </Route>
      <Route path={`${routes.REWARDS.DEALS.href}/:type/:id`}>
        <OfferPage
          lotteries={activeLotteries}
          vouchers={activeVouchers}
          isLoading={isLotteryLoading || isVoucherLoading}
          userRecoins={userRecoins}
          updateVoucherWishlist={updateVoucherWishlist}
        />
      </Route>
      <Route>
        <Deals
          lotteries={activeLotteries}
          vouchers={activeVouchers}
          isLotteryLoading={isLotteryLoading}
          isVoucherLoading={isVoucherLoading}
          onRefresh={handleRefresh}
          userRecoins={userRecoins}
          updateVoucherWishlist={updateVoucherWishlist}
          fetchMoreVouchers={fetchMoreVouchers}
          vouchersPagination={vouchersPagination}
          currentItem={dealItem}
          onSelectItem={(item: any) => setDealItem(item)}
        />
      </Route>
    </Switch>
  );
};

export default DealsPage;

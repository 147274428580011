import JTCoin from 'components/ClimateWidgets/Co2Token/JTCoin/JTCoin';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/JTCoin/images/coins/full.svg';
import onboardingRedeemImg from 'components/Onboarding/images/jt/onboarding-redeem.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/jt/onboarding-privacy.svg';
import buttons from 'config/organizations/preset/buttons';

const config = {
  name: 'jt',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Roboto',
          secondary: 'Adobe Sketchnote',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Adobe Sketchnote',
        },
      },
      de: {
        android: {
          primary: 'Roboto',
          secondary: 'Adobe Sketchnote',
        },
        ios: {
          primary: 'Helvetica Neue',
          secondary: 'Adobe Sketchnote',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#008C8C',
      secondary: '#BCEDED',
      tertiary: '#33A3A3',
      darkBg: '#30454A',
      white: '#ffffff',
      black: '#30454A',
      gray: '#D8D9DA',
      disabled: '#dee2e6',
      navMenu: '#ffffff66',
      promptBg: '#BCEDED',
      promptOverlayBg: '#00000099',
      onboardingBg: '#BCEDED',
      teamRankingHighlight: '#FFF3D7',
      teamRankingSavings: '#016817',
    },
    components: {
      dashboard: {
        bgColor: 'primary',
      },
      competition: {
        bgColor: 'primary',
      },
      nav: {
        bgColor: 'primary',
        menuColor: 'white',
        routes: ['impact', 'offers', 'more'],
      },
      widgetCard: {
        titleBgColor: 'darkBg',
        bgColor: 'secondary',
      },
      offers: {
        activeTabBgColor: 'darkBg',
      },
      badges: {},
      treePlanting: {},
      links: {},
      buttons: {
        ...buttons,
        primary: {
          bgColor: 'darkBg',
          disabledBgColor: 'disabled',
          color: 'white',
        },
      },
      mobilityIcons: {
        walking: {
          bgColor: '#29a6dd',
        },
        cycling: {
          bgColor: '#15e5da',
        },
        publicTransit: {
          bgColor: '#93cf21',
        },
      },
      prompts: {},
      customActivities: {},
      settings: {},
      co2Compensation: {},
      leaderboard: {},
      inviteFriends: {},
      vouchers: {},
      news: {},
      summaryPage: {},
    },
    colors: {
      primary: '#008C8C',
      secondary: '#BCEDED',
      tertiary: '#33A3A3',
      darkBg: '#30454A',
      white: '#ffffff',
      black: '#30454A',
      gray: '#D8D9DA',
      disabled: '#dee2e6',
      navMenu: '#ffffff66',
      promptBg: '#BCEDED',
      promptOverlayBg: '#00000099',
      onboardingBg: '#BCEDED',
      teamRankingHighlight: '#FFF3D7',
      teamRankingSavings: '#016817',
    },
  },
  translations: {
    tokenTitle: 'sdk.web.jt.contributions.your.token',
    co2Needed: 'sdk.web.jt.contributions.savings.left.for.token',
    fullTokenText: 'sdk.web.jt.contributions.full.token.text',
    offersTitle: 'sdk.web.jt.offers.your.token',
    shortTokenSingular: 'sdk.web.jt.offers.token.required.singular',
    shortTokenPlural: 'sdk.web.jt.offers.token.required.plural',
    rewardType1Singular:
      'sdk.web.jt.contributions.inviteFriends.rewardType1.singular',
    rewardType1Plural:
      'sdk.web.jt.contributions.inviteFriends.rewardType1.plural',
    notEnoughTokens: 'sdk.web.jt.offers.not.enough.tokens',
    aboutTokenLabel: 'sdk.web.jt.settings.about.title',
    onBoarding: {
      one: {
        title: 'sdk.web.jt.onboarding.one.title',
        content: 'sdk.web.jt.onboarding.one.text',
      },
      two: {
        title: 'sdk.web.jt.onboarding.two.title',
        content: 'sdk.web.jt.onboarding.two.text',
        image: fullTokenImg,
      },
      three: {
        title: 'sdk.web.jt.onboarding.three.title',
        content: 'sdk.web.jt.onboarding.three.text',
        image: onboardingRedeemImg,
      },
      privacy: {
        title: 'sdk.web.onboarding.privacy.title',
        content: 'sdk.web.onboarding.privacy.text',
        confirmLink: 'sdk.web.onboarding.privacy.link',
        image: onboardingPrivacyImg,
      },
      gps: {
        title: 'sdk.web.onboarding.gps.title',
        content: 'sdk.web.onboarding.gps.text',
      },
    },
    competition: {
      title: 'sdk.web.teams.germany.leaderboard.title',
      subtitle: 'sdk.web.teams.germany.leaderboard.subtitle',
    },
  },
  features: {
    hideAppClose: true,
    hasCompetition: false,
    hideTerms: true,
  },
  applangaTag: '5faba222b0368772cc7cc3de',
  matomoTrackingId: 3,
  tokenType: 'Thaler',
  fullTokenImg,
  FullTokenComponent: JTCoin,
};

export default config;

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {useHistory} from 'react-router-dom';

import {defaultEmissions} from './config';
import routes from 'config/routes';
import {NEW_FEATURES} from 'constants/app-features';
import {CACHE_KEYS} from 'constants/cache-keys';
import {useTranslations} from 'hooks/use-translations';
import useNavigation from 'hooks/app/use-navigation';
import useNewFeatures from 'hooks/app/use-new-features';
import {useForm} from 'hooks/forms/use-form';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import {useFetcher} from 'hooks/use-fetcher';
import {useBoolean} from 'hooks/utils/use-boolean';
import useErrorMessage from 'hooks/forms/use-error-message';
import {
  getFuelTypes,
  getMobilitySettings,
  MobilitySettingsData,
  updateMobilitySettings,
} from 'services/mobility-settings';
import {FuelType, MobilitySettings} from 'types/Mobility';
import {getEmissionsPerGram, usesElectricity} from './utils';

import InfoPage from 'components/InfoPage/InfoPage';
import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import Dropdown from 'components/Form/Dropdown';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import FormLabel from 'components/Form/FormLabel';
import Header from './Header';

import VehicleFossilImage from './images/vehicle-fossil.svg';

const Wrapper = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const defaultConsumptionLimits: {
  min: number;
  max: number;
  step: number | string;
} = {
  min: 1,
  max: 100,
  step: 'any',
};

const VehicleSettings = () => {
  const history = useHistory();
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({defaultRoute: routes.SETTINGS.href});
  const {
    markFeatureAsSeen,
    removeSeenFeature,
    markFeatureAsVisible,
    removeVisibleFeature,
  } = useNewFeatures();

  const formRef = useRef<HTMLFormElement>(null);
  const {getKeyDownListener} = useForm();
  const {apiErrors, validate} = useFormValidation();
  const errorMessage = useErrorMessage({translate});
  const toast = useToast();
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();

  const [consumptionLimits, setConsumptionLimits] = useState(
    defaultConsumptionLimits,
  );

  const validationSchema = useMemo(() => {
    const {getRequiredError, getMinError, getMaxError} = errorMessage;

    return yup.object().shape({
      fuel_type_id: yup
        .number()
        .required(
          getRequiredError({
            label: 'sdk.web.settings.vehicle.fuel.type',
          }),
        )
        .nullable(),
      fuel_consumption: yup
        .number()
        .min(consumptionLimits.min, getMinError({}))
        .max(consumptionLimits.max, getMaxError({}))
        .required(getRequiredError({}))
        .nullable(),
    });
  }, [errorMessage, consumptionLimits]);

  const {data: mobilitySettings, addDataItem: saveMobilitySettingsToCache} =
    useFetcher<MobilitySettings>({
      fetcher: getMobilitySettings,
      key: CACHE_KEYS.MOBILITY_SETTINGS,
    });

  const initialValues = useMemo(() => {
    return mobilitySettings
      ? {
          fuel_type_id: mobilitySettings.fuel_type_id,
          fuel_consumption: mobilitySettings.fuel_consumption,
        }
      : {
          fuel_type_id: '',
          fuel_consumption: '',
        };
  }, [mobilitySettings]);

  const {data: fuelTypes} = useFetcher<Array<FuelType>>({
    fetcher: getFuelTypes,
    initialValue: [],
    key: CACHE_KEYS.FUEL_TYPES,
  });

  const fuelTypesOptions = useMemo(() => {
    return fuelTypes.map((fuelType) => ({
      label: fuelType.name,
      value: fuelType.id,
      id: fuelType.id,
    }));
  }, [fuelTypes]);

  const submitData = useCallback(
    async (
      data: Pick<MobilitySettingsData, 'fuel_type_id' | 'fuel_consumption'>,
    ) => {
      try {
        startSubmitting();
        const isFuelTypeChanged =
          mobilitySettings?.fuel_type_id !== data.fuel_type_id;

        const response = await updateMobilitySettings(data);
        saveMobilitySettingsToCache({data: response});

        toast.success(translate('sdk.web.settings.vehicle.success'));

        markFeatureAsSeen(NEW_FEATURES.VEHICLE_SETTINGS);
        if (usesElectricity(response.fuel_type)) {
          markFeatureAsVisible(NEW_FEATURES.E_CAR_RATING);

          if (isFuelTypeChanged) {
            removeSeenFeature(NEW_FEATURES.E_CAR_RATING);
          }
        } else {
          removeVisibleFeature(NEW_FEATURES.E_CAR_RATING);
        }

        if (isFuelTypeChanged && usesElectricity(response.fuel_type)) {
          history.push(routes.SETTINGS.E_CAR_RATING.href);
        }
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.settings.vehicle.error.fallback',
        );
        toast.error(message);
      } finally {
        stopSubmitting();
      }
    },
    [
      toast,
      mobilitySettings,
      history,
      translate,
      startSubmitting,
      saveMobilitySettingsToCache,
      stopSubmitting,
      markFeatureAsSeen,
      markFeatureAsVisible,
      removeSeenFeature,
      removeVisibleFeature,
    ],
  );

  const onSubmit = useCallback(
    async (data: typeof initialValues) => {
      submitData(data);
    },
    [submitData],
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validate,
  });

  useEffect(
    () => {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.fuel_type_id, fuelTypes],
  );

  const handleFuelTypeChange = useCallback(
    (event: any) => {
      formik.handleChange(event);
      const fuelType = fuelTypes.find(
        (fuelType) => fuelType.id === +event.target.value,
      );
      if (!fuelType) {
        setConsumptionLimits(defaultConsumptionLimits);
        return;
      }

      setConsumptionLimits({
        min: fuelType.min,
        max: fuelType.max,
        step: fuelType.step,
      });

      setTimeout(() => {
        formik.setFieldValue('fuel_consumption', fuelType.average);
      }, 200);
    },
    [fuelTypes, formik],
  );

  const fuelType = useMemo(
    () =>
      fuelTypes.find((fuelType) => fuelType.id === +formik.values.fuel_type_id),
    [formik.values.fuel_type_id, fuelTypes],
  );

  const emissions = useMemo(() => {
    if (
      !formik.values.fuel_consumption &&
      !formik.values.fuel_type_id &&
      mobilitySettings &&
      !mobilitySettings.fuel_consumption
    ) {
      return defaultEmissions.value;
    }

    if (
      !formik.values.fuel_consumption ||
      !formik.values.fuel_type_id ||
      !fuelType
    ) {
      return;
    }

    return getEmissionsPerGram({
      fuelType,
      consumption: +formik.values.fuel_consumption,
    });
  }, [
    formik.values.fuel_type_id,
    formik.values.fuel_consumption,
    fuelType,
    mobilitySettings,
  ]);

  const handleUseDefaultSettings = useCallback(async () => {
    formik.resetForm();
    await submitData({
      fuel_type_id: null,
      fuel_consumption: null,
    });
  }, [submitData, formik]);

  return (
    <InfoPage
      title={translate('sdk.web.settings.vehicle.title')}
      onClose={handleGoBack}>
      <Wrapper ref={formRef} onSubmit={formik.handleSubmit}>
        <div>
          <Header
            subtitle={translate('sdk.web.settings.vehicle.subtitle')}
            image={VehicleFossilImage}
            emissions={emissions}
          />
          <FormGroup>
            <FormLabel>
              {translate('sdk.web.settings.vehicle.fuel.type')}
            </FormLabel>
            <Dropdown
              name="fuel_type_id"
              optional={true}
              value={(formik.values.fuel_type_id as string) || ''}
              list={fuelTypesOptions}
              onChange={handleFuelTypeChange}
              formik={formik}
              apiErrors={apiErrors}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>
              {formik.values.fuel_type_id
                ? translate('sdk.web.settings.vehicle.consumption', {
                    key: '{unit}',
                    value: fuelType?.unit || defaultEmissions.unit,
                  })
                : translate('sdk.web.settings.vehicle.consumption.default')}
            </FormLabel>
            <Input
              placeholder=""
              name="fuel_consumption"
              type="number"
              step={consumptionLimits.step.toString()}
              formik={formik}
              value={(formik.values.fuel_consumption as string) || ''}
              onChange={formik.handleChange}
              onKeyDown={getKeyDownListener({form: formRef.current})}
              enterkeyhint={'go'}
              apiErrors={apiErrors}
            />
          </FormGroup>
        </div>
        <div className="text-center">
          <Button type="submit" className="mb-base" isLoading={isSubmitting}>
            {translate('sdk.web.settings.vehicle.cta.save')}
          </Button>
          <CtaLink noMargin onClick={handleUseDefaultSettings}>
            {translate('sdk.web.settings.vehicle.cta.average')}
          </CtaLink>
        </div>
      </Wrapper>
    </InfoPage>
  );
};

export default VehicleSettings;

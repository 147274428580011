export const CACHE_KEYS = {
  ACTIVITY_SUMMARY: 'activitySummary',
  ACTIVE_VOUCHERS: 'activeVouchers',
  ACTIVE_LOTTERIES: 'activeLotteries',
  BADGES: 'badges',
  BILLING_DETAILS: 'billingDetails',
  CAR_TYPES: 'carTypes',
  BAMBOO_COIN: 'bambooCoin',
  CLIMATE_PARTNERS: 'climatePartners',
  CLIMATE_PARTNER_OFFERS: 'climatePartnerOffers',
  COMPENSATION_PARTNERS: 'compensationPartners',
  COUNTRIES: 'countries',
  CUSTOM_ACTIVITIES: 'customActivities',
  CUSTOM_ACTIVITIES_CATEGORIES: 'customActivitiesCategories',
  DRESDEN_NEWS_FEED: 'dresdenNewsFeed',
  FUEL_TYPES: 'fuelTypes',
  LEADERBOARD: 'leaderBoard/v2',
  METERING: {
    ALL_STATS: 'metering/all-stats',
    water: {
      ENERGY_PROVIDERS: 'water/energyProviders',
      USER_METER: 'water/userMeter',
      PREVIOUS_RESULTS: 'water/previousResults',
    },
    electricity: {
      ENERGY_PROVIDERS: 'electricity/energyProviders',
      USER_METER: 'electricity/userMeter',
      PREVIOUS_RESULTS: 'electricity/previousResults',
    },
    gas: {
      ENERGY_PROVIDERS: 'gas/energyProviders',
      USER_METER: 'gas/userMeter',
      PREVIOUS_RESULTS: 'gas/previousResults',
    },
  },
  MOBILITY_SETTINGS: 'mobilitySettings',
  NEWS_FEED: 'newsFeed',
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_PREFERENCES: 'notificationPreferences',
  NOTIFICATION_STATS: 'notificationStats',
  OFFERS_BUSINESS_TYPES: 'offersBusinessTypes',
  PURCHASED_VOUCHERS: 'purchasedVouchers/v2',
  SOCIO_DEMO_DATA: 'socioDemoData',
  TEAM_TYPES: 'teamTypes',
  TEAM_TYPES_TEAMS: 'teamTypes/teams',
  USER_RANKINGS: 'userRankings',
  USED_VOUCHERS: 'usedVouchers',
  WALLETS: 'wallets',
  WALLET_TRANSACTIONS: 'walletTransactions',
  WATER_STATS: 'waterStats',
  WATER_SETTINGS: 'waterSettings',
  WATER_PREVIOUS_RESULTS: 'waterPreviousResults',
  WIDGETS_CUSTOMIZATION: 'widgetsCustomizations',
  WISHLISTED_VOUCHERS: 'wishlistedVouchers/v2',
  USER_COMPENSATIONS: 'userCompensations',
  USER_CAR_RATING: 'userCarRating',
  USER_PROFILE: 'userProfile',
};

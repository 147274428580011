import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {ELEMENT_SCREENS} from '../types';
import {ElementData, METERING_ELEMENTS, MeteringStats} from 'types/Metering';

import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import Consumption from './Consumption';
import Settings from './Settings';
import Summary from './Summary';
import PreviousResults from './PreviousResults';
import SettingsConfirmation from './SettingsConfirmation';
import NewMonth from './NewMonth';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const screens = [
  {
    key: ELEMENT_SCREENS.SETTINGS,
    Component: Settings,
  },
  {
    key: ELEMENT_SCREENS.SUMMARY,
    Component: Summary,
  },
  {
    key: ELEMENT_SCREENS.CONSUMPTION,
    Component: Consumption,
  },
  {
    key: ELEMENT_SCREENS.PREVIOUS_RESULTS,
    Component: PreviousResults,
  },
  {
    key: ELEMENT_SCREENS.SETTINGS_CONFIRMATION,
    Component: SettingsConfirmation,
  },
  {
    key: ELEMENT_SCREENS.NEW_MONTH,
    Component: NewMonth,
  },
];

const getBaseScreen = ({
  stats,
  elementData,
}: Pick<ElementProps, 'stats' | 'elementData'>) => {
  return stats?.connected
    ? elementData.showWarning
      ? ELEMENT_SCREENS.NEW_MONTH
      : ELEMENT_SCREENS.SUMMARY
    : ELEMENT_SCREENS.SETTINGS;
};

interface ElementProps {
  element: METERING_ELEMENTS;
  elementData: ElementData;
  onClose: () => void;
  onRefresh: () => void;
  stats?: MeteringStats;
}

const Element = (props: ElementProps) => {
  const {element, elementData, stats, onClose, onRefresh} = props;
  const [activeScreen, setActiveScreen] = useState<ELEMENT_SCREENS>(
    getBaseScreen({stats, elementData}),
  );

  const [isVisible, setIsVisible] = useState(false);
  const [closeKey, setCloseKey] = useState(0);

  useEffect(() => {
    // Set here so prompt wrapper bg changes can be picked up
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setActiveScreen(getBaseScreen({stats, elementData}));
      setCloseKey(0);
    }
  }, [isVisible, stats, elementData]);

  const closeWidget = useCallback(() => {
    setIsVisible(false);
    // Allow for little delay for prompt wrapper clean up
    setTimeout(onClose, 200);
  }, [onClose]);

  const handleClose = useCallback(() => {
    switch (activeScreen) {
      case ELEMENT_SCREENS.CONSUMPTION: {
        setCloseKey(Date.now());
        return;
      }
      case ELEMENT_SCREENS.PREVIOUS_RESULTS: {
        if (elementData.showWarning) {
          setActiveScreen(ELEMENT_SCREENS.NEW_MONTH);
        } else {
          setActiveScreen(ELEMENT_SCREENS.SUMMARY);
        }
        return;
      }
      default: {
        setIsVisible(false);
        closeWidget();
      }
    }
  }, [activeScreen, elementData, closeWidget]);

  const handleComplete = useCallback(
    (data?: {nextScreen: ELEMENT_SCREENS}) => {
      if (data?.nextScreen) {
        setActiveScreen(data.nextScreen);
      }

      switch (activeScreen) {
        case ELEMENT_SCREENS.SETTINGS: {
          onRefresh();
          if (!data?.nextScreen) {
            closeWidget();
          }

          return;
        }
        case ELEMENT_SCREENS.SETTINGS_CONFIRMATION: {
          setActiveScreen(ELEMENT_SCREENS.CONSUMPTION);
          return;
        }
        case ELEMENT_SCREENS.SUMMARY: {
          return;
        }
        case ELEMENT_SCREENS.CONSUMPTION: {
          onRefresh();
          if (!data?.nextScreen) {
            closeWidget();
          }
          return;
        }
        case ELEMENT_SCREENS.PREVIOUS_RESULTS: {
          closeWidget();
          return;
        }
      }
    },
    [activeScreen, closeWidget, onRefresh],
  );

  return (
    <PromptWrapper
      onClose={handleClose}
      isVisible={isVisible}
      styles={{
        pb: '0px',
      }}>
      <Wrapper>
        {screens.map((screen) => (
          <React.Fragment key={screen.key}>
            {activeScreen === screen.key && (
              <screen.Component
                element={element}
                elementData={elementData}
                stats={stats}
                closeKey={closeKey}
                onClose={handleClose}
                onComplete={handleComplete}
              />
            )}
          </React.Fragment>
        ))}
      </Wrapper>
    </PromptWrapper>
  );
};

export default Element;

import merge from 'lodash/merge';

import base from 'config/organizations/base';

const config = {
  name: 'payiq',
  theme: {
    fonts: {},
    colors: {},
    components: {
      nav: {
        routes: ['impact', 'offers', 'teams', 'more'],
      },
    },
  },
  translations: {},
  features: {
    hideAppClose: false,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCo2Compensation: false,
    hasCustomActivities: true,
    hasBadges: true,
    hasNewFAQ: true,
  },
  applangaTag: '60086398da59613269f44eaa',
};

export default merge({}, base, config);

import React, {useContext, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import routes from 'config/routes';
import {useScrollToTop} from 'hooks/ui/use-scroll-to-top';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {ReviewContext} from 'contexts/ReviewContext';
import {ActivityContext} from 'contexts/ActivityContext';
import {SettingsContext} from 'contexts/SettingsContext';
import LayoutContext from 'contexts/LayoutContext';

import Navigation from 'components/Navigation';
import StoreReview from 'components/StoreReview';
import CompetitionPage from './CompetitionPage/CompetitionPage';
import ContributionPage from './ContributionPage/ContributionPage';
import RewardsPage from './RewardsPage/RewardsPage';
import NewsFeedPage from 'pages/Dashboard/NewsFeed';
import NotificationsPage from 'pages/Dashboard/NotificationsPage';
import WalletsPage from 'pages/Dashboard/WalletsPage';

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 95px;
  background: ${(props) =>
    props.theme.components.dashboard?.bgColor ||
    props.theme.colors.page ||
    props.theme.colors.body ||
    props.theme.colors.primary};
`;

const NavigationWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 95px;
`;

const Body = styled.div`
  flex-grow: 1;
`;

const Dashboard = (props: any) => {
  const {history} = props;
  const wrapperRef = useRef(null);
  const {
    isPrivacyPolicyAccepted,
    organizationConfig,
    initialPage,
    isOnboardingComplete,
    setInitialPage,
    setIsOnboardingComplete,
  } = useContext(SettingsContext);
  const {isReviewOpen} = useContext(ReviewContext);
  const {isReviewComplete} = useContext(ActivityContext);
  const {isFullScreenModal} = useContext(LayoutContext);
  const {updateBg} = useBodyBg({type: 'primary'});

  useScrollToTop({wrapperRef});

  useEffect(
    () => {
      updateBg({type: 'primary'});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationConfig],
  );

  useEffect(() => {
    // @ts-ignore
    /*wrapperRef.current?.addEventListener(
      'touchmove',
      (e: any) => e.preventDefault(),
      {passive: false},
    );*/
  }, []);

  useEffect(() => {
    if (initialPage && isPrivacyPolicyAccepted) {
      setInitialPage('');
      if (initialPage === 'offers') {
        history.push(routes.DASHBOARD.REWARDS.href);
      }
    }
  }, [isPrivacyPolicyAccepted, initialPage, history, setInitialPage]);

  useEffect(() => {
    if (isPrivacyPolicyAccepted && !isOnboardingComplete) {
      setIsOnboardingComplete(true);
    }
  }, [isPrivacyPolicyAccepted, isOnboardingComplete, setIsOnboardingComplete]);

  if (!isPrivacyPolicyAccepted) {
    history.push(routes.ONBOARDING.href);
    return <div />;
  }

  return (
    <>
      <Wrapper ref={wrapperRef}>
        {!isFullScreenModal && (
          <NavigationWrapper>
            {organizationConfig.theme.components.Navigation ? (
              <organizationConfig.theme.components.Navigation />
            ) : (
              <Navigation
                hideCloseBtn={organizationConfig.features.hideAppClose}
              />
            )}
          </NavigationWrapper>
        )}
        <Body>
          <Switch>
            <Route path={routes.DASHBOARD.CONTRIBUTIONS.href}>
              <ContributionPage />
            </Route>
            <Route path={routes.DASHBOARD.REWARDS.href}>
              <RewardsPage />
            </Route>
            <Route path={routes.DASHBOARD.COMPETITION.href}>
              <CompetitionPage />
            </Route>
            <Route path={routes.DASHBOARD.NEWS_FEED.href}>
              <NewsFeedPage />
            </Route>
            <Route path={routes.DASHBOARD.NOTIFICATIONS.href}>
              <NotificationsPage />
            </Route>
            <Route path={routes.DASHBOARD.WALLET.href}>
              <WalletsPage />
            </Route>
            <Route path="/">
              <Redirect to={routes.DASHBOARD.CONTRIBUTIONS.href} />
            </Route>
          </Switch>
        </Body>
      </Wrapper>
      {!isReviewComplete && isReviewOpen && <StoreReview />}
    </>
  );
};

export default withRouter(Dashboard);

import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {useUserProfile} from 'hooks/use-user-profile';
import {AccountType} from "types/User";

const useAuthCheck = () => {
  const history = useHistory();
  const {userProfile} = useUserProfile();
  const {goBack} = history;

  useEffect(
    () => {
      if (userProfile?.user?.account_type !== AccountType.GUEST) {
        goBack();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useAuthCheck;

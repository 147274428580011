import React, {useContext} from 'react';
import styled from 'styled-components';

import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {SettingsContext} from 'contexts/SettingsContext';

import PromptWrapper, {
  Title,
} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import CopyText from 'components/Text/CopyText/CopyText';
import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import PromptModal from 'components/PromptModal/PromptModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;

  .qr-wallet-modal {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img-wrapper {
      border: 5px solid #868164;
      background-color: #c5bf93;
      padding: 20px;
      border-radius: 100%;
      width: 130px;
      margin-bottom: 30px;

      img {
        width: 100%;
      }
    }
  }
`;

interface WalletModalProps {
  qrCode?: string;
  onClose: () => any;
}

const WalletModal = (props: WalletModalProps) => {
  const {qrCode, onClose} = props;
  const {appName} = useContext(SettingsContext);
  const {translate} = useTranslations();
  const {getTokenText} = useTokenTranslation();
  // const [isSuccessOpen, openSuccess, closeSuccess] = useBoolean(true);
  const [isHelpOpen, openHelp, closeHelp] = useBoolean(false);

  return (
    <>
      <PromptWrapper isVisible={true} onClose={onClose}>
        <Wrapper className="qr-wallet-modal">
          <div className="qr-wallet-modal__content">
            <Title className="qr-wallet-modal__title">
              {translate('sdk.web.qr.attach.wallet.success')}
            </Title>
            <div className="qr-wallet-modal__img-wrapper">
              <img src={qrCode} alt="QR code" />
            </div>
            <CopyText
              as="div"
              className="mb-base"
              dangerouslySetInnerHTML={{
                __html: translate('sdk.web.qr.attach.wallet.success.text', [
                  {
                    key: '{tokens}',
                    value: getTokenText(2),
                  },
                  {
                    key: '{appName}',
                    value: appName,
                  },
                ]),
              }}
            />
            <CopyText as="div" className="mb-base"></CopyText>
          </div>
          <div>
            <Button className="mb-base" onClick={onClose}>
              {translate('sdk.web.dialog.box.ok')}
            </Button>
            <CtaLink noMargin onClick={openHelp}>
              {translate('sdk.web.qr.attach.wallet.help.cta')}
            </CtaLink>
          </div>
        </Wrapper>
      </PromptWrapper>
      <PromptModal
        isVisible={isHelpOpen}
        title={translate('sdk.web.qr.attach.wallet.help.title')}
        textContent={translate('sdk.web.qr.attach.wallet.help.text')}
        onClose={closeHelp}
        btnText={translate('sdk.web.dialog.box.ok')}
        onBtnClick={closeHelp}
      />
    </>
  );
};

export default WalletModal;

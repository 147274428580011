const fontFamilies = {
  default: {
    styles: {
      tiny: {
        fontSize: 12,
        fontWeight: 400,
        s375: {
          fontSize: 14,
        },
      },
      small: {
        fontSize: 14,
        fontWeight: 400,
        s375: {
          fontSize: 16,
        },
      },
      normal: {
        fontSize: 16,
        fontWeight: 400,
        s375: {
          fontSize: 18,
        },
      },
      medium: {
        fontSize: 18,
        fontWeight: 400,
        s375: {
          fontSize: 20,
        },
      },
      xMedium: {
        fontSize: 20,
        fontWeight: 400,
        s375: {
          fontSize: 20,
        },
      },
      large: {
        fontSize: 21,
        fontWeight: 500,
        s375: {
          fontSize: 24,
        },
      },
      xLarge: {
        fontSize: 26,
        fontWeight: 500,
        s375: {
          fontSize: 26,
        },
      },
      detailsHeader: {
        fontWeight: 'normal',
      },
    },
  },
  'Helvetica Neue': {
    name: 'Helvetica Neue',
  },
  'Open Sans': {
    name: 'Open Sans',
    url:
      'https://fonts.googleapis.com/css?family=Open+Sans:400,500,600&display=swap',
    styles: {
      medium: {
        fontSize: 18,
        fontWeight: 400,
        s375: {
          fontSize: 18,
        },
      },
      large: {
        fontSize: 21,
        fontWeight: 600,
        s375: {
          fontSize: 24,
        },
      },
      xLarge: {
        fontSize: 24,
        fontWeight: 600,
        s375: {
          fontSize: 24,
        },
      },
    },
  },
  Roboto: {
    name: 'Roboto',
    url: 'https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap',
  },
  'Adobe Sketchnote': {
    name: 'Adobe-sketchnote',
    styles: {
      xLarge: {
        fontSize: 26,
        fontWeight: 500,
        s375: {
          fontSize: 26,
          fontWeight: 500,
        },
      },
    },
  },
  'Noto Sans': {
    name: 'Noto Sans',
    url:
      'https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap',
    styles: {
      xLarge: {
        fontSize: 21,
        fontWeight: 500,
        s375: {
          fontSize: 24,
        },
      },
    },
  },
  'Transit Pro': {
    name: 'Transit-Pro',
  },
  'TheMixOsF Black': {
    name: 'TheMixOsF-Black',
    styles: {
      xLarge: {
        fontSize: 26,
        fontWeight: 500,
        s375: {
          fontSize: 26,
          fontWeight: 500,
        },
      },
    },
  },
  'Boston Angel Bold': {
    name: 'Boston-Angel-Bold',
    styles: {
      xLarge: {
        fontSize: 26,
        fontWeight: 500,
        s375: {
          fontSize: 26,
          fontWeight: 500,
        },
      },
    },
  },
};

export default fontFamilies;

import React from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';

import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';

const Wrapper = styled.div`
  padding: 0 20px;
`;

interface HowToProps {
  deal: any;
  isVisible: boolean;
  onClose: () => void;
}

const OnsiteHowTo = (props: HowToProps) => {
  const {deal, isVisible, onClose} = props;
  const {translate} = useTranslations();

  return (
    <PromptWrapper
      isVisible={isVisible}
      title={translate('sdk.web.offers.how.to.title')}
      align="left"
      onClose={onClose}>
      <Wrapper>
        <CopyTextSm
          dangerouslySetInnerHTML={{
            __html: deal.how_to,
          }}
        />
      </Wrapper>
    </PromptWrapper>
  );
};

export default OnsiteHowTo;

import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import {useTranslations} from 'hooks/use-translations';
import {useBodyBg} from 'hooks/ui/use-body-bg';
import {useOrganization} from 'hooks/use-organization';

import InfoPage from 'components/InfoPage/InfoPage';
import {CompensationPartner} from 'types/CompensationPartner';

const Wrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 40000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
`;

type CompensationDescriptionProps = {
  onClose: () => void;
  partner: CompensationPartner;
};

const CompensationDescription = ({
  onClose,
  partner,
}: CompensationDescriptionProps) => {
  const {translate} = useTranslations();
  const {organizationConfig} = useOrganization();
  const {updateBg} = useBodyBg({type: 'primary'});

  const aboutContent = [
    <div
      dangerouslySetInnerHTML={{
        __html: partner.description,
      }}
    />,
  ];

  useEffect(() => {
    return () =>
      updateBg(
        organizationConfig.theme.components.co2Compensation.pageBgColor
          ? {
              color:
                organizationConfig.theme.components.co2Compensation.pageBgColor,
            }
          : {
              type:
                organizationConfig.theme.components.co2Compensation?.pageBg ||
                'lightSecondary',
            },
      );
  }, [updateBg, organizationConfig]);

  return createPortal(
    <Wrapper>
      <InfoPage
        title={translate('sdk.web.compensation.partner.more.info')}
        rows={aboutContent}
        isLoading={false}
        onClose={onClose}
      />
    </Wrapper>,
    document.body,
  );
};

export default CompensationDescription;

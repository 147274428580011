const translations = {
  cityName: 'sdk.web.city.name',
  shortTokenSingular: 'sdk.web.token.short.singular',
  shortTokenPlural: 'sdk.web.token.short.plural',
  tokenFullSingular: 'sdk.web.token.singular',
  tokenFullPlural: 'sdk.web.token.plural',
  tokenAbbreviation: 'sdk.web.token.abbr',
  tokenTitle: 'sdk.web.contributions.your.token',
  co2Needed: 'sdk.web.contributions.savings.left.for.token',
  fullTokenText: 'sdk.web.contributions.full.token.text',
  offersTitle: 'sdk.web.offers.your.token',
  rewardType1Singular:
    'sdk.web.contributions.inviteFriends.rewardType1.singular',
  rewardType1Plural: 'sdk.web.contributions.inviteFriends.rewardType1.plural',
  notEnoughTokens: 'sdk.web.offers.not.enough.tokens',
  aboutTokenLabel: 'sdk.web.settings.about.title',
  onBoarding: {
    one: {
      title: 'sdk.web.onboarding.one.title',
      content: 'sdk.web.onboarding.one.text',
    },
    two: {
      title: 'sdk.web.onboarding.two.title',
      content: 'sdk.web.onboarding.two.text',
    },
    three: {
      title: 'sdk.web.onboarding.three.title',
      content: 'sdk.web.onboarding.three.text',
    },
    privacy: {
      title: 'sdk.web.onboarding.privacy.title',
      content: 'sdk.web.onboarding.privacy.text',
      confirmLink: 'sdk.web.onboarding.privacy.link',
    },
    gps: {
      title: 'sdk.web.onboarding.gps.title',
      content: 'sdk.web.onboarding.gps.text',
    },
  },
  competition: {
    title: 'sdk.web.teams.germany.leaderboard.title',
    subtitle: 'sdk.web.teams.germany.leaderboard.subtitle',
  },
};

export default translations;

import {fetchAPiClient} from './api';
import {SocioDemoData} from 'types/SocioDemoData';

const defaultClient = fetchAPiClient({subdomain: 'sst'});

export const getSocioDemoData = async (): Promise<
  SocioDemoData | undefined
> => {
  const client = defaultClient;
  const url = '/user/demographics';
  const response = (await client.get(url)) as
    | SocioDemoData
    | Array<SocioDemoData>;

  return response instanceof Array ? response?.[0] : response;
};

type SocioDemoDataResponse = SocioDemoData & {
  new_coins: number;
}

export const saveSocioDemoData = async (
  data: SocioDemoData,
): Promise<SocioDemoDataResponse> => {
  const client = defaultClient;
  const url = '/user/demographics';
  return client.post(url, data);
};

import React, {useContext, useMemo} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {formatToken, numberToKm} from 'utils/numbers';

import PromptModal from 'components/PromptModal/PromptModal';
import successImg from './icons/success.svg';

interface SuccessModalProps {
  emissionsRemaining: number;
  recoinsAvailable: number;
  isVisible: boolean;
  onClose: (action?: {offset: boolean}) => void;
}

const SuccessModal = (props: SuccessModalProps) => {
  const {isVisible, emissionsRemaining, recoinsAvailable, onClose} = props;
  const {organizationConfig} = useContext(SettingsContext);
  const {translate} = useTranslations();
  const {getTokenText} = useTokenTranslation();

  const textContent = useMemo(
    () => (
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: translate('sdk.web.emissions.offset.success.remaining', {
              key: '{nr}',
              value: numberToKm({
                number: emissionsRemaining,
                dp: 1,
                ignoreThousandRule: true,
              }),
            }),
          }}
        />
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: translate('sdk.web.emissions.offset.success.available', [
              {
                key: '{token}',
                value: getTokenText(2),
              },
              {
                key: '{nr}',
                value: formatToken({
                  number: recoinsAvailable,
                }),
              },
            ]),
          }}
        />
      </div>
    ),
    [getTokenText, emissionsRemaining, recoinsAvailable, translate],
  );

  return (
    <PromptModal
      isVisible={isVisible}
      title={translate('sdk.web.emissions.offset.success.title')}
      textContent={textContent}
      image={
        organizationConfig.theme.components.co2Emissions?.successImage ||
        successImg
      }
      onClose={onClose}
      btnText={translate('sdk.web.emissions.offset.success.ok')}
      onBtnClick={onClose}
      moreInfo={{
        text: translate('sdk.web.emissions.offset.success.offset'),
        onClick: () => onClose({offset: true}),
      }}
    />
  );
};

export default SuccessModal;

import React, {useCallback} from 'react';
import {createPortal} from 'react-dom';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {useUserProfile} from 'hooks/use-user-profile';
import routes from 'config/routes/rewards';
import {Voucher} from 'types/Offers';

import InfoPage from 'components/InfoPage/InfoPage';
import Card from 'components/Rewards/Deals/Card';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 40000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.theme.components.climatePartner?.bgColor ||
    props.theme.colors.secondary};
`;

const Content = styled.div`
  margin: -10px;
  padding-bottom: 40px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  margin-bottom: 10px;
`;

const Offer = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

interface OffersProps {
  vouchers: any;
}

const Offers = (props: OffersProps) => {
  const {vouchers} = props;
  const history = useHistory();

  const {userProfile} = useUserProfile();
  const userRecoins = userProfile?.user?.recoins || 0;

  const onClose = useCallback(() => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.REWARDS.DEALS.href);
    }
  }, [history]);

  return createPortal(
    <Wrapper>
      <InfoPage title="Offers" bgColor="transparent" onClose={onClose}>
        <Content>
          <List>
            {vouchers.map((voucher: Voucher) => (
              <Item key={voucher.id}>
                <Offer
                  to={`${routes.REWARDS.DEALS.href}/voucher/${voucher.id}?scanned=true`}>
                  <Card
                    {...voucher}
                    userRecoins={userRecoins}
                    updateVoucherWishlist={() => {}}
                    imageHeight="150px"
                  />
                </Offer>
              </Item>
            ))}
          </List>
        </Content>
      </InfoPage>
    </Wrapper>,
    document.body,
  );
};

export default Offers;

import {useMemo} from 'react';

import {ElementData, METERING_ELEMENTS, METERING_UNITS} from 'types/Metering';
import useElementTranslation from '../../hooks/use-element-translation';
import {getUnitString} from 'components/ClimateWidgets/Metering/utils';

interface UseMeterUnitsProps {
  element: METERING_ELEMENTS;
  elementData: ElementData;
}

export const useMeterUnits = (props: UseMeterUnitsProps) => {
  const {element, elementData} = props;
  const translate = useElementTranslation({element});

  return useMemo(
    () => ({
      unit: translate(`sdk.web.meter.unit.${getUnitString({element})}`),
      meterUnit: translate(`sdk.web.meter.unit.${METERING_UNITS.METER}`),
      showMeter: elementData.consumptionUnit === METERING_UNITS.METER,
    }),
    [translate, elementData, element],
  );
};

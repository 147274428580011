import React from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';

import InfoPage from 'components/InfoPage/InfoPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 40000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
`;

const Content = styled.div`
  padding-bottom: 40px;
  height: 100%;
  background-color: white;
`;

interface AboutProps {
  onClose: () => void;
  title: string;
  content: string;
}

const AboutContent = (props: AboutProps) => {
  const {title, content, onClose} = props;

  return createPortal(
    <Wrapper>
      <InfoPage title={title} bgColor="transparent" onClose={onClose}>
        <Content
          dangerouslySetInnerHTML={{
            __html: content || '',
          }}
        />
      </InfoPage>
    </Wrapper>,
    document.body,
  );
};

export default AboutContent;

import React, {useContext, useMemo} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';

import {SettingsContext} from 'contexts/SettingsContext';
import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';
import {numberToKm} from 'utils/numbers';

import Loader from 'components/Loader/Loader';
import CopyText from 'components/Text/CopyText/CopyText';
import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import WidgetTitle from 'components/ClimateWidgets/Widget/WidgetTitle';
import Mobility from '../Mobility/Mobility';

import {ReactComponent as InfoIcon} from './icons/info.svg';
import walking from '../Mobility/images/walking.svg';
import cycling from '../Mobility/images/cycling.svg';
import publicTransit from '../Mobility/images/public-transit.svg';
import customActivities from '../Mobility/images/custom-activities.svg';

const Title = styled(WidgetTitle)`
  margin-bottom: 20px;
`;

const Info = styled(CopyText)`
  margin-bottom: 20px;
  display: block;
  text-align: center;
  fill: white;
`;

const Mobilities = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  width: calc(50% - 7.5px);
  margin-bottom: 10px;
`;

const InfoIconContainer = styled.div`
  text-align: right;
`;

const InfoLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  width: 35px;
  fill: ${(props) => props.theme.colors.primary};

  &:hover {
    text-decoration: none;
  }
`;

const Co2Savings = (props: any) => {
  const {activitySummary} = props;
  const {translate, locale} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {hasCo2SavingsInfoText} = useOrganization();

  const savings = activitySummary ? activitySummary.savings : {};
  const totalSavings = activitySummary
    ? numberToKm({number: activitySummary.totalSavings, dp: 2})
    : 0;
  const totalOrganizationSavings = activitySummary
    ? numberToKm({number: activitySummary.totalOrganizationSavings})
    : 0;

  const iconsConfig = organizationConfig.theme.components.mobilityIcons;
  const pathColor =
    organizationConfig.theme.components.mobilityIcons.progressPathColor ||
    '#fff';

  const customActivityImg = useMemo(
    () =>
      iconsConfig?.customActivities?.[locale]?.img ||
      iconsConfig?.customActivities?.img ||
      customActivities,
    [iconsConfig, locale],
  );

  const title = useMemo(
    () =>
      translate('sdk.web.contributions.savings.title', {
        key: '{year}',
        value: new Date().getFullYear(),
      }),
    [translate],
  );

  return (
    <WidgetCard>
      <Title
        aboutInfo={{
          title,
          userProfilePath: 'organisation.co2_contribution_info_text',
          enabled: hasCo2SavingsInfoText,
        }}>
        {title}
      </Title>
      {!!activitySummary ? (
        <Info>
          <div>
            {translate('sdk.web.contributions.your.savings', {
              key: '{co2Value}',
              value: totalSavings,
            })}
          </div>
          <div>
            {translate('sdk.web.contributions.our.savings', {
              key: '{co2AllofUs}',
              value: totalOrganizationSavings,
            })}
          </div>
        </Info>
      ) : (
        <Loader sm={true} />
      )}
      <Mobilities>
        <Item>
          <Mobility
            percent={savings.walking}
            img={iconsConfig.walking.img || walking}
            bgColor={iconsConfig.walking.bgColor}
            pathColor={pathColor}
          />
        </Item>
        <Item>
          <Mobility
            percent={savings.cycling}
            img={iconsConfig.cycling.img || cycling}
            bgColor={iconsConfig.cycling.bgColor}
            pathColor={pathColor}
          />
        </Item>
        <Item>
          <Mobility
            percent={savings.publicTransit}
            img={iconsConfig.publicTransit.img || publicTransit}
            bgColor={iconsConfig.publicTransit.bgColor}
            pathColor={pathColor}
          />
        </Item>
        {!isUndefined(savings.customActivities) && (
          <Item>
            <Mobility
              percent={savings.customActivities}
              img={customActivityImg}
              bgColor="transparent"
              pathColor={pathColor}
            />
          </Item>
        )}
      </Mobilities>
      {organizationConfig.features.showCo2SavingsInfo && (
        <InfoIconContainer>
          <InfoLink to={routes.SETTINGS.ABOUT.href}>
            <InfoIcon />
          </InfoLink>
        </InfoIconContainer>
      )}
    </WidgetCard>
  );
};

export default Co2Savings;

import React from 'react';
import styled from 'styled-components';
import {omit} from 'lodash';
import {ThreeDots} from 'react-loader-spinner';
import {addBoldText, addFontFamily, addGlobalSquareCorners} from 'utils/theme';

import CopyText from '../Text/CopyText/CopyText';

type ButtonProps = {
  variant?: string;
};

const El = styled.button<ButtonProps>`
  display: inline-block;
  border: none;
  outline: none;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-family: inherit;
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  padding: 13px 32px;
  background-color: ${(props) =>
    props.theme.components.buttons[props.variant || 'primary'].bgColor};
  color: ${(props) =>
    props.theme.components.buttons[props.variant || 'primary'].color};
  border-radius: ${(props) =>
    props.theme.components.buttons.borderRadius || '30px'};

  &:hover,
  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) =>
      props.theme.components.buttons.primary.disabledBgColor};
  }

  ${addGlobalSquareCorners()}

  ${(props) =>
    addFontFamily({
      props,
      value: props.theme.components.buttons?.fontFamily,
    })}

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Text = styled(CopyText)`
  font-size: ${(props) => props.theme.components.buttons.fontSize};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  ${(props) =>
    addBoldText({
      props,
    })}
`;

const Button = (props: any) => {
  const {isLoading} = props;
  const btnProps = omit(props, ['isSubmitting']);

  return (
    <El {...btnProps} disabled={isLoading || btnProps.disabled}>
      <Text>
        {isLoading ? (
          <ThreeDots color={'#fff'} height={20} width={20} />
        ) : (
          props.children
        )}
      </Text>
    </El>
  );
};

export default Button;

import React, {createContext, useCallback, useState} from 'react';
import Pusher, {Channel} from 'pusher-js';

import config from '../config/pusher';
import {getAuthConfig} from 'services/auth';
import {UserProfile} from 'services/user';

export type NotificationStats = {
  unseen: number;
  unread: number;
};

type AddPrivateChannelListenerParams = {
  event: string;
  callback: (data: any) => any;
};
type AddPrivateChannelListener = (
  params: AddPrivateChannelListenerParams,
) => Channel | undefined;

type Init = (params: {userProfile: UserProfile}) => Promise<void>;

type NotificationObject = {
  pusher?: Pusher;
  isNotificationsInitialized: boolean;
  init: Init;
  stats: NotificationStats;
  updateStats: (stats: NotificationStats) => void;

  addPrivateChannelListener?: AddPrivateChannelListener;
};

const defaultNotificationValue = {
  isNotificationsInitialized: false,
  init: async () => {},
  stats: {
    unseen: 0,
    unread: 0,
  },
  updateStats: (stats: {unseen: number; unread: number}) => {},
};

export const NotificationContext = createContext<NotificationObject>(
  defaultNotificationValue,
);

const NotificationProvider = (props: any) => {
  const [isNotificationsInitialized, setIsNotificationsInitialized] =
    useState(false);
  const [pusher, setPusher] = useState<Pusher>();
  const [privateChannel, setPrivateChannel] = useState<Channel>();

  const [stats, setStats] = useState({
    unseen: 0,
    unread: 0,
  });

  const init: Init = useCallback(
    async ({userProfile}) => {
      if (pusher) {
        return;
      }

      const {env, baseDomain, headers} = getAuthConfig();
      const key = config.keys[env];
      if (!key) {
        console.error('No pusher key found: ', key, env);
        return;
      }

      const authEndpoint = `https://sst.${baseDomain}/api/broadcasting/auth`;

      const authPusher = new Pusher(key, {
        cluster: 'eu',
        authEndpoint,
        auth: {
          headers,
        },
      });

      setPusher(authPusher);
      setIsNotificationsInitialized(true);

      const privateChannel = authPusher.subscribe(
        `private-user.${userProfile.user.id}`,
      );

      setPrivateChannel(privateChannel);
    },
    [pusher],
  );

  const addPrivateChannelListener: AddPrivateChannelListener = useCallback(
    ({event, callback}) => {
      if (!privateChannel) {
        return;
      }

      privateChannel.bind(event, callback);

      return privateChannel;
    },
    [privateChannel],
  );

  const value = {
    pusher,
    init,
    stats,
    isNotificationsInitialized,
    addPrivateChannelListener,
    updateStats: setStats,
  };

  return (
    <NotificationContext.Provider value={value}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';

import {useBodyBg} from 'hooks/ui/use-body-bg';
import {SettingsContext} from 'contexts/SettingsContext';
import {addFontFamily} from 'utils/theme';

import HeaderText from 'components/Text/HeaderText/HeaderText';
import CopyText from 'components/Text/CopyText/CopyText';
import {ReactComponent as Close} from 'icons/close.svg';

const overlayVariants = {
  hidden: {
    opacity: 0,
    transition: {duration: 0.4, ease: 'easeInOut'},
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const contentVariants = {
  hidden: {
    y: '100vh',
    transition: {duration: 0.3, ease: 'easeInOut'},
  },
  visible: {
    y: 0,
    transition: {delay: 0.1, ease: 'easeInOut'},
  },
};

const Wrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 400000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  color: ${(props) =>
    props.theme.components.prompts?.textColor || props.theme.colors.black};
`;

const Overlay = styled(motion.div)<{align?: 'left'}>`
  padding: 10% 0 0;
  height: 100%;
  background: ${(props) =>
    props.theme.components.prompts.overlayBgColor ||
    props.theme.colors.promptOverlayBg};
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: ${(props) => props.align || 'center'};
`;

const ContentBox = styled(motion.div)<{pb?: string; align?: 'left'}>`
  background: ${(props) =>
    props.theme.components.prompts.bgColor || props.theme.colors.promptBg};
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: ${(props) => props.align || 'center'};
  overflow: hidden;
  padding: 83px 0 40px;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  padding-bottom: ${(props) => props.pb};
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(HeaderText)<{align?: 'left'; phBase?: boolean}>`
  display: block;
  margin-bottom: 20px;
  padding: 0
    ${(props) => (props.align === 'left' || props.phBase ? '20px' : '5px')};

  ${(props) =>
    addFontFamily({
      props,
      value: props.theme.components.prompts?.titleFontFamily,
    })}
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const Text = styled(CopyText)`
  display: block;
  margin-bottom: 20px;
  padding: 0 20px;
`;

const NavIcon = styled.div`
  fill: ${(props) =>
    props.theme.components.prompts.closeIconColor ||
    props.theme.components.prompts?.textColor ||
    props.theme.colors.primary};
  cursor: pointer;
  width: 25px;
  position: absolute;
  top: 20px;
  left: 20px;
`;

interface PromptWrapperProps {
  title?: string | JSX.Element;
  textContent?: string | JSX.Element;
  image?: string;
  align?: 'left';
  isVisible?: boolean;
  onClose?: () => any;
  styles?: Record<string, string>;
}

const PromptWrapper = (props: PropsWithChildren<PromptWrapperProps>) => {
  const {
    title,
    textContent,
    image,
    onClose,
    isVisible,
    styles = {},
    align,
    children,
  } = props;
  const {bodyBgColor, organizationConfig} = useContext(SettingsContext);
  const {updateBg} = useBodyBg({});
  const [lastBodyBgColor, setLastBodyBgColor] = useState();

  useEffect(
    () => {
      if (!lastBodyBgColor) {
        setLastBodyBgColor(bodyBgColor);
        return;
      }

      if (isVisible) {
        updateBg({
          color:
            organizationConfig.theme.components.prompts?.overlayBgColor ||
            organizationConfig.theme.colors.overlayBodyBg,
        });
        setLastBodyBgColor(bodyBgColor);
      } else {
        updateBg({color: lastBodyBgColor});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible],
  );

  return createPortal(
    <AnimatePresence>
      {isVisible && (
        <Wrapper>
          <Overlay
            align={align}
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="hidden">
            <ContentBox
              align={align}
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              pb={styles?.pb}>
              {onClose && (
                <NavIcon onClick={onClose}>
                  <Close />
                </NavIcon>
              )}
              <Content>
                {title && (
                  <Title align={align} phBase>
                    {title}
                  </Title>
                )}
                {image && <Image src={image} alt="" />}
                {textContent && typeof textContent === 'string' ? (
                  <Text dangerouslySetInnerHTML={{__html: textContent}} />
                ) : (
                  <Text>{textContent}</Text>
                )}
                {children}
              </Content>
            </ContentBox>
          </Overlay>
        </Wrapper>
      )}
    </AnimatePresence>,
    document.body,
  );
};

export default PromptWrapper;

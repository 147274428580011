import React, {useContext} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {useTranslations} from 'hooks/use-translations';

import DialogBox from 'components/DialogBox/DialogBox';

interface PermissionDialogProps {
  isVisible: boolean;
  onConfirmation: (isConfirmed: boolean) => any;
}

const PermissionDialog = (props: PermissionDialogProps) => {
  const {isVisible, onConfirmation} = props;
  const {appName} = useContext(SettingsContext);
  const {translate} = useTranslations();

  if (!isVisible) {
    return null;
  }

  return (
    <DialogBox
      title={translate('sdk.web.meter.consumption.photo.perm.title')}
      promptMessage={translate('sdk.web.meter.consumption.photo.perm.body', {
        key: '{appName}',
        value: appName,
      })}
      noText={translate('sdk.web.dialog.box.cancel')}
      yesText={translate('sdk.web.meter.consumption.photo.perm.cta')}
      onConfirmation={onConfirmation}
    />
  );
};

export default PermissionDialog;

import React from 'react';
import styled from 'styled-components';
import {useTranslations} from 'hooks/use-translations';

const Wrapper = styled.div`
  display: block;
`;

const Item = styled.div`
  display: flex;
  margin-bottom: 3px;
`;

const PrizeLabel = styled.div`
  width: 83px;
`;

const PrizeName = styled.div`
  text-align: left;
  flex: 1;
`;

const Amount = styled.div`
  width: 35px;
  text-align: right;
`;

const LotteryPrizes = (props: any) => {
  const {lottery} = props;
  const {translate} = useTranslations();

  return (
    <React.Fragment>
      <Wrapper>
        {lottery.prizes.map((prize: any, index: number) => (
          <Item key={prize.id}>
            <PrizeLabel>
              <b>
                {index + 1}. {translate('sdk.web.lots.prize')}
              </b>
            </PrizeLabel>
            <PrizeName>{prize.prize_name}</PrizeName>
            <Amount>{prize.amount} x</Amount>
          </Item>
        ))}
      </Wrapper>
    </React.Fragment>
  );
};

export default LotteryPrizes;

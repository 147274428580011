import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import PromptWrapper from './PromptWrapper/PromptWrapper';

const Footer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 20px;
`;

const LinkText = styled(CtaLink)`
  margin-top: 20px;
  margin-bottom: 0;
`;

interface PromptModalProps {
  title?: string | JSX.Element;
  textContent?: string | JSX.Element;
  isVisible?: boolean;
  image?: string;
  isButtonLoading?: boolean;
  btnText: string;
  moreInfo?: {
    text?: string;
    link?: string;
    onClick?: (...data: any) => any;
  } | null;
  extraContent?: React.ReactNode;
  onBtnClick?: (...data: any) => any;
  onClose?: (...data: any) => any;
}

const PromptModal = (props: PromptModalProps) => {
  const {
    title,
    textContent,
    isVisible,
    image,
    isButtonLoading,
    btnText,
    onBtnClick,
    moreInfo,
    extraContent,
    onClose,
  } = props;

  return (
    <PromptWrapper
      title={title}
      textContent={textContent}
      image={image}
      isVisible={isVisible}
      onClose={onClose}>
      <Footer>
        <div>
          <Button onClick={onBtnClick} isLoading={isButtonLoading}>
            {btnText}
          </Button>
          {moreInfo && (
            <>
              {moreInfo.link ? (
                <Link to={moreInfo.link} className="link-base">
                  <LinkText noMargin>{moreInfo.text}</LinkText>
                </Link>
              ) : (
                <LinkText
                  noMargin
                  onClick={() => moreInfo.onClick && moreInfo.onClick()}>
                  {moreInfo.text}
                </LinkText>
              )}
            </>
          )}
          {extraContent && extraContent}
        </div>
      </Footer>
    </PromptWrapper>
  );
};

export default PromptModal;

import buttons from 'config/organizations/preset/buttons';
import {getNewsFeed} from 'services/sintra/news-feed';

import Co2Thaler from 'components/ClimateWidgets/Co2Token/CityThaler';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/CityThaler/images/thalers/full.svg';

import customActivitiesImgEn from 'components/ClimateWidgets/Mobility/images/sintra/custom-activities-en.svg';
import customActivitiesImgPt from 'components/ClimateWidgets/Mobility/images/sintra/custom-activities-pt.svg';

import cyclingImg from 'components/ClimateWidgets/Mobility/images/sdksample/cycling.svg';
import publicTransitImg from 'components/ClimateWidgets/Mobility/images/sdksample/public-transit.svg';
import walkingImg from 'components/ClimateWidgets/Mobility/images/sdksample/walking.svg';

import treePlantingImage from 'components/ClimateWidgets/TreePlanting/images/sintra/tree.svg';
import treePlantingGoalImage from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/images/sdksample/banner.svg';
import treePlantingNewTreeImage from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/images/sdksample/banner.svg';
import treePlantingHalfwayImage from 'components/ClimateWidgets/TreePlanting/HalfwayTreePrompt/images/sdksample/banner.svg';
import {images as treePlantingImages} from 'components/ClimateWidgets/TreePlanting/TreeProgress/sdksample';

import {ReactComponent as InviteFriendsInfoIcon} from 'components/ClimateWidgets/InviteFriends/icons/sdksample/info.svg';
import {ReactComponent as LocationLinkIcon} from 'icons/sintra/location-link.svg';

const config = {
  name: 'sintra',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Open Sans',
          secondary: 'Open Sans',
        },
        ios: {
          primary: 'Open Sans',
          secondary: 'Open Sans',
        },
      },
      de: {
        android: {
          primary: 'Open Sans',
          secondary: 'Open Sans',
        },
        ios: {
          primary: 'Open Sans',
          secondary: 'Open Sans',
        },
      },
      pt: {
        android: {
          primary: 'Open Sans',
          secondary: 'Open Sans',
        },
        ios: {
          primary: 'Open Sans',
          secondary: 'Open Sans',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#C0D9C2',
      secondary: '#E0ECE1',
      light: '#FFFFFF',
      white: '#FFFFFF',
      dark: '#30454A',
      black: '#000000',
      gray: '#D8D9DA',
      glacierLight: '#80BCC3FA',
      sunDown: '#FFAFAF',
      ziggurat: '#BFDEE2',
      catSkillWhite: '#EAF5F6',
    },
    components: {
      global: {
        borderColor: 'glacierLight',
        boldTextFontWeight: 600,
      },
      dashboard: {
        bgColor: 'secondary',
      },
      competition: {
        bgColor: 'secondary',
      },
      widget: {
        title: {
          fontWeight: 600,
          fontSize: 24,
        },
      },
      copyText: {
        fontWeight: 600,
      },
      mobility: {
        fontFamily: 'secondary',
        fontSize: 18,
      },
      climateCalendar: {
        fontFamily: 'primary',
      },
      widgetTitle: {
        fontFamily: 'primary',
      },
      nav: {
        bgColor: 'primary',
        menuColor: 'white',
        activeColor: 'black',
        inactiveOpacity: 1,
        routes: ['impact', 'offers', 'news', 'teams', 'more'],
        fontWeight: 600,
      },
      widgetCard: {
        bgColor: 'light',
        titleBgColor: 'black',
        titleColor: 'white',
        textColor: 'black',
      },
      offers: {
        activeTabColor: 'black',
        activeTabFontWeight: '600',
        activeTabBgColor: 'primary',
        navColor: 'black',
        navBgColor: '#CCE0CE',
        bodyBgColor: 'secondary',
        locationTypeColor: 'black',
        recoinsLeftBgColor: 'secondary',
        lessRecoinsLeftBgColor: '#FFF1D5',
        recoinsRequiredBgColor: 'secondary',
        locationTypeBgColor: 'secondary',
        directionColor: 'black',
        climateCoinsFontWeight: '600',
        LocationLinkIcon,
        climateCoinsFontTitle: '20px',
        headerIconsColor: 'black',
        headerIconsBgColor: '#F2F7F3',
        walletStrokeColor: 'black',
      },
      co2Token: {
        walletStrokeColor: 'black',
      },
      wallets: {
        textColor: 'black',
      },
      climatePartnerMap: {
        bgColor: 'secondary',
      },
      badges: {
        bgColor: 'light',
        allLinkColor: 'primary',
        shareColor: 'primary',
        fontSize: 18,
        titleFontSize: 24,
        noUnderline: true,
      },
      treePlanting: {
        bgColor: 'white',
        treeImage: treePlantingImage,
        goalBanner: treePlantingGoalImage,
        newTreeBanner: treePlantingNewTreeImage,
        halfwayBanner: treePlantingHalfwayImage,
        progressImages: treePlantingImages,
        progressPathColor: '#000000',
        progressBgColor: '#F2F7F3',
      },
      links: {
        noUnderline: false,
        textColor: 'black',
      },
      buttons: {
        ...buttons,
        primary: {
          bgColor: 'primary',
          disabledBgColor: 'disabled',
          color: 'black',
        },
        fontWeight: 600,
        // fontSize: '16px',
        borderRadius: '50px',
      },
      mobilityIcons: {
        progressPathColor: 'primary',
        walking: {
          bgColor: '#5AE6BE',
          img: walkingImg,
        },
        cycling: {
          bgColor: '#5AE6BE',
          img: cyclingImg,
        },
        publicTransit: {
          bgColor: '#5AE6BE',
          img: publicTransitImg,
        },
        customActivities: {
          bgColor: '#5AE6BE',
          en: {
            img: customActivitiesImgEn,
          },
          pt: {
            img: customActivitiesImgPt,
          },
        },
      },
      prompts: {
        closeIconColor: 'black',
        bgColor: 'light',
        overlayBgColor: '#00000099',
        titleFontFamily: 'primary',
      },
      customActivities: {
        activeCheckColor: 'black',
        detailsBgColor: 'secondary',
        ctaIconCheckColor: 'black',
        listBgColor: 'white',
        successPromptColor: 'white',
        hideCaret: true,
      },
      settings: {
        bgColor: 'light',
        itemCaretColor: 'primary',
      },
      co2Compensation: {
        pageBgColor: 'secondary',
        emptySunBgColor: 'secondary',
        compensateButtonFontWeight: '600',
        titleFontSize: 20,
        priceFontSize: 18,
        descriptionFontSize: 18,
        paymentForm: {
          headerTitleFontSize: 20,
        },
      },
      leaderboard: {
        bgColor: 'white',
        ctaColor: 'black',
      },
      inviteFriends: {
        modalBgColor: 'light',
        progressBarBgColor: '#CACACA',
        progressBarColor: 'primary',
        shareIconColor: 'primary',
        InfoIcon: InviteFriendsInfoIcon,
        titleFontSize: 24,
        fontFamily: 'primary',
      },
      vouchers: {
        bgColor: 'white',
        color: 'black',
        recoinsTagBgColor: 'secondary',
        active: {
          companyColor: 'black',
          activeBgColor: 'secondary',
          pendingBgColor: 'secondary',
          expiredBgColor: 'sunDown',
          progressColor: 'primary',
          progressTrailColor: 'ziggurat',
          headerFontFamily: 'secondary',
          dateFontSize: 46,
        },
        online: {
          codeColor: 'black',
        },
        wishlistBgColor: '#DBF3F2',
        wishlistIcon: {
          fill: 'black',
          inActiveOpacity: 0.8,
        },
      },
      news: {
        bgColor: 'white',
        titleBgColor: 'secondary',
        titleColor: 'black',
        style: 'comment',
        notificationsBgColor: 'catSkillWhite',
        notificationsCaretColor: 'black',
      },
      notifications: {
        caretColor: 'black',
      },
      summaryPage: {
        closeIconColor: 'black',
      },
      detailsNavigation: {
        color: 'black',
        fontWeight: '600',
      },
      lots: {
        fontWeight: 600,
      },
      alerts: {
        success: {
          theme: 'dark',
        },
      },
    },
    colors: {
      primary: '#C0D9C2',
      secondary: '#E0ECE1',
      tertiary: '#028fc6',
      white: '#ffffff',
      black: '#000000',
      gray: '#D8D9DA',
      disabled: '#dee2e6',
      promptBg: '#FFFFFF',
      promptOverlayBg: '#00000099',
      onboardingBg: '#FFFFFF',
      teamRankingHighlight: '#FFF3D7',
    },
    fontWeight: {
      title: 600,
      normal: 400,
    },
  },
  translations: {
    shortTokenSingular: 'sdk.web.sintra.token',
    shortTokenPlural: 'sdk.web.sintra.token',
    tokenFullSingular: 'sdk.web.sintra.token',
    tokenFullPlural: 'sdk.web.sintra.token',
  },
  services: {
    getNewsFeed: getNewsFeed,
  },
  features: {
    hideAppClose: true,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: false,
    hasCustomActivities: true,
    hasCo2Compensation: false,
    hasBadges: true,
    hasNewQRScanner: true,
    hasWallets: true,
    hasBambooCoin: true,
    hasSintraBannerWidget: true,
    hasNotifications: true,
    stage: {},
  },
  applangaTag: '642d5e2bc8cff35361bd8855',
  matomoTrackingId: 3,
  tokenType: 'Thaler',
  fullTokenImg,
  // closeIconType: 'caret',
  FullTokenComponent: Co2Thaler,
};

export default config;

import {useContext, useEffect} from 'react';
import {SettingsContext} from 'contexts/SettingsContext';
import {AuthContext} from 'contexts/AuthContext';

export const useUserLoader = () => {
  const {organizationConfig} = useContext(SettingsContext);
  const {loadUserProfile} = useContext(AuthContext);

  useEffect(
    () => {
      if (organizationConfig?.name) {
        loadUserProfile({reload: false});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationConfig],
  );
};

import React, {useContext} from 'react';
import styled from 'styled-components';
import {Link, NavLink} from 'react-router-dom';

import {SettingsContext} from 'contexts/SettingsContext';
import routes from 'config/routes';
import {useOrganization} from 'hooks/use-organization';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {formatToken} from 'utils/numbers';

import TextXMedium from 'components/Text/TextXMedium';
import OffersFilter from 'components/Rewards/Header/OffersFilter';
import Tabs from './Tabs/Tabs';
import OffersScanner from 'components/Rewards/Header/OffersScanner';
import Icon from 'components/Rewards/Header/Icon';

import {ReactComponent as LocationIcon} from 'icons/location-link.svg';
import {ReactComponent as WalletIcon} from 'icons/wallet.svg';

const Wrapper = styled.div`
  background: ${(props) => props.theme.components.offers.navBgColor};
  padding: 0 10px;
  padding-top: 15px;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  color: ${(props) => props.theme.components.offers.navColor || 'white'};
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.font.secondary.name};
`;

const DefaultTitle = styled(TextXMedium)`
  font-weight: ${(props) =>
    props.theme.components.offers?.climateCoinsFontWeight};
`;

const WalletTitle = styled(TextXMedium)`
  display: flex;
  align-items: center;
  font-weight: ${(props) =>
    props.theme.components.offers?.climateCoinsFontWeight};
  font-size: ${(props) => props.theme.components.offers?.climateCoinsFontTitle};
`;

const WalletIconWrapper = styled(Link)`
  width: 33px;
  fill: ${(props) => props.theme.components.offers.navBgColor};
  margin-right: 7px;

  svg {
    width: 100%;
    fill: ${(props) => props.theme.components.offers.navBgColor};
    stroke: ${(props) =>
      props.theme.components.offers.walletStrokeColor || 'white'};
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
`;

interface HeaderProps {
  hearts: number;
  showOffersFilter: boolean;
  activeOffersFilter: Array<string>;
  onFilter: (filters: Array<string>) => any;
}

const Header = (props: HeaderProps) => {
  const {hearts, showOffersFilter, activeOffersFilter, onFilter} = props;

  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {hasClimatePartners, hasWallets, hasHomeQrScanner} = useOrganization();
  const {tokenAbbreviation, getTokenText} = useTokenTranslation();

  const token = formatToken({number: hearts || 0});

  return (
    <Wrapper>
      <Tabs />
      <Content>
        <Title>
          {hasWallets ? (
            <WalletTitle>
              <WalletIconWrapper to={routes.DASHBOARD.WALLET.href}>
                <WalletIcon />
              </WalletIconWrapper>
              <div>
                {translate('sdk.web.offers.token', [
                  {
                    key: '{tokens}',
                    value: tokenAbbreviation,
                  },
                  {
                    key: '{nr}',
                    value: token,
                  },
                ])}
              </div>
            </WalletTitle>
          ) : (
            <DefaultTitle>
              {translate(organizationConfig.translations.offersTitle, {
                key: '{token}',
                value: getTokenText(),
              })}
              : {token}
            </DefaultTitle>
          )}
        </Title>
        <Section>
          {showOffersFilter && (
            <OffersFilter
              activeOffersFilter={activeOffersFilter}
              onFilter={onFilter}
            />
          )}
          {hasClimatePartners && (
            <NavLink to={`${routes.CLIMATE_TREASURE.href}?source=offers`}>
              <Icon Icon={LocationIcon} />
            </NavLink>
          )}
          {hasHomeQrScanner && <OffersScanner />}
        </Section>
      </Content>
    </Wrapper>
  );
};

export default Header;

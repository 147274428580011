import React from 'react';

import T0 from './images/coins/0.svg';
import T1 from './images/coins/1.svg';
import T2 from './images/coins/2.svg';
import T3 from './images/coins/3.svg';
import T4 from './images/coins/4.svg';
import T5 from './images/coins/5.svg';
import T6 from './images/coins/6.svg';
import T7 from './images/coins/7.svg';
import T8 from './images/coins/8.svg';

const coins = [T0, T1, T2, T3, T4, T5, T6, T7, T8];

const DiehlCoin = (props: any) => {
  const {percent} = props;
  const tokenIndex = Math.floor((percent / 100) * (coins.length - 1));
  const tokenImg = coins[tokenIndex];

  return <img src={tokenImg} alt="" />;
};

export default DiehlCoin;

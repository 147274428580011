import React from 'react';
import styled from 'styled-components';

import {addGlobalBoxShadow, addGlobalSquareCorners} from 'utils/theme';

import {ReactComponent as CloseIcon} from 'icons/times-padding-none.svg';

type WrapperProps = {
  bgColor?: string;
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  minHeight?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  min-height: ${(props) => props.minHeight || '200px'};
  overflow: hidden;
  padding: 20px;
  position: relative;
  border-radius: ${(props) =>
    props.theme.components.widgetCard.borderRadius || '10px'};
  margin-bottom: 10px;
  color: ${(props) =>
    props.theme.components.widgetCard.textColor || props.theme.colors.black};
  background: ${(props) =>
    props.bgColor || props.theme.components.widgetCard.bgColor};

  ${(props) => {
    if (props.theme.components.widgetCard.titlePosition === 'top') {
      return `
        border-radius: 5px;
      `;
    }
    return '';
  }}

  ${(props) => {
    if (props.theme.components.widgetCard.hasSquareCorners) {
      return `
        border-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      `;
    }
    return '';
  }}

  ${addGlobalSquareCorners()}
  ${addGlobalBoxShadow()}
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 17px;
  right: 17px;
  display: flex;
  width: 15px;
  fill: ${(props) => props.theme.colors.primary};

  svg {
    width: 100%;
  }
`;

export type WidgetCardProps = WrapperProps & {
  onClose?: (...data: any) => any;
};

const WidgetCard = (props: React.PropsWithChildren<WidgetCardProps>) => {
  const {children, onClose, ...rest} = props;

  return (
    <Wrapper {...rest}>
      {onClose && (
        <CloseIconWrapper onClick={onClose}>
          <CloseIcon />
        </CloseIconWrapper>
      )}
      {children}
    </Wrapper>
  );
};

export default WidgetCard;

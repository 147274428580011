import React from 'react';
import styled from 'styled-components';

import {useTokenTranslation} from 'hooks/use-token-translation';
import useElementTranslation from '../hooks/use-element-translation';
import {ELEMENT_SCREENS, ElementScreenProps} from '../types';

import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import CopyText from 'components/Text/CopyText/CopyText';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';

import banner from '../icons/new-month-banner.svg';

const Wrapper = styled.div`
  padding: 0 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .e-new-month {
    &__icon {
      margin-bottom: 20px;
    }

    &__description {
      padding: 0 20px;
      margin-bottom: 40px;
    }

    &__form {
      text-align: left;
    }

    &__cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__btn {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;

const NewMonth = (props: ElementScreenProps) => {
  const {element, stats, onComplete} = props;
  const translate = useElementTranslation({element});
  const {getTokenText} = useTokenTranslation();

  return (
    <Wrapper>
      <div>
        <Title>{translate('sdk.web.meter.new.month.title')}</Title>
        <img className="e-new-month__icon" src={banner} alt="banner" />
        <CopyText>
          {translate('sdk.web.meter.new.month.desc', {
            key: '{token}',
            value: getTokenText(2),
          })}
        </CopyText>
      </div>
      <div className="e-new-month__cta">
        <div className="e-new-month__btn">
          <Button
            onClick={() =>
              onComplete({nextScreen: ELEMENT_SCREENS.CONSUMPTION})
            }>
            {translate('sdk.web.meter.new.month.update')}
          </Button>
        </div>
        {stats!.has_previous_month_consumption && (
          <CtaLink
            onClick={() =>
              onComplete({nextScreen: ELEMENT_SCREENS.PREVIOUS_RESULTS})
            }>
            {translate('sdk.web.meter.new.month.prev.results')}
          </CtaLink>
        )}
        <CtaLink
          onClick={() => onComplete({nextScreen: ELEMENT_SCREENS.SETTINGS})}
          noMargin>
          {translate('sdk.web.meter.summary.settings')}
        </CtaLink>
      </div>
    </Wrapper>
  );
};

export default NewMonth;

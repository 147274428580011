import React, {useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {isDesktop} from 'react-device-detect';
import {
  OpenWidgetApi,
  OpenWidgetTheme,
  OpenWidgetUser,
  userProfileToOpenWidgetUser,
} from 'sdkwidgets';

import {AuthContext} from 'contexts/AuthContext';
import LayoutContext from 'contexts/LayoutContext';
import {SettingsContext} from 'contexts/SettingsContext';
import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {CurrentLocationEvent, QRCodeEvent} from 'types/NativeEvents';
import useNativeEvent from './use-native-event';

import WidgetCard from 'components/ClimateWidgets/Widget/WidgetCard';
import DetailsNavigation from 'components/DetailsNavigation/DetailsNavigation';

export type UserModel = {
  id: number;
  coins: number;
  account_type: number;
  email: string;
  firstname: string;
  lastname: string;
};

const Wrapper = styled.div<{isFullScreen: boolean}>`
  position: relative;

  ${(props) => {
    if (props.isFullScreen) {
      return `
        position: fixed;
        z-index: 40000;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        top: 0;
        left: 0;
        border-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 73px;
        background: ${props.theme.components.widgetCard.bgColor};
      `;
    }
    return '';
  }}
`;

interface WidgetProps {
  element: any;
}

const Widget = ({element}: WidgetProps) => {
  const authContext = useContext(AuthContext);
  const {setFullScreenMode} = useContext(LayoutContext);
  const {organizationConfig} = useContext(SettingsContext);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenTitle, setFullScreenTitle] = useState('');

  const {requestNativeEvent: getCurrentLocation} = useNativeEvent<
    CurrentLocationEvent['coordinates']
  >({
    key: 'coordinates',
    type: NATIVE_MESSAGES.REQUEST_CURRENT_LOCATION,
    event: NATIVE_EVENTS.CURRENT_LOCATION,
    isDataValid: (coordinates) =>
      !!(coordinates && coordinates.latitude && coordinates.longitude),
  });

  const {requestNativeEvent: scanQRCode} = useNativeEvent<QRCodeEvent['url']>({
    key: 'url',
    type: NATIVE_MESSAGES.REQUEST_QRCODE_SCAN,
    event: NATIVE_EVENTS.QRCODE_URL,
    isDataValid: (url) => !!url,
  });

  const user: OpenWidgetUser = useMemo(
    () =>
      userProfileToOpenWidgetUser({
        userProfile: authContext!.userProfile!,
      }),
    [authContext],
  );

  const theme: OpenWidgetTheme = useMemo(() => {
    const {
      theme: {font, colors, components},
    } = organizationConfig;

    return {
      styles: {
        font: {
          primary: font.primary.name,
          secondary: font.secondary.name,
        },
        colors: {
          primary: colors.primary,
          secondary: colors.secondary,
        },
        components: {
          app: {
            textColor: colors.black,
            bgColor: components.dashboard.bgColor,
            navBgColor: components.nav.bgColor,
            fontFamily: font.primary.name,
            fontSize: `${font.styles.normal.s375.fontSize}px`,
            fontWeight: font.styles.normal.fontWeight.toString(),
          },
          widget: {
            title: {
              textColor: components.widgetCard.titleColor,
              bgColor: components.widgetCard.titleBgColor,
              fontFamily: font.secondary.name,
              fontSize: `${font.styles.xLarge.s375.fontSize}px`,
              fontWeight: font.styles.xLarge.fontWeight.toString(),
            },
            body: {
              textColor: components.widgetCard.textColor || colors.black,
              bgColor: components.widgetCard.bgColor,
              fontFamily: font.primary.name,
              fontSize: `${font.styles.normal.s375.fontSize}px`,
              fontWeight: font.styles.normal.fontWeight.toString(),
            },
          },
        },
      },
    };
  }, [organizationConfig]);

  const api: OpenWidgetApi = useMemo(() => {
    return {
      getUser() {
        return user;
      },
      openFullScreen({title}: {title: string}) {
        setIsFullScreen(true);
        setFullScreenTitle(title || 'Widget title');
      },
      closeFullScreen() {
        setIsFullScreen(false);
      },
      getCurrentLocation() {
        if (isDesktop && false) {
          return new Promise((resolve) =>
            resolve({
              longitude: parseFloat((Math.random() * (180 - 1) + 1).toFixed(2)),
              latitude: parseFloat((Math.random() * (90 - 1) + 1).toFixed(2)),
            }),
          );
        }

        return getCurrentLocation();
      },
      scanQRCode() {
        if (isDesktop && false) {
          return new Promise((resolve) => resolve('https://changers.com'));
        }

        return scanQRCode();
      },
    };
  }, [user, getCurrentLocation, scanQRCode]);

  useEffect(() => {
    if (element.widgetRef && element.widgetRef.current) {
      element.widgetRef.current.api = api;
      element.widgetRef.current.user = user;
      element.widgetRef.current.theme = theme;
    }
  }, [authContext, element, api, user, theme, isFullScreen]);

  useEffect(() => {
    setFullScreenMode(isFullScreen);
  }, [isFullScreen, setFullScreenMode]);

  const openWidgetProps = {
    user,
    theme,
    api,
    fullscreen: isFullScreen
  };

  return (
    <Wrapper isFullScreen={isFullScreen}>
      {isFullScreen && (
        <DetailsNavigation
          text={fullScreenTitle}
          onClose={() => setIsFullScreen(false)}
        />
      )}
      <WidgetCard minHeight="100%">
        <element.reactComponent ref={element.widgetRef} {...openWidgetProps} />
      </WidgetCard>
    </Wrapper>
  );
};

export default Widget;
